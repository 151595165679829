import { useState } from "react";
import PropTypes from "prop-types";
import { MenuItem, Select } from "@mui/material";
import { Icon, Text } from "../";
import { colors, spacing, styleUtils } from "../../../styles";

/**
 * Select Dropdown options with selection check
 */
const SelectDropDown = (props) => {
  const {
    fieldValue,
    fieldName,
    handleBlur = () => {},
    variant,
    dropDownOptions,
    placeholder,
    handleChange,
    textSize = "s",
    textWeight = "regular",
    widthValue = "471",
    sx,
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <Select
      onClick={() => setShowDropdown(!showDropdown)}
      onBlur={handleBlur}
      open={showDropdown}
      variant={variant}
      displayEmpty
      IconComponent={() => (
        <Icon
          name="select-dropdown"
          size={25}
          onClick={() => setShowDropdown(!showDropdown)}
          style={{ cursor: "pointer" }}
        />
      )}
      name={fieldName}
      renderValue={() => (
        <Text
          color={fieldValue === "" ? colors.placeholder : colors.textPrimary}
          size={textSize}
          weight={textWeight}
        >
          {fieldValue === "" ? placeholder : fieldValue}
        </Text>
      )}
      value={fieldValue}
      onChange={(e) => handleChange(e.target.value)}
      MenuProps={{
        sx: {
          "&& .Mui-selected": {
            backgroundColor: colors.selectedBlue,
          },
        },
        PaperProps: {
          sx: {
            margin: 1,
            width: styleUtils.pxToRem(`${widthValue}px`),
          },
        },
      }}
      sx={{
        "& .MuiSelect-select": {
          pl: 0,
          py: spacing.xxs,
        },
        "&:hover": {
          border: "none",
        },
        width: styleUtils.pxToRem(`${widthValue}px`),
        ...sx,
      }}
    >
      {dropDownOptions.map((dropDownValue, index) => {
        return (
          <MenuItem
            key={index}
            value={dropDownValue}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: index === dropDownOptions.length - 1 ? "none" : "1px solid #E0E0E0",
            }}
          >
            {dropDownValue}
            {dropDownValue === fieldValue && <Icon name="select-done-option" size={21} />}
          </MenuItem>
        );
      })}
    </Select>
  );
};

SelectDropDown.propTypes = {
  fieldValue: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  handleBlur: PropTypes.func,
  variant: PropTypes.string,
  dropDownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  textSize: PropTypes.string,
  textWeight: PropTypes.string,
  widthValue: PropTypes.string,
  sx: PropTypes.object,
};

export default SelectDropDown;
