import { useEffect, useState } from "react";
import { Container, Grid, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import EmployerSelector from "../../../../../selectors/EmployerSelector";

import {
  SelectDropDown,
  Text,
  Icon,
} from "../../../../../../../components/atoms";
import {
  EmployerPeopleSearchInput,
  PeopleCard,
  LoadingJobView,
} from "../../../../../../../components/molecules";

import { colors, spacing, styleUtils } from "../../../../../../../styles";

import AllSeekers from "./AllSeekers";
import ShortListedSeekers from "./ShortListedSeekers";
import NoLocationView from "./NoLocationView";
import Filter from "./Filter";
import Sort from "./Sort";
import { PeopleConstants } from "../Constants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const PeopleBoard = ({
  isLoadingEmployerLocations,
  employerActiveLocations,
  selectedLoc,
  employerJobSeekerListing,
  starredEmployerJobSeekerListing,
  filterSettings,
  onClickPeople,
  onClickSearch,
  onClickPostNewJob,
  handleLocationChange,
  handleStarred,
  handleSorting,
  handleFilter,
  searchTerm,
  onChangeSearchTerm,
  onlyFavourites,
  // forceUpdate,
  peopleListPage,
  showLoadMore,
  handleLoadMorePeople,
  seekerSuggestions,
  fetchSeekerSuggestions,
  setSeekerSearchValue,
}) => {
  const [selectedTab, setSelectedTab] = useState("all");
  const isEmployerPeopleListLoading = useSelector(
    EmployerSelector.isEmployerPeopleListLoading
  );

  const renderPeople = (item, index) => {
    return (
      <Grid key={index} item md={4} mb={spacing.l}>
        <PeopleCard item={item} onClickPeople={onClickPeople} />
      </Grid>
    );
  };

  const onSelectTab = (type) => () => {
    setSelectedTab(type);
    handleStarred(type === "starred");
  };

  useEffect(() => {
    logEvent(FirebaseEvents.EMP_CLICKS_JOBSEEKER);
  },[]);

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Text size={34} sx={{ textAlign: "center" }}>
        <Text component="span" size={34} weight="medium">
          Local part-time
        </Text>
        <Text
          component="span"
          size={34}
          weight="extrabold"
          color={colors.primary}
        >
          {" "}
          hiring{" "}
        </Text>
        made simple
      </Text>
      <EmployerPeopleSearchInput
        sx={{ mt: spacing.xl, pl: "100px", pr: "100px" }}
        searchTermValue={searchTerm}
        onChangeSearchTerm={onChangeSearchTerm}
        onClickSearch={onClickSearch(searchTerm)}
        onClickPostNewJob={onClickPostNewJob}
        jobSuggestions={seekerSuggestions}
        setJobSearchValue={setSeekerSearchValue}
        fetchJobSuggestions={fetchSeekerSuggestions}
      />
      <Grid
        container
        columnSpacing={spacing.l}
        mt={spacing.xxl}
        sx={{ minHeight: "66vh" }}
      >
        {isLoadingEmployerLocations ? (
          <LoadingJobView
            image="fetching-jobs"
            imageHeight="40%"
            title={PeopleConstants.LOADING_TEXT}
          />
        ) : (
          <>
            <Grid item md={3}>
              <Filter
                filterSettings={filterSettings}
                handleFilter={handleFilter}
                onlyFavourites={onlyFavourites}
              />
            </Grid>
            <Grid item md={9}>
              <TitleContainer>
                <LocationDropdownContainer>
                  <Icon
                    name="people-loc-dropdown"
                    size={20}
                    style={{
                      alignSelf: "end",
                      marginRight: styleUtils.remToPx("6px"),
                    }}
                  />
                  <FormControl variant="standard">
                    <Text size="xs" color="#898989">
                      Results for job posted in
                    </Text>
                    <SelectDropDown
                      fieldValue={selectedLoc}
                      fieldName="employer_locations"
                      variant="standard"
                      handleChange={handleLocationChange}
                      dropDownOptions={employerActiveLocations}
                      placeholder=" "
                      widthValue={265}
                      sx={{
                        borderBottom: "0.4000000059604645px solid #2B4894",
                        border: "none",
                        height: styleUtils.pxToRem("18px"),
                        width: styleUtils.pxToRem("264.5px"),
                        padding: "0.5rem 0rem 0.5rem 0rem",
                        "& .MuiSelect-select:focus": {
                          backgroundColor: "#FFFFFF",
                        },
                      }}
                    />
                  </FormControl>
                </LocationDropdownContainer>
                <TabRow>
                  <SelectedTab
                    sx={{
                      transition: "transform 400ms",
                      transform: `translateX(${
                        selectedTab === "all" ? "0" : "calc(4rem + 4px)"
                      })`,
                    }}
                  />
                  <Tab onClick={onSelectTab("all")}>
                    <Text size="xs" color={colors.black}>
                      All
                    </Text>
                  </Tab>
                  <Tab onClick={onSelectTab("starred")}>
                    <Text size="xs" color={colors.black}>
                      Shortlist
                    </Text>
                  </Tab>
                </TabRow>
                <Sort handleSorting={handleSorting} leftMargin="0" />
              </TitleContainer>
              {isEmpty(employerActiveLocations) ? (
                <Grid container mt={spacing.s} columnSpacing={spacing.l}>
                  <NoLocationView onClickPostNewJob={onClickPostNewJob} />
                </Grid>
              ) : selectedTab === "all" ? (
                <AllSeekers
                  allJobSeekers={employerJobSeekerListing}
                  isEmployerPeopleListLoading={isEmployerPeopleListLoading}
                  handleLoadMorePeople={handleLoadMorePeople}
                  peopleListPage={peopleListPage}
                  renderPeople={renderPeople}
                  showLoadMore={showLoadMore}
                />
              ) : (
                <ShortListedSeekers
                  isEmployerPeopleListLoading={isEmployerPeopleListLoading}
                  renderPeople={renderPeople}
                  shortListedJobSeekers={starredEmployerJobSeekerListing}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default PeopleBoard;

const TitleContainer = styled("div")`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const LocationDropdownContainer = styled("div")`
  display: flex;
  height: ${styleUtils.pxToRem("48px")};
  margin-right: ${styleUtils.pxToRem("38px")};
  margin-bottom: ${styleUtils.pxToRem("8px")};
`;

const TabRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #e7edfd;
  border-radius: 6px;
  transform: translateY(-${spacing.xs});
  margin-right: ${styleUtils.pxToRem("240px")};
`;

const Tab = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs} 0px;
  width: calc(4rem + 4px);
  cursor: pointer;
`;

const SelectedTab = styled("div")`
  width: 4rem;
  left: 2px;
  height: calc(100% - 4px);
  position: absolute;
  background-color: ${colors.white};
  border-radius: 4px;
`;
