import { Button, Grid } from "@mui/material";

import EmploydSearchInput from "components/atoms/EmploydSearchInput";

import { colors, spacing } from "../../../../styles";
import { Text } from "../../../atoms";

const Input = ({
  searchTermValue,
  onChangeSearchTerm,
  onClickSearch,
  sx,
  jobSuggestions,
  fetchJobSuggestions,
  setJobSearchValue,
}) => {
  return (
    <Grid container justifyContent="center" alignItems="flex-end" sx={sx}>
      <Grid item md={5}>
        <Text
          style={{
            transform: "translateX(60px)",
            marginBottom: spacing.xs,
          }}
        >
          Job Seeker Name
        </Text>
        <EmploydSearchInput
          name="searchTerm"
          onChangeSearchTerm={onChangeSearchTerm}
          fetchSuggestions={fetchJobSuggestions}
          placeholder="Search by job seeker name"
          setSearchValue={setJobSearchValue}
          suggesstions={jobSuggestions}
          suggestionValue={searchTermValue}
          inputSx={{
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            height: 48,
          }}
        />
      </Grid>
      <Grid item md={2.5}>
        <Button
          variant="outlined"
          onClick={onClickSearch}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeftWidth: 0,
            borderColor: colors.border,
            backgroundColor: colors.primary,
            height: 48,
            "&:disabled": {
              borderColor: colors.border,
              borderLeftWidth: 0,
            },
          }}
        >
          <Text weight="bold" color={colors.white}>
            Search
          </Text>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Input;
