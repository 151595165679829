import React, { useState } from "react";
import useIsMobile from "hooks/useIsMobile";

import BlockUsersListMobile from "./mobile/BlockedUsers/BlockUsersList";
import BlockUsersList from "./web/BlockedUsers/BlockUsersList";

const BlockedUserListContainer = (props) => {
  const { onClickChat, usersList, setSelectedUser, setModalStatus, type } =
    props;

  const [anchorEl, setAnchorEl] = useState(null);
  const blockOpen = Boolean(anchorEl);
  const listTag = type === "block" ? "Blocked" : "Unblocked";
  const menuAction = type === "block" ? "Unblock User" : "Block User";
  const showChat = type === "unblock";

  const isMobile = useIsMobile();

  const handleClick = (event, userDetail) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(userDetail);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserStatus = () => {
    handleClose();
    setModalStatus(true);
  };

  const BlockedUserListWrapper = isMobile
    ? BlockUsersListMobile
    : BlockUsersList;

  return (
    <BlockedUserListWrapper
      onClickChat={onClickChat}
      usersList={usersList}
      setSelectedUser={setSelectedUser}
      setModalStatus={setModalStatus}
      handleClick={handleClick}
      handleClose={handleClose}
      handleUserStatus={handleUserStatus}
      type={type}
      blockOpen={blockOpen}
      listTag={listTag}
      menuAction={menuAction}
      showChat={showChat}
      anchorEl={anchorEl}
    />
  );
};

export default BlockedUserListContainer;
