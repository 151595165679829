import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { UnAuthenticatedNavConstants } from "../../NavBarConstants";

import {
  BASE_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  EMPLOYER_HOME,
  BLOGS,
} from "../../../../routes/routeConstants";
import { styleUtils } from "../../../../styles";

import { EMPLOYER, JOB_SEEKER } from "../../../../appConstants";
import { logEvent } from "../../../../configs/firebase";
import NavItem from "../../../../components/molecules/NavItem";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const UnAuthenticatedNav = ({ isCountryCheckError }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginType } = useParams();
  const isEmployer =
    location.pathname === EMPLOYER_HOME || loginType === "employer";

  const [searchParams] = useSearchParams();
  const referrerId = searchParams.get("referrerId");
  const referrerType = searchParams.get("referrerType");

  const onClickEmployer = () => {
    navigate(EMPLOYER_HOME);
    logEvent(FirebaseEvents.WEB_MENU_EMP, {
      items: [{ status: "success" }],
    });
  };

  const onClickJobSeeker = () => {
    navigate("/");
    logEvent(FirebaseEvents.WEB_MENU_JS, {
      items: [{ status: "success" }],
    });
  };

  const onClickLoginWithJobseeker = () => {
    navigate(`/login/${JOB_SEEKER}`, {
      state: {
        path: location.pathname,
        referrer: { referrerId, referrerType },
      },
    });
  };

  const onClickLoginWithEmployer = () => {
    navigate(`/login/${EMPLOYER}`, {
      state: {
        path: location.pathname,
        referrer: { referrerId, referrerType },
      },
    });
  };

  const handleClickForRestrictedCountry = () => {
    navigate("/", { from: location });
  };
  return (
    <>
      {import.meta.env.VITE_APP_ENVIRONMENT === "DEVELOPMENT" && (
        <StyledNavLink to={"/askhr"}>
          {({ isActive }) => (
            <NavItem isActive={isActive} text="Chatbot" icon="bell" />
          )}
        </StyledNavLink>
      )}
      {isEmployer ? (
        <>
          <StyledNavLink to={EMPLOYER_HOME}>
            {({ isActive }) => (
              <NavItem
                isActive={isActive}
                text={UnAuthenticatedNavConstants.HOME_TAB}
                icon="home"
              />
            )}
          </StyledNavLink>
          <StyledNavLink to={BLOGS}>
            {({ isActive }) => (
              <NavItem
                isActive={isActive}
                text={UnAuthenticatedNavConstants.BLOGS}
                icon="blog"
              />
            )}
          </StyledNavLink>
          <Button
            variant="outlined"
            sx={{
              width: "auto",
              marginLeft: styleUtils.pxToRem("40px"),
            }}
            onClick={onClickJobSeeker}
          >
            Job Seeker / Get a job
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "auto",
              marginLeft: styleUtils.pxToRem("20px"),
            }}
            onClick={
              isCountryCheckError
                ? handleClickForRestrictedCountry
                : onClickLoginWithEmployer
            }
          >
            Post a job
          </Button>
        </>
      ) : (
        <>
          <StyledNavLink to={BASE_PAGE_ROUTE}>
            {({ isActive }) => (
              <NavItem
                isActive={isActive}
                text={UnAuthenticatedNavConstants.HOME_TAB}
                icon="home"
              />
            )}
          </StyledNavLink>
          <StyledNavLink
            to={`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`}
          >
            {({ isActive }) => (
              <NavItem
                isActive={isActive}
                text={UnAuthenticatedNavConstants.EXPLORE_TAB}
                icon="exploreJobs"
              />
            )}
          </StyledNavLink>
          <StyledNavLink to={BLOGS}>
            {({ isActive }) => (
              <NavItem
                isActive={isActive}
                text={UnAuthenticatedNavConstants.BLOGS}
                icon="blog"
              />
            )}
          </StyledNavLink>
          <Button
            variant="outlined"
            sx={{
              width: "auto",
              marginLeft: styleUtils.pxToRem("40px"),
            }}
            onClick={onClickEmployer}
          >
            Employers / Post a job
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "auto",
              marginLeft: styleUtils.pxToRem("20px"),
            }}
            onClick={
              isCountryCheckError
                ? handleClickForRestrictedCountry
                : onClickLoginWithJobseeker
            }
          >
            Start Applying
          </Button>
        </>
      )}
    </>
  );
};

export default UnAuthenticatedNav;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${styleUtils.pxToRem("22px")};
`;
