import { CircularProgress } from "@mui/material";
import { colors, spacing } from "../../../styles";

import { Icon, Text } from "../../atoms";

const ImageUploadStatus = ({ style, status }) => {
  const divStyle = {
    marginTop: spacing.xxs,
    display: "flex",
    ...style,
  };
  const textStyle = {
    marginTop: spacing.xxs,
    display: "inline-block",
    width: "100%",
  };
  if (status === "loading") {
    return (
      <div style={divStyle}>
        <CircularProgress
          sx={{
            color: colors.orange,
            marginRight: spacing.xxs,
            marginTop: spacing.xxxs,
          }}
          size={18}
        />
        <Text size="xs" color={colors.orange} style={textStyle}>
          {PROFILE_IMAGE.loading}
        </Text>
      </div>
    );
  }
  if (status === "error") {
    return (
      <div style={divStyle}>
        <Icon
          name={"error"}
          size={18}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        />
        <Text size="xs" color={colors.red2} style={textStyle}>
          {PROFILE_IMAGE.error}
        </Text>
      </div>
    );
  }
  if (status === "success") {
    return (
      <div style={divStyle}>
        <Icon
          name={"greenTick"}
          size={18}
          style={{ marginRight: spacing.xxs, marginTop: spacing.xxxs }}
        />
        <Text size="xs" color={colors.darkGray2} style={textStyle}>
          {PROFILE_IMAGE.success}
        </Text>
      </div>
    );
  }
  return null;
};

const PROFILE_IMAGE = {
  loading: "The image is still uploading, please wait",
  error: "Error while uploading photo, try re-uploading",
  success: "Image uploaded successfully",
};

export default ImageUploadStatus;
