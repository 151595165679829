import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash/debounce";

import { Box, Card, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { EmploydSearchInput, Icon, Text } from "../../../../../../../components/atoms";
import {
  JobCard,
  JobDetailCard,
  CompanyDetailCard,
  JobCardSkeleton,
} from "../../../../../../../components/molecules";

import { setLoginRedirection } from "../../../../../../../utils/commonUtils";
import LoginSelector from "containers/Login/selectors/LoginSelector";

import { colors, spacing, styleUtils } from "../../../../../../../styles";
import { images } from "../../../../../../../assets/images";

import Filter from "./Filter";
import Sort from "./Sort";

/**
 * Job and Company Details View container for web
 */
const DetailsView = ({
  toggleExploreView,
  jobSeekerListing,
  filterSettings,
  onClickSearch,
  onClickJob,
  onClickBrandLogo,
  loggedInUser,
  showJobDetailView,
  showCompanyDetailView,
  handleSorting,
  handleFilter,
  searchTerm,
  onChangeSearchTerm,
  handleLoadMore,
  showLoadMore,
  setFieldValue,
  jobSuggestions,
  fetchJobSuggestions,
}) => {
  const scrollRef = useRef();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const navMap = searchParams.get("navMap");

  const [enableJoblisting, setEnableJoblisting] = useState(false);
  const [selectedMapcardId, setSelectedMapcardId] = useState("");
  const searchLocation = useSelector(LoginSelector.getSearchLocation);

  const jobSeekerloadinglist = [1, 2, 3, 4, 5, 6];

  const listingRef = useRef(null);
  const handleEnableJobListing = useCallback((value) => {
    setEnableJoblisting(value);
  });

  //#region - Debounce Search
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);

  const onSearch = useCallback(
    debounce((text) => {
      onClickSearch(text)();
    }, 1500),
    [],
  );
  //#endregion

  const [jobsList, setJobsList] = useState([]);
  const [isJobAbsent, setIsJobAbsent] = useState(false);

  useEffect(() => {
    setJobsList([]);
    setSelectedMapcardId("");
  }, [jobSeekerListing]);

  useEffect(() => {
    const scrollToLocation =
      document.getElementsByClassName("job-list-item-active")?.[0]?.offsetTop;
    if (jobSeekerListing.length > 0) {
      scrollRef?.current?.scrollTo(0, scrollToLocation - scrollRef?.current.offsetTop);
    }
    handleEnableJobListing(false);
    listingRef.current = undefined;
  }, []);

  const handleJobCardSelect = (job) => {
    if (navMap) {
      setLoginRedirection(
        loggedInUser?.uid,
        "",
        `?showJobDetailView=true&jobId=${job.jobId}&brandName=${job.brandName}&navMap=true`,
      );
      setSearchParams({
        showJobDetailView: true,
        jobId: job.jobId,
        brandName: job.brandName,
        navMap: true,
      });
    } else {
      onClickJob(job);
    }
  };

  const renderJobs = (item, index) => {
    const isFavourite = item?.jobSeekers.includes(loggedInUser?.uid);
    return (
      <JobCard
        key={index}
        item={item}
        isFavourite={isFavourite}
        // handleJobNotFound={handleJobNotFound}
        onClickJob={() => handleJobCardSelect(item)}
        onClickBrandLogo={onClickBrandLogo}
        cardStyle={{
          marginBottom: spacing.xl,
          marginRight: spacing.s,
          backgroundColor: item?.jobId === jobId ? colors.selectedCardBlue : colors.white,
        }}
        id={`JOB_CARD_${index}`}
        active={item?.jobId === jobId}
      />
    );
  };

  const renderJobCardSekeleton = (_, index) => {
    return (
      <JobCardSkeleton
        key={index}
        cardStyle={{
          marginBottom: spacing.xl,
          marginRight: spacing.s,
          backgroundColor: colors.white,
        }}
      />
    );
  };

  const navigateToCompanyLoc = (locationDetails) => {
    window
      .open(
        `https://www.google.com/maps/search/?api=1&query=${locationDetails?.lat},${locationDetails?.lng}`,
        "_blank",
      )
      .focus();
  };

  const navigateToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <Container sx={{ my: spacing.xs }}>
      <Grid container columnSpacing={spacing.m}>
        {!showCompanyDetailView && (
          <Grid item md={3.5}>
            <Card
              sx={{
                height: "86vh",
                py: spacing.m,
                pl: spacing.s,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row>
                <EmploydSearchInput
                  name="searchTerm"
                  placeholder="search by jobs, skills"
                  onChangeSearchTerm={onChangeSearchTerm}
                  fetchSuggestions={fetchJobSuggestions}
                  setSearchValue={setFieldValue}
                  suggesstions={jobSuggestions}
                  suggestionValue={searchTerm}
                  showFieldType={true}
                  showFullWidth={false}
                  inputSx={{
                    border: "1px solid #E6EDFF",
                    borderRadius: styleUtils.pxToRem("6px"),
                    boxShadow: `0px 5px 15px ${colors.shadow}`,
                    height: styleUtils.pxToRem("44px"),
                    width: styleUtils.pxToRem("170px"),
                  }}
                />
                <Sort handleSorting={handleSorting} />
                <Filter filterSettings={filterSettings} handleFilter={handleFilter} />
              </Row>
              <JobsContainer id="scrollableDiv" ref={scrollRef}>
                {jobSeekerListing?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={jobSeekerListing?.length}
                    scrollableTarget="scrollableDiv"
                    hasMore={enableJoblisting && showLoadMore}
                    next={handleLoadMore}
                    onScroll={() => {
                      if (!enableJoblisting && listingRef.current === undefined)
                        handleEnableJobListing(true);
                    }}
                    loader={jobSeekerloadinglist?.map(renderJobCardSekeleton)}
                  >
                    {selectedMapcardId
                      ? jobsList?.map(renderJobs)
                      : jobSeekerListing?.map(renderJobs)}
                  </InfiniteScroll>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src={images.empty.search}
                      style={{ width: 100, height: 100 }}
                      alt="search"
                    />
                    <Text size="l" style={{ marginTop: spacing.s }}>
                      No results found for
                    </Text>
                    <Text size="l">this search</Text>
                  </Box>
                )}
              </JobsContainer>
            </Card>
          </Grid>
        )}
        {showJobDetailView &&
          (showCompanyDetailView ? (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconContainer>
                <Icon
                  name="arrow-back"
                  size={42}
                  style={{ cursor: "pointer" }}
                  onClick={toggleExploreView}
                />
              </IconContainer>
              <CompanyDetailCard
                onClickBrandLogo={onClickBrandLogo}
                navigateToCompanyLoc={navigateToCompanyLoc}
                onClickJob={onClickJob}
              />
            </Grid>
          ) : (
            <Grid item md={8.5} sx={{ display: "flex", flexDirection: "column", height: "86vh" }}>
              <IconContainer>
                <Icon
                  name="arrow-back"
                  size={42}
                  style={{ cursor: "pointer" }}
                  onClick={navigateToPreviousPage}
                />
              </IconContainer>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <JobDetailCard
                  onClickBrandLogo={onClickBrandLogo}
                  setIsJobAbsent={setIsJobAbsent}
                  isJobAbsent={isJobAbsent}
                  searchLocation={searchLocation}
                />
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

DetailsView.propTypes = {
  fetchJobSuggestions: PropTypes.func.isRequired,
  filterSettings: PropTypes.object,
  handleFilter: PropTypes.func.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleSorting: PropTypes.func.isRequired,
  jobSeekerListing: PropTypes.array.isRequired,
  jobSuggestions: PropTypes.array,
  loggedInUser: PropTypes.object,
  onChangeSearchTerm: PropTypes.func.isRequired,
  onClickBrandLogo: PropTypes.func.isRequired,
  onClickJob: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  showCompanyDetailView: PropTypes.bool.isRequired,
  showJobDetailView: PropTypes.bool.isRequired,
  showLoadMore: PropTypes.bool.isRequired,
  toggleExploreView: PropTypes.func,
};

export default DetailsView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing.xl};
  padding-right: ${spacing.s};
  width: 100%;
`;

const IconContainer = styled("div")`
  display: flex;
  height: 3rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.l};
`;
const JobsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;
