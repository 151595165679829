import React ,{ useEffect, useState } from "react";
import { styled } from "@mui/material";
import bot from "../../assets/chatbot.png";
import user from "../../assets/user.png";

const Message = ({ role, content }: { role: string; content: any }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < content?.length && role === "bot") {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + content[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 10);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, content]);
  return (
    <Wrapper style={{ flexDirection: role === "user" ? "row-reverse" : "row" }} >
      <Img
        src={role === "bot" ? bot : user}
        alt="profile"
      />
      <p>{role == "bot" ? currentText : content}</p>
    </Wrapper>
  );
};

export default Message;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #1b1b1d;
  gap: 10px;
`
const Img = styled('img')`
  width: 40px;
  height: 40px;
`
