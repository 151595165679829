import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  Container,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Grid,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import useIsMobile from "../../hooks/useIsMobile";

import { logoutRequest } from "../Login/actions/LoginActions";
import { Text, TextInput } from "../../components/atoms";
import { colors, spacing, styleUtils, typography } from "../../styles";
import { hideLoader, showLoader, showNotification } from "../../utils/commonUtils";
import { deleteUserAccount } from "../../configs/network/apis";
import * as deleteAccountConstants from "../Employer/views/Profile/constants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { JOB_SEEKER } from "appConstants";

const DeleteAccountModal = ({ open, handleClose, loggedInUser }) => {
  const [deleteReason, setDeleteReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [isDeleteTextRequired, setIsDeleteTextRequired] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setDeleteReason("");
      setOtherReason("");
    };
  }, []);

  useEffect(() => {
    if (isMobile && isDeleteTextRequired && !otherReason) {
      document
        .getElementById("DELETE_REASON_INPUT_TEXT_FIELD")
        ?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isDeleteTextRequired]);

  const onClickStatus = (deleteCategory, reasonText) => async () => {
    if ((isDeleteTextRequired && otherReason) || deleteCategory !== "Other") {
      try {
        showLoader();
        const response = await deleteUserAccount({
          category: deleteCategory,
          reason: reasonText,
        });

        if (response?.data?.message === "user account successfully deleted!") {
          dispatch(logoutRequest());
          navigate("/");
          showNotification({
            alertProps: {
              severity: "success",
              children: response?.data?.message,
            },
          });
        } else {
          showNotification({
            alertProps: {
              severity: "error",
              children: "Something went wrong!",
            },
          });
        }
        handleClose();
        hideLoader();
        if (loggedInUser?.userType === JOB_SEEKER) {
          logEvent(FirebaseEvents.JS_CLICK_DELETE_ACCOUNT);
        } else {
          logEvent(FirebaseEvents.EMP_CLICK_DELETE_ACCOUNT);
        }
      } catch (e) {
        hideLoader();
        console.log(e);
      }
    } else if (deleteCategory === "Other") {
      setIsDeleteTextRequired(true);
    }
  };

  const handleChange = (event) => {
    const targetedElement = event.target.name;
    const targetedElementValue = event.target.value;

    if (targetedElement === "reasonText") {
      setOtherReason(targetedElementValue);
      {
        otherReason && setIsDeleteTextRequired(false);
      }
    } else {
      setDeleteReason(targetedElementValue);
      setIsDeleteTextRequired(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Container sx={{ mt: spacing.xxl }}>
        <Card
          sx={{
            height: "calc(100vh - 140px)",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "90%",
              sm: "90%",
              md: "80%",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "auto",
            }}
          >
            <Text
              size={22}
              weight="bold"
              style={{
                marginBottom: spacing.xs,
                marginTop: spacing.s,
                marginLeft: spacing.xl,
              }}
            >
              Delete My Account
            </Text>
            <Divider />
            <Text size="l" sx={{ mt: spacing.m, mb: spacing.l, mx: spacing.xl }}>
              It is sad to know that you are deleting your account, can you please select a reason
              for deleting your account
            </Text>
            <Grid container sx={{ mb: spacing.m, flex: { md: 1 }, overflow: "auto" }}>
              <Grid item xs sm md={7} sx={{ px: spacing.xl }}>
                <FormControl>
                  <RadioGroup value={deleteReason} onChange={handleChange}>
                    {deleteAccountConstants.DELETE_USER_MODAL_OPTIONS.map((deleteOption) => (
                      <RadioLabel
                        key={deleteOption.option}
                        value={deleteOption.value}
                        control={<Radio size="medium" id={deleteOption.option} />}
                        label={`${deleteOption.value}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item md sx={{ px: spacing.xl }}>
                <Text size="l">Enter reason here</Text>
                <StyledTextInput
                  name="reasonText"
                  onChange={handleChange}
                  sx={{
                    height: isMobile ? styleUtils.pxToRem("114px") : `calc(70% - ${spacing.xl})`,
                    width: isMobile ? styleUtils.pxToRem("282px") : "auto",
                  }}
                  inputProps={{
                    style: { height: "100%", overflow: "auto" },
                  }}
                  multiline
                />
                {isDeleteTextRequired && !otherReason && (
                  <FormHelperText
                    id="DELETE_REASON_INPUT_TEXT_FIELD"
                    sx={{ color: colors.red, marginBottom: spacing.s }}
                  >
                    Reason is required.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: isMobile ? "space-evenly" : "flex-end",
              margin: spacing.l,
            }}
          >
            <Button
              sx={{
                width: {
                  md: "auto",
                },
                px: spacing.xxxl,
                mr: spacing.s,
              }}
              disabled={!deleteReason}
              variant="outlined"
              onClick={onClickStatus(deleteReason, otherReason)}
            >
              Delete
            </Button>
            <Button
              sx={{
                width: {
                  md: "auto",
                },
                px: spacing.xxxl,
              }}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Card>
      </Container>
    </Modal>
  );
};

export default DeleteAccountModal;

const RadioLabel = styled(FormControlLabel)(() => ({
  color: colors.textPrimary,
  marginBottom: spacing.s,
  "& .MuiTypography-root": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));

const StyledTextInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
  "& .MuiInputBase-input": {
    fontWeight: typography.regular,
    fontSize: 16,
  },
}));
