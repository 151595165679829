import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import LoginSelector from "../../containers/Login/selectors/LoginSelector";
import UserSelect from "./UserSelection";

const ProtectedRoute = () => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);

  return isAuthenticated ? <Outlet /> : <UserSelect />;
};

export default ProtectedRoute;
