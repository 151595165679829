import { useEffect } from "react";
import { styled } from "@mui/material/styles";

import { Text } from "components/atoms";

import { images } from "assets/images";
import { spacing, styleUtils } from "../../../../styles";

import { FORGOT_PASSWORD } from "containers/Account/constants";

const ResetPasswordSuccess = ({ handleBack }) => {
  useEffect(() => {
    setTimeout(() => {
      handleBack();
    }, 3000);
  }, []);
  return (
    <ContentContainer>
      <ImgContainer src={images.passwordChangeSuccess} />
      <Text size="xxl" color="#3C3C3C" sx={{ textAlign: "center" }}>
        {FORGOT_PASSWORD.PASSWORD_CREATED}
      </Text>
      <Text
        size="m"
        color="#969696"
        sx={{ textAlign: "center", marginTop: spacing.m }}
      >
        {FORGOT_PASSWORD.PASSWORD_REDIRECT}
      </Text>
    </ContentContainer>
  );
};

export default ResetPasswordSuccess;

const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImgContainer = styled("img")`
  display: block;
  align-self: center;
  margin-top: ${styleUtils.pxToRem("113px")};
  margin-bottom: ${styleUtils.pxToRem("35px")};
  width: ${styleUtils.pxToRem("207px")};
  height: ${styleUtils.pxToRem("175px")};
`;
