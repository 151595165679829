import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AccordionSummary, Avatar, Box, Button, Chip, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import LoginSelector from "../../../containers/Login/selectors/LoginSelector";

import useIsMobile from "../../../hooks/useIsMobile";
import { Icon, RichTextViewer, Text } from "../../atoms";

import { colors, spacing, styleUtils, textSizes, typography } from "../../../styles";
import { renderImage } from "../../../utils/imageUtils";
import { getProcessedJobDetailValue, showNotification } from "../../../utils/commonUtils";
import { isRecentAppliedJob } from "utils/dateUtils";

import { useGetPeopleDescriptionQuery, useMarkStarredMutation } from "../../../reactQuery/people";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { logEvent } from "../../../configs/firebase";
import { usePeopleCardHelpers } from "../PeopleCard/PeopleCardHelpers";
import { JOB_SEEKER } from "../../../appConstants";
import { images } from "../../../assets/images";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import PeopleDetailCardSkeleton from "./PeopleDetailCardSkeleton";

const PeopleDetailCard = ({ handleMarkStarCallback }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const [showAppliedJobCount, setShowAppliedJobCount] = useState(true);
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");

  const { data: item, isLoading } = useGetPeopleDescriptionQuery({
    type: JOB_SEEKER,
    userId: uid,
    employer_id: loggedInUser?.uid,
  });

  const [markStarred] = useMarkStarredMutation("jobSeeker", uid);

  const isStarred = item?.starredBy.includes(loggedInUser?.uid);
  const isResumeRequested = item?.resumeEmpIds.includes(loggedInUser?.uid);
  const isAppliedUser = item?.favoriteJobs?.some((job) => job?.companyId === loggedInUser?.uid);

  const appliedJobs =
    item?.favoriteJobs?.filter((job) => job?.companyId === loggedInUser?.uid) || [];

  const recentAppliedUser = item?.favoriteJobs
    ?.filter((job) => job?.companyId === loggedInUser?.uid)
    .sort((a, b) => b?.jobAppliedDate - a?.jobAppliedDate)[0];

  const showNewAppliedTag = isAppliedUser && isRecentAppliedJob(recentAppliedUser?.jobAppliedDate);
  const { onClickRequestResume } = usePeopleCardHelpers({
    loggedInUser,
    item,
    isResumeRequested,
  });

  const RequestResumeHeaderText = (
    <Text
      weight="regular"
      style={{ marginTop: spacing.s, marginBottom: spacing.xs }}
    >
      Request job seeker resume
    </Text>
  );

  const onClickStarred = async (e) => {
    e.stopPropagation();
    await markStarred({
      jobSeekerId: item.userId || item.uid,
    });
    logEvent(isStarred ? FirebaseEvents.EMP_JS_UNSTARRED : FirebaseEvents.EMP_CLICKS_SHORTLIST, {
      items: [{ userId: loggedInUser.uid, status: "success" }],
    });
    handleMarkStarCallback();
    if (isStarred) {
      showNotification({
        alertProps: {
          severity: "info",
          children: "You have withdrawn interest from this user.",
        },
      });
    } else {
      showNotification({
        alertProps: {
          severity: "success",
          children: "You have expressed interest in this user.",
        },
      });
    }
  };

  const onClickChat = (e) => {
    e.stopPropagation();
    logEvent(FirebaseEvents.EMP_CLICKS_CHAT_NOW);
    navigate(
      {
        pathname: "/chat",
        search: `?uid=${item.userId || item.uid}&uType=${JOB_SEEKER}`,
      },
      {
        state: {
          photoURL: item.photoURL,
          fullName: item.fullName,
          age: item.age,
        },
      },
    );
  };

  if (isLoading) {
    return <PeopleDetailCardSkeleton isMobile={isMobile} />;
  }
  if (item === "Not found") {
    return (
      <Grid
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text>No people found</Text>
      </Grid>
    );
  }

  const onClickJAppliedJobTitle = (appliedJob) => {
    logEvent(FirebaseEvents.EMP_CLICK_JC, {
      items: [{ status: "onClick on job description success" }],
    });
    navigate(
      `/employer/jobs?showJobDetailView=true&jobId=${appliedJob.jobId}&brandName=${appliedJob.brandName}&showPeopleDetailView=true`,
    );
  };

  const getJobsTitle = (jobTitleValues) => {
    return jobTitleValues.map((appliedJob, index) => (
      <>
        <Text
          key={appliedJob.jobTitle}
          size="s"
          weight="medium"
          component="span"
          color={colors.midnightBlue}
          onClick={() => onClickJAppliedJobTitle(appliedJob)}
          sx={{
            marginLeft: spacing.xxxs,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {isMobile && appliedJob.jobTitle.length > 25 && showAppliedJobCount
            ? `${appliedJob.jobTitle.substring(0, 25)}...`
            : appliedJob.jobTitle}
        </Text>
        <Text
          component="span"
          size="s"
          weight="medium"
          color={colors.midnightBlue}
        >
          {index < jobTitleValues.length - 1 ? ",\xa0" : ""}
        </Text>
        {isMobile && showAppliedJobCount && appliedJobs.length > 2 && index === 1 && (
          <Text
            component="span"
            size="s"
            weight="medium"
            color={colors.blackText}
            onClick={() => setShowAppliedJobCount(false)}
          >
            {`\xa0+${appliedJobs.length - 2} more`}
          </Text>
        )}
      </>
    ));
  };

  const getAccordionSummary = (showAppliedJobCount, setShowAppliedJobCount) => (
    <AccordionSummary
      onClick={() => setShowAppliedJobCount(!showAppliedJobCount)}
      expandIcon={showAppliedJobCount ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    >
      <Text weight="regular">Applied Jobs</Text>
    </AccordionSummary>
  );

  const getAppliedJobsText = () => (
    <Text
      weight="regular"
      style={{ marginTop: spacing.s }}
    >
      Applied Jobs
    </Text>
  );

  const getAppliedJobsTitles = (jobs, showAppliedJobCount) => (
    <div>{getJobsTitle(showAppliedJobCount ? jobs.slice(0, 2) : jobs)}</div>
  );

  const appliedJobContent = isMobile ? (
    <>
      {appliedJobs.length > 2
        ? getAccordionSummary(showAppliedJobCount, setShowAppliedJobCount)
        : getAppliedJobsText()}
      {getAppliedJobsTitles(appliedJobs, showAppliedJobCount)}
    </>
  ) : (
    <>
      {getAppliedJobsText()}
      <div style={{ marginTop: spacing.xs }}>{getJobsTitle(appliedJobs)}</div>
    </>
  );

  const resumeActionPerfomer = (isResumeRequested) => (
    <div>
      {RequestResumeHeaderText}
      <Button
        variant={isResumeRequested ? "outlined" : "contained"}
        disabled={isResumeRequested}
        sx={{
          width: "auto",
          borderRadius: 100,
          padding: `${spacing.xs} ${spacing.xxl}`,
        }}
        onClick={onClickRequestResume}
      >
        {isResumeRequested ? "Requested Resume" : "Request Resume"}
      </Button>
    </div>
  );

  const getMarkingInterestIcon = (isStarred) => {
    return (
      <Icon
        size={24}
        name={"star"}
        style={{ cursor: "pointer" }}
        color={isStarred ? colors.starFill : colors.white}
        strokeColor={colors.starFill}
        onClick={onClickStarred}
      />
    );
  };

  return (
    <BoxContainer>
      <Grid container>
        <Grid
          item
          xs
          sm
          md
        >
          <TopContainer>
            <Avatar
              src={renderImage(item?.photoURL, "user")}
              variant="rounded"
              sx={{ width: 57, height: 57, borderRadius: 57 }}
            >
              <img
                alt={item?.fullName}
                src={images.defaults.user}
                style={{ width: 57, height: 57, borderRadius: 57 }}
              />
            </Avatar>
            <TitleContainer>
              <Text
                weight="bold"
                size="l"
                numberOfLines={1}
              >
                {item?.fullName}
              </Text>
              <Text
                color={colors.placeholder}
                size="xs"
              >
                {item?.age} yrs
              </Text>
              {showNewAppliedTag && (
                <AppliedStatusContainer>
                  <Text
                    weight="semibold"
                    color="#2B9433"
                    size="xxs"
                  >
                    New Applicant
                  </Text>
                </AppliedStatusContainer>
              )}
            </TitleContainer>
          </TopContainer>
        </Grid>
        <Grid
          item
          xs="auto"
          sm="auto"
          md="auto"
        >
          <IconContainer isMobile={isMobile}>{getMarkingInterestIcon(isStarred)}</IconContainer>
        </Grid>
      </Grid>
      <Divider />
      <div
        style={{
          overflow: isMobile ? "" : "auto",
          marginBottom: `calc(${textSizes.l} + 3 * ${spacing.xxxs})`,
        }}
      >
        <Grid
          container
          overflow="auto"
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          sx={{
            padding: isMobile ? 0 : `0 ${spacing.s}`,
            marginLeft: `${isMobile ? spacing.l : spacing.xs}`,
          }}
        >
          <>
            <Grid
              item
              xs
              sm
              md
            >
              <div
                style={{
                  padding: `${spacing.xs} 0`,
                }}
              >
                <Row>
                  <Icon
                    name="money"
                    alt={"money"}
                    size={31}
                  />
                  <InfoBox>
                    <Text
                      size="xs"
                      color={colors.brownText}
                      weight="semibold"
                    >
                      Hourly Wage
                    </Text>
                    <Text
                      weight="semibold"
                      size="s"
                      numberOfLines={1}
                    >
                      {`$${getProcessedJobDetailValue(item?.wageExpectation)} per hour`}
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon
                    name="clock"
                    alt={"clock"}
                    size={31}
                  />
                  <InfoBox>
                    <Text
                      size="xs"
                      color={colors.brownText}
                      weight="semibold"
                    >
                      Working Hours
                    </Text>
                    <Text
                      weight="semibold"
                      size="s"
                      numberOfLines={1}
                    >
                      {`${getProcessedJobDetailValue(item?.workingHours)} hours per week`}
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon
                    name="experience"
                    alt={"experience"}
                    size={31}
                  />
                  <InfoBox>
                    <Text
                      size="xs"
                      color={colors.brownText}
                      weight="semibold"
                    >
                      Experience
                    </Text>
                    <Text
                      weight="semibold"
                      size="s"
                      color={colors.blackText}
                    >
                      {item?.experience
                        .map((hours) => (hours === "Beginner" ? hours : `${hours} years`))
                        .join(", ")}
                    </Text>
                  </InfoBox>
                </Row>
              </div>
            </Grid>
            {!isMobile && (
              <Grid
                item
                xs={0}
                sx={{ margin: `${spacing.l} 0` }}
              >
                <Divider orientation="vertical" />
              </Grid>
            )}
            <Grid
              item
              xs
              sm
              md
            >
              <JobDetailsRightInfoContainer isMobile={isMobile}>
                <Row>
                  <Icon
                    name="timeslot"
                    alt={"timeslot"}
                    size={31}
                  />
                  <InfoBox>
                    <Text
                      size="xs"
                      color={colors.brownText}
                      weight="semibold"
                    >
                      Shift
                    </Text>
                    <Text
                      weight="semibold"
                      size="s"
                      numberOfLines={1}
                    >
                      {item?.timeSlot && Array.isArray(item?.timeSlot)
                        ? item?.timeSlot.join(", ")
                        : item?.timeSlot}
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon
                    name="calendar"
                    alt={"calendar"}
                    size={31}
                  />
                  <InfoBox>
                    <Text
                      size="xs"
                      color={colors.brownText}
                      weight="semibold"
                    >
                      Working Days
                    </Text>
                    <Text
                      weight="semibold"
                      size="s"
                      numberOfLines={1}
                    >
                      {item?.daysPerWeek && Array.isArray(item?.daysPerWeek)
                        ? item?.daysPerWeek.join(", ")
                        : item?.daysPerWeek}
                    </Text>
                  </InfoBox>
                </Row>
              </JobDetailsRightInfoContainer>
            </Grid>
          </>
        </Grid>
        <Divider sx={{ marginTop: spacing.s }} />
        {isAppliedUser && (
          <>
            <AppliedContent>{appliedJobContent}</AppliedContent>
            <Divider sx={{ marginTop: spacing.s }} />
          </>
        )}
        {item?.note && (
          <>
            <AboutDiv>
              <Text
                weight="regular"
                style={{ marginTop: spacing.s }}
              >
                About Me
              </Text>
              <RichTextViewer style={{ marginTop: spacing.xs }}>{item?.note}</RichTextViewer>
            </AboutDiv>
            <Divider style={{ marginTop: spacing.s }} />
          </>
        )}
        {item?.job_interests && (
          <>
            <JobInterestsDiv>
              <Text
                weight="regular"
                style={{ marginBottom: spacing.s }}
              >
                My Job Interests
              </Text>
              <InterestsFlexBox>
                {item?.job_interests?.map((jobRole, index) => {
                  return (
                    <Chip
                      label={jobRole}
                      key={`${index}-${jobRole}`}
                      variant="outlined"
                      onClick={() => {}}
                      sx={{
                        height: 34,
                        width: "auto",
                        margin: "0 0.75rem 0.75rem 0",
                        borderRadius: styleUtils.pxToRem("17px"),
                        pointerEvents: "none",
                        "& .MuiChip-label": {
                          fontSize: textSizes.s,
                          fontWeight: typography.semibold,
                          color: `${colors.DarkSapphireBlue}`,
                        },
                        border: `1px solid ${colors.DarkSapphireBlue}}`,
                        "& .MuiChip-clickable:hover": {
                          backgroundColor: colors.white,
                        },
                      }}
                    />
                  );
                })}
              </InterestsFlexBox>
            </JobInterestsDiv>
            <Divider style={{ marginTop: spacing.s }} />
          </>
        )}
        <ResumeDiv>
          {!item?.resumeUrl && resumeActionPerfomer(isResumeRequested)}
          {item?.resumeUrl && (
            <div>
              {RequestResumeHeaderText}
              <Button
                target="_blank"
                rel="noreferrer"
                download
                onClick={(e) => {
                  e.stopPropagation();
                  logEvent(FirebaseEvents.EMP_CLICKS_VIEW_RESUME);
                }}
                href={item?.resumeUrl}
                variant="outlined"
                sx={{
                  width: "auto",
                  borderRadius: 100,
                  padding: `${spacing.xs} ${spacing.xxl}`,
                }}
              >
                View Resume
              </Button>
            </div>
          )}
        </ResumeDiv>
      </div>
      <div style={{ height: "20px" }} />
      <ChatContainer isMobile={isMobile}>
        <ChatNowButton
          isMobile={isMobile}
          variant="contained"
          onClick={onClickChat}
        >
          {isMobile && <QuestionAnswerOutlinedIcon sx={{ marginRight: 2 }} />}
          <Text
            color={colors.white}
            weight="bold"
            size="l"
          >
            Chat Now
          </Text>
        </ChatNowButton>
      </ChatContainer>
    </BoxContainer>
  );
};

PeopleDetailCard.PropTypes = {
  handleMarkStarCallback: PropTypes.func.isRequired,
};

export default PeopleDetailCard;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s};
`;

const IconContainer = styled("div")`
  padding: ${({ isMobile }) => (isMobile ? styleUtils.pxToRem("14px") : spacing.s)};
`;

const AppliedStatusContainer = styled("div")`
  border: 0.5px solid ${colors.forestGreen};
  border-radius: 2px;
  background-color: ${colors.paleMint};
  height: ${styleUtils.pxToRem("20px")};
  width: ${styleUtils.pxToRem("77px")};
  padding: ${styleUtils.pxToRem("2px")};
  margin-top: ${spacing.xs};
  text-align: center;
  animation: fading 1.9s linear infinite;
  -webkit-animation: fading 1.9s linear infinite;
  @keyframes fading {
    0% {
      background-color: ${colors.fadingColor};
    }
    50% {
      background-color: #fff;
    }
    100% {
      background-color: ${colors.fadingColor};
    }
  }
  @-webkit-keyframes fading {
    0% {
      background-color: ${colors.fadingColor};
    }
    50% {
      background-color: #fff;
    }
    100% {
      background-color: ${colors.fadingColor};
    }
  }
`;

const InterestsFlexBox = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.l};
`;

const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;

const BoxContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s};
  margin-right: ${spacing.s};
  position: relative;
`;
const AppliedContent = styled("div")`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};
`;
const AboutDiv = styled("div")`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.l};
  padding-right: ${spacing.s};
`;
const JobInterestsDiv = styled("div")`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.l};
  padding-right: ${spacing.s};
  margin-top: ${spacing.l};
`;
const ResumeDiv = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
  margin-bottom: ${spacing.xl};
  padding-left: ${spacing.l};
`;

const JobDetailsRightInfoContainer = styled("div")`
  padding: ${spacing.xs} 0;
  padding-top: ${({ isMobile }) => (isMobile ? 0 : spacing.xs)};
  margin-left: ${({ isMobile }) => (isMobile ? 0 : spacing.xl)};
  margin-bottom: ${({ isMobile }) => (isMobile ? spacing.s : 0)};
`;

const ChatNowButton = styled(Button)`
  width: ${({ isMobile }) => (isMobile ? "85%" : styleUtils.pxToRem("250px"))};
  height: ${({ isMobile }) => (isMobile ? styleUtils.pxToRem("40px") : styleUtils.pxToRem("50px"))};
  margin-left: ${({ isMobile }) => (isMobile ? 0 : "auto")};
  border-radius: 100;
  padding: ${spacing.l} ${spacing.xxxxl};
  margin-bottom: ${({ isMobile }) => (isMobile ? "" : `${spacing.xl}`)};
`;

const ChatContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${({ isMobile }) => (isMobile ? "fixed" : "")};
  bottom: 0;
  right: auto;
  height: 65px;
  left: 0;
  width: 100%;
  background-color: ${({ isMobile }) => (isMobile ? "#fff" : "")};
  box-shadow: ${({ isMobile }) => (isMobile ? `0px -5px 5px ${colors.shadow}` : "")};
`;
