import PropTypes from "prop-types";

import useIsMobile from "../../../../hooks/useIsMobile";

import MyJobsDetailsView from "./views/MyJobsDetailsView";
import MyJobsDetailsResView from "./mobileViews/MyJobsDetailsResView";

const MyJobsDetailsContainer = ({
  jobsList,
  showJobDetailView,
  onClickJob,
  toggleGridView,
  isFavourite,
}) => {
  const isMobile = useIsMobile();

  const Wrapper = isMobile ? MyJobsDetailsResView : MyJobsDetailsView;

  return (
    <Wrapper
      jobsList={jobsList}
      showJobDetailView={showJobDetailView}
      onClickJob={onClickJob}
      toggleGridView={toggleGridView}
      isFavourite={isFavourite}
    />
  );
};

MyJobsDetailsContainer.propTypes = {
  jobsList: PropTypes.array.isRequired,
  showJobDetailView: PropTypes.bool.isRequired,
  onClickJob: PropTypes.func.isRequired,
  toggleGridView: PropTypes.func.isRequired,
  isFavourite: PropTypes.bool.isRequired,
};

export default MyJobsDetailsContainer;
