import React from "react";
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import RegistrationView from "./RegistrationView";
import { colors, styleUtils } from "../../../../styles";
import useIsMobile from "hooks/useIsMobile";

const RegistrationContainer = () => {
  const isMobile = useIsMobile();
  return (
    <Container id="CONTAINER" style={{ padding: "2% 0% 3% 0%" }}>
      <PaperContainer
        sx={{
          margin: isMobile
            ? `${styleUtils.pxToRem("20px")}  ${styleUtils.pxToRem("6px")} ${styleUtils.pxToRem(
                "42px",
              )} ${styleUtils.pxToRem("6px")}`
            : null,
        }}
      >
        <Paper variant="outlined">
          <RegistrationView />
        </Paper>
      </PaperContainer>
    </Container>
  );
};
export default RegistrationContainer;

const PaperContainer = styled("div")`
  background: #fcfffe 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px ${colors.dropShadow};
  border: 1px solid #e6edff;
  opacity: 1;
`;
