import useIsMobile from "../../hooks/useIsMobile";
import Search from "./views/Search";
import SearchMobile from "./mobileViews/Search";

const SearchWrapper = ({ onGetStarted }) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? SearchMobile : Search;

  return <Wrapper onGetStarted={onGetStarted} />;
};

export default SearchWrapper;
