export const SIGN_UP_LABEL_1 = "Job Seeker Sign up";
export const SIGN_UP_LABEL_2 = "Select your job interests";
export const SIGN_UP_LABEL_3 = "Set your preference";
export const SIGN_UP_LABEL_4 = "You’re Almost Done!";

export const SIGN_UP_SUB_TEXT_2 = "Select your job interests to get tailored job recommendations";
export const SIGN_UP_SUB_TEXT_3 = "Set your profile preference to get tailored job recommendations";

export const FORM_ADD_PROFILE_PHOTO = "Add Profile Photo";
export const FORM_NAME_LABEL = "Full Name";
export const FORM_NAME_PLACEHOLDER = "enter full name";
export const FORM_EMAIL_LABEL = "Email";
export const FORM_EMAIL_PLACEHOLDER = "enter email";
export const FORM_PWD_LABEL = "Password";
export const FORM_CONFIRM_PWD_LABEL = "Confirm Password";
export const FORM_PWD_PLACEHOLDER = "enter password";
export const FORM_PHONE_LABEL = "Phone number";
export const FORM_PHONE_PLACEHOLDER = "enter phone number";
export const FORM_AGE_LABEL = "Age";
export const FORM_AGE_PLACEHOLDER = "Enter your age";
export const FORM_STATUS_LABEL = "Work Status";
export const FORM_TERMS_TEXT = "I accept the Terms of service";
export const STATUS_BTN_BEGINNER = "Beginner";
export const STATUS_BTN_EXPERIENCED = "Experienced";

export const SEEKER_DETAILS_HEADER = "Job Seeker Account";
export const SEEKER_DETAILS_SUBTEXT = "On registering you can";
export const SEEKER_DETAILS_INFO_ONE = "Build your profile and let recruiters find you";
export const SEEKER_DETAILS_INFO_TWO = "Search for jobs that match your interests";
export const SEEKER_DETAILS_INFO_THREE = "Apply for jobs in one-click";
export const SEEKER_DETAILS_INFO_FOUR = "Chat directly with employers and get hired in no time";

export const SIGN_UP_JOBSEEKER_KEY = "jobSeeker";
export const SIGNUP_NEXT_BTN = "Next";
export const SIGNUP_COACHMARK_TEXT =
  "Adding a display picture has a 33% higher chance of getting contacted by an employer";

export const JOB_SEEKER_FORM_DETAILS = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  age: "",
  countryCode: "",
  phoneNumber: "",
  wageExpectation: "",
  workingHours: "",
  experience: "",
  photoURL: "",
  note: "",
  lat: "",
  lng: "",
  tandc: false,
};

export const JOB_SEEKER_INTERESTS_DETAILS = {
  job_interests: [],
};

export const JOB_SEEKER_PREFERENCE_FORM_DETAILS = {
  wageExpectation: "",
  workingHours: "",
  experience: "",
  daysPerWeek: "",
  note: "",
  timeSlot: "",
};

export const JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES = {
  //PAGE 1 - job seeker form details
  REQUIRED_NAME_TEXT: "Please enter your name",
  EMAIL_INVALID: "Enter a valid email",
  REQUIRED_EMAIL_TEXT: "Please enter your email",
  REQUIRED_PHONE_TEXT: "Please enter your phone number",
  PHONE_INVALID: "Please enter valid phone number",
  PASSWORD_MIN: "Password should be of minimum 6 characters",
  REQUIRED_PASSWORD_TEXT: "Please enter your password",
  PASSWORDS_DO_NOT_MATCH: "Your passwords do not match",
  REQUIRED_CONFIRM_PASSWORD_TEXT: "Please confirm your password",
  AGE_MIN: "Minimum age is 14 years",
  AGE_MAX: "Maximum age is 99 years",
  REQUIRED_AGE_TEXT: "Please enter your age",

  //PAGE 2 - job seeker interest details
  JOB_INTERESTS_MIN: "Please select a minimum of 3 job interests",
  JOB_INTERESTS_MAX: "You can only select a maximum of 6 jobs interests",
};

export const PREFERENCES_ERROR_CONSTANTS = {
  //PAGE 3 - job seeker preference form details && EditPreferences screen
  WAGE_EXPECTATION_ERROR_TEXT: "Please enter your hourly wage expectation",
  WORKING_HOURS_ERROR_TEXT: "Please enter your preferred working hours per week",
  EXPERIENCE_ERROR_TEXT: "Please enter your work experience",
  WORKING_DAYS_ERROR_TEXT: "Please enter your preferred working days in a week",
  TIME_SLOT_ERROR_TEXT: "Please enter your preferred time slot",
};

export const EMPLOYD = "EMPLOYD";
export const SET_YOUR_PREFERENCE = "Set your Preference";
export const UPLOAD_RESUME = "Upload Resume";
export const SET_PROFILE = "Set your profile preference to get tailored job recommendations";
export const ADD_PROFILE_PHOTO = "Add Profile Photo";
export const AGE = "Age";
export const WAGE_EXPECTATION = "Hourly Wage Expectations";
export const HOURS = "Hours per week";
export const NEXT = "Next";
export const WORK_EXPERIENCE = "Total Work Experience";
export const DAYS_WEEK = "Days per week";
export const SHIFT = "Shift";
export const NOTE_FOR_EMPLOYEE = "About Me";
export const NOTE_FOR_EMPLOYEE_PLACEHOLDER =
  "Tell employers a little about yourself to stand out - interests, hobbies, experience.";
export const SUBMIT = "Submit";
export const SET_PREFERENCES = "Set Preferences";
export const NOTE_FOR_EMPLOYER = "About Me";
export const NAVIGATE_TO_STEP_ONE = "back to step 01";
export const JOBSEEKER_INTERESTS_HELP_TEXT = "Add your interests: Chef, Hair stylist, Lifeguard...";

export const wageExpectations = [
  {
    id: 1,
    label: "$5-10",
    value: "5-10",
    isSelected: false,
  },
  {
    id: 2,
    label: "$10-15",
    value: "10-15",
    isSelected: false,
  },
  {
    id: 3,
    label: "$15-20",
    value: "15-20",
    isSelected: false,
  },
  {
    id: 4,
    label: "$20-30",
    value: "20-30",
    isSelected: false,
  },
  {
    id: 5,
    label: "$30-40",
    value: "30-40",
    isSelected: false,
  },
];

export const workingHrs = [
  {
    id: 1,
    label: "00-02hrs",
    value: "0-2",
    isSelected: false,
  },
  {
    id: 2,
    label: "02-05hrs",
    value: "2-5",
    isSelected: false,
  },
  {
    id: 3,
    label: "05-10hrs",
    value: "5-10",
    isSelected: false,
  },
  {
    id: 4,
    label: "10-20hrs",
    value: "10-20",
    isSelected: false,
  },
  {
    id: 5,
    label: "20-30hrs",
    value: "20-30",
    isSelected: false,
  },
  {
    id: 6,
    label: "30-40hrs",
    value: "30-40",
    isSelected: false,
  },
];

export const workExperience = [
  {
    id: 1,
    label: "Beginner",
    value: "Beginner",
    isSelected: false,
  },
  {
    id: 2,
    label: "01-02 yrs",
    value: "1-2",
    isSelected: false,
  },
  {
    id: 3,
    label: "02-05 yrs",
    value: "2-5",
    isSelected: false,
  },
  {
    id: 4,
    label: "Above 05 yrs",
    value: ">5",
    isSelected: false,
  },
];

export const timeSlots = [
  {
    id: 1,
    label: "Morning",
    value: "Morning",
    isSelected: false,
  },
  {
    id: 2,
    label: "Afternoon",
    value: "Afternoon",
    isSelected: false,
  },
  {
    id: 3,
    label: "Evening",
    value: "Evening",
    isSelected: false,
  },
  {
    id: 4,
    label: "Night",
    value: "Night",
    isSelected: false,
  },
];

export const dayAvailable = [
  {
    id: 1,
    label: "Mon",
    value: "Mon",
    isSelected: false,
  },
  {
    id: 2,
    label: "Tue",
    value: "Tue",
    isSelected: false,
  },
  {
    id: 3,
    label: "Wed",
    value: "Wed",
    isSelected: false,
  },
  {
    id: 4,
    label: "Thu",
    value: "Thu",
    isSelected: false,
  },
  {
    id: 5,
    label: "Fri",
    value: "Fri",
    isSelected: false,
  },
  {
    id: 6,
    label: "Sat",
    value: "Sat",
    isSelected: false,
  },
  {
    id: 7,
    label: "Sun",
    value: "Sun",
    isSelected: false,
  },
];

export const TOP_JOB_ROLES = [
  "Barista",
  "Cashier",
  "Server",
  "Cook",
  "Receptionist",
  "Bartender",
  "Dishwasher",
  "Customer Service",
  "Sales Associate",
  "Stocker",
  "Delivery Driver",
  "Caregiver",
  "Teller",
  "Personal Trainer",
  "Assistant Manager",
];

export const ALL_JOB_ROLES = [
  "General manager",
  "Assistant Manager",
  "Executive Chef",
  "Sous Chef",
  "Pastry Chef",
  "Kitchen Manager",
  "Line Cook",
  "Fast Food Cook",
  "Prep Cook",
  "Server",
  "Runner",
  "Busser",
  "Host",
  "Bartender",
  "Cashier",
  "Dishwasher",
  "Inventory Associate",
  "Customer Service Representative",
  "Warehouse Clerk",
  "Assistant Store Manager",
  "Inventory control Specialist",
  "Floor Manager",
  "Store Manager",
  "Buyer",
  "Department Manager",
  "Stocker",
  "Receptionist",
  "Spa/Salon Coordinator",
  "Spa Manager",
  "Colorist",
  "Spa Attendant",
  "Nail Technician",
  "Massgae Therapist",
  "Hair Stylist",
  "Spa Concierge",
  "Salon Technician",
  "Wax Specialist",
  "Esthetician",
  "Cosmetologist",
  "Dog Groomer",
  "Counter Staff",
  "Manager",
  "Opener",
  "Child Care Attendant",
  "Sales Assistant",
  "Group Fitness Instructor",
  "Personal Trainer",
  "Chiropractor",
  "Pharmacy Clerk",
  "Pharmacy Dispenser",
  "Pharmacy Assistant",
  "Pharmacist",
  "Staff Pharmacist",
  "Chemotherapy Pharmacist",
  "Home Health Aide",
  "Nursing Assistant",
  "Medical Assistant",
  "Licensed Practical Nurse",
  "Physical therapy Assistant",
  "Registered Nurse",
  "Caregivers",
  "Nanny",
  "Tax Professional",
  "Associate Team Leader",
  "Client Relationship Consultant",
  "Tax Advisor",
  "Tax Preparer Assistant",
  "Universal Branch Associate",
  "Accounting Clerks",
  "Branch Operations Manager",
  "Banking Relationship Specialist",
  "Teller",
  "Store Customer Experience Coordinator ",
  "Financial Services Associate ",
  "Assistant Branch Manager",
  "Branch Banker",
  "Universal Banker",
  "Registered Dietician",
  "Certified Nursing Assistant",
  "Food Service Worker",
  "Documentation Specialist",
  "Training Specialist",
  "Service Order Dispatcher",
  "Operations Data Analyst",
  "Senior Eligibility Manager",
  "Construction Project Coordinator  ",
  "Project Director",
  "Operations Director",
  "Material Coordinator",
  "Valet Attendent",
  "Warehouse Specialist",
  "Certified Pest Control Technician",
  "Farm worker",
  "Grower",
  "Grain elevator operator",
  "Porter",
  "Customer Service ",
  "Automotive Technician",
  "Sales Consultant",
  "Automotive Courtesy Technician",
  "Customer Specialist",
  "Diesel Technician Mechanic",
  "Outside Parts Sales",
  "Auto Mechanic",
  "Inside Parts Counter Sales",
  "Auto Body Technician",
  "Press Apprentice Mechanic",
  "Assembly Apprentice Mechanic",
  "Lot Attendant",
  "DMV Title Clerk",
  "Uber Driver",
  "Lyft Driver",
  "Delivery Driver",
  "Food Delivery",
  "Food Courier",
  "Restaurant Delivery",
  "Truck Driver",
  "Human Services Coordinator",
  "Human Services Program Assistant",
  "Bilingual Youth Advocate",
  "Case Manager ",
  "Sales Associate",
  "Administrative Assistant",
  "Box Office",
  "Theatre Team Member",
  "Lifeguard",
  "Adventure Guide",
  "Counter Server",
  "Tree Groundsman",
  "Tree Climber",
  "Housecleaners",
  "Floor Technician",
  "Shift Supervisor",
  "HVAC Service Technician",
  "Primary Janitorial Person",
  "Legal Assistant",
  "Corporate Paralegal",
  "Executive Assistant",
  "Law Firm Accountant",
  "Security Officer",
  "Security Account Manager",
  "Security Guard",
  "Inspection Tech",
  "Installation helper",
  "Field Service Technician",
  "Handyman",
  "Field Service Representative",
  "Crew Manager",
  "Housekeeping Supervisor",
  "Night Audit",
  "Maintenance Engineer",
  "House person",
  "Cook",
  "Room Attendant",
  "Food & Beverage Manager",
  "Chef",
  "Guest Service Agent",
];
