export const ChatConstants = {
  SEARCH_INPUT_PLACEHOLDER_EMPLOYER: "Search for job seekers",
  SEARCH_INPUT_PLACEHOLDER_JOBSEEKER: "Search for employer/company",
  CHAT_INPUT_PLACEHOLDER: "Send a message",
  NO_CONVERSATION: "You haven't started a conversation yet,",
  LOOK_FOR_NEW_JOB: "Start looking for jobs and chat with the hiring manager!",
  CHAT_WITH_JOB_SEEKER:
    "Chat with jobseekers around you and invite them to an interview!",
  CHAT_WITH_JOB_SEEKER_BUTTON: "Chat with Job Seekers",
  EXPLORE_JOBS: "Explore Jobs",
  CHOOSE_A_CONVERSATION: "Choose a conversation",
  CLICK_ON_EXISTING_CONVERSATION:
    "Click on an existing chat to start a conversation",
  NO_SEARCH_RESULT: "No results for your search.",
  PLACEHOLDER_BLOCK_USER_REASON: "Enter reason here...",
  PLACEHOLDER_BLOCK_USER_REASON_REQUIRED: "Enter reason here...*",
  CATEGORY_OTHER: "Other",
};
