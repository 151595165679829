import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Text } from "../../../../../../../components/atoms";
import {
  EmployerJobCard,
  EmployerSearchInput,
  EmptyView,
  LoadingJobView,
} from "../../../../../../../components/molecules";
import EmployerSelector from "../../../../../selectors/EmployerSelector";
import { colors, spacing, styleUtils } from "../../../../../../../styles";
import Filter from "./Filter";
import EmployerSignUpModal from "./EmployerSignUpModal";
import { EmployerConstants } from "../../Constants";

const JobBoard = ({
  employerListing,
  filterSettings,
  onClickJob,
  onClickSearch,
  onClickPostNewJob,
  handleFilter,
  searchTerm,
  onChangeSearchTerm,
  showSignUpModal,
  handleSignUpModal,
  fetchPostedJobsSuggestions,
  setSearchValue,
  postedJobsSuggestions,
}) => {
  const isEmployerJobListLoading = useSelector(
    EmployerSelector.isEmployerJobListLoading
  );
  const renderJobs = (item, index) => {
    return (
      <Grid key={index} item md={4} mb={spacing.l}>
        <EmployerJobCard item={item} onClickJob={onClickJob} />
      </Grid>
    );
  };

  return (
    <Container sx={{ my: spacing.xxl }}>
      <EmployerSignUpModal
        showSignUpModal={showSignUpModal}
        handleSignUpModal={handleSignUpModal}
      />
      <Text size={34} sx={{ textAlign: "center" }}>
        Local part-time{" "}
        <Text
          component="span"
          size={34}
          weight="extrabold"
          color={colors.primary}
        >
          hiring
        </Text>{" "}
        made simple
      </Text>
      <EmployerSearchInput
        sx={{ mt: spacing.xl, pl: "100px", pr: "100px" }}
        name="searchTerm"
        searchTermValue={searchTerm}
        onChangeSearchTerm={onChangeSearchTerm}
        onClickSearch={onClickSearch(searchTerm)}
        onClickPostNewJob={onClickPostNewJob}
        fetchPostedJobsSuggestions={fetchPostedJobsSuggestions}
        setSearchValue={setSearchValue}
        postedJobsSuggestions={postedJobsSuggestions}
      />
      <Grid
        sx={{ minHeight: "66vh" }}
        container
        columnSpacing={spacing.l}
        mt={spacing.xxl}
      >
        {isEmployerJobListLoading ? (
          <LoadingJobView
            image="fetching-jobs"
            imageHeight="40%"
            title={EmployerConstants.LOADING_TEXT}
          />
        ) : (
          <>
            <Grid item md={3}>
              <Filter
                filterSettings={filterSettings}
                handleFilter={handleFilter}
              />
            </Grid>
            <Grid item md={9}>
              <Text weight="bold">Jobs Posted</Text>
              <Grid
                container
                mt={employerListing.length > 0 ? spacing.s : "8rem"}
                columnSpacing={spacing.l}
              >
                {employerListing.length > 0 ? (
                  employerListing.map(renderJobs)
                ) : (
                  <EmptyView
                    image="no-recommended-jobs"
                    imageHeight={styleUtils.pxToRem("190px")}
                    title={EmployerConstants.NO_JOBS_POSTED_TITLE}
                    description={EmployerConstants.NO_JOBS_POSTED_DESCRIPTION}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default JobBoard;
