import { styled } from "@mui/material";
import { Icon } from "../../../../../../../components/atoms";
import { styleUtils } from "styles";

const Marker = ({
  currentIndex,
  handleMarkerPress,
  isActiveMarker,
  showUserLocation = false,
}) => {
  const handleClick = () => {
    handleMarkerPress(currentIndex);
  };

  return showUserLocation ? (
    <UserLocationIcon />
  ) : (
    <Icon
      name={isActiveMarker ? "map-select-marker" : "map-marker"}
      size={isActiveMarker ? 48 : 42}
      onClick={handleClick}
    />
  );
};

export default Marker;

const UserLocationIcon = styled("div")`
  border: none;
  outline: none;
  width: ${styleUtils.pxToRem("15px")};
  height: ${styleUtils.pxToRem("15px")};
  border-radius: 50%;
  display: block;
  background-color: #0a97ff;
  color: #fff;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: 2px solid #fff;

  animation: ripple 1.5s infinite;
  -webkit-animation: ripple 1.5s infinite;

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 #0a97ff, 0 0 0 0 #0a97ff;
    }

    80% {
      box-shadow: 0 0 0 5px #c5e6ff, 0 0 0 8px #c5e6ff;
    }

    100% {
      box-shadow: 0 0 0 0 #c5e6ff, 0 0 0 0 #c5e6ff;
    }
  }
`;
