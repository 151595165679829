import { useState } from "react";
import PropTypes from "prop-types";

import { Button, Divider, Grid, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Text, TextInput } from "../../../../components/atoms";
import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";

import { CHANGE_PASSWORD } from "../../constants";
import { isEmpty } from "lodash";

const CurrentPassword = ({ currentPasswordFormik, onClickPasswordScreen }) => {
  const [showPwdText, setShowPwdText] = useState(false);

  return (
    <>
      <Grid container sx={{ display: "flex", flex: 1, margin: spacing.m }}>
        <Grid item xs={12} sm={12} md={6} sx={{ padding: spacing.xs }}>
          <InfoLabelText size="l" color="#767676">
            {CHANGE_PASSWORD.PASSWORD_LABEL}
          </InfoLabelText>
          <PasswordInput
            name="currentPassword"
            placeholder={CHANGE_PASSWORD.PASSWORD_PLACEHOLDER}
            type={showPwdText ? "text" : "password"}
            value={currentPasswordFormik.values.currentPassword}
            onChange={currentPasswordFormik.handleChange}
            onBlur={currentPasswordFormik.handleBlur}
            handleSubmit={currentPasswordFormik.handleSubmit}
            endAdornment={
              <InputAdornment position="end" onClick={() => setShowPwdText(!showPwdText)}>
                <IconButton>
                  {showPwdText ? (
                    <Visibility sx={{ color: "#848484" }} />
                  ) : (
                    <VisibilityOff sx={{ color: "#848484" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {currentPasswordFormik.touched.currentPassword &&
            Boolean(currentPasswordFormik.errors.currentPassword) && (
              <FormHelperText sx={{ color: colors.red }}>
                {currentPasswordFormik.errors.currentPassword}
              </FormHelperText>
            )}
        </Grid>
      </Grid>
      <Divider flexItem sx={{ mx: spacing.s }} />
      <ActionFooter>
        <ActionButton
          onClick={() => onClickPasswordScreen(false)}
          variant="outlined"
          sx={{
            mr: spacing.s,
          }}
        >
          {CHANGE_PASSWORD.CANCEL_BUTTON}
        </ActionButton>
        <ActionButton
          disabled={!currentPasswordFormik.dirty || !isEmpty(currentPasswordFormik.errors)}
          onClick={currentPasswordFormik.handleSubmit}
          variant="contained"
        >
          {CHANGE_PASSWORD.NEXT_BUTTON}
        </ActionButton>
      </ActionFooter>
    </>
  );
};

CurrentPassword.propTypes = {
  currentPasswordFormik: PropTypes.object.isRequired,
  onClickPasswordScreen: PropTypes.func.isRequired,
};

export default CurrentPassword;

const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.s};
`;
const PasswordInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
  height: styleUtils.pxToRem("56px"),
  marginBottom: spacing.s,
  width: styleUtils.pxToRem("516px"),
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));

const ActionFooter = styled("div")`
  display: flex;
  justify-content: center;
  padding: ${spacing.s} ${spacing.l};
`;
const ActionButton = styled(Button)`
  width: ${styleUtils.pxToRem("224px")};
  height: ${styleUtils.pxToRem("48px")};
  font-size: ${textSizes.m};
`;
