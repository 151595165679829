export const EmployerConstants = {
  TITLE: "Hire Locals",
  SEARCH_INPUT_PLACEHOLDER: "search by jobs",
  SEARCH: "Search",
  POST_NEW_JOB: "Post New Job",
  FILTER_BY: "Filter by",
  JOBS_POSTED: "Jobs Posted",
  NO_JOBS_POSTED_TITLE: "No jobs posted yet",
  NO_JOBS_POSTED_DESCRIPTION: "Keep track of job listings you’re interested in",
  POSTJOB_MODAL_TITLE: "Let's make your next great hire. Fast",
  POSTJOB_MODAL_TEXT_LINE1: "You know who you're looking for.",
  POSTJOB_MODAL_TEXT_LINE2: "We'll help you find them.",
  POSTFIRSTJOB_MODAL_TEXT_1: "Post a job in 1 minute",
  POSTFIRSTJOB_MODAL_SUBTEXT_1: "We make it very easy to post your job",
  POSTFIRSTJOB_MODAL_TEXT_2: "Explore Profiles",
  POSTFIRSTJOB_MODAL_SUBTEXT_2: "Browse detailed profiles of all job seekers around you",
  POSTFIRSTJOB_MODAL_TEXT_3: "Chat Directly",
  POSTFIRSTJOB_MODAL_SUBTEXT_3: "No waiting around, start chatting with job seekers immediately",
  LOADING_TEXT: "Loading...",
};

export const MapViewConstants = {
  JOBS_POSTED: "Jobs Posted",
  SEARCH_INPUT_PLACEHOLDER: "search by jobs",
};

export const FilterConstants = {
  RESET_FILTER: "Reset Filter",
  APPLY: "Apply",
  DISTANCE: "Distance",
  MILES: "miles",
  HOURLY_WAGES: "Hourly Wages",
  HOURS_PER_WEEK: "Hours/week",
  JOB_TYPE: "Job Type",
  ALL: "All",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const employerJobSortingOptions = [
  // {
  //   key: "distance",
  //   title: "Distance",
  //   order: "",
  // },
  {
    key: "wageExpectation",
    title: "Hourly wage",
    order: "",
  },
  {
    key: "workingHours",
    title: "Hours per week",
    order: "",
  },
  {
    key: "experience",
    title: "Experience",
    order: "",
  },
];
