import { styled } from "@mui/material/styles";
import { colors } from "../../../styles";

const Indicator = () => {
  return <Line />;
};

export default Indicator;

const Line = styled("div")`
  position: absolute;
  top: 0;
  height: 4px;
  border-radius: 4px;
  width: 40%;
  background-color: ${colors.primary};
`;
