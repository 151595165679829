import { handleActions } from "redux-actions";
import update from "immutability-helper";

import {
  employerInitialState,
  employerUserDetailsInitialState,
  employerSchedulerInitialState,
} from "./initialState";
import * as employerActionTypes from "../actions/actionTypes";

const employerReducer = {
  employerState: handleActions(
    {
      [employerActionTypes.EMPLOYER_LISTING_REQUEST]: (state) => {
        return update(state, {
          employerListingLoading: { $set: true },
          employerListingFailure: { $set: false },
          employerListingSuccess: { $set: false },
        });
      },
      [employerActionTypes.EMPLOYER_LISTING_SUCCESS]: (
        state,
        { data: { data } }
      ) => {
        return update(state, {
          employerListingLoading: { $set: false },
          employerListingFailure: { $set: false },
          employerListingSuccess: { $set: true },
          employerListing: { $set: data },
        });
      },
      [employerActionTypes.EMPLOYER_LISTING_FAILURE]: (state) => {
        return update(state, {
          employerListingLoading: { $set: false },
          employerListingFailure: { $set: true },
          employerListingSuccess: { $set: false },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_REQUEST]: (state) => {
        return update(state, {
          employerJobSeekerListingLoading: { $set: true },
          employerJobSeekerListingFailure: { $set: false },
          employerJobSeekerListingSuccess: { $set: false },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_SUCCESS]: (
        state,
        { data }
      ) => {
        return update(state, {
          employerJobSeekerListingLoading: { $set: false },
          employerJobSeekerListingFailure: { $set: false },
          employerJobSeekerListingSuccess: { $set: true },
          employerJobSeekerListing: { $push: data.data },
          employerJobSeekerListCount: { $set: data.total_records },
          employerJobSeekerListingPageLimit: {
            $set: data.total_records
              ? Math.ceil(data.total_records / 20)
              : employerInitialState.listedJobsPageLimit,
          },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_FAILURE]: (state) => {
        return update(state, {
          employerJobSeekerListingLoading: { $set: false },
          employerJobSeekerListingFailure: { $set: true },
          employerJobSeekerListingSuccess: { $set: false },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_SEEKER_TOGGLE_STARRED]: (
        state,
        action
      ) => {
        const { jobSeekerId, userId, interested } = action.payload;
        const data = state.employerJobSeekerListing;
        const selectedJobSeekerIndex = data.findIndex(
          (jobseeker) => jobseeker.userId === jobSeekerId
        );
        if (interested) {
          data[selectedJobSeekerIndex]["starredBy"] = [
            ...(data[selectedJobSeekerIndex]["starredBy"] || []),
            userId,
          ];
        } else {
          data[selectedJobSeekerIndex]["starredBy"] = data[
            selectedJobSeekerIndex
          ]["starredBy"].filter((uid) => uid !== userId);
        }
        return update(state, {
          employerJobSeekerListing: { $set: data },
          forceUpdate: { $set: !state.forceUpdate },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_SEEKER_TOGGLE_REQUEST_RESUME]: (
        state,
        action
      ) => {
        const { uid, employerId } = action.payload;
        const data = state.employerJobSeekerListing;
        const selectedJobSeekerIndex = data.findIndex(
          (jobseeker) => jobseeker.uid === uid
        );
        data[selectedJobSeekerIndex]["resumeEmpIds"] = [
          ...(data[selectedJobSeekerIndex]["resumeEmpIds"] || []),
          employerId,
        ];
        return update(state, {
          employerJobSeekerListing: { $set: data },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_SEEKER_RESET]: (state) => {
        return update(state, {
          employerJobSeekerListingLoading: {
            $set: employerInitialState.employerJobSeekerListingLoading,
          },
          employerJobSeekerListingFailure: {
            $set: employerInitialState.employerJobSeekerListingFailure,
          },
          employerJobSeekerListingSuccess: {
            $set: employerInitialState.employerJobSeekerListingSuccess,
          },
          employerJobSeekerListing: {
            $set: employerInitialState.employerJobSeekerListing,
          },
          employerJobSeekerListingPageLimit: {
            $set: employerInitialState.employerJobSeekerListingPageLimit,
          },
          employerJobSeekerListCount: {
            $set: employerInitialState.employerJobSeekerListCount,
          },
          forceUpdate: { $set: employerInitialState.forceUpdate },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_DELETE_REQUEST]: (state) => {
        return update(state, {
          employerJobDeleteLoading: { $set: true },
          employerJobDeleteFailed: { $set: false },
          employerJobDeleteSuccess: { $set: false },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_DELETE_FAILED]: (state) => {
        return update(state, {
          employerJobDeleteLoading: { $set: false },
          employerJobDeleteFailed: { $set: true },
          employerJobDeleteSuccess: { $set: false },
        });
      },
      [employerActionTypes.EMPLOYER_JOB_DELETE_SUCCESS]: (state) => {
        return update(state, {
          employerJobDeleteLoading: { $set: false },
          employerJobDeleteFailed: { $set: false },
          employerJobDeleteSuccess: { $set: true },
        });
      },
      [employerActionTypes.EMPLOYER_UPDATE_RATING_STATUS]: (state) => {
        return update(state, {
          isEmployerRatingDone: { $set: true },
        });
      },

      //Scheduler Reducer
      [employerActionTypes.SCHEDULER_USER_DETAILS_UPDATE]: (state, action) => {
        return update(state, {
          schedulerUserDetails: { $set: action.payload },
        });
      },
      [employerActionTypes.SCHEDULER_GET_AVAILABILITY_LIST_SUCCESS]: (
        state,
        { availabilities }
      ) => {
        return update(state, {
          schedulerAvailableList: { $set: availabilities },
        });
      },
      [employerActionTypes.SCHEDULER_GET_UPCOMING_LIST_SUCCESS]: (
        state,
        { meetings }
      ) => {
        return update(state, {
          schedulerUpcomingMeetingList: { $set: meetings },
        });
      },
      [employerActionTypes.SCHEDULER_CREATE_AVAILABILITY_SUCCESS]: (
        state,
        { availabilities }
      ) => {
        return update(state, {
          schedulerAvailableList: { $set: availabilities },
        });
      },
      [employerActionTypes.SCHEDULER_GET_SHARE_LINK_SUCCESS]: (
        state,
        { shareLinkUrl }
      ) => {
        return update(state, {
          schedulerShareLink: { $set: shareLinkUrl },
        });
      },
      [employerActionTypes.EMPLOYER_FIRST_JOB_START_TIME]: (state, action) => {
        return update(state, {
          employerFirstJobStartTime: { $set: action.payload },
        });
      },
    },
    employerInitialState,
    employerUserDetailsInitialState,
    employerSchedulerInitialState
  ),
};

export default employerReducer;
