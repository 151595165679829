import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useIsMobile from "hooks/useIsMobile";

import LoginSelector from "containers/Login/selectors/LoginSelector";
import { logoutRequest } from "containers/Login/actions/LoginActions";

import ContinueLoginMobile from "./mobile/ChangePassword/ContinueLogin";
import ContinueLogin from "./web/ChangePassword/ContinueLogin";

import { EMPLOYER, JOB_SEEKER } from "../../appConstants/index";

const ContinueLoginContainer = () => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? ContinueLoginMobile : ContinueLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userType =
    useSelector(LoginSelector.getLoggedInUser)?.userType === EMPLOYER
      ? EMPLOYER
      : JOB_SEEKER;

  useEffect(() => {
    const handleLocationChange = () => {
      handleContinueLogin();
    };

    window.addEventListener("beforeunload", handleLocationChange);
    return () => {
      navigate(`/login/${userType}`);
      window.removeEventListener("beforeunload", handleLocationChange);
    };
  }, []);

  const handleContinueLogin = () => {
    dispatch(logoutRequest());
  };

  return <Wrapper handleContinueLogin={handleContinueLogin} />;
};

export default ContinueLoginContainer;
