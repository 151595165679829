import * as yup from "yup";

export const validationSchema = yup.object().shape({
  userName: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Enter your email"),
  password: yup.string().when("showPassword", {
    is: (showPassword) => showPassword,
    then: yup
      .string("Enter your password")
      .min(6, "Email or Password incorrect")
      .required("Enter your password"),
    otherwise: yup.string(),
  }),
});
