import { Box } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { styleUtils } from "../../../styles";

const BlogLandingBannerMobile = () => {
  return (
    <Box
      sx={{
        background: `linear-gradient(360deg, #2B4894DE 0%, #54545400 100%) 0% 0%/cover no-repeat padding-box, url(${images.blogsBackgroundMobile})`,
        opacity: 1,
        height: styleUtils.pxToRem("136px"),
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundSize: "cover",
      }}
    >
      <Text size={styleUtils.pxToRem("32px")} weight="bold" color="#FFFFFF">
        EmployD{" "}
        <Text
          size={styleUtils.pxToRem("32px")}
          weight="medium"
          color="#FFFFFF"
          component="span"
        >
          Blogs
        </Text>
      </Text>
    </Box>
  );
};

export default BlogLandingBannerMobile;
