import {
  Card,
  Divider,
  Radio,
  RadioGroup,
  Button,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import { CardModal, Text, TextInput } from "../../components/atoms";
import { REPORT_CATEGORIES } from "../../appConstants";
import { colors, spacing, styleUtils, textSizes, typography } from "../../styles";
import useIsMobile from "../../hooks/useIsMobile";
import { isEmpty } from "utils/commonUtils";
import { ChatConstants } from "./Constants";

const ReportModal = ({ isOpen, onClose, handleBlockAndReport }) => {
  const isMobile = useIsMobile();
  const validationSchema = yup.object().shape({
    category: yup.string().required("Category is required"),
    message: yup.string().when("category", {
      is: (value) => value === ChatConstants.CATEGORY_OTHER,
      then: yup.string().required("Reason is required"),
      otherwise: yup.string(),
    }),
  });

  const onSubmit = (values) => {
    handleBlockAndReport(values);
  };

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      message: "",
      category: "",
    },
    validationSchema,
    onSubmit,
  });

  const isDisabled = !values?.category || (values?.category === ChatConstants.CATEGORY_OTHER && isEmpty(values?.message));

  const onChangeRadio = (e) => {
    setFieldValue("category", e.target.value);
  };

  return (
    <CardModal open={isOpen}>
      <Card
        sx={{
          height: styleUtils.pxToRem("600px"),
          width: styleUtils.pxToRem(isMobile ? "352px" : "482px"),
          padding: spacing.s,
        }}
      >
        <Text
          color={colors.textPrimary}
          size="m"
          weight="medium"
          sx={{ px: spacing.s, py: spacing.xs }}
        >
          Tell us why you want to block this user
        </Text>
        <Divider />
        <div style={{ padding: spacing.s }}>
          <RadioGroup value={values.category} onChange={onChangeRadio}>
            {REPORT_CATEGORIES.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio style={{ color: colors.primary }} />}
                label={
                  <Text size="s" color={colors.blackText}>
                    {item.label}
                  </Text>
                }
                sx={{ marginBottom: spacing.xxs }}
              />
            ))}
          </RadioGroup>
          {Boolean(errors.category) && (
            <FormHelperText sx={{ color: colors.red, marginBottom: spacing.s }}>
              Category is required
            </FormHelperText>
          )}
          <TextInput
            multiline
            name="message"
            value={values.message}
            onChange={handleChange}
            error={Boolean(errors.message)}
            placeholder={
              values?.category !== ChatConstants.CATEGORY_OTHER ? 
              ChatConstants.PLACEHOLDER_BLOCK_USER_REASON : 
              ChatConstants.PLACEHOLDER_BLOCK_USER_REASON_REQUIRED
            }
            inputProps={{
              style: {
                height: styleUtils.pxToRem("119px"),
                width: styleUtils.pxToRem(isMobile ? "249px" : "422px"),
                overflow: "auto",
              },
            }}
            sx={{ border: "1px solid #E0E0E0", borderRadius: "10px" }}
          />
          {touched.message && Boolean(errors.message) && (
            <FormHelperText sx={{ color: colors.red, marginBottom: spacing.s }}>
              Reason is required
            </FormHelperText>
          )}
          <ButtonRow
            sx={{
              marginLeft: isMobile ? spacing.xxs : spacing.xxxl,
              marginTop: isMobile ? spacing.l : spacing.xxxl,
            }}
          >
            <ActionButton
              variant="outlined"
              onClick={onClose}
              sx={{ width: styleUtils.pxToRem(isMobile ? "124px" : "146px") }}
            >
              Cancel
            </ActionButton>
            <ActionButton
              variant={isDisabled ? "outlined" : "contained"}
              onClick={handleSubmit}
              sx={{
                marginLeft: isMobile ? spacing.xs : spacing.s,
                width: styleUtils.pxToRem(isMobile ? "124px" : "146px"),
              }}
              disabled={isDisabled}
            >
              Block
            </ActionButton>
          </ButtonRow>
        </div>
      </Card>
    </CardModal>
  );
};

ReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleBlockAndReport: PropTypes.func.isRequired,
};

export default ReportModal;

const ButtonRow = styled("div")`
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${spacing.s};
`;
const ActionButton = styled(Button)`
  font-weigt: ${typography.bold};
  font-size: ${textSizes.m};
`;
