import * as yup from "yup";
import { isValidPhoneNumber } from "../../../../utils/commonUtils";
import {
  EMPLOYER_SIGNUP_UI_ERROR_MESSAGES,
  REGISTRATION_FLOW_UI_ERROR_MESSAGES,
} from "./constants";

export const employerSchemaOne = yup.object({
  fullName: yup.string().required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_NAME_TEXT),
  email: yup
    .string()
    .email(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.EMAIL_INVALID)
    .required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_EMAIL_TEXT),
  phoneNumber: yup
    .string()
    .required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_PHONE_TEXT)
    .test(
      "validate-phone",
      EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.PHONE_INVALID,
      async (value, context) => {
        if (value) {
          return await isValidPhoneNumber({
            countryCode: context.parent.countryCode,
            phoneNumber: value,
          });
        }
        return true;
      },
    ),
  companyName: yup.string().required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_COMPANY_NAME_TEXT),
  //website
});

export const employerSchemaTwo = yup.object({
  employee_size: yup
    .string()
    .required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_EMPLOYEE_SIZE_TEXT),
  designation: yup.string().required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_DESIGNATION_TEXT),
  designationText: yup.string().when("designation", {
    is: (designation) => designation === "Other",
    then: yup.string().required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_DESIGNATION_TEXT),
  }),
  multiple_location: yup
    .boolean()
    .nullable()
    .required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_MULTIPLE_LOCATION_TEXT),
  password: yup
    .string()
    .min(6, EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.PASSWORD_MIN)
    .required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_PASSWORD_TEXT),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.PASSWORD_DO_NOT_MATCH)
    .required(EMPLOYER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_CONFIRM_PASSWORD_TEXT),
});

export const employerRegistrationSchema = yup.object({
  fullName: yup.string().required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_EMPLOYER_NAME_TEXT),
  email: yup
    .string()
    .email(REGISTRATION_FLOW_UI_ERROR_MESSAGES.EMAIL_INVALID)
    .required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_EMAIL_TEXT),
  companyName: yup
    .string()
    .required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_COMPANY_NAME_TEXT),
  designation: yup.string().required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_DESIGNATION_TEXT),
  designationText: yup.string().when("designation", {
    is: (designation) => designation === "Other",
    then: yup
      .string()
      .required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_OTHER_DESIGNATION_TEXT),
  }),
  employee_size: yup
    .string()
    .required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_EMPLOYEE_SIZE_TEXT),
  multiple_location: yup
    .boolean()
    .nullable()
    .required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_MULTIPLE_LOCATION_TEXT),
  phoneNumber: yup
    .string()
    .required(REGISTRATION_FLOW_UI_ERROR_MESSAGES.REQUIRED_PHONE_TEXT)
    .test(
      "validate-phone",
      REGISTRATION_FLOW_UI_ERROR_MESSAGES.PHONE_INVALID,
      async (value, context) => {
        if (value) {
          return await isValidPhoneNumber({
            countryCode: context.parent.countryCode,
            phoneNumber: value,
          });
        }
        return true;
      },
    ),
});
