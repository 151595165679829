import React, { useState } from "react";
import { Box, Button, Divider, Drawer, MenuItem, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { jobSeekerListingRequest } from "../../../../actions/JobSeekerActions";
import { userSettingsUpdate } from "../../../../../../containers/Login/actions/LoginActions";
import LoginSelector from "containers/Login/selectors/LoginSelector";

import { Icon, MultiCheckbox, Text } from "../../../../../../components/atoms";

import {
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../../../../styles";
import { colors } from "../../../../../../styles/colors";
import { bottomFilterValueSeparator } from "../FilterConstants";
import useIsMobile from "hooks/useIsMobile";

const BottomDrawerFilter = ({ filterType, filterUtil, filterValues }) => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useIsMobile();

  const filterTitle = filterValues.filterOptions[filterType].title;
  const filterByTitle = filterValues.filterOptions[filterType].filterBy;
  const selectedFilterOption = filterValues.filterOptions[filterType].option;
  const selectedFilterUnit = filterValues.filterOptions[filterType].unit;
  const selectedFilterValueKey =
    filterValues.filterOptions[filterType].valueKey;
  const searchLocation = useSelector(LoginSelector.getSearchLocation);
  const userLocation = useSelector(
    LoginSelector.getLoggedInUserCurrentLocation
  );

   const searchLatValue = searchLocation?.lat
    ? searchLocation.lat
    : userLocation?.lat;

  const searchLngValue = searchLocation?.lng
    ? searchLocation.lng
    : userLocation?.lng;

  const {
    distance,
    wage,
    hrsPerWeek,
    experience,
    days,
    timeSlot,
    selectedValues,
    onChangeDistance,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeDays,
    onChangeTimeslots,
    onApply,
  } = filterUtil;

  const changeHandlers = {
    distance: {
      value: distance,
      change: onChangeDistance,
    },
    hourlyWage: {
      value: wage,
      change: onChangeHourlyWages,
    },
    hoursPerWeek: {
      value: hrsPerWeek,
      change: onChangeHoursPerWeek,
    },
    experience: {
      value: experience,
      change: onChangeExperience,
    },
    days: {
      value: days,
      change: onChangeDays,
    },
    timeSlot: {
      value: timeSlot,
      change: onChangeTimeslots,
    },
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleApply = () => {
    onApply();
    handleDrawerClose();
  };

  const handleReset = () => {
    changeHandlers[filterType].change([]);
    dispatch(
      jobSeekerListingRequest({
        ...selectedValues,
        [selectedFilterValueKey]: [],
        page: 1,
        totalRecords: 0,
        source: isMobile? "resp" : "web",
      })
    );
    handleDrawerClose();
  };

  const onDistanceApply = (value) => () => {
    onChangeDistance(value);
    dispatch(
      jobSeekerListingRequest({
        distance: value,
        wage,
        experience,
        hrsPerWeek,
        days,
        timeSlot,
        page: 1,
        totalRecords: 0,
        locationString:searchLocation?.locationName,
        searchedLat: searchLatValue,
        searchedLng: searchLngValue,
        source: isMobile? "resp" : "web",
      })
    );
    dispatch(
      userSettingsUpdate("jobSeekerListingFilter", {
        distance: value,
        wage,
        hrsPerWeek,
        experience,
        days,
        timeSlot,
      })
    );
    handleDrawerClose();
  };

  const renderDistanceFilter = (item) => (
    <DistanceOption
      sx={{
        backgroundColor: item?.value === distance ? "#F2F5FF" : colors.white,
      }}
    >
      <Text sx={{ padding: `0 ${spacing.l}` }}>{item.label}</Text>
      {item?.value === distance && <Icon name="select-done-option" size={21} />}
    </DistanceOption>
  );

  const renderMultiFilters = (
    <>
      <MultiCheckbox
        selectOptions={filterValues[selectedFilterOption]}
        prevSelectedOptions={changeHandlers[filterType].value}
        handleSelect={changeHandlers[filterType].change}
        sx={{
          marginRight: spacing.xxl,
          marginLeft: spacing.xs,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        labelSx={{ padding: `0 ${spacing.s} ${spacing.s}`, flex: "0 48%" }}
      />
      <ButtonRow>
        <ActionButton variant="outlined" onClick={handleReset}>
          Clear
        </ActionButton>
        <ActionButton
          variant="contained"
          onClick={handleApply}
          sx={{ marginLeft: styleUtils.pxToRem("18px") }}
        >
          Apply
        </ActionButton>
      </ButtonRow>
    </>
  );

  const filterContent =
    filterType === "distance"
      ? filterValues[selectedFilterOption].map((selectedFilter) => {
          return (
            <FilterMenuItem
              key={selectedFilter.id}
              onClick={onDistanceApply(selectedFilter.value)}
            >
              {renderDistanceFilter(selectedFilter)}
            </FilterMenuItem>
          );
        })
      : renderMultiFilters;

  const appliedFilterValue = () => {
    if (changeHandlers[filterType].value.length > 0) {
      return changeHandlers[filterType].value.length > 2 ? (
        <>
          <FilterValueText size="xs" weight="bold" color="#2B4894">
            {`${
              changeHandlers[filterType].value[0] === "all"
                ? "All"
                : changeHandlers[filterType].value[0]
            }${selectedFilterUnit}`}
          </FilterValueText>
          <FilterValueText size="xs" weight="bold" color="#2B4894">
            {`${changeHandlers[filterType].value[1]}${selectedFilterUnit}`}
          </FilterValueText>
          <FilterValueText size="xs" weight="bold" color="#2B4894">
            {`+${changeHandlers[filterType].value.length - 2}`}
          </FilterValueText>
        </>
      ) : (
        changeHandlers[filterType].value.map((filterValue) => (
          <FilterValueText
            key={filterValue}
            size="xs"
            weight="bold"
            color="#2B4894"
          >
            {`${filterValue}${selectedFilterUnit}`}
          </FilterValueText>
        ))
      );
    }
    return null;
  };

  const distanceValueText = (
    <>
      {bottomFilterValueSeparator}
      <FilterValueText size="xs" weight="bold" color="#2B4894">
        {`Within ${changeHandlers[filterType].value}${selectedFilterUnit}`}
      </FilterValueText>
    </>
  );

  const MultiFilterValueText = (
    <>
      {changeHandlers[filterType].value.length > 0 &&
        bottomFilterValueSeparator}
      {appliedFilterValue()}
    </>
  );

  return (
    <>
      <Grid
        item
        xs="auto"
        sm="auto"
        md="auto"
        key={filterType}
        sx={{
          marginLeft: filterType === "distance" ? spacing.xs : 0,
          marginRight: styleUtils.pxToRem("8px"),
        }}
      >
        <OptionButton
          onClick={handleDrawerOpen}
          variant="outlined"
          endIcon={
            <Icon size={10} name="arrow-down" color={colors.lightGray} />
          }
        >
          {`${filterTitle} `}
          {filterType === "distance" ? distanceValueText : MultiFilterValueText}
        </OptionButton>

        <Drawer
          sx={{
            width: "100%",
            "& .MuiDrawer-paper": {
              borderWidth: 0,
              borderRadius: 0,
              width: "100%",
              backgroundColor: colors.white,
              padding: `${spacing.l} 0`,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            },
          }}
          anchor="bottom"
          open={drawerOpen}
          onClose={handleDrawerClose}
        >
          <Row>
            <Text size="m" weight="bold">
              {`${filterByTitle} `}
            </Text>
          </Row>
          <Divider sx={{ mb: spacing.xs }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "auto",
            }}
          >
            {filterContent}
          </Box>
        </Drawer>
      </Grid>
    </>
  );
};

export default BottomDrawerFilter;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.s};
  margin-left: ${spacing.xl};
`;
const OptionButton = styled(Button)(() => ({
  border: "1px solid #E8E8E8",
  color: colors.textPrimary,
  marginRight: spacing.xs,
  width: "auto",
  " &:hover": {
    backgroundColor: colors.white,
    color: colors.textPrimary,
    borderColor: "#DADADA",
    "& .MuiTypography-root": {
      color: "#2B4894",
    },
  },
}));
const FilterMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
  },
  padding: 0,
}));
const FilterValueText = styled(Text)(() => ({
  border: "1px solid #2B4894",
  borderRadius: styleUtils.pxToRem("22px"),
  backgroundColor: "#F2F6FF",
  lineHeight: styleUtils.pxToRem("16px"),
  marginLeft: spacing.xxs,
  padding: `${spacing.xxs} ${spacing.xs} ${spacing.xxs} ${spacing.xs}`,
  textAlign: "center",
  " &:hover": {
    backgroundColor: "#F2F6FF",
    color: "#2B4894",
  },
}));
const DistanceOption = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${spacing.xs};
  width: 100%;
`;
const ButtonRow = styled("div")`
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing.s};
  padding-left: ${spacing.m};
  padding-right: ${spacing.s};
  width: 100%;
`;
const ActionButton = styled(Button)`
  font-weigt: ${typography.bold};
  font-size: ${textSizes.m};
  height: ${styleUtils.pxToRem("40px")};
  width: ${styleUtils.pxToRem("146px")};
`;
