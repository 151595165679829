import { useDispatch, useSelector } from "react-redux";
import { Button, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { CardModal, Text } from "../../../../components/atoms";
import { logoutRequest } from "../../../Login/actions/LoginActions";
import { spacing } from "../../../../styles";
import { showNotification } from "../../../../utils/commonUtils";
import { logEvent } from "../../../../configs/firebase";
import LoginSelector from "../../../Login/selectors/LoginSelector";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const LogoutView = ({ islogoutModalOpen, hideLogoutModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const onLogout = () => {
    dispatch(logoutRequest());
    navigate("/");
    logEvent(
      loggedInUser?.userType === "employer" ? FirebaseEvents.EMP_LOGOUT : "",
      {
        items: [{ status: "success" }],
      }
    );
    showNotification({
      alertProps: {
        severity: "info",
        children: "You are logged out.",
      },
    });
  };

  const onCancel = () => {
    hideLogoutModal();
  };

  return (
    <CardModal open={islogoutModalOpen} onClose={hideLogoutModal}>
      <Card
        sx={{
          width: 388,
          height: 206,
          py: spacing.l,
          px: spacing.l,
          borderRadius: 5,
        }}
      >
        <Text style={{ textAlign: "center" }} size="l">
          Are you sure <br /> you want to logout?
        </Text>
        <Row sx={{ marginTop: spacing.xxxl }}>
          <Button onClick={onLogout}>Yes</Button>
          <Button variant="contained" onClick={onCancel} sx={{ ml: spacing.s }}>
            No
          </Button>
        </Row>
      </Card>
    </CardModal>
  );
};

export default LogoutView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.s};
`;
