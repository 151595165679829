import { takeEvery, put, all, call } from "redux-saga/effects";

import { userDataDetails } from "configs/network/apis";
import * as CommonActionsTypes from "../../Common/actions/commonActionTypes"

function* handleUserUpdatedDetail(action){
  const { payload } = action;
  try {
    const { data } = yield call(userDataDetails, payload);
    let userData = data.user;
    yield put({
      type: CommonActionsTypes.USER_DETAIL_SUCCESS,
      userData,
    });
  } catch (error) {
    //No code here
  }
}



function* watchUserUpdatedDetailSaga(){
  yield takeEvery(CommonActionsTypes.USER_DETAIL, handleUserUpdatedDetail);
}

export function* userSaga(){
  yield all([
    watchUserUpdatedDetailSaga(),
  ])
};