import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Badge, Button, Divider, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text, Icon, Dropdown, Accordion, MultiCheckbox } from "../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../styles";

import {
  distanceFilter,
  hourlyWageFilter,
  hoursPerWeekFilter,
  daysFilter,
  timeSlotFilter,
  experienceFilter,
} from "../FilterConstants";
import { FilterCount } from "components/molecules";
import { getFilterCount } from "utils/commonUtils";

const Filter = ({ filterUtil, activeFiltersCount }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    distance,
    wage,
    hrsPerWeek,
    experience,
    days,
    timeSlot,
    onChangeDistance,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeDays,
    onChangeTimeslots,
    onApply,
    onReset,
  } = filterUtil;

  const handleApply = () => {
    onApply();
    handleDrawerClose();
  };

  const handleReset = () => {
    onReset();
    handleDrawerClose();
  };

  return (
    <>
      <FilterButton
        variant="outlined"
        onClick={handleDrawerOpen}
        startIcon={
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={activeFiltersCount}
            sx={{
              "& .MuiBadge-badge": {
                borderRadius: "50%",
                height: "15px",
                minWidth: "15px",
                padding: 0,
                right: "2%",
              },
            }}
          >
            <Icon size={17} name="filter-by" />
          </Badge>
        }
      >
        Filter
      </FilterButton>
      <Drawer
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            borderRadius: 0,
            width: "80%",
            backgroundColor: colors.white,
            padding: `${spacing.l} ${spacing.m}`,
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Row>
          <div style={{ display: "flex" }}>
            <Text size="l" weight="bold">
              Filter By
            </Text>
            <FilterText weight="bold" color={colors.white} size="s">
              {activeFiltersCount}
            </FilterText>
          </div>

          <Icon
            name="close"
            size={14}
            color={colors.black}
            onClick={handleDrawerClose}
            style={{ cursor: "pointer" }}
          />
        </Row>
        <Divider sx={{ mb: spacing.s }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
            pr: spacing.xs,
          }}
        >
          <div style={{ display: "flex" }}>
            <Text weight="semibold" sx={{ mb: spacing.xs }}>
              Distance
            </Text>
            <FilterCount count={1} />
          </div>

          <Dropdown
            sx={{ height: 40 }}
            value={distance}
            onChange={onChangeDistance}
            options={distanceFilter}
          />
          <Divider sx={{ mt: spacing.s }} />
          <Accordion
            title="Hourly Wages"
            headerRightContent={<FilterCount count={getFilterCount(wage)} />}
            content={
              <MultiCheckbox
                selectOptions={hourlyWageFilter}
                prevSelectedOptions={wage}
                handleSelect={onChangeHourlyWages}
              />
            }
          />
          <Divider />
          <Accordion
            title="Hours/week"
            headerRightContent={<FilterCount count={getFilterCount(hrsPerWeek)} />}
            content={
              <MultiCheckbox
                selectOptions={hoursPerWeekFilter}
                prevSelectedOptions={hrsPerWeek}
                handleSelect={onChangeHoursPerWeek}
              />
            }
          />
          <Divider />
          <Accordion
            title="Experience"
            headerRightContent={<FilterCount count={getFilterCount(experience)} />}
            content={
              <MultiCheckbox
                selectOptions={experienceFilter}
                prevSelectedOptions={experience}
                handleSelect={onChangeExperience}
              />
            }
          />
          <Divider />
          <Accordion
            title="Days/Week"
            headerRightContent={<FilterCount count={getFilterCount(days)} />}
            content={
              <MultiCheckbox
                selectOptions={daysFilter}
                prevSelectedOptions={days}
                handleSelect={onChangeDays}
              />
            }
          />
          <Divider />
          <Accordion
            title="Shift"
            headerRightContent={<FilterCount count={getFilterCount(timeSlot)} />}
            content={
              <MultiCheckbox
                selectOptions={timeSlotFilter}
                prevSelectedOptions={timeSlot}
                handleSelect={onChangeTimeslots}
              />
            }
          />
        </Box>
        <Divider />
        <ButtonRow>
          <Button
            variant="outlined"
            sx={{
              marginRight: spacing.xs,
              height: "28px",
            }}
            onClick={handleReset}
          >
            Reset Filter
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "28px",
            }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </ButtonRow>
      </Drawer>
    </>
  );
};

Filter.propTypes = {
  filterUtil: PropTypes.func.isRequired,
  activeFiltersCount: PropTypes.number,
};

export default Filter;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.xs};
`;
const FilterButton = styled(Button)`
  border-color: ${colors.filterBadgeBlue};
  background-color: #f2f6ff;
  margin-left: ${spacing.xs};
  width: auto;
`;
const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const FilterText = styled(Text)`
  background-color: ${colors.filterBadgeBlue};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: ${spacing.xxs};
  margin-top: ${spacing.xxs};
`;
