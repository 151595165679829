import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import { Icon, Text } from "../../../../components/atoms";
import { colors, spacing } from "../../../../styles";
import LoginSelector from "../../../Login/selectors/LoginSelector";

import { renderImage } from "../../../../utils/imageUtils";
import {
  CHAT_PAGE_ROUTE,
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  PEOPLE_PAGE_ROUTE,
  POST_NEW_JOB_PAGE_ROUTE,
  EMPLOYER_PROFILE_PAGE_ROUTE,
} from "../../../../routes/routeConstants";
import { EmployerAuthenticatedNavConstants } from "../../NavBarConstants";

import LogoutView from "./LogoutView";
import "./AuthenticatedNav.css";
import NavItem from "../../../../components/molecules/NavItem";

const EmployerAuthenticatedNav = ({ chatBadgeCount }) => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const navigate = useNavigate();
  const data = {
    image: loggedInUser?.photoURL,
    defaultImage: "job",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = () => {
    navigate({
      pathname: `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_PROFILE_PAGE_ROUTE}`,
    });
    setAnchorEl(null);
  };

  const showLogoutModal = () => {
    handleClose();
    setIsLogoutModalOpen(true);
  };

  const hideLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  return (
    <>
      <StyledNavLink to={`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`}>
        {({ isActive }) => (
          <NavItem
            icon="application"
            text={EmployerAuthenticatedNavConstants.JOBS_TAB}
            isActive={isActive}
          />
        )}
      </StyledNavLink>

      <StyledNavLink to={`${EMPLOYER_PAGE_ROUTE}/${PEOPLE_PAGE_ROUTE}`}>
        {({ isActive }) => (
          <NavItem
            icon="job-seeker"
            text={EmployerAuthenticatedNavConstants.PEOPLE_TAB}
            isActive={isActive}
          />
        )}
      </StyledNavLink>

      <StyledNavLink to={CHAT_PAGE_ROUTE}>
        {({ isActive }) => (
          <NavItem
            icon="comment-o"
            text={EmployerAuthenticatedNavConstants.CHAT}
            isActive={isActive}
            hasBadge
            badgeContent={chatBadgeCount}
          />
        )}
      </StyledNavLink>

      <StyledNavLink to={`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`}>
        <Button
          variant="contained"
          startIcon={
            <Icon
              size={20}
              name="plus-white"
              style={{ marginRight: spacing.xxs }}
            />
          }
          sx={{ marginLeft: spacing.s, marginRight: spacing.s }}
        >
          {EmployerAuthenticatedNavConstants.POST_NEW_JOB_TAB}
        </Button>
      </StyledNavLink>
      <UserButton variant="text" onClick={handleClick}>
        <Avatar
          src={renderImage(data?.image, data.defaultImage)}
          sx={{ marginRight: spacing.xs, width: 44, height: 44 }}
        >
          <Icon name="user" />
        </Avatar>
        <Text weight="semibold" style={{ marginRight: spacing.xs }}>
          {loggedInUser?.name || loggedInUser?.fullName}
        </Text>
        <Icon size={10} name="arrow-down" />
      </UserButton>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          border: `1px solid ${colors.primary}`,
        }}
      >
        <LoginMenuItem
          className="authentication-nav-menu-item"
          onClick={onClickMenuItem}
        >
          <Icon name="user" />
          <LoginMenuItemText size="s" weight="medium">
            {EmployerAuthenticatedNavConstants.MY_PROFILE}
          </LoginMenuItemText>
        </LoginMenuItem>
        <LoginMenuItem
          className="authentication-nav-menu-item"
          onClick={showLogoutModal}
        >
          <Icon name="login" />
          <LoginMenuItemText size="s" weight="medium">
            {EmployerAuthenticatedNavConstants.LOGOUT}
          </LoginMenuItemText>
        </LoginMenuItem>
      </Menu>
      <LogoutView
        islogoutModalOpen={isLogoutModalOpen}
        hideLogoutModal={hideLogoutModal}
      />
    </>
  );
};

export default EmployerAuthenticatedNav;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${spacing.s};
`;

const UserButton = styled(Button)`
  box-shadow: 0px 0px 0px;
  border-width: 0px;
  width: auto;
`;

const LoginMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.primary,
  },
  minWidth: 160,
  padding: `${spacing.xs} ${spacing.s}`,
  marginBottom: spacing.xxs,
}));

const LoginMenuItemText = styled(Text)`
  margin-left: ${spacing.s};
`;
