import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Icon, Text } from "../../../components/atoms";
import { getBlogPostBySlugApi } from "../../../configs/network/apis";
import useIsMobile from "../../../hooks/useIsMobile";
import { styleUtils } from "../../../styles";
import {
  getSanitizedHtml,
  hideLoader,
  showLoader,
} from "../../../utils/commonUtils";
import { CATEGORY_COLORS } from "../blogConstants";
import BlogPostFooter from "./BlogPostFooter";

const BlogPost = () => {
  const { slug } = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [post, setPost] = useState(location.state ?? {});

  let { title, content, image, authorName, date, category } = post;
  document.title = title;

  const onClickBack = () => {
    navigate("/blogs");
  };

  const fetchBlogPost = async () => {
    showLoader();
    const res = await getBlogPostBySlugApi({ slug });
    let {
      title,
      content,
      featured_image: image,
      author: { name: authorName },
      date,
    } = res;
    category = Object.keys(res?.categories)?.[0];
    setPost({ title, content, image, authorName, date, category });
    hideLoader();
  };

  /**
   * Fetch blog post when its opened by blog link directly
   */
  useEffect(() => {
    if (!content) {
      fetchBlogPost();
    }
  }, [content]);

  /**
   * To show empty when blog post is being fetched
   * TODO: Replace with a Skeleton
   */
  if (isEmpty(post)) {
    return null;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      {/* backgroundimage and ismobile are smallcase because of custom attribute restriction of styled */}
      {/* "+isMobile" makes it a number because boolean arguments are not supported. Usage doesnt change. */}
      <StyledHeaderContainer backgroundimage={image} ismobile={+isMobile}>
        <StyledHeader ismobile={+isMobile}>
          <Icon
            name="arrow-back"
            size={isMobile ? 47 : 54}
            style={{
              display: "block",
              cursor: "pointer",
            }}
            onClick={onClickBack}
          />
          <StyledCategoryBadge category={category} ismobile={+isMobile}>
            <Text disableScroll size={isMobile ? "xxs" : "l"} weight="semibold">
              {category}
            </Text>
          </StyledCategoryBadge>
          <StyledTitle
            ismobile={+isMobile}
            dangerouslySetInnerHTML={{ __html: getSanitizedHtml(title) }}
          />
          <StyledDateAuthorContainer>
            <StyledDate>
              <Icon
                name="blog-date"
                size={15.9}
                color="#FFFFFF"
                style={{
                  marginRight: styleUtils.pxToRem("5px"),
                  marginTop: styleUtils.pxToRem("-2px"),
                }}
              />
              <Text size={isMobile ? "s" : "l"} color="#FFFFFF">
                {date && moment(date).format("MMM DD YYYY")}
              </Text>
            </StyledDate>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginX: isMobile ? "12.5px" : "20px",
                borderColor: "#E4E3E3",
              }}
            />
            <StyledAuthor>
              <Icon
                name="blog-author"
                size={17.6}
                color="#FFFFFF"
                style={{
                  marginRight: styleUtils.pxToRem("5px"),
                  marginTop: styleUtils.pxToRem("-2px"),
                }}
              />
              <Text size={isMobile ? "s" : "l"} color="#FFFFFF">
                {authorName}
              </Text>
            </StyledAuthor>
          </StyledDateAuthorContainer>
        </StyledHeader>
      </StyledHeaderContainer>
      <StyledContentContainer ismobile={+isMobile}>
        <StyledContent
          variant="body2"
          component="p"
          dangerouslySetInnerHTML={{ __html: getSanitizedHtml(content) }}
          ismobile={+isMobile}
        />
        <BlogPostFooter isMobile={isMobile} />
      </StyledContentContainer>
    </div>
  );
};

export default BlogPost;

const StyledHeaderContainer = styled("div")(
  ({ backgroundimage, ismobile }) => ({
    background: `linear-gradient(360deg, #2B4894DE 0%, #54545400 100%) 0% 0% no-repeat padding-box, url(${backgroundimage})`,
    opacity: 1,
    height: ismobile
      ? styleUtils.pxToRem("381px")
      : styleUtils.pxToRem("434px"),
    backgroundSize: "cover",
  })
);

const StyledTitle = styled(Typography)(({ ismobile }) => ({
  fontSize: ismobile ? styleUtils.pxToRem("24px") : styleUtils.pxToRem("28px"),
  fontWeight: 600, // semi-bold
  color: "#ffffff",
  textAlign: "left",
  lineHeight: 1.2,
  marginTop: ismobile ? styleUtils.pxToRem("24px") : styleUtils.pxToRem("32px"),
}));

const StyledHeader = styled("div")(({ ismobile }) => ({
  display: "flex",
  flexDirection: "column",
  height: "inherit",
  marginLeft: ismobile
    ? styleUtils.pxToRem("30px")
    : styleUtils.pxToRem("180px"),
  marginRight: ismobile
    ? styleUtils.pxToRem("38px")
    : styleUtils.pxToRem("150px"),
  paddingTop: ismobile
    ? styleUtils.pxToRem("40px")
    : styleUtils.pxToRem("84px"),
}));

const StyledCategoryBadge = styled("div")(({ category, ismobile }) => ({
  backgroundColor: CATEGORY_COLORS[category],
  borderRadius: 99,
  width: "fit-content",
  padding: ismobile ? "4px 14px 2px 15px" : "7px 26px 7px 26px",
  marginTop: ismobile ? styleUtils.pxToRem("99px") : styleUtils.pxToRem("54px"),
}));

const StyledDateAuthorContainer = styled("div")({
  marginTop: styleUtils.pxToRem("25px"),
  display: "flex",
  flexDirection: "row",
});

const StyledDate = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledAuthor = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledContentContainer = styled("div")(({ ismobile }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  ...(!ismobile && { maxWidth: styleUtils.pxToRem("862px") }),
  marginLeft: ismobile
    ? styleUtils.pxToRem("30px")
    : styleUtils.pxToRem("180px"),
  marginRight: ismobile
    ? styleUtils.pxToRem("30px")
    : styleUtils.pxToRem("150px"),
  paddingTop: styleUtils.pxToRem("16px"),
  paddingBottom: ismobile
    ? styleUtils.pxToRem("33px")
    : styleUtils.pxToRem("53px"),
}));

const StyledContent = styled(Typography)(({ ismobile }) => ({
  fontSize: ismobile ? styleUtils.pxToRem("16px") : styleUtils.pxToRem("22px"),
  color: "#4C5468",
  lineHeight: 1.7,
  "& .wp-caption-text": { display: "none" },
  "& .wp-block-heading": {
    fontSize: ismobile
      ? styleUtils.pxToRem("24px")
      : styleUtils.pxToRem("32px"),
    paddingBottom: ismobile
      ? styleUtils.pxToRem("18px")
      : styleUtils.pxToRem("24px"),
    paddingTop: ismobile
      ? styleUtils.pxToRem("32px")
      : styleUtils.pxToRem("38px"),
  },
  "& .wp-block-heading:first-child": {
    paddingTop: 0,
  },
  "& img": {
    maxWidth: ismobile
      ? styleUtils.pxToRem("301px")
      : styleUtils.pxToRem("862px"),
    height: "auto",
  },
  "& a": { color: "#199DFF", textDecoration: "none" },
  "& ol, ul": { paddingInlineStart: "20px" },
  "& ol,ul,p,.wp-block-image": {
    marginBottom: ismobile
      ? styleUtils.pxToRem("32px")
      : styleUtils.pxToRem("36px"),
  },
}));
