import PropTypes from "prop-types";
import { Button, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import Filter from "./Filter";
import BottomDrawerFilter from "./BottomDrawerFilter";

import useJobseekerListingFilter from "../../../../../../hooks/filters/useJobseekerListingFilter";

import { spacing, styleUtils, textSizes, typography } from "../../../../../../styles";
import * as FilterConstants from "../FilterConstants";
import { activeFiltersCount } from "utils/commonUtils";

/**
 * Filter Wrapper for dropdown filters and horizontal individual filter options
 */
const ResponsiveFilterWrapper = ({ filterSettings, handleFilter }) => {
  const filterTypes = Object.keys(FilterConstants.filterOptions);
  const filterUtil = useJobseekerListingFilter(filterSettings, handleFilter);
  const totalFilterCount = activeFiltersCount(filterSettings) + 1;
  // Adding 1 for default distance filter

  const { onReset } = filterUtil;

  return (
    <>
      <Grid item xs="auto" sm="auto" md="auto">
        <Filter filterUtil={filterUtil} activeFiltersCount={totalFilterCount} />
      </Grid>
      {filterTypes?.map((filterType) => (
        <BottomDrawerFilter
          key={filterType}
          filterType={filterType}
          filterUtil={filterUtil}
          filterValues={FilterConstants}
        />
      ))}
      <Grid item xs="auto" sm="auto" md="auto" sx={{ height: styleUtils.pxToRem("36px") }}>
        <Divider orientation="vertical" sx={{ borderColor: "#E8E8E8" }} />
      </Grid>
      <Grid item xs="auto" sm="auto" md="auto">
        <ClearAllButton variant="text" onClick={onReset}>
          Clear All
        </ClearAllButton>
      </Grid>
    </>
  );
};

ResponsiveFilterWrapper.propTypes = {
  filterSettings: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default ResponsiveFilterWrapper;

const ClearAllButton = styled(Button)`
  border: none;
  color: #2b4894;
  font-size: ${textSizes.xs};
  font-weight: ${typography.bold};
  margin-left: ${spacing.xs};
  width: auto;
`;
