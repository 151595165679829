import PropTypes from "prop-types";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../../../../components/atoms";
import { PeopleDetailCard } from "../../../../../../../components/molecules";
import { spacing } from "../../../../../../../styles";
import useScrollYAnimationResp from "hooks/useScrollYAnimationResp";

const PeopleDescription = ({ handleMarkStarCallback }) => {
  const navigate = useNavigate();
  const { y: yPos } = useScrollYAnimationResp();

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <BackButtonContainer
        sx={{
          position: yPos > 45 ? "sticky" : "",
          top: 0,
          zIndex: 1100,
        }}
      >
        <Icon
          name="arrow-back-black"
          style={{ marginLeft: spacing.s }}
          size={24}
          onClick={onClickBack}
        />
      </BackButtonContainer>
      <PeopleDetailCard handleMarkStarCallback={handleMarkStarCallback} />
    </>
  );
};

export default PeopleDescription;

PeopleDescription.propTypes = {
  handleMarkStarCallback: PropTypes.func.isRequired,
};

const BackButtonContainer = styled("div")`
  display: flex;
  height: 55px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 5px 15px #2b48951a;
  width: 100%;
`;
