import { Button, Container } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";

const ChatDirectly = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        my: spacing.xxxxl,
      }}
    >
      <Text size={32} style={{ lineHeight: 1.2 }}>
        <Text
          size={32}
          component="span"
          weight="extrabold"
          color={colors.green}
          style={{ lineHeight: 1.2 }}
        >
          Chat
        </Text>{" "}
        directly
      </Text>
      <Text size={32} style={{ lineHeight: 1.2 }}>
        with job-seekers
      </Text>
      <Text color={colors.placeholder} style={{ marginTop: spacing.s }}>
        No waiting around, start chatting with job seekers immediately &amp;
        know more about them.
      </Text>
      {!isAuthenticated && (
        <Button
          variant="contained"
          sx={{
            mt: spacing.xxl,
            width: "fit-content",
            px: spacing.xxl,
            py: spacing.xs,
          }}
          onClick={onGetStarted}
        >
          Get Started
        </Button>
      )}
      <img
        src={images.home["employer-hero"]["4"]}
        alt="4"
        style={{ width: "100%", marginTop: spacing.xl }}
      />
    </Container>
  );
};

export default ChatDirectly;
