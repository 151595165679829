export const EMPLOYER_KEY = "employer";
export const LOGIN_OTP_KEY = "OL";
export const LOGIN_WITH_OTP_SUCCESS_MSG = "You are logged in successfully";
export const LOGIN_OTP_RESEND_SUCCESS = "Otp reshared successfully";
export const LOGIN_OTP_RESEND_FAILED =
  "Otp resharing failed. PleaseTry Again!!";
export const EMPTY_LOGIN_OTP_SRC_ERR_MSG =
  "Please provide either email or phone number as login source";
export const UNREGISTERED_LOGIN_OTP_SRC_ERR_MSG =
  "Please sign-up before logging in";
export const LOGIN_OTP_FORM_HEADER = "Login via Phone Number";
export const LOGIN_OTP_FORM_HEADER_SUBTEXT =
  "We'll send a verification code to your registered phone number";
export const LOGIN_OTP_FORM_EMAIL_LABEL = "Enter Email to receive OTP";
export const LOGIN_OTP_FORM_PHONE_LABEL = "Phone Number";
export const LOGIN_OTP_FORM_SIGNUP_LINK_TEXT = "Sign-up here";
export const LOGIN_OTP_FORM_NEXT_BTN = "Next";

export const LOGIN_OTP_VERIFICAION_FORM_HEADER =
  "We’ve sent you verification code";
export const LOGIN_OTP_VERIFICAION_FORM_HEADER_SUBTEXT =
  "Please enter the six-digit verification code we've sent on your phone number.";
export const LOGIN_OTP_VERIFICATION_FORM_INPUT_LABEL =
  "Enter verification code";
export const LOGIN_OTP_VERIFICATION_FORM_INPUT_PLACEHOLDER =
  "enter 6 digit code here";
export const LOGIN_OTP_VERIFICATION_INVALID_OTP_MSG =
  "Please enter a valid otp";
export const LOGIN_OTP_VERIFICATION_FORM_RESEND_LABEL = "Resend code";
export const LOGIN_OTP_FORM_LOGIN_BTN = "Login";

export const LOGIN_OTP_USER_FORM_DETAILS = {
  email: "",
  phone: "",
};

export const LOGIN_OTP_VERIFY_DETAILS = {
  loginOtp: "",
};
