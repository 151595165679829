import { Avatar, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text } from "../../atoms";
import { renderImage } from "../../../utils/imageUtils";
import { colors, spacing } from "../../../styles";
import { images } from "../../../assets/images";

const MapCard = ({ item, cardStyle, onMapJobCardSelect }) => {
  return (
    <Card
      sx={{ maxWidth: "230px", ...cardStyle }}
      onClick={() => onMapJobCardSelect(item)}
    >
      <Row>
        <Avatar
          src={renderImage(item?.brandLogo, "job")}
          variant="rounded"
          sx={{ width: 64, height: 64, mr: spacing.xs }}
        >
          <img
            alt={item.brandName}
            src={images.defaults.job}
            style={{ width: 64, height: 64 }}
          />
        </Avatar>
        <div>
          <Text size="xs" noWrap color={colors.black} weight="medium">
            {item.brandName} | {item.listingPosition}
          </Text>
          <Text size="xs" color={colors.black}>
            {`${item?.wage}/hr`}
          </Text>
        </div>
      </Row>
    </Card>
  );
};

export default MapCard;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacing.s};
  cursor: pointer;
`;
