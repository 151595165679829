import { Container, FormControl, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import EmployerSelector from "../../../../../selectors/EmployerSelector";

import {
  Text,
  BottomDrawerDropDown,
  Icon,
} from "../../../../../../../components/atoms";
import {
  EmployerPeopleSearchInput,
  PeopleCard,
  LoadingJobView,
} from "../../../../../../../components/molecules";
import { colors, spacing, styleUtils } from "../../../../../../../styles";

import NoLocationView from "./NoLocationView";
import AllSeekers from "./AllSeekers";
import ShortListedSeekers from "./ShortListedSeekers";
import Sort from "./Sort";
import Filter from "./Filter";
import { PeopleConstants } from "../Constants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const PeopleBoard = ({
  isLoadingEmployerLocations,
  employerActiveLocations,
  selectedLoc,
  employerJobSeekerListing,
  starredEmployerJobSeekerListing,
  filterSettings,
  onClickPeople,
  onClickSearch,
  handleLocationChange,
  handleStarred,
  handleSorting,
  handleFilter,
  searchTerm,
  onChangeSearchTerm,
  onlyFavourites,
  mobileSearchValues,
  // forceUpdate,
  onClickPostNewJob,
  peopleListPage,
  showLoadMore,
  handleLoadMorePeople,

  seekerSuggestions,
  fetchSeekerSuggestions,
  setSeekerSearchValue,
}) => {
  const [selectedTab, setSelectedTab] = useState("all");
  const isEmployerPeopleListLoading = useSelector(
    EmployerSelector.isEmployerPeopleListLoading
  );

  const onSelectTab = (type) => () => {
    setSelectedTab(type);
    handleStarred(type === "starred");
  };

  const renderPeople = (item, index) => {
    return (
      <Grid mt={spacing.s} key={index} item xs={12} sm={12}>
        <PeopleCard item={item} onClickPeople={onClickPeople} />
      </Grid>
    );
  };
  useEffect(() => {
    logEvent(FirebaseEvents.EMP_CLICKS_JOBSEEKER);
  },[]);

  return (
    <Container sx={{ mt: spacing.xxl, mb: spacing.xxxxl }}>
      <Text size={34}>
        <Text
          component="span"
          size={34}
          weight="extrabold"
          color={colors.primary}
        >
          Local hiring
        </Text>{" "}
      </Text>
      <Text size={34}>made simple</Text>
      <EmployerPeopleSearchInput
        sx={{ mt: spacing.l }}
        searchTermValue={searchTerm}
        onChangeSearchTerm={onChangeSearchTerm}
        onClickSearch={onClickSearch(searchTerm)}
        mobileSearchValues={mobileSearchValues}
        jobSuggestions={seekerSuggestions}
        setJobSearchValue={setSeekerSearchValue}
        fetchJobSuggestions={fetchSeekerSuggestions}
      />
      <Grid container sx={{ minHeight: "68vh", marginTop: spacing.xxl }}>
        {isLoadingEmployerLocations ? (
          <LoadingJobView
            image="fetching-jobs"
            imageHeight="40%"
            title={PeopleConstants.LOADING_TEXT}
          />
        ) : (
          <>
            <Grid item xs={12} sm={12}>
              <LocationDropdownContainer>
                <Icon
                  name="people-loc-dropdown"
                  size={20}
                  style={{
                    alignSelf: "end",
                    marginRight: styleUtils.remToPx("6px"),
                  }}
                />
                <FormControl variant="standard">
                  <Text size="s" color="#898989">
                    Results for job posted in
                  </Text>
                  <BottomDrawerDropDown
                    fieldValue={selectedLoc}
                    fieldName="employer_locations"
                    variant="standard"
                    handleChange={handleLocationChange}
                    dropDownOptions={employerActiveLocations}
                    placeholder=" "
                    sx={{
                      borderBottom: "0.4000000059604645px solid #2B4894",
                      border: "none",
                      height: styleUtils.pxToRem("18px"),
                      width: styleUtils.pxToRem("264.5px"),
                      paddingLeft: 0,
                    }}
                  />
                </FormControl>
              </LocationDropdownContainer>
              <TitleContainer>
                <TabRow>
                  <SelectedTab
                    sx={{
                      transition: "transform 400ms",
                      transform: `translateX(${
                        selectedTab === "all" ? "0" : "calc(4rem + 4px)"
                      })`,
                    }}
                  />
                  <Tab onClick={onSelectTab("all")}>
                    <Text size="xs" color={colors.black}>
                      All
                    </Text>
                  </Tab>
                  <Tab onClick={onSelectTab("starred")}>
                    <Text size="xs" color={colors.black}>
                      Shortlist
                    </Text>
                  </Tab>
                </TabRow>
                <Filters>
                  <Sort handleSorting={handleSorting} />
                  <Filter
                    handleFilter={handleFilter}
                    filterSettings={filterSettings}
                    onlyFavourites={onlyFavourites}
                  />
                </Filters>
              </TitleContainer>
              {isEmpty(employerActiveLocations) ? (
                <Grid container mt={spacing.xxs} spacing={spacing.xl}>
                  <NoLocationView onClickPostNewJob={onClickPostNewJob} />
                </Grid>
              ) : selectedTab === "all" ? (
                <AllSeekers
                  allJobSeekers={employerJobSeekerListing}
                  isEmployerPeopleListLoading={isEmployerPeopleListLoading}
                  handleLoadMorePeople={handleLoadMorePeople}
                  peopleListPage={peopleListPage}
                  renderPeople={renderPeople}
                  showLoadMore={showLoadMore}
                />
              ) : (
                <ShortListedSeekers
                  isEmployerPeopleListLoading={isEmployerPeopleListLoading}
                  renderPeople={renderPeople}
                  shortListedJobSeekers={starredEmployerJobSeekerListing}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default PeopleBoard;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TabRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #e7edfd;
  border-radius: 6px;
  margin-right: ${spacing.s};
`;

const Tab = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs} 0px;
  width: calc(4rem + 4px);
  cursor: pointer;
`;

const SelectedTab = styled("div")`
  width: 4rem;
  left: 2px;
  height: calc(100% - 4px);
  position: absolute;
  background-color: ${colors.white};
  border-radius: 4px;
`;

const Filters = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LocationDropdownContainer = styled("div")`
  display: flex;
  height: ${styleUtils.pxToRem("48px")};
  margin-right: ${styleUtils.pxToRem("38px")};
  margin-bottom: ${styleUtils.pxToRem("28.5px")};
`;
