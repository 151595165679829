import PeopleBoard from "./views/PeopleBoard";
import PeopleBoardMobile from "./mobileViews/PeopleBoard";
import useIsMobile from "../../../../../../hooks/useIsMobile";

const PeopleBoardContainer = (props) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? PeopleBoardMobile : PeopleBoard;

  return <Wrapper {...props} />;
};

export default PeopleBoardContainer;
