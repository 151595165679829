const isLocalhost = window.location.hostname === "localhost";

if (!isLocalhost) {
  import("@sentry/react")
    .then((Sentry) => {
      Sentry.init({
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        debug: true,
        integrations: [Sentry.httpClientIntegration()],
        release: __APP_VERSION__,
        environment: import.meta.env.VITE_APP_ENVIRONMENT,
        // Set profilesSampleRate to 1.0 to profile every transaction.
        tracesSampleRate: 1.0,
        //For sending header and cookies with event
        sendDefaultPii: true,
        sendClientReports: false,
        grouping: {
          enhancements: [
            // Group issues by error message
            { "function:message": true },
            // Further customize to group by error type
            { "type:value": true },
          ],
        },
        beforeSend(event) {
          //Separate issues based on error type and message
          if (event?.exception?.values) {
            const exception = event.exception.values[0];
            event.fingerprint = ["{{ default }}", exception?.type, exception.value];
          }
          return event;
        },
      });
    })
    .catch((err) => {
      console.error("Error during loading Sentry", err);
    });
}
