import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../../../components/atoms";
import { colors, spacing, textSizes, typography } from "../../../../../styles";
import ProfileImage from "../../../../../components/molecules/ProfileImage";

const PersonalInformation = ({ userDetails }) => {
  return (
    <>
      <TopContainer>
        <ProfileImage
          style={{ width: 111, height: 111 }}
          currentImageUrl={userDetails?.photoURL}
          alt={userDetails?.fullName}
          imageType="job"
        />
        <Text size={22} weight="semibold" style={{ marginTop: spacing.s }}>
          {userDetails?.name || userDetails?.fullName}
        </Text>
        <Text size="l" color={colors.placeholder}>
          {userDetails?.designation}
        </Text>
        <Text size="l" color={colors.placeholder}>
          {userDetails?.companyName}
        </Text>
      </TopContainer>
      <Divider style={{ margin: spacing.l, marginTop: 0 }} />
      <div style={{ padding: spacing.l, paddingTop: 0 }}>
        <Row sx={{ mb: spacing.xxl }}>
          <Icon
            name="profile-email"
            size={24}
            style={{ marginRight: spacing.s }}
          />
          <TextFlex numberOfLines={1}>{userDetails?.email}</TextFlex>
        </Row>
        <Row>
          <Icon
            name="profile-phone"
            size={24}
            style={{ marginRight: spacing.s }}
          />
          <TextFlex numberOfLines={1} sx={{ paddingTop: spacing.xxxs }}>
            {userDetails?.countryCode
              ? `+${userDetails?.countryCode}-${userDetails?.phoneNumber}`
              : userDetails?.phoneNumber}
          </TextFlex>
        </Row>
      </div>
      <Divider style={{ margin: spacing.l, marginTop: 0 }} />
      <div style={{ padding: spacing.l, paddingTop: 0 }}>
        <TextLabel>Company Website</TextLabel>
        <TextSemiBold color={colors.primary}>
          {userDetails?.website}
        </TextSemiBold>
        <TextLabel>No. Of Employees</TextLabel>
        <TextSemiBold>{userDetails?.employee_size}</TextSemiBold>
      </div>
    </>
  );
};
export default PersonalInformation;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${spacing.m};
`;

const TextLabel = styled(Text)`
  font-size: ${textSizes.m};
  color: #767676;
  margin-top: ${spacing.m};
`;

const TextSemiBold = styled(Text)`
  font-size: ${textSizes.m};
  font-weight: ${typography.semibold};
  margin-top: ${spacing.xs};
`;

const TextFlex = styled(Text)`
  font-size: ${textSizes.m};
  display: flex;
  flex: 1;
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
`;
