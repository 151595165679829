import { styled } from "@mui/material/styles";

import Icon from "../Icon";
import "./HoverIcon.css";

const HoverIcon = ({
  outlineIcon,
  filledIcon,
  color,
  size,
  style,
  ...props
}) => {
  return (
    <IconWrapper sx={style}>
      <Icon
        name={outlineIcon}
        size={size}
        color={color}
        style={{ position: "absolute", zIndex: 1 }}
        {...props}
      />
      <Icon
        className="filled-icon"
        name={filledIcon}
        size={size}
        color={color}
        style={{ position: "absolute", zIndex: 2 }}
        {...props}
      />
    </IconWrapper>
  );
};

export default HoverIcon;

const IconWrapper = styled("div")`
  position: relative;
`;
