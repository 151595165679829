import { Button, Container, Grid } from "@mui/material";

import { colors, spacing } from "../../../styles";

import { Text } from "../../../components/atoms";

const Search = ({ onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "78vh",
      }}
    >
      <Grid
        container
        columnSpacing={spacing.s}
        sx={{ alignItem: "center", marginTop: spacing.xxxl }}
      >
        <Grid item md={7}>
          <Text size={46} style={{ marginTop: spacing.xxxxl, lineHeight: 1.2 }}>
            Local part-time
          </Text>
          <Text
            size={46}
            weight="extrabold"
            component="span"
            color={colors.primary}
            style={{ lineHeight: 1.2 }}
          >
            hiring{" "}
            <Text component="span" size={46} style={{ lineHeight: 1.2 }}>
              made simple
            </Text>
          </Text>
          <Text size={22} style={{ marginTop: spacing.m }} color="#4C5468">
            It’s easy to hire local part-time workers with EmployD!
          </Text>
          <Text size={22} color="#4C5468">
            Our simple, easy-to-use platform allows you to post
          </Text>
          <Text size={22} color="#4C5468">
            your job without hassle
          </Text>
          <Button
            variant="contained"
            sx={{
              width: "auto",
              py: "12px",
              px: spacing.xl,
              mt: spacing.xxxl,
            }}
            onClick={onGetStarted}
          >
            <Text size="l" weight="bold" color={colors.white}>
              Start Hiring Instantly
            </Text>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;
