import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { ChatAttachmentCard, ChatItemCard } from "../../components/molecules";
import { spacing } from "../../styles";
import { isIos } from "../../utils/commonUtils";
import LoginSelector from "../Login/selectors/LoginSelector";
import BlockedMessage from "./BlockedMessage";

const ChatScroller = forwardRef(
  ({ scrollableDivHeight, isBlocked, isBlockedBy, messages }, ref) => {
    const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
    const deviceIsIos = isIos();
    let timelines = [];
    const divStyles = deviceIsIos
      ? { display: "block", transform: "scaleY(-1)" }
      : { display: "flex", flexDirection: "column" };

    const renderItem = (item, index) => {
      const mine = item.user._id === loggedInUser.uid;
      if (item.is_file) {
        return (
          <ChatAttachmentCard
            id={`CHAT_MSG_${index}`}
            key={index}
            mine={mine}
            item={item}
            cardStyle={{
              marginBottom: spacing.xl,
              marginRight: mine ? spacing.s : 0,
            }}
            isIos={deviceIsIos}
            timelines={timelines}
          />
        );
      }
      return (
        <ChatItemCard
          id={`CHAT_MSG_${index}`}
          key={index}
          mine={mine}
          item={item}
          cardStyle={{
            marginBottom: spacing.xl,
            marginRight: mine ? spacing.s : 0,
          }}
          isIos={deviceIsIos}
          timelines={timelines}
        />
      );
    };

    const scrollToBottom = () => {
      document
        .getElementById(`CHAT_MSG_${messages.length - 1}`)
        ?.scrollIntoView({ behavior: "smooth", block: "end" });
    };

    useEffect(() => {
      if (messages.length > 1) scrollToBottom();
    }, []);

    useEffect(() => {
      scrollToBottom();
    }, [messages]);

    return (
      <Scroller
        ref={ref}
        sx={{
          ...divStyles,
          height: scrollableDivHeight,
          ...(deviceIsIos && {
            "> div": {
              transform: "scaleY(-1)",
            },
          }),
        }}
      >
        <BlockedMessage isBlocked={isBlocked} isBlockedBy={isBlockedBy} />
        {messages.map(renderItem)}
      </Scroller>
    );
  }
);

export default ChatScroller;

const Scroller = styled("div")`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
