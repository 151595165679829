import { useEffect } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { isEmpty } from "lodash";

import LoginSelector from "../../../../../../Login/selectors/LoginSelector";
import Marker from "./Marker";

const LocationMap = ({
  activeCenter,
  activeMarker,
  jobSeekerListing,
  location,
  onMapJobCardSelect,
  setActiveCenter,
  setActiveMarker,
}) => {
  const userLocation = useSelector(
    LoginSelector.getLoggedInUserCurrentLocation
  );
  const defaultCenter = {
    lat: Number(location.lat) || userLocation?.lat,
    lng: Number(location.lng) || userLocation?.lng,
  };

  useEffect(() => {
    if (activeMarker) {
      setActiveCenter({
        lat: jobSeekerListing[activeMarker]?.lat,
        lng: jobSeekerListing[activeMarker]?.lng,
      });
    } else {
      //No code here
    }
  }, [activeMarker]);

  const handleMarkerPress = (selectedIndex) => {
    if (activeMarker === selectedIndex) {
      setActiveMarker(null);
    } else {
      setActiveMarker(selectedIndex);
      onMapJobCardSelect(selectedIndex);
    }
  };

  const getUserLocationMarker = () => {
    return (
      <Marker
        lat={userLocation.lat}
        lng={userLocation.lng}
        showUserLocation={true}
      />
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: import.meta.env.VITE_APP_GOOGLE_PLACES_API_KEY }}
        center={{
          lat:
            activeMarker && activeCenter ? activeCenter.lat : defaultCenter.lat,
          lng:
            activeMarker && activeCenter ? activeCenter.lng : defaultCenter.lng,
        }}
        zoom={14}
      >
        {!isEmpty(userLocation) && getUserLocationMarker()}
        {jobSeekerListing.map((item, index) => {
          return (
            <Marker
              key={item?.jobId}
              lat={item.lat}
              lng={item.lng}
              currentIndex={index}
              handleMarkerPress={handleMarkerPress}
              isActiveMarker={index === activeMarker}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default LocationMap;
