export const LOGIN_HEADER = "EMPLOYD";
export const LOGIN_TEXT = "Signup/ Login as ";

export const SIGNIN_HERE_TEXT = "Sign in here";

export const COMMON_SIGNIN_TEXT = "If you are ";

export const EMPLOYER_KEY = "employer";

export const OTP_LOGIN_BTN = " Login via Phone Number";
export const GOOGLE = "Continue with Google";
export const FACEBOOK = "Continue with Facebook";
export const CONTINUE_WITH_EMAIL = "Continue with Email";
export const TANDC = "T&C";
export const PRIVACY_POLICY = "Privacy Policy.";
export const AGREE_TO_TERMS = " By Signing-up, you agree to our";
