export const SCHEDULER_EMAIL_CONSTS = {
  EMAIL_HEADER: "Email",
  EMAIL_PLACEHOLDER: "Enter email address",
  NEXT_BUTTON: "Next",
};

export const DETAILS_FORM_CONSTS = {
  DETAILS_FORM_NAME_LABEL: "Full Name",
  DETAILS_FORM_NAME_PLACEHOLDER: "enter full name",
  DETAILS_FORM_EMAIL_LABEL: "Email",
  DETAILS_FORM_EMAIL_PLACEHOLDER: "enter email",
  DETAILS_FORM_JOBTITLE_LABEL: "Job Title",
  DETAILS_FORM_JOBTITLE_PLACEHOLDER: "enter job title",
  DETAILS_FORM_BUTTON_SUBMIT: "Submit",
};

export const AVAILAIBLE_SCHEDULE_TABLE_CONSTS = {
  COLUMN_NAME: "Name",
  COLUMN_DATE: "Date",
  COLUMN_TIIME: "Time",
  COLUMN_DURATION: "Duration",
  COLUMN_MEETING_MODE: "Interview Mode",
};

export const AVAILAIBLE_SCHEDULE_ROW_KEYS = [
  "full_name",
  "date",
  "time",
  "duration",
  "meeting_mode",
];

export const UPCOMING_SCHEDULE_TABLE_CONSTS = {
  COLUMN_EMP_NAME: "Employer Name",
  COLUMN_SEEKER_NAME: "JobSeeker Name",
  COLUMN_JOB_TITLE: "Job Title",
  COLUMN_DATE: "Date",
  COLUMN_TIIME: "Time",
  COLUMN_DURATION: "Duration",
  COLUMN_ZOOM_DETAILS: "Zoom",
};

export const UPCOMING_SCHEDULE_ROW_KEYS = [
  "employer_name",
  "seeker_name",
  "job_title",
  "date",
  "time",
  "duration",
  "start_url",
];

export const CREATE_AVAILABILITY_CONSTS = {
  CREATE_MEETING_HEADER: "Create Meeting",
  TIME_SLOT_DURATION: [15, 30, 45, 60, 120],
  TIME_SLOT_PLACEHOLDER: "Meeting duration in mins",
  MEETING_MODES: ["Phone", "Video", "In-Person"],
  MEETING_MODES_PLACEHOLDER: "Choose meeting modes",
  CREATE_MEETING_INITAL_STATE: {
    date: "",
    time: "",
    duration: "",
    meetingMode: "",
  },
};
