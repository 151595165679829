const getIsExisitngBrand = ({ signUpState }) => {
  const { employerValidDetails } = signUpState;
  return employerValidDetails;
};

const showEmployerVerificationLoader = ({ signUpState }) => {
  const { isEmployerVerificationLoading } = signUpState;
  return isEmployerVerificationLoading;
};

const isEmployerVerificationSuccess = ({ signUpState }) => {
  const { isEmployerVerificationSuccess } = signUpState;
  return isEmployerVerificationSuccess;
};

const SignUpSelector = {
  getIsExisitngBrand,
  showEmployerVerificationLoader,
  isEmployerVerificationSuccess,
};

export default SignUpSelector;
