export const distanceFilter = [
  {
    id: 1,
    label: "Within 5 mile",
    value: 5,
  },
  {
    id: 2,
    label: "Within 10 miles",
    value: 10,
  },
  {
    id: 3,
    label: "Within 20 miles",
    value: 20,
  },
  { id: 4, label: "Within 50 miles", value: 50 },
];

export const hourlyWageFilter = [
  { id: 1, label: "$5-10 /hr", value: "5-10" },
  {
    id: 2,
    label: "$10-15 /hr",
    value: "10-15",
  },
  {
    id: 3,
    label: "$15-20 /hr",
    value: "15-20",
  },
  {
    id: 4,
    label: "$20-30 /hr",
    value: "20-30",
  },
  { id: 5, label: "$30-40 /hr", value: "30-40" },
];

export const hoursPerWeekFilter = [
  {
    id: 1,
    label: "00-02 hrs",
    value: "0-2",
  },
  {
    id: 2,
    label: "02-05 hrs",
    value: "2-5",
  },
  {
    id: 3,
    label: "05-10 hrs",
    value: "5-10",
  },
  {
    id: 4,
    label: "10-20 hrs",
    value: "10-20",
  },
  {
    id: 5,
    label: "20-30 hrs",
    value: "20-30",
  },
  { id: 6, label: "30-40 hrs", value: "30-40" },
];

export const experienceFilter = [
  {
    id: 1,
    label: "Beginner",
    value: "Beginner",
  },
  {
    id: 2,
    label: "01-02 years",
    value: "1-2",
  },
  {
    id: 3,
    label: "02-05 years",
    value: "2-5",
  },
  {
    id: 4,
    label: "Above 05 years",
    value: ">5",
  },
];

export const daysFilter = [
  {
    id: 1,
    label: "All",
    value: "all",
  },
  {
    id: 2,
    label: "Monday",
    value: "Mon",
  },
  {
    id: 3,
    label: "Tuesday",
    value: "Tue",
  },
  {
    id: 4,
    label: "Wednesday",
    value: "Wed",
  },
  {
    id: 5,
    label: "Thursday",
    value: "Thu",
  },
  {
    id: 6,
    label: "Friday",
    value: "Fri",
  },
  {
    id: 7,
    label: "Saturday",
    value: "Sat",
  },
  {
    id: 8,
    label: "Sunday",
    value: "Sun",
  },
];

export const timeSlotFilter = [
  {
    id: 1,
    label: "All",
    value: "all",
  },
  {
    id: 2,
    label: "Morning",
    value: "Morning",
  },
  {
    id: 3,
    label: "Afternoon",
    value: "Afternoon",
  },
  {
    id: 4,
    label: "Evening",
    value: "Evening",
  },
  {
    id: 5,
    label: "Night",
    value: "Night",
  },
];

export const bottomFilterValueSeparator = ": ";

export const filterOptions = {
  distance: {
    title: "Distance",
    filterBy: "Filter by distance",
    option: "distanceFilter",
    unit: " miles",
    valueKey: "distance",
  },
  hourlyWage: {
    title: "Hourly Wages",
    filterBy: "Filter by hourly wages",
    option: "hourlyWageFilter",
    unit: " /hr",
    valueKey: "wage",
  },
  hoursPerWeek: {
    title: "Hours/week",
    filterBy: "Filter by hours/week",
    option: "hoursPerWeekFilter",
    unit: " hrs",
    valueKey: "hrsPerWeek",
  },
  experience: {
    title: "Experience",
    filterBy: "Filter by experience",
    option: "experienceFilter",
    unit: " years",
    valueKey: "experience",
  },
  days: {
    title: "Days/Week",
    filterBy: "Filter by days/week",
    option: "daysFilter",
    unit: " ",
    valueKey: "days",
  },
  timeSlot: {
    title: "Shift",
    filterBy: "Filter by shift",
    option: "timeSlotFilter",
    unit: " ",
    valueKey: "timeSlot",
  },
};
