import * as CommonActionsTypes from "./commonActionTypes";

export const showLoaderAction = () => ({
  type: CommonActionsTypes.SHOW_LOADER,
});

export const hideLoaderAction = () => ({
  type: CommonActionsTypes.HIDE_LOADER,
});

export const hideNotificationAction = (payload) => ({
  type: CommonActionsTypes.HIDE_NOTIFICATION,
  payload,
});

export const showNotificationAction = (payload) => ({
  type: CommonActionsTypes.SHOW_NOTIFICATION,
  payload,
});

export const getUserUpdatedDetails = (payload) => ({
  type: CommonActionsTypes.USER_DETAIL,
  payload,
})

