import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container, Divider, Modal, styled } from "@mui/material";

import { colors, spacing, styleUtils } from "styles";
import { images } from "assets/images";
import useIsMobile from "hooks/useIsMobile";
import { Icon, Text, TextInput } from "components/atoms";

import { hideLoader, showLoader, showNotification } from "utils/commonUtils";
import { userReviewDetails } from "configs/network/apis";

import LoginSelector from "containers/Login/selectors/LoginSelector";

import * as FeedbackConstants from "./constants";
import { getUserUpdatedDetails } from "containers/Common/actions/CommonActions";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { JOB_SEEKER } from "appConstants";

const RatingsModal = (props) => {
  const { isOpen, setShowRatingModal } = props;

  const [ratings, setRatings] = useState(0);
  const [feedbackInputText, setFeedbackInputText] = useState("");
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  //Height for web and web-responsive part
  const heightForMobile = showFeedbackInput
    ? styleUtils.pxToRem("500px")
    : styleUtils.pxToRem("400px");
  const heightForWeb = showFeedbackInput
    ? styleUtils.pxToRem("466px")
    : styleUtils.pxToRem("370px");

  const handleRatingChange = (e, newValue) => {
    e.stopPropagation();
    setRatings(newValue);
    if (newValue >= 1 && newValue <= 3) {
      setShowFeedbackInput(true);
    } else {
      setShowFeedbackInput(false);
    }
  };

  const handleFeedbackChange = (e) => {
    const targetedElementValue = e.target.value;
    setFeedbackInputText(targetedElementValue);
  };

  const handleRatingSubmit = async (ratings, feedbackInputText) => {
    setIsFeedbackSubmitted(true);
    setShowFeedbackInput(false);
    showLoader();
    try {
      const { data } = await userReviewDetails({
        rating: ratings,
        review: feedbackInputText,
        platform: "web",
      });
      hideLoader();
      dispatch(
        getUserUpdatedDetails({
          uid: loggedInUser?.uid,
          type: loggedInUser?.userType,
        }),
      );
      showNotification({
        alertProps: {
          severity: "success",
          children: data?.message,
        },
      });
      const currUserType = loggedInUser?.userType;
      if (currUserType === JOB_SEEKER) {
        logEvent(FirebaseEvents.JS_SUBMITS_RATING);
      } else {
        logEvent(FirebaseEvents.EMP_SUBMITS_RATING);
      }
    } catch (error) {
      hideLoader();
      showNotification({
        alertProps: {
          severity: "warning",
          children: "An unexpected error occurred. Please try again.",
        },
      });
    }
  };

  const RatingData = Object.entries(FeedbackConstants.RatingTexts);

  const experienceText =
    ratings > 3 ? (
      <div style={{ display: "none" }} />
    ) : (
      <Text weight="medium" size="m">
        {FeedbackConstants.RatingConstants.EXPERIENCE_RATING_TEXT}
      </Text>
    );

  const feedbackTextWrapper = showFeedbackInput ? (
    <TextInput
      name="user_feedback"
      onChange={handleFeedbackChange}
      placeholder={FeedbackConstants.RatingConstants.EXPERIENCE_RATING_PLACEHOLDER}
      inputProps={{
        style: {
          height: styleUtils.pxToRem("100px"),
          width: isMobile ? styleUtils.pxToRem("270px") : styleUtils.pxToRem("450px"),
          overflow: "auto",
        },
      }}
      multiline
    />
  ) : (
    experienceText
  );

  const thanksGivingContent = (
    <>
      <HeaderContainer>
        <ImgContainer src={images.imgthanksgivingmodal} />
      </HeaderContainer>
      <TextWrapper>
        <Text size="xl" weight="bold">
          {FeedbackConstants.RatingConstants.THANKS_GIVING_FEEDBACK_TITLE}
        </Text>
        <Text size="xs" color={colors.brownText}>
          {FeedbackConstants.RatingConstants.THANKS_GIVING_FEEDBACK_SUBTEXT}
        </Text>
        <ButtonWrapper sx={{ marginTop: styleUtils.pxToRem("55px") }}>
          <Button
            variant="contained"
            onClick={() => setShowRatingModal(false)}
            sx={{
              width: styleUtils.pxToRem("315px"),
              height: styleUtils.pxToRem("40px"),
              boxShadow: "0px 3px 10px #2B48953B",
              fontWeight: "200",
            }}
          >
            {FeedbackConstants.RatingConstants.CONTINUE_TO_HOME_BUTTON_TEXT}
          </Button>
        </ButtonWrapper>
      </TextWrapper>
    </>
  );

  const content = !isFeedbackSubmitted ? (
    <>
      <Text
        weight="bold"
        size="xl"
        sx={{
          marginBottom: styleUtils.pxToRem("40px"),
          textAlign: "center",
        }}
      >
        {FeedbackConstants.RatingConstants.EXPERIENCE_RATING_TITLE}
      </Text>
      <StarWrapper
        sx={{
          gap: isMobile ? styleUtils.pxToRem("10px") : styleUtils.pxToRem("20px"),
        }}
      >
        {RatingData.map((rating) => (
          <IconContainer
            sx={{
              maxHeight: isMobile ? styleUtils.pxToRem("40px") : styleUtils.pxToRem("60px"),
              maxWidth: isMobile ? styleUtils.pxToRem("40px") : styleUtils.pxToRem("60px"),
            }}
            id={rating[0]}
            key={rating[0]}
          >
            <Icon
              name="star"
              color={rating[0] <= ratings ? colors.ratingFilledYellowStar : "none"}
              strokeColor={
                rating[0] <= ratings ? colors.ratingFilledYellowStar : colors.ratingStarOutline
              }
              size="65"
              onClick={(e) => handleRatingChange(e, rating[0])}
            />
          </IconContainer>
        ))}
      </StarWrapper>
      <TextWrapper>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Text weight="semibold" size="xl" sx={{ marginBottom: styleUtils.pxToRem("10px") }}>
            {FeedbackConstants.RatingTexts[ratings]}
          </Text>
        </div>

        {feedbackTextWrapper}

        <ButtonWrapper sx={{ marginTop: styleUtils.pxToRem("70px") }}>
          <Button
            variant={ratings ? "contained" : "outlined"}
            onClick={() => handleRatingSubmit(ratings, feedbackInputText)}
            disabled={!ratings}
            sx={{
              width: styleUtils.pxToRem("315px"),
              height: styleUtils.pxToRem("40px"),
              boxShadow: "0px 3px 10px #2B48953B",
            }}
          >
            {FeedbackConstants.RatingConstants.SUBMIT_BUTTON_TEXT}
          </Button>
        </ButtonWrapper>
      </TextWrapper>
    </>
  ) : (
    thanksGivingContent
  );

  return (
    <Modal open={isOpen} style={{ backdropFilter: "blur(2px)" }}>
      <Container sx={{ mt: spacing.xxl }}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            transition: "height 0.5s ease-in-out",
            width: isMobile ? styleUtils.pxToRem("340px") : styleUtils.pxToRem("609px"),
            height: isMobile ? heightForMobile : heightForWeb,
            p: 2,
          }}
        >
          <Wrapper>
            {content}
            {!isFeedbackSubmitted && (
              <div style={{ width: "100%", marginTop: "-70px" }}>
                <Divider orientation="horizontal"></Divider>
              </div>
            )}
          </Wrapper>
        </Card>
      </Container>
    </Modal>
  );
};

export default RatingsModal;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StarWrapper = styled("div")`
  display: flex;
  justify-content: center;
  min-height: ${styleUtils.pxToRem("90px")};
`;

const TextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${styleUtils.pxToRem("10px")};
  text-align: center;
`;

const ButtonWrapper = styled("div")`
  margin-top: ${styleUtils.pxToRem("80px")};
`;

const HeaderContainer = styled("div")`
  display: flex;
  justify-content: center;
  height: ${styleUtils.pxToRem("165px")};
  width: 100%;
  background: ${colors.lightBlue} 0% 0% no-repeat;
`;

const ImgContainer = styled("img")`
  display: block;
  align-self: center;
  width: ${styleUtils.pxToRem("248px")};
  height: ${styleUtils.pxToRem("151px")};
`;

const IconContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
