import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Card } from "@mui/material";
import { CardModal, Text } from "../../atoms";
import { spacing } from "../../../styles";

const ApiErrorModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isActivationFlow = queryParams.get("isActivationFlow") === "true" || false;
  useImperativeHandle(ref, () => ({
    showModal: onOpen,
    hideModal: onClose,
  }));

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    if (!isActivationFlow) {
      setIsOpen(false);
    }
  };

  const onTryAgain = () => {
    document.location.reload(true);
  };

  return (
    <CardModal open={isOpen} onClose={onClose}>
      <Card
        sx={{
          p: spacing.l,
        }}
      >
        <Text>{`There was something wrong with the request. Please try again${
          isActivationFlow ? " later." : "."
        }`}</Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: spacing.l,
          }}
        >
          {isActivationFlow ? (
            <Button
              variant="contained"
              sx={{ width: "auto" }}
              onClick={() => {
                window.location.href = "/employerhome";
              }}
            >
              Done
            </Button>
          ) : (
            <Button variant="contained" sx={{ width: "auto" }} onClick={onTryAgain}>
              Try Again
            </Button>
          )}
        </div>
      </Card>
    </CardModal>
  );
});

export default ApiErrorModal;
