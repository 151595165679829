import { Button, Grid } from "@mui/material";
import { Icon, Text } from "../../../../../../../components/atoms";
import { PeopleCard } from "../../../../../../../components/molecules";
import { colors, spacing, styleUtils } from "../../../../../../../styles";
import { mockPeopleCard } from "../Constants";
import { styled } from "@mui/material/styles";

const renderPeople = (item, index) => {
  return (
    <Grid
      key={index}
      item
      xs={12}
      sm={12}
      style={{
        position: "relative",
        height: styleUtils.pxToRem("203px"),
        width: styleUtils.pxToRem("374px"),
        marginBottom: spacing.s,
      }}
    >
      <PeopleCard
        item={item}
        onClickPeople={() => ""}
        cardStyle={{
          position: "absolute",
          height: "100%",
          width: "100%",
          opacity: "0.5,",
        }}
      />
      <PeopleCardDisabledContainer>
        <Icon
          name="poeple-card-lock"
          size={41}
          style={{
            marginTop: styleUtils.pxToRem("38px"),
            marginBottom: styleUtils.pxToRem("16px"),
          }}
        />
        <Text size="s" weight="medium" color={colors.white}>
          Post a job to view details
        </Text>
        <Text size="s" weight="medium" color={colors.white}>
          of the job seeker
        </Text>
      </PeopleCardDisabledContainer>
    </Grid>
  );
};

const NoLocationView = ({ onClickPostNewJob }) => {
  return (
    <NoLocContainer>
      <Grid container mt={spacing.xxs} spacing={spacing.xl}>
        {mockPeopleCard.map(renderPeople)}
      </Grid>
      <CardContainer>
        <Text size="s" color={colors.darkBlack}>
          1000s of active job seekers are on EmployD.
        </Text>
        <Text size="s" color={colors.darkBlack}>
          Post a job to search for jobseekers.
        </Text>
        <PostJobBtn variant="contained" onClick={onClickPostNewJob}>
          Post your job
        </PostJobBtn>
      </CardContainer>
    </NoLocContainer>
  );
};

export default NoLocationView;

const NoLocContainer = styled("div")`
  margin-left: ${styleUtils.pxToRem("24px")};
`;

const PostJobBtn = styled(Button)`
  width: ${styleUtils.pxToRem("152px")};
  height: ${styleUtils.pxToRem("42px")};
  margin-top: ${spacing.s};
`;

const CardContainer = styled("div")`
  padding: ${spacing.m};
  margin-top: ${spacing.s};
`;

const PeopleCardDisabledContainer = styled("div")`
  background: #0000009f;
  border: 1px solid #efeef3;
  border-radius: 6px;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9;
`;
