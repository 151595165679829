import { useState } from "react";
import { Card, Container } from "@mui/material";

import { useFormik } from "formik";

import {
  schedulerDetailsFormSchema,
  schedulerUserValidationSchema,
} from "../../schedulerValidationSchema";

import {
  schedulerEmployerDetails,
  schedulerRegisterUser,
} from "../../../../../../configs/network/apis";
import { hideLoader, showLoader, showNotification } from "../../../../../../utils/commonUtils";
import { spacing } from "../../../../../../styles";
import { useDispatch } from "react-redux";
import { schedulerDetailsUpdate } from "../../../../actions/EmployerActions";
import SchedulerUserView from "./ScheduleruserView";

const SchedulerUserContainer = ({ handleUserValidation }) => {
  const [showUserDetailsForm, setShowUserDetailsForm] = useState(false);
  const dispatch = useDispatch();

  const emailValidationFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schedulerUserValidationSchema,
    onSubmit: async (results) => {
      const { email } = results;
      showLoader();
      try {
        const res = await schedulerEmployerDetails({ email: email });
        if (res?.details?.id) {
          handleUserValidation(true, res.details);
          setShowUserDetailsForm(false);
          dispatch(schedulerDetailsUpdate(res.details));
        }
        hideLoader();
      } catch (error) {
        hideLoader();
        setShowUserDetailsForm(true);
        handleUserValidation(false, {});
        showNotification({
          alertProps: {
            severity: "error",
            children: error?.response?.status === 404 ? error?.response?.data?.message : "Error",
          },
        });
      }
    },
  });

  const detailsFormValidationFormik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      jobTitle: "",
    },
    validationSchema: schedulerDetailsFormSchema,
    onSubmit: async (results) => {
      const { email, fullName, jobTitle } = results;
      showLoader();
      try {
        const res = await schedulerRegisterUser({
          email: email,
          full_name: fullName,
          job_title: jobTitle,
        });
        if (res?.details?.id) {
          handleUserValidation(true, res.details);
          setShowUserDetailsForm(false);
          dispatch(schedulerDetailsUpdate(res.details));
        }
        hideLoader();
      } catch (error) {
        hideLoader();
        setShowUserDetailsForm(false);
        handleUserValidation(false, {});
        showNotification({
          alertProps: {
            severity: "error",
            children: error?.response?.status === 404 ? error?.response?.data?.message : "Error",
          },
        });
      }
    },
  });

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Card
        sx={{
          py: spacing.xl,
          flex: 1,
          display: "flex",
        }}
      >
        <SchedulerUserView
          detailsFormValidationFormik={detailsFormValidationFormik}
          emailValidationFormik={emailValidationFormik}
          showUserDetailsForm={showUserDetailsForm}
        />
      </Card>
    </Container>
  );
};

export default SchedulerUserContainer;
