import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import SchedulerHomeView from "./SchedulerHomeView";

import {
  createSchedulerAvailability,
  getSchedulerAvailabilityList,
  getSchedulerUpcomingMeetingList,
} from "../../../../actions/EmployerActions";

import { showLoader, showNotification } from "../../../../../../utils/commonUtils";
import { CREATE_AVAILABILITY_CONSTS } from "../../constants";
// import { createAvailabilitySchema } from "../../schedulerValidationSchema";

const SchedulerHomeContainer = ({ schedulerUserDetails }) => {
  const dispatch = useDispatch();
  const [showCreateAvailModal, setShowCreateAvailModal] = useState(false);

  useEffect(() => {
    showLoader();
    dispatch(
      getSchedulerAvailabilityList({
        userId: schedulerUserDetails?.id,
        fetch: "all",
      }),
    );
    dispatch(
      getSchedulerUpcomingMeetingList({
        userId: schedulerUserDetails?.id,
      }),
    );
  }, [schedulerUserDetails]);

  const createAvailabilityFormik = useFormik({
    initialValues: {
      ...CREATE_AVAILABILITY_CONSTS.CREATE_MEETING_INITAL_STATE,
    },
    // validationSchema: createAvailabilitySchema,
    onSubmit: async (results) => {
      const { date, time, duration, meetingMode } = results;
      showLoader();
      try {
        dispatch(
          createSchedulerAvailability({
            uid: schedulerUserDetails?.id,
            date: date,
            time: time,
            duration: duration,
            meetingMode: meetingMode,
          }),
        );
        setShowCreateAvailModal(false);
      } catch (error) {
        showNotification({
          alertProps: {
            severity: "error",
            children: error?.response?.status === 404 ? error?.response?.data?.message : "Error",
          },
        });
      }
    },
  });

  const handleCreateAvailModal = (modalAction) => {
    setShowCreateAvailModal(modalAction);
  };

  return (
    <SchedulerHomeView
      showCreateAvailModal={showCreateAvailModal}
      handleCreateAvailModal={handleCreateAvailModal}
      createAvailabilityFormik={createAvailabilityFormik}
    />
  );
};

export default SchedulerHomeContainer;
