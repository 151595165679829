import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { EMPLOYER, JOB_SEEKER } from "../../appConstants";

import LoginSelector from "../../containers/Login/selectors/LoginSelector";
import { showNotification } from "../../utils/commonUtils";
import {
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
} from "../../routes/routeConstants";

const EmployerRoute = () => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const location = useLocation();

  if (isAuthenticated && loggedInUser?.userType === JOB_SEEKER) {
    showNotification({
      alertProps: {
        severity: "info",
        children: "The link you are trying to open is for an employer",
      },
    });
    return <Navigate to="/" replace />;
  } else if (
    isAuthenticated &&
    loggedInUser?.userType === EMPLOYER &&
    location.pathname === "/e"
  ) {
    return (
      <Navigate
        to={`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`}
        replace
      />
    );
  }
  return <Outlet />;
};

export default EmployerRoute;
