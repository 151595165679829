import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";

import { getNotification } from "./selectors/CommonSelector";
import { Icon, Text } from "../../components/atoms";
import { hideNotification } from "../../utils/commonUtils";
import { colors } from "../../styles";

const NotificationContainer = () => {
  const { isShown, snackbarProps, alertProps } = useSelector(getNotification);

  const handleClose = () => {
    hideNotification();
  };

  return (
    <Snackbar
      open={isShown}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      {...snackbarProps}
      onClose={handleClose}
      autoHideDuration={4000}
    >
      <Alert
        elevation={6}
        {...alertProps}
        sx={{ width: "100%" }}
        children={
          <Text
            size="s"
            weight="regular"
            color={colors.textPrimary}
            style={{ transform: "translateY(4px)" }}
          >
            {alertProps.children}
          </Text>
        }
        iconMapping={{
          success: <Icon size={34} name="alert-success" />,
          info: <Icon size={34} name="alert-info" />,
          warning: <Icon size={34} name="alert-warning" />,
          error: <Icon size={34} name="alert-error" />,
        }}
      />
    </Snackbar>
  );
};

export default NotificationContainer;
