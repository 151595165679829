import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import AbsentAccountModal from "components/molecules/AbsentAccountModal";

import BlockingModal from "./BlockingModal";
import BlockedUserListContainer from "containers/Account/BlockedUserListContainer";

import { Text } from "../../../../components/atoms";

import { spacing } from "styles";
import ReportModal from "containers/Chat/ReportModal";

const EditBlockedUsers = ({
  blockedUsers,
  blockModalStatus,
  isAccountAbsent,
  onClickChat,
  handleAbsentAccountError,
  handleBlockUser,
  handleUnblockUser,
  selectedUserType,
  setSelectedUser,
  setBlockModalStatus,
  setUnblockModalStatus,
  unBlockedUsers,
  unBlockModalStatus,
}) => {
  const handleBlockModalStatus = () => {
    setBlockModalStatus(false);
  }
  return (
      <UsersListContainer>
        <BlockedUserListContainer
          onClickChat={onClickChat}
          usersList={blockedUsers}
          setSelectedUser={setSelectedUser}
          setModalStatus={setUnblockModalStatus}
          type="block"
        />
        <Divider sx={{ marginTop: spacing.xxl }} />
        <Text
          size={22}
          weight="bold"
          sx={{ marginTop: spacing.s, marginBottom: spacing.xxl }}
        >
          Recently Unblocked Users
        </Text>
        <BlockedUserListContainer
          onClickChat={onClickChat}
          usersList={unBlockedUsers}
          setSelectedUser={setSelectedUser}
          setModalStatus={setBlockModalStatus}
          type="unblock"
        />
        {unBlockModalStatus && (
          <BlockingModal
            status={unBlockModalStatus}
            setStatus={setUnblockModalStatus}
            handleSubmit={handleUnblockUser}
            action="Are you sure you want to unblock this user"
            header="Unblock User"
            actioButtonName="Unblock"
          />
        )}
        {blockModalStatus && (
          <ReportModal
            isOpen={blockModalStatus}
            onClose={handleBlockModalStatus}
            handleBlockAndReport={handleBlockUser}
          />
        )}
        {isAccountAbsent && (
          <AbsentAccountModal
            isOpen={isAccountAbsent}
            userType={selectedUserType}
            handleAccountErrorClick={handleAbsentAccountError}
          />
        )}
      </UsersListContainer>
  );
};

EditBlockedUsers.propTypes = {
  blockedUsers: PropTypes.array.isRequired,
  blockModalStatus: PropTypes.bool.isRequired,
  isAccountAbsent: PropTypes.bool.isRequired,
  onClickChat: PropTypes.func.isRequired,
  handleAbsentAccountError: PropTypes.func.isRequired,
  handleBlockUser: PropTypes.func.isRequired,
  handleUnblockUser: PropTypes.func.isRequired,
  selectedUserType: PropTypes.string.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  setBlockModalStatus: PropTypes.func.isRequired,
  setUnblockModalStatus: PropTypes.func.isRequired,
  unBlockedUsers: PropTypes.array.isRequired,
  unBlockModalStatus: PropTypes.bool.isRequired,
};

export default EditBlockedUsers;

const UsersListContainer = styled("div")`
  padding: ${spacing.xl};
  margin-left: ${spacing.m};
  margin-right: ${spacing.m};
`;
