import { Box, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { images } from "../../assets/images";

import { getLoader } from "./selectors/CommonSelector";

const LoaderContainer = () => {
  const { isShown } = useSelector(getLoader);

  return (
    <Modal open={isShown}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={images.spinner} width={100} height={100} alt="Loading" />
      </Box>
    </Modal>
  );
};

export default LoaderContainer;
