import {
  Box,
  Container,
  Divider,
  Grid,
  Pagination,
  Tab,
  Tabs,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { images } from "../../../assets/images";
import useIsMobile from "../../../hooks/useIsMobile";
import { styleUtils } from "../../../styles";
import { CATEGORY_COLORS } from "../blogConstants";
import BlogLandingBannerMobile from "../mobileViews/BlogLandingBannerMobile";
import { useBlogPostsQuery } from "../react-query";
import PostCard from "./PostCard";

const LOADER_LIST_DATA = [1, 2, 3, 4, 5, 6];

const BlogLanding = () => {
  const [tabValue, setTabValue] = useState("All");
  const [page, setPage] = useState(1);
  const { isLoading, posts, pageCount } = useBlogPostsQuery({
    category: tabValue,
    page,
  });

  const isMobile = useIsMobile();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handlePageChange = (event, number) => {
    setPage(number);
  };

  return (
    <Box marginBottom={styleUtils.pxToRem("65px")}>
      <Banner />
      <Box
        sx={{
          marginTop: styleUtils.pxToRem("32px"),
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          ".MuiTabs-root": { minHeight: styleUtils.pxToRem("38px") },
        }}
      >
        <StyledTabs
          isMobile={isMobile}
          onChange={handleTabChange}
          centered={!isMobile} // because its not allowed have scrollable with centered property
          variant={isMobile ? "scrollable" : "standard"}
          TabIndicatorProps={{ children: <span /> }}
          value={tabValue}
        >
          <StyledTab label={"All"} value={"All"} key={"All"} />
          {Object.keys(CATEGORY_COLORS).map((category) => (
            <StyledTab label={category} value={category} key={category} />
          ))}
        </StyledTabs>
        {!isMobile && <StyledDivider />}
        {/* Divider for mobile view is handled via styling for "& .MuiTabs-flexContainer" in StyleTabs */}
      </Box>

      <Container>
        <Grid
          container
          justifyContent={"center"}
          mt={styleUtils.pxToRem("26px")}
          spacing={2.5}
        >
          {isLoading ? (
            LOADER_LIST_DATA.map((value, index) => (
              <Grid key={index} item>
                <Skeleton
                  height={styleUtils.pxToRem("376px")}
                  width={styleUtils.pxToRem("320px")}
                />
              </Grid>
            ))
          ) : (
            <>
              {posts?.map((post) => (
                <Grid key={post.ID} item>
                  <PostCard
                    title={post.title}
                    excerpt={post.excerpt}
                    image={post.featured_image}
                    slug={post.slug}
                    content={post.content}
                    category={Object.keys(post.categories)[0]}
                    date={post.date}
                    authorName={post.author.name}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
        {pageCount > 1 && (
          <Box
            display="flex"
            justifyContent={"center"}
            marginTop={styleUtils.pxToRem("82px")}
          >
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
              sx={{
                "& .MuiPaginationItem-root:hover": {
                  backgroundColor: "#AFC5F8",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#D6E2FF",
                  borderColor: "#2B4894",
                },
                "& .MuiPaginationItem-root.Mui-selected:hover": {
                  backgroundColor: "#D6E2FF",
                  borderColor: "#2B4894",
                },
              }}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default BlogLanding;

const Banner = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <BlogLandingBannerMobile />
  ) : (
    <img src={images.blogs} width="100%" alt="Blogs" />
  );
};

const StyledTab = styled(Tab)({
  minHeight: "38px",
  height: "38px",
  alignSelf: "flex-end",
  zIndex: 1,
  paddingRight: "30px !important",
  paddingLeft: "30px !important",
});

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ isMobile }) => ({
  "& .MuiTab-root": { textTransform: "none", minHeight: 38, height: "38px" },
  "& button": { borderRadius: 222 },
  "& button.Mui-selected": { color: "white" },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& span.MuiTabs-indicator span": {
    backgroundColor: "#2B4894",
    marginTop: "-36px",
    width: "100%",
    borderRadius: "99px",
  },
  "& .MuiTabs-flexContainer": {
    height: 38,
    ...(isMobile && {
      marginLeft: 17,
      marginRight: 20,
      borderBottom: "1px solid #E4E3E3",
      width: "max-content",
    }),
  },
  "& .MuiTabs-flexContainer::before": {
    content: "''",
    minWidth: 20,
  },
  "& .MuiTabs-flexContainer::after": {
    content: "''",
    minWidth: 20,
  },
}));

const StyledDivider = styled(Divider)({
  width: "54%",
  alignSelf: "center",
  borderColor: "#E4E3E3",
});
