import PropTypes from "prop-types";
import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../atoms";
import { colors, spacing } from "../../../styles";
import ReadMore from "../ReadMore";
import { formatAMPM, dateFromNowDaily } from "../../../utils/dateUtils";

const ChatItemCard = ({ item, cardStyle, mine, timelines, id }) => {
  const showDay = !timelines.includes(dateFromNowDaily(item.createdAt));
  const currentMessage = item?.text && item?.text.trim();

  if (showDay) {
    timelines.push(dateFromNowDaily(item.createdAt));
  }

  const renderSeen = () => {
    if (item?.read) {
      return (
        <StyledSeenMark>
          <StyledMark />
          <StyledMark />
        </StyledSeenMark>
      );
    }
    if (item?.sent) {
      return (
        <Icon
          name="check"
          size={12}
          style={{ transform: "translateY(-1px)", marginLeft: spacing.xs }}
        />
      );
    }
    return null;
  };

  return (
    <>
      {showDay && (
        <MessageDayDataWrapper>
          <Text size="xs" style={{ margin: `0px ${spacing.s}` }} color={colors.white}>
            {dateFromNowDaily(item.createdAt)}
          </Text>
        </MessageDayDataWrapper>
      )}
      <ChatItemWrapper id={id} sx={[{ alignItems: mine ? "flex-end" : "flex-start" }, cardStyle]}>
        <ChatDiv
          sx={{
            alignItems: mine ? "flex-end" : "flex-start",
          }}
        >
          {currentMessage && (
            <>
              <Card
                sx={{
                  px: spacing.m,
                  py: spacing.xs,
                  backgroundColor: mine ? colors.primary : colors.white,
                  border: mine
                    ? `1px solid ${colors.primary}`
                    : `1px solid ${colors.chatBorderColor}`,
                  borderRadius: mine ? "20px 20px 0px 20px" : "20px 20px 20px 0px",
                }}
              >
                <ReadMore
                  style={{ flex: 1 }}
                  size="s"
                  color={mine ? colors.white : colors.textPrimary}
                >
                  {currentMessage}
                </ReadMore>
              </Card>
              <TimeRow>
                <Text size="xs" color={colors.disableGrey}>
                  {formatAMPM(item.createdAt)}
                </Text>
                {mine && renderSeen()}
              </TimeRow>
            </>
          )}
        </ChatDiv>
      </ChatItemWrapper>
    </>
  );
};

ChatItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  cardStyle: PropTypes.object,
  mine: PropTypes.bool,
  timelines: PropTypes.array,
  id: PropTypes.string,
};

export default ChatItemCard;

const ChatItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`;

const ChatDiv = styled("div")`
  width: max-content;
  max-width: 70%;
  display: flex;
  flex-direction: column;
`;

const MessageDayDataWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${spacing.s};
  background-color: ${colors.descriptionGray};
  width: fit-content;
  color: ${colors.white};
  margin: 0 auto;
  margin-bottom: ${spacing.xl};
`;

const TimeRow = styled("div")`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.xxs};
  width: 100%;
`;

const StyledMark = styled("div")`
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 5px;
  border-right: 2px solid ${colors.green};
  border-bottom: 2px solid ${colors.green};
`;

const StyledSeenMark = styled("div")`
  display: flex;
  align-items: center;
  gap: -10px;
`;
