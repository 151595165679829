import PropTypes from "prop-types";
import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors, spacing } from "../../../styles";
import { Icon, Text } from "../../atoms";
import { dateFromNowDaily, formatAMPM } from "../../../utils/dateUtils";

const ChatAttachmentCard = ({ item, cardStyle, mine, timelines, id }) => {
  const showDay = !timelines.includes(dateFromNowDaily(item.createdAt));
  if (showDay) {
    timelines.push(dateFromNowDaily(item.createdAt));
  }

  const renderSeen = () => {
    if (item.read) {
      return (
        <Icon
          name="eye"
          size={12}
          style={{ transform: "translateY(-1px)", marginLeft: spacing.xs }}
        />
      );
    }
    if (item.sent) {
      return (
        <Icon
          name="check"
          size={12}
          style={{ transform: "translateY(-1px)", marginLeft: spacing.xs }}
        />
      );
    }
    return null;
  };

  return (
    <>
      {showDay && (
        <Row>
          <Text size="xs" style={{ margin: `0px ${spacing.s}` }} color={colors.disableGrey}>
            {dateFromNowDaily(item.createdAt)}
          </Text>
        </Row>
      )}
      <ChatItemWrapper id={id} sx={[{ alignItems: mine ? "flex-end" : "flex-start" }, cardStyle]}>
        <ChatDiv
          sx={{
            alignItems: mine ? "flex-end" : "flex-start",
          }}
        >
          <Card
            sx={{
              px: spacing.xxs,
              py: spacing.xxs,
              backgroundColor: mine ? colors.primary : colors.white,
              border: mine ? `1px solid ${colors.primary}` : `1px solid ${colors.stroke}`,
              width: "100%",
            }}
          >
            <AttachmentWrapper
              sx={{
                backgroundColor: mine ? colors.white : colors.primary,
              }}
            >
              <Text size="xs" weight="medium" color={mine ? colors.primary : colors.white}>
                Attachment {mine ? "Sent" : "Received"}
              </Text>
            </AttachmentWrapper>
            <ContentWrapper>
              <Icon name={mine ? "file-mine" : "file"} style={{ marginRight: spacing.xs }} />
              <div style={{ display: "flex", flex: 1 }}>
                <Text size="xxs" color={mine ? colors.white : colors.primary} numberOfLines={2}>
                  {item.text}
                </Text>
              </div>
              <a href={item.attachmentUrl} download>
                <Icon
                  name={mine ? "attachment-sent" : "attachment-download"}
                  style={{ marginLeft: spacing.xs }}
                />
              </a>
            </ContentWrapper>
          </Card>
          <TimeRow>
            <Text size="xs" color={colors.disableGrey}>
              {formatAMPM(item.createdAt)}
            </Text>
            {mine && renderSeen()}
          </TimeRow>
        </ChatDiv>
      </ChatItemWrapper>
    </>
  );
};

ChatAttachmentCard.propTypes = {
  item: PropTypes.object.isRequired,
  cardStyle: PropTypes.object,
  mine: PropTypes.bool,
  timelines: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ChatAttachmentCard;

const ChatItemWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`;

const ChatDiv = styled("div")`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const AttachmentWrapper = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: ${spacing.xxs} 0;
`;

const ContentWrapper = styled("div")`
  width: 100%;
  margin-top: ${spacing.xs};
  padding-left: ${spacing.xs};
  padding-right: ${spacing.xs};
  display: flex;
  align-items: center;
  padding-bottom: ${spacing.xxs};
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px ${spacing.xl} 0px;
`;

const TimeRow = styled("div")`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.xxs};
  width: 100%;
`;
