import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Divider, Grid } from "@mui/material";

import useIsMobile from "hooks/useIsMobile";

import ForgotPasswordOtpVerification from "./web/ForgotPassword/ForgotPasswordOtpVerification";
import JobSeekerDetail from "containers/LoginEmail/views/JobSeekerDetail/JobSeekerDetail";
import EmployerDetail from "containers/LoginEmail/views/EmployerDetail/EmployerDetail";

import { spacing, styleUtils } from "../../styles";
import { EMPLOYER } from "../../appConstants";
import ResetPassword from "./web/ForgotPassword/ResetPassword";
import ResetPasswordSuccess from "./web/ForgotPassword/ResetPasswordSuccess";

const ForgotPasswordContainer = () => {
  const isMobile = useIsMobile();
  const {
    state: { userType, email, phoneNumber },
  } = useLocation();

  const navigate = useNavigate();

  const [showResetPwdScreen, setShowResetPwdScreen] = useState(false);
  const [isResetSuccess, setResetSuccess] = useState(false);

  const handlePasswordValidation = (showReset) => {
    setShowResetPwdScreen(showReset);
  };

  const handleBack = () => {
    navigate(`/loginemail/${userType}`);
  };

  let validationContent = showResetPwdScreen ? (
    <ResetPassword setResetSuccess={setResetSuccess} />
  ) : (
    <ForgotPasswordOtpVerification
      userType={userType}
      handlePasswordValidation={handlePasswordValidation}
      email={email}
      phoneNumber={phoneNumber}
      handleBack={handleBack}
    />
  );

  return (
    <Container sx={{ my: spacing.xxl }}>
      {isResetSuccess ? (
        <Card
          sx={{
            paddingTop: spacing.xl,
            paddingBottom: spacing.xxxxxl,
          }}
        >
          <ResetPasswordSuccess handleBack={handleBack} />
        </Card>
      ) : (
        <Card
          sx={{
            py: spacing.xl,
            flex: 1,
            display: "flex",
          }}
        >
          <Grid container>
            <Grid
              sx={{
                marginLeft: styleUtils.pxToRem(isMobile ? "24px" : "48px"),
                marginRight: styleUtils.pxToRem(isMobile ? "24px" : "48px"),
                marginTop: styleUtils.pxToRem(isMobile ? "24px" : "48px"),
                height: styleUtils.pxToRem(isMobile ? "643px" : "auto"),
                padding: isMobile ? spacing.xxxs : spacing.s,
              }}
            >
              {validationContent}
            </Grid>
            {!isMobile && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "100%" }}
                />
                <Grid>
                  {userType === EMPLOYER ? (
                    <EmployerDetail />
                  ) : (
                    <JobSeekerDetail />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      )}
    </Container>
  );
};

export default ForgotPasswordContainer;
