export const JOB_SEEKER_LISTING_REQUEST = "JOB_SEEKER_LISTING_REQUEST";
export const JOB_SEEKER_LISTING_SUCCESS = "JOB_SEEKER_LISTING_SUCCESS";
export const JOB_SEEKER_LISTING_FAILURE = "JOB_SEEKER_LISTING_FAILURE";
export const JOB_SEEKER_TOGGLE_FAVOURITE = "JOB_SEEKER_TOGGLE_FAVOURITE";
export const JOB_SEEKER_RESET = "JOB_SEEKER_RESET";
export const JOB_SEEKER_RATING_STATUS = "JOB_SEEKER_RATING_STATUS";
export const JOB_SEEKER_UPDATE_RATING_STATUS = "JOB_SEEKER_UPDATE_RATING_STATUS";

//Scheduler
export const JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_REQUEST =
  "JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_REQUEST";
export const JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_SUCCESS =
  "JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_SUCCESS";
export const JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_FAILED =
  "JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_FAILED";

export const JOB_SEEKER_SCHEDULER_BOOK_SLOT_REQUEST =
  "JOB_SEEKER_SCHEDULER_BOOK_SLOT_REQUEST";
export const JOB_SEEKER_SCHEDULER_BOOK_SLOT_SUCCESS =
  "JOB_SEEKER_SCHEDULER_BOOK_SLOT_SUCCESS";
export const JOB_SEEKER_SCHEDULER_BOOK_SLOT_FAILED =
  "JOB_SEEKER_SCHEDULER_BOOK_SLOT_FAILED";
