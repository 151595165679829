import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Container, styled } from "@mui/material";
import { Icon } from "../../../../../../../components/atoms";
import { EmployerJobDetailCard } from "../../../../../../../components/molecules";
import { spacing } from "../../../../../../../styles";

import RatingsModal from "components/molecules/Ratings";

import LoginSelector from "containers/Login/selectors/LoginSelector";
import useScrollYAnimationResp from "hooks/useScrollYAnimationResp";

// TO-DO: Component's name needs to be change. Its not relevant for the employer.
const MapView = ({ showRatingModal, setShowRatingModal }) => {
  const isRatingDone = useSelector(LoginSelector.getUserRatingStatus);
  const { y: yPos, hideAnimation } = useScrollYAnimationResp();
  useEffect(() => {
    if (!isRatingDone) {
      setShowRatingModal(true);
    }
  }, []);

  const navigate = useNavigate();

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <Container sx={{ mt: spacing.xxl }}>
      <IconContainer sx={{ animation: `${hideAnimation} 0.4s ease`, opacity: yPos < 10 ? 1 : 0 }}>
        <Icon
          size={24}
          name="arrow-back-black"
          style={{ marginLeft: "20px" }}
          onClick={onClickBack}
        />
      </IconContainer>
      <EmployerJobDetailCard />
      <RatingsModal isOpen={showRatingModal} setShowRatingModal={setShowRatingModal} />
    </Container>
  );
};

MapView.propTypes = {
  showRatingModal: PropTypes.bool.isRequired,
  setShowRatingModal: PropTypes.func.isRequired,
};

export default MapView;

const IconContainer = styled("div")`
  display: flex;
  height: 50px;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 5px 15px #2b48951a;
  position: fixed;
  left: auto;
  right: 0;
  width: 100%;
  top: 50px;
  z-index: 1100;
`;
