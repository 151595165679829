export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";

export const RESET_EMAIL_VALIDATION = "RESET_EMAIL_VALIDATION";
export const RESET_PHONE_VALIDATION = "RESET_PHONE_VALIDATION";
export const RESET_OTP_VIEW = "RESET_OTP_VIEW";

export const VERIFY_LOGIN_OTP_USER_REQUEST = "VERIFY_LOGIN_OTP_USER__REQUEST";
export const VERIFY_LOGIN_OTP_USER_FAILED = "VERIFY_LOGIN_OTP_USER__FAILED";
export const VERIFY_LOGIN_OTP_USER_SUCCESS = "VERIFY_LOGIN_OTP_USER__SUCCESS";
export const SEND_LOGIN_OTP_REQUEST = "SEND_LOGIN_OTP_REQUEST";
export const SEND_LOGIN_OTP_FAILED = "SEND_LOGIN_OTP_FAILED";
export const SEND_LOGIN_OTP_SUCCESS = "SEND_LOGIN_OTP_SUCCESS";
export const VERIFY_LOGIN_OTP_REQUEST = "VERIFY_LOGIN_OTP_REQUEST";
export const VERIFY_LOGIN_OTP_FAILED = "VERIFY_LOGIN_OTP_FAILED";
export const VERIFY_LOGIN_OTP_SUCCESS = "VERIFY_LOGIN_OTP_SUCCESS";
export const RESET_LOGIN_OTP_VALIDATION = "RESET_LOGIN_OTP_VALIDATION";
export const RESET_LOGIN_OTP_VIEW = "RESET_LOGIN_OTP_VIEW";
