import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import NavBar from "./views/NavBar";
import MobileNavBar from "./MobileViews/MobileNavBar";
import useIsMobile from "../../hooks/useIsMobile";

import LoginSelector from "../Login/selectors/LoginSelector";
import { EMPLOYER } from "../../appConstants";
// import useRealTimeUserChanges from "../../hooks/useRealTimeUserChanges";
// import useRealTimeDeletedUserChanges from "../../hooks/useRealTimeDeletedUserChanges";
import {
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
} from "../../routes/routeConstants";

const NavBarContainer = ({ isCountryCheckError }) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? MobileNavBar : NavBar;

  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const navigate = useNavigate();

  const onClickLogo = () => {
    if (isAuthenticated && loggedInUser?.userType === EMPLOYER) {
      navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`, {
        replace: true,
      });
    } else {
      navigate("/", { replace: true });
    }
  };

  //TO-Do- Validate and change approach

  // useRealTimeUserChanges("blockedByIds");
  // useRealTimeUserChanges("blockedIds");
  // useRealTimeUserChanges("mutedIds");
  // useRealTimeDeletedUserChanges();

  return (
    <Wrapper
      isAuthenticated={isAuthenticated}
      onClickLogo={onClickLogo}
      isCountryCheckError={isCountryCheckError}
    />
  );
};

export default NavBarContainer;
