import useIsMobile from "hooks/useIsMobile";
import PropTypes from "prop-types";
import { Button, Card, Container, Modal, styled } from "@mui/material";

import { Icon, Text } from "components/atoms";

import { colors, spacing, styleUtils } from "styles";
import { EMPLOYER } from "../../../appConstants";

export const DELETED_ACCOUNT_CONSTANTS = {
  NOT_FOUND: "account cannot be found",
  BUTTON_OK: "Okay",
  TYPE_EMPLOYER: "Employer",
  TYPE_JOBSEEKER: "Jobseeker",
};

const AbsentAccountModal = (props) => {
  const isMobile = useIsMobile();
  const { userType, isOpen, handleAccountErrorClick } = props;

  const deletedUserType =
    userType === EMPLOYER
      ? DELETED_ACCOUNT_CONSTANTS.TYPE_EMPLOYER
      : DELETED_ACCOUNT_CONSTANTS.TYPE_JOBSEEKER;

  return (
    <Modal open={isOpen} style={{ backdropFilter: "blur(2px)" }}>
      <Container sx={{ mt: spacing.xxl }}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: styleUtils.pxToRem(isMobile ? "350px" : "450px"),
            height: styleUtils.pxToRem(isMobile ? "300px" : "320px"),
            p: 2,
          }}
        >
          <ContentWrapper>
            <Icon name="absent-account" size={styleUtils.pxToRem("100px")} />
            <Text size="m" weight="bold" color={colors.black}>
              {`${deletedUserType} ${DELETED_ACCOUNT_CONSTANTS.NOT_FOUND}`}
            </Text>
            <Button
              variant="contained"
              sx={{ width: styleUtils.pxToRem("189px") }}
              onClick={handleAccountErrorClick}
            >
              {DELETED_ACCOUNT_CONSTANTS.BUTTON_OK}
            </Button>
          </ContentWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

AbsentAccountModal.propTypes = {
  userType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleAccountErrorClick: PropTypes.func.isRequired,
};

export default AbsentAccountModal;

const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${styleUtils.pxToRem("30px")};
  height: 100%;
  margin-bottom: 0;
`;
