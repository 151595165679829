import { useState } from "react";
import { Card, Container } from "@mui/material";

import SchedulerHomeContainer from "./views/SchedulerHome/SchedulerHomeContainer";
import SchedulerUserContainer from "./views/SchedulerUser/SchedulerUserContainer";

import { spacing } from "../../../../styles";

const InterviewSchedulerContainer = () => {
  const [isUserValidated, setUserValidated] = useState(false);
  const [schedulerUserDetails, setSchedulerUserDetails] = useState({});

  const handleUserValidation = (validationStatus, userDetails) => {
    setUserValidated(validationStatus);
    setSchedulerUserDetails(userDetails);
  };

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Card sx={{ py: spacing.xl, px: spacing.m }}>
        {isUserValidated && schedulerUserDetails?.id ? (
          <SchedulerHomeContainer schedulerUserDetails={schedulerUserDetails} />
        ) : (
          <SchedulerUserContainer handleUserValidation={handleUserValidation} />
        )}
      </Card>
    </Container>
  );
};

export default InterviewSchedulerContainer;
