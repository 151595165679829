import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useFormik } from "formik";
import {
  jobSeekerDetailsSchema,
  jobSeekerInterestsSchema,
  jobSeekerPreferenceSchema,
} from "../JobSeeker/signUpFormValidationSchema";

import SeekerSignUpForm from "./SeekerSignUpForm";
import SeekerInterestForm from "./SeekerInterestForm";
import SeekerPreferenceForm from "./SeekerPreferenceForm";
import SeekerSignUpDetailsView from "./SeekerSignUpDetailsView";
import EditSignUpDetails from "../../../Otp/views/EditSignUpDetails";
import EmailVerificationForm from "../../../Otp/views/EmailVerificationForm";
import { StepperComponent, Text } from "../../../../components/atoms";

import { resetSignUpRequest } from "../../actions/SignUpActions";

import useIsMobile from "../../../../hooks/useIsMobile";
import { images } from "../../../../assets/images";
import { showLoader, hideLoader, getBase64 } from "../../../../utils/commonUtils";

import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import * as FormConsts from "./constants";
import { colors, spacing, styleUtils } from "../../../../styles";

const SeekerSignUpContainer = ({
  otpReq,
  seekerFormDetails,
  handleSetFormDetails,
  handleJobSeekerSignUp,
  handleEmailEditForm,
  handleOtpSubmit,
  socialSignUpDetails,
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const { idToken, image, isSocialLogin, source, loginType } = socialSignUpDetails;
  const [signUpStep, setSignUpStep] = useState(1);
  const [imageStatus, setImageStatus] = useState();
  const [retryingImage, setRetryingImage] = useState(false);
  const [editSignUpDetails, setEditSignUpDetails] = useState(false);
  const [isDetailsEdited, setIsDetailsEdited] = useState(false);
  const [markAsCompleted, setMarkAsCompleted] = useState(false);
  const [resumeFile, setResumeFile] = useState({});
  const signUpContainerPaddingPageTwo = signUpStep === 2 ? "2.68rem" : spacing.xxxl;
  const signUpContainerPadding = isMobile ? spacing.s : signUpContainerPaddingPageTwo;

  const mobileStylesForEditContainer = {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    return () => {
      dispatch(resetSignUpRequest());
    };
  }, []);

  const handleStepperChange = (isNext) => {
    setSignUpStep(isNext ? signUpStep + 1 : signUpStep - 1);
  };

  /**
   * Job Seeker Form One Details Formik
   */
  const seekerDetailsFormik = useFormik({
    initialValues: {
      ...FormConsts.JOB_SEEKER_FORM_DETAILS,
    },
    validationSchema: jobSeekerDetailsSchema,
    validateOnBlur: true,
    onSubmit: () => {
      handleseekerDetailsFormSubmit();
    },
  });

  const handleImageStatus = (status) => {
    setImageStatus(status);
  };

  const handleseekerDetailsFormSubmit = () => {
    if ((!retryingImage && imageStatus === "error") || imageStatus === "loading") {
      window.scrollTo(0, 0);
      setRetryingImage(true);
    } else {
      handleSetFormDetails({
        ...seekerDetailsFormik.values,
        image,
        idToken,
        loginType,
        source,
      });
      handleStepperChange(true);
      if (socialSignUpDetails.loginType === "GL") {
        //Google Signup
        logEvent(FirebaseEvents.JS_GOOGLE_SIGNUP_PAGE1);
      } else if (socialSignUpDetails.loginType === "FL") {
        //Facebook Signup
        logEvent(FirebaseEvents.JS_FACEBOOK_SIGNUP_PAGE1);
      } else {
        //Signup by email
        logEvent(FirebaseEvents.JS_EMAIL_SIGNUP_PAGE1);
      }
    }
  };

  const seekerInterestsFormik = useFormik({
    initialValues: {
      ...FormConsts.JOB_SEEKER_INTERESTS_DETAILS,
    },
    validationSchema: jobSeekerInterestsSchema,
    onSubmit: (values) => {
      if (socialSignUpDetails.loginType === "GL") {
        //Google Signup
        logEvent(FirebaseEvents.JS_GOOGLE_SIGNUP_PAGE2);
      } else if (socialSignUpDetails.loginType === "FL") {
        //Facebook Signup
        logEvent(FirebaseEvents.JS_FACEBOOK_SIGNUP_PAGE2);
      } else {
        //Signup by email
        logEvent(FirebaseEvents.JS_EMAIL_SIGNUP_PAGE2);
      }
      handleSetFormDetails(values);
      handleStepperChange(true);
    },
  });

  const seekerPreferenceFormik = useFormik({
    initialValues: {
      ...FormConsts.JOB_SEEKER_PREFERENCE_FORM_DETAILS,
    },
    validationSchema: jobSeekerPreferenceSchema,
    onSubmit: (values) => {
      if (isSocialLogin) {
        handleJobSeekerSignUp(values);
        if (socialSignUpDetails.loginType === "GL") {
          //Google Signup
          logEvent(FirebaseEvents.JS_GOOGLE_SIGNUP_PAGE3);
        } else if (socialSignUpDetails.loginType === "FL") {
          //Facebook Signup
          logEvent(FirebaseEvents.JS_FACEBOOK_SIGNUP_PAGE3);
        }
      } else {
        handleSetFormDetails(values);
        logEvent(FirebaseEvents.JS_EMAIL_SIGNUP_PAGE3);
        handleStepperChange(true);
      }
    },
  });

  const onUploadAttachments = async (file) => {
    showLoader();
    const encodedFile = await getBase64(file);
    setResumeFile(file);
    hideLoader();
    logEvent(FirebaseEvents.JS_CLICK_SAVE_RESUME);
    seekerPreferenceFormik.setFieldValue("resumeUrl", encodedFile);
    seekerPreferenceFormik.setFieldValue("resume_filename", file.name);
  };

  const handleEditNav = (isSave, values) => {
    if (isSave) {
      handleEmailEditForm(values);
      seekerDetailsFormik.setFieldValue("email", values.email);
      setEditSignUpDetails(false);
    } else {
      setEditSignUpDetails(false);
    }
  };

  const ImageForSignUpPageTwoAndThree =
    signUpStep === 2 ? (
      <PreferencesImageContainer>
        <InterestsImgOne src={images.imginterestreview} />
        <InterestsImgTwo src={images.imginterestsignup} />
      </PreferencesImageContainer>
    ) : (
      <PreferencesImageContainer>
        <PreferencesImgOne src={images.imgpreferencereview} />
        <PreferencesImgTwo src={images.imgpreferencesignup} />
      </PreferencesImageContainer>
    );

  return (
    <>
      <Grid item xs={12} sm={12} md={6} sx={isMobile && { ...mobileStylesForEditContainer }}>
        {editSignUpDetails ? (
          <EditSignUpDetails
            details={otpReq}
            isEmployer={false}
            isEmailEdit={true}
            handleEditNav={handleEditNav}
          />
        ) : (
          <SeekerContainer
            isMobile={isMobile}
            sx={{
              padding: signUpContainerPadding,
            }}
          >
            <Text
              size="xl"
              weight="semibold"
              color={colors.titleBlack}
              sx={{
                marginTop: spacing.xs,
              }}
            >
              {FormConsts[`SIGN_UP_LABEL_${signUpStep}`]}
            </Text>
            {signUpStep > 1 && signUpStep < 4 && (
              <Text size="s" color={colors.subtextGray} sx={{ marginTop: spacing.xxxs }}>
                {FormConsts[`SIGN_UP_SUB_TEXT_${signUpStep}`]}
              </Text>
            )}

            <StepperComponent
              stepsCount={isSocialLogin ? 3 : 4}
              activeStep={signUpStep}
              markComplete={markAsCompleted}
              sx={{
                mt: spacing.m,
                mb: spacing.xl,
                width: "70%",
              }}
            />
            {
              {
                1: (
                  <SeekerSignUpForm
                    formik={seekerDetailsFormik}
                    formDetails={seekerFormDetails}
                    imageStatus={imageStatus}
                    socialSignUpDetails={socialSignUpDetails}
                    handleImageStatus={handleImageStatus}
                  />
                ),
                2: (
                  <SeekerInterestForm
                    formik={seekerInterestsFormik}
                    handleNav={handleStepperChange}
                  />
                ),
                3: (
                  <SeekerPreferenceForm
                    resumeFile={resumeFile}
                    setResumeFile={setResumeFile}
                    formik={seekerPreferenceFormik}
                    handleNav={handleStepperChange}
                    onUploadAttachments={onUploadAttachments}
                    isSocialLogin={socialSignUpDetails?.isSocialLogin}
                  />
                ),
                4: !socialSignUpDetails?.isSocialLogin && (
                  <EmailVerificationForm
                    editEmail={setEditSignUpDetails}
                    handleBack={handleStepperChange}
                    handleOtpSubmit={handleOtpSubmit}
                    isDetailsEdited={isDetailsEdited}
                    setIsDetailsEdited={setIsDetailsEdited}
                    setMarkAsCompleted={setMarkAsCompleted}
                    sourceDetails={otpReq}
                  />
                ),
              }[signUpStep]
            }
          </SeekerContainer>
        )}
      </Grid>

      {!isMobile && (
        <>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} sm container>
            {signUpStep > 1 && signUpStep < 4 ? (
              ImageForSignUpPageTwoAndThree
            ) : (
              <>
                <SeekerSignUpDetailsView />
                <ImgContainer
                  src={images.imgjobseekersignup}
                  sx={{
                    marginTop: styleUtils.pxToRem(signUpStep === 4 ? "100px" : "330px"),
                    marginBottom: styleUtils.pxToRem(signUpStep === 4 ? "62.5px" : "172px"),
                  }}
                />
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
export default SeekerSignUpContainer;

SeekerSignUpContainer.propTypes = {
  otpReq: PropTypes.object,
  seekerFormDetails: PropTypes.object,
  handleSetFormDetails: PropTypes.func,
  handleJobSeekerSignUp: PropTypes.func,
  handleEmailEditForm: PropTypes.func,
  handleOtpSubmit: PropTypes.func,
  socialSignUpDetails: PropTypes.object,
};

const SeekerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ImgContainer = styled("img")`
  display: block;
  align-self: center;
  margin-left: ${styleUtils.pxToRem("105.5px")};
  height: ${styleUtils.pxToRem("192px")};
  width: ${styleUtils.pxToRem("243px")};
`;

const PreferencesImageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${styleUtils.pxToRem("48px")};
  margin-left: ${styleUtils.pxToRem("74px")};
`;

const PreferencesImgOne = styled("img")`
  height: ${styleUtils.pxToRem("450px")};
  width: ${styleUtils.pxToRem("420px")};
`;

const PreferencesImgTwo = styled("img")`
  align-self: center;
  height: ${styleUtils.pxToRem("278px")};
  width: ${styleUtils.pxToRem("278px")};
  margin-top: ${styleUtils.pxToRem("150px")};
  margin-bottom: ${styleUtils.pxToRem("150px")};
`;

const InterestsImgOne = styled("img")`
  height: ${styleUtils.pxToRem("227px")};
  width: ${styleUtils.pxToRem("370px")};
`;

const InterestsImgTwo = styled("img")`
  align-self: center;
  height: ${styleUtils.pxToRem("228px")};
  width: ${styleUtils.pxToRem("238px")};
  margin-top: ${styleUtils.pxToRem("99px")};
  margin-bottom: ${styleUtils.pxToRem("47px")};
`;
