import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Text } from "../../../components/atoms";
import { colors } from "../../../styles";
import { NavContext } from "./NavContext";

const NavTextLink = ({ to, text }) => {
  const { handleDrawerClose } = useContext(NavContext);
  return (
    <NavLink
      onClick={handleDrawerClose}
      to={to}
      style={{
        textDecoration: "none",
      }}
    >
      {({ isActive }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text
            color={isActive ? colors.primary : colors.textPrimary}
            size="l"
            weight="medium"
            sx={{ transform: "none" }} // to negate global translateY(-1)
          >
            {text}
          </Text>
        </div>
      )}
    </NavLink>
  );
};

export default NavTextLink;
