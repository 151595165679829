import useIsMobile from "../../../hooks/useIsMobile";
import Input from "./views/Input";
import InputMobile from "./mobileViews/Input";

const EmployerSearchInput = (props) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? InputMobile : Input;

  return <Wrapper {...props} />;
};

export default EmployerSearchInput;
