import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import copy from "copy-to-clipboard";

import CreateAvailabilityView from "./CreateAvailabilityView";

import { getSchedulerShareLink } from "../../../../actions/EmployerActions";
import EmployerSelector from "../../../../selectors/EmployerSelector";

import { Icon, Text } from "../../../../../../components/atoms";
import {
  showLoader,
  showNotification,
} from "../../../../../../utils/commonUtils";

import { spacing, styleUtils, textSizes } from "../../../../../../styles";
import {
  AVAILAIBLE_SCHEDULE_ROW_KEYS,
  AVAILAIBLE_SCHEDULE_TABLE_CONSTS,
  UPCOMING_SCHEDULE_ROW_KEYS,
  UPCOMING_SCHEDULE_TABLE_CONSTS,
} from "../../constants";
import { SchdeulerListingTable } from "../../../../../../components/molecules";

const SchedulerHomeView = ({
  handleCreateAvailModal,
  showCreateAvailModal,
  createAvailabilityFormik,
}) => {
  const dispatch = useDispatch();

  const schedulerUserDetails = useSelector(
    EmployerSelector.getSchedulerUserDetails
  );
  const schedulerShareLink = useSelector(
    EmployerSelector.getSchedulerShareLink
  );
  const availabilityList = useSelector(
    EmployerSelector.getSchedulerAvailabilityList
  );
  const upcomingList = useSelector(EmployerSelector.getSchedulerUpcomingList);

  const handleGetShareLink = () => {
    if (schedulerUserDetails?.id) {
      showLoader();
      dispatch(
        getSchedulerShareLink({
          userId: schedulerUserDetails?.id,
        })
      );
    }
  };

  const onClickCopy = () => {
    copy(schedulerShareLink);

    showNotification({
      alertProps: {
        severity: "info",
        children: "Link copied to clipboard.",
      },
    });
  };

  const MeetingLink = (
    <div
      style={{
        marginLeft: spacing.s,
        display: "inline-flex",
        marginTop: spacing.xs,
      }}
    >
      <LinkWrapper>
        <Text size="l" style={{ marginRight: spacing.xxxxl }}>
          {schedulerShareLink?.slice(38)}
        </Text>
        <Icon
          size={24}
          onClick={onClickCopy}
          name="profile-copy"
          style={{ cursor: "pointer" }}
        />
      </LinkWrapper>
    </div>
  );

  return (
    <>
      <div style={{ marginBottom: spacing.m }}>
        <Button
          variant="contained"
          sx={{
            marginRight: "auto",
            width: styleUtils.pxToRem("216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
            marginLeft: spacing.s,
          }}
          onClick={() => handleCreateAvailModal(true)}
        >
          Create Availability
        </Button>
        <Button
          variant="outlined"
          sx={{
            width: styleUtils.pxToRem("216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
            marginLeft: spacing.s,
          }}
          onClick={handleGetShareLink}
        >
          Get Calendar Link
        </Button>
        {schedulerShareLink && MeetingLink}

        <SchdeulerListingTable
          tableTitle="Availability"
          cloumnHeaders={AVAILAIBLE_SCHEDULE_TABLE_CONSTS}
          rowKeys={AVAILAIBLE_SCHEDULE_ROW_KEYS}
          rowValues={availabilityList}
        />
        <SchdeulerListingTable
          tableTitle="Upcoming"
          cloumnHeaders={UPCOMING_SCHEDULE_TABLE_CONSTS}
          rowKeys={UPCOMING_SCHEDULE_ROW_KEYS}
          rowValues={upcomingList}
        />
        <CreateAvailabilityView
          handleCreateAvailModal={handleCreateAvailModal}
          showCreateAvailModal={showCreateAvailModal}
          createAvailabilityFormik={createAvailabilityFormik}
        />
      </div>
    </>
  );
};

export default SchedulerHomeView;

const LinkWrapper = styled("div")`
  border-radius: 5px;
  border: 1px solid #e6edff;
  padding: ${spacing.xs};
  margin-top: ${spacing.xs};
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: ${textSizes.m};
  height: ${styleUtils.pxToRem("42px")};
`;
