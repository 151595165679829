import * as jobSeekerActionTypes from "./actionTypes";

export const jobSeekerListingRequest = (payload) => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_LISTING_REQUEST,
    payload,
  };
};

export const toggleFavourite = (payload) => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_TOGGLE_FAVOURITE,
    payload,
  };
};

export const jobSeekerReset = () => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_RESET,
  };
};

//Scheduler
export const jobSeekerGetEmployerAvailabilityReq = (payload) => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_REQUEST,
    payload,
  };
};

export const jobSeekerSchedulerBookSlot = (payload) => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_BOOK_SLOT_REQUEST,
    payload,
  };
};


//for ratings
export const jobSeekerRatingStatus = () => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_RATING_STATUS,
  }
};

export const setJobSeekerRatingStatus = () => {
  return {
    type: jobSeekerActionTypes.JOB_SEEKER_UPDATE_RATING_STATUS,
  }
};
