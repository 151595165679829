import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Container, Modal, styled } from "@mui/material";
import Text from "../Text";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  CANCEL_LEAVE_MODAL_BUTTON_CANCEL,
  CANCEL_LEAVE_MODAL_BUTTON_LEAVE,
  CANCEL_LEAVE_MODAL_SUBTEXT1,
  CANCEL_LEAVE_MODAL_SUBTEXT2,
} from "../../../containers/Employer/views/AddJob/AddJobConstants";
import { colors, spacing, styleUtils } from "../../../styles";

const CancelModal = ({ isOpen, handleClose }) => {
  const isMobile = useIsMobile();
  return (
    <Modal open={isOpen} style={{ backdropFilter: "blur(2px)" }}>
      <Container sx={{ mt: spacing.xxl }}>
        <CancelModalCard isMobile={isMobile}>
          <ContentWrapper>
            <div style={{ textAlign: "center" }}>
              <Text size="l" weight="medium" color={colors.black}>
                {CANCEL_LEAVE_MODAL_SUBTEXT1}
                <br />
                {CANCEL_LEAVE_MODAL_SUBTEXT2}
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: styleUtils.pxToRem(isMobile ? "120px" : "148px"),
                  height: styleUtils.pxToRem("40px"),
                }}
                onClick={() => handleClose(true)}
              >
                {CANCEL_LEAVE_MODAL_BUTTON_CANCEL}
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: styleUtils.pxToRem(isMobile ? "120px" : "148px"),
                  height: styleUtils.pxToRem("40px"),
                }}
                onClick={() => handleClose(false)}
              >
                {CANCEL_LEAVE_MODAL_BUTTON_LEAVE}
              </Button>
            </div>
          </ContentWrapper>
        </CancelModalCard>
      </Container>
    </Modal>
  );
};

CancelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default CancelModal;

const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${styleUtils.pxToRem("30px")};
  height: 100%;
  margin-bottom: 0;
`;

const CancelModalCard = styled(Card)`
  position: absolute;
  border-radius: 20px;
  top: ${(props) => (props.isMobile ? "50%" : "20%")};
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => styleUtils.pxToRem(props.isMobile ? "320px" : "450px")};
  height: ${(props) => styleUtils.pxToRem(props.isMobile ? "190px" : "200px")};
`;
