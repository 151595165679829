import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import HomeBrands from "./views/HomeBrands";
import HomeBrandsMobile from "./mobileViews/HomeBrands";

import SearchLocalJobs from "./views/SearchLocalJobs";
import SearchLocalJobsMobile from "./mobileViews/SearchLocalJobs";

import EasyOneClick from "./views/EasyOneClick";
import EasyOneClickMobile from "./mobileViews/EasyOneClick";

import ChatDirectly from "./views/ChatDirectly";
import ChatDirectlyMobile from "./mobileViews/ChatDirectly";

import Reviews from "./views/Reviews";
import ReviewsMobile from "./mobileViews/Reviews";

import { spacing, styleUtils } from "../../styles";
import LoginSelector from "../Login/selectors/LoginSelector";
import useIsMobile from "../../hooks/useIsMobile";

import SearchWrapper from "./SearchWrapper";
import { JOB_SEEKER } from "../../appConstants";

import { images } from "../../assets/images";

const HomeView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();
  const referrerId = searchParams.get("referrerId");
  const referrerType = searchParams.get("referrerType");
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);

  const imagesValue = [
    images.home["jobseeker-background"]["1"],
    images.home["jobseeker-background"]["2"],
    images.home["jobseeker-background"]["3"],
    images.home["jobseeker-background"]["4"],
  ];

  const imagesResponsiveValue = [
    images.home["jobseeker-responsive-background"]["1"],
    images.home["jobseeker-responsive-background"]["2"],
    images.home["jobseeker-responsive-background"]["3"],
    images.home["jobseeker-responsive-background"]["4"],
  ];

  const [currentImage, setCurrentImage] = useState(0);

  const onGetStarted = () => {
    navigate(`/login/${JOB_SEEKER}`, {
      state: {
        path: location.pathname,
        referrer: { referrerId, referrerType },
      },
    });
  };

  useEffect(() => {
    let timer;
    timer = setInterval(() => {
      changeBackgroundImage();
    }, 3000);

    return () => clearInterval(timer);
  }, [currentImage]);

  const changeBackgroundImage = () => {
    let newCurrentImg = 0;
    const noOfImages = imagesValue.length;
    if (currentImage !== noOfImages - 1) {
      newCurrentImg = currentImage + 1;
    }
    setCurrentImage(newCurrentImg);
  };

  const allReviews = [
    {
      name: "Albert Winkler",
      job: "Cashier",
      location: "Newtown, PA",
      title: "I found my perfect job in just 2 minutes",
      message:
        "EmployD does precisely what it says in the description, and I'm happy with the results so far. It's a really simple process that only takes about 2 minutes to start",
    },
    {
      name: "Ralph Font",
      job: "Sales Associate",
      location: "Newtown, PA",
      title: "Loved it, Will recommend to everyone",
      message:
        "I've gotten some really cool opportunities through it and the fact that you can chat with the employers directly is fantastic. I'd recommend it to anyone looking for a part-time job!",
    },
    {
      name: "Elizabeth Delph",
      job: "Crew Member",
      location: "Newtown, PA",
      title: "Best part-time job search platform",
      message:
        "I was able to find a job in less than a week. The best part is it was local and I didn't have to travel anywhere. It took me several months to find a job before using EmployD.",
    },
    {
      name: "Raegan Fowler",
      job: "Produce Clerk",
      location: "Newtown, PA",
      title: "One click and I landed my dream job",
      message:
        "EmployD is the best way to find work. The one-click apply makes it easy to apply to jobs, and good jobs are posted here",
    },
  ];

  const HomeBrandsWrapper = isMobile ? HomeBrandsMobile : HomeBrands;
  const SearchLocalJobsWrapper = isMobile
    ? SearchLocalJobsMobile
    : SearchLocalJobs;
  const EasyOneClickWrapper = isMobile ? EasyOneClickMobile : EasyOneClick;
  const ChatDirectlyWrapper = isMobile ? ChatDirectlyMobile : ChatDirectly;
  const ReviewsWrapper = isMobile ? ReviewsMobile : Reviews;

  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        marginBottom: spacing.xxl,
      }}
    >
      <div
        style={{
          backgroundImage: isMobile
            ? `url(${imagesResponsiveValue[currentImage]})`
            : `url(${imagesValue[currentImage]})`,
          padding: 0,
          margin: 0,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: isMobile ? styleUtils.pxToRem("594px") : "auto",
        }}
      >
        <SearchWrapper />
      </div>
      <Divider />
      <HomeBrandsWrapper />
      <Divider />
      <SearchLocalJobsWrapper
        isAuthenticated={isAuthenticated}
        onGetStarted={onGetStarted}
      />
      <EasyOneClickWrapper
        isAuthenticated={isAuthenticated}
        onGetStarted={onGetStarted}
      />
      <ChatDirectlyWrapper
        isAuthenticated={isAuthenticated}
        onGetStarted={onGetStarted}
      />
      <ReviewsWrapper reviews={allReviews} />
    </div>
  );
};

export default HomeView;
