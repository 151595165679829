import { takeLatest, put, all, call } from "redux-saga/effects";
import { userExistApi, sendOtpApi, verifyOtpApi } from "../../../configs/network/apis";
import { showLoader, hideLoader } from "../../../utils/commonUtils";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import * as loginOtpActions from "../actions/actionTypes";

/**
 * Verify login otp Saga handler
 */
function* handleLoginOtpVerify(action) {
  const { payload } = action;
  const { onCallback } = payload;

  try {
    showLoader();
    const res = yield call(verifyOtpApi, payload);
    const { verifiedStatus, target } = res.data;
    const key = target === "email" ? "isEmailOtpValid" : "isPhoneOtpValid";
    const response = { verifiedStatus, key };
    yield put({
      type: loginOtpActions.VERIFY_LOGIN_OTP_SUCCESS,
      response,
    });
    logEvent(FirebaseEvents.EMP_PHONE_SIGNIN);
    if (verifiedStatus) onCallback({ response });

    hideLoader();
  } catch (error) {
    yield put({ type: loginOtpActions.VERIFY_LOGIN_OTP_FAILED });
    hideLoader();
  }
}

/**
 * Send login otp Saga handler
 */
function* handleSendLoginOtp(action) {
  const { payload } = action;
  const { onCallback } = payload;

  try {
    showLoader();
    const res = yield call(sendOtpApi, payload);
    const message = res.data;
    if (
      message &&
      (message === "OTP shared on requested email" ||
        message === "OTP shared on requested phone number")
    ) {
      yield put({
        type: loginOtpActions.SEND_LOGIN_OTP_SUCCESS,
      });
    } else {
      yield put({
        type: loginOtpActions.SEND_LOGIN_OTP_FAILED,
      });
    }
    hideLoader();
  } catch (error) {
    yield put({
      type: loginOtpActions.SEND_LOGIN_OTP_FAILED,
    });
    hideLoader();
  }
  if (onCallback) {
    onCallback(() => {});
  }
}

/**
 * User verifier handler
 */
function* handleUserVerification(action) {
  const { payload } = action;
  const { onCallback } = payload;

  try {
    showLoader();
    const res = yield call(userExistApi, payload);
    const response = res.data;
    hideLoader();
    yield put({
      type: loginOtpActions.VERIFY_LOGIN_OTP_USER_SUCCESS,
      response,
    });
    onCallback(response);
  } catch (err) {
    yield put({ type: loginOtpActions.VERIFY_LOGIN_OTP_USER_FAILED });
    hideLoader();
    if (err.response.status === 404) {
      onCallback(err.response.data);
    }
  }
}

function* watchLoginOtpVerifyRequest() {
  yield takeLatest(loginOtpActions.VERIFY_LOGIN_OTP_REQUEST, handleLoginOtpVerify);
}

function* watchSendLoginOtpRequest() {
  yield takeLatest(loginOtpActions.SEND_LOGIN_OTP_REQUEST, handleSendLoginOtp);
}

function* watchLoginOtpUserRequest() {
  yield takeLatest(loginOtpActions.VERIFY_LOGIN_OTP_USER_REQUEST, handleUserVerification);
}

export function* loginOtpSagas() {
  yield all([watchLoginOtpUserRequest(), watchSendLoginOtpRequest(), watchLoginOtpVerifyRequest()]);
}
