import { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled } from "@mui/material/styles";

import { IconButton, Text } from "../../../../../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../../../../../styles";
import { sortingOptions } from "../../Constants";

/**
 * Sorting Menu for jobs
 * by distance, wage and work hours
 */
const Sort = ({ handleSorting }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (type) => () => {
    setAnchorEl(null);
    let sortOrder = "";
    sortingOptions.forEach((item) => {
      if (item.key === type) {
        sortOrder = !item.order || item.order === "desn" ? "ascn" : "desn";
        item.order = sortOrder;
      } else {
        item.order = "";
      }
    });
    handleSorting(type, sortOrder);
  };

  const handleResetSort = () => {
    setAnchorEl(null);
    sortingOptions.forEach((item) => {
      item.order = "";
    });
    handleSorting("clear", "");
  };

  return (
    <div style={{ flexShrink: 0 }}>
      <IconButton
        name="sort-az"
        size={26}
        style={{
          marginLeft: styleUtils.pxToRem("12px"),
          marginRight: styleUtils.pxToRem("8px"),
        }}
        onClick={handleClick}
      />
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: colors.white,
            borderWidth: 0,
          },
        }}
      >
        {sortingOptions.map((option) => {
          return (
            <SortingMenuItem key={option.key} onClick={onClickMenuItem(option.key)}>
              <Text size="s" weight="medium">
                {option.title}
              </Text>
              {option.order &&
                (option.order === "ascn" ? (
                  <ArrowUpwardIcon sx={{ color: colors.primary, fontSize: "small" }} />
                ) : (
                  <ArrowDownwardIcon sx={{ color: colors.primary, fontSize: "small" }} />
                ))}
            </SortingMenuItem>
          );
        })}
        <ResetMenuItem key="clear" onClick={handleResetSort}>
          <Text size="s" weight="medium">
            Clear all
          </Text>
        </ResetMenuItem>
      </Menu>
    </div>
  );
};

Sort.propTypes = {
  handleSorting: PropTypes.func.isRequired,
};

export default Sort;

const SortingMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
  },
  minWidth: 160,
  marginBottom: spacing.xxs,
  borderBottom: `1px solid ${colors.shadow}`,
}));

const ResetMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
    textDecorationColor: colors.primary,
  },
  minWidth: 160,
  marginBottom: spacing.xxs,
}));
