import { Grid } from "@mui/material";

import {
  EmptyView,
  PeopleCardSkeleton,
} from "../../../../../../../components/molecules";

import { spacing } from "../../../../../../../styles";

/**
 * ShortListed JobSeekers Listed View
 * @returns List of shrortlisted job seekers for selected location
 */
const ShortListedSeekers = ({
  isEmployerPeopleListLoading,
  renderPeople,
  shortListedJobSeekers,
}) => {
  const peopleShimmerLimit = [1, 2, 3, 4, 5, 6];

  const renderPeopleCardSkeleton = (value) => {
    return (
      <Grid key={value} item md={4} mb={spacing.l}>
        <PeopleCardSkeleton />
      </Grid>
    );
  };

  let peopleCardsContent =
    shortListedJobSeekers.length > 0 ? (
      shortListedJobSeekers.map(renderPeople)
    ) : (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: spacing.l,
        }}
      >
        <EmptyView
          image="no-recommended-jobs"
          imageHeight="75%"
          title="No people found yet"
          description="Keep track of people you’re interested in"
        />
      </div>
    );

  return (
    <Grid container mt={spacing.s} columnSpacing={spacing.l}>
      {isEmployerPeopleListLoading
        ? peopleShimmerLimit.map(renderPeopleCardSkeleton)
        : peopleCardsContent}
    </Grid>
  );
};

export default ShortListedSeekers;
