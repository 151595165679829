import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../components/atoms";
import { EMPLOYER, JOB_SEEKER } from "../../../appConstants";
import { colors, spacing } from "../../../styles";
import { useSelector } from "react-redux";

import { UnAuthenticatedNavConstants } from "../NavBarConstants";
import LoginSelector from "../../Login/selectors/LoginSelector";
import { logEvent } from "../../../configs/firebase";

import "./LoginButton.css";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const UnAuthenticatedNavContainer = ({ isCountryCheckError }) => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const referrerId = searchParams.get("referrerId");
  const referrerType = searchParams.get("referrerType");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (type) => () => {
    if (type === "employer") {
      logEvent(FirebaseEvents.MOBILE_MENU_EMP, {
        items: [{ status: "success" }],
      });
    } else {
      logEvent(FirebaseEvents.MOBILE_MENU_JS, {
        items: [{ status: "success" }],
      });
    }

    setAnchorEl(null);
    navigate(`/login/${type}`, {
      state: {
        path: location.pathname,
        referrer: { referrerId, referrerType },
      },
    });
  };

  const handleClickForRestrictedCountry = () => {
    navigate("/", { from: location });
    setAnchorEl(null);
  };

  return (
    <>
      {!isAuthenticated && (
        <>
          <Button
            variant="outlined"
            onClick={handleClick}
            endIcon={<Icon size={10} name="arrow-down" />}
            sx={{
              width: "auto",
              marginLeft: spacing.s,
            }}
          >
            {UnAuthenticatedNavConstants.AUTH_SELECT_BUTTON}
          </Button>
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: colors.white,
                borderWidth: 0,
              },
            }}
          >
            <LoginMenuItem
              className="unauthentication-nav-menu-item"
              onClick={
                isCountryCheckError
                  ? handleClickForRestrictedCountry
                  : onClickMenuItem(JOB_SEEKER)
              }
            >
              <Text size="s" weight="medium">
                {UnAuthenticatedNavConstants.SELECT_JOB_SEEKER_BUTTON}
              </Text>
            </LoginMenuItem>
            <LoginMenuItem
              className="unauthentication-nav-menu-item"
              onClick={
                isCountryCheckError
                  ? handleClickForRestrictedCountry
                  : onClickMenuItem(EMPLOYER)
              }
            >
              <Text size="s" weight="medium">
                {UnAuthenticatedNavConstants.SELECT_EMPLOYER_BUTTON}
              </Text>
            </LoginMenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default UnAuthenticatedNavContainer;

const LoginMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.primary,
  },
  minWidth: 136,
  marginBottom: spacing.xxs,
}));
