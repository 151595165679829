import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { loginOtpInitialState } from "./initialState";
import * as loginOtpActionTypes from "../actions/actionTypes";

const loginOtpReducer = {
  loginOtpState: handleActions(
    {
      [loginOtpActionTypes.SEND_LOGIN_OTP_REQUEST]: (state) => {
        return immutable(state, {
          sendLoginOtpLoading: { $set: true },
          sendLoginOtpFailed: { $set: false },
          sendLoginOtpSuccess: { $set: false },
        });
      },
      [loginOtpActionTypes.SEND_LOGIN_OTP_FAILED]: (state) => {
        return immutable(state, {
          sendLoginOtpLoading: { $set: false },
          sendLoginOtpFailed: { $set: true },
          sendLoginOtpSuccess: { $set: false },
        });
      },
      [loginOtpActionTypes.SEND_LOGIN_OTP_SUCCESS]: (state) => {
        return immutable(state, {
          sendLoginOtpLoading: { $set: false },
          sendLoginOtpFailed: { $set: false },
          sendLoginOtpSuccess: { $set: true },
        });
      },
      [loginOtpActionTypes.VERIFY_LOGIN_OTP_REQUEST]: (state) => {
        return immutable(state, {
          verifyLoginOtpLoading: { $set: true },
          verifyLoginOtpFailed: { $set: false },
          verifyLoginOtpSuccess: { $set: false },
        });
      },
      [loginOtpActionTypes.VERIFY_LOGIN_OTP_FAILED]: (state) => {
        return immutable(state, {
          verifyLoginOtpLoading: { $set: false },
          verifyLoginOtpFailed: { $set: true },
          verifyLoginOtpSuccess: { $set: false },
        });
      },
      [loginOtpActionTypes.VERIFY_LOGIN_OTP_SUCCESS]: (
        state,
        { response: { verifiedStatus } }
      ) => {
        return immutable(state, {
          verifyLoginOtpLoading: { $set: false },
          verifyLoginOtpFailed: { $set: false },
          verifyLoginOtpSuccess: { $set: true },
          isLoginOtpValid: { $set: verifiedStatus },
        });
      },
      [loginOtpActionTypes.RESET_LOGIN_OTP_VALIDATION]: (state) => {
        return immutable(state, {
          userValidation: { $set: false },
        });
      },
      [loginOtpActionTypes.RESET_LOGIN_OTP_VIEW]: (state) => {
        return immutable(state, {
          sendLoginOtpLoading: { $set: false },
          sendLoginOtpFailed: { $set: false },
          sendLoginOtpSuccess: { $set: false },
          verifyLoginOtpLoading: { $set: false },
          verifyLoginOtpFailed: { $set: false },
          verifyLoginOtpSuccess: { $set: false },
          isLoginOtpValid: { $set: false },
          userValidation: { $set: false },
        });
      },
    },
    loginOtpInitialState
  ),
};

export default loginOtpReducer;
