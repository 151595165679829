import { takeLatest, put, all, call } from "redux-saga/effects";
import * as otpActionTypes from "../actions/actionTypes";
import { sendOtpApi, verifyOtpApi } from "../../../configs/network/apis";

/**
 * Verify Otp Saga handler
 */
function* handleVerifyOtp(action) {
  const { payload } = action;
  try {
    const res = yield call(verifyOtpApi, payload);
    const { verifiedStatus, target } = res.data;
    const key = target === "email" ? "isEmailOtpValid" : "isPhoneOtpValid";
    const validationSrc = target === "email" ? "emailValidation" : "phoneValidation";
    const response = { verifiedStatus, key, validationSrc };
    yield put({
      type: otpActionTypes.VERIFY_OTP_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: otpActionTypes.VERIFY_OTP_FAILED });
  }
}

/**
 * Send otp Saga handler
 */
function* handleSendOtp(action) {
  const { payload } = action;
  const { onCallback } = payload;

  try {
    const res = yield call(sendOtpApi, payload);
    const message = res.data;

    if (
      message &&
      (message === "OTP shared on requested email" ||
        message === "OTP shared on requested phone number")
    ) {
      yield put({
        type: otpActionTypes.SEND_OTP_SUCCESS,
      });
    } else {
      yield put({
        type: otpActionTypes.SEND_OTP_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: otpActionTypes.SEND_OTP_FAILED,
    });
  }
  if (onCallback) {
    onCallback(() => {});
  }
}

function* watchVerifyOtpRequest() {
  yield takeLatest(otpActionTypes.VERIFY_OTP_REQUEST, handleVerifyOtp);
}

function* watchSendOtpRequest() {
  yield takeLatest(otpActionTypes.SEND_OTP_REQUEST, handleSendOtp);
}

export function* otpSagas() {
  yield all([watchVerifyOtpRequest(), watchSendOtpRequest()]);
}
