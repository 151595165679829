import PropTypes from "prop-types";

import { Avatar, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "components/atoms";
import Toggler from "components/atoms/Toggler/Toggler";

import { getFormattedJobLocation, getProcessedJobDetailValue } from "utils/commonUtils";
import { renderImage } from "utils/imageUtils";

import { images } from "assets/images";
import { colors, spacing } from "styles";

const EmpJobDetailCardMobile = ({
  statusOfCurrJob,
  getJobDetailsContainerItems,
  item,
  handleStateOfJob,
  onClickCopy,
  onClickEdit,
}) => {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs
          sm
          md
        >
          <TopContainer
            sx={{
              opacity: statusOfCurrJob === "active" ? 1 : 0.3,
            }}
          >
            <Avatar
              src={renderImage(item?.brandLogo, "job")}
              variant="rounded"
              sx={{ width: 57, height: 57, borderRadius: 57 / 2 }}
            >
              <img
                alt={item?.listingPosition}
                src={images.defaults.job}
                style={{ width: 57, height: 57, borderRadius: 57 / 2 }}
              />
            </Avatar>
            <TitleContainer sx={{ marginLeft: "20px" }}>
              <Text
                weight="bold"
                size="l"
                numberOfLines={4}
              >
                {item?.listingPosition}
              </Text>
              <Text
                color={colors.placeholder}
                size="xs"
              >
                {item?.brandName}
              </Text>
              <Text
                color={colors.placeholder}
                size="xs"
              >
                {getFormattedJobLocation(item?.city)}
              </Text>
            </TitleContainer>
          </TopContainer>
        </Grid>
        <Grid
          item
          xs="auto"
          sm="auto"
          md="auto"
        >
          <IconContainer sx={{ gap: spacing.l, alignItems: "center" }}>
            <IconLabel sx={{ marginTop: "-1px" }}>
              <Toggler
                checked={statusOfCurrJob === "active"}
                onChange={() => handleStateOfJob(statusOfCurrJob?.toLowerCase())}
                inputProps={{ "aria-label": "controlled" }}
              />
            </IconLabel>
            <IconLabel className="duplicate">
              <Icon
                size={23}
                name="duplicate"
                onClick={onClickCopy}
                style={{ cursor: "pointer" }}
              />
            </IconLabel>
            <IconLabel>
              <Icon
                name="edit"
                size={21}
                onClick={onClickEdit}
              />
            </IconLabel>
          </IconContainer>
        </Grid>
      </Grid>

      <Divider />
      <div
        style={{
          overflow: "auto",
          opacity: statusOfCurrJob === "active" ? 1 : 0.3,
        }}
      >
        <Grid
          container
          overflow="auto"
          sx={{
            margin: `${spacing.s} 0`,
          }}
        >
          <Grid
            item
            xs
            sm
            md
          >
            <div
              style={{
                padding: `${spacing.xs} 0`,
              }}
            >
              <Icon
                name="money"
                alt={"money"}
                size={24}
              />
              <Text
                weight="medium"
                color={colors.blackText}
              >
                {`$${getProcessedJobDetailValue(item?.wage)} per hour`}
              </Text>
              <Icon
                name="clock"
                alt={"clock"}
                size={24}
                style={{ marginTop: spacing.s }}
              />
              <Text
                weight="medium"
                color={colors.blackText}
              >
                {`${getProcessedJobDetailValue(item?.hoursListed)} hours per week`}
              </Text>
              <Icon
                name="experience"
                alt={"experience"}
                size={24}
                style={{ marginTop: spacing.s }}
              />
              <Text
                weight="medium"
                color={colors.blackText}
              >
                {getProcessedJobDetailValue(item?.experience) === "Beginner"
                  ? getProcessedJobDetailValue(item?.experience)
                  : `${getProcessedJobDetailValue(item?.experience)} years`}
              </Text>
              <Icon
                name="timeslot"
                alt={"timeslot"}
                size={24}
                style={{ marginTop: spacing.s }}
              />
              <Text
                weight="medium"
                color={colors.blackText}
              >
                {item?.timeSlot && Array.isArray(item?.timeSlot)
                  ? item?.timeSlot.join(", ")
                  : item?.timeSlot}
              </Text>
              <Icon
                name="calendar"
                alt={"calendar"}
                size={24}
                style={{ marginTop: spacing.s }}
              />
              <Text
                weight="medium"
                color={colors.blackText}
              >
                {item?.daysSelected && Array.isArray(item?.daysSelected)
                  ? item?.daysSelected.join(", ")
                  : item?.day}
              </Text>
            </div>
          </Grid>
        </Grid>
        <Divider />
        {getJobDetailsContainerItems()}
      </div>
    </>
  );
};

EmpJobDetailCardMobile.propTypes = {
  item: PropTypes.object,
  statusOfCurrJob: PropTypes.string,
  getJobDetailsContainerItems: PropTypes.func,
  handleStateOfJob: PropTypes.func,
  onClickCopy: PropTypes.func,
  onClickEdit: PropTypes.func,
};

export default EmpJobDetailCardMobile;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;
const IconLabel = styled("div")`
  display: block;
  text-align: center;
  cursor: pointer;
`;
const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s};
`;
const IconContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
  justify-content: space-between;
`;
