import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

import NavBarContainer from "../../containers/NavBar/NavBarContainer";
import FooterContainer from "../../containers/Footer/FooterContainer";
import useIsMobile from "../../hooks/useIsMobile";
import { JOBSEEKER_PAGE_ROUTE, EXPLORE_JOBS_PAGE_ROUTE } from "../../routes/routeConstants";
import RestrictedCountry from "containers/RestrictedCountry/RestrictedCountry";
import { checkIsDetailView } from "utils/commonUtils";

const NavFooter = ({ isCountryCheckError }) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const isDetailView = checkIsDetailView(location);
  const isAddOrEditJobFlow =
    location.pathname.includes("/editJob") || location.pathname.includes("/postnewjob");
  const showFooter =
    !isMobile ||
    (isMobile &&
      !(
        location.pathname === `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}` &&
        location.search.includes("showMapView=true")
      ) &&
      !isDetailView && !isAddOrEditJobFlow);

  return (
    <>
      <NavBarContainer isCountryCheckError={isCountryCheckError} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {location.pathname === "/blogs" ? (
          <Outlet />
        ) : isCountryCheckError ? (
          <RestrictedCountry />
        ) : (
          <Outlet />
        )}
      </Container>
      {showFooter && <FooterContainer />}
    </>
  );
};

export default NavFooter;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
