import { FacebookLoginClient } from "@greatsumini/react-facebook-login";

const FacebookLoginButton = ({ onProfileSuccess, onFail, render }) => {
  const scope = "public_profile, email";

  const loginOptions = {
    return_scopes: false,
    scope,
  };

  const handleButtonClick = () => {
    if (!window.FB) {
      onFail && onFail({ status: "facebookNotLoaded" });
      return;
    }
    requestLogin();
  };

  const requestLogin = () => {
    FacebookLoginClient.login(
      (res) => {
        if (!res.authResponse) {
          onFail && onFail({ status: "loginCancelled" });
          return;
        }

        if (onProfileSuccess) {
          FacebookLoginClient.getProfile(
            (response) =>
              onProfileSuccess({ ...response, ...res.authResponse }),
            {
              fields: "name,email,picture",
            }
          );
        }
      },
      { ...loginOptions, scope }
    );
  };

  return <>{render({ onClick: handleButtonClick })}</>;
};

export default FacebookLoginButton;
