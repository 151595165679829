import { useState } from "react";
import PropTypes from "prop-types";

import { Button, Grid, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Text, TextInput } from "../../../../components/atoms";
import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";

import { CHANGE_PASSWORD } from "../../constants";
import { isEmpty } from "lodash";

const CurrentPassword = ({ currentPasswordFormik, handleEdit }) => {
  const [showPwdText, setShowPwdText] = useState(false);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xs, mx: spacing.m }}>
        <InfoLabelText size="l" color="#767676">
          {CHANGE_PASSWORD.PASSWORD_LABEL}
        </InfoLabelText>
        <PasswordInput
          name="currentPassword"
          placeholder={CHANGE_PASSWORD.PASSWORD_PLACEHOLDER}
          type={showPwdText ? "text" : "password"}
          value={currentPasswordFormik.values.currentPassword}
          onChange={currentPasswordFormik.handleChange}
          onBlur={currentPasswordFormik.handleBlur}
          handleSubmit={currentPasswordFormik.handleSubmit}
          //TODO - make this as a reusable component
          endAdornment={
            <InputAdornment position="end" onClick={() => setShowPwdText(!showPwdText)}>
              <IconButton>
                {showPwdText ? (
                  <Visibility sx={{ color: "#848484" }} />
                ) : (
                  <VisibilityOff sx={{ color: "#848484" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          sx={{ width: "100%" }}
        />
        {currentPasswordFormik.touched.currentPassword &&
          Boolean(currentPasswordFormik.errors.currentPassword) && (
            <FormHelperText sx={{ color: colors.red }}>
              {currentPasswordFormik.errors.currentPassword}
            </FormHelperText>
          )}
      </Grid>
      <ActionFooter>
        <ActionButton
          onClick={() => handleEdit("settings", "")}
          variant="outlined"
          sx={{
            mr: spacing.s,
          }}
        >
          {CHANGE_PASSWORD.CANCEL_BUTTON}
        </ActionButton>
        <ActionButton
          disabled={!currentPasswordFormik.dirty || !isEmpty(currentPasswordFormik.errors)}
          onClick={currentPasswordFormik.handleSubmit}
          variant="contained"
        >
          {CHANGE_PASSWORD.NEXT_BUTTON}
        </ActionButton>
      </ActionFooter>
    </>
  );
};

CurrentPassword.propTypes = {
  currentPasswordFormik: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default CurrentPassword;

const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.xs};
`;
const PasswordInput = styled(TextInput)(() => ({
  border: `1px solid ${colors.lightBlue}`,
  borderRadius: 8,
  height: styleUtils.pxToRem("46px"),
  width: styleUtils.pxToRem("294px"),
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: textSizes.m,
  },
}));
const ActionFooter = styled("div")`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-left: ${spacing.xs};
  padding-right: ${spacing.xs};
  margin-left: ${spacing.xxl};
  margin-right: ${spacing.xxl};
`;
const ActionButton = styled(Button)`
  width: ${styleUtils.pxToRem("142.5px")};
  height: ${styleUtils.pxToRem("40px")};
  font-size: ${textSizes.m};
`;
