import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import LoginSelector from "../../containers/Login/selectors/LoginSelector";

const UnAuthenticatedRoute = () => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);

  return isAuthenticated ? <Navigate to="/" replace /> : <Outlet />;
};

export default UnAuthenticatedRoute;
