import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Modal } from "@mui/material";
import { Icon, Text } from "components/atoms";
import { colors, spacing, styleUtils, textSizes } from "styles";
import { styled } from "@mui/material/styles";
import useIsMobile from "hooks/useIsMobile";

const DeleteResumeModal = (props) => {
  const { isOpen, hideDeleteResumeModal, handleDeleteResume } = props;

  const isMobile = useIsMobile();

  return (
    <Modal open={isOpen} onClose={hideDeleteResumeModal} style={{ backdropFilter: "blur(2px)" }}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "none",
          height: styleUtils.pxToRem("228px"),
          width: isMobile ? styleUtils.pxToRem("326px") : styleUtils.pxToRem("400px"),
          borderRadius: styleUtils.pxToRem("8px"),
          backgroundColor: colors.white,
        }}
      >
        <ContentContainer
          sx={{
            marginLeft: isMobile ? "45px" : "80px",
            marginRight: isMobile ? "45px" : "70px",
          }}
        >
          <ImgContainer name="delete-bin" />
          <ContentWrapper>
            <Text weight="medium" size="m" color="#000000">
              Are you sure you want to delete your resume?
            </Text>
          </ContentWrapper>
          <ButtonWrapper>
            <ActionButton variant="outlined" onClick={hideDeleteResumeModal}>
              Cancel
            </ActionButton>
            <ActionButton variant="contained" sx={{}} onClick={handleDeleteResume}>
              Delete
            </ActionButton>
          </ButtonWrapper>
        </ContentContainer>
      </Card>
    </Modal>
  );
};

DeleteResumeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideDeleteResumeModal: PropTypes.func.isRequired,
  handleDeleteResume: PropTypes.func.isRequired,
};

export default DeleteResumeModal;

const ContentContainer = styled("div")`
  margin-top: ${styleUtils.pxToRem("25px")};
  margin-bottom: ${styleUtils.pxToRem("40px")};
  background-color: ${colors.white};
`;

const ContentWrapper = styled("div")`
  height: ${styleUtils.pxToRem("44px")};
  width: ${styleUtils.pxToRem("236px")};
  margin-top: ${styleUtils.pxToRem("17px")};
  text-align: center;
`;

const ImgContainer = styled(Icon)`
  height: ${styleUtils.pxToRem("29px")};
  width: ${styleUtils.pxToRem("23px")};
  margin: auto;
  display: block;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  margin-top: ${styleUtils.pxToRem("40px")};
  gap: ${styleUtils.pxToRem("14.5px")};
`;

const ActionButton = styled(Button)`
  font-size: ${textSizes.s};
  border-radius: ${spacing.xl};
  width: ${styleUtils.pxToRem("140px")};
  height: ${styleUtils.pxToRem("40px")};
`;
