import { useFormik } from "formik";
import noop from "lodash/noop";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { userSettingsUpdate } from "../../containers/Login/actions/LoginActions";

const useEmployerListingFilter = (
  filterSettings,
  handleFilter,
  handleClose = noop
) => {
  const dispatch = useDispatch();

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      wage: filterSettings?.wage?.length > 0 ? filterSettings?.wage : [],
      status: filterSettings?.status?.length > 0 ? filterSettings?.status : [],
      experience:
        filterSettings?.experience?.length > 0
          ? filterSettings?.experience
          : [],
    },
  });

  const onChangeHourlyWages = useCallback((value) => {
    setFieldValue("wage", value);
  }, []);

  const onChangeStatus = useCallback((value) => {
    setFieldValue("status", value);
  }, []);

  const onChangeExperience = useCallback((value) => {
    setFieldValue("experience", value);
  }, []);

  const onApply = () => {
    handleFilter({
      wage: values.wage.includes("all") ? [] : values.wage,
      status: values.status.includes("all") ? [] : values.status,
      experience: values.experience.includes("all") ? [] : values.experience,
    });
    dispatch(
      userSettingsUpdate("employerListingFilter", {
        wage: values.wage,
        status: values.status,
        experience: values.experience,
      })
    );
    handleClose();
  };

  const onReset = () => {
    handleFilter({});
    dispatch(
      userSettingsUpdate("employerListingFilter", {
        wage: [],
        status: [],
        experience: [],
      })
    );
    resetForm({
      values: {
        wage: [],
        status: [],
        experience: [],
      },
    });
    handleClose();
  };

  return {
    wage: values.wage,
    status: values.status,
    experience: values.experience,
    onChangeHourlyWages,
    onChangeStatus,
    onChangeExperience,
    onApply,
    onReset,
  };
};

export default useEmployerListingFilter;
