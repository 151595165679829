import { styled } from "@mui/material/styles";
import { Text } from "../../components/atoms";
import { ChatConstants } from "./Constants";
import { images } from "../../assets/images";
import { colors } from "../../styles";

const NoChatSelected = () => {
  return (
    <Wrapper>
      <img
        alt="NODATA"
        src={images["noChatSelected"]}
        style={{ width: 212, height: 177 }}
      />

      <Text weight="bold" size="xl" style={{ textAlign: "center" }}>
        {ChatConstants.CHOOSE_A_CONVERSATION} <br />
      </Text>
      <Text size="l" color={colors.placeholder} style={{ textAlign: "center" }}>
        {ChatConstants.CLICK_ON_EXISTING_CONVERSATION}
      </Text>
    </Wrapper>
  );
};

export default NoChatSelected;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
