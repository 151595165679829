import { Button, Grid } from "@mui/material";

import { colors, spacing } from "../../../../styles";
import { EmploydSearchInput, Text } from "../../../atoms";

const Input = ({
  searchTermValue,
  onChangeSearchTerm,
  onClickSearch,
  fetchPostedJobsSuggestions,
  setSearchValue,
  postedJobsSuggestions,
  sx,
}) => {
  return (
    <Grid container justifyContent="center" alignItems="flex-end" sx={sx}>
      <Grid item md={5}>
        <Text
          weight="medium"
          style={{
            transform: "translateX(60px)",
            marginBottom: spacing.xs,
          }}
        >
          Jobs
        </Text>
        <EmploydSearchInput
          name="searchTerm"
          onChangeSearchTerm={onChangeSearchTerm}
          fetchSuggestions={fetchPostedJobsSuggestions}
          setSearchValue={setSearchValue}
          suggesstions={postedJobsSuggestions}
          suggestionValue={searchTermValue}
          placeholder="Search here"
          inputSx={{
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            height: 48,
          }}
        />
      </Grid>
      <Grid item md={2.5}>
        <Button
          variant="outlined"
          onClick={onClickSearch}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeftWidth: 0,
            borderColor: colors.border,
            backgroundColor: colors.primary,
            height: 48,
            "&:disabled": {
              borderColor: colors.border,
              borderLeftWidth: 0,
            },
          }}
        >
          <Text weight="bold" color={colors.white}>
            Search
          </Text>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Input;
