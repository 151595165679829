import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
// import { getDatabase } from "firebase/database";
import { logEvent as logFirebaseEvent } from "firebase/analytics";
import FirebaseEvents from "./FirebaseEvents";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAXyKFb22YXM2orx9jtx5WFfR4bqnjURMQ",
//   authDomain: "employd-63953.firebaseapp.com",
//   databaseURL: "https://employdiajobsr.firebaseio.com",
//   projectId: "employd-63953",
//   storageBucket: "employd-63953.appspot.com",
//   messagingSenderId: "467671629184",
//   appId: "1:467671629184:web:a7f4b67615d46a9417d565",
//   measurementId: "G-1QW12ESKPM",
// };

const app = initializeApp(firebaseConfig);
export const logEvent = (name, payload) => {
  const isCommonEvent = FirebaseEvents.COMMON_EVENTS.includes(name);
  const isDevEvent = FirebaseEvents.DEV_EVENTS.includes(name);
  const isProdEvent = FirebaseEvents.PROD_EVENTS.includes(name);
  if (
    (import.meta.env.VITE_APP_ENVIRONMENT === "DEVELOPMENT" && isDevEvent) ||
    (import.meta.env.VITE_APP_ENVIRONMENT === "PRODUCTION" && isProdEvent) ||
    isCommonEvent
  ) {
    isSupported().then((yes) => {
      if (yes) {
        let analytics = getAnalytics(app);
        logFirebaseEvent(analytics, name, payload);
      }
    });
  }
};

// export const database = getDatabase(app);
