export const RatingConstants = {
  EXPERIENCE_RATING_TITLE: "Rate your experience with EmployD",
  EXPERIENCE_RATING_TEXT: "Your feedback matters a lot, thank you so much!",
  EXPERIENCE_RATING_PLACEHOLDER: "Please share your feedback here",
  THANKS_GIVING_FEEDBACK_TITLE: "Thank a ton for sharing your feedback",
  THANKS_GIVING_FEEDBACK_SUBTEXT:
    "We thrive for the best and will continue doing it everyday.",
  SUBMIT_BUTTON_TEXT: "Submit Feedback",
  CONTINUE_TO_HOME_BUTTON_TEXT: "Continue",
};

export const RatingFeedBackConstants = {
  TEXT_POOR: "Poor",
  TEXT_BAD: "Bad",
  TEXT_AVERAGE: "Average",
  TEXT_GREAT: "Great",
  TEXT_EXCELLENT: "Excellent",
};

export const RatingTexts = {
  1: RatingFeedBackConstants.TEXT_POOR,
  2: RatingFeedBackConstants.TEXT_BAD,
  3: RatingFeedBackConstants.TEXT_AVERAGE,
  4: RatingFeedBackConstants.TEXT_GREAT,
  5: RatingFeedBackConstants.TEXT_EXCELLENT,
};
