import React from "react";
import { styled } from "@mui/material/styles";
import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Text } from "../../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../../styles";
import * as EmployerDetailsConsts from "./constants";

/**
 * Employer SignUp Details View Component
 */
const EmployerSignUpDetailsView = () => {
  return (
    <EmployerSignUpDetailsContainer>
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        sx={{ marginTop: spacing.s }}
      >
        {EmployerDetailsConsts.EMPLOYER_DETAILS_HEADER}
      </Text>
      <Text
        size="s"
        sx={{
          color: colors.subtextGray,
          marginTop: spacing.xxs,
          marginBottom: spacing.xxs,
        }}
      >
        {EmployerDetailsConsts.EMPLOYER_DETAILS_SUBTEXT}
      </Text>
      <List>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {EmployerDetailsConsts.EMPLOYER_DETAILS_INFO_ONE}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon sx={{ minWidth: "8px", marginRight: "8px" }}>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {EmployerDetailsConsts.EMPLOYER_DETAILS_INFO_TWO}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {EmployerDetailsConsts.EMPLOYER_DETAILS_INFO_THREE}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {EmployerDetailsConsts.EMPLOYER_DETAILS_INFO_FOUR}
          </Text>
        </ListItem>
      </List>
    </EmployerSignUpDetailsContainer>
  );
};

export default EmployerSignUpDetailsView;

const EmployerSignUpDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 74px;
`;

const ListIcon = styled(ListItemIcon)`
  min-width: ${styleUtils.pxToRem("8px")};
  margin-right: ${styleUtils.pxToRem("8px")};
`;
