//Mutliply by 16 to get pixels
export const textSizes = {
  xxxs: "0.5rem", //8px
  xxs: "0.625rem", // 10px
  xs: "0.75rem", // 12px
  s: "0.875rem", // 14px
  m: "1rem", //16px
  l: "1.125rem", // 18px
  xl: "1.25rem", // 20px
  xxl: "1.5rem", // 24px
  xxxl: "1.75rem", // 28px
  xxxxl: "2rem", // 32px
};
