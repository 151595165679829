import { Button, Container, Grid } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";
import { ChatDirectlyConstants } from "./Constants";

const ChatDirectly = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "80vh",
        marginBottom: styleUtils.pxToRem("180px"),
      }}
    >
      <Grid container columnSpacing={spacing.xxxxl}>
        <Grid item md={6}>
          <img
            src={images.home["jobseeker-hero"]["4"]}
            alt="4"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid
          item
          md={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text id="CHAT_DIR_WEB_TITLE_2" size={44} style={{ lineHeight: 1.2 }}>
            <Text
              id="CHAT_DIR_WEB_TITLE_1"
              size={44}
              component="span"
              weight="extrabold"
              color={colors.green}
              style={{ lineHeight: 1.2 }}
            >
              {ChatDirectlyConstants.TITLE_1}
            </Text>{" "}
            {ChatDirectlyConstants.TITLE_2}
          </Text>
          <Text id="CHAT_DIR_WEB_TITLE_3" size={44} style={{ lineHeight: 1.2 }}>
            {ChatDirectlyConstants.TITLE_3}
          </Text>
          <Text
            id="CHAT_DIR_WEB_SUB_TITLE_1"
            size="l"
            color="#4C5468"
            style={{ marginTop: spacing.s }}
          >
            {ChatDirectlyConstants.SUB_TITLE_1} &amp;
          </Text>
          <Text id="CHAT_DIR_WEB_SUB_TITLE_2" size="l" color="#4C5468">
            {ChatDirectlyConstants.SUB_TITLE_2}
          </Text>
          {!isAuthenticated && (
            <Button
              id="CHAT_DIR_WEB_STARTED_BTN"
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("46px"),
                width: styleUtils.pxToRem("176px"),
                mt: spacing.xxxl,
                px: spacing.xxl,
                py: spacing.xs,
                fontSize: textSizes.l,
                fontWeight: typography.bold,
              }}
              onClick={onGetStarted}
            >
              {ChatDirectlyConstants.GET_STARTED_BTN}
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatDirectly;
