export const SETTINGS_SCREEN_CONSTS = {
  BLOCKED_USERS: "Blocked Users",
  CHANGE_PASSWORD: "Change Password",
  DELETE_ACCOUNT: "Delete your EmployD Account?",
  CLICK_HERE: "Click Here",
};

export const CONNECT_SOCIAL_CONSTS = {
  CONNECT_SUBTEXT:
    "Connect your other social accounts with EmployD for a simple & secure sign-in.",
  CONNECT_BUTTON_TEXT: "Connect",
};

export const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_HEADER: "Change Password",
  EMAIL_LABEL: "Email ID",
  PHONE_LABEL: "Phone Number",
  PASSWORD_LABEL: "Password",
  PASSWORD_PLACEHOLDER: "Enter Current Password",
  INCORRECT_PASSWORD_ERROR: "Wrong password! Please try again",
  NEW_PASSWORD_LABEL: "New Password",
  NEW_PASSWORD_PLACEHOLDER: "Enter New Password",
  CONFIRM_PASSWORD_LABEL: "Confirm Password",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirm your Password",
  PASSWORD_MISMATCH: "Password mismatch! Retry again",
  PASSWORD_MATCH: "Passwords Match",
  NEXT_BUTTON: "Next",
  SAVE_BUTTON: "Save",
  CANCEL_BUTTON: "Cancel",
  PASSWORD_CHANGE_MSG_1: "You have successfully changed your account password.",
  PASSWORD_CHANGE_MSG_2: "Kindly login again to continue using EmployD.",
  PASSWORD_CHANGE_MSG_RESPONSIVE:
    "You have successfully changed your account password. Kindly login again to continue using EmployD.",
  CONTINUE_LOGIN_BUTTON: "Continue to Login",
  CURRENT_PASSWORD_INITAL_STATE: {
    currentPassword: "",
  },
  NEW_PASSWORD_INITAL_STATE: {
    newPassword: "",
    confirmPassword: "",
  },
};

export const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_HEADER: "Forgot password",
  FORGOT_PASSWORD_OTP_PLACEHOLDER:
    "Enter 6 digit code sent to your email or phone no.",
  FORGOT_PASSWORD_OTP_PLACEHOLDER_MOBILE: "Enter 6 digit code here",
  RESET_PASSWORD_HEADER: "Reset your account password",
  PASSWORD_FIELD_LABEL: "Password *",
  PASSWORD_FIELD_PLACEHOLDER: "Enter the password",
  CONFIRM_PASSWORD_FIELD_LABEL: "Confirm Password *",
  CONFIRM_PASSWORD_FIELD_PLACEHOLDER: "Re-enter the password",
  PASSWORD_MISMATCH: "Password’s don’t match, recheck once",
  PASSWORD_MATCH: "Passwords Match",
  PASSWORD_CREATED: "New password successfully created",
  PASSWORD_REDIRECT: "Redirecting you to the Login Screen",
  NEXT_BUTTON: "Next",
  SUBMIT_BUTTON: "Submit",
  ERROR_OTP_LENGTH: "Enter 6 digit code",
  ERROR_OTP_MISMATCH: "Code provided did not match",
  ERROR_MESSAGE_GENERIC: "Something went wrong. Please try again.",
  ERROR_MESSAGE_EMPTY_NEW_PASSWORD: "Please enter the new password",
  ERROR_MESSAGE_LENGTH_NEW_PASSWORD:"Please enter a minimum 6 characters",
  ERROR_MESSAGE_EMPTY_CONFIRM_PASSWORD: "Please re-enter the new password",
  KEY_NEW_PASSWORD: "newPassword",
};
