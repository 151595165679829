import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CompanyDetailCard } from "../../../../../../../components/molecules";
import { Icon, NewJob, Text } from "../../../../../../../components/atoms";

import { colors, spacing, styleUtils, textSizes } from "../../../../../../../styles";
import { useDispatch, useSelector } from "react-redux";
import LoginSelector from "containers/Login/selectors/LoginSelector";
import { hideLoader, showLoader, showNotification } from "utils/commonUtils";
import { jobDetails, postBookmarkJob } from "configs/network/apis";
import { userDetailUpdate } from "containers/Login/actions/LoginActions";
import { pull } from "lodash";
import useScrollYAnimationResp from "hooks/useScrollYAnimationResp";

import JobSeekerSelector from "containers/JobSeeker/selectors/JobSeekerSelector";
import { getDateDifference } from "utils/dateUtils";
import JobDetailCardMobile from "components/molecules/JobDetailCard/JobDetailCardMobile";

/**
 * Job and Company Details View container for responsive
 */
const DetailsView = ({
  onClickBrandLogo,
  showJobDetailView,
  showCompanyDetailView,
  onClickJob,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const brandName = searchParams.get("brandName");
  const forceUpdate = useSelector(JobSeekerSelector.getForceUpdateJobSeekerListing);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const searchLocation = useSelector(LoginSelector.getSearchLocation);
  const { y: yPos } = useScrollYAnimationResp();

  const [item, setItem] = useState(null);
  const [isJobAbsent, setIsJobAbsent] = useState(false);
  const [isJobDetailLoading, setIsJobDetailLoading] = useState(false);

  let isBookmarked = loggedInUser?.bookmarkedJobs.includes(item?.jobId);
  const isNew = getDateDifference(item?.date) < 14;
  let bookmarkIcon = isBookmarked || isJobDetailLoading ? "bookmark" : "remove-bookmark";
  let bookmarkColor = isBookmarked && colors.midnightBlue;
  if (isJobDetailLoading) {
    bookmarkColor = colors.shimmerHighlightColor;
  }

  useEffect(() => {
    init();
  }, [jobId, brandName, forceUpdate]);

  const init = async () => {
    try {
      const searchLatLngParams =  {
          searchedLat: searchLocation?.lat,
          searchedLng: searchLocation?.lng,
        };
      const res = await jobDetails(jobId, searchLatLngParams);
      setItem(res?.data || "Not found");
    } catch (e) {
      setItem("Not found");
    }finally{
      //NO CODE HERE
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickBookmark = async () => {
    if (isAuthenticated) {
      showLoader();
      try {
        const res = await postBookmarkJob({
          jobId: item?.jobId,
        });
        const {
          data: { bookmarkStatus, message },
        } = res;
        hideLoader();
        isBookmarked = bookmarkStatus;
        dispatch(
          userDetailUpdate({
            bookmarkedJobs: bookmarkStatus
              ? [...loggedInUser.bookmarkedJobs, item?.jobId]
              : pull(loggedInUser.bookmarkedJobs, item?.jobId),
          }),
        );

        showNotification({
          alertProps: {
            severity: bookmarkStatus ? "success" : "warning",
            children: message,
          },
        });
      } catch (error) {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again.",
          },
        });
      }finally{
        hideLoader();
      }
    }
  };

  const navigateToCompanyLoc = (locationDetails) => {
    window
      .open(
        `https://www.google.com/maps/search/?api=1&query=${locationDetails?.lat},${locationDetails?.lng}`,
        "_blank",
      )
      .focus();
  };

  return (
    <>
      {showJobDetailView && !showCompanyDetailView && (
        <BackButtonContainer
          sx={{
            position: yPos > 50 ? "sticky" : "",
            zIndex: 1100,
            top: 0,
          }}
        >
          <Icon
            style={{ marginLeft: "10px" }}
            name="arrow-back-black"
            size={24}
            onClick={onClickBack}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: `${spacing.xs}`,
            }}
          >
            {isAuthenticated && (item || isJobDetailLoading) && (
              <div style={{ marginRight: isNew ? "0" : spacing.s }}>
                <Icon
                  onClick={!isJobDetailLoading ? onClickBookmark : () => {}}
                  name={bookmarkIcon}
                  size={32}
                  color={bookmarkColor}
                />
              </div>
            )}
            {item &&  (
              <div style={{ marginTop: `-${spacing.xxs}`, display: isNew ? 'block' : 'none' }}>
                <NewJob
                  jobStyle={{
                    borderRadius: `${styleUtils.pxToRem("100px")} 0 0 ${styleUtils.pxToRem(
                      "100px",
                    )}`,
                    fontSize: textSizes.xxxs,
                    width: styleUtils.pxToRem("81px"),
                  }}
                  isNew={isNew}
                />
              </div>
            )}
          </div>
        </BackButtonContainer>
      )}
      {showCompanyDetailView && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: spacing.s,
          }}
        >
          <IconContainer>
            <Icon name="arrow-back" size={42} onClick={onClickBack} />
          </IconContainer>
          <Text size="m" weight="bold">
            About Company
          </Text>
          <div style={{ width: 40 }} />
        </div>
      )}
      {showJobDetailView && !showCompanyDetailView && (
        <JobDetailCardMobile
          onClickBrandLogo={onClickBrandLogo}
          setIsJobAbsent={setIsJobAbsent}
          isJobAbsent={isJobAbsent}
          searchLocation={searchLocation}
          isJobDetailLoading={isJobDetailLoading}
          setIsJobDetailLoading={setIsJobDetailLoading}
        />
      )}
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginLeft: spacing.m,
          marginRight: spacing.s,
        }}
      >
        {showJobDetailView && showCompanyDetailView && (
          <CompanyDetailCard
            onClickBrandLogo={onClickBrandLogo}
            navigateToCompanyLoc={navigateToCompanyLoc}
            onClickJob={onClickJob}
          />
        )}
      </Card>
    </>
  );
};

DetailsView.propTypes = {
  onClickBrandLogo: PropTypes.func.isRequired,
  showJobDetailView: PropTypes.bool.isRequired,
  showCompanyDetailView: PropTypes.bool.isRequired,
  onClickJob: PropTypes.func.isRequired,
};

export default DetailsView;

const IconContainer = styled("div")`
  display: flex;
  height: 3rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.s};
  margin-left: ${spacing.m};
`;

const BackButtonContainer = styled("div")`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 5px 15px ${colors.shadow};
  width: 100%;
`;
