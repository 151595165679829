import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import JobBoard from "./views/JobBoard";
import JobBoardMobile from "./mobileViews/JobBoard";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import EmployerSelector from "../../../../selectors/EmployerSelector";
import { resetSignUpRequest } from "../../../../../SignUp/actions/SignUpActions";
import { logEvent } from "../../../../../../configs/firebase";
import {
  EMPLOYER_PAGE_ROUTE,
  POST_NEW_JOB_PAGE_ROUTE,
} from "../../../../../../routes/routeConstants";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { setEmployerFirstJobModalStart } from "containers/Employer/actions/EmployerActions";
import { activeFiltersCount, showNotification } from "utils/commonUtils";

const JobBoardContainer = (props) => {
  const isEmployerNotPostedJob = useSelector(EmployerSelector.isEmployerNotPostedJob);
  const employerJobsList = useSelector(EmployerSelector.getEmployerListing);
  const timer = useSelector(EmployerSelector.getEmployerFirstJobStartTime);
  const currentTime = moment().valueOf();
  const currentDate = moment();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { filterSettings } = props;

  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const activefiltersCount = activeFiltersCount(filterSettings);

  useEffect(() => {
    if (activefiltersCount === 0) {
      if (isEmployerNotPostedJob && !timer) {
        dispatch(setEmployerFirstJobModalStart(currentTime));
        setShowSignUpModal(true);
      } else if (timer && isEmployerNotPostedJob) {
        const timerDate = moment(timer);
        const nextDay = timerDate.add(1, "days");
        if (currentDate.isSameOrAfter(nextDay)) {
          dispatch(setEmployerFirstJobModalStart(currentTime));
          setShowSignUpModal(true);
        }
      }
    }
  }, [timer, employerJobsList, activeFiltersCount]);

  useEffect(() => {
    if (isEmployerNotPostedJob && activefiltersCount > 0) {
      showNotification({
        alertProps: {
          severity: "info",
          children: "No jobs found for the applied filters",
        },
      });
    }
  }, [employerJobsList, activefiltersCount]);

  const handleSignUpModal = (action) => {
    if (action === "close") {
      setShowSignUpModal(false);
    } else {
      setShowSignUpModal(false);
      logEvent(FirebaseEvents.EMP_CLICKS_POST_NEW_JOB_NAVBAR);
      navigate(`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`);
    }

    dispatch(resetSignUpRequest());
  };

  const isMobile = useIsMobile();
  const Wrapper = isMobile ? JobBoardMobile : JobBoard;

  return (
    <Wrapper
      showSignUpModal={showSignUpModal}
      handleSignUpModal={handleSignUpModal}
      {...props}
    />
  );
};

JobBoardContainer.propTypes = {
  filterSettings: PropTypes.object,
};

export default JobBoardContainer;
