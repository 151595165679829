import React from "react";
import Grid from "@mui/material/Grid";
import SeekerSignUpContainer from "./JobSeeker/SeekerSignUpContainer";
import EmployerSignUpContainer from "./Employer/EmployerSignUpContainer";

const SignUpView = (props) => {
  const {
    isEmployer,
    isOtpLogin,
    isPhoneSignUp,
    countryCodeValue,
    phoneNumValue,
    otpReq,
    handleEmployerSignUp,
    handleJobSeekerSignUp,
    handleOtpSubmit,
    handleEmailEditForm,
    handlePhoneEditForm,
    socialSignUpDetails,
    signUpActionsProp,
    seekerFormDetails,
    handleSetFormDetails,
  } = props;

  let formContent = isEmployer ? (
    <EmployerSignUpContainer
      socialSignUpDetails={socialSignUpDetails}
      signUpActionsProp={signUpActionsProp}
      handleEmployerSignUp={handleEmployerSignUp}
      isPhoneSignUp={isPhoneSignUp}
      countryCodeValue={countryCodeValue}
      phoneNumValue={phoneNumValue}
      handleOtpSubmit={handleOtpSubmit}
      handleEmailEditForm={handleEmailEditForm}
      handlePhoneEditForm={handlePhoneEditForm}
      otpReq={otpReq}
      isLoginByPhn={!isPhoneSignUp && isOtpLogin}
    />
  ) : (
    <SeekerSignUpContainer
      socialSignUpDetails={socialSignUpDetails}
      signUpActionsProp={signUpActionsProp}
      handleJobSeekerSignUp={handleJobSeekerSignUp}
      handleOtpSubmit={handleOtpSubmit}
      handleEmailEditForm={handleEmailEditForm}
      handleSetFormDetails={handleSetFormDetails}
      seekerFormDetails={seekerFormDetails}
      otpReq={otpReq}
    />
  );

  return (
    <Grid container spacing={0}>
      {formContent}
    </Grid>
  );
};

export default SignUpView;
