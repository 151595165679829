import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import isEmpty from "lodash/isEmpty";

import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";
import { EmploydSearchInput, GoogleInput, Icon, Text } from "../../../atoms";

const InputMobile = ({
  searchTermValue,
  mobileSearchValues,
  onChangeSearchTerm,
  googleInputRef,
  onGooglePlaceSelected,
  onClearGoogleInput,
  onClickSearch,
  displayHome = false,
  sx,

  jobSuggestions,
  fetchJobSuggestions,
  setJobSearchValue,
}) => {
  const { location, term } = mobileSearchValues;
  const disabled = isEmpty(location);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onSearch = () => {
    handleDrawerClose();
    onClickSearch(searchTermValue);
  };

  const searchInputvalue = term ? `${term} jobs in` : "Jobs In";
  const homeSearchPlaceHolder = "Search by Job title / employer / loc..";
  const displayHomeView = displayHome && !drawerOpen;

  return (
    <SearchContainer sx={sx}>
      <SearchInputContainer
        onClick={handleDrawerOpen}
        sx={{ my: displayHomeView ? spacing.xs : "" }}
      >
        <IconContainer>
          <Icon name="search" size={18} style={{ fill: "#7E7E7E" }} />
        </IconContainer>
        <div style={{ marginLeft: spacing.s }}>
          {displayHomeView ? (
            <Text
              size="m"
              weight="semibold"
              placeholder="Job Title / Company"
              color={colors.textPrimary}
              numberOfLines={1}
            >
              {homeSearchPlaceHolder}
            </Text>
          ) : (
            <>
              <Text
                size="l"
                weight="semibold"
                placeholder="Job Title / Company"
                color={colors.textPrimary}
                numberOfLines={1}
              >
                {searchInputvalue}
              </Text>
              <Text size="s" placeholder="Job Location" color={colors.lightGray} numberOfLines={1}>
                {location}
              </Text>
            </>
          )}
        </div>
      </SearchInputContainer>

      <div style={{ marginTop: spacing.s, display: drawerOpen ? "block" : "none" }}>
        <Divider sx={{ color: "#E8E8E8" }} />
        <EmploydSearchInput
          name="searchTerm"
          onChangeSearchTerm={onChangeSearchTerm}
          fetchSuggestions={fetchJobSuggestions}
          setSearchValue={setJobSearchValue}
          suggesstions={jobSuggestions}
          suggestionValue={searchTermValue}
          placeholder="Job Title / Employer"
          showFieldType={true}
          inputSx={{
            borderRadius: 40,
            height: 44,
            border: "1px solid #E8E8E8",
            marginTop: spacing.s,
          }}
        />
        <GoogleInput
          ref={googleInputRef}
          name="locationName"
          placeholder="Job Location *"
          onPlaceSelected={onGooglePlaceSelected}
          setSearchValue={setJobSearchValue}
          onClear={onClearGoogleInput}
          retainValue={location}
          style={{
            border: "1px solid #E8E8E8",
            borderRadius: 40,
            height: 44,
            marginTop: spacing.s,
          }}
        />
      </div>
      {drawerOpen && (
        <SearchButton
          disabled={disabled}
          onClick={onSearch}
          variant={disabled ? "outlined" : "contained"}
          sx={{
            py: "14px",
            "&:disabled": {
              borderColor: colors.primary,
            },
            fontColor: disabled ? colors.primary : colors.white,
          }}
        >
          Search
        </SearchButton>
      )}
    </SearchContainer>
  );
};

InputMobile.propTypes = {
  searchTermValue: PropTypes.string.isRequired,
  mobileSearchValues: PropTypes.shape({
    term: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
  onChangeSearchTerm: PropTypes.func.isRequired,
  googleInputRef: PropTypes.object,
  onGooglePlaceSelected: PropTypes.func.isRequired,
  onClearGoogleInput: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  displayHome: PropTypes.bool,
  sx: PropTypes.object,
  jobSuggestions: PropTypes.arrayOf(PropTypes.object),
  fetchJobSuggestions: PropTypes.func.isRequired,
  setJobSearchValue: PropTypes.func.isRequired,
};

export default InputMobile;

const SearchContainer = styled("div")`
  border-radius: ${styleUtils.pxToRem("40px")};
  border: 1px solid #f8f8f8;
  display: flex;
  flex-direction: column;
  box-shadow: 15px 5px 15px 15px ${colors.shadow};
  padding-left: ${spacing.l};
  padding-right: ${spacing.l};
  padding-top: ${spacing.xs};
  padding-bottom: ${spacing.xs};
`;
const SearchInputContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;
const IconContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SearchButton = styled(Button)`
  height: ${styleUtils.pxToRem("52px")};
  font-weight: ${typography.semibold};
  font-size: ${textSizes.l};
  margin-bottom: ${spacing.l};
  margin-top: ${spacing.xl};
`;
