import { useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import LoginSelector from "containers/Login/selectors/LoginSelector";

import { Text } from "../../../../components/atoms";
import { colors, spacing } from "../../../../styles";

import { CHANGE_PASSWORD } from "containers/Account/constants";

const UserInfo = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  return (
    <>
      <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xs, mx: spacing.m }}>
        <InfoLabelText size="l" color="#767676">
          {CHANGE_PASSWORD.EMAIL_LABEL}
        </InfoLabelText>
        <Text size="xl" color={colors.textPrimary} weight="semibold">
          {loggedInUser?.email}
        </Text>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{ px: spacing.xs, mt: spacing.l, mx: spacing.m }}
      >
        <InfoLabelText size="l" color="#767676">
          {CHANGE_PASSWORD.PHONE_LABEL}
        </InfoLabelText>
        <Text size="xl" color={colors.textPrimary} weight="semibold">
          {`+${loggedInUser.countryCode}-${loggedInUser.phoneNumber}`}
        </Text>
      </Grid>
    </>
  );
};

export default UserInfo;

const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.xxs};
`;
