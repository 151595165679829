import { JobSeekerSearchInput } from "../../../../../../../components/molecules";
import { Text } from "../../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../../styles";

import { SearchBoxConstants } from "../../Constants";

const SearchBox = ({
  clearInput,
  googleInputRef,
  location,
  mobileSearchValues,
  onChangeSearchTerm,
  onClickSearch,
  onPlaceSelected,
  searchTerm,

  jobSuggestions,
  fetchJobSuggestions,
  setJobSearchValue,
}) => {
  return (
    <>
      <JobSeekerSearchInput
        googleInputRef={googleInputRef}
        mobileSearchValues={mobileSearchValues}
        onChangeSearchTerm={onChangeSearchTerm}
        onClearGoogleInput={clearInput}
        onClickSearch={onClickSearch(searchTerm)}
        onGooglePlaceSelected={onPlaceSelected}
        searchTermValue={searchTerm}
        jobSuggestions={jobSuggestions}
        fetchJobSuggestions={fetchJobSuggestions}
        setJobSearchValue={setJobSearchValue}
        sx={{ mt: spacing.l, pl: "100px", pr: "100px" }}
      />
      <Text
        size="l"
        weight="medium"
        style={{
          marginTop: spacing.s,
          paddingLeft: "100px",
          paddingRight: "100px",
        }}
        color="#FD2222"
      >
        {SearchBoxConstants.REQUIRED_ASTERIK}
        <Text component="span" size="l" weight="medium" color="#A3A3A3">
          {SearchBoxConstants.SUB_TEXT_1}
          <Text
            size="l"
            component="span"
            weight="semibold"
            color={colors.textPrimary}
          >
            {SearchBoxConstants.SUB_TEXT_2}
          </Text>
        </Text>
      </Text>
      {(!location?.lat || !location?.lng) && (
        <Text
          size="xs"
          color={colors.red}
          style={{
            marginTop: spacing.s,
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
          {SearchBoxConstants.LOCATION_PERMISSION}
        </Text>
      )}
    </>
  );
};

export default SearchBox;
