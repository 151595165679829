import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import isEmpty from "lodash/isEmpty";

import { colors, spacing, styleUtils, textSizes } from "../../../../styles";
import { GoogleInput, Text } from "../../../atoms";
import EmploydSearchInput from "components/atoms/EmploydSearchInput";

const Input = ({
  searchTermValue,
  mobileSearchValues,
  onChangeSearchTerm,
  googleInputRef,
  onGooglePlaceSelected,
  onClearGoogleInput,
  onClickSearch,
  sx,
  jobSuggestions,
  fetchJobSuggestions,
  setJobSearchValue,
}) => {
  const disabled = isEmpty(mobileSearchValues.location);
  return (
    <Grid container alignItems="flex-end" sx={sx}>
      <Grid item md>
        <EmploydSearchInput
          name="searchTerm"
          onChangeSearchTerm={onChangeSearchTerm}
          fetchSuggestions={fetchJobSuggestions}
          setSearchValue={setJobSearchValue}
          suggesstions={jobSuggestions}
          suggestionValue={searchTermValue}
          placeholder="Job Title / Company name"
          showFieldType={true}
          showSearchIcon={false}
          inputSx={{
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            height: 64,
            "& .MuiInputBase-input": {
              fontSize: textSizes.l,
              "&::placeholder": {
                opacity: 1,
                fontSize: textSizes.l,
                color: colors.border,
              },
            },
          }}
        />
      </Grid>
      <EndBox
        sx={{
          "& .MuiTypography-root": {
            padding: "4px 0 4px",
          },
        }}
      >
        <Text size="xl">in</Text>
      </EndBox>
      <Grid item md>
        <GoogleInput
          ref={googleInputRef}
          name="locationName"
          placeholder="Job Location *"
          onPlaceSelected={onGooglePlaceSelected}
          setSearchValue={setJobSearchValue}
          onClear={onClearGoogleInput}
          retainValue={mobileSearchValues?.location}
          style={{ height: styleUtils.pxToRem("64px") }}
          disableSearch={disabled}
          onClickSearch={onClickSearch}
          showSearchIcon={true}
        />
      </Grid>
    </Grid>
  );
};

export default Input;

const EndBox = styled("div")`
  background-color: #f4f4f4;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs} 0;
  border: 1px solid ${colors.border};
  border-left-width: 0;
  border-right-width: 0;
  height: ${styleUtils.pxToRem("64px")};
`;
