import { Button, Container } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";

const EasyOneClick = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        my: spacing.xxxxl,
      }}
    >
      <Text size={32} style={{ lineHeight: 1.2 }}>
        Explore profiles
      </Text>
      <Text size={32} style={{ lineHeight: 1.2 }}>
        &amp;{" "}
        <Text
          component="span"
          color={colors.orange}
          size={32}
          weight="extrabold"
          style={{ lineHeight: 1.2 }}
        >
          shortlist
        </Text>
      </Text>
      <Text color={colors.placeholder} style={{ marginTop: spacing.s }}>
        Browse detailed profiles of all job seekers around you and shortlist
        them for further follow-up.
      </Text>
      {!isAuthenticated && (
        <Button
          variant="contained"
          sx={{
            mt: spacing.xxl,
            width: "fit-content",
            px: spacing.xxl,
            py: spacing.xs,
          }}
          onClick={onGetStarted}
        >
          Get Started
        </Button>
      )}
      <img
        src={images.home["employer-hero"]["3"]}
        alt="3"
        style={{ width: "100%", marginTop: spacing.xl }}
      />
    </Container>
  );
};

export default EasyOneClick;
