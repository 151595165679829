export const getLoader = (state) => {
  const loaderState = state.commonState.loader;
  return loaderState;
};

export const getNotification = (state) => {
  const notificationState = state.commonState.notification;
  return notificationState;
};

const CommonSelector = {
  getLoader,
  getNotification,
};

export default CommonSelector;
