import { useQuery } from "react-query";
import { getBlogPostsApi } from "../../../configs/network/apis";

export const useBlogPostsQuery = ({
  category = null,
  page = 1,
  pageSize = 6,
}) => {
  const { isLoading, data } = useQuery(["posts", category, page], () =>
    getBlogPostsApi({
      category: category === "All" ? undefined : category,
      page,
      pageSize,
    })
  );

  return {
    isLoading,
    posts: data?.posts,
    pageCount: Math.ceil(data?.found / pageSize),
  };
};
