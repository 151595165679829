import PropTypes from "prop-types";
import { Text } from "components/atoms";
import { colors, spacing } from "styles";
const FilterCount = ({ count }) => {
  if (count <= 0) {
    return;
  }
  return (
    <Text
      size="s"
      weight="bold"
      color={colors.white}
      sx={{
        backgroundColor: colors.filterBadgeBlue,
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        textAlign: "center",
        marginTop: spacing.xxxs,
        marginLeft: spacing.xxs,
      }}
    >
      {count}
    </Text>
  );
};

FilterCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default FilterCount;
