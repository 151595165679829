import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import JobBoard from "./views/JobBoard";
import JobBoardMobile from "./mobileViews/JobBoard";

import JobSeekerSelector from "../../../../selectors/JobSeekerSelector";

import useIsMobile from "../../../../../../hooks/useIsMobile";

import { notifyUser } from "../../../../../../configs/network/apis";
import { hideLoader, showLoader, showNotification } from "../../../../../../utils/commonUtils";
import LoginSelector from "containers/Login/selectors/LoginSelector";

const JobBoardContainer = (props) => {
  const {
    location,
    googleInputRef,
    setFieldValue,
    searchTerm,
    setSuggestedLocationAddressValuesForTitle,
  } = props;
  const isMobile = useIsMobile();

  const userLocation = useSelector(LoginSelector.getLoggedInUserCurrentLocation);

  const forceUpdate = useSelector(JobSeekerSelector.getForceUpdateJobSeekerListing);

  const [emailId, setEmailId] = useState("");

  useEffect(() => {
    googleInputRef.current?.changeValue(location.locationName || null);
  }, [forceUpdate]);

  const onPlaceSelected = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const address = place.inputValue;
    setFieldValue("lat", lat);
    setFieldValue("lng", lng);
    setFieldValue("locationName", address);

    let searchedAddressTitle = "";

    if (place.addressComponents["administrative_area_level_1"]) {
      searchedAddressTitle = place.addressComponents["administrative_area_level_1"];
      if (place.addressComponents["locality"]) {
        searchedAddressTitle = `${place.addressComponents["locality"]}, ${searchedAddressTitle}`;
      }
    } else {
      searchedAddressTitle = place.addressComponents["country"];
    }

    setSuggestedLocationAddressValuesForTitle(searchedAddressTitle);
  };

  // Reset the Lat Lng and value of the field.
  const clearInput = () => {
    googleInputRef.current?.changeValue(null);
    setFieldValue("lat", null);
    setFieldValue("lng", null);
    setFieldValue("locationName", null);
  };

  const onPressNotify = async () => {
    try {
      showLoader();
      // TODO: handle sending location from ip (google lib) when not provided
      await notifyUser({
        lat: location.lat,
        lng: location.lng,
        userLat: userLocation?.lat,
        userLng: userLocation?.lng,
        email: emailId,
        locationName: location.locationName,
        source: isMobile ? "resp" : "web",
        type: "JobSeeker",
        searchTerm,
      });
      hideLoader();
      showNotification({
        alertProps: {
          severity: "success",
          children: "You will be notified when we are available at your location",
        },
      });
    } catch (e) {
      hideLoader();
      showNotification({
        alertProps: {
          severity: "success",
          children: "Unable to process the task, please try after sometime",
        },
      });
    }
  };

  const Wrapper = isMobile ? JobBoardMobile : JobBoard;

  return (
    <Wrapper
      clearInput={clearInput}
      googleInputRef={googleInputRef}
      onPlaceSelected={onPlaceSelected}
      emailId={emailId}
      setEmailId={setEmailId}
      onPressNotify={onPressNotify}
      {...props}
    />
  );
};

JobBoardContainer.propTypes = {
  location: PropTypes.object.isRequired,
  googleInputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  setFieldValue: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  setSuggestedLocationAddressValuesForTitle: PropTypes.func,
};

export default JobBoardContainer;
