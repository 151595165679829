import * as yup from "yup";
import { CHANGE_PASSWORD } from "containers/Account/constants";

export const currentPasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Please enter your current password"),
});

export const newPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], CHANGE_PASSWORD.PASSWORD_MISMATCH)
    .required("Confirm Password is required"),
});
