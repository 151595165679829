import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Text from "../Text";
import useIsMobile from "hooks/useIsMobile";
import { colors, spacing } from "styles";

const CoachMark = ({ text, styles, textStyles }) => {
  const isMobile = useIsMobile();
  return (
    <Coach isMobile={isMobile} customStyles={styles}>
      <Text size="s" color={colors.black} style={{ textAlign: "center", ...textStyles }}>
        {text}
      </Text>
    </Coach>
  );
};

const Coach = styled("div")(({ isMobile, customStyles }) => ({
  backgroundColor: colors.coachMarkBackgroundColor,
  borderRadius: spacing.xs,
  maxWidth: isMobile ? "70%" : "50%",
  padding: "0.8rem",
  textAlign: "center",
  ...customStyles, 
}));

CoachMark.propTypes = {
  text: PropTypes.string.isRequired,  
  styles: PropTypes.object,          
  textStyles: PropTypes.object,  
};

export default CoachMark;

