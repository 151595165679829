import { Button, Grid, Card, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon, Text } from "../../../../../../../components/atoms";
import { PeopleCard } from "../../../../../../../components/molecules";
import { colors, spacing, styleUtils } from "../../../../../../../styles";
import { images } from "../../../../../../../assets/images";
import { mockPeopleCard } from "../Constants";

const renderPeople = (item, index) => {
  return (
    <Grid key={index} item xs={12} sm={12} md={4}>
      <Box sx={{ position: "relative" }}>
        <PeopleCard item={item} onClickPeople={() => {}} />
        <PeopleCardDisabledContainer>
          <Icon
            name="poeple-card-lock"
            size={41}
            style={{
              marginTop: styleUtils.pxToRem("38px"),
              marginBottom: styleUtils.pxToRem("16px"),
            }}
          />
          <Text size="s" weight="medium" color={colors.white}>
            Post a job to view details
          </Text>
          <Text size="s" weight="medium" color={colors.white}>
            of the job seeker
          </Text>
        </PeopleCardDisabledContainer>
      </Box>
    </Grid>
  );
};

const NoLocationView = ({ onClickPostNewJob }) => {
  return (
    <NoLocContainer>
      <Grid container mt={spacing.s} spacing={spacing.s}>
        {mockPeopleCard.map(renderPeople)}
      </Grid>
      <CardContainer>
        <Grid container>
          <Grid item xs={6}>
            <Text size="s" color={colors.darkBlack}>
              1000s of active job seekers are on EmployD.
            </Text>
            <Text size="s" color={colors.darkBlack}>
              Post a job to search for jobseekers.
            </Text>
            <PostJobBtn variant="contained" onClick={onClickPostNewJob}>
              Post your job
            </PostJobBtn>
          </Grid>
          <Grid item xs={6}>
            <NoLocImg src={images["noLocationImg"]} />
          </Grid>
        </Grid>
      </CardContainer>
    </NoLocContainer>
  );
};

export default NoLocationView;

const NoLocContainer = styled("div")`
  margin-left: ${styleUtils.pxToRem("24px")};
`;

const PostJobBtn = styled(Button)`
  width: ${styleUtils.pxToRem("152px")};
  height: ${styleUtils.pxToRem("32px")};
  margin-top: ${spacing.s};
`;

const CardContainer = styled(Card)`
  padding: ${spacing.xl} ${spacing.m};
  margin-top: ${spacing.m};
`;

const NoLocImg = styled("img")`
  margin-left: auto;
  display: block;
  height: ${styleUtils.pxToRem("89px")};
  width: ${styleUtils.pxToRem("128px")};
`;

const PeopleCardDisabledContainer = styled("div")`
  background: #0000009f;
  border: 1px solid #efeef3;
  border-radius: 6px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
`;
