export const PeopleConstants = {
  TITLE: "Hire Locals",
  SEARCH_INPUT_PLACEHOLDER: "search by jobseekers",
  SEARCH: "Search",
  POST_NEW_JOB: "Post New Job",
  FILTER_BY: "Filter by",
  PEOPLE: "People",
  NO_PEOPLE_TITLE: "No people found yet",
  NO_PEOPLE_DESCRIPTION: "Keep track of people you’re interested in",
  LOADING_TEXT: "Loading...",
};

export const FilterConstants = {
  RESET_FILTER: "Reset Filter",
  APPLY: "Apply",
  DISTANCE: "Distance",
  MILES: "miles",
  HOURLY_WAGES: "Hourly Wages",
  HOURS_PER_WEEK: "Hours Per Week",
  AGE: "Age",
  EXPERIENCE: "Experience",
  ALL: "All",
  EXPERIENCED: "Experienced",
  BEGINNER: "Beginner",
  FAVOURITES: "Applied",
};

export const PeopleDescription = {
  WAGE_EXPECTATION: "Wage expectations",
  PERSONAL_INFORMATION: "Personal Information",
  ACTIVELY_LOOKING_FOR_JOB: "Actively looking for job",
  PREFERENCES: "PREFERENCES",
  WORKING_HOURS: "Hours/week",
  EXPERIENCE: "Years of Experience",
  DAYS_WEEK: "Days/week",
  NOTE: "About Me",
};

export const SORT_BY_BUTTON = "Sort by";
export const CLEAR_OPTION = "Clear";

export const peopleSortingOptions = [
  // {
  //   key: "distance",
  //   title: "Distance",
  //   order: "",
  // },
  {
    key: "wageExpectation",
    title: "Hourly wage",
    order: "",
  },
  {
    key: "workingHours",
    title: "Hours per week",
    order: "",
  },
  {
    key: "experience",
    title: "Experience",
    order: "",
  },
];

export const peopleExperienceOptions = [
  {
    id: 1,
    label: "All",
    value: "all",
    isSelected: false,
  },
  {
    id: 2,
    label: "Experienced",
    value: "Experience",
    isSelected: false,
  },
  {
    id: 3,
    label: "Beginner",
    value: "Beginner",
    isSelected: false,
  },
];

export const mockPeopleCard = [
  {
    age: "25",
    countryCode: "91",
    createdAt: 1663320317459,
    daysPerWeek: ["Thu"],
    email: "dip@yopmail.com",
    experience: ["2-5"],
    favoriteJobs: [
      {
        brandName: "ANKUSHCOMPANY",
        jobId: "-N5i3ZhBmvLN-F1s0fTM",
      },
    ],
    fullName: "Carol T Reyes",
    isPhoneVerified: false,
    lat: 12.9061592,
    lng: 77.6900915,
    loginType: "PW",
    note: "",
    phOtp: "",
    phoneNumber: "7259727739",
    photoURL: "",
    referrerId: "",
    referrerType: "",
    resumeEmpIds: ["4ISeGWH2WAZkPYzpiP3MT1fpkRd2"],
    resumeUrl: "",
    signUpOffer: "",
    social_id: "",
    timeSlot: ["Evening"],
    wageExpectation: ["10-15"],
    workingHours: ["2-5"],
  },

  {
    age: 30,
    countryCode: "91",
    createdAt: 1663914705040,
    daysPerWeek: ["mon", "tue"],
    email: "biplab.malakar@impactanalytics.co",
    experience: ["beg"],
    fullName: "Benjamin D Marr",
    isPhoneVerified: false,
    lat: 49.12,
    lng: -40.12,
    loginType: "PW",
    note: "abc",
    phOtp: "",
    phoneNumber: "9592350924",
    photoURL: "test url",
    referrerId: "",
    referrerType: "",
    resumeUrl: "test resume url",
    signUpOffer: "",
    social_id: "",
    starredBy: ["Ph7t3sNV6AeyVvq3c7zV1fqaBay1"],
    wageExpectation: ["1-2"],
    website: "test",
    workingHours: ["2-3"],
  },

  {
    age: "22",
    countryCode: "91",
    createdAt: 1663317967344,
    daysPerWeek: ["Tue"],
    email: "celolid330@edxplus.com",
    experience: ["1-2"],
    favoriteJobs: [
      {
        brandName: "IMPACT453",
        jobId: "-N4VrPG__Rag1i3ddQA4",
      },
    ],
    fullName: "Eric V Britt",
    isPhoneVerified: false,
    lat: 18.5405512,
    lng: 73.8892731,
    loginType: "PW",
    note: "",
    phOtp: "",
    phoneNumber: "9821277814",
    photoURL: "",
    referrerId: "",
    referrerType: "",
    resumeUrl: "",
    signUpOffer: "",
    social_id: "",
    timeSlot: ["Afternoon"],
    wageExpectation: ["5-10"],
    workingHours: ["0-2"],
  },

  {
    age: "18",
    countryCode: "91",
    createdAt: 1663329517656,
    daysPerWeek: ["Tue"],
    email: "jijac47631@pahed.com",
    experience: ["Beginner"],
    fcmTokens: [
      "eVSihoigQrif6qSBycNp9V:APA91bF48gSBJPga1rEPTV_vxUuWugirwX1p1tFsBbrR7p-qt2t2ikIL74rvhE58M9gIHGAfTu_gxJe_AMQOcuiAGKez4zATRarecJu_--TR7CTMQvphMkuEoRPAoGz-yGQ85cla9CDg",
    ],
    fullName: "Daniel J",
    isPhoneVerified: false,
    lat: "",
    lng: "",
    loginType: "PW",
    note: "",
    phOtp: "",
    phoneNumber: "9821277814",
    referrerId: "",
    referrerType: "",
    resumeEmpIds: ["Ph7t3sNV6AeyVvq3c7zV1fqaBay1"],
    resumeUrl: "",
    signUpOffer: "",
    social_id: "",
    starredBy: ["Ph7t3sNV6AeyVvq3c7zV1fqaBay1"],
    timeSlot: ["Afternoon"],
    wageExpectation: ["5-10"],
    workingHours: ["0-2"],
  },

  {
    age: "25",
    countryCode: "91",
    createdAt: 1656647166773,
    daysPerWeek: ["Wed"],
    email: "bidohic695@meidir.com",
    experience: ["1-2"],
    fullName: "Mathew Blackwell",
    isPhoneVerified: false,
    lat: "",
    lng: "",
    loginType: "PW",
    note: "",
    phOtp: "",
    phoneNumber: "7259727739",
    photoURL: "",
    referrerId: "a76w0lP4wXc3QKcU2W8aBwZxjQV2",
    referrerType: "jobSeeker",
    resumeEmpIds: [
      "78aiggMG9UZRuev5OiiYPsUmOYt2",
      "RKefmhkMVLX2tQjSOGKd4LRqTyy1",
      "NJYycne3dsOxkd13ai1d0ZSI5Nf1",
      "Ph7t3sNV6AeyVvq3c7zV1fqaBay1",
      "ddHSaLXeT1VrOZLQSJEM5QMMJYh2",
      "Bt64vkc8J7UaP1jbyIotIwMFAkj1",
      "vhaOoWwx4wav6e9Ejt1hsELaEaQ2",
    ],
    resumeUrl: "",
    signUpOffer: "",
    starredBy: [
      "gnfw2Tp0ltaUAQfwQFKDW5Tdz022",
      "NJYycne3dsOxkd13ai1d0ZSI5Nf1",
      "ddHSaLXeT1VrOZLQSJEM5QMMJYh2",
      "Bt64vkc8J7UaP1jbyIotIwMFAkj1",
    ],
    timeSlot: ["Afternoon"],
    wageExpectation: ["10-15"],
    workingHours: ["2-5"],
  },

  {
    age: "22",
    countryCode: "91",
    createdAt: 1659599959038,
    daysPerWeek: ["Mon"],
    email: "tauguhenneca-5271@yopmail.com",
    experience: ["Beginner"],
    favoriteJobs: [
      {
        brandName: "METAVERSE",
        jobId: "-N6SDEBjabPJtzWv71Xb",
      },
    ],
    fullName: "Lori Davis",
    isPhoneVerified: false,
    lat: 12.924540349454576,
    lng: 77.68331637132759,
    loginType: "PW",
    note: "Hello",
    phOtp: "",
    phoneNumber: "8878044181",
    photoURL: "",
    resumeEmpIds: [
      "HlphzjcD3RYmatGFMLKValmW0hp2",
      "4ISeGWH2WAZkPYzpiP3MT1fpkRd2",
      "8C6X88plG1VnlQWeF4MrrlwYM622",
      "pNkjGi3ir5SuY1WVmrz986O3fUf2",
      "vhaOoWwx4wav6e9Ejt1hsELaEaQ2",
      "b8jzdFVovOPXgWIhEprHWQipYGC2",
    ],
    resumeUrl: "",
    signUpOffer: "",
    timeSlot: ["Morning"],
    wageExpectation: ["5-10"],
    workingHours: ["0-2"],
  },
];
