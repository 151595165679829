import { Box, Step, Stepper, StepLabel } from "@mui/material";
import { Icon } from "../../atoms";
import { colors } from "../../../styles";

const Steppers = (props) => {
  const { stepsCount, activeStep, markComplete = false, sx } = props;

  return (
    <Box sx={sx}>
      <Stepper
        activeStep={activeStep}
        sx={{
          "& .css-z7uhs0-MuiStepConnector-line": {
            borderColor: colors.stroke,
          },
        }}
      >
        {[...Array(stepsCount)].map((value, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={() => (
                <Icon
                  name={
                    index + 1 === activeStep
                      ? markComplete
                        ? "stepperFilled"
                        : "stepperActive"
                      : index + 1 < activeStep
                      ? "stepperFilled"
                      : "stepperInactive"
                  }
                  size={21}
                />
              )}
            >
              {value}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Steppers;
