import { FormHelperText } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { employerCheckWebsite } from "../../../configs/network/apis";
import TextInput from "../TextInput";

const WebsiteInputComponent = ({
  inputRef,
  websiteError,
  setWebsiteError,
  validateWebsite,
  ...props
}) => {
  useEffect(() => {
    inputRef.current = "";
  }, []);

  return (
    <>
      <TextInput
        name="website"
        onChange={(e) => (inputRef.current = e.target.value)}
        onBlur={validateWebsite}
        onFocus={() => setWebsiteError(null)}
        {...props}
      />
      {!!websiteError && (
        <FormHelperText sx={{ color: "red" }}>{websiteError}</FormHelperText>
      )}
    </>
  );
};

const WebsiteInput = forwardRef((props, ref) => {
  const websiteInputRef = useRef();
  const [websiteError, setWebsiteError] = useState();

  useImperativeHandle(ref, () => ({
    getWebsiteValue,
  }));

  const getWebsiteValue = async () => {
    setWebsiteError("Validating...");
    const websiteValue = websiteInputRef.current;
    let isValid = await isWebsiteValid(websiteValue);

    if (!isValid) {
      const error = "Website is not valid";
      setWebsiteError(error);
      return { webError: error };
    }

    setWebsiteError(null);
    return {
      webError: null,
      websiteValue: websiteValue ? formatWebsiteUrl(websiteValue) : null,
    };
  };

  return useMemo(
    () => (
      <WebsiteInputComponent
        inputRef={websiteInputRef}
        validateWebsite={getWebsiteValue}
        websiteError={websiteError}
        setWebsiteError={setWebsiteError}
        {...props}
      />
    ),
    [websiteError, websiteInputRef]
  );
});

export default WebsiteInput;

const websiteValidateCache = {};

const isWebsiteValid = async (value) => {
  if (!(value in websiteValidateCache)) {
    if (value?.length > 0) {
      let res = await websiteExists(value);
      websiteValidateCache[value] = res;
      return res;
    }
    return true;
  } else {
    return websiteValidateCache[value];
  }
};

const websiteExists = async (url) => {
  url = formatWebsiteUrl(url);
  try {
    let res = await employerCheckWebsite({ url });
    return res.data;
  } catch (error) {
    return false;
  }
};

const formatWebsiteUrl = (url) => {
  if (!url.startsWith("http")) {
    url = "https://" + url;
  }
  return url;
};
