import PeopleDescription from "./views/PeopleDescription";
import PeopleDescriptionMobile from "./mobileViews/PeopleDescription";

import useIsMobile from "../../../../../../hooks/useIsMobile";

const PeopleDescriptionContainer = (props) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? PeopleDescriptionMobile : PeopleDescription;

  return <Wrapper {...props} />;
};

export default PeopleDescriptionContainer;
