import React from "react";
import PropTypes from "prop-types";
import { Chip, Divider } from "@mui/material";
import styled from "@emotion/styled";
import { colors, spacing, textSizes, typography, styleUtils } from "../../../../../styles";
import { Icon, Text } from "../../../../../components/atoms";
import { getProcessedJobDetailValue } from "utils/commonUtils";

const Preferences = ({ userDetails, handleEdit }) => {
  return (
    <>
      <TopContainer>
        <Icon
          name="money"
          alt={"money"}
          size={22}
        />
        <Text weight="medium">
          {`$${getProcessedJobDetailValue(userDetails?.wageExpectation)} per hour `}
        </Text>
        <Icon
          name="clock"
          alt={"clock"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">
          {`${getProcessedJobDetailValue(userDetails?.workingHours)} hours per week `}
        </Text>
        <Icon
          name="experience"
          alt={"experience"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">
          {userDetails?.experience.map((hours) =>
            hours === "Beginner" ? hours : `${hours} years`,
          )}
        </Text>
        <Icon
          name="calendar"
          alt={"calendar"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">{userDetails?.daysPerWeek?.join(", ")}</Text>
        <Icon
          name="timeslot"
          alt={"timeslot"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">{userDetails?.timeSlot?.join(", ")}</Text>
      </TopContainer>
      <Divider sx={{ my: spacing.m }} />
      <TopContainer>
        <Text
          weight="semibold"
          style={{ marginBottom: spacing.xs }}
        >
          About Me
        </Text>
        <Text
          size="s"
          color={colors.placeholder}
        >
          {userDetails?.note}
        </Text>
      </TopContainer>
      <Divider sx={{ my: spacing.m }} />
      <TopContainer>
        <SpaceFlexBox>
          <Text
            weight="semibold"
            style={{ marginBottom: spacing.s }}
          >
            My Job Interests
          </Text>
          <Icon
            name="profile-pencil"
            style={{ cursor: "pointer" }}
            onClick={() => handleEdit("preferences", "editinter")}
          />
        </SpaceFlexBox>
        <InterestsFlexBox>
          {userDetails?.job_interests?.map((jobRole, index) => {
            return (
              <Chip
                label={jobRole}
                key={index}
                variant="outlined"
                onClick={() => {}}
                sx={{
                  height: 34,
                  width: "auto",
                  margin: "0 0.75rem 0.75rem 0",
                  pointerEvents: "none",
                  borderRadius: styleUtils.pxToRem("17px"),
                  "& .MuiChip-label": {
                    fontSize: textSizes.s,
                    fontWeight: typography.medium,
                    color: "#2C4895",
                  },
                  border: "1px solid #2C4895",
                  "& .MuiChip-clickable:hover": {
                    backgroundColor: colors.white,
                  },
                }}
              />
            );
          })}
        </InterestsFlexBox>
      </TopContainer>
    </>
  );
};

Preferences.propTypes = {
  userDetails: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default Preferences;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 ${spacing.xl};
`;

const SpaceFlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const InterestsFlexBox = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;
