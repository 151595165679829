import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { otpInitialState } from "./initialState";
import * as otpActionTypes from "../actions/actionTypes";

const otpReducer = {
  otpState: handleActions(
    {
      [otpActionTypes.SEND_OTP_REQUEST]: (state) => {
        return immutable(state, {
          sendOtpLoading: { $set: true },
          sendOtpFailed: { $set: false },
          sendOtpSuccess: { $set: false },
        });
      },
      [otpActionTypes.SEND_OTP_FAILED]: (state) => {
        return immutable(state, {
          sendOtpLoading: { $set: false },
          sendOtpFailed: { $set: true },
          sendOtpSuccess: { $set: false },
        });
      },
      [otpActionTypes.SEND_OTP_SUCCESS]: (state) => {
        return immutable(state, {
          sendOtpLoading: { $set: false },
          sendOtpFailed: { $set: false },
          sendOtpSuccess: { $set: true },
        });
      },
      [otpActionTypes.VERIFY_OTP_REQUEST]: (state) => {
        return immutable(state, {
          verifyOtpLoading: { $set: true },
          verifyOtpFailed: { $set: false },
          verifyOtpSuccess: { $set: false },
        });
      },
      [otpActionTypes.VERIFY_OTP_FAILED]: (state) => {
        return immutable(state, {
          verifyOtpLoading: { $set: false },
          verifyOtpFailed: { $set: true },
          verifyOtpSuccess: { $set: false },
        });
      },
      [otpActionTypes.VERIFY_OTP_SUCCESS]: (
        state,
        { response: { key, verifiedStatus, validationSrc } }
      ) => {
        return immutable(state, {
          verifyOtpLoading: { $set: false },
          verifyOtpFailed: { $set: false },
          verifyOtpSuccess: { $set: true },
          [key]: { $set: verifiedStatus },
          [validationSrc]: { $set: true },
        });
      },
      [otpActionTypes.RESET_EMAIL_VALIDATION]: (state) => {
        return immutable(state, {
          isEmailOtpValid: { $set: false },
          emailValidation: { $set: false },
        });
      },
      [otpActionTypes.RESET_PHONE_VALIDATION]: (state) => {
        return immutable(state, {
          isPhoneOtpValid: { $set: false },
          phoneValidation: { $set: false },
        });
      },
      [otpActionTypes.RESET_OTP_VIEW]: (state) => {
        return immutable(state, {
          sendOtpLoading: { $set: false },
          sendOtpFailed: { $set: false },
          sendOtpSuccess: { $set: false },
          verifyOtpLoading: { $set: false },
          verifyOtpFailed: { $set: false },
          verifyOtpSuccess: { $set: false },
          isEmailOtpValid: { $set: false },
          isPhoneOtpValid: { $set: false },
          emailValidation: { $set: false },
          phoneValidation: { $set: false },
        });
      },
    },
    otpInitialState
  ),
};

export default otpReducer;
