import { Button, Container } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";
import { EasyOneClickConstants } from "./Constants";

const EasyOneClick = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        my: spacing.xxxxl,
      }}
    >
      <Text id="EASY_CLICK_MOB_TITLE_1" size={32} style={{ lineHeight: 1.2 }}>
        {EasyOneClickConstants.TITLE_1}{" "}
        <Text
          id="EASY_CLICK_MOB_TITLE_2"
          size={32}
          component="span"
          weight="extrabold"
          color={colors.orange}
          style={{ lineHeight: 1.2 }}
        >
          {EasyOneClickConstants.TITLE_2}
        </Text>{" "}
      </Text>
      <Text id="EASY_CLICK_MOB_TITLE_3" size={32} style={{ lineHeight: 1.2 }}>
        {EasyOneClickConstants.TITLE_3}
      </Text>
      <Text
        id="EASY_CLICK_MOB_SUB_TITLE_1"
        color={colors.placeholder}
        style={{ marginTop: spacing.s }}
      >
        {EasyOneClickConstants.SUB_TITLE_1}
        {EasyOneClickConstants.SUB_TITLE_2} {"\n"}
        {EasyOneClickConstants.SUB_TITLE_3}
      </Text>
      {!isAuthenticated && (
        <Button
          id="EASY_CLICK_MOB_STARTED_BTN"
          variant="contained"
          sx={{
            mt: spacing.xxl,
            width: "fit-content",
            px: spacing.xxl,
            py: spacing.xs,
          }}
          onClick={onGetStarted}
        >
          {EasyOneClickConstants.GET_STARTED_BTN}
        </Button>
      )}
      <img
        src={images.home["jobseeker-hero"]["3"]}
        alt="3"
        style={{ width: "100%", marginTop: spacing.xl }}
      />
    </Container>
  );
};

export default EasyOneClick;
