import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Button, CircularProgress, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { Icon, Text } from "../../../../../../../components/atoms";
import {
  NonServiceableArea,
  JobCardSkeleton,
} from "../../../../../../../components/molecules";

import JobSeekerSelector from "../../../../../selectors/JobSeekerSelector";

import ResponsiveFilterWrapper from "../../ResponsiveFilter/ResponsiveFilterWrapper";
import JobResults from "../../JobResults";
import Sort from "./Sort";

import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../../../../../styles";
import { JobBoardConstants } from "../../Constants";

/**
 * Listed Job Cards view for responsive view
 */
const ListView = (props) => {
  const {
    toggleExploreView,
    jobSeekerListing,
    jobSeekerSuggestedResults,
    filterSettings,
    location,
    onClickJob,
    onClickBrandLogo,
    loggedInUser,
    handleSorting,
    handleFilter,
    isLocationAllowed,
    handleLoadMore,
    showLoadMore,
    totalJobsCount,
    emailId,
    setEmailId,
    onPressNotify,
  } = props;

  const isJobListFetching = useSelector(JobSeekerSelector.isJobListFetching);
  const showEmptyResultView = isEmpty(location?.locationName);

  const handleChange = (email) => {
    setEmailId(email);
  };

  const renderJobCardSekeleton = (_, index) => {
    return (
      <Grid item xs={12} sm={12} key={index}>
        <JobCardSkeleton />
      </Grid>
    );
  };

  const jobSeekerloadinglist = [1, 2, 3, 4, 5, 6];
  const isShowingSuggestedJobs = isEmpty(jobSeekerListing);
  const finalJobsDataToRender = isShowingSuggestedJobs
    ? jobSeekerSuggestedResults
    : jobSeekerListing;

  const showTextForLoadingJobs = isJobListFetching ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: styleUtils.pxToRem("10px"),
      }}
    >
      <Text weight="semibold">Loading jobs</Text>
      <CircularProgress
        size="1rem"
        color="inherit"
        sx={{ marginLeft: styleUtils.pxToRem("10px") }}
      />
    </div>
  ) : (
    //To keep space occupied even though content is not there.
    <div></div>
  );

  const nonServicableAreaView = (
    <NonServiceableArea
      image="nonserviceblearea-image"
      imageHeight="100%"
      title={JobBoardConstants.WE_ARE_NOT_THERE}
      description={
        loggedInUser
          ? JobBoardConstants.AUTHEINICATED_COMMING_SOON
          : JobBoardConstants.COMING_SOON
      }
      activeRegion={JobBoardConstants.CURRENT_REGION}
      sub_description={
        loggedInUser
          ? JobBoardConstants.AUTHEINICATED_BE_THE_FIRST_ONE
          : JobBoardConstants.BE_THE_FIRST_ONE
      }
      handleChange={handleChange}
      emailId={emailId}
      onPressNotify={onPressNotify}
      loggedInUser={loggedInUser}
      marginTop="15%"
      textInputWidth="80%"
      buttonWidth="70%"
    />
  );

  return (
    <>
      {isLocationAllowed ? (
        <Grid
          sx={{
            minHeight: "68vh",
            paddingLeft: spacing.m,
            paddingRight: spacing.s,
          }}
          container
          mt={spacing.xxl}
        >
          <>
            <Grid item xs={12} sm={12}>
              <OpitonsContainer
                sx={{ "&::-webkit-scrollbar": { display: "none" } }}
              >
                <Grid item xs="auto" sm="auto" md="auto">
                  <Sort handleSorting={handleSorting} />
                </Grid>
                <Grid
                  item
                  xs="auto"
                  sm="auto"
                  md="auto"
                  sx={{ height: styleUtils.pxToRem("36px") }}
                >
                  <Divider orientation="vertical" sx={{ borderColor: "#E8E8E8" }} />
                </Grid>
                <ResponsiveFilterWrapper
                  filterSettings={filterSettings}
                  handleFilter={handleFilter}
                />
              </OpitonsContainer>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ marginTop: spacing.l, marginLeft: spacing.xxxs,display:'flex',alignItems:'center',justifyContent:'space-between' }}
              >
                {totalJobsCount !== 0 ? (
                  <Text >
                    <Text  component="span">
                      {JobBoardConstants.SEARCH_RESULTS_SHOW}
                    </Text>
                    <Text  weight="bold" component="span">
                      {jobSeekerListing?.length}
                    </Text>
                    <Text  component="span">
                      {JobBoardConstants.SEARCH_RESULTS_OF}
                    </Text>
                    <Text  weight="bold" component="span">
                      {totalJobsCount}
                    </Text>
                    {JobBoardConstants.SEARCH_RESULTS_JOBS}
                  </Text>
                ) : (
                  showTextForLoadingJobs
                )}
                <MapToggleButton
                onClick={toggleExploreView}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.dodgerBlue,
                  },
                }}
              >
                <Icon name="map" color={colors.white} size={14} />
                Map View
              </MapToggleButton>
              </Grid>

              {isJobListFetching ? (
                <Grid container mt={spacing.xxs} spacing={spacing.xl}>
                  {jobSeekerloadinglist.map(renderJobCardSekeleton)}
                </Grid>
              ) : (
                <JobResults
                  finalJobsDataToRender={finalJobsDataToRender}
                  isShowingSuggestedJobs={isShowingSuggestedJobs}
                  onClickJob={onClickJob}
                  onClickBrandLogo={onClickBrandLogo}
                  showEmptyResultView={showEmptyResultView}
                />
              )}
            </Grid>
            {showLoadMore && (
              <LoadMoreBtn
                variant="outlined"
                onClick={handleLoadMore}
                endIcon={<ArrowDownwardIcon sx={{ color: colors.primary }} />}
              >
                {JobBoardConstants.LOAD_MORE_JOBS}
              </LoadMoreBtn>
            )}
          </>
        </Grid>
      ) : (
        <Grid
          container
          pl={spacing.m}
          mt={spacing.xxs}
          spacing={spacing.m}
        >
           {nonServicableAreaView}
        </Grid>
      )}
    </>
  );
};

export default ListView;

const OpitonsContainer = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  scrollbarwidth: none;
`;
const LoadMoreBtn = styled(Button)`
  font-size: ${textSizes.xl};
  font-weight: ${typography.medium};
  height: ${styleUtils.pxToRem("60px")};
  margin-top: ${spacing.xxl};
  margin-left: auto;
  margin-right: auto;
  width: ${styleUtils.pxToRem("345px")};
`;
const MapToggleButton = styled(Button)`
  align-items: center;
  background-color: ${colors.dodgerBlue};
  border-radius: ${styleUtils.pxToRem("18px")};
  color: ${colors.white};
  display: flex;
  font-size: ${textSizes.s};
  font-weight: ${typography.bold};
  height: ${styleUtils.pxToRem("32px")};
  justify-content: center;
  gap:7px;
  width: ${styleUtils.pxToRem("120px")};
`;
