import { styled } from "@mui/material/styles";

import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";
import { navigateWindow } from "../../../utils/commonUtils";
import { FooterConstants } from "../Constants";

const DownloadApp = () => {
  const onClickRedirect = (path) => {
    navigateWindow(path);
  };

  return (
    <>
      <Text color={colors.white} size="s" style={{ marginTop: spacing.s }}>
        {FooterConstants.HIRING_SUBTEXT}
      </Text>
      <Text color={colors.white} size="s" style={{ marginTop: spacing.xxxs }}>
        {FooterConstants.DOWNLOAD_SUBTEXT}
      </Text>
      <Row>
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            onClickRedirect(
              "https://apps.apple.com/tt/app/employd/id1583769658"
            );
          }}
          onKeyDown={() => {
            onClickRedirect(
              "https://apps.apple.com/tt/app/employd/id1583769658"
            );
          }}
        >
          <img
            alt={"appStore"}
            src={images.home.store["app-store"]}
            style={{
              width: 191 / 1.4,
              height: 67 / 1.4,
              backgroundSize: "cover",
              marginRight: spacing.l,
            }}
          />
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            onClickRedirect(
              "https://apps.apple.com/tt/app/employd/id1583769658"
            );
          }}
          onKeyDown={() => {
            onClickRedirect(
              "https://apps.apple.com/tt/app/employd/id1583769658"
            );
          }}
        >
          <img
            alt={"playStore"}
            src={images.home.store["play-store"]}
            style={{
              width: 223 / 1.4,
              height: 86 / 1.4,
              backgroundSize: "cover",
            }}
          />
        </div>
      </Row>
    </>
  );
};

export default DownloadApp;

const Row = styled("div")`
  display: flex;
  margin-top: ${spacing.l};
  align-items: center;
`;
