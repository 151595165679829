import * as yup from "yup";
import { isValidPhoneNumber } from "../../../../utils/commonUtils";

export const loginOtpUserSchema = yup.object({
  email: yup.string().email("Enter a valid email"),
  phone: yup
    .string()
    .test(
      "validate-phone",
      "Please enter valid phone number.",
      async (value, context) => {
        if (value) {
          return await isValidPhoneNumber({
            countryCode: context.parent.countryCode,
            phoneNumber: value,
          });
        }
        return true;
      }
    ),
});

export const loginOtpVerificationSchema = yup.object({
  loginOtp: yup
    .string()
    .required("Otp is required to login")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be 6 digit")
    .max(6, "Must be 6 digit"),
});
