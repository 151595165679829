import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { signUpInitialState } from "./initialState";
import * as signUpActionTypes from "../actions/actionTypes";

const signUpReducer = {
  signUpState: handleActions(
    {
      [signUpActionTypes.JOB_SEEKER_SIGNUP_REQUEST]: (state) => {
        return immutable(state, {
          isJobSeekerSignUpLoading: { $set: true },
          isJobSeekerSignUpFailed: { $set: false },
          isJobSeekerSignUpSuccess: { $set: false },
          userDetails: { $set: null },
        });
      },
      [signUpActionTypes.JOB_SEEKER_SIGNUP_FAILED]: (state) => {
        return immutable(state, {
          isJobSeekerSignUpLoading: { $set: false },
          isJobSeekerSignUpFailed: { $set: true },
          isJobSeekerSignUpSuccess: { $set: false },
          userDetails: { $set: null },
        });
      },
      [signUpActionTypes.JOB_SEEKER_SIGNUP_SUCCESS]: (state, { userData }) => {
        return immutable(state, {
          isJobSeekerSignUpLoading: { $set: false },
          isJobSeekerSignUpFailed: { $set: false },
          isJobSeekerSignUpSuccess: { $set: true },
          userDetails: { $set: userData },
        });
      },
      [signUpActionTypes.EMPLOYER_SIGNUP_REQUEST]: (state) => {
        return immutable(state, {
          isEmployerSignUpLoading: { $set: true },
          isEmployerSignUpFailed: { $set: false },
          isEmployerSignUpSuccess: { $set: false },
          userDetails: { $set: null },
        });
      },
      [signUpActionTypes.EMPLOYER_SIGNUP_FAILED]: (state) => {
        return immutable(state, {
          isEmployerSignUpLoading: { $set: false },
          isEmployerSignUpFailed: { $set: true },
          isEmployerSignUpSuccess: { $set: false },
          userDetails: { $set: null },
        });
      },
      [signUpActionTypes.EMPLOYER_SIGNUP_SUCCESS]: (state, { userData }) => {
        return immutable(state, {
          isEmployerSignUpLoading: { $set: false },
          isEmployerSignUpFailed: { $set: false },
          isEmployerSignUpSuccess: { $set: true },
          userDetails: { $set: userData },
        });
      },
      [signUpActionTypes.RESET_SIGN_UP]: (state) => {
        return immutable(state, {
          isJobSeekerSignUpLoading: { $set: false },
          isJobSeekerSignUpFailed: { $set: false },
          isJobSeekerSignUpSuccess: { $set: false },
          userDetails: { $set: null },
          isEmployerSignUpLoading: { $set: false },
          isEmployerSignUpFailed: { $set: false },
          isEmployerSignUpSuccess: { $set: false },
          employerValidDetails: { $set: null },
        });
      },
      [signUpActionTypes.EMPLOYER_VERIFY_REQUEST]: (state) => {
        return immutable(state, {
          isEmployerVerificationLoading: { $set: true },
          isEmployerVerificationFailed: { $set: false },
          isEmployerVerificationSuccess: { $set: false },
        });
      },
      [signUpActionTypes.EMPLOYER_VERIFY_SUCCESS]: (state, { response }) => {
        return immutable(state, {
          isEmployerVerificationLoading: { $set: false },
          isEmployerVerificationFailed: { $set: false },
          isEmployerVerificationSuccess: { $set: true },
          employerValidDetails: { $set: response },
        });
      },
      [signUpActionTypes.EMPLOYER_VERIFY_FAILED]: (state) => {
        return immutable(state, {
          isEmployerVerificationLoading: { $set: false },
          isEmployerVerificationFailed: { $set: true },
          isEmployerVerificationSuccess: { $set: false },
        });
      },
    },
    signUpInitialState,
  ),
};

export default signUpReducer;
