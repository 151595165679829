import { Box, Button, Container, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../components/atoms";
import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import { colors, spacing } from "../../styles";
import useIsMobile from "../../hooks/useIsMobile";
import usePreviousRoute from "../../hooks/usePreviousRoute";
import { logEvent } from "../../configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { setLoginRedirection } from "utils/commonUtils";
import { useEffect } from "react";

const UserSelect = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const previousRoute = usePreviousRoute();
  const { pathname, search, state } = location;
  const isChatFlow = pathname.includes("/chat");
  useEffect(() => {
    if (isChatFlow) {
     const isCurrentUserJobSeeker = search.includes(EMPLOYER)
      if (isCurrentUserJobSeeker) {
        navigate(`/login/${JOB_SEEKER}`, {
          replace: true,
        });
      } else {
        navigate(`/login/${EMPLOYER}`, {
          replace: true,
        });
      }
      setLoginRedirection("", pathname, search, state);
    }
  },[]);

  const navigateTo = (type) => {
    navigate(`/login/${type}`, {
      state: {
        path: location.pathname,
      },
    });
  };

  const handleClose = () => {
    if (previousRoute) {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  };

  return (
    <Container
      sx={{
        height: "90vh",
        position: "relative",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      <Grid item xs={12} sm={12} md={6}>
        <MiddleBox style={{ minWidth: isMobile ? "90%" : "35%" }}>
          <TopContainer>
            <Icon size={16} name="close" onClick={handleClose} />
          </TopContainer>
          <div>
            <Text
              size="xl"
              weight="medium"
              color={colors.darkBlue}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Continue As
            </Text>
          </div>
          <Box sx={{ marginTop: spacing.s }} component="form" autoComplete="off">
            <Button
              variant="contained"
              style={{ fontSize: "1rem", marginTop: spacing.l }}
              onClick={() => {
                logEvent(FirebaseEvents.CONTINUE_WITH_JS);
                navigateTo(JOB_SEEKER);
              }}
            >
              JOB SEEKER
            </Button>
            <Button
              variant="contained"
              style={{ fontSize: "1rem", marginTop: spacing.l }}
              onClick={() => {
                logEvent(FirebaseEvents.CONTINUE_WITH_EMP, {});
                navigateTo(EMPLOYER);
              }}
            >
              EMPLOYER
            </Button>
          </Box>
        </MiddleBox>
      </Grid>
    </Container>
  );
};

export default UserSelect;

const MiddleBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  box-shadow: 0px 5px 15px ${colors.shadow};
  border: 1px solid ${colors.stroke};
  border-radius: 6px;
  padding: ${spacing.xl};
`;
const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${spacing.s};
  cursor: pointer;
`;
