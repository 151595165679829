import { Button, Container, Grid } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";
import { SearchLocalJobsConstants } from "./Constants";

const SearchLocalJobs = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "calc(90vh - 64px)",
        marginBottom: styleUtils.pxToRem("110px"),
        marginTop: styleUtils.pxToRem("145px"),
      }}
    >
      <Grid container columnSpacing={spacing.xxxxl}>
        <Grid item md={6} ml={6}>
          <img
            src={images.home["jobseeker-hero"]["2"]}
            alt="2"
            style={{ width: styleUtils.pxToRem("549px")}}
          />
        </Grid>
        <Grid
          item
          md={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            id="SEARCH_LOCAL_WEB_TITLE_1"
            size={44}
            style={{ lineHeight: 1.2 }}
          >
            {SearchLocalJobsConstants.TITLE_1}{" "}
            <Text
              id="SEARCH_LOCAL_WEB_TITLE_2"
              size={44}
              component="span"
              weight="extrabold"
              color={colors.primary}
              style={{ lineHeight: 1.2 }}
            >
              {SearchLocalJobsConstants.TITLE_2}
            </Text>
          </Text>
          <Text
            id="SEARCH_LOCAL_WEB_TITLE_3"
            size={44}
            style={{ lineHeight: 1.2 }}
          >
            {SearchLocalJobsConstants.TITLE_3}
          </Text>
          <Text
            id="SEARCH_LOCAL_WEB_SUBTITLE_1"
            size="l"
            color="#4C5468"
            style={{ marginTop: spacing.s }}
          >
            {SearchLocalJobsConstants.SUB_TITLE_1}
          </Text>
          <Text id="SEARCH_LOCAL_WEB_SUBTITLE_2" size="l" color="#4C5468">
            {SearchLocalJobsConstants.SUB_TITLE_2}
          </Text>
          {!isAuthenticated && (
            <Button
              id="SEARCH_LOCAL_WEB_STARTED_BTN"
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("46px"),
                width: styleUtils.pxToRem("176px"),
                mt: spacing.xxxl,
                px: spacing.xxl,
                py: spacing.xs,
                fontSize: textSizes.l,
                fontWeight: typography.bold,
              }}
              onClick={onGetStarted}
            >
              {SearchLocalJobsConstants.GET_STARTED_BTN}
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchLocalJobs;
