export const commonInitialState = {
  loader: {
    isShown: false,
  },
  notification: {
    isShown: false,
    snackbarProps: {},
    alertProps: {},
  },
};
