import * as otpActions from "./actionTypes";

export const sendOtpRequest = (payload) => {
  return {
    type: otpActions.SEND_OTP_REQUEST,
    payload,
  };
};

export const verifyOtpRequest = (payload) => {
  return {
    type: otpActions.VERIFY_OTP_REQUEST,
    payload,
  };
};

export const resetEmailValidation = () => {
  return {
    type: otpActions.RESET_EMAIL_VALIDATION,
  };
};

export const resetPhoneValidation = () => {
  return {
    type: otpActions.RESET_PHONE_VALIDATION,
  };
};

export const resetOtpContainer = () => {
  return {
    type: otpActions.RESET_OTP_VIEW,
  };
};
