import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import LoginSelector from "../../../Login/selectors/LoginSelector";

import PeopleBoardContainer from "./views/PeopleBoard/PeopleBoardContainer";
import PeopleDescriptionContainer from "./views/PeopleDescription/PeopleDescriptionContainer";

import { EMPLOYER_PAGE_ROUTE, POST_NEW_JOB_PAGE_ROUTE } from "../../../../routes/routeConstants";

import EmployerSelector from "../../selectors/EmployerSelector";
import {
  employerJobSeekerListingRequest,
  employerJobSeekerReset,
} from "../../actions/EmployerActions";

// import { useGetPeople } from "../../../../react-query/people";
import { hideLoader, showLoader } from "../../../../utils/commonUtils";
import {
  getEmployerActiveLocs,
  getEmployerSeekersSuggestions,
} from "../../../../configs/network/apis";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const PeopleContainer = () => {
  const [seekerSuggestions, setSeekerSuggestions] = useState([]);
  const [peopleListPage, setPeopleListPage] = useState(1);
  const [isLoadingEmployerLocations, setIsLoadingEmployerLocations] = useState(false);
  const [employerLocations, setEmployerLocations] = useState([]);
  const [employerActiveLocations, setEmployerActiveLocations] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [locationDetails, setLocationDetails] = useState({});

  const filterSettings = useSelector(
    LoginSelector.getLoggedInUserSettings("employerJobSeekerListingFilter"),
  );
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const employerJobSeekerListing = useSelector(EmployerSelector.getEmployerJobSeekerListing);
  const peoplePageLimit = useSelector(EmployerSelector.getPageLimitEmployerJobSeekerListing);
  const totalJobSeekers = useSelector(EmployerSelector.getEmployerJobSeekersListTotal);

  const forceUpdate = useSelector(EmployerSelector.getForceUpdateEmployerJobSeekerListing);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = searchParams.get("searchTerm") || "";
  const showPeopleDetailView = searchParams.get("showPeopleDetailView");
  const onlyFavourites = searchParams.get("fav") === "1";
  const previousUserId = searchParams.get("uid");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const starredEmployerJobSeekerListing = employerJobSeekerListing?.filter((item) =>
    item?.starredBy.includes(loggedInUser?.uid),
  );

  const employerJobSeekerListingKeys = {
    searchTerm,
    wage: filterSettings?.wage || [],
    hrsPerWeek: filterSettings?.hrsPerWeek || [],
    age: filterSettings?.age || 0,
    experience: filterSettings?.experience || [],
    interested: onlyFavourites || filterSettings?.interested || false,
    lat: locationDetails.lat,
    lng: locationDetails.lng,
    page: 0,
    totalRecords: 0,
  };

  useEffect(() => {
    init();
  }, [forceUpdate]);

  const init = async () => {
    try {
      showLoader();
      setIsLoadingEmployerLocations(true);
      const res = await getEmployerActiveLocs();
      hideLoader();
      setIsLoadingEmployerLocations(false);

      setLocationDetails(res?.length > 0 ? res[0] : {});
      setEmployerLocations(res?.length > 0 ? res : []);
      setEmployerActiveLocations(res?.length > 0 ? res?.map((location) => location?.city) : []);
      setSelectedCity(res?.length > 0 ? res[0].city : "No posted job location");
      if (res?.length > 0) {
        dispatch(
          employerJobSeekerListingRequest({
            ...employerJobSeekerListingKeys,
            lat: res?.[0]?.lat,
            lng: res?.[0]?.lng,
            interested: onlyFavourites || filterSettings?.interested || false,
            page: peopleListPage,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(employerJobSeekerReset());
    };
  }, []);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      searchTerm,
    },
  });

  const toggleExploreView = () => {
    if (showPeopleDetailView) {
      navigate("/employer/people", { replace: true });
      if (!isEmpty(searchTerm)) {
        setSearchParams({
          searchTerm: searchTerm,
        });
      }
    }
  };

  const handleLocationChange = (selectedCity) => {
    showLoader();
    const selectedCitydetails = employerLocations.filter((loc) => loc.city === selectedCity)[0];
    setLocationDetails({
      city: selectedCitydetails.city,
      lat: selectedCitydetails.lat,
      lng: selectedCitydetails.lng,
    });
    setSelectedCity(selectedCity);
    dispatch(employerJobSeekerReset());
    setPeopleListPage(1);
    dispatch(
      employerJobSeekerListingRequest({
        ...employerJobSeekerListingKeys,
        interested: onlyFavourites || filterSettings?.interested || false,
        lat: selectedCitydetails.lat,
        lng: selectedCitydetails.lng,
        page: 1,
      }),
    );
    hideLoader();
  };

  const onClickSearch = (searchText) => () => {
    if (isEmpty(searchText)) {
      setSearchParams({
        ...(showPeopleDetailView && {
          showPeopleDetailView,
          uid: previousUserId,
        }),
      });
    } else {
      setSearchParams({
        searchTerm: searchText,
        ...(showPeopleDetailView && {
          showPeopleDetailView,
          uid: previousUserId,
        }),
      });
    }
    dispatch(employerJobSeekerReset());
    dispatch(
      employerJobSeekerListingRequest({
        ...employerJobSeekerListingKeys,
        searchTerm: searchText,
        interested: onlyFavourites || filterSettings?.interested || false,
        page: 1,
      }),
    );
    setPeopleListPage(1);
  };

  const handleLoadMorePeople = () => {
    if (peopleListPage + 1 <= peoplePageLimit) {
      dispatch(
        employerJobSeekerListingRequest({
          ...employerJobSeekerListingKeys,
          interested: onlyFavourites || filterSettings?.interested || false,
          page: peopleListPage + 1,
          totalRecords: totalJobSeekers,
        }),
      );
      setPeopleListPage(peopleListPage + 1);
    }
  };

  const onClickPeople = (people) => {
    logEvent(FirebaseEvents.EMP_CLICKS_JS_CARD);
    if (isEmpty(searchTerm)) {
      setSearchParams({
        showPeopleDetailView: true,
        uid: people.uid,
      });
    } else {
      setSearchParams({
        searchTerm,
        showPeopleDetailView: true,
        uid: people.uid,
      });
    }
  };

  const onClickPostNewJob = () => {
    navigate(`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`);
  };

  const handleSorting = (key, sortOrder) => {
    dispatch(employerJobSeekerReset());
    setPeopleListPage(1);

    if (key === "clear") {
      if (!showPeopleDetailView) {
        setSearchParams({
          searchTerm,
          sortKey: "",
          sortOrder: "",
        });
      }

      dispatch(
        employerJobSeekerListingRequest({
          ...employerJobSeekerListingKeys,
          totalRecords: totalJobSeekers,
        }),
      );
    } else {
      dispatch(
        employerJobSeekerListingRequest({
          ...employerJobSeekerListingKeys,
          sortKey: key,
          sortOrder: sortOrder,
          totalRecords: totalJobSeekers,
        }),
      );
    }
  };

  const handleFilter = (values) => {
    dispatch(employerJobSeekerReset());
    setPeopleListPage(1);
    dispatch(
      employerJobSeekerListingRequest({
        searchTerm,
        lat: locationDetails.lat,
        lng: locationDetails.lng,
        ...values,
      }),
    );
  };

  const handleStarred = (isStarred) => {
    dispatch(employerJobSeekerReset());
    setPeopleListPage(1);
    if (isStarred) {
      dispatch(
        employerJobSeekerListingRequest({
          ...employerJobSeekerListingKeys,
          interested: onlyFavourites || filterSettings?.interested || false,
        }),
      );
    } else {
      dispatch(
        employerJobSeekerListingRequest({
          ...employerJobSeekerListingKeys,
          interested: onlyFavourites || filterSettings?.interested || false,
          page: 1,
        }),
      );
    }
  };

  const mobileSearchValues = {
    term: values?.searchTerm,
  };

  const handleMarkStarCallback = () => {
    dispatch(employerJobSeekerReset());
    dispatch(
      employerJobSeekerListingRequest({
        ...employerJobSeekerListingKeys,
        interested: onlyFavourites || filterSettings?.interested || false,
        page: peopleListPage,
        totalRecords: totalJobSeekers,
      }),
    );
  };

  const fetchSeekerSuggestions = async (value) => {
    try {
      const res = await getEmployerSeekersSuggestions({
        searchTerm: value,
      });
      setSeekerSuggestions(res.data);
    } catch (e) {
      setSeekerSuggestions([]);
    }
  };

  return (
    <>
      {!showPeopleDetailView ? (
        <PeopleBoardContainer
          isLoadingEmployerLocations={isLoadingEmployerLocations}
          employerActiveLocations={employerActiveLocations}
          selectedLoc={selectedCity}
          employerJobSeekerListing={employerJobSeekerListing}
          starredEmployerJobSeekerListing={starredEmployerJobSeekerListing}
          filterSettings={filterSettings}
          onClickPeople={onClickPeople}
          onClickSearch={onClickSearch}
          onClickPostNewJob={onClickPostNewJob}
          handleLocationChange={handleLocationChange}
          handleStarred={handleStarred}
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          searchTerm={values.searchTerm}
          onChangeSearchTerm={handleChange}
          onlyFavourites={onlyFavourites}
          mobileSearchValues={mobileSearchValues}
          forceUpdate={forceUpdate}
          peopleListPage={peopleListPage}
          showLoadMore={peopleListPage < peoplePageLimit}
          handleLoadMorePeople={handleLoadMorePeople}
          setSeekerSearchValue={setFieldValue}
          seekerSuggestions={seekerSuggestions}
          fetchSeekerSuggestions={fetchSeekerSuggestions}
        />
      ) : (
        <PeopleDescriptionContainer
          toggleExploreView={toggleExploreView}
          loggedInUser={loggedInUser}
          employerActiveLocations={employerActiveLocations}
          selectedLoc={selectedCity}
          employerJobSeekerListing={employerJobSeekerListing}
          starredEmployerJobSeekerListing={starredEmployerJobSeekerListing}
          filterSettings={filterSettings}
          onClickSearch={onClickSearch}
          onClickPeople={onClickPeople}
          onClickPostNewJob={onClickPostNewJob}
          handleLocationChange={handleLocationChange}
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          searchTerm={values.searchTerm}
          onChangeSearchTerm={handleChange}
          showLoadMore={peopleListPage < peoplePageLimit}
          handleLoadMorePeople={handleLoadMorePeople}
          handleMarkStarCallback={handleMarkStarCallback}
          mobileSearchValues={mobileSearchValues}
          setSeekerSearchValue={setFieldValue}
          seekerSuggestions={seekerSuggestions}
          fetchSeekerSuggestions={fetchSeekerSuggestions}
        />
      )}
    </>
  );
};

export default PeopleContainer;
