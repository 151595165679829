import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import OtpSelector from "../selectors/OtpSelectors";
import useIsMobile from "../../../hooks/useIsMobile";
import { resetPhoneValidation, sendOtpRequest, verifyOtpRequest } from "../actions/OtpActions";

import { showNotification } from "../../../utils/commonUtils";
import { Icon, Text, OtpInput } from "../../../components/atoms";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { colors, spacing, styleUtils, textSizes } from "../../../styles";

import * as OtpConsts from "./OtpConstants";
import "./OtpStyleSheet.css";
import { FORGOT_PASSWORD } from "containers/Account/constants";
import { FormHelperErrorText, ResendButton, ResendContainer } from "./styledConstants";

/**
 * Verification form for phone otp validation
 */
const PhoneVerificationForm = ({
  editPhone,
  handleBack,
  handleOtpSubmit,
  isDetailsEdited,
  setIsDetailsEdited,
  setMarkAsCompleted,
  sourceDetails: { email, countryCode, phoneNumber },
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [phoneSeconds, setPhoneSeconds] = useState(60);

  const isOtpShareSuccess = useSelector(OtpSelector.getIsOtpShareSuccess);
  const IsOtpShareFailed = useSelector(OtpSelector.getIsOtpShareFailed);
  const phoneValidation = useSelector(OtpSelector.getIsPhoneValidation);
  const isPhoneOtpValid = useSelector(OtpSelector.getIsPhoneOtpValid);
  const isVerifying = useSelector(OtpSelector.getIsVerifying);
  const validate = (values) => {
    const errors = {};
    if (values.phoneOtp.trim().length === 0 || values.phoneOtp.trim().length < 6) {
      errors.phoneOtp = FORGOT_PASSWORD.ERROR_OTP_LENGTH;
    } else if (values.phoneOtp.trim().length === 6 && !isPhoneOtpValid && !isVerifying) {
      errors.phoneOtp = FORGOT_PASSWORD.ERROR_OTP_MISMATCH;
    }
    return errors;
  };

  const sendPhoneOtp = (callbackFn) => {
    dispatch(
      sendOtpRequest({
        email: email,
        countryCode: `+${countryCode}`,
        phoneNumber: phoneNumber.replace(`+${countryCode}`, ""),
        target: "phone",
        onCallback: callbackFn,
      }),
    );
  };

  const otpNotification = () => {
    const message = isOtpShareSuccess
      ? OtpConsts.RESEND_SUCCESS
      : IsOtpShareFailed
      ? OtpConsts.RESEND_FAILED
      : "";
    const status = isOtpShareSuccess ? "success" : IsOtpShareFailed ? "error" : "";

    if (isOtpShareSuccess || IsOtpShareFailed) {
      showNotification({
        alertProps: {
          severity: status,
          children: message,
        },
      });
    } else {
      //No code here
    }
  };

  useEffect(() => {
    if (!isDetailsEdited) {
      sendPhoneOtp(() => {});
    } else {
      // No code here
    }

    return () => {
      if (!isDetailsEdited) {
        dispatch(resetPhoneValidation());
      } else {
        // No code here
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    if (phoneSeconds !== 0) {
      timer = setInterval(() => {
        setPhoneSeconds(phoneSeconds - 1);
      }, 1000);
      if (phoneSeconds === 0) {
        setPhoneSeconds(60);
        clearInterval(timer);
      }
    }
    return () => clearInterval(timer);
  }, [phoneSeconds]);

  const phoneOtpFormik = useFormik({
    initialValues: {
      phoneOtp: "",
    },
    validate,
    onSubmit: () => {
      if (phoneValidation && isPhoneOtpValid) {
        handleOtpSubmit();
        logEvent(FirebaseEvents.EMP_PHONE_SIGNUP_PAGE4);
      }
    },
  });

  useEffect(() => {
    if (phoneValidation && isPhoneOtpValid) {
      setMarkAsCompleted(true);
    }
  }, [phoneValidation]);

  useEffect(() => {
    if (phoneOtpFormik.values.phoneOtp && phoneOtpFormik.values.phoneOtp.trim().length === 6) {
      dispatch(
        verifyOtpRequest({
          email: email,
          countryCode: `+${countryCode}`,
          phoneNumber: phoneNumber.replace(`+${countryCode}`, ""),
          phoneOtp: phoneOtpFormik.values.phoneOtp,
          target: "phone",
        }),
      );
    }
  }, [phoneOtpFormik.values.phoneOtp]);

  const showPhoneFormError =
    phoneOtpFormik.touched.phoneOtp && Boolean(phoneOtpFormik.errors.phoneOtp);
  const invalidPhoneOtp = phoneValidation && !isPhoneOtpValid;

  const handleResend = () => {
    setPhoneSeconds(60);
    sendPhoneOtp(otpNotification);
  };
  const showZero = phoneSeconds < 10 ? "0" : "";
  return (
    <PhoneVerificationBox>
      <Text variant="h5" weight="semibold" size="l" color={colors.titleBlack}>
        {OtpConsts.OTP_PHONE_VERIFY_HEADER}
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
        sx={{
          marginBottom: styleUtils.pxToRem("32px"),
        }}
      >
        {`${OtpConsts.OTP_PHONE_VERIFY_SUBTEXT} ${phoneNumber}`}
      </Text>
      <Text color={colors.labelBlack} sx={{ marginTop: spacing.l }}>
        {isMobile ? OtpConsts.OTP_PHONE__RES_LABEL : OtpConsts.OTP_PHONE_LABEL} *
      </Text>
      <FormControl required>
        <OtpInput
          value={phoneOtpFormik.values.phoneOtp}
          onChange={(text) => phoneOtpFormik.setFieldValue("phoneOtp", text)}
          isVerifying={isVerifying}
          isCodeVerified={isPhoneOtpValid}
          hasError={showPhoneFormError || invalidPhoneOtp}
        />
        <ResendContainer sx={{ width: isMobile ? "90%" : "65%" }}>
          {(showPhoneFormError || invalidPhoneOtp) && (
            <>
              <Icon name="error" size={11} />
              <FormHelperErrorText>{phoneOtpFormik.values.phoneOtp}</FormHelperErrorText>
            </>
          )}
          <ResendButton
            variant="text"
            disabled={phoneSeconds !== 0}
            onClick={() => {
              handleResend();
            }}
            className="otp-resend-link"
            size="xs"
            sx={{
              color: phoneSeconds !== 0 ? colors.timerGray : colors.link,
              border: "none",
              boxShadow: "none",
              position: "absolute",
              left: phoneSeconds !== 0 ? "160px" : "210px",
            }}
          >
            {phoneSeconds !== 0
              ? `Resend code in ${"00"}:${showZero}${phoneSeconds}`
              : OtpConsts.RESEND_CODE_TEXT}
          </ResendButton>
        </ResendContainer>
      </FormControl>

      <Button
        variant="text"
        onClick={() => {
          editPhone(true);
          setIsDetailsEdited(true);
        }}
        sx={{
          mt: spacing.s,
          border: "none",
          boxShadow: "none",
          fontSize: textSizes.m,
          textDecoration: "underline",
          width: "fit-content",
          p: 0,
          "&:hover": {
            backgroundColor: colors.white,
            textDecoration: "underline",
          },
        }}
      >
        {OtpConsts.OTP_PHONE_EDIT_LINK}
      </Button>
      <FormsBtnContainer
        sx={{
          marginTop: styleUtils.pxToRem(isMobile ? "330px" : "190px"),
        }}
      >
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setMarkAsCompleted(false);
            handleBack(false);
          }}
        />
        <Button
          variant="contained"
          disabled={!isPhoneOtpValid}
          onClick={phoneOtpFormik.handleSubmit}
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
        >
          Submit
        </Button>
      </FormsBtnContainer>
    </PhoneVerificationBox>
  );
};

export default PhoneVerificationForm;

PhoneVerificationForm.propTypes = {
  editPhone: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleOtpSubmit: PropTypes.func.isRequired,
  isDetailsEdited: PropTypes.bool.isRequired,
  setIsDetailsEdited: PropTypes.func.isRequired,
  setMarkAsCompleted: PropTypes.func.isRequired,
  sourceDetails: PropTypes.any,
};

const PhoneVerificationBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;
