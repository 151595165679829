export const EMPLOYD = "EMPLOYD";
export const SET_YOUR_PREFERENCE = " Set your Preference";
export const SET_PROFILE =
  "Set your profile preference to get tailored job recommendations";
export const ADD_PROFILE_PHOTO = "Add Profile Photo";
export const AGE = "Age";
export const WAGE_EXPECTATION = "Wage expectations";
export const HOURS = "Hours/week";
export const NEXT = "Next";
export const WORK_EXPERIENCE = "Work experience";
export const DAYS_WEEK = "Days/week";
export const TIME_SLOT = "Time Slot";
export const NOTE_FOR_EMPLOYEE = "About Me";
export const SUBMIT = "Submit";
export const SET_PREFERENCES = "Set Preferences";
export const NOTE_FOR_EMPLOYER = "About Me";
export const EDIT_PERSONAL_INFORMATION = "Edit Personal Information";
export const PREFERENCES = "PREFERENCES";
export const EDIT = "Edit";
export const PERSONAL_INFORMATION = "PERSONAL INFORMATION";
export const CANCEL = "Cancel";
export const SAVE = "Save";
export const NAME = "Name";
export const PHONE_NUMBER = "Phone number";
export const EMAIL_ID = "Email ID";
export const ENTER_YOUR_AGE = "Enter your age";
export const ALTERNATIVE_PHONER_NUMBER = "Alternative Phone number";
export const WORK_STATUS = "Work status";
export const REFERRALS = "REFERRALS/INVITE";
export const INVITE_FRIENDS = "Invite your friends to EmployD";
export const INVITE_FREINDS_TO_EARN =
  "Invite a friend and earn $10 when your friend uses employD";
export const TOTAL_EARNED = "Total Earned";
export const SHARE_REFERRAL_LINK = "Share referral link";
export const EMPLOYER = "employer";
export const USER_PROFILE = "User Profile";
export const DELTE_ACCOUNT_HEADER = "Delete Acount";
export const DELETE_MODAL_SUBTEXT_ONE =
  "You’re about to delete EmployD Account";
export const DELETE_MODAL_SUBTEXT_TWO = "Please select a reason for deleting";
export const DELETE_MODAL_REASON_PLACEHOLDER = "Enter reason here…";
export const INACTIVE_MODAL_BTN_CANCEL = "Cancel";
export const INACTIVE_MODAL_BTN_APPLY = "Apply";

export const DELETE_MODAL_SUBTEXT = "Are you sure you want to delete this job?";
export const DELETE_MODAL_BTN_CANCEL = "Cancel";
export const DELETE_MODAL_BTN_APPLY = "Delete";

export const DELETE_USER_MODAL_OPTIONS = [
  {
    option: "1",
    value: "Too Busy/Distracting",
  },
  {
    option: "2",
    value: "Created another account",
  },
  {
    option: "3",
    value: "Trouble getting started",
  },
  {
    option: "4",
    value: "Privacy Concerns",
  },
  {
    option: "5",
    value: "Can’t find Jobs",
  },
  {
    option: "6",
    value: "Other",
  },
];

export const wageExpectations = [
  {
    id: 1,
    label: "$5-10",
    value: "5-10",
    isSelected: false,
  },
  {
    id: 2,
    label: "$10-15",
    value: "10-15",
    isSelected: false,
  },
  {
    id: 3,
    label: "$15-20",
    value: "15-20",
    isSelected: false,
  },
  {
    id: 4,
    label: "$20-30",
    value: "20-30",
    isSelected: false,
  },
  {
    id: 5,
    label: "$30-40",
    value: "30-40",
    isSelected: false,
  },
];

export const workingHrs = [
  {
    id: 1,
    label: "00-02hrs",
    value: "0-2",
    isSelected: false,
  },
  {
    id: 2,
    label: "02-05hrs",
    value: "2-5",
    isSelected: false,
  },
  {
    id: 3,
    label: "05-10hrs",
    value: "5-10",
    isSelected: false,
  },
  {
    id: 4,
    label: "10-20hrs",
    value: "10-20",
    isSelected: false,
  },
  {
    id: 5,
    label: "20-30hrs",
    value: "20-30",
    isSelected: false,
  },
  {
    id: 6,
    label: "30-40hrs",
    value: "30-40",
    isSelected: false,
  },
];

export const workExperience = [
  {
    id: 1,
    label: "Beginner",
    value: "Beginner",
    isSelected: false,
  },
  {
    id: 2,
    label: "01-02 Yrs",
    value: "1-2",
    isSelected: false,
  },
  {
    id: 3,
    label: "02-05 Yrs",
    value: "2-5",
    isSelected: false,
  },
  {
    id: 4,
    label: "Above 05 Yrs",
    value: ">5",
    isSelected: false,
  },
];

export const daysAvailable = [
  {
    id: 1,
    label: "Mon",
    value: "Mon",
    isSelected: false,
  },
  {
    id: 2,
    label: "Tue",
    value: "Tue",
    isSelected: false,
  },
  {
    id: 3,
    label: "Wed",
    value: "Wed",
    isSelected: false,
  },
  {
    id: 4,
    label: "Thu",
    value: "Thu",
    isSelected: false,
  },
  {
    id: 5,
    label: "Fri",
    value: "Fri",
    isSelected: false,
  },
  {
    id: 6,
    label: "Sat",
    value: "Sat",
    isSelected: false,
  },
  {
    id: 7,
    label: "Sun",
    value: "Sun",
    isSelected: false,
  },
];

export const timeSlots = [
  {
    id: 1,
    label: "Morning",
    value: "Morning",
    isSelected: false,
  },
  {
    id: 2,
    label: "Afternoon",
    value: "Afternoon",
    isSelected: false,
  },
  {
    id: 3,
    label: "Evening",
    value: "Evening",
    isSelected: false,
  },
  {
    id: 4,
    label: "Night",
    value: "Night",
    isSelected: false,
  },
];

export const EMPLOYEE_SIZE_OPTIONS = [
  "1-10",
  "11-50",
  "51-100",
  "101-250",
  "251-500",
  "501-1000",
  "1000+",
];

export const EMPLOYEE_DESIGNATION_OPTIONS = [
  "Business Owner",
  "Manager",
  "HR/Talent Professional",
  "Director/VP",
  "Admin",
  "C-Level",
  "Other",
];

export const EMPLOYEE_MULTI_LOCATION_OPTIONS = ["Yes", "No"];
