import { Modal, Button, Card, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

import SchedulerDateTimePicker from "./SchedulerDateTimePicker";

import {
  colors,
  spacing,
  styleUtils,
  textSizes,
} from "../../../../../../styles";
import { SelectDropDown, Text } from "../../../../../../components/atoms";

import { CREATE_AVAILABILITY_CONSTS } from "../../constants";

const CreateAvailabilityView = ({
  handleCreateAvailModal,
  showCreateAvailModal,
  createAvailabilityFormik,
}) => {
  const handleDurationChange = (selectedDuration) => {
    createAvailabilityFormik.setFieldValue("duration", selectedDuration);
  };

  const handleMeetingModeChange = (selectedMode) => {
    createAvailabilityFormik.setFieldValue("meetingMode", selectedMode);
  };

  const handleDateTimeChange = (selectedDate, selectedTime) => {
    createAvailabilityFormik.setFieldValue(
      "date",
      selectedDate.replaceAll("/", "-")
    );
    createAvailabilityFormik.setFieldValue("time", selectedTime);
  };

  return (
    <Modal open={showCreateAvailModal} style={{ backdropFilter: "blur(2px)" }}>
      <Container
        sx={{
          mt: spacing.l,
          backgroundColor: colors.white,
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "none",
            width: "auto",
            borderRadius: "1.5rem",
          }}
        >
          <Text
            weight="bold"
            size="xl"
            color={colors.blackText}
            sx={{ marginTop: spacing.m, marginLeft: spacing.s }}
          >
            {CREATE_AVAILABILITY_CONSTS.CREATE_MEETING_HEADER}
          </Text>
          <div
            style={{
              marginTop: spacing.xl,
              position: "relative",
            }}
          >
            <ContentWrapper sx={{ marginLeft: spacing.m }}>
              <SchedulerDateTimePicker
                name="dateTime"
                handleDateTimeChange={handleDateTimeChange}
              />
            </ContentWrapper>
            <ContentWrapper>
              <SelectDropDown
                fieldValue={createAvailabilityFormik.values.duration}
                fieldName="duration"
                variant="standard"
                handleChange={handleDurationChange}
                dropDownOptions={CREATE_AVAILABILITY_CONSTS.TIME_SLOT_DURATION}
                placeholder={CREATE_AVAILABILITY_CONSTS.TIME_SLOT_PLACEHOLDER}
                widthValue={265}
                sx={{
                  border: `1px solid ${colors.disableGrey}`,
                  borderRadius: "4px",
                  height: spacing.xxxl,
                  padding: `0rem ${spacing.m} 0rem ${spacing.m}`,
                  marginLeft: spacing.s,
                  "& .MuiSelect-select:focus": {
                    border: `1px solid ${colors.disableGrey}`,
                  },
                }}
              />
            </ContentWrapper>
            <ContentWrapper>
              <SelectDropDown
                fieldValue={createAvailabilityFormik.values.meetingMode}
                fieldName="meetingMode"
                variant="standard"
                handleChange={handleMeetingModeChange}
                dropDownOptions={CREATE_AVAILABILITY_CONSTS.MEETING_MODES}
                placeholder={
                  CREATE_AVAILABILITY_CONSTS.MEETING_MODES_PLACEHOLDER
                }
                widthValue={265}
                sx={{
                  border: `1px solid ${colors.disableGrey}`,
                  borderRadius: "4px",
                  height: spacing.xxxl,
                  padding: `0rem ${spacing.m} 0rem ${spacing.m}`,
                  marginLeft: spacing.s,
                }}
              />
            </ContentWrapper>
          </div>

          <ContentWrapper>
            <ActionButton
              variant="outlined"
              onClick={() => handleCreateAvailModal(false)}
            >
              Cancel
            </ActionButton>
            <ActionButton
              variant="contained"
              onClick={createAvailabilityFormik.handleSubmit}
            >
              Create
            </ActionButton>
          </ContentWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

export default CreateAvailabilityView;

const ContentWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.l};
`;
const ActionButton = styled(Button)`
  font-size: ${textSizes.m};
  margin-right: ${spacing.s};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("180px")};
`;
