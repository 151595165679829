import { useGoogleLogin } from "@react-oauth/google";
import noop from "lodash/noop";

const GoogleLoginButton = ({
  onProfileSuccess = noop,
  onFail = noop,
  render,
}) => {
  const login = useGoogleLogin({
    flow: "implicit",
    onSuccess: onProfileSuccess,
    onError: onFail,
  });

  const handleButtonClick = () => {
    login();
  };

  return <>{render({ onClick: handleButtonClick })}</>;
};

export default GoogleLoginButton;
