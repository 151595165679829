import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { colors } from "styles";

const Toggler = (props) => {
  const { checked, onChange, width = 40, height = 20 } = props;

  const Toggle = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={checked}
      onChange={onChange}
      {...props}
    />
  ))(({ theme }) => ({
    width: width,
    height: height,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: colors.green,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: colors.green,
        border: `6px solid ${colors.white}`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: colors.disableGrey,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 15,
      height: 15,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: colors.timerGray,
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return <Toggle />;
};

export default Toggler;
