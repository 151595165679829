import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { styleUtils } from "../../../styles";

const BlogPostFooter = ({ isMobile }) => {
  return (
    <StyledContentFooter
      backgroundimage={
        isMobile ? images.blogPostFooterMobile : images.blogPostFooter
      }
      ismobile={+isMobile}
    >
      <div>
        <Text size={isMobile ? "xxl" : "xxxl"} weight="bold" color="#414141">
          Local
          <Text
            size={isMobile ? "xxl" : "xxxl"}
            weight="extrabold"
            color="#214999"
            component="span"
          >
            {" part-time "}
          </Text>
          job search made simple with
          <Text
            size={isMobile ? "xxl" : "xxxl"}
            weight="bold"
            color="#214999"
            component="span"
          >
            {" Employ"}
          </Text>
          <Text
            size={isMobile ? "xxl" : "xxxl"}
            weight="bold"
            color="#00ABE4"
            component="span"
          >
            {"D"}
          </Text>
        </Text>
      </div>
      <div
        style={{
          marginTop: isMobile ? "24px" : "20px",
          ...(!isMobile && { marginRight: "130px" }),
        }}
      >
        <Text size={isMobile ? "18px" : "22px"} weight="medium" color="#414141">
          Download EmployD on the{" "}
          <AppLinkText type="Android" isMobile={isMobile} />
          <Text
            size={isMobile ? "18px" : "22px"}
            weight="medium"
            color="#414141"
            component="span"
            {...(isMobile && { style: { display: "block" } })}
          >
            {" "}
            or <AppLinkText type="iOS" isMobile={isMobile} />
            <Text
              size={isMobile ? "18px" : "22px"}
              weight="medium"
              color="#414141"
              component="span"
            >
              {" "}
              to easily find your dream job.
            </Text>
          </Text>
        </Text>
      </div>
      <div style={{ marginTop: isMobile ? "24px" : "20px" }}>
        <Text size={isMobile ? "18px" : "22px"} weight="medium" color="#414141">
          Take control of your job search with EmployD
        </Text>
      </div>
    </StyledContentFooter>
  );
};

export default BlogPostFooter;

const AppLinkText = ({ type, isMobile }) => {
  const TEXT = {
    Android: "Android Play Store",
    iOS: "App Store",
  };
  const URL = {
    Android:
      "https://play.google.com/store/apps/details?id=com.impactanalytics.employdnew",
    iOS: "https://apps.apple.com/tt/app/employd/id1583769658",
  };
  return (
    <Text
      size={isMobile ? "18px" : "22px"}
      weight="bold"
      color="#414141"
      component="span"
    >
      <Link rel="noopener noreferrer" href={URL[type]} target="_blank">
        {TEXT[type]}
      </Link>
    </Text>
  );
};

const StyledContentFooter = styled("div")(({ backgroundimage, ismobile }) => ({
  background: `0% 0% no-repeat padding-box, url(${backgroundimage})`,
  height: ismobile ? styleUtils.pxToRem("390px") : styleUtils.pxToRem("257px"),
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  marginTop: ismobile ? styleUtils.pxToRem("40px") : styleUtils.pxToRem("88px"),
  paddingTop: ismobile
    ? styleUtils.pxToRem("32px")
    : styleUtils.pxToRem("40px"),
  paddingLeft: ismobile
    ? styleUtils.pxToRem("32px")
    : styleUtils.pxToRem("58px"),
  paddingRight: ismobile
    ? styleUtils.pxToRem("38px")
    : styleUtils.pxToRem("58px"),
}));
