const getIsOtpShareSuccess = (state) => {
  const { sendOtpSuccess } = state.otpState;
  return sendOtpSuccess;
};

const getIsOtpShareFailed = (state) => {
  const { sendOtpFailed } = state.otpState;
  return sendOtpFailed;
};

const getIsEmailValidation = (state) => {
  const { emailValidation } = state.otpState;
  return emailValidation;
};

const getIsEmailOtpValid = (state) => {
  const { isEmailOtpValid } = state.otpState;
  return isEmailOtpValid;
};

const getIsPhoneValidation = (state) => {
  const { phoneValidation } = state.otpState;
  return phoneValidation;
};

const getIsPhoneOtpValid = (state) => {
  const { isPhoneOtpValid } = state.otpState;
  return isPhoneOtpValid;
};

const getIsVerifying = (state) => {
  const { verifyOtpLoading } = state.otpState;
  return verifyOtpLoading;
}

const OtpSelector = {
  getIsOtpShareSuccess,
  getIsOtpShareFailed,
  getIsEmailValidation,
  getIsEmailOtpValid,
  getIsPhoneValidation,
  getIsPhoneOtpValid,
  getIsVerifying
};

export default OtpSelector;
