import React from "react";
import useIsMobile from "../../../../../../../hooks/useIsMobile";
import { Modal, Button, Card, Container } from "@mui/material";
import { Carousel, Text } from "../../../../../../../components/atoms";
import { colors, spacing, textSizes } from "../../../../../../../styles";
import { images } from "../../../../../../../assets/images";
import { EmployerConstants } from "../../Constants";
import { styled } from "@mui/material/styles";

const EmployerSignUpModal = (props) => {
  const { showSignUpModal, handleSignUpModal } = props;
  const isMobile = useIsMobile();
  const carouselImages = ["postjobimg_1", "postjobimg_2", "postjobimg_3"];

  return (
    <Modal
      open={showSignUpModal}
      onClose={() => handleSignUpModal("close")}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Container
        sx={{
          mt: spacing.xxl,
          backgroundColor: colors.white,
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "none",
            width: isMobile ? "90%" : "30%",
            borderRadius: "1.5rem",
          }}
        >
          <Carousel>
            {carouselImages?.map((imageName, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      marginTop: "2rem",
                      position: "relative",
                    }}
                  >
                    <Text
                      weight="regular"
                      size="m"
                      sx={{
                        width: isMobile ? "3rem" : "4rem",
                        float: "right",
                        cursor: "pointer",
                        marginBottom: "1rem",
                        color: "#212121",
                      }}
                      onClick={() => handleSignUpModal("close")}
                    >
                      Skip
                    </Text>

                    {!isMobile && <ImgContainer src={images[imageName]} />}
                    {isMobile && <MobileImgContainer src={images[imageName]} />}
                    <ContentWrapper>
                      <Text weight="bold" size="xl" color={"#000000DE"}>
                        {
                          EmployerConstants[
                            `POSTFIRSTJOB_MODAL_TEXT_${index + 1}`
                          ]
                        }
                      </Text>
                      <Text weight="regular" size="l" color={"#696767DE"}>
                        {
                          EmployerConstants[
                            `POSTFIRSTJOB_MODAL_SUBTEXT_${index + 1}`
                          ]
                        }
                      </Text>
                    </ContentWrapper>
                  </div>
                </React.Fragment>
              );
            })}
          </Carousel>
          <ButtonWrapper sx={{ marginTop: isMobile ? "2rem" : "3rem" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: textSizes.xl,
                borderRadius: spacing.l,
                width: "70%",
              }}
              onClick={() => handleSignUpModal("post")}
            >
              Post your first job
            </Button>
          </ButtonWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

export default EmployerSignUpModal;

const ContentWrapper = styled("div")`
  padding: 2%;
  margin-top: 1%;
  text-align: center;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const ImgContainer = styled("img")`
  display: block;
  margin-left: auto;
  width: 100%;
`;

const MobileImgContainer = styled("img")`
  display: block;
  width: 100%;
`;
