import { Modal as MUIModal, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Modal = ({ children, ...props }) => {
  return (
    <MUIModal {...props}>
      <MUIBox>{children}</MUIBox>
    </MUIModal>
  );
};

export default Modal;

const MUIBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
