import PropTypes from "prop-types";
import { Card, Button, FormHelperText, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";

import { CardModal, Icon, Text, TextInput } from "../../atoms";
import { colors, spacing, styleUtils } from "../../../styles";
import { EMPLOYER } from "../../../appConstants";
import { updateLastMessage } from "../../../configs/network/apis";
import { hideLoader, showLoader } from "../../../utils/commonUtils";
import AIGenerator from "../AIGenerator";
import { useCallback, useEffect, useRef, useState } from "react";
import { AI_GENERATION_TYPES, DONE_CHUNK, fetchAIData } from "utils/aiUtils";
import useIsMobile from "hooks/useIsMobile";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import DividerWithText from "components/atoms/DividerWithText";

const AppliedModal = ({ isOpen, onClose, jobDetails }) => {
  const navigate = useNavigate();
  const abortControllerRef = useRef();
  const isAIContentEmptyRef = useRef(true);
  const [generatedText, setGeneratedText] = useState("");
  const [isGenerationComplete, setIsGenerationComplete] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (generatedText) {
      setFieldValue("message", generatedText);
    }
    if (generatedText?.length === 0) {
      isAIContentEmptyRef.current = true;
    } else {
      isAIContentEmptyRef.current = false;
    }
  }, [generatedText]);

  const onSend = async (message) => {
    await updateLastMessage({
      receiverId: jobDetails?.recruiterId,
      message,
    });
  };

  const onSubmit = async (values) => {
    showLoader();
    await onSend(values.message);
    hideLoader();
    logEvent(FirebaseEvents.JS_CLICK_SEND_MESSAGE_APPYJOB);
    navigate({
      pathname: "/chat",
      search: `?uid=${jobDetails?.recruiterId}&uType=${EMPLOYER}`,
    });
  };

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit,
  });

  const enteredMessage = values.message.trim();

  const onPressGenerate = useCallback(async () => {
    setGeneratedText("");
    setIsGenerationComplete(false);
    abortControllerRef.current = new AbortController();
    logEvent(
      isAIContentEmptyRef?.current
        ? FirebaseEvents.JS_CLICK_GENERATE_MESSAGE_APPLYJOB
        : FirebaseEvents.JS_CLICK_REGENERATE_MESSAGE_APPLYJOB,
    );
    await fetchAIData({
      aiGenerationType: AI_GENERATION_TYPES.APPLY_NOTE,
      aiGeneratorInput: {
        jobId: jobDetails?.jobId,
      },
      dataUpdaterWithChunk: updateJobDescWithChunk,
      abortController: abortControllerRef.current,
    });
  }, []);

  const onPressStopGenerate = useCallback(async () => {
    abortControllerRef.current.abort();
    setIsGenerationComplete(true);
  }, []);

  const updateJobDescWithChunk = ({ chunk }) => {
    if (chunk === DONE_CHUNK) {
      setIsGenerationComplete(true);
      return;
    }
    setGeneratedText((prevText) => `${prevText}${chunk}`);
  };

  return (
    <CardModal open={isOpen} onClose={onClose}>
      <Card
        sx={{
          minWidth: {
            xs: "86vw",
            sm: "86vw",
            md: "30vw",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: {
              xs: styleUtils.pxToRem("10px"),
              sm: styleUtils.pxToRem("12px"),
              md: styleUtils.pxToRem("26px"),
            },
            alignItems: "center",
          }}
        >
          <Icon
            name="suitcase"
            width={isMobile ? 39.5 : 48.5}
            height={isMobile ? 30.5 : 37.5}
            style={{ margin: "20px" }}
          />
          {isMobile ? (
            <div style={{ marginBottom: spacing.l, textAlign: "center" }}>
              <Text weight="medium">You have successfully applied</Text>
              <Text weight="medium">to this job. Send the hiring manager</Text>
              <Text weight="medium">a note to stand out from others.</Text>
            </div>
          ) : (
            <div style={{ marginBottom: spacing.l, textAlign: "center" }}>
              <Text weight="medium">You have successfully applied to this job.</Text>
              <Text weight="medium">Send the hiring manager</Text>
              <Text weight="medium">a note to stand out from others.</Text>
            </div>
          )}
          <StyledInputContainer>
            <AIGenerator
              onPressGenerate={onPressGenerate}
              onPressStopGenerate={onPressStopGenerate}
              isGenerationComplete={isGenerationComplete}
              isAIContentEmpty={isAIContentEmptyRef.current}
              toolbarSx={{ backgroundColor: colors.white }}
            />
            <DividerWithText />
            <TextInput
              multiline
              name="message"
              value={values.message}
              onChange={handleChange}
              error={errors.message}
              style={{ width: "100%", border: 0, borderRadius: 7 }}
              inputProps={{
                style: { height: 180, overflow: "auto" },
              }}
              placeholder="Type your message here"
              sx={{
                border: "none",
              }}
            />
          </StyledInputContainer>
          {touched.message && Boolean(errors.message) && (
            <FormHelperText sx={{ color: colors.red, marginBottom: spacing.s }}>
              Message is required
            </FormHelperText>
          )}
          <Row>
            <Button
              variant="contained"
              sx={{
                px: spacing.xxl,
                fontWeight: "medium",
                height: 43,
                lineHeight: "unset",
                paddingX: 0,
              }}
              onClick={handleSubmit}
              disabled={!isGenerationComplete || !enteredMessage}
            >
              Send Message
            </Button>
            <Button
              onClick={onClose}
              sx={{
                px: spacing.xxl,
                fontWeight: "medium",
                border: "1px solid #2B4894",
                height: 43,
                paddingX: 0,
              }}
            >
              Cancel
            </Button>
          </Row>
        </Box>
      </Card>
    </CardModal>
  );
};

AppliedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  jobDetails: PropTypes.any,
};

export default AppliedModal;

const Row = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  padding: ${spacing.s};
  margin-top: ${spacing.l};
  width: 100%;
  gap: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const StyledInputContainer = styled("div")`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000015;
  border: 0.25px solid #dbdbdb;
  border-radius: 7px;
  opacity: 1;
  width: 100%;
`;
