import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";

import isEmpty from "lodash/isEmpty";
import { AI_GENERATION_TYPES, DONE_CHUNK, fetchAIData } from "utils/aiUtils";

import { Icon, TextInput } from "../../components/atoms";
import AIGenerator from "components/molecules/AIGenerator";

import { ChatConstants } from "./Constants";
import { colors, spacing } from "../../styles";

const ChatInput = ({ onSend, showUploadModal, isBlocked, isBlockedBy, aiGeneratorInput }) => {
  const abortControllerRef = useRef();
  const isAIContentEmptyRef = useRef(true);
  const [generatedText, setGeneratedText] = useState("");
  const [isGenerationComplete, setIsGenerationComplete] = useState(true);
  const textInputRef = useRef(true);

  useEffect(() => {
    // Regular expression to match the sender's name and colon, e.g., "SenderName:"
    const senderNameRegex = /^[^:]+:/;

    if (generatedText) {
      setFieldValue("message", generatedText.replace(senderNameRegex, ""));
      textInputRef.current.focus();
      textInputRef.current.scrollTop = textInputRef.current.scrollHeight;
    }
    if (generatedText?.length === 0) {
      isAIContentEmptyRef.current = true;
    } else {
      isAIContentEmptyRef.current = false;
    }
  }, [generatedText]);

  const onSubmit = ({ message }) => {
    if (isEmpty(values.message?.trim())) {
      return;
    }
    if (isBlocked || isBlockedBy) {
      return;
    }
    onSend(message?.trim());
    resetForm();
  };

  const validate = (formData) => {
    const errors = {};
    if (!formData.message || isEmpty(formData.message?.trim())) {
      return (errors["message"] = "Enter a message");
    }
    return errors;
  };

  const { handleChange, handleSubmit, values, resetForm, setFieldValue } = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit,
    validate,
  });

  const onClickAttachment = () => {
    if (isBlocked) {
      return;
    }
    showUploadModal();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };
  const onPressGenerate = useCallback(async () => {
    setGeneratedText("");
    setIsGenerationComplete(false);

    abortControllerRef.current = new AbortController();
    await fetchAIData({
      aiGenerationType: AI_GENERATION_TYPES.CHAT,
      aiGeneratorInput: aiGeneratorInput,
      dataUpdaterWithChunk: updateJobDescWithChunk,
      abortController: abortControllerRef.current,
    });
  }, [aiGeneratorInput]);

  const onPressStopGenerate = useCallback(async () => {
    abortControllerRef.current.abort();
    setIsGenerationComplete(true);
  }, []);

  const updateJobDescWithChunk = ({ chunk }) => {
    if (chunk === DONE_CHUNK) {
      setIsGenerationComplete(true);
      return;
    }
    setGeneratedText((prevText) => `${prevText}${chunk}`);
  };

  return (
    <div>
      <AIGenerator
        onPressGenerate={onPressGenerate}
        onPressStopGenerate={onPressStopGenerate}
        isGenerationComplete={isGenerationComplete}
        isAIContentEmpty={isAIContentEmptyRef.current}
        textForGenerate="Chatting using AI"
        buttonTextForGenerate="Lets Start"
        textForLoading="Generating response for you…"
        buttonTextForLoading="Stop generating"
        textForGenerationDone="Chatting using AI"
        buttonTextForGenerationDone="Generate"
        toolbarSx={{ marginLeft: spacing.s, marginRight: spacing.s }}
        buttonSx={{ padding: 0 }}
      />
      <Row>
        <TextInput
          name="message"
          onChange={handleChange}
          onKeyDown={onKeyDown}
          value={values.message}
          placeholder={ChatConstants.CHAT_INPUT_PLACEHOLDER}
          multiline
          maxRows={4}
          inputProps={{ readOnly: isBlocked }}
          inputRef={textInputRef}
          startAdornment={
            <StartAdornmentWrapper>
              <Icon
                size={24}
                name="add"
                {...(!isBlockedBy &&
                  isGenerationComplete && {
                    onClick: onClickAttachment,
                  })}
                color={isBlockedBy || isBlocked ? colors.disableGrey : colors.primary}
                style={{ cursor: "pointer" }}
              />
            </StartAdornmentWrapper>
          }
          sx={{
            borderRadius: "10px",
            borderColor: colors.chatBorderColor,
            paddingLeft: "20px",
            display: "flex",
            gap: "10px",
          }}
        />
        <SendMessageIconWrapper message={values?.message}>
          <Icon
            name="send"
            size={20}
            color={
              isBlockedBy || isBlocked || !isGenerationComplete
                ? colors.disableGrey
                : colors.primary
            }
            style={{ cursor: "pointer" }}
            {...(!isBlockedBy &&
              isGenerationComplete && {
                onClick: handleSubmit,
              })}
            disabled={!isGenerationComplete || !values?.message}
          />
        </SendMessageIconWrapper>
      </Row>
    </div>
  );
};

ChatInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  showUploadModal: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  isBlockedBy: PropTypes.bool.isRequired,
  aiGeneratorInput: PropTypes.string.isRequired,
};

export default ChatInput;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: ${spacing.s};
  margin-right: ${spacing.s};
  padding-bottom: ${spacing.xs};
  gap: 20px;
`;

const StartAdornmentWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
`;

const SendMessageIconWrapper = styled("div")`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ message }) => (message ? colors.primary : colors.disableGrey)};
`;
