import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { EmptyView, JobCard } from "../../../../../components/molecules";
import { spacing, colors } from "../../../../../styles";

import { MyJobsConstants } from "../Constants";

const JobsGridView = ({ jobsList, onClickJob, isFavourite }) => {
  const navigate = useNavigate();
  const renderJobs = (item, index) => {
    return (
      <Grid key={index} item xs={12} sm={12} md={4}>
        <JobCard
          item={item}
          isFavourite={isFavourite}
          jobActionStatus={
            isFavourite ? MyJobsConstants.APPPLIED_JOB_TAG : MyJobsConstants.BOOKMARKED_JOB_TAG
          }
          onClickJob={onClickJob}
          cardStyle={{
            marginBottom: spacing.xl,
          }}
        />
      </Grid>
    );
  };

  const onClick = () => {
    navigate("/jobSeeker/explorejobs");
  };

  return (
    <Container
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        marginBottom: spacing.l,
      }}
    >
      <Grid container columnSpacing={spacing.l} mt={spacing.xxl}>
        {jobsList?.length > 0 ? (
          jobsList?.map(renderJobs)
        ) : (
          <Grid item md={12} sx={{ py: spacing.l, height: "55vh" }}>
            {jobsList?.length === 0 && (
              <EmptyView
                image="noApplicationFound"
                imageHeight={169}
                title={
                  isFavourite
                    ? MyJobsConstants.NO_FAVOURITE_JOBS_TITLE
                    : MyJobsConstants.NO_BOOKMARKED_JOBS_TITLE
                }
                description={MyJobsConstants.NO_FAVOURITE_JOBS_SUB_TITLE}
                fromMobile={false}
                noApplication={true}
                onClick={onClick}
                descriptiontextColor={colors.textPrimary}
                descriptionTextStyle={true}
                buttonText="Find Jobs"
              />
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default JobsGridView;
