import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { Box, Card, Container, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, TextInput, Text } from "../../../components/atoms";
import { spacing } from "../../../styles";

import ChatBox from "../ChatBox";
import ChatList from "../ChatList";
import EmptyChatView from "../EmptyChatView";

import LoginSelector from "../../Login/selectors/LoginSelector";

import { EMPLOYER } from "../../../appConstants";
import { ChatConstants } from "../Constants";

const ChatView = ({
  selectedChatUser,
  onChangeChatUser,
  chatUsers,
  filteredChatUsers,
  searchTerm,
  onChangeSearchTerm,
  onSearch,
  onSend,
  blockedUsers,
  setBlockedUsers,
  getChatUsers,
}) => {
  const initialRender = useRef(true);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const isEmployer =
    (loggedInUser && loggedInUser?.userType === EMPLOYER) || false;

  //#region - Debounce Search
  // TODO: Refactor

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);


  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        margin: '0 !important',
        padding: '0 !important',
      }}
    >
      {chatUsers?.length > 0 && !selectedChatUser ? (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            pl: spacing.s,
            pt: spacing.s,
          }}
        >
          <Row>
            <TextInput
              name="searchTerm"
              value={searchTerm}
              placeholder={
                isEmployer
                  ? ChatConstants.SEARCH_INPUT_PLACEHOLDER_EMPLOYER
                  : ChatConstants.SEARCH_INPUT_PLACEHOLDER_JOBSEEKER
              }
              sx={{ height: "44px" }}
              endAdornment={
                <InputAdornment sx={{ marginLeft: spacing.m }} position="end">
                  <Icon name="search" />
                </InputAdornment>
              }
              onChange={onChangeSearchTerm}
            />
          </Row>
          {filteredChatUsers?.length === 0 && !isEmpty(searchTerm) && (
            <Text style={{ textAlign: "center", marginTop: "50%" }}>
              {ChatConstants.NO_SEARCH_RESULT}
            </Text>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "auto",
            }}
          >
            {filteredChatUsers && (
              <ChatList
                chatUsers={filteredChatUsers}
                selectedChatUser={selectedChatUser}
                onChangeChatUser={onChangeChatUser}
              />
            )}
          </Box>
        </Card>
      ) : selectedChatUser ? (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <ChatBox
            selectedChatUser={selectedChatUser}
            onSend={onSend}
            blockedUsers={blockedUsers}
            setBlockedUsers={setBlockedUsers}
            getChatUsers={getChatUsers}
          />
        </Card>
      ) : (
        <div>
          {chatUsers?.length === 0 && (
            <EmptyChatView loggedInUser={loggedInUser}></EmptyChatView>
          )}
        </div>
      )}
    </Container>
  );
};

ChatView.propTypes = {
  blockedUsers: PropTypes.arrayOf(PropTypes.string),
  chatUsers: PropTypes.arrayOf(PropTypes.object),
  filteredChatUsers: PropTypes.arrayOf(PropTypes.object),
  getChatUsers: PropTypes.func,
  onChangeChatUser: PropTypes.func,
  onChangeSearchTerm: PropTypes.func,
  onSearch: PropTypes.func,
  onSend: PropTypes.func,
  searchTerm: PropTypes.string,
  selectedChatUser: PropTypes.object,
  setBlockedUsers: PropTypes.func,
};

export default ChatView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width:100%,
  margin-right: ${spacing.s};
  margin-bottom: ${spacing.xl};
  padding-right: ${spacing.s};
`;
