import moment from "moment";

export const getDateDifference = (date) => {
  if (!date) return false;
  const date1 = new Date(date);
  const today = new Date();
  const diffTime = Math.abs(today - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const formatAMPM = (dateStr) => {
  const date = new Date(dateStr);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const dateFromNowDaily = (date) => {
  return moment(date).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastDay: "[Yesterday]",
    nextWeek: "DDDD",
    lastWeek: "dddd",
    sameElse: "ddd, DD MMM",
  });
};

export const getDateDiff = (timeStamp) => {
  const dateDiffValue = moment(timeStamp).fromNow();
  return dateDiffValue;
};

export const getJobPostedDateDiff = ({ jobPostedDate, jobCardType }) => {
  const OLD_JOB_THRESHOLD = 30;
  const formattedTime = getDateDiff(jobPostedDate);
  const daysDiff = moment().diff(moment(jobPostedDate), "days");
  const jobCardFormattedTime =
    daysDiff > OLD_JOB_THRESHOLD ? `${OLD_JOB_THRESHOLD}+ days ago` : formattedTime;
  switch (jobCardType?.toLowerCase()) {
    case "bookmarked":
      return formattedTime;
    case "applied":
      return formattedTime;
    default:
      return jobCardFormattedTime;
  }
};

export const isRecentAppliedJob = (jobAppliedDate) => {
  let duration = moment.duration(moment().diff(moment(jobAppliedDate))).as("hours");

  return duration <= 72;
};
