export const BASE_PAGE_ROUTE = "/";
export const LOGIN_PAGE_ROUTE = "/login/:loginType";
export const LOGIN_WITH_EMAIL_PAGE_ROUTE = "/loginEmail/:loginType";
export const JOBSEEKER_PAGE_ROUTE = "/jobSeeker";
export const EXPLORE_JOBS_PAGE_ROUTE = "explorejobs";
export const JOBSEEKER_FAVOURITE_PAGE_ROUTE = "applications";
export const JOBSEEKER_PROFILE_PAGE_ROUTE = "profile";
export const SIGN_UP_PAGE_ROUTE = "/signup/:signUpType";
export const FORGOT_PASSWORD_PAGE_ROUTE = "/forgotpassword";
export const CHAT_PAGE_ROUTE = "/chat";
export const JOB_FAIR_INFO = "/jobfairinfo";
export const JOB_FAIR_PAGE = "/JobFair";

export const JOBSEEKER_SCHEDULER_PAGE_ROUTE =
  "scheduler/calendar/:employer_calendar_id";

// Public Routes
export const ABOUT_PAGE_ROUTE = "/about";
export const PRIVACY_POLICY = "/privacyPolicy";
export const TERMS_CONDITION = "/termsAndConditions";
export const APP_PRIVACY_POLICY = "/app/privacyPolicy";
export const APP_TERMS_CONDITION = "/app/termsAndConditions";
export const BLOGS = "/blogs";
export const BLOG_POST = "/blogs/post/:slug";

//Employer Routes
export const EMPLOYER_HOME = "/employerhome";
export const EMPLOYER_PAGE_ROUTE = "/employer";
export const EMPLOYER_JOBS_PAGE_ROUTE = "jobs";
export const PEOPLE_PAGE_ROUTE = "people";
export const POST_NEW_JOB_PAGE_ROUTE = "postnewjob";
export const EDIT_JOB_PAGE_ROUTE = "editJob";
export const EMPLOYER_PROFILE_PAGE_ROUTE = "profile";
export const EMPLOYER_REGISTRATION_SCREEN = "/employerRegistration";
export const EMPLOYER_SCHEDULER_PAGE_ROUTE = "scheduler";
