import React from 'react'
import { styled } from "@mui/material";

const Input = ({
  value,
  onChange,
  onClick,
}: {
  value: string;
  onChange: () => void;
  onClick: () => void;
}) => {
  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onClick();
    }
  };
  return (
    <Wrapper >
      <TextInput 
        onKeyDown={onPressEnter}
        placeholder="Enter your query here..."
        value={value}
        onChange={onChange}
      />
      <Button onClick={onClick}>
        Ask
      </Button>
    </Wrapper>
  );
};

export default Input;

const Wrapper = styled("div")`
  display: grid;
  grid-template-columns: auto 100px;
  height: 60px;
  border-radius: 10px;
  background-color:#323236;
`

const TextInput = styled("input")`
  border: none;
  outline: none;
  background: none;
  padding: 20px;
  color: white;
  font-size: 16px;
`

const Button = styled("button")`
  border: none;
  border-radius: 0 10px 10px 0;
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(218, 255, 170);
  &:hover{
    cursor: pointer;
    background-color: rgb(200, 253, 130);
  }
`
