import useIsMobile from "../../hooks/useIsMobile";

import FooterMobile from "./mobileViews/MobileFooter";
import Footer from "./views/Footer";
import { useSelector } from "react-redux";
import LoginSelector from "../Login/selectors/LoginSelector";

const FooterContainer = () => {
  const isMobile = useIsMobile();
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);

  return (
    <>
      {isMobile ? (
        <FooterMobile isAuthenticated={isAuthenticated} />
      ) : (
        <>
          <Footer />
        </>
      )}
    </>
  );
};

export default FooterContainer;
