import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { images } from "../../../assets/images";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";
import { Text, TextInput } from "../../atoms";
import { isEmpty } from "utils/commonUtils";
import useIsMobile from "../../../hooks/useIsMobile";

const NonServiceableAreaView = ({
  image,
  imageHeight,
  title,
  description,
  activeRegion,
  sub_description,
  handleChange,
  onPressNotify,
  emailId,
  loggedInUser,
}) => {
  const isMobile = useIsMobile();
  return (
    <Wrapper>
      <img
        style={{ width: "auto", height: imageHeight }}
        src={images[image]}
        alt="Live from space album cover"
      />
      <Text
        weight="semibold"
        size={isMobile ? "l" : "xl"}
        color={colors.labelBlack}
        sx={{ marginTop: spacing.m }}
      >
        {title}
      </Text>
      <Text
        color={colors.fadedBlack}
        size={isMobile ? "s" : "m"}
        sx={{
          marginTop: isMobile ? spacing.xs : spacing.s,
          width: isMobile ? "auto" : "50%",
        }}
      >
        {description}
        {loggedInUser && (
          <Text
            component="span"
            color={colors.textPrimary}
            size={isMobile ? "s" : "m"}
          >
            {activeRegion}
          </Text>
        )}
      </Text>
      <Text
        size={isMobile ? "s" : "m"}
        color={colors.fadedBlack}
        sx={{ marginTop: spacing.xl }}
      >
        {sub_description}
      </Text>
      {!loggedInUser && (
        <div style={{ display: "flex", alignSelf: "center" }}>
          <StyledTextInput
            name="email"
            onChange={handleChange}
            placeholder="Enter your email address here "
            sx={{
              height: styleUtils.pxToRem("46px"),
              width: styleUtils.pxToRem(isMobile ? "319px" : "403px"),
              marginTop: spacing.s,
            }}
          />
          {!isMobile && (
            <Button
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("44px"),
                width: styleUtils.pxToRem("159px"),
                padding: spacing.xs,
                backgroundColor: "#2B4895",
                borderRadius: "22px",
                fontSize: textSizes.l,
                fontWeight: typography.medium,
                color: colors.white,
                marginTop: spacing.s,
                marginLeft: spacing.m,
              }}
              onClick={onPressNotify}
              value={emailId}
              disabled={isEmpty(emailId)}
            >
              Notify Me
            </Button>
          )}
        </div>
      )}
      {!loggedInUser && isMobile && (
        <Button
          variant="contained"
          sx={{
            width: styleUtils.pxToRem("216px"),
            marginTop: spacing.l,
            padding: spacing.xs,
            backgroundColor: "#2B4895",
            borderRadius: "22px",
            fontSize: textSizes.l,
            fontWeight: typography.medium,
            color: colors.white,
          }}
          onClick={onPressNotify}
          value={emailId}
          disabled={isEmpty(emailId)}
        >
          Notify Me
        </Button>
      )}
    </Wrapper>
  );
};

NonServiceableAreaView.propTypes = {
  activeRegion: PropTypes.string,
  description: PropTypes.string,
  emailId: PropTypes.string,
  handleChange: PropTypes.func,
  image: PropTypes.string,
  imageHeight: PropTypes.string,
  loggedInUser: PropTypes.object,
  onPressNotify: PropTypes.func,
  sub_description: PropTypes.string,
  title: PropTypes.string,
};

export default NonServiceableAreaView;

const Wrapper = styled("div")`
  padding: ${spacing.m};
  margin-top: ${spacing.l};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
`;

const StyledTextInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 16,
    "&::placeholder": {
      fontWeight: typography.light,
    },
  },
}));
