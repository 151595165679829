import { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";

import { ChatListCard } from "../../components/molecules";
import { spacing } from "../../styles";

const ChatList = ({ selectedChatUser, onChangeChatUser, chatUsers }) => {
  const scrollRef = useRef();
  useEffect(() => {
    if (selectedChatUser && chatUsers?.length > 0) {
      const scrollToLocation =
        document.getElementsByClassName("chat-list-item-active")?.[0]?.offsetTop;

      scrollRef?.current?.scrollTo({
        top: scrollToLocation - scrollRef.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [chatUsers, selectedChatUser]);

  const transformChatUsers = useCallback(() => {
    if (selectedChatUser) {
      if (
        chatUsers.find(
          (user) => user?.userId === (selectedChatUser?.userId || selectedChatUser?.uid),
        )
      ) {
        return chatUsers;
      } else {
        // Add new user to the start of the chat list
        return [selectedChatUser, ...chatUsers];
      }
    }
    return chatUsers;
  }, [selectedChatUser, chatUsers]);

  const renderChatList = useCallback(
    (item) => {
      // const isMute = (loggedInUser?.mutedIds || []).includes(item?.userId);
      return (
        <ChatListCard
          onClick={onChangeChatUser}
          item={item}
          active={item?.userId === (selectedChatUser?.userId || selectedChatUser?.uid)}
          key={item?.userId}
          cardStyle={{
            marginBottom: spacing.xl,
            marginRight: spacing.s,
          }}
        />
      );
    },
    [onChangeChatUser, selectedChatUser],
  );

  const transformedChatUsers = transformChatUsers();

  return (
    <ChatListContainer id="scrollableDiv" ref={scrollRef}>
      {chatUsers && (
        <InfiniteScroll dataLength={transformedChatUsers.length} scrollableTarget="scrollableDiv">
          {transformedChatUsers.map(renderChatList)}
        </InfiniteScroll>
      )}
    </ChatListContainer>
  );
};

ChatList.propTypes = {
  chatUsers: PropTypes.array,
  onChangeChatUser: PropTypes.func,
  selectedChatUser: PropTypes.object,
};

export default ChatList;

const ChatListContainer = styled("div")`
  height: 100%;
  overflow: auto;
`;
