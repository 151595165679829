import { useState } from "react";
import PropTypes from "prop-types";
import { Divider, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../../../styles";
import UploadModal from "../UploadModal";
import ProfileImage from "../../../../../components/molecules/ProfileImage";
import ResumeContainer from "../ResumeContainer";
import DeleteResumeModal from "../DeleteResumeModal";
import { hideLoader, showLoader, showNotification } from "utils/commonUtils";
import { deleteJobSeekerResume } from "configs/network/apis";
import { useDispatch } from "react-redux";
import { userDetailUpdate } from "containers/Login/actions/LoginActions";

const PersonalInformation = ({ userDetails, onUploadResume, onClickEditPersonalInformation }) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteResumeModalOpen, setIsDeleteResumeModalOpen] = useState(false);

  const dispatch = useDispatch();

  const showUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const hideUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const showDeleteResumeModal = () => {
    setIsDeleteResumeModalOpen(true);
  };
  const hideDeleteResumeModal = () => {
    setIsDeleteResumeModalOpen(false);
  };

  const handleDeleteResume = async () => {
    hideDeleteResumeModal();
    try {
      showLoader();
      const response = await deleteJobSeekerResume();
      const { message } = response.data;
      const { resumeUrl, resume_filename } = response.data.user;
      dispatch(
        userDetailUpdate({
          resumeUrl: resumeUrl,
          resume_filename: resume_filename,
        }),
      );
      showNotification({
        alertProps: {
          severity: "success",
          children: message,
        },
      });
    } catch (err) {
      showNotification({
        alertProps: {
          severity: "error",
          children: err?.response?.message,
        },
      });
    }
    hideLoader();
  };

  return (
    <>
      <TopContainer>
        <ProfileImage
          style={{ width: 136, height: 136 }}
          currentImageUrl={userDetails?.photoURL}
          alt={userDetails?.fullName}
          imageType="user"
        />
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            right: spacing.l,
            top: spacing.s,
            width: "auto",
            pl: spacing.xl,
            pr: spacing.xl,
          }}
          onClick={() => onClickEditPersonalInformation("personalinformation", "editPers")}
        >
          <Text size="l" weight="medium" color={colors.primary}>
            Edit
          </Text>
        </Button>
        <Text size={22} weight="semibold" style={{ marginTop: spacing.s }}>
          {userDetails?.fullName || userDetails?.name}
        </Text>
        <Text size="l" color={colors.placeholder}>
          {userDetails?.age} yrs
        </Text>
      </TopContainer>
      <Divider />
      <InfoContainer>
        <div style={{ marginTop: spacing.xs }}>
          <InfoBox>
            <Icon size={24} name="profile-email" style={{ marginRight: spacing.s }} />
            <Text size="l">{userDetails?.email}</Text>
          </InfoBox>
          <InfoBox>
            <Icon size={24} name="profile-phone" style={{ marginRight: spacing.s }} />
            <Text size="l">
              {userDetails?.countryCode
                ? `+${userDetails?.countryCode}-${userDetails?.phoneNumber}`
                : userDetails?.phoneNumber}
            </Text>
          </InfoBox>
        </div>
        <DividerBlock />
        <div style={{ marginTop: spacing.xs }}>
          <Row
            sx={{ alignItems: "flex-start", cursor: "pointer" }}
            onClick={!userDetails.resumeUrl ? () => showUploadModal() : ""}
          >
            <Icon
              name="profile-description"
              size={24}
              style={{
                marginRight: spacing.s,
                transform: "translateY(4px)",
              }}
            />
            {!userDetails.resumeUrl && (
              <Text
                size="l"
                color={colors.primary}
                style={{
                  textDecoration: "underline",
                  textUnderlinePosition: "under",
                }}
              >
                Upload your resume
              </Text>
            )}
            {userDetails.resumeUrl && (
              <Text size="l" sx={{ marginTop: spacing.xxs }}>
                Your job resume
              </Text>
            )}
          </Row>
          <ResumeContainer
            userDetails={userDetails}
            showUploadModal={showUploadModal}
            showDeleteResumeModal={showDeleteResumeModal}
          />
        </div>
      </InfoContainer>
      <UploadModal
        isResume
        userDetails={userDetails}
        isUploadModalOpen={isUploadModalOpen}
        showUploadModal={showUploadModal}
        hideUploadModal={hideUploadModal}
        onUploadAttachments={onUploadResume}
        uploadButtonName="Save"
      />
      <DeleteResumeModal
        isOpen={isDeleteResumeModalOpen}
        hideDeleteResumeModal={hideDeleteResumeModal}
        handleDeleteResume={handleDeleteResume}
      />
    </>
  );
};

PersonalInformation.propTypes = {
  userDetails: PropTypes.object.isRequired,
  onUploadResume: PropTypes.func.isRequired,
  onClickEditPersonalInformation: PropTypes.func.isRequired,
};
export default PersonalInformation;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${spacing.xl};
`;

const InfoContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s} ${spacing.xxl};
  margin-top: ${spacing.l};
  margin-left: ${styleUtils.pxToRem("56px")};
`;

const InfoBox = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xxl};
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
`;

const DividerBlock = styled("div")`
  border: 0;
  border-right: 1px solid ${colors.stroke};
  height: 15rem;
  margin-left: ${spacing.xxxl};
  margin-right: ${spacing.xxxl};
`;
