import { Container } from "@mui/material";
import { images } from "../../assets/images";

const CountryCheckLoaderContainer = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={images.spinner} width={100} height={100} alt="Loading" />
    </Container>
  );
};

export default CountryCheckLoaderContainer;
