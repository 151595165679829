import { useState } from "react";
import PropTypes from "prop-types";

import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { Text, TextInput, Icon } from "components/atoms";

import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { colors, spacing, styleUtils } from "../../../../../styles";
import { ACCOUNT_ACTIVATION_CONSTS } from "containers/SignUp/constants";

const PasswordActivationView = ({ isMobile, newPasswordFormik }) => {
  const [showNewPwdText, setShowNewPwdText] = useState(false);
  const [showConfirmPwdText, setShowConfirmPwdText] = useState(false);

  return (
    <div style={{ marginTop: styleUtils.pxToRem("32px") }}>
      <div>
        <InfoLabelText size="s" color={colors.labelBlack}>
          {ACCOUNT_ACTIVATION_CONSTS.LABEL_PASSWORD}
        </InfoLabelText>
        <PasswordInput
          name="newPassword"
          placeholder={ACCOUNT_ACTIVATION_CONSTS.PLACEHOLDER_PASSWORD}
          type={showNewPwdText ? "text" : "password"}
          value={newPasswordFormik.values.newPassword}
          onChange={newPasswordFormik.handleChange}
          endAdornment={
            <InputAdornment position="end" onClick={() => setShowNewPwdText(!showNewPwdText)}>
              <IconButton>
                {showNewPwdText ? (
                  <Visibility sx={{ color: `${colors.bulletGray}` }} />
                ) : (
                  <VisibilityOff sx={{ color: `${colors.bulletGray}` }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          sx={{
            width: styleUtils.pxToRem(isMobile ? "100%" : "336px"),
            marginBottom: spacing.xs,
          }}
        />
        {Boolean(newPasswordFormik.errors.newPassword) && (
          <FormHelperText sx={{ color: colors.red }}>
            {newPasswordFormik.errors.newPassword}
          </FormHelperText>
        )}
      </div>
      <div>
        <InfoLabelText size="s" color={colors.labelBlack} sx={{ marginTop: spacing.l }}>
          {ACCOUNT_ACTIVATION_CONSTS.LABEL_CONFIRM_PASSWORD}
        </InfoLabelText>
        <PasswordInput
          name="confirmPassword"
          placeholder={ACCOUNT_ACTIVATION_CONSTS.PLACEHOLDER_CONFIRM_PASSWORD}
          type={showConfirmPwdText ? "text" : "password"}
          value={newPasswordFormik.values.confirmPassword}
          onChange={newPasswordFormik.handleChange}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              newPasswordFormik.handleSubmit();
            }
          }}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={() => setShowConfirmPwdText(!showConfirmPwdText)}
            >
              <IconButton>
                {showConfirmPwdText ? (
                  <Visibility sx={{ color: "#C" }} />
                ) : (
                  <VisibilityOff sx={{ color: `${colors.bulletGray}` }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          sx={{
            width: styleUtils.pxToRem(isMobile ? "100%" : "336px"),
            marginBottom: spacing.xs,
          }}
        />
        {Boolean(newPasswordFormik.errors.confirmPassword) &&
          newPasswordFormik.values.newPassword !== newPasswordFormik.values.confirmPassword && (
            <FormHelperText sx={{ color: colors.red }}>
              {newPasswordFormik.errors.confirmPassword}
            </FormHelperText>
          )}
        {newPasswordFormik.values.newPassword.length > 6 &&
          newPasswordFormik.values.newPassword === newPasswordFormik.values.confirmPassword && (
            <div
              style={{
                marginTop: spacing.xs,
                display: "flex",
              }}
            >
              <Icon size={15} name="correct-validation" style={{ marginTop: "2px" }} />
              <Text size="s" sx={{ color: "#2E2E2E", marginLeft: spacing.xxs }}>
                {ACCOUNT_ACTIVATION_CONSTS.SUCCESS_PASSWORD_MATCH}
              </Text>
            </div>
          )}
      </div>
    </div>
  );
};

PasswordActivationView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  newPasswordFormik: PropTypes.object.isRequired,
};

export default PasswordActivationView;

const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.xxxs};
  text-align: left;
`;
const PasswordInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: ${styleUtils.pxToRem("42px")};
`;
