export const styleUtils = {
  remToPx: (rem) => {
    if (rem.includes("rem")) {
      return `${parseFloat(rem.replace("rem", "")) * 16}px`;
    }
    return rem;
  },
  pxToRem: (px) => {
    if (px.includes("px")) {
      return `${parseFloat(px.replace("px", "")) / 16}rem`;
    }
    return px;
  },
  pxToVw: (px) => {
    if (px.includes("px")) {
      let value = parseFloat(px.replace("px", ""));
      const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName("body")[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth;

      return `${(100 * value) / x}vw`;
    }
  },
};
