import { Button, Container, Grid } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";

const EasyOneClick = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: styleUtils.pxToRem("110px"),
        minHeight: "80vh",
      }}
    >
      <Grid container columnSpacing={spacing.xxxxl}>
        <Grid item md={0.5} />
        <Grid
          item
          md={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text size={44} style={{ lineHeight: 1.2 }}>
            Explore profiles
          </Text>
          <Text size={44} style={{ lineHeight: 1.2 }}>
            &amp;{" "}
            <Text
              component="span"
              size={44}
              weight="extrabold"
              color={colors.orange}
              style={{ lineHeight: 1.2 }}
            >
              shortlist
            </Text>
          </Text>
          <Text size="l" color="#4C5468" style={{ marginTop: spacing.s }}>
            Browse detailed profiles of all job seekers
          </Text>
          <Text size="l" color="#4C5468">
            around you and shortlist them for
          </Text>
          <Text size="l" color="#4C5468">
            further follow-up.
          </Text>
          {!isAuthenticated && (
            <Button
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("46px"),
                width: styleUtils.pxToRem("176px"),
                mt: spacing.xxl,
                px: spacing.xxl,
                py: spacing.xs,
                fontSize: textSizes.l,
                fontWeight: typography.bold,
              }}
              onClick={onGetStarted}
            >
              Get Started
            </Button>
          )}
        </Grid>
        <Grid item md={6}>
          <img
            src={images.home["employer-hero"]["3"]}
            alt="3"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default EasyOneClick;
