import PropTypes from "prop-types";
import Icons from "../../../assets/icons";
import { colors, textSizes } from "../../../styles";

const Icon = ({
  name,
  size = textSizes.l,
  color = colors.primary,
  strokeColor = "none",
  ...props
}) => {
  const SVGComponent = Icons[name];
  return <SVGComponent fill={color} stroke={strokeColor} height={size} width={size} {...props}/>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  strokeColor: PropTypes.string,
};

export default Icon;
