import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import { Button, Grid, FormHelperText } from "@mui/material";

import useIsMobile from "../../../../../hooks/useIsMobile";

import { colors, spacing, styleUtils, typography } from "../../../../../styles";
import { Text, TextInput } from "../../../../../components/atoms";
import { userUpdate } from "../../../../Login/actions/LoginActions";
import {
  showLoader,
  getSanitizedFullName,
  isKeyAllowedInFullName,
} from "../../../../../utils/commonUtils";
import * as FromConstants from "../constants";

const EditPersonalInformation = (props) => {
  const { handleEdit, userDetails } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const validateSchema = yup.object({
    fullName: yup.string().required("Name is required"),
    age: yup
      .number(userDetails?.age)
      .min(14, "Minimum age is 14")
      .max(99, "Maximum age is 99")
      .required("Age is required"),
  });

  const handleProfileOne = (values) => {
    showLoader();
    dispatch(
      userUpdate({
        fullName: values.fullName,
        age: values.age,
      }),
    );
    handleEdit("personalinformation", "");
  };

  const personalInfoFormik = useFormik({
    initialValues: {
      age: userDetails?.age,
      fullName: userDetails?.fullName || userDetails?.name,
      email: userDetails?.email,
      phoneNumber: userDetails?.phoneNumber,
    },
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleProfileOne(values);
    },
  });

  const handleFullName = (event) => {
    personalInfoFormik.setFieldValue("fullName", getSanitizedFullName(event.target.value));
  };

  const trimFullNameOnBlur = () => {
    personalInfoFormik.setFieldValue("fullName", personalInfoFormik.values.fullName.trim());
  };

  return (
    <>
      <Grid container sx={{ display: "flex", flex: 1, my: spacing.s }}>
        <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xxs, mx: spacing.l }}>
          <Text size="s" color={colors.placeholder} sx={{ marginBottom: spacing.xs }}>
            {FromConstants.NAME}
          </Text>
          <StyledTextInput
            name="fullName"
            value={personalInfoFormik.values.fullName}
            placeholder={"Name"}
            onChange={handleFullName}
            onBlur={trimFullNameOnBlur}
            onKeyDown={(e) => {
              if (!isKeyAllowedInFullName(e.key)) {
                e.preventDefault(); // Prevent input
              }
            }}
          />
          {personalInfoFormik.touched.fullName && Boolean(personalInfoFormik.errors.fullName) && (
            <FormHelperText sx={{ color: "red" }}>
              {personalInfoFormik.errors.fullName}
            </FormHelperText>
          )}
          <Text size="s" color={colors.placeholder} sx={{ marginBottom: spacing.xs }}>
            {FromConstants.PHONE_NUMBER}
          </Text>
          <div style={{ pointerEvents: "none" }}>
            <StyledTextInput
              name="phoneNumber"
              value={
                personalInfoFormik?.values?.countryCode
                  ? `+${personalInfoFormik.values?.countryCode}-${personalInfoFormik.values?.phoneNumber}`
                  : personalInfoFormik.values?.phoneNumber
              }
              placeholder={"PhoneNumber"}
              sx={{ color: colors.placeholder }}
            />
          </div>
          <Text size="s" color={colors.placeholder} sx={{ marginBottom: spacing.xs }}>
            {FromConstants.EMAIL_ID}
          </Text>
          <div style={{ pointerEvents: "none", marginBottom: spacing.l }}>
            <StyledTextInput
              name="email"
              value={personalInfoFormik.values.email}
              placeholder={"Email"}
              sx={{ color: colors.placeholder }}
            />
          </div>
          <Text size="s" color={colors.placeholder} sx={{ marginBottom: spacing.xs }}>
            {FromConstants.ENTER_YOUR_AGE}
          </Text>
          <StyledTextInput
            name="age"
            value={personalInfoFormik.values.age}
            placeholder={"Age"}
            onChange={personalInfoFormik.handleChange}
            inputProps={{ type: "number" }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
            }}
          />
          {personalInfoFormik.touched.age && Boolean(personalInfoFormik.errors.age) && (
            <FormHelperText sx={{ color: "red" }}>{personalInfoFormik.errors.age}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Footer sx={{ justifyContent: isMobile ? "space-evenly" : "flex-end" }}>
        <ActionButton variant="outlined" onClick={() => handleEdit("personalinformation", "")}>
          {FromConstants.CANCEL}
        </ActionButton>
        <ActionButton
          variant="contained"
          onClick={personalInfoFormik.handleSubmit}
          disabled={!personalInfoFormik.dirty}
        >
          {FromConstants.SAVE}
        </ActionButton>
      </Footer>
    </>
  );
};

export default EditPersonalInformation;

const StyledTextInput = styled(TextInput)(() => ({
  borderRadius: 8,
  marginBottom: spacing.xxl,
  border: `1px solid ${colors.lightBlue}`,
  height: styleUtils.pxToRem("46px"),
  width: styleUtils.pxToRem("294px"),
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));

const Footer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
  padding: ${spacing.s} ${spacing.l};
`;

const ActionButton = styled(Button)`
  font-size: ${styleUtils.pxToRem("14px")};
  margin-right: ${spacing.s};
  height: ${styleUtils.pxToRem("40px")};
  width: ${styleUtils.pxToRem("143px")};
`;
