export const signUpInitialState = {
  isJobSeekerSignUpLoading: false,
  isJobSeekerSignUpFailed: false,
  isJobSeekerSignUpSuccess: false,
  employerValidDetails: null,
  isEmployerSignUpLoading: false,
  isEmployerSignUpFailed: false,
  isEmployerSignUpSuccess: false,
  isEmployerVerificationLoading: false,
  isEmployerVerificationSuccess: false,
  isEmployerVerificationFailed: false,
  userDetails: null,
};
