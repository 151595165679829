import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon, Text } from "components/atoms";
import { colors, spacing, styleUtils } from "styles";
import { SETTINGS_SCREEN_CONSTS } from "containers/Account/constants";

const Settings = ({
  onClickPasswordScreen,
  onClickEditBlockedUsers,
  onClickDeleteAccount,
  showChangePassword,
}) => {
  return (
    <SettingsContainer>
      <TopContainer>
        <Icon size={25} name="blocked-users" color={colors.primary} />
        <Text size="xl" color={colors.textPrimary} sx={{ marginLeft: spacing.s }}>
          {SETTINGS_SCREEN_CONSTS.BLOCKED_USERS}
        </Text>
        <Icon
          size={28}
          name="profile-next"
          onClick={() => onClickEditBlockedUsers("settings", "editBlocked")}
          style={{ cursor: "pointer", marginLeft: "auto" }}
        />
      </TopContainer>
      <Divider />
      {showChangePassword && (
        <>
          <TopContainer>
            <Icon name="changePassword" size={24} color={colors.primary} />
            <Text size="xl" color={colors.textPrimary} sx={{ marginLeft: spacing.s }}>
              {SETTINGS_SCREEN_CONSTS.CHANGE_PASSWORD}
            </Text>
            <Icon
              size={28}
              name="profile-next"
              onClick={() => onClickPasswordScreen(true)}
              style={{ cursor: "pointer", marginLeft: "auto" }}
            />
          </TopContainer>
          <Divider />
        </>
      )}
      <TopContainer sx={{ alignItems: "flex-start" }}>
        <Icon name="delete-bin" size={24} color={colors.primary} />
        <DeleteContainer>
          <Text size="xl" color={colors.textPrimary} sx={{ marginLeft: spacing.s }}>
            {SETTINGS_SCREEN_CONSTS.DELETE_ACCOUNT}
          </Text>
          <Text
            size="m"
            color={colors.linkColor}
            sx={{
              cursor: "pointer",
              marginLeft: spacing.s,
              marginTop: spacing.xs,
              width: styleUtils.pxToRem("75px"),
            }}
            onClick={onClickDeleteAccount}
          >
            {SETTINGS_SCREEN_CONSTS.CLICK_HERE}
          </Text>
        </DeleteContainer>
      </TopContainer>
    </SettingsContainer>
  );
};

Settings.propTypes = {
  onClickPasswordScreen: PropTypes.func.isRequired,
  onClickEditBlockedUsers: PropTypes.func.isRequired,
  onClickDeleteAccount: PropTypes.func.isRequired,
  showChangePassword: PropTypes.bool.isRequired,
};

export default Settings;

const SettingsContainer = styled("div")`
  padding-left: ${spacing.s};
  padding-right: ${spacing.xs};
`;
const TopContainer = styled("div")`
  padding: ${spacing.xl};
  display: flex;
  align-items: center;
`;
const DeleteContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
