import { Avatar, Button, Divider, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getDateDiff } from "utils/dateUtils";
import { renderImage } from "../../../../utils/imageUtils";
import { images } from "../../../../assets/images";
import { Icon, Text } from "../../../../components/atoms";
import { colors, spacing, styleUtils, textSizes } from "styles";

const BlockUsersList = ({
  onClickChat,
  usersList,
  blockOpen,
  listTag,
  menuAction,
  showChat,
  anchorEl,
  handleClick,
  handleClose,
  handleUserStatus,
}) => {
  const renderUsersList = (userDetail, index, { length }) => {
    return (
      <>
        <Row>
          <DetailWraper>
            <Avatar
              sx={{ width: 39, height: 39 }}
              src={renderImage(userDetail.photoURL, "user")}
            >
              <img
                alt={userDetail.fullName}
                src={images.defaults[userDetail.defaultImage]}
                style={{ width: 39, height: 39 }}
              />
            </Avatar>
            <TextContainer>
              <Text
                size="m"
                weight="bold"
                color={colors.labelBlack}
                numberOfLines={1}
              >
                {userDetail.fullName}
              </Text>
              <Text
                size="m"
                weight="semibold"
                color={colors.labelBlack}
                numberOfLines={1}
              >
                {userDetail.companyName}
              </Text>
              <ContentWrapper>
                <Text
                  size="xs"
                  color={colors.subtextGray}
                  numberOfLines={1}
                  disableScroll
                  style={{ flex: 1 }}
                >
                  {`${listTag} ${getDateDiff(userDetail?.lastUpdatedAt)}`}
                </Text>
              </ContentWrapper>
            </TextContainer>
          </DetailWraper>

          <Icon
            name="profile-more"
            size={29}
            onClick={(e) => handleClick(e, userDetail)}
            color={colors.subtextGray}
            style={{ cursor: "pointer", marginLeft: "auto" }}
          />
          <Menu
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={blockOpen}
            onClose={handleClose}
            sx={{
              borderRadius: "10px",
              boxShadow: "0px 4px 10px #0000001C",
              "& .MuiPaper-root": {
                backgroundColor: colors.white,
                borderWidth: 0,
                height: styleUtils.pxToRem("53px"),
                width: styleUtils.pxToRem("183px"),
              },
            }}
          >
            <MenuItem onClick={handleUserStatus}>
              <Text size="m" weight="medium" color={colors.textPrimary}>
                {menuAction}
              </Text>
            </MenuItem>
          </Menu>
        </Row>
        {showChat && (
          <Row style={{ marginLeft: styleUtils.pxToRem("43px") }}>
            <Button
              variant="outlined"
              sx={{
                height: styleUtils.pxToRem("30px"),
                width: styleUtils.pxToRem("115px"),
                fontSize: textSizes.s,
              }}
              onClick={() => onClickChat(userDetail)}
            >
              Chat Now
            </Button>
          </Row>
        )}
        {index !== length - 1 && (
          <Divider sx={{ marginTop: spacing.s, marginBottom: spacing.s }} />
        )}
      </>
    );
  };
  return <>{usersList.map(renderUsersList)}</>;
};

export default BlockUsersList;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${spacing.xs};
`;
const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.xs};
  flex: 1;
`;
const ContentWrapper = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
`;

const DetailWraper = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
