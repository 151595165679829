import React, { useEffect } from "react";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { Button, Box, FormControl, FormLabel } from "@mui/material";

import useIsMobile from "../../../../hooks/useIsMobile";

import { Icon, OtpInput, Text } from "../../../../components/atoms";

import { colors, spacing, styleUtils, textSizes } from "../../../../styles";
import * as loginOtpVerifyConsts from "./loginOtpConstants";
import "./otploginsylesheet.css";
import { maskPhone } from "utils/commonUtils";
import { getLoader } from "containers/Common/selectors/CommonSelector";
import { useSelector } from "react-redux";
import { FORGOT_PASSWORD } from "containers/Account/constants";
import {
  FormHelperErrorText,
  ResendButton,
  ResendContainer,
} from "../styledConstants";

/**
 * Otp Login verification component
 */
const LoginOtpVerificationForm = (props) => {
  const {
    handleOtpVerification,
    handleResendOtp,
    handleVerifyLoginView,
    verifyLoginOtpSuccess,
    isLoginOtpValid,
    loginOtpSecs,
    setLoginOtpSecs,
    otpSrcDetails,
  } = props;
  const isMobile = useIsMobile();
  const { isShown } = useSelector(getLoader);
  const validate = (values) => {
    const errors = {};
    if (
      values.loginOtp.trim().length === 0 ||
      values.loginOtp.trim().length < 6
    ) {
      errors.loginOtp = FORGOT_PASSWORD.ERROR_OTP_LENGTH;
    } else if (values.loginOtp.trim().length === 6 && !isLoginOtpValid) {
      errors.loginOtp = FORGOT_PASSWORD.ERROR_OTP_MISMATCH;
    }
    return errors;
  };

  const usersPhoneNumber =
    otpSrcDetails.countryCode + "-" + otpSrcDetails.phoneNumber;

  useEffect(() => {
    let timer;
    if (loginOtpSecs !== 0) {
      timer = setInterval(() => {
        setLoginOtpSecs(loginOtpSecs - 1);
      }, 1000);
      if (loginOtpSecs === 0) {
        setLoginOtpSecs(60);
        clearInterval(timer);
      }
    }
    return () => clearInterval(timer);
  }, [loginOtpSecs]);

  const loginOtpValidationFormik = useFormik({
    initialValues: {
      ...loginOtpVerifyConsts.LOGIN_OTP_VERIFY_DETAILS,
    },
    validate,
    onSubmit: (values) => {
      if (values.loginOtp && values.loginOtp.trim().length === 6) {
        handleOtpVerification(values.loginOtp);
      }
    },
  });

  useEffect(() => {
    if (loginOtpValidationFormik.values.loginOtp.trim().length === 6) {
      handleOtpVerification(loginOtpValidationFormik.values.loginOtp);
    }
  }, [loginOtpValidationFormik.values.loginOtp]);

  const showOtpInputError =
    loginOtpValidationFormik.touched.loginOtp &&
    Boolean(loginOtpValidationFormik.errors.loginOtp);
  const invalidLoginlOtp = verifyLoginOtpSuccess && !isLoginOtpValid;
  const showZero = loginOtpSecs < 10 ? "0" : "";
  return (
    <VerifcationFormContainer
      sx={{
        padding: isMobile ? spacing.xxxs : spacing.xs,
      }}
    >
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        sx={{
          marginTop: spacing.xs,
        }}
      >
        {loginOtpVerifyConsts.LOGIN_OTP_VERIFICAION_FORM_HEADER}
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
        sx={{
          marginTop: spacing.xxs,
          marginBottom: spacing.xl,
        }}
      >
        {loginOtpVerifyConsts.LOGIN_OTP_VERIFICAION_FORM_HEADER_SUBTEXT}
        <span style={{ fontStyle: "italic" }}>
          {maskPhone(usersPhoneNumber)}
        </span>
      </Text>
      <FormControl required>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: spacing.xs,
          }}
        >
          {loginOtpVerifyConsts.LOGIN_OTP_VERIFICATION_FORM_INPUT_LABEL}
        </FormLabel>
        <OtpInput
          value={loginOtpValidationFormik.values.loginOtp}
          hasError={showOtpInputError || invalidLoginlOtp}
          onChange={(text) =>
            loginOtpValidationFormik.setFieldValue("loginOtp", text)
          }
          isVerifying={isShown}
          isCodeVerified={isLoginOtpValid}
        />
        <ResendContainer>
          {(showOtpInputError || invalidLoginlOtp) && (
            <>
              <Icon
                name="error"
                size={11}
              />
              <FormHelperErrorText>
                {loginOtpValidationFormik.errors.loginOtp}
              </FormHelperErrorText>
            </>
          )}

          <ResendButton
            variant="text"
            disabled={loginOtpSecs !== 0}
            onClick={handleResendOtp}
            className="otp-login-resend-link"
            size="xs"
            sx={{
              color: loginOtpSecs !== 0 ? colors.timerGray : colors.link,
              border: "none",
              boxShadow: "none",
              position: "absolute",
              left: loginOtpSecs !== 0 ? "160px" : "210px",
            }}
          >
            {loginOtpSecs !== 0
              ? `Resend code in ${"00"}:${showZero}${loginOtpSecs}`
              : loginOtpVerifyConsts.LOGIN_OTP_VERIFICATION_FORM_RESEND_LABEL}
          </ResendButton>
        </ResendContainer>
      </FormControl>

      <ButtonsContainer>
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => handleVerifyLoginView(false)}
        />
        <Button
          variant="contained"
          disabled={loginOtpValidationFormik.values.loginOtp.length !== 6}
          onClick={loginOtpValidationFormik.handleSubmit}
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
            alignSelf: "end",
          }}
        >
          {loginOtpVerifyConsts.LOGIN_OTP_FORM_LOGIN_BTN}
        </Button>
      </ButtonsContainer>
    </VerifcationFormContainer>
  );
};

export default LoginOtpVerificationForm;

const VerifcationFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${styleUtils.pxToRem("112px")};
`;
