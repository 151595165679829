import { Card, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { spacing } from "../../../styles";

import "./jobcard.css";

const JobCardSkeleton = ({ cardStyle, size = "m", id, active }) => {
  return (
    <Card
      sx={{
        transform: `scale(${size === "m" ? 1 : 0.875})`,
        cursor: "pointer",
        ...cardStyle,
      }}
      id={id}
      className={active ? "job-list-item-active" : ""}
    >
      <div>
        <TopContainer>
          <Skeleton circle={true} height={41} width={41} />
          <TitleContainer
            sx={{
              height: spacing.xxxl,
              marginRight: spacing.m,
            }}
          >
            <Skeleton count={3} />
          </TitleContainer>
          <Skeleton circle={true} height={58} width={58} style={{ marginRight: spacing.xs }} />
        </TopContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: `0 ${spacing.xxs}`,
          }}
        >
          <FlexContainer>
            <Skeleton circle={true} height={30} width={30} />
            <TitleContainer sx={{ marginLeft: spacing.xxs }}>
              <Skeleton count={2} />
            </TitleContainer>
          </FlexContainer>
          <FlexContainer>
            <Skeleton circle={true} height={30} width={30} />
            <TitleContainer sx={{ marginLeft: spacing.xxs }}>
              <Skeleton count={2} />
            </TitleContainer>
          </FlexContainer>
          <FlexContainer>
            <Skeleton circle={true} height={30} width={30} />
            <TitleContainer sx={{ marginLeft: spacing.xxs }}>
              <Skeleton count={2} />
            </TitleContainer>
          </FlexContainer>
        </div>
        <Divider
          style={{
            marginLeft: spacing.s,
            marginRight: spacing.s,
            marginTop: spacing.s,
          }}
        />

        <Skeleton
          count={1}
          style={{
            margin: spacing.s,
            width: "90%",
          }}
        />
      </div>
    </Card>
  );
};

export default JobCardSkeleton;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
  padding-right: 0;
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${spacing.s};
`;
const FlexContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.xs} ${spacing.m} ${spacing.xs} ${spacing.m};
`;
