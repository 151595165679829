import { Button, Container } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";
import { ChatDirectlyConstants } from "./Constants";

const ChatDirectly = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        my: spacing.xxxxl,
      }}
    >
      <Text id="CHAT_DIR_MOB_TITLE_2" size={32} style={{ lineHeight: 1.2 }}>
        <Text
          id="CHAT_DIR_MOB_TITLE_1"
          size={32}
          component="span"
          weight="extrabold"
          color={colors.green}
          style={{ lineHeight: 1.2 }}
        >
          {ChatDirectlyConstants.TITLE_1}
        </Text>{" "}
        {ChatDirectlyConstants.TITLE_2}
      </Text>
      <Text id="CHAT_DIR_MOB_TITLE_3" size={32} style={{ lineHeight: 1.2 }}>
        {ChatDirectlyConstants.TITLE_3}
      </Text>
      <Text
        id="CHAT_DIR_MOB_SUB_TITLE_1"
        color={colors.placeholder}
        style={{ marginTop: spacing.s }}
      >
        {ChatDirectlyConstants.SUB_TITLE_1} &amp;{" "}
        {ChatDirectlyConstants.SUB_TITLE_2}
      </Text>
      {!isAuthenticated && (
        <Button
          id="CHAT_DIR_MOB_STARTED_BTN"
          variant="contained"
          sx={{
            mt: spacing.xxl,
            width: "fit-content",
            px: spacing.xxl,
            py: spacing.xs,
          }}
          onClick={onGetStarted}
        >
          {ChatDirectlyConstants.GET_STARTED_BTN}
        </Button>
      )}
      <img
        src={images.home["jobseeker-hero"]["4"]}
        alt="4"
        style={{ width: "100%", marginTop: spacing.xl }}
      />
    </Container>
  );
};

export default ChatDirectly;
