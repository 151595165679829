import * as yup from "yup";

export const schedulerDetailsFormSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  fullName: yup.string().required("Name is required"),
  jobTitle: yup.string().required("Job Title is required"),
});

export const schedulerUserValidationSchema = yup.object().shape({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Enter your email"),
});

export const createAvailabilitySchema = yup.object({
  dateTime: yup.date().required("Date and Time is required"),
  duration: yup.number().required("Meeting Duration is required"),
  meetingMode: yup.string().required("Meeting Mode is required"),
});
