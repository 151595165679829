import * as employerActionTypes from "./actionTypes";

export const employerListingRequest = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_LISTING_REQUEST,
    payload,
  };
};

export const employerListingSuccess = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_LISTING_SUCCESS,
    payload,
  };
};

export const employerListingFailure = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_LISTING_FAILURE,
    payload,
  };
};

export const employerJobSeekerListingRequest = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_REQUEST,
    payload,
  };
};

export const employerJobSeekerListingSuccess = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_SUCCESS,
    payload,
  };
};

export const employerJobSeekerListingFailure = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_FAILURE,
    payload,
  };
};

export const toggleStarred = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_SEEKER_TOGGLE_STARRED,
    payload,
  };
};

export const toggleRequestResume = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_SEEKER_TOGGLE_REQUEST_RESUME,
    payload,
  };
};

export const employerJobSeekerReset = () => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_SEEKER_RESET,
  };
};

export const deleteJobPost = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_JOB_DELETE_REQUEST,
    payload,
  };
};

//Scheduler Actions
export const schedulerDetailsUpdate = (payload) => {
  return {
    type: employerActionTypes.SCHEDULER_USER_DETAILS_UPDATE,
    payload,
  };
};

export const getSchedulerAvailabilityList = (payload) => {
  return {
    type: employerActionTypes.SCHEDULER_GET_AVAILABILITY_LIST_REQUEST,
    payload,
  };
};

export const getSchedulerUpcomingMeetingList = (payload) => {
  return {
    type: employerActionTypes.SCHEDULER_GET_UPCOMING_LIST_REQUEST,
    payload,
  };
};

export const createSchedulerAvailability = (payload) => {
  return {
    type: employerActionTypes.SCHEDULER_CREATE_AVAILABILITY_REQUEST,
    payload,
  };
};

export const getSchedulerShareLink = (payload) => {
  return {
    type: employerActionTypes.SCHEDULER_GET_SHARE_LINK_REQUEST,
    payload,
  };
};

//Rating
export const employerRatingStatus = () => {
  return {
    type: employerActionTypes.EMPLOYER_RATING_STATUS,
  }
};

export const setEmployerRatingStatus = () => {
  return {
    type: employerActionTypes.EMPLOYER_UPDATE_RATING_STATUS,
  }
};


//First Job Modal
export const setEmployerFirstJobModalStart = (payload) => {
  return {
    type: employerActionTypes.EMPLOYER_FIRST_JOB_START_TIME,
    payload,
  }
};