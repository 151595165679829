import ConnectSocialMobile from "./mobile/ConnectSocialAccs/ConnectSocial";
import ConnectSocial from "./web/ConnectSocialAccs/ConnectSocial";

import useIsMobile from "hooks/useIsMobile";

const ConnectSocialAccountContainer = ({
  socialAccountListData,
  onSuccessGoogleLogin,
  onSuccessFacebookLogin,
  onFailureGoogleLogin,
}) => {
  const isMobile = useIsMobile();
  const googleData =
    socialAccountListData?.length > 0
      ? socialAccountListData[
          socialAccountListData?.findIndex(
            (typeIndex) => typeIndex.account_type === "google"
          )
        ]
      : [];
  const facebookData =
    socialAccountListData?.length > 0
      ? socialAccountListData[
          socialAccountListData?.findIndex(
            (typeIndex) => typeIndex.account_type === "facebook"
          )
        ]
      : [];

  const ConnectSocialAccountWrapper = isMobile
    ? ConnectSocialMobile
    : ConnectSocial;

  return (
    <ConnectSocialAccountWrapper
      googleData={googleData}
      facebookData={facebookData}
      onSuccessGoogleLogin={onSuccessGoogleLogin}
      onFailureGoogleLogin={onFailureGoogleLogin}
      onSuccessFacebookLogin={onSuccessFacebookLogin}
    />
  );
};

export default ConnectSocialAccountContainer;
