import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const Loader = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
