import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { images } from "../../../../assets/images";
import useIsMobile from "../../../../hooks/useIsMobile";
import RegistrationForm from "./RegistrationForm";
import { spacing } from "styles";
const RegistrationView = () => {
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <RegistrationForm />
      </Grid>
      {!isMobile && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: spacing.xl,
          }}
        >
          <div style={{ marginBottom: "15%" }}>
            <Logo src={images.logo} />
          </div>
          <ImgContainer src={images.imgemployersignup} />
        </div>
      )}
    </Grid>
  );
};

export default RegistrationView;

const ImgContainer = styled("img")`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 400px;
`;

const Logo = styled("img")`
  max-width: 150px;
  width: auto;
  height: auto;
`;
