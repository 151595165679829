import { Button, Container } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";

const SearchLocalJobs = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        my: spacing.xxxxl,
      }}
    >
      <Text
        weight="extrabold"
        size={32}
        style={{ lineHeight: 1.2 }}
        color={colors.primary}
      >
        Post a job
      </Text>
      <Text size={32} style={{ lineHeight: 1.2 }}>
        in a minute
      </Text>
      <Text color={colors.placeholder} style={{ marginTop: spacing.s }}>
        We simplify the process of posting your job. Our simple interface allows
        you to post your job quickly and hassle-free.
      </Text>
      {!isAuthenticated && (
        <Button
          variant="contained"
          sx={{
            mt: spacing.xxl,
            width: "fit-content",
            px: spacing.xxl,
            py: spacing.xs,
          }}
          onClick={onGetStarted}
        >
          Get Started
        </Button>
      )}
      <img
        src={images.home["employer-hero"]["2"]}
        alt="2"
        style={{ width: "100%", marginTop: spacing.xl }}
      />
    </Container>
  );
};

export default SearchLocalJobs;
