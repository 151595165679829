import { styled } from "@mui/material/styles";

import { MultiItemCarousel, Text } from "../../../components/atoms";

import { images } from "../../../assets/images";
import { spacing } from "../../../styles";
import { convertArrayIntoChunks } from "../../../utils/commonUtils";

const HomeBrands = () => {
  const brands = [
    {
      image: images.home.brands.rainbow,
      width: 72,
      height: 42,
    },
    {
      image: images.home.brands["agasar-family"],
      width: 85,
      height: 40,
    },
    {
      image: images.home.brands.nac,
      width: 85,
      height: 42,
    },
    {
      image: images.home.brands["silly-spoons"],
      width: 42,
      height: 42,
    },
    {
      image: images.home.brands["smoke-dudes"],
      width: 62,
      height: 46,
    },
    {
      image: images.home.brands["the-briches"],
      width: 105,
      height: 46,
    },
    {
      image: images.home.brands.arway,
      width: 76,
      height: 37,
    },
    {
      image: images.home.brands["fzp-digital"],
      width: 149,
      height: 42,
    },
    {
      image: images.home.brands["newtown-pizza"],
      width: 106,
      height: 41,
    },
    {
      image: images.home.brands["hi-bar"],
      width: 56,
      height: 56,
    },
    {
      image: images.home.brands["smoothie-king"],
      width: 54,
      height: 40,
    },
    {
      image: images.home.brands["newtown-bagel"],
      width: 51,
      height: 47,
    },
    {
      image: images.home.brands["meglio-wood-pizzeria"],
      width: 48,
      height: 47,
    },
    {
      image: images.home.brands.playabowls,
      width: 47,
      height: 47,
    },
    {
      image: images.home.brands.kumon,
      width: 105,
      height: 23,
    },
    {
      image: images.home.brands.handm,
      width: 44,
      height: 29,
    },
    {
      image: images.home.brands.shadybrook,
      width: 64,
      height: 32,
    },

    {
      image: images.home.brands.gymguyz,
      width: 166,
      height: 39,
    },
    {
      image: images.home.brands["island-tanning"],
      width: 205,
      height: 34,
    },
    {
      image: images.home.brands["the-coffee-room"],
      width: 69,
      height: 38,
    },
    {
      image: images.home.brands["the-lash-lounge"],
      width: 100,
      height: 32,
    },
    {
      image: images.home.brands.popeyes,
      width: 153,
      height: 24,
    },
    {
      image: images.home.brands.ctp,
      width: 78,
      height: 36,
    },
  ];

  return (
    <HomeBrandsWrapper>
      <Text
        size="xl"
        weight="bold"
        color="#343434"
        sx={{ paddingTop: spacing.l }}
      >
        Trusted by thousands of businesses
      </Text>
      <MultiItemCarousel interval={7000}>
        {convertArrayIntoChunks(brands, 5).map((j, jindex) => (
          <div
            key={jindex}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: `${spacing.l} 0`,
            }}
          >
            {j.map((i, index) => (
              <img
                key={index}
                alt={index}
                src={i.image}
                width={i.width}
                height={i.height}
              />
            ))}
          </div>
        ))}
      </MultiItemCarousel>
    </HomeBrandsWrapper>
  );
};

export default HomeBrands;

const HomeBrandsWrapper = styled("div")`
  text-align: center;
  background: #fafafa;
`;
