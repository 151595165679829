import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, FormControl, FormLabel, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";

import { Icon, Text, TextInput, SingleSelect, MultiSelect } from "../../../../components/atoms";

import useIsMobile from "../../../../hooks/useIsMobile";

import { colors, styleUtils, textSizes } from "../../../../styles";
import * as SeekerPreferenceConsts from "./constants";
import "./SeekerSignUpStyle.css";
import ResumeContainer from "containers/JobSeeker/views/Profile/ResumeContainer";
import UploadModal from "containers/JobSeeker/views/Profile/UploadModal";
import DeleteResumeModal from "containers/JobSeeker/views/Profile/DeleteResumeModal";

const SeekerPreferenceForm = ({
  resumeFile,
  formik,
  isSocialLogin,
  handleNav,
  onUploadAttachments,
  setResumeFile,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteResumeModalOpen, setIsDeleteResumeModalOpen] = useState(false);
  const isMobile = useIsMobile();

  const showDeleteResumeModal = () => {
    setIsDeleteResumeModalOpen(true);
  };
  const hideDeleteResumeModal = () => {
    setIsDeleteResumeModalOpen(false);
  };

  const handleWageChange = (value) => {
    formik.setFieldTouched("wageExpectation", true);
    formik.setFieldValue("wageExpectation", value);
  };

  const handleHoursChange = (value) => {
    formik.setFieldTouched("workingHours", true);
    formik.setFieldValue("workingHours", value);
  };

  const handleExpChange = (value) => {
    formik.setFieldTouched("experience", true);
    if (!isEmpty(value)) {
      formik.setFieldValue("experience", [value]);
    } else {
      formik.setFieldValue("experience", []);
    }
  };

  const handleDaysChange = (value) => {
    formik.setFieldTouched("daysPerWeek", true);
    formik.setFieldValue("daysPerWeek", value);
  };

  const handleSlotsChange = (value) => {
    formik.setFieldTouched("timeSlot", true);
    formik.setFieldValue("timeSlot", value);
  };

  const handleNoteChange = (value) => {
    formik.setFieldValue("note", value.target.value);
  };

  const showUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const hideUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDeleteResume = () => {
    setResumeFile({});
    hideDeleteResumeModal();
  };

  return (
    <DetailsContainer>
      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {SeekerPreferenceConsts.WAGE_EXPECTATION}
        </FormLabel>
        <SelectContainer>
          <MultiSelect
            name="wageExpectation"
            selectOptions={SeekerPreferenceConsts.wageExpectations}
            prevSelectedOptions={formik.values.wageExpectation}
            handleSelect={handleWageChange}
          />
        </SelectContainer>
        {formik.touched.wageExpectation && formik.errors.wageExpectation && (
          <FormHelperText className="profile-form-error-field">
            {formik.errors.wageExpectation}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {SeekerPreferenceConsts.HOURS}
        </FormLabel>
        <SelectContainer>
          <MultiSelect
            name="workingHours"
            selectOptions={SeekerPreferenceConsts.workingHrs}
            prevSelectedOptions={formik.values.workingHours}
            handleSelect={handleHoursChange}
          />
        </SelectContainer>
        {formik.touched.workingHours && formik.errors.workingHours && (
          <FormHelperText className="profile-form-error-field">
            {formik.errors.workingHours}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {SeekerPreferenceConsts.WORK_EXPERIENCE}
        </FormLabel>
        <SelectContainer>
          <SingleSelect
            name="experience"
            options={SeekerPreferenceConsts.workExperience}
            prevSelectedOptions={formik.values.experience[0]}
            handleSelect={handleExpChange}
          />
        </SelectContainer>
        {formik.touched.experience && formik.errors.experience && (
          <FormHelperText className="profile-form-error-field">
            {formik.errors.experience}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {SeekerPreferenceConsts.DAYS_WEEK}
        </FormLabel>
        <SelectContainer>
          <MultiSelect
            selectOptions={SeekerPreferenceConsts.dayAvailable}
            prevSelectedOptions={formik.values.daysPerWeek}
            handleSelect={handleDaysChange}
            name="daysPerWeek"
          />
        </SelectContainer>
        {formik.touched.daysPerWeek && formik.errors.daysPerWeek && (
          <FormHelperText className="profile-form-error-field">
            {formik.errors.daysPerWeek}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {SeekerPreferenceConsts.SHIFT}
        </FormLabel>
        <SelectContainer>
          <MultiSelect
            selectOptions={SeekerPreferenceConsts.timeSlots}
            prevSelectedOptions={formik.values.timeSlot}
            handleSelect={handleSlotsChange}
            name="timeSlot"
          />
        </SelectContainer>
        {formik.touched.timeSlot && Boolean(formik.errors.timeSlot) && (
          <FormHelperText className="profile-form-error-field">
            {formik.errors.timeSlot}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          Upload Resume (Optional)
        </FormLabel>
        <SubmitBtnContainer>
          {!(resumeFile instanceof File) && (
            <Button
              variant="contained"
              startIcon={<Icon size={19} name="file-upload" />}
              onClick={showUploadModal}
              sx={{
                width: styleUtils.pxToRem("161px"),
                height: styleUtils.pxToRem("34px"),
              }}
            >
              {SeekerPreferenceConsts.UPLOAD_RESUME}
            </Button>
          )}
          {resumeFile?.name !== "" && (
            <ResumeTextWrapper color={colors.activeLinkBlue}>{resumeFile?.name}</ResumeTextWrapper>
          )}
        </SubmitBtnContainer>
        {resumeFile?.name && (
          <div style={{ marginLeft: !isMobile ? styleUtils.pxToRem("-40px") : 0 }}>
            <ResumeContainer
              resumeFile={resumeFile}
              showUploadModal={showUploadModal}
              showDeleteResumeModal={showDeleteResumeModal}
            />
          </div>
        )}
      </FormControl>
      <FormControl variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {SeekerPreferenceConsts.NOTE_FOR_EMPLOYEE} (Optional)
        </FormLabel>
        <TextInput
          name="note"
          placeholder={SeekerPreferenceConsts.NOTE_FOR_EMPLOYEE_PLACEHOLDER}
          onChange={handleNoteChange}
          inputProps={{ style: { height: 129, overflow: "auto" } }}
          multiline
          sx={{
            width: styleUtils.pxToRem(isMobile ? "304px" : "432px"),
            height: styleUtils.pxToRem("129px"),
            marginTop: styleUtils.pxToRem("8px"),
            border: "1px solid #E0E0E0",
            borderRadius: styleUtils.pxToRem("4px"),
          }}
        />
      </FormControl>

      <FormsBtnContainer>
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => handleNav(false)}
        />
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
          sx={{
            marginLeft: "auto",
            marginRight: !isMobile ? "3.5rem" : "0",
            width: styleUtils.pxToRem(isMobile ? "161px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
        >
          {isSocialLogin ? SeekerPreferenceConsts.SUBMIT : SeekerPreferenceConsts.NEXT}
        </Button>
      </FormsBtnContainer>
      <UploadModal
        isResume
        resumeFile={resumeFile}
        isUploadModalOpen={isUploadModalOpen}
        showUploadModal={showUploadModal}
        hideUploadModal={hideUploadModal}
        onUploadAttachments={onUploadAttachments}
        uploadButtonName="Save"
      />

      <DeleteResumeModal
        isOpen={isDeleteResumeModalOpen}
        hideDeleteResumeModal={hideDeleteResumeModal}
        handleDeleteResume={handleDeleteResume}
      />
    </DetailsContainer>
  );
};

SeekerPreferenceForm.propTypes = {
  resumeFile: PropTypes.object,
  formik: PropTypes.object.isRequired,
  isSocialLogin: PropTypes.bool.isRequired,
  handleNav: PropTypes.func.isRequired,
  onUploadAttachments: PropTypes.func.isRequired,
  setResumeFile: PropTypes.func.isRequired,
};

export default SeekerPreferenceForm;

const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const SelectContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const ResumeTextWrapper = styled(Text)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: ${styleUtils.pxToRem("64px")};
`;

const SubmitBtnContainer = styled("div")`
  height: min-content;
`;
