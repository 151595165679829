import { createTheme } from "@mui/material/styles";

import { colors } from "./colors";
import { componentStyles } from "./componentStyles";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.green,
    },
  },
  typography: {
    fontFamily: "Mulish",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
  components: componentStyles,
});
