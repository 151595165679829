import { handleActions } from "redux-actions";
import update from "immutability-helper";

import {
  jobSeekerInitialState,
  jobSeekerSchedulerInitialState,
} from "./initialState";
import * as jobSeekerActionTypes from "../actions/actionTypes";

const jobSeekerReducer = {
  jobSeekerState: handleActions(
    {
      [jobSeekerActionTypes.JOB_SEEKER_LISTING_REQUEST]: (state) => {
        return update(state, {
          jobSeekerListingLoading: { $set: true },
          jobSeekerListingFailure: { $set: false },
          jobSeekerListingSuccess: { $set: false },
        });
      },
      [jobSeekerActionTypes.JOB_SEEKER_LISTING_SUCCESS]: (state, { data }) => {
        return update(state, {
          jobSeekerListingLoading: { $set: false },
          jobSeekerListingFailure: { $set: false },
          jobSeekerListingSuccess: { $set: true },
          jobSeekerListing: { $push: data.data },
          jobSeekerSuggestedResults: { $set: data.suggestedResult },
          totalListingJobs: { $set: data.total_records },
          listedJobsPageLimit: {
            $set: data.total_records
              ? Math.ceil(data.total_records / 20)
              : jobSeekerInitialState.listedJobsPageLimit,
          },
        });
      },
      [jobSeekerActionTypes.JOB_SEEKER_LISTING_FAILURE]: (state) => {
        return update(state, {
          jobSeekerListingLoading: { $set: false },
          jobSeekerListingFailure: { $set: true },
          jobSeekerListingSuccess: { $set: false },
        });
      },
      [jobSeekerActionTypes.JOB_SEEKER_TOGGLE_FAVOURITE]: (state, action) => {
        const { jobId, userId, interested } = action.payload;
        const data = state.jobSeekerListing;
        const selectedJobIndex = data.findIndex((job) => job.jobId === jobId);
        if (selectedJobIndex > -1) {
          if (interested) {
            data[selectedJobIndex]["jobSeekers"] = [
              ...(data[selectedJobIndex]["jobSeekers"] || []),
              userId,
            ];
          } else {
            data[selectedJobIndex]["jobSeekers"] = data[selectedJobIndex][
              "jobSeekers"
            ].filter((uid) => uid !== userId);
          }
        }
        return update(state, {
          jobSeekerListing: { $set: data },
          forceUpdate: { $set: !state.forceUpdate },
        });
      },
      [jobSeekerActionTypes.JOB_SEEKER_RESET]: (state) => {
        return update(state, {
          jobSeekerListingLoading: {
            $set: jobSeekerInitialState.jobSeekerListingLoading,
          },
          jobSeekerListingFailure: {
            $set: jobSeekerInitialState.jobSeekerListingFailure,
          },
          jobSeekerListingSuccess: {
            $set: jobSeekerInitialState.jobSeekerListingSuccess,
          },
          jobSeekerListing: { $set: jobSeekerInitialState.jobSeekerListing },
          totalListingJobs: { $set: jobSeekerInitialState.totalListingJobs },
          listedJobsPageLimit: {
            $set: jobSeekerInitialState.listedJobsPageLimit,
          },
          forceUpdate: { $set: jobSeekerInitialState.forceUpdate },
        });
      },
      [jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_SUCCESS]:
        (state, { availabilities }) => {
          return update(state, {
            employerAvailableList: { $set: availabilities },
          });
        },
      [jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_BOOK_SLOT_SUCCESS]: (
        state,
        { availabilities }
      ) => {
        return update(state, {
          employerAvailableList: { $set: availabilities },
        });
      },
      [jobSeekerActionTypes.JOB_SEEKER_UPDATE_RATING_STATUS]: (state) => {
        return update(state, {
          isJobSeekerRatingDone: { $set: true },
        });
      },
    },
    jobSeekerInitialState,
    jobSeekerSchedulerInitialState
  ),
};

export default jobSeekerReducer;
