import { logEvent } from "configs/firebase";
import { showNotification } from "utils/commonUtils";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { useRequestResumeMutation } from "reactQuery/people";

export const usePeopleCardHelpers = ({ loggedInUser, item, isResumeRequested }) => {
  const [requestResume] = useRequestResumeMutation("jobSeeker", item?.uid, loggedInUser?.uid);
  const onClickRequestResume = async (e) => {
    e.stopPropagation();
    if (isResumeRequested) {
      showNotification({
        alertProps: {
          severity: "warning",
          children: "You have already requested for this jobseeker's resume",
        },
      });
      return;
    }
    await requestResume({
      jobSeekerId: item?.userId || item?.uid,
    });
    logEvent(FirebaseEvents.EMP_REQUEST_RESUME, {
      items: [{ userId: loggedInUser?.uid, status: "success" }],
    });
  };

  return { onClickRequestResume };
};
