import "./styles.css";

const RichTextViewer = ({ children, style }) => {
  return (
    <div
      className="rich-text-viewer"
      style={style}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export default RichTextViewer;
