import { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import Icon from "../Icon";
import { colors, spacing, styleUtils, typography } from "../../../styles";

/**
 * Select Dropdown with bottom list & options with selection check
 */
const BottomDrawerDropDown = ({
  fieldValue,
  fieldName,
  dropDownOptions,
  placeholder,
  handleChange,
  handleBlur = noop,
  sx,
}) => {
  const [openBottomDrawer, setOpenBottomDrawer] = useState(false);

  const handleButtonClick = (event) => {
    setOpenBottomDrawer(true);
    handleBlur(event);
  };

  return (
    <>
      <Button
        name={fieldName}
        value={fieldValue}
        onClick={handleButtonClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: spacing.s,
          height: styleUtils.pxToRem("47px"),
          width: styleUtils.pxToRem("304px"),
          borderRadius: styleUtils.pxToRem("4px"),
          border: "1px solid #E0E0E0",
          "& .css-1gnd1fd-MuiButton-endIcon": {
            marginLeft: styleUtils.pxToRem("60px"),
          },
          color: fieldValue ? colors.textPrimary : colors.lightGray,
          fontWeight: typography.regular,
          ...sx,
        }}
      >
        {fieldValue ? fieldValue : placeholder}
        <Icon name="select-dropdown" size={25} />
      </Button>
      <Drawer
        anchor="bottom"
        open={openBottomDrawer}
        onClose={() => setOpenBottomDrawer(false)}
        sx={{
          height: styleUtils.pxToRem("260px"),
          width: styleUtils.pxToRem("360x"),
        }}
      >
        <List>
          {dropDownOptions.map((dropDownValue, index) => {
            return (
              <ListItem
                button
                key={index}
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  background: dropDownValue === fieldValue ? colors.selectedBlue : colors.white,
                  borderBottom: index === dropDownOptions.length - 1 ? "none" : "1px solid #E0E0E0",
                }}
              >
                <ListItemButton
                  value={dropDownValue}
                  onClick={() => {
                    setOpenBottomDrawer(false);
                    handleChange(dropDownValue);
                  }}
                >
                  <ListItemText>{dropDownValue}</ListItemText>
                  {dropDownValue === fieldValue && (
                    <ListItemIcon>
                      <Icon name="select-done-option" size={21} />
                    </ListItemIcon>
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

BottomDrawerDropDown.propTypes = {
  fieldValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  dropDownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  sx: PropTypes.object,
};

export default BottomDrawerDropDown;
