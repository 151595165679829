import { useState, useEffect } from "react";
import { keyframes } from "@mui/material/styles";
const useScrollYAnimationResp = () => {
  //tested only for repsonsive
  const [y, setY] = useState(window.scrollY);
  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const hideAnimation = keyframes`
  from{
      opacity : 1;
    }to{
      opacity:0;
  }
`;
  return { y, hideAnimation };
};

export default useScrollYAnimationResp;
