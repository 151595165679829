import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Card, Container, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import Filter1Icon from "@mui/icons-material/Filter1";
import GroupsIcon from "@mui/icons-material/Groups";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import PersonalInformation from "./PersonalInformation";
import Preferences from "./Preferences";
import Referrals from "./Referrals";
import EditPersonalInformation from "./EditPersonalInformation";
import EditPreferences from "./EditPreferences";
import EditJobInterestsContainer from "../EditJobInterestsContainer";

import SettingsContainer from "containers/Account/SettingsContainer";
import LogoutView from "../../../../NavBar/MobileViews/AuthenticatedNav/LogoutView";

import { colors, spacing } from "../../../../../styles";
import { Icon, Text } from "../../../../../components/atoms";
import EditBlockedUsersContainer from "containers/Account/EditBlockedUsersContainer";
import ConnectSocialAccountContainer from "containers/Account/ConnectSocialAccountContainer";
import ChangePasswordContainer from "containers/Account/ChangePasswordContainer";

const ProfileMobileView = ({
  loggedInUser,
  onUploadResume,
  profileType,
  editType,
  onClickMenu,
  handleNavigateHome,
  handleEdit,
  onClickPasswordScreen,
  onUploadPic,
  onClickDeleteAccount,
  socialAccountListData,
  onSuccessGoogleLogin,
  onFailureGoogleLogin,
  onSuccessFacebookLogin,
}) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const hideLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const getRespHeaderValue = useMemo(() => {
  const headerValues = {
    personalinformation: editType
      ? "Edit Personal Information"
      : "Personal Information",
    preferences: editType
      ? editType === "editPref"
        ? "Edit My Preferences"
        : "Edit My Job Interests"
      : "My Preferences",
    referrals: "Invite a friend",
    connectsocial: "Connect Social Accounts",
    settings: editType
      ? editType === "editPassword"
        ? "Change Password"
        : "Blocked Users"
      : "Settings",
  };

  return headerValues[profileType] ?? "Account";
}, [editType, profileType]);

  const getRespDetailsView =
    {
      personalinformation: editType ? (
        <EditPersonalInformation
          userDetails={loggedInUser}
          handleEdit={handleEdit}
        />
      ) : (
        <PersonalInformation
          userDetails={loggedInUser}
          onUploadResume={onUploadResume}
          onUploadPic={onUploadPic}
        />
      ),
      preferences: editType ? (
        editType === "editinter" ? (
          <EditJobInterestsContainer
            userData={loggedInUser}
            handleEditJobInterests={handleEdit}
          />
        ) : (
          <EditPreferences userData={loggedInUser} handleEdit={handleEdit} />
        )
      ) : (
        <Preferences userDetails={loggedInUser} handleEdit={handleEdit} />
      ),
      referrals: <Referrals userDetails={loggedInUser} />,
      connectsocial: (
        <ConnectSocialAccountContainer
          socialAccountListData={socialAccountListData}
          onSuccessGoogleLogin={onSuccessGoogleLogin}
          onFailureGoogleLogin={onFailureGoogleLogin}
          onSuccessFacebookLogin={onSuccessFacebookLogin}
        />
      ),
      settings: editType ? (
        editType === "editPassword" ? (
          <ChangePasswordContainer
            handleEdit={handleEdit}
            onClickPasswordScreen={onClickPasswordScreen}
          />
        ) : (
          <EditBlockedUsersContainer />
        )
      ) : (
        <SettingsContainer
          onClickEditBlockedUsers={handleEdit}
          onClickDeleteAccount={onClickDeleteAccount}
        />
      ),
    }[profileType] ?? <DefaultView showLogoutModal={showLogoutModal} onClickMenu={onClickMenu} />;

  const displayEditIcon = profileType
    ? !editType &&
      (profileType === "personalinformation" || profileType === "preferences")
    : false;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          mt: spacing.m,
          pb: spacing.s,
        }}
      >
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            {profileType && (
              <Icon
                size={24}
                name="arrow-back-black"
                style={{ marginRight: spacing.s, cursor: "pointer" }}
                onClick={() => {
                  editType ? handleEdit(profileType, "") : handleNavigateHome();
                }}
              />
            )}
            <Text size={18} weight="bold">
              {getRespHeaderValue}
            </Text>
          </div>
          {profileType ? (
            displayEditIcon ? (
              <Icon
                name="profile-pencil"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleEdit(
                    profileType,
                    profileType === "personalinformation"
                      ? "editPers"
                      : "editPref"
                  )
                }
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Header>
        <Divider sx={{ mb: spacing.s }} />
        {getRespDetailsView}
      </Card>
      <LogoutView
        islogoutModalOpen={isLogoutModalOpen}
        hideLogoutModal={hideLogoutModal}
      />
    </Container>
  );
};

const DefaultView = React.memo((props) => {
  const {
    onClickMenu,
    showLogoutModal,
  }  = props;
return (
    <>
      <Row onClick={onClickMenu("personalinformation")}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <PersonIcon htmlColor={colors.primary} />
          <Text style={{ marginLeft: spacing.s }} color={colors.black}>
            Personal Information
          </Text>
        </div>
        <Icon size={28} name="profile-next" />
      </Row>

      <Row onClick={onClickMenu("preferences")}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <Filter1Icon htmlColor={colors.primary} />
          <Text style={{ marginLeft: spacing.s }} color={colors.black}>
            My Preferences
          </Text>
        </div>
        <Icon size={28} name="profile-next" />
      </Row>
      <Row onClick={onClickMenu("settings")}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <Icon size={24} name="settings-solid" color={colors.primary} />
          <Text style={{ marginLeft: spacing.s }} color={colors.black}>
            Settings
          </Text>
        </div>
        <Icon size={28} name="profile-next" />
      </Row>
      <Row onClick={onClickMenu("referrals")}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <GroupsIcon htmlColor={colors.primary} />
          <Text style={{ marginLeft: spacing.s }} color={colors.black}>
            Invite a friend
          </Text>
        </div>
        <Icon size={28} name="profile-next" />
      </Row>

      <Row onClick={onClickMenu("connectsocial")} style={{ marginBottom: 0 }}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <ManageAccountsIcon htmlColor={colors.primary} />
          <Text style={{ marginLeft: spacing.s }} color={colors.black}>
            Connect Social Accounts
          </Text>
        </div>
        <Icon size={28} name="profile-next" />
      </Row>
      <Row>
        <MarkunreadIcon htmlColor={colors.primary} />
        <div style={{ marginLeft: spacing.s }}>
          <Text color={colors.black} style={{ marginTop: spacing.m }}>
            Contact Us
          </Text>
          <Text
            size={14}
            color={colors.linkColor}
            onClick={() => {
              window.open("mailto:care@employd.us");
            }}
          >
            care@employd.us
          </Text>
        </div>
      </Row>
      <Row onClick={showLogoutModal}>
        <Icon name="profile-logout" color={colors.primary} />
        <Text style={{ marginLeft: spacing.s }} color={colors.black}>
          Logout
        </Text>
      </Row>
    </>
  );
}
);

ProfileMobileView.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  onUploadResume: PropTypes.func.isRequired,
  profileType: PropTypes.string.isRequired, 
  editType: PropTypes.string, 
  onClickMenu: PropTypes.func.isRequired, 
  handleNavigateHome: PropTypes.func.isRequired, 
  handleEdit: PropTypes.func.isRequired, 
  onClickPasswordScreen: PropTypes.func.isRequired, 
  onUploadPic: PropTypes.func.isRequired,
  onClickDeleteAccount: PropTypes.func.isRequired,
  socialAccountListData: PropTypes.object,
  onSuccessGoogleLogin: PropTypes.func.isRequired,
  onFailureGoogleLogin: PropTypes.func.isRequired,
  onSuccessFacebookLogin: PropTypes.func.isRequired,
};

DefaultView.propTypes = {
  showLogoutModal: PropTypes.func.isRequired, 
};

export default ProfileMobileView;

const Row = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.l};
  cursor: pointer;
  padding: ${spacing.xs} ${spacing.l};
  position: relative;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.s} ${spacing.l};
`;