import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";

import useIsMobile from "../../../../hooks/useIsMobile";
import SignUpSelector from "../../selectors/SignUpSelector";

import EmployerSignUpFormOne from "./views/SignUpForms/EmployerSignUpFormOne";
import EmployerSignUpFormTwo from "./views/SignUpForms/EmployerSignUpFormTwo";
import LoginOtpContainer from "../../../Otp/views/OtpLogin/LoginOtpContainer";
import EditSignUpDetails from "../../../Otp/views/EditSignUpDetails";
import EmailVerificationForm from "../../../Otp/views/EmailVerificationForm";
import PhoneVerificationForm from "../../../Otp/views/PhoneVerificationForm";
import EmployerSignUpDetailsView from "./EmployerSignUpDetailsView";

import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { employerSchemaOne, employerSchemaTwo } from "./employerFormValidationSchema";
import { StepperComponent, Text } from "../../../../components/atoms";

import * as EmployerConsts from "./constants";
import { images } from "../../../../assets/images";
import { colors, spacing, styleUtils } from "../../../../styles";

/**
 * Employer SignUp Form view container
 */
const EmployerSignUpContainer = (props) => {
  const isMobile = useIsMobile();

  const {
    handleEmployerSignUp,
    signUpActionsProp,
    socialSignUpDetails,
    isPhoneSignUp,
    countryCodeValue,
    phoneNumValue,
    handleOtpSubmit,
    handleEmailEditForm,
    handlePhoneEditForm,
    otpReq,
    isLoginByPhn,
  } = props;

  const { idToken, source, loginType, isSocialLogin } = socialSignUpDetails;
  const websiteInputRef = useRef();
  const [formDetails, setFormDetails] = useState({});
  const [signUpStep, setSignUpStep] = useState(1);
  const [imageStatus, setImageStatus] = useState();
  const [retryingImage, setRetryingImage] = useState(false);
  const [editEmailDetails, setEditEmailDetails] = useState(false);
  const [editPhoneDetails, setEditPhoneDetails] = useState(false);
  const [isDetailsEdited, setIsDetailsEdited] = useState(false);
  const [markedCompleted, setMarkAsCompleted] = useState(false);
  const [showValidationIcon, setShowValidationIcon] = useState(false);

  const existingEmpValidations = useSelector(SignUpSelector.getIsExisitngBrand);
  const getGoogleSignupEvent = (isFormOne) =>
    isFormOne ? FirebaseEvents.EMP_GOOGLE_SIGNUP_PAGE1 : FirebaseEvents.EMP_GOOGLE_SIGNUP_PAGE2;

  const getFacebookSignupEvent = (isFormOne) =>
    isFormOne ? FirebaseEvents.EMP_FACEBOOK_SIGNUP_PAGE1 : FirebaseEvents.EMP_FACEBOOK_SIGNUP_PAGE2;

  const getPhoneSignupEvent = (isFormOne) =>
    isFormOne ? FirebaseEvents.EMP_PHONE_SIGNUP_PAGE1 : FirebaseEvents.EMP_PHONE_SIGNUP_PAGE2;

  const getEmailSignupEvent = (isFormOne) =>
    isFormOne ? FirebaseEvents.EMP_EMAIL_SIGNUP_PAGE1 : FirebaseEvents.EMP_EMAIL_SIGNUP_PAGE2;

  const PHONE_OF_EMAIL_SIGNUP_STEP_COUNT = isPhoneSignUp ? 4 : 3;
  const SIGNUP_STEPS_COUNT = isSocialLogin ? 2 : PHONE_OF_EMAIL_SIGNUP_STEP_COUNT;

  const STEPPER_COMPONENT_WIDTH = isPhoneSignUp
    ? isMobile
      ? styleUtils.pxToRem("345px")
      : "75%"
    : "55%";

  const formOneFormik = useFormik({
    initialValues: {
      ...EmployerConsts.EMPLOYER_FORM_ONE_DETAILS,
    },
    validationSchema: employerSchemaOne,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      if ((!retryingImage && imageStatus === "error") || imageStatus === "loading") {
        window.scrollTo(0, 0);
        setRetryingImage(true);
        return;
      }
      let { webError, websiteValue } = await websiteInputRef.current.getWebsiteValue();
      if (webError) {
        return;
      }
      values.website = websiteValue;
      const { isCompanyExist, message } = existingEmpValidations;
      if (!isCompanyExist) {
        handleFormSubmit({ ...formOneFormik.values }, true);
      } else {
        formOneFormik.setErrors({ companyName: message });
      }
    },
  });

  const handleCompanyValidation = () => {
    signUpActionsProp.employerVerifyRequest({
      companyName: formOneFormik.values.companyName.trim(),
      onCallback: handleCompanyCheckCallback,
    });
  };

  const handleCompanyCheckCallback = (params) => {
    setShowValidationIcon(true);
    const { message, isCompanyExist } = params;
    if (isCompanyExist) {
      formOneFormik.setFieldTouched("companyName", true);
      formOneFormik.setFieldError("companyName", message);
    } else {
      formOneFormik.setFieldError("companyName", "");
    }
  };

  const formTwoFormik = useFormik({
    initialValues: {
      ...EmployerConsts.EMPLOYER_FORM_TWO_DETAILS,
    },
    validationSchema: employerSchemaTwo,
    onSubmit: (values) => {
      handleFormSubmit({ ...values, loginType, idToken, source }, false);
    },
  });

  const getPageSubmitEvent = (isFormOne) => {
    if (isSocialLogin && loginType === "GL") {
      return getGoogleSignupEvent(isFormOne);
    }
    if (isSocialLogin && loginType === "FL") {
      return getFacebookSignupEvent(isFormOne);
    }
    if (isPhoneSignUp) {
      return getPhoneSignupEvent(isFormOne);
    }

    return getEmailSignupEvent(isFormOne);
  };

  const handleFormSubmit = (formValues, isFormOne) => {
    if (isFormOne) {
      setFormDetails(formValues);
      handleStepperChange(true);
    } else {
      handleEmployerSignUp({ ...formDetails, ...formValues });
      if (!isSocialLogin) {
        handleStepperChange(true);
      }
    }
    logEvent(getPageSubmitEvent(isFormOne));
  };

  const handleStepperChange = (isNext) => {
    if (isNext) {
      setSignUpStep(signUpStep + 1);
    } else {
      setSignUpStep(signUpStep - 1);
    }
  };

  const handleImageStatus = (status) => {
    setImageStatus(status);
  };

  const handleEmailEditNav = (isSave, values) => {
    if (isSave) {
      handleEmailEditForm(values);
      formOneFormik.setFieldValue("email", values.email);
      setEditEmailDetails(false);
    } else {
      setEditEmailDetails(false);
    }
  };

  const handlePhoneEditNav = (isSave, values) => {
    if (isSave) {
      handlePhoneEditForm(values);
      formOneFormik.setFieldValue("countryCode", countryCodeValue);
      formOneFormik.setFieldValue("phoneNumber", phoneNumValue);
      setEditPhoneDetails(false);
    } else {
      setEditPhoneDetails(false);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={6}>
        {editEmailDetails || editPhoneDetails ? (
          <EditSignUpDetails
            details={otpReq}
            isEmployer={true}
            isEmailEdit={editEmailDetails}
            handleEditNav={editEmailDetails ? handleEmailEditNav : handlePhoneEditNav}
          />
        ) : (
          <EmployerContainer
            sx={{
              padding: isMobile ? spacing.s : spacing.xxxl,
            }}
          >
            {!isLoginByPhn && (
              <Text
                size="xl"
                weight="semibold"
                color={colors.titleBlack}
                sx={{
                  marginTop: spacing.xs,
                }}
              >
                {EmployerConsts.EMPLOYER_FORM_SIGN_UP_LABEL}
              </Text>
            )}
            {isLoginByPhn ? (
              <LoginOtpContainer />
            ) : (
              <>
                <StepperComponent
                  stepsCount={SIGNUP_STEPS_COUNT}
                  activeStep={signUpStep}
                  markComplete={markedCompleted}
                  sx={{
                    mt: spacing.m,
                    mb: spacing.xl,
                    width: STEPPER_COMPONENT_WIDTH,
                  }}
                />
                {
                  {
                    1: (
                      <EmployerSignUpFormOne
                        formik={formOneFormik}
                        imageStatus={imageStatus}
                        isPhoneSignUp={isPhoneSignUp}
                        countryCodeValue={countryCodeValue}
                        phoneNumValue={phoneNumValue}
                        handleImageStatus={handleImageStatus}
                        handleCompanyValidation={handleCompanyValidation}
                        socialSignUpDetails={socialSignUpDetails}
                        websiteInputRef={websiteInputRef}
                        existingEmpValidations={existingEmpValidations}
                        showValidationIcon={showValidationIcon}
                        setShowValidationIcon={setShowValidationIcon}
                      />
                    ),
                    2: (
                      <EmployerSignUpFormTwo
                        formik={formTwoFormik}
                        handleBack={handleStepperChange}
                        socialSignUpDetails={socialSignUpDetails}
                      />
                    ),
                    3: !socialSignUpDetails?.isSocialLogin && (
                      <EmailVerificationForm
                        editEmail={setEditEmailDetails}
                        handleBack={handleStepperChange}
                        handleOtpSubmit={handleOtpSubmit}
                        isDetailsEdited={isDetailsEdited}
                        isPhoneSignUp={isPhoneSignUp}
                        setIsDetailsEdited={setIsDetailsEdited}
                        setMarkAsCompleted={setMarkAsCompleted}
                        sourceDetails={otpReq}
                      />
                    ),
                    4: !socialSignUpDetails?.isSocialLogin && isPhoneSignUp && (
                      <PhoneVerificationForm
                        editPhone={setEditPhoneDetails}
                        handleBack={handleStepperChange}
                        handleOtpSubmit={handleOtpSubmit}
                        isDetailsEdited={isDetailsEdited}
                        setIsDetailsEdited={setIsDetailsEdited}
                        setMarkAsCompleted={setMarkAsCompleted}
                        sourceDetails={otpReq}
                      />
                    ),
                  }[signUpStep]
                }
              </>
            )}
          </EmployerContainer>
        )}
      </Grid>

      {!isMobile && (
        <>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} sm container>
            <EmployerSignUpDetailsView />
            <ImgContainer src={images.imgemployersignup} signUpStep={signUpStep} />
          </Grid>
        </>
      )}
    </>
  );
};

EmployerSignUpContainer.propTypes = {
  countryCodeValue: PropTypes.string.isRequired,
  isLoginByPhn: PropTypes.bool,
  isPhoneSignUp: PropTypes.bool,
  otpReq: PropTypes.object,
  phoneNumValue: PropTypes.string.isRequired,
  signUpActionsProp: PropTypes.object,
  socialSignUpDetails: PropTypes.object,
  handleEmailEditForm: PropTypes.func,
  handleEmployerSignUp: PropTypes.func.isRequired,
  handleOtpSubmit: PropTypes.func.isRequired,
  handlePhoneEditForm: PropTypes.func.isRequired,
};

export default EmployerSignUpContainer;

const EmployerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ImgContainer = styled("img")`
  display: block;
  align-self: center;
  margin-left: ${styleUtils.pxToRem("105.5px")};
  height: ${styleUtils.pxToRem("251px")};
  width: ${styleUtils.pxToRem("225px")};
  margintop: ${({ signUpStep }) => styleUtils.pxToRem(signUpStep > 2 ? "60px" : "173px")};
  marginbottom: ${({ signUpStep }) => styleUtils.pxToRem(signUpStep > 2 ? "55px" : "160px")};
`;
