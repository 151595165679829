import React, { Suspense, lazy, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import "react-quill/dist/quill.snow.css";

import { colors, styleUtils } from "../../../styles";

import "./styles.css";
import noop from "lodash/noop";
import DividerWithText from "../DividerWithText";
import useIsMobile from "../../../hooks/useIsMobile";

const ReactQuill = lazy(() => import("react-quill"));

const CustomToolbar = ({ isMobile }) => (
  <ToolBarContainer isMobile={isMobile} id="toolbar">
    <span className="ql-formats">
      <select className="ql-font"></select>
      <select className="ql-size"></select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
    </span>
    <span className="ql-formats">
      <select className="ql-color"></select>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-indent" value="-1"></button>
      <button className="ql-indent" value="+1"></button>
    </span>
    <span className="ql-formats">
      <select className="ql-align"></select>
    </span>
  </ToolBarContainer>
);

const ReactQuillModules = {
  toolbar: {
    container: "#toolbar",
  },
};

const RichTextEditor = ({
  renderAIToolbar = noop,
  editorRef,
  placeholder,
  handleChange,
  editorHtml,
  isDisabled,
}) => {
  const reactQuillRef = useRef();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (editorRef) {
      editorRef.current = reactQuillRef.current?.getEditor();
    }
  }, [reactQuillRef.current]);

  return (
    <RichTextContainer
      sx={{
        "&:hover": {
          border: `1px solid ${colors.secondary}`,
        },
      }}
    >
      {renderAIToolbar()}
      <div>
        <DividerWithText size="s" />
        <div
          className="text-editor"
          style={{
            height: styleUtils.pxToRem("720px"),
          }}
        >
          <Suspense fallback={null}>
            <ReactQuill
              ref={reactQuillRef}
              value={editorHtml}
              onChange={handleChange}
              placeholder={placeholder}
              modules={ReactQuillModules}
              readOnly={isDisabled}
              style={{ height: "85%" }}
            />
          </Suspense>
        </div>
        <CustomToolbar isMobile={isMobile} />
      </div>
    </RichTextContainer>
  );
};

CustomToolbar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default React.memo(RichTextEditor);

const RichTextContainer = styled("div")`
  box-shadow: 0px 5px 15px ${colors.shadow};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: ${colors.white};
  position: relative;
`;

const ToolBarContainer = styled("div")`
  border-radius: 5px;
  box-shadow: 0px 21px 24px -11px rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.isMobile ? "90%" : "60%")};
  position: absolute;
  bottom: 10px;
  left: 20px;
  background: ${colors.white};
`;
