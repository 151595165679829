import PropTypes from "prop-types";
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";

import { colors, spacing, textSizes, typography, styleUtils } from "../../../../../styles";
import { Icon } from "../../../../../components/atoms";
import * as FormConstants from "../constants";

const EditJobInterests = ({
  allJobRoles,
  formik,
  handleDeleteInterests,
  handleEditJobInterests,
  setInterests,
  topJobRoles,
}) => {
  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flex: 1, my: spacing.m }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={{ px: spacing.xs, mx: spacing.xxl }}
        >
          <FormControl
            required
            variant="standard"
            sx={{ marginTop: styleUtils.pxToRem("10px") }}
          >
            <Autocomplete
              disablePortal
              forcePopupIcon={false}
              filterSelectedOptions={true}
              options={allJobRoles}
              onChange={(event, newValue) => {
                if (newValue) {
                  setInterests(newValue);
                } else {
                  //No code here
                }
              }}
              clearOnEscape={true}
              ListboxProps={{ style: { maxHeight: "11.85rem" } }}
              renderInput={(params) => (
                <TextField
                  placeholder={FormConstants.JOBSEEKER_INTERESTS_HELP_TEXT}
                  {...params}
                  sx={{
                    width: styleUtils.pxToRem("432px"),
                    height: styleUtils.pxToRem("42px"),
                  }}
                />
              )}
              sx={{
                width: styleUtils.pxToRem("432px"),
                height: styleUtils.pxToRem("42px"),
                borderColor: "#E0E0E0",
                "& .MuiOutlinedInput-root": {
                  padding: "0px 0px 0px 5px",
                },
                "& .MuiOutlinedInput-root:hover": {
                  borderColor: "#E0E0E0",
                },
                "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                  borderBottom: "1px solid #E0E0E0",
                },
                "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
                  backgroundColor: colors.selectedBlue,
                },
              }}
            />
            {Boolean(formik.errors.job_interests) && (
              <FormHelperText className="profile-form-error-field">
                {formik.errors.job_interests}
              </FormHelperText>
            )}
          </FormControl>
          <InterestsFlexBox sx={{ marginTop: spacing.l }}>
            {formik?.values?.job_interests?.map((jobRole, index) => {
              return (
                <Chip
                  label={jobRole}
                  key={index}
                  variant="contained"
                  deleteIcon={
                    <Icon
                      size={15}
                      name="close-white"
                    />
                  }
                  onDelete={() => {
                    handleDeleteInterests(jobRole);
                  }}
                  sx={{
                    height: 34,
                    margin: "0 0.75rem 0.5rem 0",
                    backgroundColor: "#2C4895",
                    borderRadius: styleUtils.pxToRem("17px"),
                    "& .MuiChip-label": {
                      fontSize: textSizes.s,
                      fontWeight: typography.medium,
                      color: colors.white,
                    },
                    border: "1px solid #2C4895",
                  }}
                />
              );
            })}
          </InterestsFlexBox>
          <InterestsFlexBox sx={{ marginTop: spacing.xl }}>
            {topJobRoles.map((jobRole, index) => {
              if (!formik?.values?.job_interests?.includes(jobRole)) {
                return (
                  <Chip
                    label={jobRole}
                    key={index}
                    variant="outlined"
                    onClick={() => setInterests(jobRole)}
                    sx={{
                      height: 34,
                      margin: "0 0.75rem 0.75rem 0",
                      borderRadius: styleUtils.pxToRem("17px"),
                      "& .MuiChip-label": {
                        fontSize: textSizes.s,
                        fontWeight: typography.medium,
                        color: "#2C4895",
                      },
                      border: "1px solid #2C4895",
                      "& .MuiChip-clickable:hover": {
                        backgroundColor: colors.white,
                      },
                    }}
                  />
                );
              }
              return null;
            })}
          </InterestsFlexBox>
        </Grid>
      </Grid>
      <Divider
        flexItem
        sx={{ mt: spacing.s }}
      />
      <Footer sx={{ justifyContent: "flex-end" }}>
        <ActionButton
          variant="outlined"
          onClick={() => handleEditJobInterests("preferences", "")}
        >
          {FormConstants.CANCEL}
        </ActionButton>
        <ActionButton
          variant="contained"
          onClick={formik.handleSubmit}
          disabled={!formik.dirty}
        >
          {FormConstants.SAVE}
        </ActionButton>
      </Footer>
    </>
  );
};

EditJobInterests.propTypes = {
  allJobRoles: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
  handleDeleteInterests: PropTypes.func.isRequired,
  handleEditJobInterests: PropTypes.func.isRequired,
  setInterests: PropTypes.func.isRequired,
  topJobRoles: PropTypes.array.isRequired,
};

export default EditJobInterests;

const InterestsFlexBox = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const Footer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
  padding: ${spacing.s} ${spacing.l};
`;

const ActionButton = styled(Button)`
  font-size: 1rem;
  margin-right: ${spacing.s};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("180px")};
`;
