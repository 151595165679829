import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useIsMobile from "../../../../../../hooks/useIsMobile";

import FormDropDownContainer from "../FormDropdown/FormDropDownContainer";
import { styled } from "@mui/material/styles";
// import { isEmpty } from "../../../../../../utils/commonUtils";
import { TextInput, Icon } from "../../../../../../components/atoms";
import { colors, textSizes, styleUtils } from "../../../../../../styles";

import { EMPLOYER_SIGNUP_FORM_TWO } from "../../constants";

/**
 * Employer SignUp Form Two view component
 */
const EmployerSignUpFormTwo = (props) => {
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const isMobile = useIsMobile();

  const {
    formik,
    handleBack,
    socialSignUpDetails: { isSocialLogin, idToken },
  } = props;

  const scrollToTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    scrollToTop();
    if (isSocialLogin) {
      formik.setFieldValue("password", idToken);
      formik.setFieldValue("confirmPassword", idToken);
    }
  }, []);

  const handleEmpSizeChange = (targetValue) => {
    formik.setFieldValue("employee_size", targetValue);
  };

  const handleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  const handleShowConfirmPwd = () => {
    setShowConfirmPwd(!showConfirmPwd);
  };

  const handleDesignationSelection = (targetValue) => {
    formik.setFieldValue("designation", targetValue);
  };

  const handleMultiLocationSelection = (targetValue) => {
    formik.setFieldValue(
      "multiple_location",
      targetValue === EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS[0],
    );
  };

  return (
    <React.Fragment>
      <FormControl
        size="small"
        required
        sx={{ marginTop: styleUtils.pxToRem("24px") }}
      >
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_TWO.LABEL_EMPLOYEESIZE}
        </FormLabel>
        <FormDropDownContainer
          fieldValue={formik.values.employee_size}
          fieldName="employee_size"
          widthValue={491}
          handleChange={handleEmpSizeChange}
          handleBlur={formik.handleBlur}
          dropDownOptions={EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_SIZE_OPTIONS}
          placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_EMPLOYEESIZE}
        />
        {formik.touched.employee_size && Boolean(formik.errors.employee_size) && (
          <EmployerFormHelperText>{formik.errors.employee_size}</EmployerFormHelperText>
        )}
      </FormControl>
      <FormControl
        size="small"
        required
        sx={{ marginTop: styleUtils.pxToRem("24px") }}
      >
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_TWO.LABEL_DESIGNATION}
        </FormLabel>
        <FormDropDownContainer
          fieldValue={formik.values.designation}
          fieldName="designation"
          widthValue={491}
          handleChange={handleDesignationSelection}
          handleBlur={formik.handleBlur}
          dropDownOptions={EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_DESIGNATION_OPTIONS}
          placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_DESIGNATION}
        />
        {formik.touched.designation && Boolean(formik.errors.designation) && (
          <EmployerFormHelperText>{formik.errors.designation}</EmployerFormHelperText>
        )}
      </FormControl>
      <>
        {formik.values.designation === "Other" && (
          <TextInput
            name="designationText"
            value={formik.values.designationText}
            onChange={formik.handleChange}
            error={formik.touched.designationText && Boolean(formik.errors.designationText)}
            placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_OTHER_DESIGNATION}
            sx={{
              height: styleUtils.pxToRem("42px"),
              marginTop: styleUtils.pxToRem("8px"),
              border: "1px solid #E0E0E0",
              borderRadius: styleUtils.pxToRem("4px"),
            }}
          />
        )}
        {Boolean(formik.errors.designationText) && (
          <EmployerFormHelperText>{formik.errors.designationText}</EmployerFormHelperText>
        )}
      </>
      <FormControl
        size="small"
        required
        sx={{ marginTop: styleUtils.pxToRem("24px") }}
      >
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_TWO.LABEL_MULTI_LOCATIONS}
        </FormLabel>
        <FormDropDownContainer
          fieldValue={
            formik.values.multiple_location !== null
              ? formik.values.multiple_location
                ? EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS[0]
                : EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS[1]
              : ""
          }
          fieldName="multiple_location"
          widthValue={491}
          handleChange={handleMultiLocationSelection}
          handleBlur={formik.handleBlur}
          dropDownOptions={EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS}
          placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_MULTI_LOCATIONS}
        />
        {formik.touched.multiple_location && Boolean(formik.errors.multiple_location) && (
          <EmployerFormHelperText>{formik.errors.multiple_location}</EmployerFormHelperText>
        )}
      </FormControl>

      {!isSocialLogin && (
        <>
          <FormControl
            variant="standard"
            required
            sx={{ marginTop: styleUtils.pxToRem("24px") }}
          >
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {EMPLOYER_SIGNUP_FORM_TWO.LABEL_PASSWORD}
            </FormLabel>
            <EmployerFormInput
              name="password"
              type={showPwd ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPwd}
                    sx={{ cursor: "pointer" }}
                  >
                    {showPwd ? (
                      <Visibility sx={{ color: "#848484" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#848484" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_PASSWORD}
            />
            {formik.touched.password && Boolean(formik.errors.password) && (
              <EmployerFormHelperText>{formik.errors.password}</EmployerFormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="standard"
            required
            sx={{ marginTop: styleUtils.pxToRem("24px") }}
          >
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {EMPLOYER_SIGNUP_FORM_TWO.LABEL_CONFIRM_PASSWORD}
            </FormLabel>
            <EmployerFormInput
              name="confirmPassword"
              type={showConfirmPwd ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowConfirmPwd}
                    sx={{ cursor: "pointer" }}
                  >
                    {showPwd ? (
                      <Visibility sx={{ color: "#848484" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#848484" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_PASSWORD}
            />
            {formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword) && (
              <EmployerFormHelperText>{formik.errors.confirmPassword}</EmployerFormHelperText>
            )}
          </FormControl>
        </>
      )}
      <FormsBtnContainer
        sx={{
          marginTop: isSocialLogin ? styleUtils.pxToRem("349px") : styleUtils.pxToRem("129px"),
        }}
      >
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => handleBack(false)}
        />
        <Button
          variant="contained"
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
          onClick={formik.handleSubmit}
        >
          {EMPLOYER_SIGNUP_FORM_TWO.BUTTON_NEXT}
        </Button>
      </FormsBtnContainer>
    </React.Fragment>
  );
};

EmployerSignUpFormTwo.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      employee_size: PropTypes.string,
      designation: PropTypes.string,
      designationText: PropTypes.string,
      multiple_location: PropTypes.bool,
      password: PropTypes.string,
      confirmPassword: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.shape({
      employee_size: PropTypes.bool,
      designation: PropTypes.bool,
      designationText: PropTypes.bool,
      multiple_location: PropTypes.bool,
      password: PropTypes.bool,
      confirmPassword: PropTypes.bool,
    }),
    errors: PropTypes.shape({
      employee_size: PropTypes.string,
      designation: PropTypes.string,
      designationText: PropTypes.string,
      multiple_location: PropTypes.string,
      password: PropTypes.string,
      confirmPassword: PropTypes.string,
    }),
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  handleBack: PropTypes.func.isRequired,
  socialSignUpDetails: PropTypes.shape({
    isSocialLogin: PropTypes.bool.isRequired,
    idToken: PropTypes.string.isRequired,
  }).isRequired,
};

export default EmployerSignUpFormTwo;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const EmployerFormInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const EmployerFormHelperText = styled(FormHelperText)`
  color: #ff0000;
`;
