import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, Container, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import Filter1Icon from "@mui/icons-material/Filter1";
import GroupsIcon from "@mui/icons-material/Groups";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import EditPersonalInformation from "../EditPersonalInformation";
import EditPreferences from "../EditPreferences";
import EditJobInterestsContainer from "../EditJobInterestsContainer";
import PersonalInformation from "./PersonalInformation";
import Preferences from "./Preferences";
import Referrals from "./Referrals";

import { Icon, Text } from "../../../../../components/atoms";
import { colors, spacing } from "../../../../../styles";
import SettingsContainer from "containers/Account/SettingsContainer";
import EditBlockedUsersContainer from "containers/Account/EditBlockedUsersContainer";
import ConnectSocialAccountContainer from "containers/Account/ConnectSocialAccountContainer";

const Profile = (props) => {
  const { loggedInUser, profileType, editType, handleEdit } = props;

  const getWebHeaderValue = useMemo(() => {
    const headerValues = {
      personalinformation: editType ? "Edit Personal Information" : "My Profile",
      preferences: editType
        ? editType === "editPref"
          ? "Edit My Preferences"
          : "Edit My Job Interests"
        : "My Profile",
      settings: editType ? "Blocked Users" : "My Profile",
    };

    return headerValues[profileType] ?? "My Profile";
  }, [editType, profileType]);

  let content = {
    editPers: <EditPersonalInformation userDetails={loggedInUser} handleEdit={handleEdit} />,
    editPref: <EditPreferences userData={loggedInUser} handleEdit={handleEdit} />,
    editinter: (
      <EditJobInterestsContainer userData={loggedInUser} handleEditJobInterests={handleEdit} />
    ),
    editBlocked: <EditBlockedUsersContainer />,
  }[editType] ?? <OptionsView {...props} />;

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Card
        sx={{
          minHeight: `calc(100vh - 64px - 2 * ${spacing.xxxxl})`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header>
          {editType && (
            <Icon
              name="arrow-back-black"
              size={24}
              onClick={() => handleEdit(profileType, "")}
              style={{
                cursor: "pointer",
                marginRight: spacing.l,
                fill: "#000000",
              }}
            />
          )}
          <Text size={22} weight="bold">
            {getWebHeaderValue}
          </Text>
        </Header>
        <Divider />
        {content}
      </Card>
    </Container>
  );
};

const OptionsView = React.memo((props) => {
  const {
    loggedInUser,
    profileType,
    onUploadPic,
    onClickDeleteAccount,
    onClickPasswordScreen,
    onClickMenu,
    handleEdit,
    socialAccountListData,
    onSuccessGoogleLogin,
    onFailureGoogleLogin,
    onSuccessFacebookLogin,
    onUploadResume,
  } = props;

  const isPersonalInformationProfileType = !profileType || profileType === "personalinformation";
  // TO-DO: Need to add constants for profileType values
  return (
    <Grid container sx={{ display: "flex", flex: 1, flexWrap: "nowrap", my: spacing.m }}>
      <Grid item sx={{ py: spacing.xl }}>
        <Row onClick={onClickMenu("personalinformation")}>
          {isPersonalInformationProfileType && <Line />}
          <PersonIcon
            htmlColor={isPersonalInformationProfileType ? colors.primary : colors.black}
          />
          <Text
            size={20}
            style={{ marginLeft: spacing.s }}
            color={isPersonalInformationProfileType ? colors.textPrimary : colors.black}
          >
            Personal Information
          </Text>
        </Row>
        <Row onClick={onClickMenu("preferences")}>
          {profileType === "preferences" && <Line />}
          <Filter1Icon htmlColor={profileType === "preferences" ? colors.primary : colors.black} />
          <Text
            size={20}
            style={{ marginLeft: spacing.s }}
            color={profileType === "preferences" ? colors.textPrimary : colors.black}
          >
            My Preferences
          </Text>
        </Row>

        <Row onClick={onClickMenu("settings")}>
          {profileType === "settings" && <Line />}
          <Icon
            size={24}
            name="settings-solid"
            color={profileType === "settings" ? colors.primary : colors.black}
          />
          <Text
            size={20}
            style={{ marginLeft: spacing.s }}
            color={profileType === "settings" ? colors.textPrimary : colors.black}
          >
            Settings
          </Text>
        </Row>

        <Row onClick={onClickMenu("referrals")}>
          {profileType === "referrals" && <Line />}
          <GroupsIcon htmlColor={profileType === "referrals" ? colors.primary : colors.black} />
          <Text
            size={20}
            style={{ marginLeft: spacing.s }}
            color={profileType === "referrals" ? colors.textPrimary : colors.black}
          >
            Invite a friend
          </Text>
        </Row>
        <Row onClick={onClickMenu("connectsocial")} style={{ marginBottom: 0 }}>
          {profileType === "connectsocial" && <Line />}
          <ManageAccountsIcon
            htmlColor={profileType === "connectsocial" ? colors.primary : colors.black}
          />
          <Text
            size={20}
            style={{ marginLeft: spacing.s }}
            color={profileType === "connectsocial" ? colors.textPrimary : colors.black}
          >
            Connect Social Accounts
          </Text>
        </Row>
        <Row>
          <MarkunreadIcon htmlColor={colors.black} />
          <div style={{ marginLeft: spacing.s }}>
            <Text size={20} color={colors.black} style={{ marginTop: spacing.m }}>
              Contact Us
            </Text>
            <Text
              size={14}
              color={colors.linkColor}
              onClick={() => {
                window.open("mailto:care@employd.us");
              }}
            >
              care@employd.us
            </Text>
          </div>
        </Row>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item md>
        {isPersonalInformationProfileType && (
          <PersonalInformation
            userDetails={loggedInUser}
            onUploadResume={onUploadResume}
            onUploadPic={onUploadPic}
            onClickEditPersonalInformation={handleEdit}
          />
        )}
        {profileType === "preferences" && (
          <Preferences userDetails={loggedInUser} onClickEditPreferences={handleEdit} />
        )}
        {profileType === "settings" && (
          <SettingsContainer
            onClickEditBlockedUsers={handleEdit}
            onClickDeleteAccount={onClickDeleteAccount}
            onClickPasswordScreen={onClickPasswordScreen}
          />
        )}
        {profileType === "referrals" && <Referrals userDetails={loggedInUser} />}
        {profileType === "connectsocial" && (
          <ConnectSocialAccountContainer
            socialAccountListData={socialAccountListData}
            onSuccessGoogleLogin={onSuccessGoogleLogin}
            onFailureGoogleLogin={onFailureGoogleLogin}
            onSuccessFacebookLogin={onSuccessFacebookLogin}
          />
        )}
      </Grid>
    </Grid>
  );
});

Profile.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  profileType: PropTypes.string,
  editType: PropTypes.string,
  handleEdit: PropTypes.func.isRequired,
};

export default Profile;

const Row = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.l};
  cursor: pointer;
  padding: ${spacing.xs} ${spacing.l};
  position: relative;
`;

const Line = styled("div")`
  height: 100%;
  width: 6px;
  background-color: ${colors.primary};
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  padding: ${spacing.s} ${spacing.l};
`;
