const getEmployerListing = (state) => {
  const { employerListing } = state.employerState;
  return employerListing;
};

const getEmployerJobStatusById = (id) => (state) => {
  const { employerListing } = state.employerState;
  const currJobWithId = employerListing.filter((job) => job.jobId === id)[0];
  return currJobWithId?.status || null;
};

const getEmployerJobSeekersListTotal = (state) => {
  const { employerJobSeekerListCount } = state.employerState;
  return employerJobSeekerListCount;
};

const getPageLimitEmployerJobSeekerListing = (state) => {
  const { employerJobSeekerListingPageLimit } = state.employerState;
  return employerJobSeekerListingPageLimit;
};

const getEmployerJobSeekerListing = (state) => {
  const { employerJobSeekerListing } = state.employerState;
  return employerJobSeekerListing;
};

const getStarredEmployerJobSeekerListing = (state) => {
  const { employerJobSeekerListing } = state.employerState;
  const {
    user: { user },
  } = state.loginState;
  return employerJobSeekerListing.filter(
    (item) => item.starredBy && item.starredBy.includes(user.uid)
  );
};

const getForceUpdateEmployerJobSeekerListing = (state) => {
  const { forceUpdate } = state.employerState;
  return forceUpdate;
};

const getIsEmployerSignUp = (state) => {
  const { isEmployerSignUpSuccess } = state.signUpState;
  return isEmployerSignUpSuccess;
};

const isEmployerJobListLoading = (state) => {
  const {
    employerState: { employerListingLoading, employerListingSuccess },
  } = state;
  return employerListingLoading && !employerListingSuccess;
};

const isEmployerPeopleListLoading = (state) => {
  const {
    employerState: { employerJobSeekerListingLoading },
  } = state;
  return employerJobSeekerListingLoading;
};

const isEmployerPeopleListSuccess = (state) => {
  const {
    employerState: { employerJobSeekerListingSuccess },
  } = state;
  return employerJobSeekerListingSuccess;
};

const isEmployerNotPostedJob = (state) => {
  const {
    employerState: { employerListingSuccess, employerListing },
    loginState: { isLoginSuccess },
  } = state;
  return (
    isLoginSuccess && employerListingSuccess && employerListing?.length === 0
  );
};

const getSchedulerUserDetails = (state) => {
  const {
    employerState: { schedulerUserDetails },
  } = state;
  return schedulerUserDetails;
};

const getSchedulerAvailabilityList = (state) => {
  const {
    employerState: { schedulerAvailableList },
  } = state;
  return schedulerAvailableList;
};

const getSchedulerUpcomingList = (state) => {
  const {
    employerState: { schedulerUpcomingMeetingList },
  } = state;
  return schedulerUpcomingMeetingList;
};

const getSchedulerShareLink = (state) => {
  const {
    employerState: { schedulerShareLink },
  } = state;
  return schedulerShareLink;
};

const getEmployerFirstJobStartTime = (state) => {
  const {
    employerState: { employerFirstJobStartTime },
  } = state;
  return employerFirstJobStartTime;
};

const EmployerSelector = {
  getEmployerListing,
  getEmployerJobSeekerListing,
  getStarredEmployerJobSeekerListing,
  getForceUpdateEmployerJobSeekerListing,
  getIsEmployerSignUp,
  isEmployerJobListLoading,
  isEmployerPeopleListLoading,
  isEmployerPeopleListSuccess,
  getPageLimitEmployerJobSeekerListing,
  getEmployerJobSeekersListTotal,
  isEmployerNotPostedJob,
  getEmployerJobStatusById,
  //Scheduler
  getSchedulerUserDetails,
  getSchedulerAvailabilityList,
  getSchedulerUpcomingList,
  getSchedulerShareLink,
  //First job modal timer
  getEmployerFirstJobStartTime,
};

export default EmployerSelector;
