import React from "react";
import { styled } from "@mui/material/styles";
import copy from "copy-to-clipboard";

import { getReferralLink } from "../../../../../configs/network/apis";
import { Icon, Text } from "../../../../../components/atoms";
import { images } from "../../../../../assets/images";
import { colors, spacing } from "../../../../../styles";
import { logEvent } from "../../../../../configs/firebase";

import { showNotification } from "../../../../../utils/commonUtils";
import { useQuery } from "react-query";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const Referrals = () => {
  const { data } = useQuery("referral", async () =>
    getReferralLink({
      source: "web",
    }),
  );
  const onClickCopy = () => {
    copy(data?.data?.referralLink?.shortLink);
    logEvent(FirebaseEvents.JS_CLICK_COPYANDSHARE_REFERRAL_LINK);
    showNotification({
      alertProps: {
        severity: "info",
        children: "Link copied to clipboard.",
      },
    });
  };

  return (
    <TopContainer>
      <Text size={24} weight="semibold">
        Invite your friends to EmployD
      </Text>
      <Text style={{ marginTop: spacing.s }} color={colors.placeholder}>
        Refer a friend and you both earn $10 when your friend
      </Text>
      <Text color={colors.placeholder}>creates a complete profile</Text>
      <Text size="l" weight="semibold" style={{ marginTop: spacing.xl }}>
        Share this referral link
      </Text>
      <LinkWrapper sx={{ zIndex: 999 }}>
        <Text size="l" style={{ marginRight: spacing.xxxxl }}>
          {data?.data?.referralLink?.shortLink}
        </Text>
        <Icon size={28} onClick={onClickCopy} name="profile-copy" style={{ cursor: "pointer" }} />
      </LinkWrapper>
      <img
        style={{
          width: "50%",
          position: "absolute",
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
        src={images["referral-banner"]}
        alt="Referral banner"
      />
    </TopContainer>
  );
};
export default Referrals;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${spacing.xl};
  height: 100%;
`;

const LinkWrapper = styled("div")`
  border-radius: 5px;
  border: 1px solid #e6edff;
  padding: ${spacing.s} ${spacing.s};
  margin-top: ${spacing.xs};
  display: flex;
  align-items: center;
  width: fit-content;
`;
