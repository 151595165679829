import { Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropTypes from "prop-types";

import { spacing, styleUtils, textSizes, colors } from "../../../styles";
import { Icon } from "../../atoms";
import { useSelector } from "react-redux";
import LoginSelector from "containers/Login/selectors/LoginSelector";

const JobDetailCardSkeleton = ({ isMobile }) => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);

  const jobDetailPointerRows = (
    <Row>
      <Skeleton circle={true} height={31} width={31} />
      <InfoBox>
        <Skeleton width={100} style={{ borderRadius: 50, height: 10 }} />
        <Skeleton width={isMobile ? 200 : 80} style={{ borderRadius: 50, height: 10 }} />
      </InfoBox>
    </Row>
  );

  const jobDetailPointers = (
    <>
      <Grid item xs sm md>
        <LeftJobDetailPointers isMobile={isMobile}>
          {jobDetailPointerRows}
          {jobDetailPointerRows}
          {jobDetailPointerRows}
        </LeftJobDetailPointers>
      </Grid>
      {!isMobile && (
        <Grid item xs={0}>
          <Divider orientation="vertical" />
        </Grid>
      )}
      <Grid item xs sm md>
        <RightJobDetailPointers isMobile={isMobile}>
          {jobDetailPointerRows}
          {jobDetailPointerRows}
          {jobDetailPointerRows}
        </RightJobDetailPointers>
      </Grid>
    </>
  );

  const applyButtonSkeleton = (
    <Skeleton
      style={{
        borderRadius: 100,
        padding: `${spacing.s} 125px`,
      }}
    />
  );

  const shareJobIconSkeleton = (
    <Icon name="shareJob" size={42} style={{ marginLeft: spacing.l }} color={colors.shimmerGray} />
  );

  const applyButton = isMobile ? (
    <ApplyRespContainer>
      <div style={{ flex: 1, marginLeft: 20 }}>{applyButtonSkeleton}</div>
      {shareJobIconSkeleton}
    </ApplyRespContainer>
  ) : (
    <ApplyWebContainer>
      {applyButtonSkeleton}
      {shareJobIconSkeleton}
    </ApplyWebContainer>
  );

  const jobDetailSections = (
    <>
      <Skeleton
        style={{
          marginTop: spacing.s,
          marginBottom: spacing.xs,
          width: "20%",
          borderRadius: 50,
        }}
      />
      <Skeleton
        count={2}
        style={{
          lineHeight: "1.5",
          width: "100%",
          height: 10,
          borderRadius: 50,
        }}
      />
    </>
  );

  return (
    <SkeletonTheme
      baseColor={colors.shimmerBaseColor}
      highlightColor={colors.shimmerHighlightColor}
    >
      <TopContainer isMobile={isMobile}>
        <GridContainer
          container
          sx={{
            height: `${styleUtils.pxToRem("146px")}`,
            alignItems: "center",
          }}
        >
          <Grid item xs sm md>
            <TopContainer
              sx={{
                paddingLeft: isMobile ? "" : spacing.xxl,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Skeleton
                circle={true}
                style={{
                  width: 57,
                  height: 57,
                  borderRadius: 57 / 2,
                }}
              />
              <TitleContainer height="auto" sx={{ marginLeft: isMobile ? "" : spacing.s }}>
                <Skeleton
                  count={3}
                  width={100}
                  style={{ borderRadius: 50, height: 10, marginTop: 8 }}
                />
              </TitleContainer>
            </TopContainer>
          </Grid>
          <div style={{ display: "flex", position: "relative" }}>
            <Skeleton
              circle={true}
              style={{
                width: 80,
                height: 80,
                borderRadius: 80 / 2,
                marginRight: isMobile ? 20 : 57,
              }}
              baseColor={colors.white}
              highlightColor={colors.white}
            />

            <Skeleton
              width={50}
              style={{
                position: "absolute",
                left: 15,
                top: 37,
                height: 10,
              }}
            />
          </div>
          {isAuthenticated && !isMobile && (
            <Icon
              name={"bookmark"}
              size={`${spacing.xxl}`}
              color={colors.shimmerHighlightColor}
              style={{
                marginRight: spacing.xl,
                marginLeft: `-${spacing.l}`,
                marginTop: `-${spacing.l}`,
              }}
            />
          )}
        </GridContainer>
        <Divider />
        <ScrollableContainer>
          <Grid
            container
            overflow="auto"
            sx={{
              padding: `0 ${!isMobile && spacing.s}`,
            }}
          >
            {isMobile ? (
              <div style={{ marginLeft: spacing.s }}>{jobDetailPointers}</div>
            ) : (
              jobDetailPointers
            )}
          </Grid>
          <div style={{ marginTop: spacing.m }}>
            <Divider />
          </div>
          <JobDetailsContainer sx={{ paddingLeft: isMobile ? "" : spacing.xl }}>
            {jobDetailSections}
            {jobDetailSections}
            {jobDetailSections}
          </JobDetailsContainer>
          <Divider style={{ marginTop: spacing.s }} />
          <ChatPosterContainer sx={{ paddingLeft: isMobile ? "" : spacing.xl }}>
            <Skeleton
              style={{
                marginTop: spacing.s,
                width: "20%",
                borderRadius: 50,
              }}
            />
            <JobPosterFlexBox sx={{ justifyContent: isMobile ? "space-between" : "" }}>
              <div style={{ width: "20%" }}>
                <Skeleton
                  style={{
                    marginTop: spacing.s,
                    width: "100%",
                    height: 10,
                    borderRadius: 50,
                  }}
                />
                <Skeleton
                  style={{
                    marginBottom: spacing.xs,
                    width: "50%",
                    height: 10,
                    borderRadius: 50,
                  }}
                />
              </div>
              <Skeleton
                style={{
                  marginLeft: `${spacing.xxxl}`,
                  borderRadius: 100,
                  height: `${styleUtils.pxToRem("38px")}`,
                  width: `${styleUtils.pxToRem("138px")}`,
                  padding: `${spacing.xs} ${spacing.xxxl}`,
                }}
              />
            </JobPosterFlexBox>
          </ChatPosterContainer>
        </ScrollableContainer>
        {applyButton}
      </TopContainer>
    </SkeletonTheme>
  );
};

JobDetailCardSkeleton.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default JobDetailCardSkeleton;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (!props.isMobile ? spacing.s : 0)};
  overflow: auto;
  position: relative;
  border: 1px solid ${colors.lightBlue};
  border-radius: 6px;
  height: 100%;
  width: 100%;
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const JobDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;

const ChatPosterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;

const JobPosterFlexBox = styled("div")`
  display: flex;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.l};
`;

const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;

const GridContainer = styled(Grid)`
  width: 100%;
  display: flex;
  background: transparent linear-gradient(79deg, #ffffff00 0%, ${colors.gainsboroGray} 200%) 0% 0%
    no-repeat padding-box;
  borderradius: 6px 6px 0px 0px;
`;

const ApplyWebContainer = styled("div")`
  display: flex;
  margin: ${spacing.s};
  position: absolute;
  bottom: 0;
  right: 0;
`;

const ApplyRespContainer = styled("div")`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 5px;
`;

const LeftJobDetailPointers = styled("div")`
  padding: ${(props) => (!props.isMobile ? spacing.xs : 0)};
`;

const RightJobDetailPointers = styled("div")`
  padding: ${(props) => (!props.isMobile ? spacing.xs : 0)};
  margin-left: ${(props) => (!props.isMobile ? spacing.xl : 0)};
`;

const ScrollableContainer = styled("div")`
  overflow: auto;
  margin-bottom: calc(${textSizes.l} + 3 * ${spacing.m});
  padding-left: ${spacing.xl};
`;
