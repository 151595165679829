import { useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import { Text, Icon } from "../../../../../components/atoms";

import {
  activationPasswordSetupApi,
  activationSocialLoginApi,
  getUserInfoGoogleApi,
} from "configs/network/apis";

import { colors, spacing, styleUtils, textSizes } from "../../../../../styles";
import {
  ACCOUNT_ACTIVATION_CONSTS,
  SOCIAL_ACTIVATION_CONSTANTS,
} from "containers/SignUp/constants";

import PasswordActivationView from "./PasswordActivationView";
import SocialActivationView from "./SocialActivationView";

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

import { hideLoader, showLoader, showNotification } from "utils/commonUtils";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const AccountDetailsView = ({ token, activateEmail, isMobile }) => {
  const [showPasswordField, setShowPasswordField] = useState(false);

  const setPassword = async (newPassword) => {
    try {
      showLoader();
      await activationPasswordSetupApi({
        activation_token: token,
        password: newPassword,
      });
      logEvent(FirebaseEvents.EMP_FINISHES_ACCOUNT_ACTIVATION);
    } catch (error) {
      showNotification({
        alertProps: {
          severity: "error",
          children: SOCIAL_ACTIVATION_CONSTANTS.GENERIC_ERROR_MESSAGE,
        },
      });
    } finally {
      hideLoader();
    }
  };

  const onSuccessGoogleLogin = async (response) => {
    try {
      showLoader();
      const { access_token } = response;
      const userInfo = await getUserInfoGoogleApi({
        access_token,
      });
      const { name, email, sub: googleId } = userInfo;
      await activationSocialLoginApi({
        activation_token: token,
        email,
        name,
        loginType: "GL",
        social_id: googleId,
        idToken: access_token,
        source: "WEB",
      });
      logEvent(FirebaseEvents.EMP_FINISHES_ACCOUNT_ACTIVATION);
    } catch (error) {
      handleSocialLoginError(error);
    } finally {
      hideLoader();
    }
  };

  const onSuccessFacebookLogin = async (response) => {
    try {
      showLoader();
      const { email, name, id, accessToken } = response;
      await activationSocialLoginApi({
        activation_token: token,
        email,
        name,
        loginType: "FL",
        social_id: id,
        idToken: accessToken,
        source: "WEB",
      });
      logEvent(FirebaseEvents.EMP_FINISHES_ACCOUNT_ACTIVATION);
    } catch (error) {
      handleSocialLoginError(error);
    } finally {
      hideLoader();
    }
  };

  const handleSocialLoginError = (error) => {
    const errorStatus = error?.response?.status;
    if (errorStatus === 409) {
      const code = error?.response?.data?.code;
      if (code === "already_activated") {
        showNotification({
          alertProps: {
            severity: "warning",
            children: SOCIAL_ACTIVATION_CONSTANTS.ACCOUNT_ALREADY_ACTIVE,
          },
        });
      } else if (code === "duplicate_data" || code === "duplicate_email") {
        showNotification({
          alertProps: {
            severity: "warning",
            children: SOCIAL_ACTIVATION_CONSTANTS.ACCOUNT_ALREADY_EXISTS,
          },
        });
      }
    } else if (errorStatus === 401 || errorStatus === 400) {
      showNotification({
        alertProps: {
          severity: "warning",
          children: SOCIAL_ACTIVATION_CONSTANTS.UNEXPECTED_ERROR,
        },
      });
    } else {
      showNotification({
        alertProps: {
          severity: "error",
          children: SOCIAL_ACTIVATION_CONSTANTS.GENERIC_ERROR_MESSAGE,
        },
      });
    }
  };

  const onFailureSocialActivation = () => {
    showNotification({
      alertProps: {
        severity: "error",
        children: SOCIAL_ACTIVATION_CONSTANTS.GENERIC_ERROR_MESSAGE,
      },
    });
  };

  const onSubmit = async (values) => {
    setPassword(values.newPassword);
  };

  const toggleShowPasswordField = () => {
    setShowPasswordField((prevState) => !prevState);
  };

  function validationSchema() {
    return yup.object().shape({
      newPassword: yup
        .string()
        .required(ACCOUNT_ACTIVATION_CONSTS.ERROR_NO_PASSWORD_ENTERED)
        .min(6, ACCOUNT_ACTIVATION_CONSTS.ERROR_MINIMUM_PASSWORD_LENGTH),
      confirmPassword: yup
        .string()
        .required(ACCOUNT_ACTIVATION_CONSTS.ERROR_NO_CONFIRM_PASSWORD_ENTERED)
        .oneOf([yup.ref("newPassword")], ACCOUNT_ACTIVATION_CONSTS.ERROR_PASSWORD_MISMATCH),
    });
  }

  const newPasswordFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit,
    validationSchema,
  });

  const passwordsValidated =
    !newPasswordFormik?.errors &&
    newPasswordFormik.values.newPassword.length > 6 &&
    newPasswordFormik.values.newPassword === newPasswordFormik.values.confirmPassword;

  return (
    <>
      <StyledContainer isMobile={isMobile}>
        <Text
          color={colors.linkText}
          component="span"
          onClick={toggleShowPasswordField}
          size="s"
          weight="semibold"
          sx={{ cursor: "pointer" }}
          numberOfLines={1}
        >
          {ACCOUNT_ACTIVATION_CONSTS.TEXT_PREFIX_CONTINUE_WITH}
          <Text
            component="span"
            color={colors.linkText}
            size="s"
            sx={{ fontStyle: "italic" }}
            weight="semibold"
          >
            {activateEmail}
          </Text>
        </Text>
        {showPasswordField && (
          <PasswordActivationView isMobile={isMobile} newPasswordFormik={newPasswordFormik} />
        )}
      </StyledContainer>

      {showPasswordField ? (
        <ActionFooter
          sx={{
            marginTop: styleUtils.pxToRem("24px"),
          }}
        >
          <Icon
            name="arrow-back"
            size={42}
            style={{ cursor: "pointer" }}
            onClick={() => setShowPasswordField(false)}
          />
          <ActionButton
            onClick={newPasswordFormik.handleSubmit}
            variant={passwordsValidated ? "contained" : "outlined"}
            sx={{
              width: styleUtils.pxToRem(isMobile ? "163px" : "216px"),
            }}
          >
            {ACCOUNT_ACTIVATION_CONSTS.BUTTON_NEXT}
          </ActionButton>
        </ActionFooter>
      ) : (
        <SocialActivationView
          onSuccessGoogleLogin={onSuccessGoogleLogin}
          onSuccessFacebookLogin={onSuccessFacebookLogin}
          onFailureSocialActivation={onFailureSocialActivation}
        />
      )}
    </>
  );
};

AccountDetailsView.propTypes = {
  token: PropTypes.string.isRequired,
  activateEmail: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default AccountDetailsView;

const StyledContainer = styled("div")`
  border: 1px solid #2b4894;
  border-radius: 4px;
  text-align: center;
  padding: ${(props) => (props.isMobile ? spacing.xs : spacing.s)};
  width: ${(props) => styleUtils.pxToRem(props.isMobile ? "294px" : "367px")};
`;

const ActionFooter = styled("div")`
  display: flex;
  flex-direction: row;
`;

const ActionButton = styled(Button)`
  height: ${styleUtils.pxToRem("42px")};
  font-size: ${textSizes.m};
  margin-left: auto;
`;
