import { useState } from "react";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { renderImage } from "../../../../utils/imageUtils";

import { Icon, Text } from "../../../../components/atoms";
import { colors, spacing } from "../../../../styles";
import LoginSelector from "../../../Login/selectors/LoginSelector";

import {
  BASE_PAGE_ROUTE,
  CHAT_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  JOBSEEKER_PROFILE_PAGE_ROUTE,
  JOBSEEKER_FAVOURITE_PAGE_ROUTE,
} from "../../../../routes/routeConstants";
import { JobSeekerAuthenticatedNavConstants } from "../../NavBarConstants";

import LogoutView from "./LogoutView";
import "./AuthenticatedNav.css";
import NavItem from "../../../../components/molecules/NavItem";

const JobSeekerAuthenticatedNav = ({ chatBadgeCount }) => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = () => {
    navigate({
      pathname: `${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_PROFILE_PAGE_ROUTE}`,
    });
    setAnchorEl(null);
  };

  const showLogoutModal = () => {
    handleClose();
    setIsLogoutModalOpen(true);
  };

  const hideLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  return (
    <>
      <StyledNavLink to={BASE_PAGE_ROUTE}>
        {({ isActive }) => (
          <NavItem
            icon="home"
            text={JobSeekerAuthenticatedNavConstants.HOME_TAB}
            isActive={isActive}
          />
        )}
      </StyledNavLink>

      <StyledNavLink to={`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`}>
        {({ isActive }) => (
          <NavItem
            icon="exploreJobs"
            text={JobSeekerAuthenticatedNavConstants.EXPLORE_TAB}
            isActive={isActive}
          />
        )}
      </StyledNavLink>

      <StyledNavLink to={CHAT_PAGE_ROUTE}>
        {({ isActive }) => (
          <NavItem
            icon="comment-o"
            text={JobSeekerAuthenticatedNavConstants.CHAT}
            isActive={isActive}
            hasBadge
            badgeContent={chatBadgeCount}
          />
        )}
      </StyledNavLink>

      <StyledNavLink
        to={`${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_FAVOURITE_PAGE_ROUTE}`}
      >
        {({ isActive }) => (
          <NavItem
            icon="application"
            text={JobSeekerAuthenticatedNavConstants.FAVOURITES}
            isActive={isActive}
          />
        )}
      </StyledNavLink>

      <UserButton variant="text" onClick={handleClick}>
        <Avatar
          alt={loggedInUser?.fullName}
          src={renderImage(loggedInUser?.photoURL, "user")}
          sx={{ width: 40, height: 40, marginRight: spacing.xs }}
        >
          <Icon name="user" />
        </Avatar>
        <Text
          weight="semibold"
          style={{ marginLeft: spacing.xs, marginRight: spacing.xs }}
        >
          {loggedInUser?.fullName}
        </Text>
        <Icon size={10} name="arrow-down" />
      </UserButton>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          border: `1px solid ${colors.primary}`,
        }}
      >
        <LoginMenuItem
          className="authentication-nav-menu-item"
          onClick={onClickMenuItem}
        >
          <Icon name="user" />
          <LoginMenuItemText size="s" weight="medium">
            {JobSeekerAuthenticatedNavConstants.MY_PROFILE}
          </LoginMenuItemText>
        </LoginMenuItem>
        <LoginMenuItem
          className="authentication-nav-menu-item"
          onClick={showLogoutModal}
        >
          <Icon name="login" />
          <LoginMenuItemText size="s" weight="medium">
            {JobSeekerAuthenticatedNavConstants.LOGOUT}
          </LoginMenuItemText>
        </LoginMenuItem>
      </Menu>
      <LogoutView
        islogoutModalOpen={isLogoutModalOpen}
        hideLogoutModal={hideLogoutModal}
      />
    </>
  );
};

export default JobSeekerAuthenticatedNav;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${spacing.s};
`;

const UserButton = styled(Button)`
  box-shadow: 0px 0px 0px;
  border-width: 0px;
  width: auto;
`;

const LoginMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.primary,
  },
  minWidth: 160,
  padding: `${spacing.xs} ${spacing.s}`,
  marginBottom: spacing.xxs,
}));

const LoginMenuItemText = styled(Text)`
  margin-left: ${spacing.s};
`;
