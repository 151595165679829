import { Button, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  EmptyView,
  PeopleCardSkeleton,
} from "../../../../../../../components/molecules";

import {
  colors,
  spacing,
  textSizes,
  typography,
  styleUtils,
} from "../../../../../../../styles";

/**
 * All JobSeekers Paginated View
 * @returns List of job seekers for selected location
 */
const AllSeekers = ({
  allJobSeekers,
  handleLoadMorePeople,
  isEmployerPeopleListLoading,
  peopleListPage,
  renderPeople,
  showLoadMore,
}) => {
  const peopleShimmerLimit = [1, 2, 3, 4, 5, 6];

  const renderPeopleCardSkeleton = (value) => {
    return (
      <Grid key={value} item md={4} mb={spacing.l}>
        <PeopleCardSkeleton />
      </Grid>
    );
  };

  let peopleCardsContent =
    allJobSeekers.length > 0 ? (
      allJobSeekers.map(renderPeople)
    ) : (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: spacing.l,
        }}
      >
        <EmptyView
          image="no-recommended-jobs"
          imageHeight="75%"
          title="No people found yet"
          description="Keep track of people you’re interested in"
        />
      </div>
    );

  return (
    <>
      {isEmployerPeopleListLoading && peopleListPage === 1 ? (
        <Grid container mt={spacing.s} columnSpacing={spacing.l}>
          {peopleShimmerLimit.map(renderPeopleCardSkeleton)}
        </Grid>
      ) : (
        <>
          <Grid
            container
            mt={spacing.s}
            columnSpacing={spacing.l}
            sx={{ maxHeight: "68vh", overflow: "auto" }}
          >
            {peopleCardsContent}
            {isEmployerPeopleListLoading &&
              peopleShimmerLimit.map(renderPeopleCardSkeleton)}
          </Grid>
          {showLoadMore && (
            <LoadMoreBtn
              variant="outlined"
              onClick={handleLoadMorePeople}
              endIcon={<ArrowDownwardIcon sx={{ color: colors.primary }} />}
            >
              Load More
            </LoadMoreBtn>
          )}
        </>
      )}
    </>
  );
};

export default AllSeekers;

const LoadMoreBtn = styled(Button)`
  display: flex;
  font-size: ${textSizes.xl};
  font-weight: ${typography.medium};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("305px")};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.xxl};
`;
