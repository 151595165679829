export const SEEKERS_BOOK_SLOT_FORM_CONSTS = {
  SEEKERS_DETAILS_FORM_NAME_LABEL: "Full Name",
  SEEKERS_DETAILS_FORM_NAME_PLACEHOLDER: "enter full name",
  SEEKERS_DETAILS_FORM_EMAIL_LABEL: "Email",
  SEEKERS_DETAILS_FORM_EMAIL_PLACEHOLDER: "enter email",
  SEEKERS_DETAILS_FORM_BUTTON_SUBMIT: "Submit",
  SEEKERS_DETAILS_FORM_INITAL_STATE: {
    email: "",
    fullName: "",
  },
};

export const SEEKER_EMP_SCHEDULE_TABLE_COL_CONSTS = {
  COLUMN_EMP_NAME: "Employer Name",
  COLUMN_JOB_TITLE: "Job Title",
  COLUMN_DATE: "Date",
  COLUMN_TIIME: "Time",
  COLUMN_DURATION: "Duration",
  COLUMN_MEETING_MODE: "Interview Mode",
  COLUMN_BOOK_SLOT: "",
};
export const SEEKER_EMP_SCHEDULE_TABLE_ROW_KEYS = [
  "full_name",
  "job_title",
  "date",
  "time",
  "duration",
  "meeting_mode",
  "button",
];
