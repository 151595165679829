import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { images } from "../../../assets/images";
import { colors, spacing } from "../../../styles";
import { Text } from "../../atoms";

const EmptyView = ({
  image,
  imageHeight,
  title,
  description,
  noApplication,
  onClick,
  descriptiontextColor,
  descriptionTextStyle,
  buttonText = "Apply Now",
}) => {
  return (
    <Wrapper>
      <img
        style={{ width: "auto", height: imageHeight }}
        src={images[image]}
        alt="Live from space album cover"
      />
      <Text
        weight="medium"
        size="l"
        style={{ marginTop: `${spacing.xxs}` }}
        color={colors.textPrimary}
      >
        {title}
      </Text>
      <Text
        style={{
          marginTop: spacing.xxs,
          textAlign: "center",
        }}
        size={descriptionTextStyle ? "l" : "m"}
        weight={descriptionTextStyle ? "medium" : "regular"}
        color={descriptiontextColor ? descriptiontextColor : colors.lightGray}
      >
        {description}
      </Text>
      {noApplication && (
        <Button
          variant={"contained"}
          sx={{
            width: "auto",
            borderRadius: 100,
            padding: `${spacing.xs} ${spacing.xxxxl}`,
            marginTop: `${spacing.s}`,
          }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </Wrapper>
  );
};

export default EmptyView;

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
