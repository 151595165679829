export const PeopleConstants = {
  TITLE: "Hire Locals",
  SEARCH_INPUT_PLACEHOLDER: "search by jobseekers",
  SEARCH: "Search",
  POST_NEW_JOB: "Post New Job",
  FILTER_BY: "Filter by",
  PEOPLE: "People",
  NO_PEOPLE_TITLE: "No people found yet",
  NO_PEOPLE_DESCRIPTION: "Keep track of people you’re interested in",
};

export const FilterConstants = {
  RESET_FILTER: "Reset Filter",
  APPLY: "Apply",
  DISTANCE: "Distance",
  MILES: "miles",
  HOURLY_WAGES: "Hourly Wages",
  HOURS_PER_WEEK: "Hours Per Week",
  AGE: "Age",
  EXPERIENCE: "Experience",
  ALL: "All",
  EXPERIENCED: "Experienced",
  BEGINNER: "Beginner",
  FAVOURITES: "Applied",
};

export const PeopleDescription = {
  WAGE_EXPECTATION: "Wage expectations",
  PERSONAL_INFORMATION: "Personal Information",
  ACTIVELY_LOOKING_FOR_JOB: "Actively looking for job",
  PREFERENCES: "PREFERENCES",
  WORKING_HOURS: "Hours/week",
  EXPERIENCE: "Years of Experience",
  DAYS_WEEK: "Days/week",
  NOTE: "About Me",
};

export const SORT_BY_BUTTON = "Sort by";
export const CLEAR_OPTION = "Clear";

export const peopleSortingOptions = [
  // {
  //   key: "distance",
  //   title: "Distance",
  //   order: "",
  // },
  {
    key: "wageExpectation",
    title: "Hourly wage",
    order: "",
  },
  {
    key: "workingHours",
    title: "Hours per week",
    order: "",
  },
  {
    key: "experience",
    title: "Experience",
    order: "",
  },
];

export const peopleExperienceOptions = [
  {
    id: 1,
    label: "All",
    value: "all",
    isSelected: false,
  },
  {
    id: 2,
    label: "Experienced",
    value: "Experience",
    isSelected: false,
  },
  {
    id: 3,
    label: "Beginner",
    value: "Beginner",
    isSelected: false,
  },
];
