import { Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { images } from "../../../../assets/images";
import { Text } from "../../../../components/atoms";
import { colors, spacing } from "../../../../styles";
import {
  ConnectSocialItem,
  FacebookLoginButton,
  GoogleLoginButton,
} from "../../../../components/molecules";

const ConnectSocial = ({
  facebookData,
  googleData,
  onSuccessGoogleLogin,
  onSuccessFacebookLogin,
  onFailureGoogleLogin,
}) => {
  return (
    <>
      <TopContainer>
        <Text color={colors.placeholder}>
          Connect your other social accounts with EmployD for a simple & secure
          sign-in.
        </Text>
      </TopContainer>
      <Divider />
      <SocialContainer>
        <ConnectSocialItem
          name="Google"
          image={images.profile.social.google}
          socialData={googleData}
          renderConnectButton={
            <GoogleLoginButton
              onProfileSuccess={onSuccessGoogleLogin}
              onFail={onFailureGoogleLogin}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  variant="contained"
                  sx={{
                    width: 120,
                    px: spacing.xl,
                    mt: spacing.m,
                  }}
                >
                  Connect
                </Button>
              )}
            />
          }
        />
        <ConnectSocialItem
          name="Facebook"
          image={images.profile.social.facebook}
          socialData={facebookData}
          renderConnectButton={
            <FacebookLoginButton
              onProfileSuccess={onSuccessFacebookLogin}
              onFail={(e) => console.log(e)}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  variant="contained"
                  sx={{
                    width: 120,
                    px: spacing.xl,
                    mt: spacing.m,
                  }}
                >
                  Connect
                </Button>
              )}
            />
          }
        />
      </SocialContainer>
    </>
  );
};

export default ConnectSocial;

const TopContainer = styled("div")`
  padding: 0 ${spacing.m} ${spacing.m} ${spacing.m};
`;

const SocialContainer = styled("div")`
  padding: 0 ${spacing.m};
`;
