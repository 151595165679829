import React, { Suspense, lazy } from "react";

import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "hooks/useIsMobile";
import { Text } from "components/atoms";

import { images } from "assets/images";
import generatingAnimation from "assets/lotties/generatingAnimation.json";
import { colors, styleUtils } from "styles";

const Lottie = lazy(() => import("react-lottie-player"));

const AI_GENERATION_DISABLED = "disabledAIGeneration";
const AI_GENERATION_ENABLED = "aiGeneration";

const AIGenerator = ({
  onPressGenerate,
  onPressStopGenerate,
  isDisabled,
  isGenerationComplete,
  isAIContentEmpty,
  textForGenerate = "Generate using AI",
  textForLoading = "Generating using AI",
  textForGenerationDone = "Generated using AI",
  buttonTextForGenerate = "Generate",
  buttonTextForLoading = "Stop generating",
  buttonTextForGenerationDone = "Regenerate",
  toolbarSx,
  buttonSx,
}) => {
  const isMobile = useIsMobile();
  const AIGenImage = isDisabled ? AI_GENERATION_DISABLED : AI_GENERATION_ENABLED;
  if (isGenerationComplete) {
    return (
      <AIToolbar sx={toolbarSx} isDisabled={isDisabled}>
        <div style={{ marginLeft: 2 }}>
          <Text
            size={isMobile ? "xs" : "s"}
            weight="medium"
            color="#363636"
            style={{ display: "inline-block" }}
          >
            {!isAIContentEmpty ? textForGenerationDone : textForGenerate}
          </Text>
        </div>
        <StyledAIButton onClick={onPressGenerate} sx={buttonSx} isDisabled={isDisabled}>
          {!isAIContentEmpty ? buttonTextForGenerationDone : buttonTextForGenerate}
          <img alt="aiGeneration" src={images[AIGenImage]} style={{ width: 18, height: 18 }} />
        </StyledAIButton>
      </AIToolbar>
    );
  } else {
    return (
      <AIToolbar sx={toolbarSx} isDisabled={isDisabled}>
        <div style={{ marginLeft: 2 }}>
          <Text
            size={isMobile ? "xs" : "s"}
            weight="medium"
            color="#363636"
            style={{ display: "inline-block" }}
          >
            {textForLoading}
          </Text>
        </div>
        <StyledAIButton onClick={onPressStopGenerate} sx={buttonSx} isDisabled={isDisabled}>
          {isAIContentEmpty ? (
            <Suspense fallback={null}>
              <Lottie
                loop
                animationData={generatingAnimation}
                play
                style={{ width: 18, height: 18 }}
              />
            </Suspense>
          ) : (
            <Box>
              <BlinkContainer>
                <StopGeneratingIcon />
                {buttonTextForLoading}
              </BlinkContainer>
            </Box>
          )}
        </StyledAIButton>
      </AIToolbar>
    );
  }
};

AIGenerator.propTypes = {
  onPressGenerate: PropTypes.func,
  onPressStopGenerate: PropTypes.func,
  isDisabled: PropTypes.bool,
  isGenerationComplete: PropTypes.bool,
  isAIContentEmpty: PropTypes.bool,
  textForGenerate: PropTypes.string,
  textForLoading: PropTypes.string,
  textForGenerationDone: PropTypes.string,
  buttonTextForGenerate: PropTypes.string,
  buttonTextForLoading: PropTypes.string,
  buttonTextForGenerationDone: PropTypes.string,
  toolbarSx: PropTypes.any,
  buttonSx: PropTypes.any,
};

export default React.memo(AIGenerator);

const AIToolbar = styled("div")`
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 6px;
  border-radius: 4px;
  opacity: 1;
  background: ${({ isDisabled }) =>
    isDisabled
      ? "transparent linear-gradient(90deg, #EAEAEA 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box"
      : "#F7F0FC"};
  display: flex;
  margin: 8px;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledAIButton = styled(Button)`
  width: ${styleUtils.pxToRem("138px")};
  height: ${styleUtils.pxToRem("34px")};
  background: transparent linear-gradient(270deg, #3c4ffa 0%, #7e17dd 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 4px 10px ${({ isDisabled }) => (isDisabled ? "#898989" : "#469dfb8b")};
  border: 1px solid ${({ isDisabled }) => (isDisabled ? "#898989" : "none")};
  border-radius: 20px;
  opacity: 1;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  color: ${({ isDisabled }) => (isDisabled ? "#898989" : colors.white)};
  font-weight: 400;
  font-size: 0.875rem;
  gap: 8px;
  &:hover {
    background-color: #fff;
  }
  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 33px;
    font-size: 0.75rem;
  }
`;

const StopGeneratingIcon = styled("div")`
  border: 1px solid #469dfb;
  border-radius: 2px;
  background-color: #eaf3ff;
  height: 12px;
  width: 12px;
  display: inline-block;
`;

const BlinkContainer = styled("span")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  animation: blink-animation 1.9s linear infinite;
  -webkit-animation: blink-animation 1.9s linear infinite;
  @keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
