import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";

import { Box, Button, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Icon, Text, TextInput } from "../../../../components/atoms";
import { colors, spacing, styleUtils, textSizes } from "../../../../styles";

import { FORGOT_PASSWORD } from "containers/Account/constants";
import { hideLoader, isEmpty, showLoader, showNotification } from "utils/commonUtils";
import { resetForgotPasswordApi } from "configs/network/apis";
import useIsMobile from "hooks/useIsMobile";

const ResetPassword = ({ setResetSuccess }) => {
  const isMobile = useIsMobile();
  const [showNewPwdText, setShowNewPwdText] = useState(false);
  const [showConfirmPwdText, setShowConfirmPwdText] = useState(false);

  const resetForgotPassword = async (newPassword) => {
    try {
      showLoader();
      await resetForgotPasswordApi({ newPassword });
      setResetSuccess(true);
    } catch (error) {
      showNotification({
        alertProps: {
          severity: "error",
          children: FORGOT_PASSWORD.ERROR_MESSAGE_GENERIC,
        },
      });
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async (values) => {
    await resetForgotPassword(values.newPassword);
  };

  function validationSchema() {
    return yup.object().shape({
      newPassword: yup
        .string()
        .required(FORGOT_PASSWORD.ERROR_MESSAGE_EMPTY_NEW_PASSWORD)
        .min(6, FORGOT_PASSWORD.ERROR_MESSAGE_LENGTH_NEW_PASSWORD),
      confirmPassword: yup
        .string()
        .required(FORGOT_PASSWORD.ERROR_MESSAGE_EMPTY_CONFIRM_PASSWORD)
        .oneOf([yup.ref(FORGOT_PASSWORD.KEY_NEW_PASSWORD)], FORGOT_PASSWORD.PASSWORD_MISMATCH),
    });
  }

  const newPasswordFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit,
    validationSchema,
  });

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (name === FORGOT_PASSWORD.KEY_NEW_PASSWORD) {
      newPasswordFormik.setFieldError(
        FORGOT_PASSWORD.KEY_NEW_PASSWORD,
        !isEmpty(value) && value?.length < 6
          ? FORGOT_PASSWORD.ERROR_MESSAGE_LENGTH_NEW_PASSWORD
          : "",
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === FORGOT_PASSWORD.KEY_NEW_PASSWORD) {
      if (value.length >= 6) {
        newPasswordFormik.setFieldError(FORGOT_PASSWORD.KEY_NEW_PASSWORD, "");
      }
    }
    newPasswordFormik.handleChange(event);
  };

  return (
    <VerificationBox>
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        style={{ marginBottom: spacing.l }}
      >
        {FORGOT_PASSWORD.RESET_PASSWORD_HEADER}
      </Text>
      <div>
        <InfoLabelText size="s" color="#383838">
          {FORGOT_PASSWORD.PASSWORD_FIELD_LABEL}
        </InfoLabelText>
        <PasswordInput
          name="newPassword"
          placeholder={FORGOT_PASSWORD.PASSWORD_FIELD_PLACEHOLDER}
          type={showNewPwdText ? "text" : "password"}
          value={newPasswordFormik.values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          endAdornment={
            <InputAdornment position="end" onClick={() => setShowNewPwdText(!showNewPwdText)}>
              <IconButton>
                {showNewPwdText ? (
                  <Visibility sx={{ color: "#848484" }} />
                ) : (
                  <VisibilityOff sx={{ color: "#848484" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          sx={{
            width: styleUtils.pxToRem(isMobile ? "100%" : "432px"),
            marginBottom: spacing.xs,
          }}
        />
        {Boolean(newPasswordFormik.errors.newPassword) && (
          <FormHelperText sx={{ color: colors.red }}>
            {newPasswordFormik.errors.newPassword}
          </FormHelperText>
        )}
      </div>
      <div>
        <InfoLabelText size="s" color="#383838" sx={{ marginTop: spacing.l }}>
          {FORGOT_PASSWORD.CONFIRM_PASSWORD_FIELD_LABEL}
        </InfoLabelText>
        <PasswordInput
          name="confirmPassword"
          placeholder={FORGOT_PASSWORD.CONFIRM_PASSWORD_FIELD_PLACEHOLDER}
          type={showConfirmPwdText ? "text" : "password"}
          value={newPasswordFormik.values.confirmPassword}
          onChange={newPasswordFormik.handleChange}
          handleSubmit={newPasswordFormik.handleSubmit}
          endAdornment={
            <InputAdornment
              position="end"
              onClick={() => setShowConfirmPwdText(!showConfirmPwdText)}
            >
              <IconButton>
                {showConfirmPwdText ? (
                  <Visibility sx={{ color: "#848484" }} />
                ) : (
                  <VisibilityOff sx={{ color: "#848484" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          sx={{
            width: styleUtils.pxToRem(isMobile ? "100%" : "432px"),
            marginBottom: spacing.xs,
          }}
        />
        {Boolean(newPasswordFormik.errors.confirmPassword) &&
          newPasswordFormik.values.newPassword !== newPasswordFormik.values.confirmPassword && (
            <FormHelperText sx={{ color: colors.red }}>
              {newPasswordFormik.errors.confirmPassword}
            </FormHelperText>
          )}
        {newPasswordFormik.values?.newPassword.length > 6 &&
          newPasswordFormik.values?.newPassword === newPasswordFormik.values?.confirmPassword && (
            <div
              style={{
                marginTop: spacing.xs,
                display: "flex",
              }}
            >
              <Icon size={15} name="correct-validation" style={{ marginTop: "2px" }} />
              <Text size="s" sx={{ color: "#2E2E2E", marginLeft: spacing.xxs }}>
                {FORGOT_PASSWORD.PASSWORD_MATCH}
              </Text>
            </div>
          )}
      </div>
      <ActionFooter
        sx={{
          marginTop: styleUtils.pxToRem(isMobile ? "310px" : "264px"),
        }}
      >
        <ActionButton
          onClick={newPasswordFormik.handleSubmit}
          variant="contained"
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
          }}
        >
          {FORGOT_PASSWORD.SUBMIT_BUTTON}
        </ActionButton>
      </ActionFooter>
    </VerificationBox>
  );
};

ResetPassword.propTypes = {
  setResetSuccess: PropTypes.func.isRequired,
};

export default ResetPassword;

const VerificationBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.xxxs};
`;
const PasswordInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: ${styleUtils.pxToRem("42px")};
`;
const ActionFooter = styled("div")`
  display: flex;
  justify-content: space-between;
`;
const ActionButton = styled(Button)`
  height: ${styleUtils.pxToRem("42px")};
  font-size: ${textSizes.m};
`;
