import { Button, Container, Grid } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";

const SearchLocalJobs = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: styleUtils.pxToRem("110px"),
        marginTop: styleUtils.pxToRem("145px"),
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Grid container columnSpacing={spacing.xxxxl}>
        <Grid item md={6} ml={4}>
          <img
            src={images.home["employer-hero"]["2"]}
            alt="2"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid
          item
          md={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            weight="extrabold"
            size={44}
            style={{ lineHeight: 1.2 }}
            color={colors.primary}
          >
            Post a job{" "}
          </Text>
          <Text size={44} style={{ lineHeight: 1.2 }}>
            in a minute
          </Text>
          <Text size="l" color="#4C5468" style={{ marginTop: spacing.s }}>
            We simplify the process of posting your job.
          </Text>
          <Text size="l" color="#4C5468">
            Our simple interface allows you to post your job quickly and
            hassle-free.
          </Text>
          {!isAuthenticated && (
            <Button
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("46px"),
                width: styleUtils.pxToRem("176px"),
                mt: spacing.xxxl,
                px: spacing.xxl,
                py: spacing.xs,
                fontSize: textSizes.l,
                fontWeight: typography.bold,
              }}
              onClick={onGetStarted}
            >
              Get Started
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchLocalJobs;
