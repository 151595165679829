import { Button, Container } from "@mui/material";

import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";

import { Text } from "../../../components/atoms";

const Search = ({ onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "630px",
      }}
    >
      <Text size={32} style={{ marginTop: spacing.xl, lineHeight: 1.2 }}>
        Local part-time
      </Text>
      <Text size={32} style={{ lineHeight: 1.2 }}>
        <Text
          component="span"
          color={colors.primary}
          size={32}
          weight="extrabold"
          style={{ lineHeight: 1.2 }}
        >
          hiring
        </Text>{" "}
        made{" "}
      </Text>
      <Text size={32} style={{ lineHeight: 1.2 }}>
        simple
      </Text>
      <Text
        size="m"
        style={{ marginTop: spacing.m, width: styleUtils.pxToRem("271px") }}
        color="#4C5468"
      >
        It’s easy to hire local part-time workers with EmployD!Our simple,
        easy-to-use platform allows you to post your job without hassle
      </Text>

      <Button
        variant="contained"
        sx={{
          height: styleUtils.pxToRem("48px"),
          width: styleUtils.pxToRem("226px"),
          fontSize: textSizes.l,
          fontWeight: typography.bold,
          marginTop: styleUtils.pxToRem("300px"),
        }}
        onClick={onGetStarted}
      >
        Start Hiring Instantly
      </Button>
    </Container>
  );
};

export default Search;
