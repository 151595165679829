import { styled } from "@mui/material/styles";
import { images } from "../../../assets/images";
import { spacing } from "../../../styles";
import { Text } from "../../atoms";

const LoadingJobView = ({ image, imageHeight, title }) => {
  return (
    <Wrapper>
      <Text style={{ marginTop: spacing.m }} weight="medium" size="xxxxl">
        {title}
      </Text>
      <img
        style={{ width: "auto", height: imageHeight, marginTop: spacing.l }}
        src={images[image]}
        alt="loading jobs for you"
      />
    </Wrapper>
  );
};

export default LoadingJobView;

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  padding: ${spacing.m};
  margin-top: ${spacing.l};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
