import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled } from "@mui/material/styles";

import { Text } from "../../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../../styles";
import {
  peopleSortingOptions,
  SORT_BY_BUTTON,
  CLEAR_OPTION,
} from "../Constants";

/**
 * Sorting Menu for jobs
 * by distance, wage and work hours
 */
const Sort = ({ handleSorting, leftMargin = "50%" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (type) => () => {
    setAnchorEl(null);
    let sortOrder = "";
    peopleSortingOptions.forEach((item) => {
      if (item.key === type) {
        sortOrder = !item.order || item.order === "desn" ? "ascn" : "desn";
        item.order = sortOrder;
      } else {
        item.order = "";
      }
    });
    handleSorting(type, sortOrder);
  };

  const handleResetSort = () => {
    setAnchorEl(null);
    peopleSortingOptions.forEach((item) => {
      item.order = "";
    });
    handleSorting("clear", "");
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          width: "auto",
          marginLeft: leftMargin,
          transform: `translateY(-${spacing.xs})`,
        }}
      >
        {SORT_BY_BUTTON}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: colors.white,
            borderWidth: 0,
          },
        }}
      >
        {peopleSortingOptions.map((option) => {
          return (
            <SortingMenuItem
              key={option.key}
              onClick={onClickMenuItem(option.key)}
            >
              <Text size="s" weight="medium">
                {option.title}
              </Text>
              {option.order &&
                (option.order === "ascn" ? (
                  <ArrowUpwardIcon
                    sx={{ color: colors.primary, fontSize: "small" }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    sx={{ color: colors.primary, fontSize: "small" }}
                  />
                ))}
            </SortingMenuItem>
          );
        })}
        <ResetMenuItem key="clear" onClick={handleResetSort}>
          <Text size="s" weight="medium">
            {CLEAR_OPTION}
          </Text>
        </ResetMenuItem>
      </Menu>
    </>
  );
};

export default Sort;

const SortingMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
  },
  minWidth: 160,
  marginBottom: spacing.xxs,
  borderBottom: `1px solid ${colors.shadow}`,
}));

const ResetMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
    textDecorationColor: colors.primary,
  },
  minWidth: 160,
  marginBottom: spacing.xxs,
}));
