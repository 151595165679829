const getIsAuthenticated = (state) => {
  const { isLoginSuccess } = state.loginState;
  return isLoginSuccess;
};

const getLoggedInUser = (state) => {
  const { user } = state.loginState;
  if (user) {
    return user;
  }
  return null;
};

export const getUserRatingStatus = (state) => {
  const { user } = state.loginState;
  return user ? user.is_feedback_done : null;
};

const getLoggedInUserSettings = (type) => (state) => {
  const { settings } = state.loginState;
  return settings[type];
};

const getLoggedInUserCurrentLocation = (state) => {
  const { location } = state.loginState;
  return location;
};

const getIsUserLocationUpdating = (state) => {
  const { isLocationUpdating } = state.loginState;
  return isLocationUpdating;
};

const getSearchLocation = (state) => {
  const { searchLocation } = state.loginState;
  return searchLocation;
};

const getSocialAccountListData = (state) => {
  const { userSocial } = state.loginState;
  return userSocial;
};

const getBlockedUsersList = (state) => {
  const { blockedUsers } = state.loginState;
  return blockedUsers;
};

const getUnblockedUsersList = (state) => {
  const { recentlyUnblockedUsers } = state.loginState;
  return recentlyUnblockedUsers;
};

const LoginSelector = {
  getIsAuthenticated,
  getLoggedInUser,
  getLoggedInUserSettings,
  getLoggedInUserCurrentLocation,
  getIsUserLocationUpdating,
  getSearchLocation,
  getSocialAccountListData,
  getBlockedUsersList,
  getUnblockedUsersList,
  getUserRatingStatus,
};

export default LoginSelector;
