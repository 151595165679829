import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Button,
  FormHelperText,
  Grid,
  Container,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import {
  Text,
  TextInput,
  GoogleInput,
  Icon,
  MultiSelect,
  StepperComponent,
} from "../../../../../components/atoms";
import * as ProfileConstants from "../../../../SignUp/views/JobSeeker/constants";
import * as AddJobsConts from "../AddJobConstants";

import DeleteModal from "./DeleteModal";
import DuplicateJobAlert from "./DuplicateJobAlert";
import { JobDescriptionInput } from "../../../../../components/molecules";
import CancelModal from "../../../../../components/atoms/cancelModal";
import { colors, spacing } from "../../../../../styles";

const AddJobView = (props) => {
  const {
    activeStepAddEditJob,
    city,
    isCopy,
    isEditPress,
    formik,
    jobDescriptionFormik,
    googleInputRef,
    jobLocationFormattedAddress,
    invalidLocation,
    loggedInUser,
    minAge,
    showDeleteJob,
    showDuplicateJobAlert,
    showCancelLeaveModal,
    clearInput,
    onBlurJobTitle,
    onPressCancel,
    onPlaceSelected,
    handleJobTitleChange,
    handleAgeChange,
    handleDaysSelectedChange,
    handleDeleteModal,
    handleExpChange,
    handleHoursChange,
    handleTimeSlotChange,
    handleWageChange,
    handleJobDescriptionChange,
    handleCancelLeaveModal,
    setShowDeleteJob,
    setShowDuplicateJobAlert,
    setMinAge,
  } = props;

  const [isJDGenerationComplete, setIsJDGenerationComplete] = useState(true);

  let actionLabel;
  if (isEditPress) {
    actionLabel = "Edit Job Post";
  } else if (isCopy) {
    actionLabel = "Duplicate Job";
  } else {
    actionLabel = "Add Job Post";
  }

  const location = useLocation();
  useEffect(() => {
    if (!location?.state) {
      handleFormReset();
    }
  }, [location]);

  const handleFormReset = () => {
    setMinAge("");
    clearInput();
    formik.setFieldValue("jobTitle", "");
    formik.setFieldValue("note", "");
    formik.setFieldValue("description", "");
    formik.setFieldValue("wageExpectation", []);
    formik.setFieldValue("workingHours", []);
    formik.setFieldValue("experience", []);
    formik.setFieldValue("timeSlot", []);
    formik.setFieldValue("daysSelected", []);
    formik.setFieldValue("min_age", "");
  };

  const renderAgeDropDown = () => {
    return (
      <div>
        <Text
          size="s"
          weight="medium"
          sx={{ marginTop: 0 }}
        >
          Minimum Age for the job
        </Text>

        <FormControl
          fullWidth
          sx={{
            m: 1,
            margin: 0,
            "& .MuiOutlinedInput-root:hover": {
              borderColor: "transparent",
            },
          }}
          size="small"
        >
          <Select
            id="demo-select-small"
            displayEmpty
            renderValue={
              minAge !== ""
                ? undefined
                : () => (
                    <Text
                      size="s"
                      color={colors.lightGray}
                    >
                      Select the minimum age for the job
                    </Text>
                  )
            }
            value={minAge}
            onChange={handleAgeChange}
            disabled={invalidLocation}
            sx={{
              padding: 0,
              marginTop: spacing.xxs,
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
              },
              "&.Mui-disabled": {
                pointerEvents: "none",
                "& .MuiSelect-select": {
                  color: "rgba(0, 0, 0, 0.26)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.12)",
                },
              },
            }}
          >
            {AddJobsConts.AGE_VALUES.map((data) => {
              return (
                <MenuItem
                  key={data}
                  value={data}
                >
                  {data} years
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {formik.touched.min_age && Boolean(formik.errors.min_age) && (
          <FormHelperText sx={{ color: colors.red, marginBottom: spacing.s }}>
            {formik.errors.min_age}
          </FormHelperText>
        )}
      </div>
    );
  };

  return (
    <Container sx={{ mt: spacing.xxl, mb: spacing.xxxxl }}>
      <DuplicateJobAlert
        show={showDuplicateJobAlert}
        handleCancel={() => setShowDuplicateJobAlert(false)}
        handlePost={formik.handleSubmit}
      />
      <CancelModal
        isOpen={showCancelLeaveModal}
        handleClose={handleCancelLeaveModal}
      />
      <Row>
        <Text
          size="l"
          weight="medium"
          color={colors.titleBlack}
          style={{ marginBottom: spacing.m }}
        >
          {actionLabel}
        </Text>
        {isEditPress && (
          <ActiveBlockContainer>
            <Icon
              size={26}
              name="delete-bin"
              onClick={() => setShowDeleteJob(true)}
              color={colors.primary}
              style={{ marginRight: spacing.s, cursor: "pointer" }}
            />
          </ActiveBlockContainer>
        )}
      </Row>
      <div style={{ width: "10%" }}>
        <StepperComponent
          stepsCount={2}
          activeStep={activeStepAddEditJob}
          markComplete={activeStepAddEditJob === 3}
          sx={{
            mb: spacing.xl,
          }}
        />
      </div>
      <Grid
        container
        columnSpacing={spacing.xxl}
      >
        <DeleteModal
          showDeleteJob={showDeleteJob}
          handleDeleteModal={handleDeleteModal}
        />
        {activeStepAddEditJob === 1 && (
          <>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
            >
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
              >
                Job Title
              </Text>
              <TextInput
                name="jobTitle"
                value={formik.values.jobTitle}
                onChange={handleJobTitleChange}
                error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                placeholder={"Enter job title here"}
                onBlur={onBlurJobTitle}
                sx={{
                  marginTop: spacing.xxs,
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                }}
              />

              {formik.touched.jobTitle && Boolean(formik.errors.jobTitle) && (
                <FormHelperText sx={{ color: colors.red }}>{formik.errors.jobTitle}</FormHelperText>
              )}
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.m }}
              >
                Job Location
              </Text>

              <GoogleInput
                ref={googleInputRef}
                placeholder={"Enter job location"}
                onPlaceSelected={onPlaceSelected}
                defaultValue={isEditPress ? city : ""}
                onClear={clearInput}
                isCopy={isCopy}
                style={{
                  marginTop: spacing.xxs,
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                }}
              />
              {invalidLocation && (
                <div style={{ display: "flex" }}>
                  <Icon
                    size={26}
                    name="error"
                    onClick={() => setShowDeleteJob(true)}
                    color={colors.primary}
                    style={{ marginRight: spacing.s, cursor: "pointer" }}
                  />
                  <Text
                    size="s"
                    color={colors.red2}
                  >
                    We’re currently available only in the Bucks County region, Pennsylvania. We will
                    notify you when we are in {jobLocationFormattedAddress}
                  </Text>
                </div>
              )}
              {formik.touched.jobLocation && Boolean(!formik.values.jobLocation) && (
                <FormHelperText sx={{ color: colors.red }}>
                  {formik.errors.jobLocation}
                </FormHelperText>
              )}

              <div>
                <Text
                  size="s"
                  weight="medium"
                  color={colors.labelBlack}
                  sx={{ marginTop: spacing.m }}
                >
                  Write a short note
                </Text>
                <TextInput
                  multiline
                  name="note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  error={formik.touched.note && Boolean(formik.errors.note)}
                  placeholder={
                    "Tell job seekers about perks, benefits, work environment, etc. For Example - Fun work environment & free smoothies!"
                  }
                  inputProps={{ style: { height: 140, overflow: "auto" } }}
                  sx={{
                    marginTop: spacing.xxs,
                    border: "1px solid #E0E0E0",
                    borderRadius: "4px",
                  }}
                  disabled={invalidLocation}
                />
                {formik.touched.note && Boolean(formik.errors.note) && (
                  <FormHelperText sx={{ color: colors.red }}>{formik.errors.note}</FormHelperText>
                )}
              </div>
              <div style={{ marginTop: spacing.m }}>{renderAgeDropDown()}</div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
            >
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Hourly Wage Rate
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.wageExpectations}
                    name="wageExpectation"
                    prevSelectedOptions={formik.values.wageExpectation}
                    handleSelect={handleWageChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.wageExpectation && Boolean(formik.errors.wageExpectation) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.wageExpectation}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Hours per week
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.workingHrs}
                    name="workingHours"
                    prevSelectedOptions={formik.values.workingHours}
                    handleSelect={handleHoursChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.workingHours && Boolean(formik.errors.workingHours) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.workingHours}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                style={{ marginTop: spacing.xs }}
              >
                Experience Required
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.workExperience}
                    name="experience"
                    prevSelectedOptions={formik.values.experience}
                    handleSelect={handleExpChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.experience && Boolean(formik.errors.experience) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.experience}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Shift
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.timeSlots}
                    name="timeSlot"
                    prevSelectedOptions={formik.values.timeSlot}
                    handleSelect={handleTimeSlotChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.timeSlot && Boolean(formik.errors.timeSlot) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.timeSlot}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Days per week
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    name="daysSelected"
                    selectOptions={ProfileConstants.dayAvailable}
                    prevSelectedOptions={formik.values.daysSelected}
                    handleSelect={handleDaysSelectedChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.daysSelected && Boolean(formik.errors.daysSelected) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.daysSelected}
                  </FormHelperText>
                )}
              </>
            </Grid>
          </>
        )}

        {activeStepAddEditJob === 2 && (
          <Grid
            item
            xs={12}
            sx={{
              marginTop: spacing.s,
            }}
          >
            <JobDescriptionInput
              formik={formik}
              jobDescriptionFormik={jobDescriptionFormik}
              handleJobDescriptionChange={handleJobDescriptionChange}
              companyName={loggedInUser?.companyName}
              isJDGenerationComplete={isJDGenerationComplete}
              setIsJDGenerationComplete={setIsJDGenerationComplete}
              isDisabled={invalidLocation}
            />
            {Boolean(jobDescriptionFormik.errors.jobDesscription) && (
              <FormHelperText sx={{ color: colors.red }}>
                {jobDescriptionFormik.errors.jobDesscription}
              </FormHelperText>
            )}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: spacing.l,
          }}
        >
          {activeStepAddEditJob === 1 ? (
            <Button
              variant="contained"
              style={{ width: "12%", marginLeft: 30 }}
              size="large"
              onClick={formik.handleSubmit}
              disabled={invalidLocation}
            >
              {"Next"}
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ width: "12%", marginLeft: 30 }}
              size="large"
              onClick={
                activeStepAddEditJob === 1 ? formik.handleSubmit : jobDescriptionFormik.handleSubmit
              }
              disabled={!isJDGenerationComplete || (!formik.dirty && !jobDescriptionFormik.dirty)}
            >
              {isEditPress ? "Save" : "Post"}
            </Button>
          )}
          <Button
            style={{ width: "12%" }}
            size="large"
            onClick={onPressCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

AddJobView.propTypes = {
  activeStepAddEditJob: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  clearInput: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  googleInputRef: PropTypes.object.isRequired,
  handleAgeChange: PropTypes.func.isRequired,
  handleCancelLeaveModal: PropTypes.func.isRequired,
  handleDaysSelectedChange: PropTypes.func.isRequired,
  handleDeleteModal: PropTypes.func.isRequired,
  handleJobTitleChange: PropTypes.func.isRequired,
  handleExpChange: PropTypes.func.isRequired,
  handleHoursChange: PropTypes.func.isRequired,
  handleJobDescriptionChange: PropTypes.func.isRequired,
  handleTimeSlotChange: PropTypes.func.isRequired,
  handleWageChange: PropTypes.func.isRequired,
  invalidLocation: PropTypes.bool.isRequired,
  isCopy: PropTypes.bool.isRequired,
  isEditPress: PropTypes.bool.isRequired,
  jobDescriptionFormik: PropTypes.object.isRequired,
  jobLocationFormattedAddress: PropTypes.string.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  minAge: PropTypes.any,
  onBlurJobTitle: PropTypes.func.isRequired,
  onPlaceSelected: PropTypes.func.isRequired,
  onPressCancel: PropTypes.func.isRequired,
  setMinAge: PropTypes.func.isRequired,
  setShowDeleteJob: PropTypes.func.isRequired,
  setShowDuplicateJobAlert: PropTypes.func.isRequired,
  showCancelLeaveModal: PropTypes.bool.isRequired,
  showDeleteJob: PropTypes.bool.isRequired,
  showDuplicateJobAlert: PropTypes.bool.isRequired,
};

export default AddJobView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const ActiveBlockContainer = styled("div")`
  display: flex;
`;
