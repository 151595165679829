import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import LoginSelector from "../../containers/Login/selectors/LoginSelector";

import { connectDisconnectSocialApi, userDataDetails } from "../../configs/network/apis";
import { showNotification } from "../../utils/commonUtils";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { JOB_SEEKER } from "appConstants";

const ERROR_CONNECT = "Connect Failed, Please try again.";

export const useGetSocialAccountListQuery = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const { isLoading: isSocialAccountsLoading, data } = useQuery(
    ["social-account-list", loggedInUser?.uid, loggedInUser?.userType],
    () =>
      userDataDetails({
        uid: loggedInUser?.uid,
        type: loggedInUser?.userType,
      }),
  );

  return {
    isSocialAccountsLoading,
    socialAccountListData: data?.data?.social,
  };
};

export const useConnectDisconnectSocialMutation = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const queryClient = useQueryClient();
  const mutation = useMutation(connectDisconnectSocialApi, {
    onSuccess: () => {
      if (loggedInUser?.userType === JOB_SEEKER) {
        logEvent(FirebaseEvents.JS_CONNECTS_SOCIAL_ACCOUNT);
      } else {
        logEvent(FirebaseEvents.EMP_CONNECTS_SOCIAL_ACCOUNT);
      }
      queryClient.invalidateQueries("social-account-list");
    },
    onError: (error) => {
      showNotification({
        alertProps: {
          severity: "error",
          children:
            error?.response?.status === 409 ? error?.response?.data?.message : ERROR_CONNECT,
        },
      });
    },
  });
  const connectDisconnectSocial = (body) => mutation.mutateAsync(body);
  return [connectDisconnectSocial, mutation];
};
