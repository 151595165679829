import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { noop } from "lodash";

import { Icon, RichTextViewer, Text } from "components/atoms";
import JobSeekerSelector from "containers/JobSeeker/selectors/JobSeekerSelector";
import LoginSelector from "containers/Login/selectors/LoginSelector";
import AppliedModal from "./AppliedModal";
import JobNotFoundModal from "../JobNotFoundModal";
import RatingsModal from "../Ratings";

import { renderImage } from "utils/imageUtils";
import { images } from "assets/images";
import {
  decodeToHtml,
  getClickedOnApplyButtonAction,
  getFormattedJobLocation,
  getProcessedJobDetailValue,
  handleAsync,
  showNotification,
} from "utils/commonUtils";

import ShareJobDetailsModal from "./ShareJobDetailsModal";
import JobCardDetailHelpers from "./JobCardDetailHelpers";

import { EXPLORE_JOBS_PAGE_ROUTE, JOBSEEKER_PAGE_ROUTE } from "routes/routeConstants";

import { colors, spacing, styleUtils, textSizes, typography } from "styles";
import JobDetailCardSkeleton from "./JobDetailCardSkeleton";

const JobDetailCardMobile = ({
  onClickBrandLogo,
  setIsJobAbsent,
  isJobAbsent,
  fallbackPath = `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
  searchLocation = null,
  isJobDetailLoading,
  setIsJobDetailLoading,
}) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const brandName = searchParams.get("brandName");
  const forceUpdate = useSelector(JobSeekerSelector.getForceUpdateJobSeekerListing);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const isRatingDone = useSelector(LoginSelector.getUserRatingStatus);
  const [showRatingModal, setShowRatingModal] = useState(false);

  // TO-DO: This needs to be handle only in parent (DetailsMobileView) file because currently its calling an api twice.
  //Ideally logic should be in parent file and only data related to render should be passed down to the child component.
  const {
    item,
    init,
    onClickChat,
    onClickFavourite,
    appliedModalOpen,
    showShareJobModal,
    structuredData,
    handleAppliedModalClose,
    handleShareJobModal,
  } = JobCardDetailHelpers({
    setIsJobAbsent,
    isAuthenticated,
    isRatingDone,
    loggedInUser,
    brandName,
    jobId,
    searchLocation,
    setShowRatingModal,
    setIsJobDetailLoading,
  });

  const isFavourite = item?.jobSeekers?.includes(loggedInUser?.uid);
  const messageForDistanceValue = item?.distance
    ? `${item?.distance} miles away`
    : "Click to Navigate";

  useEffect(() => {
    if (item) {
      const isUnSignedUserClickedOnApply = getClickedOnApplyButtonAction();
      const isShowAlreadyAppliedJobMessage =
        isFavourite && isUnSignedUserClickedOnApply !== "false";
      if (isShowAlreadyAppliedJobMessage) {
        showNotification({
          alertProps: {
            severity: "warning",
            children: "You have already applied for this job",
          },
        });
      }
      if (isUnSignedUserClickedOnApply === "true" && item?.jobId && !isFavourite) {
        onClickFavourite();
      }
    }
  }, [isFavourite, item?.jobId, onClickFavourite]);

  useEffect(() => {
    init();
  }, [jobId, brandName, forceUpdate]);

  if (isJobDetailLoading) {
    return <JobDetailCardSkeleton isMobile={true} />;
  }

  const onClickMap = (e) => {
    e.stopPropagation();
    window.open("https://maps.google.com?q=" + `${item.lat}` + "," + `${item.lng}`);
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          border: `1px solid ${colors.lightBlue}`,
          borderRadius: "6px",
        }}
      >
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        <GridContainer
          container
          sx={{ alignItems: "flex-start", height: "auto" }}
        >
          <Grid
            item
            xs
            sm
            md
          >
            <TopContainer>
              <Avatar
                src={renderImage(item?.brandLogo, "job")}
                onClick={() => onClickBrandLogo(item)}
                variant="rounded"
                sx={{
                  width: 57,
                  height: 57,
                  cursor: "pointer",
                  borderRadius: 57 / 2,
                  marginBottom: "10px",
                }}
              >
                <img
                  src={images.defaults.job}
                  alt={item?.listingPosition}
                  style={{ width: 57, height: 57, borderRadius: 57 / 2 }}
                />
              </Avatar>
              <TitleContainer height="auto">
                <Text
                  weight="semibold"
                  size="l"
                  numberOfLines={4}
                >
                  {item?.listingPosition}
                </Text>
                <Text color={colors.placeholder}>{item?.brandName}</Text>
                <Text
                  color={colors.placeholder}
                  size="xs"
                >
                  {getFormattedJobLocation(item?.city)}
                </Text>
              </TitleContainer>
            </TopContainer>
          </Grid>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: `${spacing.m}`,
            }}
          >
            <CircularBox sx={{ marginTop: "15px", marginRight: "15px" }}>
              <div style={{ display: "flex" }}>
                <Text
                  weight="medium"
                  size="s"
                  numberOfLines={1}
                  sx={{ color: `${colors.royalBlue}`, fontWeight: "bold" }}
                >
                  ${getProcessedJobDetailValue(item?.wage)}/hr
                </Text>
              </div>
            </CircularBox>
          </div>
        </GridContainer>
        <Divider />
        <div
          style={{
            overflow: "auto",
            marginBottom: `calc(${textSizes.l} + 3 * ${spacing.m})`,
            paddingLeft: spacing.xl,
          }}
        >
          <Grid
            container
            overflow="auto"
            sx={{ padding: `0 ${spacing.s}` }}
          >
            <Grid
              item
              xs
              sm
              md
            >
              <Row>
                <Icon
                  name="money"
                  alt="money"
                  size={31}
                />
                <InfoBox>
                  <Text
                    size="xs"
                    color={colors.brownText}
                    weight="semibold"
                  >
                    Hourly Wage
                  </Text>
                  <Text
                    weight="semibold"
                    size={spacing.descrsize}
                    numberOfLines={1}
                  >
                    {`$${getProcessedJobDetailValue(item?.wage)} per hour`}
                  </Text>
                </InfoBox>
              </Row>
              <Row>
                <Icon
                  name="clock"
                  alt={"clock"}
                  size={31}
                />
                <InfoBox>
                  <Text
                    size="xs"
                    color={colors.brownText}
                    weight="semibold"
                  >
                    Working Hours
                  </Text>
                  <Text
                    weight="semibold"
                    size={spacing.descrsize}
                    numberOfLines={1}
                  >
                    {`${getProcessedJobDetailValue(item?.hoursListed)} hours per week`}
                  </Text>
                </InfoBox>
              </Row>
              <Row>
                <Icon
                  name="experience"
                  alt={"experience"}
                  size={31}
                />
                <InfoBox>
                  <Text
                    size="xs"
                    color={colors.brownText}
                    weight="semibold"
                  >
                    Experience Required
                  </Text>
                  <Text
                    weight="semibold"
                    size={spacing.descrsize}
                    color={colors.blackText}
                  >
                    {getProcessedJobDetailValue(item?.experience) === "Beginner"
                      ? getProcessedJobDetailValue(item?.experience)
                      : `${getProcessedJobDetailValue(item?.experience)} years`}
                  </Text>
                </InfoBox>
              </Row>
              <Row>
                <Icon
                  name="distance"
                  alt={"distance"}
                  size={31}
                />
                <InfoBox>
                  <Text
                    size="xs"
                    color={colors.brownText}
                    weight="semibold"
                  >
                    Distance to job location
                  </Text>
                  <Text
                    weight="semibold"
                    size={spacing.descrsize}
                    color={colors.blackText}
                  >
                    {messageForDistanceValue}
                  </Text>
                </InfoBox>
                <Icon
                  name="map-navigation"
                  size={24}
                  onClick={onClickMap}
                  style={{
                    alignSelf: "end",
                    cursor: "pointer",
                    marginLeft: spacing.xs,
                    marginBottom: spacing.xxs,
                  }}
                />
              </Row>
              <Row>
                <Icon
                  name="timeslot"
                  alt={"timeslot"}
                  size={31}
                />
                <InfoBox>
                  <Text
                    size="xs"
                    color={colors.brownText}
                    weight="semibold"
                  >
                    Shift
                  </Text>
                  <Text
                    weight="semibold"
                    size={spacing.descrsize}
                    numberOfLines={1}
                  >
                    {item?.timeSlot && Array.isArray(item?.timeSlot)
                      ? item?.timeSlot.join(", ")
                      : item?.timeSlot}
                  </Text>
                </InfoBox>
              </Row>
              <Row>
                <Icon
                  name="calendar"
                  alt={"calendar"}
                  size={31}
                />
                <InfoBox>
                  <Text
                    size="xs"
                    color={colors.brownText}
                    weight="semibold"
                  >
                    Working Days
                  </Text>
                  <Text
                    weight="semibold"
                    size={spacing.descrsize}
                    numberOfLines={1}
                  >
                    {item?.daysSelected && Array.isArray(item?.daysSelected)
                      ? item?.daysSelected.join(", ")
                      : item?.day}
                  </Text>
                </InfoBox>
              </Row>
            </Grid>
          </Grid>
          <div style={{ marginTop: `${spacing.m}` }}>
            <Divider />
          </div>
          <JobDetailsContainer>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{ marginTop: spacing.s }}
              size={spacing.s}
            >
              Job Description
            </Text>
            <RichTextViewer
              style={{
                fontSize: textSizes.s,
                lineHeight: "1.5",
                marginTop: spacing.xs,
                color: colors.descriptionGray,
              }}
            >
              {decodeToHtml(item?.listingDescription)}
            </RichTextViewer>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{ marginTop: spacing.xl }}
            >
              Short Note
            </Text>
            <Text
              size="s"
              color={colors.descriptionGray}
              style={{ marginTop: spacing.xs }}
            >
              {item?.note}
            </Text>
            {!!item?.min_age && (
              <>
                <Text
                  weight="semibold"
                  color={colors.blackText}
                  style={{ marginTop: spacing.xl }}
                >
                  Minimum Age for the job
                </Text>
                <Text
                  size="s"
                  color={colors.descriptionGray}
                  style={{ marginTop: spacing.xs }}
                >
                  {item?.min_age} Years
                </Text>
              </>
            )}
          </JobDetailsContainer>
          <Divider style={{ marginTop: spacing.s }} />
          <ChatPosterContainer>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{
                marginTop: spacing.s,
                marginBottom: spacing.xs,
              }}
            >
              Chat with employer
            </Text>
            <JobPosterFlexBox style={{ justifyContent: "space-between" }}>
              <JobPosterDetailsBox>
                <Text
                  weight="medium"
                  size={"m"}
                >
                  {item?.empName}
                </Text>
                <Text
                  size="xs"
                  color={colors.placeholder}
                >
                  {item?.empDesignation}
                </Text>
              </JobPosterDetailsBox>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: spacing.l,
                  borderRadius: 100,
                  height: styleUtils.pxToRem("38px"),
                  width: styleUtils.pxToRem("138px"),
                  fontSize: textSizes.m,
                  fontWeight: typography.semibold,
                }}
                onClick={onClickChat}
              >
                Chat Now
              </Button>
            </JobPosterFlexBox>
          </ChatPosterContainer>
        </div>

        <ApplyMobileContainer>
          <ApplyBtnMobile
            disabled={isFavourite}
            variant={isFavourite ? "outlined" : "contained"}
            onClick={isFavourite ? onClickFavourite : noop}
            sx={{
              border: `1px solid ${colors.darkBlue}`,
              "&:disabled": {
                borderColor: colors.border,
              },
            }}
            {...(!isFavourite && {
              onClick: handleAsync(onClickFavourite),
            })}
          >
            {isFavourite ? "Applied" : "Apply Now"}
          </ApplyBtnMobile>
          <Icon
            name="shareJob"
            onClick={() => handleShareJobModal(true)}
            size={48}
            style={{ marginLeft: spacing.s, marginRight: spacing.s, cursor: "pointer" }}
          />
        </ApplyMobileContainer>
      </Box>
      {appliedModalOpen && (
        <AppliedModal
          isOpen={appliedModalOpen}
          onClose={handleAppliedModalClose}
          jobDetails={item}
        />
      )}
      {showShareJobModal && (
        <ShareJobDetailsModal
          isOpen={showShareJobModal}
          jobLink={item?.finalLink}
          handleShareJobModal={handleShareJobModal}
        />
      )}
      <RatingsModal
        isOpen={showRatingModal}
        setShowRatingModal={setShowRatingModal}
      />
      {isJobAbsent && (
        <JobNotFoundModal
          isOpen={isJobAbsent}
          handleClickForJobNotFound={() => {
            navigate(
              {
                pathname: fallbackPath,
              },
              {
                state: {
                  handleInactiveLink: true,
                  locationDetails: {
                    lat: item?.lat,
                    lng: item?.lng,
                    locationName: item?.city,
                  },
                },
              },
            );
          }}
        />
      )}
    </>
  );
};

JobDetailCardMobile.propTypes = {
  onClickBrandLogo: PropTypes.func,
  setIsJobAbsent: PropTypes.func,
  isJobAbsent: PropTypes.bool,
  fallbackPath: PropTypes.string,
  searchLocation: PropTypes.object,
  isJobDetailLoading: PropTypes.bool.isRequired,
  setIsJobDetailLoading: PropTypes.func.isRequired,
};

const GridContainer = styled(Grid)`
  width: 100%;
  display: flex;
  background: transparent linear-gradient(79deg, #ffffff00 0%, ${colors.royalBlue} 200%) 0% 0%
    no-repeat padding-box;
  borderradius: 6px 6px 0px 0px;
`;
const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
  padding-left: ${spacing.s};
  flex-direction: column;
`;
const ChatPosterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;
const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s};
`;
const JobPosterFlexBox = styled("div")`
  display: flex;
`;
const JobPosterDetailsBox = styled("div")`
  padding-top: ${spacing.xxxs};
`;
const ApplyMobileContainer = styled("div")`
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: ${spacing.xl};
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0px -25px 20px ${colors.shadow};
  height: 60px;
`;
const CircularBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.xs};
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 10px ${colors.boxShadow};
  opacity: 1;
`;
const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.l};
`;
const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;
const JobDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;
const ApplyBtnMobile = styled(Button)`
  width: 100%;
  height: ${styleUtils.pxToRem("48px")};
  border-radius: 100;
  font-weight: ${typography.bold};
  font-size: ${textSizes.l};
`;
export default JobDetailCardMobile;
