import React from "react";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";

import { getReferralLink } from "../../../../../configs/network/apis";
import { Icon, Text } from "../../../../../components/atoms";
import { images } from "../../../../../assets/images";
import { colors, spacing } from "../../../../../styles";
import { logEvent } from "../../../../../configs/firebase";

import { REFERRAL_SHARE } from "../../../../../appConstants";
import { showNotification } from "../../../../../utils/commonUtils";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const Referrals = () => {
  const { data } = useQuery("referral", async () =>
    getReferralLink({
      source: "web",
    }),
  );

  const onClickShare = async () => {
    logEvent(FirebaseEvents.JS_CLICK_COPYANDSHARE_REFERRAL_LINK);
    if (navigator.share) {
      try {
        await navigator.share({
          url: data?.data?.referralLink?.shortLink,
          title: REFERRAL_SHARE.title,
          text: REFERRAL_SHARE.message,
        });
      } catch (e) {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Cannot share link. Please try again later.",
          },
        });
      }
    }
  };

  return (
    <TopContainer>
      <Text size="l" weight="semibold">
        Invite your friends to EmployD
      </Text>
      <Text size="s" style={{ marginTop: spacing.s }} color={colors.placeholder}>
        Refer a friend and you both earn $10 when your friend creates a complete profile
      </Text>
      <Text size="l" weight="semibold" style={{ marginTop: spacing.xl }}>
        Share this referral link
      </Text>
      <LinkWrapper>
        <Text size="s">{data?.data?.referralLink?.shortLink}</Text>
        <Icon onClick={onClickShare} name="profile-share" />
      </LinkWrapper>
      <img
        style={{
          width: 292,
          height: 312,
          transform: `translateX(${spacing.xl}) translateY(${spacing.xl})`,
        }}
        src={images["referral-banner"]}
        alt="Referral banner"
      />
    </TopContainer>
  );
};
export default Referrals;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 ${spacing.xl};
  height: 100%;
`;

const LinkWrapper = styled("div")`
  border-radius: 5px;
  border: 1px solid #e6edff;
  padding: ${spacing.s} ${spacing.xs};
  margin-top: ${spacing.xs};
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
`;
