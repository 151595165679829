import { takeLatest, put, all, call } from "redux-saga/effects";

import * as employerActionTypes from "../actions/actionTypes";
import {
  getBrandJobsApi,
  employerJobSeekerListingApi,
  deleteEmployerJobPostApi,
  schedulerGetAvailabilityList,
  schedulerGetMeetingList,
  schedulerGetShareLink,
  schedulerCreateAvailability,
} from "../../../configs/network/apis";
import { hideLoader, showLoader, showNotification } from "../../../utils/commonUtils";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

function* handleEmployerListing(action) {
  const { payload } = action;
  try {
    showLoader();
    const { data } = yield call(getBrandJobsApi, payload);
    hideLoader();
    yield put({
      type: employerActionTypes.EMPLOYER_LISTING_SUCCESS,
      data,
    });
  } catch (error) {
    hideLoader();
    yield put({ type: employerActionTypes.EMPLOYER_LISTING_FAILURE });
  }
}

function* watchEmployerListingRequest() {
  yield takeLatest(employerActionTypes.EMPLOYER_LISTING_REQUEST, handleEmployerListing);
}

function* handleEmployerJobSeekerListing(action) {
  const { payload } = action;
  try {
    const { data } = yield call(employerJobSeekerListingApi, payload);
    yield put({
      type: employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_SUCCESS,
      data,
    });
    logEvent(FirebaseEvents.EMP_CLICKS_SEARCH);
  } catch (error) {
    yield put({
      type: employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_FAILURE,
    });
  }
}

function* watchEmployerJobSeekerListingRequest() {
  yield takeLatest(
    employerActionTypes.EMPLOYER_JOB_SEEKER_LISTING_REQUEST,
    handleEmployerJobSeekerListing,
  );
}

//Delete Job Post
function* handleEmployerJobPostDeleting(action) {
  const { payload } = action;
  const { onCallback, jobId, deleteReason } = payload;

  try {
    showLoader();
    const { data } = yield call(deleteEmployerJobPostApi, { deleteReason }, jobId);
    yield put({
      type: employerActionTypes.EMPLOYER_JOB_DELETE_SUCCESS,
    });
    onCallback({
      deleteStatus: true,
      message: data,
    });
  } catch (error) {
    yield put({
      type: employerActionTypes.EMPLOYER_JOB_DELETE_FAILED,
    });
    onCallback(error);
  }
}

function* watchEmployerJobPostDeleteRequest() {
  yield takeLatest(employerActionTypes.EMPLOYER_JOB_DELETE_REQUEST, handleEmployerJobPostDeleting);
}

function* handleSchedulerGetAvailabilityList(action) {
  const { payload } = action;
  try {
    const { availabilities } = yield call(schedulerGetAvailabilityList, payload);
    yield put({
      type: employerActionTypes.SCHEDULER_GET_AVAILABILITY_LIST_SUCCESS,
      availabilities,
    });
    hideLoader();
  } catch (error) {
    hideLoader();
    yield put({
      type: employerActionTypes.SCHEDULER_GET_AVAILABILITY_LIST_FAILED,
    });
  }
}

function* watchSchedulerGetAvailabilityList() {
  yield takeLatest(
    employerActionTypes.SCHEDULER_GET_AVAILABILITY_LIST_REQUEST,
    handleSchedulerGetAvailabilityList,
  );
}

function* handleSchedulerGetUpcomingList(action) {
  const { payload } = action;
  try {
    const { meetings } = yield call(schedulerGetMeetingList, payload);
    yield put({
      type: employerActionTypes.SCHEDULER_GET_UPCOMING_LIST_SUCCESS,
      meetings,
    });
    hideLoader();
  } catch (error) {
    hideLoader();
    yield put({
      type: employerActionTypes.SCHEDULER_GET_UPCOMING_LIST_FAILED,
    });
  }
}

function* watchSchedulerGetUpcomingList() {
  yield takeLatest(
    employerActionTypes.SCHEDULER_GET_UPCOMING_LIST_REQUEST,
    handleSchedulerGetUpcomingList,
  );
}

function* handleSchedulerCreateAvailability(action) {
  const { payload } = action;
  try {
    const { availabilities, message } = yield call(schedulerCreateAvailability, payload);
    yield put({
      type: employerActionTypes.SCHEDULER_CREATE_AVAILABILITY_SUCCESS,
      availabilities,
    });
    hideLoader();
    showNotification({
      alertProps: {
        severity: "success",
        children: message,
      },
    });
  } catch (error) {
    hideLoader();
    showNotification({
      alertProps: {
        severity: "error",
        children: error?.response?.status === 409 ? error?.response?.data?.message : "Try Again",
      },
    });
    yield put({
      type: employerActionTypes.SCHEDULER_CREATE_AVAILABILITY_FAILED,
    });
  }
}

function* watchSchedulerCreateAvailability() {
  yield takeLatest(
    employerActionTypes.SCHEDULER_CREATE_AVAILABILITY_REQUEST,
    handleSchedulerCreateAvailability,
  );
}

function* handleSchedulerGetShareLink(action) {
  const { payload } = action;
  try {
    const { share_link } = yield call(schedulerGetShareLink, payload);
    //TO-DO: Integrate open source url shortener
    // const shareLinkUrl = yield urlShortener(share_link)
    yield put({
      type: employerActionTypes.SCHEDULER_GET_SHARE_LINK_SUCCESS,
      shareLinkUrl: share_link,
    });
    hideLoader();
  } catch (error) {
    hideLoader();
    showNotification({
      alertProps: {
        severity: "error",
        children: error?.response?.status === 404 ? error?.response?.data?.message : "Try Again",
      },
    });
    yield put({
      type: employerActionTypes.SCHEDULER_GET_SHARE_LINK_FAILED,
    });
  }
}

function* watchSchedulerGetShareLink() {
  yield takeLatest(
    employerActionTypes.SCHEDULER_GET_SHARE_LINK_REQUEST,
    handleSchedulerGetShareLink,
  );
}

export function* employerSagas() {
  yield all([
    watchEmployerListingRequest(),
    watchEmployerJobSeekerListingRequest(),
    watchEmployerJobPostDeleteRequest(),
    watchSchedulerGetAvailabilityList(),
    watchSchedulerGetUpcomingList(),
    watchSchedulerCreateAvailability(),
    watchSchedulerGetShareLink(),
  ]);
}
