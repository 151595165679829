import { useFormik } from "formik";
import noop from "lodash/noop";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { userSettingsUpdate } from "../../containers/Login/actions/LoginActions";

const useJobseekerListingFilter = (filterSettings, handleFilter, handleClose = noop) => {
  const dispatch = useDispatch();

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      distance: filterSettings?.distance || 50,
      wage: filterSettings?.wage?.length > 0 ? filterSettings.wage : [],
      hrsPerWeek: filterSettings?.hrsPerWeek?.length > 0 ? filterSettings.hrsPerWeek : [],
      experience: filterSettings?.experience?.length > 0 ? filterSettings.experience : [],
      days: filterSettings?.days?.length > 0 ? filterSettings.days : [],
      timeSlot: filterSettings?.timeSlot?.length > 0 ? filterSettings.timeSlot : [],
    },
    enableReinitialize: true,
  });

  const onChangeDistance = useCallback((value) => {
    setFieldValue("distance", value);
  }, []);

  const onChangeHourlyWages = useCallback((value) => {
    setFieldValue("wage", value);
  }, []);

  const onChangeHoursPerWeek = useCallback((value) => {
    setFieldValue("hrsPerWeek", value);
  }, []);

  const onChangeExperience = useCallback((value) => {
    setFieldValue("experience", value);
  }, []);

  const onChangeDays = useCallback((value) => {
    setFieldValue("days", value);
  }, []);

  const onChangeTimeslots = useCallback((value) => {
    setFieldValue("timeSlot", value);
  }, []);

  const onApply = () => {
    const { distance, wage, hrsPerWeek, experience, days, timeSlot } = values;

    handleFilter({
      distance: distance,
      wage: wage.includes("all") ? [] : wage,
      hrsPerWeek: hrsPerWeek.includes("all") ? [] : hrsPerWeek,
      experience: experience.includes("all") ? [] : experience,
      days: days.includes("all") ? [] : days,
      timeSlot: timeSlot.includes("all") ? [] : timeSlot,
    });
    handleClose();
  };

  const onReset = () => {
    handleFilter({
      distance: 50,
      wage: [],
      hrsPerWeek: [],
      experience: [],
      days: [],
      timeSlot: [],
    });
    dispatch(
      userSettingsUpdate("jobSeekerListingFilter", {
        distance: 50,
        wage: [],
        hrsPerWeek: [],
        experience: [],
        days: [],
        timeSlot: [],
      }),
    );
    resetForm({
      values: {
        distance: 50,
        wage: [],
        hrsPerWeek: [],
        experience: [],
        days: [],
        timeSlot: [],
      },
    });
    handleClose();
  };

  return {
    distance: values.distance,
    wage: values.wage,
    hrsPerWeek: values.hrsPerWeek,
    experience: values.experience,
    days: values.days,
    timeSlot: values.timeSlot,
    selectedValues: values,
    onChangeDistance,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeDays,
    onChangeTimeslots,
    onApply,
    onReset,
  };
};

export default useJobseekerListingFilter;
