//External imports
import { styled } from "@mui/material/styles";
import React from "react";
import { NavLink } from "react-router-dom";

//Internal Imports
import { images } from "assets/images";
import { colors, spacing } from "styles";
import { Text } from "components/atoms";
import {
  RESTRICTED_COUNTRY_DESCRIPTION,
  RESTRICTED_COUNTRY_HEADER,
} from "appConstants";
import useIsMobile from "hooks/useIsMobile";

const GoToBlogs = () => {
  return (
    <NavLink to="/blogs" style={{ textDecoration: "none" }}>
      <Text
        weight="bold"
        color={colors.darkBlue}
        size="m"
        sx={{ display: "inline-block", textDecoration: "underline" }}
      >
        Blogs
      </Text>
    </NavLink>
  );
};

const RestrictedCountry = () => {
  const isMobile = useIsMobile();

  return (
    <Wrapper sx={{ margin: isMobile ? "0" : "0 auto" }}>
      <img
        style={{ width: "auto", height: "100%" }}
        src={images["restrictedcountry-image"]}
        alt="Country not allowed cover"
      />
      <Text
        weight="semibold"
        size={isMobile ? "l" : "xl"}
        color={colors.labelBlack}
        sx={{ marginTop: spacing.m }}
      >
        {RESTRICTED_COUNTRY_HEADER}
      </Text>
      <Text
        color={colors.fadedBlack}
        size={isMobile ? "s" : "m"}
        sx={{
          marginTop: isMobile ? spacing.xs : spacing.s,
        }}
      >
        {RESTRICTED_COUNTRY_DESCRIPTION}
      </Text>
      <Text
        color={colors.fadedBlack}
        size={isMobile ? "s" : "m"}
        sx={{
          marginTop: isMobile ? spacing.xs : spacing.s,
        }}
      >
        You can checkout our <GoToBlogs /> for information on hiring &
        recruitment.
      </Text>
    </Wrapper>
  );
};

export default RestrictedCountry;

const Wrapper = styled("div")`
  padding: ${spacing.m};
  margin-top: ${spacing.l};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
