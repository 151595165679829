export const JobBoardConstants = {
  TITLE: "Find your next Job",
  SEARCH_INPUT_PLACEHOLDER: "search by jobs, skills",
  GOOLGE_INPUT_PLACEHOLDER: "enter location",
  SEARCH: "Search",
  POPULAR_SKILLS: "Popular skills:",
  FILTER_BY: "Filter by",
  SEARCH_RESULTS_SHOW: "Showing ",
  SEARCH_RESULTS_OF: " of ",
  SEARCH_RESULTS_JOBS: " jobs",
  NO_RECOMMENDED_JOBS_TITLE: "No jobs that match this search.",
  SIMILAR_JOBS_HERE: "Here are similar jobs",
  NO_RECOMMENDED_JOBS_DESCRIPTION:
    "Try expanding your search location or reduce any filters for more results.",
  LOCATION_PERMISSION: "Please provide location permission or enter a location to find jobs",
  WE_ARE_NOT_THERE: "Oh No ! We are not here yet.",
  COMING_SOON: "But we are moving fast and expanding to other regions soon.",
  AUTHEINICATED_COMMING_SOON:
    "We are not currently present at your location, but we will inform you about our presence very soon. You can try searching a job in ",
  CURRENT_REGION: "Newtown, Langhorne, or Yardley!",
  BE_THE_FIRST_ONE:
    "To be the first to know about our arrival in your area, leave your email below.",
  AUTHEINICATED_BE_THE_FIRST_ONE:
    "We’d love to help you find the right job! We’re looking forward to connecting with you soon",
  LOAD_MORE_JOBS: "Load more jobs",
  FETCHING_JOBS_TITLE: "Loading…",
  ENTER_SEARCH_FILED_TEXT: "Search for jobs by job title/ company name or by its location",
};

export const SearchBoxConstants = {
  REQUIRED_ASTERIK: "* ",
  SUB_TEXT_1: "We are currently available in ",
  SUB_TEXT_2: "Bucks County region, Pennsylvania",
  LOCATION_PERMISSION: "Please provide location permission or enter a location to find jobs",
};

export const MapViewConstants = {
  NO_RECOMMENDED_JOBS_TITLE: "No jobs that match this search.",
  SEARCH_RESULTS_SHOW: "Showing ",
  SEARCH_RESULTS_OF: " of ",
  SEARCH_RESULTS_JOBS: " jobs",
  SIMILAR_JOBS_TITLE: "Showing other similar jobs",

  NO_RECOMMENDED_JOBS_DESCRIPTION:
    "Try expanding your search location or reduce any filters for more results.",
  LOAD_MORE_JOBS: "Load more jobs",
  FETCHING_JOBS_TITLE: "Loading…",
  WAITING_TIME: "Please wait for some more time.",
};

export const FilterConstants = {
  RESET_FILTER: "Reset Filter",
  APPLY: "Apply",
  DISTANCE: "Distance",
  MILES: "miles",
  HOURLY_WAGES: "Hourly Wages",
  HOURS_PER_WEEK: "Hours/week",
  EXPERIENCE: "Experience",
  DAYSWEEK: "Days/Week",
  TIMESLOT: "Time Slot",
  JOBLISTING: "Job Listing",
  ALL: "All",
  EXPERIENCED: "Experienced",
  BEGINNER: "Beginner",
};

export const SORT_BY_BUTTON = "Sort by";
export const CLEAR_OPTION = "Clear";

export const sortingOptions = [
  {
    key: "wageExpectation",
    title: "Hourly wage",
    order: "",
  },
  {
    key: "workingHours",
    title: "Hours per week",
    order: "",
  },
  {
    key: "experience",
    title: "Experience",
    order: "",
  },
];

export const experienceFilter = [
  {
    id: 1,
    label: "All",
    value: "all",
    isSelected: false,
  },
  {
    id: 2,
    label: "Experienced",
    value: "Experience",
    isSelected: false,
  },
  {
    id: 3,
    label: "Beginner",
    value: "Beginner",
    isSelected: false,
  },
];

export const daysFilter = [
  {
    id: 1,
    label: "All",
    value: "all",
    isSelected: false,
  },
  {
    id: 2,
    label: "Mon",
    value: "Mon",
    isSelected: false,
  },
  {
    id: 3,
    label: "Tue",
    value: "Tue",
    isSelected: false,
  },
  {
    id: 4,
    label: "Wed",
    value: "Wed",
    isSelected: false,
  },
  {
    id: 5,
    label: "Thu",
    value: "Thu",
    isSelected: false,
  },
  {
    id: 6,
    label: "Fri",
    value: "Fri",
    isSelected: false,
  },
  {
    id: 7,
    label: "Sat",
    value: "Sat",
    isSelected: false,
  },
  {
    id: 8,
    label: "Sun",
    value: "Sun",
    isSelected: false,
  },
];

export const timeSlotFilter = [
  {
    id: 1,
    label: "All",
    value: "all",
    isSelected: false,
  },
  {
    id: 2,
    label: "Morning",
    value: "Morning",
    isSelected: false,
  },
  {
    id: 3,
    label: "Afternoon",
    value: "Afternoon",
    isSelected: false,
  },
  {
    id: 4,
    label: "Evening",
    value: "Evening",
    isSelected: false,
  },
  {
    id: 5,
    label: "Night",
    value: "Night",
    isSelected: false,
  },
];

export const jobListingFilter = [
  {
    id: 1,
    label: "All",
    value: "",
    isSelected: false,
  },
  {
    id: 2,
    label: "Verified",
    value: "verified",
    isSelected: false,
  },
];
