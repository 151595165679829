import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import pull from "lodash/pull";
import PropTypes from "prop-types";

import { Icon, Text } from "../../components/atoms";
import { colors, spacing } from "../../styles";
import LoginSelector from "../Login/selectors/LoginSelector";
import {
  getIsAccountDeleted,
  hideLoader,
  showLoader,
  showNotification,
} from "../../utils/commonUtils";
import { userDetailUpdate } from "../Login/actions/LoginActions";
import { blockUser, reportUser } from "../../configs/network/apis";
import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import ReportModal from "./ReportModal";
import AbsentAccountModal from "components/molecules/AbsentAccountModal";
import { useNavigate } from "react-router-dom";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const ChatSettings = ({
  isBlocked,
  userId,
  blockedUsers,
  setBlockedUsers,
  selectedChatUser,
  getChatUsers,
}) => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [isAccountAbsent, setIsAccountAbsent] = useState(false);

  const handleReportModalClose = () => {
    setReportModalOpen(false);
  };

  const onClickBlock = async () => {
    const isAccountDeleted = await getIsAccountDeleted(
      selectedChatUser?.userId ? selectedChatUser?.userId : selectedChatUser?.uid,
      selectedChatUser?.userType,
    );
    if (isAccountDeleted) {
      setIsAccountAbsent(true);
    } else {
      setIsAccountAbsent(false);
      if (isBlocked) {
        unblockChat();
      } else {
        setReportModalOpen(true);
      }
    }
  };

  const unblockChat = async () => {
    showLoader();
    try {
      const res = await blockUser({
        blockedUserId: userId,
        blockedUserType: loggedInUser?.userType === EMPLOYER ? JOB_SEEKER : EMPLOYER,
      });
      setBlockedUsers(pull(blockedUsers, userId));
      dispatch(
        userDetailUpdate({
          blockedIds: res?.data?.blockedIds,
        }),
      );
      showNotification({
        alertProps: {
          severity: "success",
          children: res.data.message,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };

  const reportChat = async ({ message, category }) => {
    try {
      const res = await reportUser({
        reportedUserId: userId,
        message: `${category}:${message}`,
      });
      if (res?.data?.blockedIds.length >= 0) {
        setBlockedUsers([...blockedUsers, selectedChatUser]);
        dispatch(
          userDetailUpdate({
            blockedIds: [...res.data.blockedIds],
          }),
        );
      }
      showNotification({
        alertProps: {
          severity: "success",
          children: res?.data?.message,
        },
      });
    } catch (error) {
      hideLoader();
      showNotification({
        alertProps: {
          severity: "error",
          children: "Unable to report user",
        },
      });
    }
  };

  const reportAndBlock = async (values) => {
    showLoader();
    await reportChat(values);
    hideLoader();
    handleReportModalClose();
    if (loggedInUser?.userType === JOB_SEEKER) {
      logEvent(FirebaseEvents.JS_BLOCK_EMPLOYER);
    } else {
      logEvent(FirebaseEvents.EMP_BLOCK_JOBSEEKER);
    }
  };

  return (
    <>
      <Column>
        <Icon
          color={isBlocked ? colors.black : colors.primary}
          size={20}
          name={isBlocked ? "unblock-user" : "block-user"}
          onClick={onClickBlock}
          style={{ cursor: "pointer" }}
        />
        <Text size="xxs" color="#5E5E5E" sx={{ marginTop: spacing.xxs }}>
          {isBlocked ? "Unblock" : "Block"}
        </Text>
      </Column>
      {reportModalOpen && (
        <ReportModal
          isOpen={reportModalOpen}
          onClose={handleReportModalClose}
          handleBlockAndReport={reportAndBlock}
        />
      )}
      {isAccountAbsent && (
        <AbsentAccountModal
          isOpen={isAccountAbsent}
          userType={selectedChatUser.userType}
          handleAccountErrorClick={() => {
            navigate({ pathname: "/chat" });
            getChatUsers();
          }}
        />
      )}
    </>
  );
};

ChatSettings.propTypes = {
  isBlocked: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  blockedUsers: PropTypes.array.isRequired,
  setBlockedUsers: PropTypes.func.isRequired,
  selectedChatUser: PropTypes.object.isRequired,
  getChatUsers: PropTypes.func.isRequired,
};

export default ChatSettings;

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing.xs} ${spacing.s};
`;
