import { Button, Grid, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { isEmpty } from "lodash";

import { useSelector } from "react-redux";

import { Icon, Text } from "../../../../../../../components/atoms";

import {
  NonServiceableArea,
  JobCardSkeleton,
} from "../../../../../../../components/molecules";

import JobSeekerSelector from "../../../../../selectors/JobSeekerSelector";
import JobResults from "../../JobResults";
import Filter from "./Filter";
import Sort from "./Sort";

import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../../../../../styles";
import { JobBoardConstants } from "../../Constants";

const ListView = (props) => {
  const {
    toggleExploreView,
    jobSeekerListing,
    jobSeekerSuggestedResults,
    filterSettings,
    location,
    onClickJob,
    onClickBrandLogo,
    loggedInUser,
    handleSorting,
    handleFilter,
    isLocationAllowed,
    handleLoadMore,
    showLoadMore,
    totalJobsCount,
    emailId,
    setEmailId,
    onPressNotify,
    isJobNotFound,
    handleJobNotFound,
  } = props;

  const isJobListFetching = useSelector(JobSeekerSelector.isJobListFetching);
  const jobSeekerloadinglist = [1, 2, 3, 4, 5, 6];
  const showEmptyResultView = isEmpty(location?.locationName);

  const renderJobCardSekeleton = (value) => {
    return (
      <Grid
        key={value}
        item
        md={4}
        mb={spacing.l}
      >
        <JobCardSkeleton />
      </Grid>
    );
  };

  const handleChange = (event) => {
    setEmailId(event.target.value);
  };

  const isShowingSuggestedJobs = isEmpty(jobSeekerListing);
  const finalJobsDataToRender = isShowingSuggestedJobs
    ? jobSeekerSuggestedResults
    : jobSeekerListing;

  const showTextForLoadingJobs = isJobListFetching ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: styleUtils.pxToRem("10px"),
      }}
    >
      <Text weight="semibold">Loading jobs</Text>
      <CircularProgress
        size="1rem"
        color="inherit"
        sx={{ marginLeft: styleUtils.pxToRem("10px") }}
      />
    </div>
  ) : (
    //To keep space occupied even though content is not there.
    <div></div>
  );

  return (
    <>
      {isLocationAllowed ? (
        <Grid
          sx={{ minHeight: "66vh", width: "80vw" }}
          container
          columnSpacing={spacing.l}
          mt={spacing.xxl}
        >
          <>
            <Grid
              item
              md={3}
            >
              <Filter
                filterSettings={filterSettings}
                handleFilter={handleFilter}
              />
            </Grid>
            <Grid
              item
              md={9}
            >
              <TitleContainer>
                {totalJobsCount !== 0 ? (
                  <Text sx={{ paddingTop: styleUtils.pxToRem("10px") }}>
                    <Text component="span">
                      {JobBoardConstants.SEARCH_RESULTS_SHOW}
                    </Text>
                    <Text
                      weight="bold"
                      component="span"
                    >
                      {jobSeekerListing?.length}
                    </Text>
                    <Text component="span">
                      {JobBoardConstants.SEARCH_RESULTS_OF}
                    </Text>
                    <Text
                      weight="bold"
                      component="span"
                    >
                      {totalJobsCount}
                    </Text>
                    {JobBoardConstants.SEARCH_RESULTS_JOBS}
                  </Text>
                ) : (
                  showTextForLoadingJobs
                )}
                <ToggleButtonContainer>
                  <Sort handleSorting={handleSorting} />
                  <MapToggleButton
                    sx={{
                      "&:hover": {
                        backgroundColor: colors.dodgerBlue,
                      },
                    }}
                    onClick={toggleExploreView}
                  >
                    <Icon
                      name="map"
                      color={colors.white}
                      size={14}
                    />
                    Map View
                  </MapToggleButton>
                </ToggleButtonContainer>
              </TitleContainer>
              {isJobListFetching ? (
                <Grid
                  container
                  mt={spacing.xxs}
                  columnSpacing={spacing.l}
                >
                  {jobSeekerloadinglist.map(renderJobCardSekeleton)}
                </Grid>
              ) : (
                <>
                  <JobResults
                    finalJobsDataToRender={finalJobsDataToRender}
                    isShowingSuggestedJobs={isShowingSuggestedJobs}
                    onClickJob={onClickJob}
                    onClickBrandLogo={onClickBrandLogo}
                    isJobNotFound={isJobNotFound}
                    handleJobNotFound={handleJobNotFound}
                    showEmptyResultView={showEmptyResultView}
                  />

                  {showLoadMore && (
                    <LoadMoreBtn
                      variant="outlined"
                      onClick={handleLoadMore}
                      endIcon={
                        <ArrowDownwardIcon sx={{ color: colors.primary }} />
                      }
                    >
                      {JobBoardConstants.LOAD_MORE_JOBS}
                    </LoadMoreBtn>
                  )}
                </>
              )}
            </Grid>
          </>
        </Grid>
      ) : (
        <Grid
          item
          style={{ marginTop: spacing.xxs }}
        >
          <NonServiceableArea
            image="nonserviceblearea-image"
            imageHeight="50%"
            title={JobBoardConstants.WE_ARE_NOT_THERE}
            description={
              loggedInUser
                ? JobBoardConstants.AUTHEINICATED_COMMING_SOON
                : JobBoardConstants.COMING_SOON
            }
            activeRegion={JobBoardConstants.CURRENT_REGION}
            sub_description={
              loggedInUser
                ? JobBoardConstants.AUTHEINICATED_BE_THE_FIRST_ONE
                : JobBoardConstants.BE_THE_FIRST_ONE
            }
            handleChange={handleChange}
            emailId={emailId}
            onPressNotify={onPressNotify}
            loggedInUser={loggedInUser}
          />
        </Grid>
      )}
    </>
  );
};

export default ListView;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${spacing.m};
  margin-right: ${spacing.s};
`;
const LoadMoreBtn = styled(Button)`
  display: flex;
  font-size: ${textSizes.xl};
  font-weight: ${typography.medium};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("305px")};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.xxl};
`;
const MapToggleButton = styled(Button)`
  align-items: center;
  background-color: ${colors.dodgerBlue};
  border-radius: ${styleUtils.pxToRem("18px")};
  color: ${colors.white};
  font-size: ${textSizes.s};
  font-weight: ${typography.bold};
  justify-content: center;
  padding-top: ${spacing.xs};
  width: ${styleUtils.pxToRem("130px")};
  height: ${styleUtils.pxToRem("36px")};
  gap: 7px;
`;

const ToggleButtonContainer = styled("div")`
  display: flex;
  gap: 20px;
`;
