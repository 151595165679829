import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../styles";
import DownloadApp from "./DownloadApp";

import LoginSelector from "containers/Login/selectors/LoginSelector";

import {
  TERMS_CONDITION,
  PRIVACY_POLICY,
  BLOGS,
  EMPLOYER_HOME,
} from "../../../routes/routeConstants";

import { EMPLOYER, SOCIAL_LINKS } from "../../../appConstants";
import {
  FOOTER_LINKS,
  EMPLOYER_FOOTER_DESCRIPTION_TEXT,
  FooterConstants,
  JOB_SEEKER_FOOTER_DESCRIPTION_TEXT,
} from "../Constants";
import { ACCOUNT_ACTIVATION_CONSTS } from "containers/SignUp/constants";

const Footer = () => {
  const { pathname } = useLocation();
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const isEmployer = pathname === EMPLOYER_HOME || loggedInUser?.userType === EMPLOYER;
  const descriptionConstants = isEmployer
    ? EMPLOYER_FOOTER_DESCRIPTION_TEXT
    : JOB_SEEKER_FOOTER_DESCRIPTION_TEXT;

  return (
    <FooterWrapper>
      <Container sx={{ py: spacing.xxxl }}>
        <Grid
          container
          columnSpacing={spacing.xxxl}
        >
          <Grid
            item
            md={5}
          >
            <Text
              color={colors.white}
              size={30}
              weight="bold"
              style={{ lineHeight: 1.2 }}
            >
              {FooterConstants.EMPLOYD_TAG}
            </Text>
            <DownloadApp />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: colors.footerBlue }}
          />
          <Grid
            item
            md={4}
          >
            <NavLink
              to={BLOGS}
              style={{ textDecoration: "none" }}
            >
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.BLOGS}
              </Text>
            </NavLink>
            <a
              href={`mailto:${ACCOUNT_ACTIVATION_CONSTS.SUPPORT_EMAIL}`}
              style={{ textDecoration: "none" }}
            >
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.CONTACT_US}
              </Text>
            </a>
            <NavLink
              to={PRIVACY_POLICY}
              style={{ textDecoration: "none" }}
            >
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.PRIVACY_POLICY}
              </Text>
            </NavLink>
            <NavLink
              to={TERMS_CONDITION}
              style={{ textDecoration: "none" }}
            >
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.TERMS_AND_CONDITION}
              </Text>
            </NavLink>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: colors.footerBlue, marginRight: spacing.xxxl }}
          />
          <Grid
            item
            md
          >
            <QRBox>
              <QRImage
                src={images.home.app.qr}
                alt="qr"
              />
              <Text
                weight="medium"
                size="xxs"
                style={{
                  textAlign: "center",
                  marginTop: spacing.s,
                }}
              >
                Scan with your phone
              </Text>
              <Text
                weight="medium"
                size="xxs"
                style={{
                  textAlign: "center",
                }}
              >
                camera to download the app
              </Text>
            </QRBox>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: colors.footerBlue, marginTop: spacing.xxl }} />
        <FooterLinksContainer>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.facebook}
          >
            <SocialImage
              src={images.social.facebook}
              alt="Facebook"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.twitter}
          >
            <SocialImage
              src={images.social.twitter}
              alt="Twitter"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.instagram}
          >
            <SocialImage
              src={images.social.instagram}
              alt="Instagram"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.linkedin}
          >
            <SocialImage
              src={images.social.linkedin}
              alt="LinkedIn"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.tiktok}
          >
            <SocialImage
              src={images.social.tiktok}
              alt="TikTok"
            />
          </a>
          <Text
            size="s"
            color={colors.white}
            sx={{ marginLeft: "auto" }}
          >
            {FooterConstants.COPYRIGHT_RIGHTS_RESERVED}
          </Text>
        </FooterLinksContainer>

        <Divider
          flexItem
          sx={{ borderColor: colors.footerBlue, margin: `${spacing.xl} 0` }}
        />

        <FooterJobLinksTextContainer>
          {FOOTER_LINKS.map((item, index) => (
            <FooterJobLinksColumnWrapper key={item.label}>
              <FooterJobLinksTextWrapper>
                <FooterJobLinksTitle>{item.label}</FooterJobLinksTitle>
                {item.information.map((itemDetails) => (
                  <FooterJobLinksAnchorTag
                    rel="noopener noreferrer"
                    target="_blank"
                    href={itemDetails.link}
                    key={itemDetails.link}
                  >
                    <Text color={colors.white}>{itemDetails.text}</Text>
                  </FooterJobLinksAnchorTag>
                ))}
              </FooterJobLinksTextWrapper>

              {index < FOOTER_LINKS.length - 1 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: colors.footerBlue, margin: `${spacing.xl} 0` }}
                />
              )}
            </FooterJobLinksColumnWrapper>
          ))}
        </FooterJobLinksTextContainer>

        <Divider
          flexItem
          sx={{ borderColor: "#214093", margin: `${spacing.xl} 0` }}
        />
        <FooterDescriptionTextWrapper>
          {/* <h1> tag used for SEO  */}
          <h1 style={{ fontSize: "1rem", marginBottom: spacing.xxs, fontFamily: "Mulish" }}>
            {descriptionConstants[0].DESCRIPTION_TITLE}
          </h1>
          <FooterDescription>{descriptionConstants[0].DESCRIPTION}</FooterDescription>
        </FooterDescriptionTextWrapper>
        {descriptionConstants?.slice(1)?.map((item) => (
          <FooterDescriptionTextWrapper key={item?.DESCRIPTION_TITLE}>
            <FooterDescriptionTitle>{item?.DESCRIPTION_TITLE}</FooterDescriptionTitle>
            <FooterDescription>{item?.DESCRIPTION}</FooterDescription>
          </FooterDescriptionTextWrapper>
        ))}
      </Container>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled("footer")`
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SocialImage = styled("img")`
  width: ${styleUtils.pxToRem("30px")};
  height: ${styleUtils.pxToRem("30px")};
`;
const QRImage = styled("img")`
  width: ${styleUtils.pxToRem("109px")};
  height: ${styleUtils.pxToRem("109px")};
`;
const FooterLinksContainer = styled("div")`
  align-items: center;
  display: flex;
  gap: ${styleUtils.pxToRem("26px")};
  justify-content: space-between;
  margin-top: ${spacing.l};
  margin-bottom: ${spacing.l};
`;
const QRBox = styled("div")`
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001c;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: ${styleUtils.pxToRem("204px")};
  margin-left: ${spacing.l};
  padding: ${spacing.s} 0;
  width: ${styleUtils.pxToRem("169px")};
`;

const FooterDescriptionTextWrapper = styled("div")`
  color: ${colors.white};
  margin-bottom: ${spacing.xl};
`;

const FooterDescriptionTitle = styled(Text)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: ${spacing.xxs};
`;

const FooterDescription = styled(Text)`
  color: ${colors.white};
  white-space: pre-line;
`;

const FooterJobLinksTitle = styled(Text)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: ${spacing.s};
`;

const FooterJobLinksTextContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterJobLinksColumnWrapper = styled("div")`
  display: flex;
  flex: 1;
  justify-content: space-around;
`;

const FooterJobLinksTextWrapper = styled("div")`
  color: ${colors.white};
`;

const FooterJobLinksAnchorTag = styled("a")`
  text-decoration: none;
  display: block;
  margin-bottom: ${spacing.descrsize};
  white-space: nowrap;
`;
