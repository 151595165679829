import * as loginActions from "./actionTypes";

/**
 * Function to dispatch job seeker login request
 */
export const loginRequest = (payload) => ({
  type: loginActions.LOGIN_REQUEST,
  payload,
});

//Social Login
export const socialLoginRequest = (payload) => ({
  type: loginActions.SOCIAL_LOGIN_REQUEST,
  payload,
});

//User Preferences or Settings
export const userSettingsUpdate = (settingType, settings) => ({
  type: loginActions.USER_SETTINGS_UPDATE,
  payload: { settingType, settings },
});

//Current Location
export const userLocationUpdate = (location) => ({
  type: loginActions.USER_LOCATION_UPDATE,
  payload: location,
});

export const userIsLocationUpdating = (isLoadingStart) => ({
  type: loginActions.USER_LOCATION_UPDATING_STATUS,
  payload: isLoadingStart,
});

//Search Location
export const searchLocationUpdate = (location) => ({
  type: loginActions.USER_SEARCH_LOCATION_UPDATE,
  payload: location,
});

//Update user
export const userUpdate = (payload) => {
  return {
    type: loginActions.USER_DETAILS_UPDATE,
    payload,
  };
};

export const userDetailUpdate = (payload) => {
  return {
    type: loginActions.USER_DETAIL_UPDATE,
    payload,
  };
};

//Logout
export const logoutRequest = (payload, usertype) => ({
  type: loginActions.LOGOUT,
  payload,
  usertype,
});

export const setLoginOnSignUp = (userData) => ({
  type: loginActions.SET_SIGN_UP_USER,
  userData,
});

//blocked Users
export const getBlockedUsersList = (payload) => ({
  type: loginActions.GET_BLOCKED_USERS_LIST_REQUEST,
  payload,
});

export const getUnblockedUsersList = (payload) => ({
  type: loginActions.GET_RECENT_UNBLOCKED_USERS_LIST_REQUEST,
  payload,
});

/**
 * Action function to dispatch forgot password otp share request
 */
export const shareForgotPasswordOtpRequest = (payload) => {
  return {
    type: loginActions.FORGOT_PASSWORD_OTP_REQUEST,
    payload,
  };
};

/**
 * Action function to dispatch forgot password otp verify request
 */
export const verifyForgotPasswordOtpRequest = (payload) => {
  return {
    type: loginActions.VERIFY_FORGOT_PASSWORD_OTP_REQUEST,
    payload,
  };
};
