import { JobSeekerSearchInput } from "../../../../../../../components/molecules";
import { Text } from "../../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../../styles";

import { SearchBoxConstants } from "../../Constants";

const SearchBox = ({
  clearInput,
  googleInputRef,
  location,
  mobileSearchValues,
  onChangeSearchTerm,
  onClickSearch,
  onPlaceSelected,
  searchTerm,

  jobSuggestions,
  fetchJobSuggestions,
  setJobSearchValue,
}) => {
  return (
    <>
      <JobSeekerSearchInput
        sx={{ mt: spacing.xs, marginLeft: spacing.m, marginRight: spacing.s }}
        searchTermValue={searchTerm}
        mobileSearchValues={mobileSearchValues}
        onChangeSearchTerm={onChangeSearchTerm}
        googleInputRef={googleInputRef}
        onGooglePlaceSelected={onPlaceSelected}
        onClearGoogleInput={clearInput}
        onClickSearch={onClickSearch(searchTerm)}
        jobSuggestions={jobSuggestions}
        fetchJobSuggestions={fetchJobSuggestions}
        setJobSearchValue={setJobSearchValue}
      />
      {(!location?.lat || !location?.lng) && (
        <Text size="xs" color={colors.red} style={{ marginTop: spacing.l }}>
          {SearchBoxConstants.LOCATION_PERMISSION}
        </Text>
      )}
    </>
  );
};

export default SearchBox;
