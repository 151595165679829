import { Button, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text, TextInput } from "../../../../../../components/atoms";

import useIsMobile from "../../../../../../hooks/useIsMobile";
import {
  colors,
  spacing,
  textSizes,
  styleUtils,
} from "../../../../../../styles";
import { SCHEDULER_EMAIL_CONSTS } from "../../constants";

const SchedulerEmailView = ({ emailValidationFormik }) => {
  const isMobile = useIsMobile();

  return (
    <Grid container>
      <Grid
        sx={{
          px: spacing.xl,
          marginLeft: styleUtils.pxToRem(isMobile ? "24px" : "48px"),
          marginTop: styleUtils.pxToRem(isMobile ? "24px" : "48px"),
          height: styleUtils.pxToRem(isMobile ? "643px" : "auto"),
          padding: isMobile ? spacing.xxxs : spacing.s,
        }}
      >
        {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Logo src={images.logo} />
                  {isMobile && <Icon size={16} name="close" onClick={goBack} />}
                </div> */}
        <Text
          size="l"
          weight="semibold"
          color={colors.titleBlack}
          style={{ marginBottom: spacing.l }}
        >
          Scheduler
        </Text>
        <Text color={colors.labelBlack}>
          {SCHEDULER_EMAIL_CONSTS.EMAIL_HEADER}
        </Text>
        <TextInput
          placeholder={SCHEDULER_EMAIL_CONSTS.EMAIL_PLACEHOLDER}
          name="email"
          onChange={emailValidationFormik.handleChange}
          autoComplete="email"
          value={emailValidationFormik.values.email}
          sx={{
            border: "1px solid #E0E0E0",
            borderRadius: styleUtils.pxToRem("4px"),
            height: styleUtils.pxToRem("42px"),
            marginTop: spacing.xs,
            width: styleUtils.pxToRem(isMobile ? "304px" : "432px"),
          }}
        />
        {emailValidationFormik.touched.email &&
          Boolean(emailValidationFormik.errors.email) && (
            <ErrorContainer>
              {emailValidationFormik.errors.email}
            </ErrorContainer>
          )}

        <Button
          variant="contained"
          onClick={emailValidationFormik.handleSubmit}
          sx={{
            fontSize: textSizes.m,
            marginTop: styleUtils.pxToRem("56px"),
            marginRight: "3rem",
            height: styleUtils.pxToRem("42px"),
            width: styleUtils.pxToRem(isMobile ? "302px" : "432px"),
          }}
        >
          {SCHEDULER_EMAIL_CONSTS.NEXT_BUTTON}
        </Button>
      </Grid>
      {!isMobile && (
        <>
          <Divider orientation="vertical" flexItem sx={{ height: "100%" }} />
        </>
      )}
    </Grid>
  );
};

export default SchedulerEmailView;

const ErrorContainer = styled("div")`
  color: ${colors.red};
  margin-bottom: 3%;
  font-size: ${textSizes.xxs};
`;
