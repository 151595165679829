//Public route title tags
export const EMPLOYD = "EmployD";
export const DEFAULT_TITLE_TAG = "EmployD | Find Local Part Time Jobs Near Me";
export const BLOGS_TITLE_TAG =
  "Career Advice Questions | Part Time Job Search Advice | Hiring Tips";
export const PRIVACY_POLICY_TITLE_TAG = "Privacy Policy | EmployD";
export const TERMS_AND_CONDITION_TITLE_TAG = "Terms and Conditions | EmployD";
//Employer title tags
export const EMPLOYER_TITLE_TAGS = {
  EMPLOYER_HOME: "Post a Job for Free | Quickly Hire Part Time Workers | EmployD for Employers",
  EMPLOYER_LOGIN: "Employer Sign In | EmployD",
};

//JobSeeker title tags
export const JOBSEEKER_TITLE_TAGS = {
  JOBSEEKER_LOGIN: "Job Seeker Sign In | EmployD",
};

//JobSeeker Explore jobs page title tags
export const JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS = {
  JOBSEEKER_NO_LOCATION_SEARCHED_TITLE_TAG: "Find Local Part Time Jobs Near Me | EmployD ",
  JOBSEEKER_LOCATION_ALLOWED_TITLE_TAG_PREFIX: "Part Time Jobs in ",
  PART_TIME_TITLE: "Part Time ",
  JOBS_TITLE_TEXT: " Jobs ",
  IN_TITLE_TEXT: " in ",
  EXPLORE_TITLE_TEXT: "Explore ",
};
