import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormLabel,
  FormHelperText,
  Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { TextInput } from "../../../../../components/atoms";

import { colors, spacing, styleUtils, textSizes } from "../../../../../styles";
import { SEEKERS_BOOK_SLOT_FORM_CONSTS } from "../constants";

const SchedulerBookSlotForm = ({
  handleBookSlotFormModal,
  showBookSlotFormModal,
  schdedulerBookSlotFormik,
}) => {
  return (
    <Modal open={showBookSlotFormModal} style={{ backdropFilter: "blur(2px)" }}>
      <Container
        sx={{
          mt: spacing.xxl,
          backgroundColor: colors.white,
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "none",
            width: "30%",
            height: "50%",
            borderRadius: "1.5rem",
          }}
        >
          <DetailsFormContainer>
            <FormControl
              variant="standard"
              required
              sx={{ marginTop: styleUtils.pxToRem("24px") }}
            >
              <FormLabel
                sx={{
                  color: colors.labelBlack,
                  marginBottom: styleUtils.pxToRem("6px"),
                }}
              >
                {SEEKERS_BOOK_SLOT_FORM_CONSTS.SEEKERS_DETAILS_FORM_EMAIL_LABEL}
              </FormLabel>
              <DetailsFormTextInput
                name="email"
                value={schdedulerBookSlotFormik.values.email}
                onChange={schdedulerBookSlotFormik.handleChange}
                error={
                  schdedulerBookSlotFormik.touched.email &&
                  Boolean(schdedulerBookSlotFormik.errors.email)
                }
                placeholder={
                  SEEKERS_BOOK_SLOT_FORM_CONSTS.SEEKERS_DETAILS_FORM_EMAIL_PLACEHOLDER
                }
              />
              {schdedulerBookSlotFormik.touched.email &&
                Boolean(schdedulerBookSlotFormik.errors.email) && (
                  <DetailsFormHelperText>
                    {schdedulerBookSlotFormik.errors.email}
                  </DetailsFormHelperText>
                )}
            </FormControl>
            <FormControl
              variant="standard"
              required
              sx={{ marginTop: styleUtils.pxToRem("24px") }}
            >
              <FormLabel
                sx={{
                  color: colors.labelBlack,
                  marginBottom: styleUtils.pxToRem("6px"),
                }}
              >
                {SEEKERS_BOOK_SLOT_FORM_CONSTS.SEEKERS_DETAILS_FORM_NAME_LABEL}
              </FormLabel>
              <DetailsFormTextInput
                name="fullName"
                value={schdedulerBookSlotFormik.values.fullName}
                onChange={schdedulerBookSlotFormik.handleChange}
                error={
                  schdedulerBookSlotFormik.touched.fullName &&
                  Boolean(schdedulerBookSlotFormik.errors.fullName)
                }
                placeholder={
                  SEEKERS_BOOK_SLOT_FORM_CONSTS.SEEKERS_DETAILS_FORM_NAME_PLACEHOLDER
                }
              />
              {schdedulerBookSlotFormik.touched.fullName &&
                Boolean(schdedulerBookSlotFormik.errors.fullName) && (
                  <DetailsFormHelperText>
                    {schdedulerBookSlotFormik.errors.fullName}
                  </DetailsFormHelperText>
                )}
            </FormControl>
            <ButtonWrapper>
              <ActionButton
                variant="outlined"
                onClick={() => handleBookSlotFormModal(false, "")}
              >
                Cancel
              </ActionButton>
              <ActionButton
                variant="contained"
                onClick={schdedulerBookSlotFormik.handleSubmit}
              >
                {
                  SEEKERS_BOOK_SLOT_FORM_CONSTS.SEEKERS_DETAILS_FORM_BUTTON_SUBMIT
                }
              </ActionButton>
            </ButtonWrapper>
          </DetailsFormContainer>
        </Card>
      </Container>
    </Modal>
  );
};

export default SchedulerBookSlotForm;

const DetailsFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: ${spacing.l};
  padding: ${spacing.l};
`;
const DetailsFormTextInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const DetailsFormHelperText = styled(FormHelperText)`
  color: #ff0000;
`;
const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: center;
  margin-left: ${spacing.s};
  padding: ${spacing.s};
  position: absolute;
  bottom: 0;
`;
const ActionButton = styled(Button)`
  font-size: ${textSizes.m};
  margin-right: ${spacing.s};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("180px")};
`;
