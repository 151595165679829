import { Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { textSizes } from "../../../styles";
import { NavContext } from "./NavContext";

const NavButtonLink = ({ to, text }) => {
  const navigate = useNavigate();
  const { handleDrawerClose } = useContext(NavContext);
  const onClick = () => {
    navigate(to);
    handleDrawerClose();
  };
  return (
    <Button
      variant="outlined"
      sx={{
        width: "fit-content",
        padding: "4px 21px",
        fontSize: textSizes.m,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default NavButtonLink;
