import { Button, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors, spacing, styleUtils, textSizes } from "styles";
import { CardModal, Text } from "../../../../components/atoms";

const BlockingModal = ({
  status,
  setStatus,
  handleSubmit,
  action,
  header,
  actioButtonName,
}) => {
  return (
    <CardModal open={status} onClose={() => setStatus(false)}>
      <Card
        sx={{
          height: styleUtils.pxToRem("205px"),
          width: styleUtils.pxToRem("422px"),
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: spacing.s,
            alignItems: "center",
          }}
        >
          <Text
            size="xl"
            color={colors.textPrimary}
            weight="bold"
            style={{ textAlign: "center" }}
          >
            {header}
          </Text>
          <Text
            size="m"
            color={colors.textPrimary}
            sx={{
              marginBottom: spacing.l,
            }}
          >
            {action}
          </Text>
          <ButtonRow>
            <ActionButton
              onClick={() => setStatus(false)}
              sx={{ marginRight: spacing.s }}
            >
              Cancel
            </ActionButton>
            <ActionButton variant="contained" onClick={handleSubmit}>
              {actioButtonName}
            </ActionButton>
          </ButtonRow>
        </div>
      </Card>
    </CardModal>
  );
};

export default BlockingModal;

const ButtonRow = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
  margin-top: ${spacing.l};
`;
const ActionButton = styled(Button)`
  height: ${styleUtils.pxToRem("40px")};
  width: ${styleUtils.pxToRem("178px")};
  font-size: ${textSizes.m};
`;
