import { useDispatch } from "react-redux";
import { Button, Divider, FormHelperText, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/system";

import { colors, spacing, styleUtils } from "../../../../styles";
import { Text, TextInput, SingleSelect, MultiSelect } from "../../../../components/atoms";
import * as FormConstants from "./constants";
import {
  wageExpectations,
  workingHrs,
  workExperience,
  daysAvailable,
  timeSlots,
} from "./constants";
import { PREFERENCES_ERROR_CONSTANTS } from "containers/SignUp/views/JobSeeker/constants";
import { userUpdate } from "../../../Login/actions/LoginActions";
import { showLoader } from "../../../../utils/commonUtils";
import useIsMobile from "../../../../hooks/useIsMobile";

const EditPreferences = (props) => {
  const { handleEdit, userData } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const validate = yup.object({
    selectedWageExpectations: yup
      .array()
      .required("Please select wage expectation")
      .min(1, PREFERENCES_ERROR_CONSTANTS.WAGE_EXPECTATION_ERROR_TEXT),
    selectedWorkingHrs: yup
      .array()
      .required("Please select working hours")
      .min(1, PREFERENCES_ERROR_CONSTANTS.WORKING_HOURS_ERROR_TEXT),
    selectedworkExperience: yup
      .array()
      .required("Please select work experience")
      .min(1, PREFERENCES_ERROR_CONSTANTS.EXPERIENCE_ERROR_TEXT),
    selectedDays: yup
      .array()
      .required("Please select days/week")
      .min(1, PREFERENCES_ERROR_CONSTANTS.WORKING_DAYS_ERROR_TEXT),
    selectedTimeSlots: yup
      .array()
      .required("Shift is required")
      .min(1, PREFERENCES_ERROR_CONSTANTS.TIME_SLOT_ERROR_TEXT),
  });

  const handleEditPreferences = (values) => {
    showLoader();
    handleEdit("preferences", "");
    dispatch(
      userUpdate({
        wageExpectation: values.selectedWageExpectations,
        workingHours: values.selectedWorkingHrs,
        experience: values.selectedworkExperience,
        daysPerWeek: values.selectedDays,
        timeSlot: values.selectedTimeSlots,
        note: values.note,
      }),
    );
  };

  const formProps = useFormik({
    initialValues: {
      selectedWageExpectations: userData?.wageExpectation,
      selectedworkExperience: userData?.experience,
      selectedWorkingHrs: userData?.workingHours,
      selectedDays: userData?.daysPerWeek,
      selectedTimeSlots: userData?.timeSlot,
      note: userData?.note,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      handleEditPreferences(values);
    },
  });

  const handleWageExpectation = (value) => {
    formProps.setFieldValue("selectedWageExpectations", value);
  };

  const handleWorkingHrs = (value) => {
    formProps.setFieldValue("selectedWorkingHrs", value);
  };

  const handleWorkExperience = (value) => {
    let val = value === undefined ? [] : [value];
    formProps.setFieldValue("selectedworkExperience", val);
  };

  const handleDaysPerWeek = (value) => {
    formProps.setFieldValue("selectedDays", value);
  };

  const handleSlotsChange = (value) => {
    formProps.setFieldValue("selectedTimeSlots", value);
  };
  return (
    <>
      <Grid
        container
        sx={{ my: spacing.m, flex: 1, overflow: "auto" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ px: spacing.xs, mt: spacing.xs, mx: spacing.xxl }}
        >
          <UserInputContainer>
            <Text
              size="s"
              color={colors.labelBlack}
              sx={{ marginBottom: spacing.xxxs }}
            >
              {FormConstants.WAGE_EXPECTATION} *
            </Text>
            <SelectContainer>
              <MultiSelect
                selectOptions={wageExpectations}
                prevSelectedOptions={formProps.values.selectedWageExpectations}
                handleSelect={handleWageExpectation}
                name="wage expectation"
              />
            </SelectContainer>
            {Boolean(formProps.errors.selectedWageExpectations) && (
              <FormHelperText sx={{ color: colors.red }}>
                {formProps.errors.selectedWageExpectations}
              </FormHelperText>
            )}
          </UserInputContainer>
          <UserInputContainer>
            <Text
              size="s"
              color={colors.labelBlack}
              sx={{ marginBottom: spacing.xxxs }}
            >
              {FormConstants.HOURS} *
            </Text>
            <SelectContainer>
              <MultiSelect
                selectOptions={workingHrs}
                prevSelectedOptions={formProps.values.selectedWorkingHrs}
                handleSelect={handleWorkingHrs}
                name="workinghours"
              />
            </SelectContainer>
            {Boolean(formProps.errors.selectedWorkingHrs) && (
              <FormHelperText sx={{ color: colors.red }}>
                {formProps.errors.selectedWorkingHrs}
              </FormHelperText>
            )}
          </UserInputContainer>
          <UserInputContainer>
            <Text
              size="s"
              color={colors.labelBlack}
              sx={{ marginBottom: spacing.xxxs }}
            >
              {FormConstants.WORK_EXPERIENCE} *
            </Text>
            <SelectContainer>
              <SingleSelect
                options={workExperience}
                prevSelectedOptions={formProps.values?.selectedworkExperience[0]}
                handleSelect={handleWorkExperience}
                name="selectedworkExperience"
              />
            </SelectContainer>
            {Boolean(formProps.errors.selectedworkExperience) && (
              <FormHelperText sx={{ color: colors.red }}>
                {formProps.errors.selectedworkExperience}
              </FormHelperText>
            )}
          </UserInputContainer>
          <UserInputContainer>
            <Text
              size="s"
              color={colors.labelBlack}
              sx={{ marginBottom: spacing.xxxs }}
            >
              {FormConstants.DAYS_WEEK} *
            </Text>
            <SelectContainer>
              <MultiSelect
                selectOptions={daysAvailable}
                prevSelectedOptions={formProps.values.selectedDays}
                handleSelect={handleDaysPerWeek}
              />
            </SelectContainer>
            {Boolean(formProps.errors.selectedDays) && (
              <FormHelperText sx={{ color: colors.red }}>
                {formProps.errors.selectedDays}
              </FormHelperText>
            )}
          </UserInputContainer>
          <UserInputContainer>
            <Text
              size="s"
              color={colors.labelBlack}
              sx={{ marginBottom: spacing.xxxs }}
            >
              {FormConstants.SHIFT} *
            </Text>
            <SelectContainer>
              <MultiSelect
                selectOptions={timeSlots}
                prevSelectedOptions={formProps.values.selectedTimeSlots}
                handleSelect={handleSlotsChange}
                name="timeSlot"
              />
            </SelectContainer>
            {Boolean(formProps.errors.selectedTimeSlots) && (
              <FormHelperText className="profile-form-error-field">
                {formProps.errors.selectedTimeSlots}
              </FormHelperText>
            )}
          </UserInputContainer>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={12}
          sm={12}
          md
          sx={{ px: spacing.xs, mx: spacing.xxl }}
        >
          <Text
            size="l"
            color="#515458"
            sx={{ marginBottom: spacing.xxxs, marginLeft: spacing.m }}
          >
            {FormConstants.NOTE_FOR_EMPLOYER}
          </Text>
          <StyledTextInput
            name="note"
            value={formProps.values.note}
            placeholder={userData?.note}
            onChange={formProps.handleChange}
            sx={{ height: "400px", overflow: "auto" }}
            inputProps={{
              style: { height: "100%" },
            }}
            multiline
          />
          {formProps.touched.note && Boolean(formProps.errors.note) && (
            <FormHelperText sx={{ color: colors.red }}>{formProps.errors.note}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Footer sx={{ justifyContent: isMobile ? "space-evenly" : "flex-end" }}>
        <ActionButton
          variant="outlined"
          onClick={() => handleEdit("preferences", "")}
        >
          {FormConstants.CANCEL}
        </ActionButton>
        <ActionButton
          variant="contained"
          disabled={!formProps.dirty}
          onClick={formProps.handleSubmit}
        >
          {FormConstants.SAVE}
        </ActionButton>
      </Footer>
    </>
  );
};
export default EditPreferences;

const UserInputContainer = styled("div")`
  margin-bottom: ${spacing.s};
`;

const SelectContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTextInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
}));

const Footer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
  padding: ${spacing.s} ${spacing.l};
`;

const ActionButton = styled(Button)`
  font-size: 1rem;
  margin-right: ${spacing.s};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("180px")};
`;
