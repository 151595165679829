import { Card, Container, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { colors, spacing } from "../../../../../styles";
import { Icon, Text } from "../../../../../components/atoms";
import PersonalInformation from "./PersonalInformation";

import SettingsContainer from "containers/Account/SettingsContainer";
import ConnectSocialAccountContainer from "containers/Account/ConnectSocialAccountContainer";
import EditPersonalInformation from "../EditPersonalInformation";
import EditBlockedUsersContainer from "containers/Account/EditBlockedUsersContainer";

const Profile = ({
  loggedInUser,
  onUploadResume,
  profileType,
  editType,
  onClickMenu,
  handleEdit,
  onUploadPic,
  onClickDeleteAccount,
  onClickPasswordScreen,
  socialAccountListData,
  onSuccessGoogleLogin,
  onFailureGoogleLogin,
  onSuccessFacebookLogin,
}) => {
  const getWebHeaderValue =
    {
      personalinformation: editType
        ? "Edit Personal Information"
        : "My Profile",
      settings: editType ? "Blocked Users" : "My Profile",
    }[profileType] ?? "My Profile";

  const OptionsView = () => {
    return (
      <Grid container sx={{ display: "flex", flex: 1, my: spacing.m }}>
        <Grid item sx={{ py: spacing.xl }}>
          <Row onClick={onClickMenu("personalinformation")}>
            {(!profileType || profileType === "personalinformation") && (
              <Line />
            )}
            <PersonIcon
              htmlColor={
                !profileType || profileType === "personalinformation"
                  ? colors.primary
                  : colors.black
              }
            />
            <Text
              size={20}
              style={{ marginLeft: spacing.s }}
              color={
                !profileType || profileType === "personalinformation"
                  ? colors.textPrimary
                  : colors.black
              }
            >
              Personal Information
            </Text>
          </Row>
          <Row onClick={onClickMenu("connectsocial")}>
            {profileType === "connectsocial" && <Line />}
            <ManageAccountsIcon
              htmlColor={
                profileType === "connectsocial" ? colors.primary : colors.black
              }
            />
            <Text
              size={20}
              style={{ marginLeft: spacing.s }}
              color={
                profileType === "connectsocial"
                  ? colors.textPrimary
                  : colors.black
              }
            >
              Connect Social Accounts
            </Text>
          </Row>

          <Row onClick={onClickMenu("settings")}>
            {profileType === "settings" && <Line />}
            <Icon
              size={24}
              name="settings-solid"
              color={profileType === "settings" ? colors.primary : colors.black}
            />
            <Text
              size={20}
              style={{ marginLeft: spacing.s }}
              color={
                profileType === "settings" ? colors.textPrimary : colors.black
              }
            >
              Settings
            </Text>
          </Row>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item md>
          {(!profileType || profileType === "personalinformation") && (
            <PersonalInformation
              userDetails={loggedInUser}
              onUploadResume={onUploadResume}
              onUploadPic={onUploadPic}
              onClickEditPersonalInformation={handleEdit}
            />
          )}
          {profileType === "connectsocial" && (
            <ConnectSocialAccountContainer
              socialAccountListData={socialAccountListData}
              onSuccessGoogleLogin={onSuccessGoogleLogin}
              onFailureGoogleLogin={onFailureGoogleLogin}
              onSuccessFacebookLogin={onSuccessFacebookLogin}
            />
          )}
          {profileType === "settings" && (
            <SettingsContainer
              onClickDeleteAccount={onClickDeleteAccount}
              onClickEditBlockedUsers={handleEdit}
              onClickPasswordScreen={onClickPasswordScreen}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  let content = {
    editPers: (
      <EditPersonalInformation
        userDetails={loggedInUser}
        handleEdit={handleEdit}
      />
    ),
    editBlocked: <EditBlockedUsersContainer />,
  }[editType] ?? <OptionsView />;

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Card
        sx={{
          minHeight: `calc(100vh - 64px - 4 * ${spacing.xxxl})`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header>
          {editType && (
            <Icon
              name="arrow-back-black"
              size={24}
              onClick={() => handleEdit(profileType, "")}
              style={{
                cursor: "pointer",
                marginRight: spacing.l,
                fill: "#000000",
              }}
            />
          )}
          <Text size={22} weight="bold">
            {getWebHeaderValue}
          </Text>
        </Header>
        <Divider />
        {content}
      </Card>
    </Container>
  );
};

export default Profile;

const Row = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.l};
  cursor: pointer;
  padding: ${spacing.xs} ${spacing.l};
  position: relative;
`;

const Line = styled("div")`
  height: 100%;
  width: 6px;
  background-color: ${colors.primary};
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  padding: ${spacing.s} ${spacing.l};
`;
