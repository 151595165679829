import { useState } from "react";
import { useFormik } from "formik";

import useIsMobile from "hooks/useIsMobile";

import ChangePassword from "./web/ChangePassword/ChangePassword";
import ChangePasswordMobile from "./mobile/ChangePassword/ChangePassword";

import {
  currentPasswordSchema,
  newPasswordSchema,
} from "./validationSchemas/ChangePassword/validationSchema";

import {
  resetPasswordApi,
  validateCurrentPasswordApi,
} from "configs/network/apis";
import { hideLoader, showLoader, showNotification } from "utils/commonUtils";
import { CHANGE_PASSWORD } from "./constants";

const ChangePasswordContainer = ({ onClickPasswordScreen, handleEdit }) => {
  const isMobile = useIsMobile();

  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
  const [passwordResetStatus, setPasswordResetStatus] = useState(false);

  const currentPasswordFormik = useFormik({
    initialValues: {
      ...CHANGE_PASSWORD.CURRENT_PASSWORD_INITAL_STATE,
    },
    validationSchema: currentPasswordSchema,
    validateOnChange: false,
    onSubmit: async ({ currentPassword }) => {
      handlePasswordValidation(currentPassword);
    },
  });

  const newPasswordFormik = useFormik({
    initialValues: {
      ...CHANGE_PASSWORD.NEW_PASSWORD_INITAL_STATE,
    },
    validationSchema: newPasswordSchema,
    onSubmit: async ({ newPassword }) => {
      handleCreateNewPassword(newPassword);
    },
  });

  const handlePasswordValidation = async (currentPassword) => {
    try {
      showLoader();
      const res = await validateCurrentPasswordApi({ currentPassword });
      setShowNewPasswordForm(res?.data?.message.length > 0);
    } catch (error) {
      if (error?.response?.status === 403) {
        currentPasswordFormik.setFieldError(
          "currentPassword",
          CHANGE_PASSWORD.INCORRECT_PASSWORD_ERROR
        );
        setShowNewPasswordForm(false);
      } else {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again.",
          },
        });
      }
    } finally {
      hideLoader();
    }
  };

  const handleCreateNewPassword = async (newPassword) => {
    try {
      showLoader();
      const res = await resetPasswordApi({ newPassword });
      setPasswordResetStatus(res?.data?.message.length > 0);
    } catch (error) {
      if (error?.response?.status === 403) {
        currentPasswordFormik.setFieldError(
          "currentPassword",
          CHANGE_PASSWORD.INCORRECT_PASSWORD_ERROR
        );
        setPasswordResetStatus(false);
      } else {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again.",
          },
        });
      }
    } finally {
      hideLoader();
    }
  };

  const Wrapper = isMobile ? ChangePasswordMobile : ChangePassword;

  return (
    <Wrapper
      currentPasswordFormik={currentPasswordFormik}
      onClickPasswordScreen={onClickPasswordScreen}
      newPasswordFormik={newPasswordFormik}
      passwordResetStatus={passwordResetStatus}
      showNewPasswordForm={showNewPasswordForm}
      handleEdit={handleEdit}
    />
  );
};

export default ChangePasswordContainer;
