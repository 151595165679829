import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "../../../../hooks/useIsMobile";

import { showNotification } from "../../../../utils/commonUtils";
import { CardModal, Text, Icon } from "../../../../components/atoms";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { colors, spacing, styleUtils, textSizes } from "../../../../styles";

const FILE_TYPES = ".jpg, .jpeg, .png, .doc,.docx, .pdf, .mp3, .mp4";
const FILE_MIME_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "audio/mpeg",
  "video/mp4",
];

const RESUME_FILE_TYPES = ".doc,.docx, .pdf";

const RESUME_FILE_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];

const UploadModal = ({
  isUploadModalOpen,
  hideUploadModal,
  onUploadAttachments,
  uploadButtonName,
  isResume,
  resumeFile = {},
  userDetails = {},
}) => {
  const isMobile = useIsMobile();
  const [files, setFiles] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileSelectError, setFileSelectError] = useState(false);

  const isSaveDisabled = !(files instanceof File);

  const [dragging, setDragging] = useState({ value: false, numberOfFiles: 0 });

  useEffect(() => {
    if (resumeFile instanceof File) {
      setFiles({ name: resumeFile?.name });
    }
  }, [resumeFile]);

  useEffect(() => {
    if (userDetails?.resumeUrl) {
      setFiles({ name: userDetails?.resume_filename });
    }
  }, [userDetails]);

  const onDropFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging({
      value: false,
      numberOfFiles: 0,
    });
    if (e.dataTransfer.items) {
      onSetFile(e.dataTransfer.items, "item");
    } else {
      onSetFile(e.dataTransfer.files, "file");
    }
  };

  const onChangeFile = (e) => {
    onSetFile(e.target.files, "file");
  };

  const onSetFile = (fileItems, type) => {
    if (fileItems.length > 1) {
      showNotification({
        alertProps: {
          severity: "error",
          children: "Only one file can be uploaded",
        },
      });
    } else if (fileItems.length === 1) {
      let file;
      if (type === "item") {
        file = fileItems[0].getAsFile();
      } else {
        file = fileItems[0];
      }
      if (file.size > 5242880) {
        setFileSizeError(true);
        return;
      }
      setFileSizeError(false);
      setFileSelectError(false);
      const isResumeFileOfRightType = isResume && !RESUME_FILE_MIME_TYPES.includes(file.type);
      if (isResumeFileOfRightType) {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Only pdf and docs are allowed",
          },
        });
        return;
      }
      if (!FILE_MIME_TYPES.includes(file.type)) {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Only pdf, images, videos and audios are allowed",
          },
        });
        return;
      }
      setFiles(file);
    }
  };

  const onClickUpload = () => {
    if (files && files instanceof File) {
      onUploadAttachments(files);
      hideUploadModal();
      setFileSelectError(false);
      logEvent(FirebaseEvents.JS_CLICK_SAVE_RESUME);
    } else {
      setFileSelectError(true);
    }
  };

  const onDragOverFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging({
      value: true,
      numberOfFiles: e.dataTransfer.items.length || e.dataTransfer.files.length,
    });
    setFileSelectError(false);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging({
      value: false,
      numberOfFiles: 0,
    });
  };

  return (
    <CardModal
      style={{
        left: isMobile ? "1%" : "9%",
        right: isMobile ? "1%" : "",
        bottom: isMobile ? "22%" : "20%",
      }}
      open={isUploadModalOpen}
      onClose={hideUploadModal}
    >
      <Card
        style={{
          maxHeight: styleUtils.pxToRem(isMobile ? "461px" : "451px"),
          minWidth: styleUtils.pxToRem(isMobile ? "334px" : "445px"),
          border: "none",
          borderColor: "none",
        }}
      >
        <Text weight="bold" size="l" sx={{ padding: spacing.m }}>
          Upload Your Resume
        </Text>
        <Divider />
        <ResumeCardWrapper
          component="label"
          htmlFor="file-upload"
          onDrop={onDropFile}
          onDragOver={onDragOverFile}
          onDragLeave={onDragLeave}
          isMobile={isMobile}
          dragging={dragging}
          fileSizeError={fileSizeError}
          fileSelectError={fileSelectError}
        >
          {dragging.value ? (
            <>
              <Text size="l" style={{ pointerEvents: "none" }}>
                Drop here!
              </Text>
              <Text size="xs" color={colors.disableGrey} style={{ pointerEvents: "none" }}>
                Dropping{" "}
                {dragging.numberOfFiles === 1 ? "1 file" : `${dragging.numberOfFiles} files`}
              </Text>
            </>
          ) : (
            <>
              <Text size="m" weight="semibold" style={{ pointerEvents: "none" }}>
                Add your resume here or{" "}
              </Text>
              <Text
                size="l"
                weight="semibold"
                style={{
                  textDecoration: "underline",
                  textUnderlinePosition: "under",
                  color: "#0B97FA",
                  cursor: "pointer",
                }}
              >
                Upload
              </Text>
              <Text
                size="s"
                color="#9D9D9D"
                weight="semibold"
                style={{ pointerEvents: "none", marginTop: spacing.xl }}
              >
                File Format: DOC, DOCX and PDF Only
              </Text>
              <Text size="s" color="#9D9D9D" weight="semibold" style={{ pointerEvents: "none" }}>
                Max. File Size: 5MB
              </Text>
            </>
          )}
        </ResumeCardWrapper>
        <div
          style={{
            marginLeft: spacing.s,
            marginBottom: spacing.s,
          }}
        >
          {fileSizeError && (
            <Text
              weight="regular"
              size="s"
              color="#FF405E"
              sx={{
                marginLeft: isMobile ? spacing.m : spacing.s,
              }}
            >
              File size is more than 5MB
            </Text>
          )}
          {fileSelectError && (
            <Text
              weight="regular"
              size="s"
              color="#FF405E"
              sx={{
                marginLeft: isMobile ? spacing.m : spacing.s,
              }}
            >
              Please choose a file
            </Text>
          )}
          <input
            type="file"
            id="file-upload"
            accept={isResume ? RESUME_FILE_TYPES : FILE_TYPES}
            multiple={false}
            hidden
            onChange={onChangeFile}
          />
        </div>
        {files && (
          <>
            <Text weight="medium" size="s" color="#767676" style={{ marginLeft: spacing.l }}>
              Resume uploaded
            </Text>
            <Card
              sx={{
                height: styleUtils.pxToRem("46px"),
                width: styleUtils.pxToRem(isMobile ? "294px" : "399px"),
                mx: spacing.l,
                mt: spacing.xs,
                mb: spacing.m,
                p: spacing.s,
                display: "flex",
                alignItems: "center",
                boxShadow: "none",
              }}
            >
              <Text style={{ flex: 1 }} numberOfLines={1}>
                {files.name}
              </Text>
              {files.name !== userDetails?.resume_filename && (
                <Icon
                  name="close"
                  alt="close"
                  size={14}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFiles(null);
                  }}
                />
              )}
            </Card>
          </>
        )}
        <Divider />
        <Row sx={{ marginLeft: isMobile ? spacing.l : spacing.xxl }}>
          <ActionBtn
            onClick={hideUploadModal}
            sx={{
              width: styleUtils.pxToRem(isMobile ? "143px" : "178px"),
              marginRight: spacing.xs,
            }}
          >
            Cancel
          </ActionBtn>
          <ActionBtn
            variant="contained"
            disabled={isSaveDisabled}
            onClick={onClickUpload}
            sx={{ width: styleUtils.pxToRem(isMobile ? "143px" : "178px") }}
          >
            {uploadButtonName}
          </ActionBtn>
        </Row>
      </Card>
    </CardModal>
  );
};

UploadModal.propTypes = {
  hideUploadModal: PropTypes.func,
  resumeFile: PropTypes.object,
  isResume: PropTypes.bool,
  isUploadModalOpen: PropTypes.bool,
  onUploadAttachments: PropTypes.func,
  userDetails: PropTypes.object,
  uploadButtonName: PropTypes.string,
};

export default UploadModal;

const Row = styled("div")`
  display: flex;
  margin-top: ${spacing.m};
  margin-bottom: ${spacing.m};
`;

const ActionBtn = styled(Button)`
  height: ${styleUtils.pxToRem("40px")};
  font-size: ${textSizes.m};
`;

const ResumeCardWrapper = styled(Card)`
  border: 2px dashed ${({ dragging }) => (!dragging ? "#B7C5EB" : colors.primary)};
  margin: 0 ${spacing.l};
  margin-top: ${spacing.l};
  margin-bottom: ${({ fileSelectError, fileSizeError }) =>
    fileSizeError || fileSelectError ? spacing.xs : spacing.xl};
  padding: ${spacing.xxxl} 0;
  height: ${({ isMobile }) =>
    isMobile ? styleUtils.pxToRem("161px") : styleUtils.pxToRem("156px")};
  width: ${({ isMobile }) =>
    isMobile ? styleUtils.pxToRem("294px") : styleUtils.pxToRem("399px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  boxshadow: none;
`;
