import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import PropTypes from "prop-types";
import { logEvent } from "configs/firebase";
import { jobDetails, jobSeekerJobApply } from "configs/network/apis";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { toggleFavourite } from "containers/JobSeeker/actions/JobSeekerActions";
import { EMPLOYER, JOB_SEEKER } from "appConstants";
import { CHAT_PAGE_ROUTE } from "routes/routeConstants";
import {
  decodeToHtml,
  getBlockUsersList,
  getProcessedJobDetailValue,
  hideLoader,
  setClickedOnApplyButtonAction,
  showLoader,
  showNotification,
} from "utils/commonUtils";

const JobCardDetailHelpers = ({
  setIsJobAbsent,
  isAuthenticated,
  isRatingDone,
  setShowRatingModal,
  loggedInUser,
  jobId,
  searchLocation,
  setIsJobDetailLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [item, setItem] = useState(null);
  const [appliedModalOpen, setAppliedModalOpen] = useState(false);
  const [showShareJobModal, setShowShareJobModal] = useState(false);

  const employerState = {
    photoURL: item?.brandLogo,
    companyName: item?.brandName,
    designation: item?.empDesignation,
    fullName: item?.empName,
  };

  const init = async () => {
    try {
      setIsJobDetailLoading(true);
      const blockListData = isAuthenticated ? await getBlockUsersList(loggedInUser) : [];
      const searchLatLngParams = {
        searchedLat: searchLocation?.lat,
        searchedLng: searchLocation?.lng,
      };
      const res = await jobDetails(jobId, searchLatLngParams);

      if (res?.data?.status === "inactive" || blockListData?.includes(res?.data?.recruiterId)) {
        setIsJobAbsent(true);
      } else {
        setIsJobAbsent(false);
      }
      setItem(res?.data || "Not found");
    } catch (error) {
      const errorResponse = error?.response?.data;

      if (errorResponse?.status === 404 && errorResponse?.code === "not_found") {
        setIsJobAbsent(true);
      }

      setItem("Not found");
    } finally {
      setIsJobDetailLoading(false);
    }
  };

  const onClickChat = (e) => {
    e.stopPropagation();
    logEvent(FirebaseEvents.JS_JOB_DETAILS_CARD_CHAT_NOW_CLICK, {
      Is_SignedIn: loggedInUser?.uid ? "Signed_In" : "Non_signed_in",
    });
    navigate(
      {
        pathname: CHAT_PAGE_ROUTE,
        search: `?uid=${item?.recruiterId}&uType=${EMPLOYER}`,
      },
      {
        state: employerState,
      },
    );
  };

  const onClickFavourite = async () => {
    if (isAuthenticated) {
      showLoader();
      try {
        await jobSeekerJobApply({
          jobId: item?.jobId,
        });
        hideLoader();
        showNotification({
          alertProps: {
            severity: "info",
            children: "You have successfully applied to this job",
          },
        });

        dispatch(
          toggleFavourite({
            userId: loggedInUser.uid,
            jobId: item?.jobId,
            interested: true,
          }),
        );
        setAppliedModalOpen(true);
      } catch (error) {
        hideLoader();
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again.",
          },
        });
      }
    } else {
      //set favourite action tp perform after login..
      navigate(`/login/${JOB_SEEKER}`, {
        state: {
          path: location?.pathname,
        },
      });
      setClickedOnApplyButtonAction();
    }
    logEvent(FirebaseEvents.JS_JOB_APPLY_NOW_CLICK, {
      Is_SignedIn: loggedInUser?.uid ? "Signed_In" : "Non_signed_in",
    });
  };
  const handleAppliedModalClose = () => {
    setAppliedModalOpen(false);
    logEvent(FirebaseEvents.JS_CLICK_CANCEL_MESSAGE_APPYJOB);
    if (!isRatingDone) {
      setShowRatingModal(true);
    }
  };

  /*
    @param {Boolean} modalStatus - variable to show or hide modal
  */
  const handleShareJobModal = (modalStatus) => {
    setShowShareJobModal(modalStatus);
  };
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    employmentType: "PART_TIME",
    title: item?.listingPosition,
    hiringOrganization: {
      "@type": "Organization",
      name: item?.brandName,
      sameAs: item?.website,
      logo: item?.brandLogo,
    },
    datePosted: item?.date,
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: item?.address_locality,
        addressRegion: item?.address_region,
        addressCountry: item?.address_country,
        postalCode: item?.postal_code,
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: item?.lat,
        longitude: item?.lng,
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        minValue: getProcessedJobDetailValue(item?.wage)?.split("-")[0],
        maxValue: getProcessedJobDetailValue(item?.wage)?.split("-")[1],
        unitText: "Hour",
      },
    },
    description: decodeToHtml(item?.listingDescription),
  };
  return {
    item,
    init,
    onClickChat,
    onClickFavourite,
    setAppliedModalOpen,
    appliedModalOpen,
    isRatingDone,
    structuredData,
    showShareJobModal,
    handleAppliedModalClose,
    handleShareJobModal,
  };
};

JobCardDetailHelpers.propTypes = {
  setIsJobAbsent: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isRatingDone: PropTypes.bool.isRequired,
  setShowRatingModal: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  brandName: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  setIsJobDetailLoading: PropTypes.func.isRequired,
};

export default JobCardDetailHelpers;
