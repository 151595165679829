import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Container } from "@mui/material";
import { useFormik } from "formik";

import SeekerSchedulerView from "./views/SeekerSchedulerView";

import { seekerSchedulerBookSlotSchema } from "./seekerSchedulerValidationSchema";
import {
  jobSeekerGetEmployerAvailabilityReq,
  jobSeekerSchedulerBookSlot,
} from "../../actions/JobSeekerActions";
import { showLoader, showNotification } from "../../../../utils/commonUtils";

import { spacing } from "../../../../styles";
import { SEEKERS_BOOK_SLOT_FORM_CONSTS } from "./constants";

const SeekerSchedulerContainer = () => {
  const dispatch = useDispatch();
  const { employer_calendar_id } = useParams();
  const [showBookSlotFormModal, setShowBookSlotFormModal] = useState(false);
  const [selectedBookSlotId, setSelectedBookSlotId] = useState("");

  useEffect(() => {
    showLoader();
    dispatch(
      jobSeekerGetEmployerAvailabilityReq({
        userId: employer_calendar_id.replace("calendar-", ""),
        fetch: "unbooked",
      }),
    );
  }, []);

  const handleBookSlotFormModal = (modalAction, slotId) => {
    setShowBookSlotFormModal(modalAction);
    setSelectedBookSlotId(modalAction && slotId ? slotId : "");
  };

  const schdedulerBookSlotFormik = useFormik({
    initialValues: {
      ...SEEKERS_BOOK_SLOT_FORM_CONSTS.SEEKERS_DETAILS_FORM_INITAL_STATE,
    },
    validationSchema: seekerSchedulerBookSlotSchema,
    onSubmit: async (results) => {
      const { email, fullName } = results;
      showLoader();
      try {
        dispatch(
          jobSeekerSchedulerBookSlot({
            availbilityId: selectedBookSlotId,
            seekerName: fullName,
            seekerEmail: email,
          }),
        );
        setShowBookSlotFormModal(false);
      } catch (error) {
        showNotification({
          alertProps: {
            severity: "error",
            children: error?.response?.status === 404 ? error?.response?.data?.message : "Error",
          },
        });
      }
    },
  });

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Card
        sx={{
          py: spacing.xl,
          flex: 1,
          display: "flex",
        }}
      >
        <SeekerSchedulerView
          handleBookSlotFormModal={handleBookSlotFormModal}
          schdedulerBookSlotFormik={schdedulerBookSlotFormik}
          showBookSlotFormModal={showBookSlotFormModal}
        />
      </Card>
    </Container>
  );
};

export default SeekerSchedulerContainer;
