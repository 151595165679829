import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { spacing } from "../../../styles";

/**
 * SingleSelect util class
 */
let dataOptions = [];
let selectedData = [];
class SingleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const { options, prevSelectedOptions } = this.props;
    /*** Logic to show preselected values in edit preference modal */
    dataOptions = cloneDeep(options);
    selectedData = [];
    if (prevSelectedOptions && prevSelectedOptions.length > 0) {
      dataOptions.forEach((data) => {
        if (prevSelectedOptions === data.value) {
          data.isSelected = true;
          selectedData.push(data.value);
        }
      });
    }
    this.setState({
      options: dataOptions,
    });
  }

  /**
   * handler function for multi-selection
   */
  handleClick(item) {
    const { options } = this.state;
    const { handleSelect } = this.props;

    options.forEach((data) => {
      if (item === data.value) data["isSelected"] = !data.isSelected;
      else data["isSelected"] = false;
    });

    this.setState({
      options: options,
    });

    const value = options.filter((data) => data.isSelected)[0]?.value;
    handleSelect(value);
  }

  render() {
    const { variant = "outlined", style, name, parentStyle } = this.props;
    const { options } = this.state;
    return (
      <>
        {options.map((data) => {
          return (
            <Box
              key={data.id}
              style={
                parentStyle
                  ? parentStyle
                  : {
                      marginRight: spacing.xs,
                      marginBottom: spacing.xs,
                      marginTop: spacing.xs,
                    }
              }
            >
              <Button
                name={name}
                value={data.value}
                variant={data.isSelected ? "contained" : variant}
                onClick={() => this.handleClick(data.value)}
                style={style}
              >
                {data.label}
              </Button>
            </Box>
          );
        })}
      </>
    );
  }
}

export default SingleSelect;
