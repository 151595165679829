import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import { Button, Grid, Divider, FormHelperText } from "@mui/material";

import FormDropDownContainer from "../../views/Profile/FormDropdown/FormDropDownContainer";
import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";
import { Text, TextInput, WebsiteInput } from "../../../../components/atoms";
import { userUpdate } from "../../../Login/actions/LoginActions";
import {
  getSanitizedFullName,
  isKeyAllowedInFullName,
  isValidPhoneNumber,
  showLoader,
} from "../../../../utils/commonUtils";
import useIsMobile from "../../../../hooks/useIsMobile";

import * as FormConstants from "./constants";

const EditPersonalInformation = (props) => {
  const { handleEdit, userDetails } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const websiteInputRef = useRef();

  const employerFormDetails = {
    fullName: userDetails?.name || userDetails?.fullName,
    email: userDetails?.email,
    countryCode: userDetails?.countryCode,
    phoneNumber: userDetails?.phoneNumber,
    companyName: userDetails?.companyName,
    designation: userDetails?.designation,
    designationText: userDetails?.designationText,
    employee_size: userDetails?.employee_size,
    multiple_location: userDetails?.multiple_location,
  };

  const employerSchema = yup.object({
    fullName: yup.string().required("Name is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .test("validate-phone", "Please enter valid phone number.", async (value, context) => {
        if (value) {
          return await isValidPhoneNumber({
            countryCode: context.parent.countryCode,
            phoneNumber: value,
          });
        }
        return true;
      }),
    companyName: yup.string().required("Company name is required"),
    employee_size: yup.string().required("Employee size is required"),
    designation: yup.string().required("Designation is required"),
    designationText: yup.string().when("designation", {
      is: (designation) => designation === "Other",
      then: yup.string().required("Please enter Designation"),
    }),
    multiple_location: yup.boolean(),
  });

  const formProps = useFormik({
    initialValues: employerFormDetails,
    validationSchema: employerSchema,
    onSubmit: async (values) => {
      let { webError, websiteValue } = await websiteInputRef.current.getWebsiteValue();
      if (webError) {
        return;
      }
      if (values["designation"] === "Other") {
        values["designation"] = values?.designationText;
      }
      delete values?.designationText;

      values.website = websiteValue;
      handleProfile(values);
    },
  });

  const handleProfile = (values) => {
    showLoader();
    dispatch(userUpdate(values));
    handleEdit("personalinformation", "");
  };

  const handleFullName = (event) => {
    formProps.setFieldValue("fullName", getSanitizedFullName(event.target.value));
  };

  const trimFullNameOnBlur = () => {
    formProps.setFieldValue("fullName", formProps.values.fullName.trim());
  };

  const renderActionBtns = () => {
    return (
      <>
        <Button
          sx={{
            height: styleUtils.pxToRem(isMobile ? "40px" : "48px"),
            width: styleUtils.pxToRem(isMobile ? "143px" : "180px"),
            fontSize: isMobile ? textSizes.s : textSizes.m,
            px: spacing.xxxl,
            mr: spacing.s,
          }}
          variant="outlined"
          onClick={() => handleEdit("personalinformation", "")}
        >
          {FormConstants.CANCEL}
        </Button>
        <Button
          sx={{
            height: styleUtils.pxToRem(isMobile ? "40px" : "48px"),
            width: styleUtils.pxToRem(isMobile ? "143px" : "180px"),
            fontSize: isMobile ? textSizes.s : textSizes.m,
            px: spacing.xxxl,
          }}
          variant="contained"
          onClick={formProps.handleSubmit}
          disabled={!formProps.dirty}
        >
          {FormConstants.SAVE}
        </Button>
      </>
    );
  };

  return (
    <>
      <Grid container sx={{ my: spacing.m, flex: { md: 1 }, overflow: "auto" }}>
        <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xl }}>
          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{ marginBottom: spacing.xxs }}
          >
            {FormConstants.NAME}
          </Text>
          <StyledTextInput
            name="fullName"
            value={formProps.values.fullName}
            onChange={handleFullName}
            onBlur={trimFullNameOnBlur}
            onKeyDown={(e) => {
              if (!isKeyAllowedInFullName(e.key)) {
                e.preventDefault(); // Prevent input
              }
            }}
            placeholder="Name"
            sx={{
              height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
              width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
            }}
          />
          {formProps.touched.fullName && Boolean(formProps.errors.fullName) && (
            <FormHelperText sx={{ color: "red" }}>{formProps.errors.fullName}</FormHelperText>
          )}
          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{
              mt: isMobile ? spacing.s : spacing.l,
              marginBottom: spacing.xxs,
            }}
          >
            {FormConstants.PHONE_NUMBER}
          </Text>
          <div style={{ pointerEvents: "none" }}>
            <StyledTextInput
              name="phoneNumber"
              value={
                formProps?.values?.countryCode
                  ? `+${formProps.values?.countryCode}-${formProps.values?.phoneNumber}`
                  : formProps.values?.phoneNumber
              }
              placeholder="PhoneNumber"
              sx={{
                color: colors.placeholder,
                height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
                width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
              }}
            />
          </div>
          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{
              mt: isMobile ? spacing.s : spacing.l,
              marginBottom: spacing.xxs,
            }}
          >
            {FormConstants.EMAIL_ID}
          </Text>
          <div
            style={{
              pointerEvents: "none",
              marginBottom: isMobile ? spacing.s : spacing.l,
            }}
          >
            <StyledTextInput
              name="email"
              value={formProps.values.email}
              placeholder="Email"
              sx={{
                color: colors.placeholder,
                height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
                width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
              }}
            />
          </div>
          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{
              mt: isMobile ? spacing.s : spacing.l,
              marginBottom: spacing.xxs,
            }}
          >
            Company Name
          </Text>
          <div style={{ pointerEvents: "none", marginBottom: spacing.l }}>
            <StyledTextInput
              name="companyName"
              value={formProps.values.companyName}
              placeholder="CompanyName"
              sx={{
                height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
                width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
                color: colors.placeholder,
              }}
            />
          </div>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm={12} md sx={{ px: spacing.xl }}>
          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{ marginBottom: spacing.xxs }}
          >
            Company Website (Optional)
          </Text>
          {isMobile ? (
            <StyledWebsiteInput
              ref={websiteInputRef}
              defaultValue={userDetails?.website}
              placeholder="www.employd.com"
            />
          ) : (
            <StyledWebsiteInputWeb
              ref={websiteInputRef}
              defaultValue={userDetails?.website}
              placeholder="www.employd.com"
            />
          )}
          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{
              mt: isMobile ? spacing.s : spacing.l,
              marginBottom: spacing.xxs,
            }}
          >
            Your Position
          </Text>
          <FormDropDownContainer
            fieldValue={formProps.values.designation}
            fieldName="designation"
            handleChange={(targetValue) => {
              formProps.setFieldValue("designation", targetValue);
            }}
            dropDownOptions={FormConstants.EMPLOYEE_DESIGNATION_OPTIONS}
            placeholder="Position"
            textSize="l"
            textWeight="semibold"
            sx={{
              height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
              width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
            }}
          />
          {formProps.touched.designation && Boolean(formProps.errors.designation) && (
            <FormHelperText sx={{ color: "red" }}>{formProps.errors.designation}</FormHelperText>
          )}
          {formProps.values.designation === "Other" && (
            <StyledTextInput
              name="designationText"
              value={formProps.values.designationText}
              onChange={formProps.handleChange}
              error={formProps.touched.designationText && Boolean(formProps.errors.designationText)}
              sx={{
                height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
                width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
                mt: spacing.xs,
              }}
            />
          )}
          {formProps.touched.designationText && Boolean(formProps.errors.designationText) && (
            <FormHelperText sx={{ color: "red" }}>
              {formProps.errors.designationText}
            </FormHelperText>
          )}

          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{
              mt: isMobile ? spacing.s : spacing.l,
              marginBottom: spacing.xxs,
            }}
          >
            How many employees do you currently have?
          </Text>
          <FormDropDownContainer
            fieldValue={formProps.values.employee_size}
            fieldName="employee_size"
            handleChange={(targetValue) => {
              formProps.setFieldValue("employee_size", targetValue);
            }}
            dropDownOptions={FormConstants.EMPLOYEE_SIZE_OPTIONS}
            placeholder="Select from the dropdown"
            textSize="xl"
            textWeight="semibold"
            sx={{
              height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
              width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
              border: `1px solid ${colors.lightBlue}`,
            }}
          />
          {formProps.touched.employee_size && Boolean(formProps.errors.employee_size) && (
            <FormHelperText sx={{ color: "red" }}>{formProps.errors.employee_size}</FormHelperText>
          )}

          <Text
            size={isMobile ? "s" : "l"}
            color={colors.placeholder}
            sx={{
              mt: isMobile ? spacing.s : spacing.l,
              marginBottom: spacing.xxs,
            }}
          >
            Are you present in multiple locations?
          </Text>
          <FormDropDownContainer
            fieldValue={
              formProps.values.multiple_location
                ? FormConstants.EMPLOYEE_MULTI_LOCATION_OPTIONS[0]
                : FormConstants.EMPLOYEE_MULTI_LOCATION_OPTIONS[1]
            }
            fieldName="multiple_location"
            handleChange={(targetValue) => {
              formProps.setFieldValue(
                "multiple_location",
                targetValue === FormConstants.EMPLOYEE_MULTI_LOCATION_OPTIONS[0] ? true : false,
              );
            }}
            dropDownOptions={FormConstants.EMPLOYEE_MULTI_LOCATION_OPTIONS}
            placeholder="Select from the dropdown"
            textSize="l"
            textWeight="semibold"
            sx={{
              height: styleUtils.pxToRem(isMobile ? "46px" : "56px"),
              width: styleUtils.pxToRem(isMobile ? "100%" : "422px"),
            }}
          />
          {formProps.touched.multiple_location && Boolean(formProps.errors.multiple_location) && (
            <FormHelperText sx={{ color: "red" }}>
              {formProps.errors.multiple_location}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      {!isMobile && <Divider />}

      {isMobile ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: spacing.l,
          }}
        >
          {renderActionBtns()}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: spacing.l,
          }}
        >
          {renderActionBtns()}
        </div>
      )}
    </>
  );
};

export default EditPersonalInformation;

const StyledTextInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));

const StyledWebsiteInput = styled(WebsiteInput)(() => ({
  borderRadius: 8,
  height: styleUtils.pxToRem("46px"),
  width: styleUtils.pxToRem("100%"),
  padding: "0.5rem 1rem 0.5rem 1rem",
  border: `1px solid ${colors.lightBlue}`,
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));

const StyledWebsiteInputWeb = styled(WebsiteInput)(() => ({
  borderRadius: 8,
  height: styleUtils.pxToRem("56px"),
  width: styleUtils.pxToRem("422px"),
  padding: "0.5rem 1rem 0.5rem 1rem",
  border: `1px solid ${colors.lightBlue}`,
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));
