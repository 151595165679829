import { Button, Container } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../styles";
import { SearchLocalJobsConstants } from "./Constants";

const SearchLocalJobs = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        my: spacing.xxxxl,
      }}
    >
      <Text id="SEARCH_LOCAL_MOB_TITLE_1" size={32} style={{ lineHeight: 1.2 }}>
        {SearchLocalJobsConstants.TITLE_1}{" "}
        <Text
          id="SEARCH_LOCAL_MOB_TITLE_2"
          size={32}
          component="span"
          weight="extrabold"
          color={colors.primary}
          style={{ lineHeight: 1.2 }}
        >
          {SearchLocalJobsConstants.TITLE_2}
        </Text>
      </Text>
      <Text id="SEARCH_LOCAL_MOB_TITLE_3" size={32} style={{ lineHeight: 1.2 }}>
        {SearchLocalJobsConstants.TITLE_3}
      </Text>
      <Text
        id="SEARCH_LOCAL_MOB_SUBTITLE_1"
        color={colors.placeholder}
        style={{ marginTop: spacing.s }}
      >
        {SearchLocalJobsConstants.SUB_TITLE_1}{" "}
        {SearchLocalJobsConstants.SUB_TITLE_2}
      </Text>
      {!isAuthenticated && (
        <Button
          id="SEARCH_LOCAL_MOB_STARTED_BTN"
          variant="contained"
          sx={{
            mt: spacing.xxl,
            width: "fit-content",
            px: spacing.xxl,
            py: spacing.xs,
          }}
          onClick={onGetStarted}
        >
          {SearchLocalJobsConstants.GET_STARTED_BTN}
        </Button>
      )}
      <img
        src={images.home["jobseeker-hero"]["2"]}
        alt="2"
        style={{ width: styleUtils.pxToRem("280px"), marginTop: spacing.xl }}
      />
    </Container>
  );
};

export default SearchLocalJobs;
