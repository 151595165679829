import { useLocation, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { spacing } from "../../../styles";
import BottomTab from "./BottomTab";
import Footer from "./Footer";
import { CHAT_PAGE_ROUTE } from "routes/routeConstants";

const MobileFooter = ({ isAuthenticated }) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid") || "";
  if (pathname == CHAT_PAGE_ROUTE && uid) {
    return null;
  }
  if (isAuthenticated) {
    return (
      <div style={{ marginTop: spacing.s }}>
        <div style={{ height: 58 }} />
        <BottomTab />
      </div>
    );
  }
  return (
    <div style={{ marginTop: spacing.s }}>
      <Footer />
    </div>
  );
};

MobileFooter.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default MobileFooter;
