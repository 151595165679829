import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../../../../../components/atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
} from "../../../../../../../styles";
import { sortingOptions, SORT_BY_BUTTON } from "../../Constants";

/**
 * Sorting Menu for jobs
 * by distance, wage and work hours
 */
const Sort = ({ handleSorting }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const appliedSort = sortingOptions.filter((sortOption) => sortOption.order);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (type) => () => {
    setAnchorEl(null);
    let sortOrder = "";
    sortingOptions.forEach((item) => {
      if (item.key === type) {
        sortOrder = !item.order || item.order === "desn" ? "ascn" : "desn";
        item.order = sortOrder;
      } else {
        item.order = "";
      }
    });
    handleSorting(type, sortOrder);
  };

  const handleResetSort = () => {
    setAnchorEl(null);
    sortingOptions.forEach((item) => {
      item.order = "";
    });
    handleSorting("clear", "");
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<Icon size={22} name="select-dropdown" />}
        sx={{
          width: styleUtils.pxToRem("140px"),
          border: "1px solid #E6EDFF",
          fontColor: "#2B4894",
          fontSize: textSizes.m,
          height : styleUtils.pxToRem("36px")
        }}
      >
        {SORT_BY_BUTTON}
        {appliedSort?.length > 0 && (
          <Icon
            size={16}
            name={
              appliedSort[0]?.order === "ascn"
                ? "sort-arrow-up"
                : "sort-arrow-down"
            }
            sx={{ marginLeft: spacing.xs }}
          />
        )}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: colors.white,
            borderWidth: 0,
          },
        }}
      >
        {sortingOptions.map((option) => {
          return (
            <SortingMenuItem
              key={option.key}
              onClick={onClickMenuItem(option.key)}
            >
              <Text size="s" weight="medium">
                {option.title}
              </Text>
              {option.order && (
                <Icon
                  size={16}
                  name={
                    appliedSort[0]?.order === "ascn"
                      ? "sort-arrow-up"
                      : "sort-arrow-down"
                  }
                  sx={{ marginLeft: spacing.xs }}
                />
              )}
            </SortingMenuItem>
          );
        })}
        <ResetMenuItem key="clear" onClick={handleResetSort}>
          <Text size="s" weight="medium">
            Clear all
          </Text>
        </ResetMenuItem>
      </Menu>
    </>
  );
};

export default Sort;

const SortingMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
  },
  minWidth: 160,
  marginBottom: spacing.xxs,
  borderBottom: `1px solid ${colors.shadow}`,
}));

const ResetMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
    textDecorationColor: colors.primary,
  },
  minWidth: 160,
  marginBottom: spacing.xxs,
}));
