import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { noop, pull } from "lodash";

import { Avatar, Box, Divider, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, NewJob, RichTextViewer, Text } from "../../atoms";

import { renderImage } from "../../../utils/imageUtils";
import {
  showLoader,
  hideLoader,
  showNotification,
  getFormattedJobLocation,
  getProcessedJobDetailValue,
  handleAsync,
  decodeToHtml,
  getClickedOnApplyButtonAction,
} from "../../../utils/commonUtils";

import AppliedModal from "./AppliedModal";
import ShareJobDetailsModal from "./ShareJobDetailsModal";
import RatingsModal from "../Ratings";

import { postBookmarkJob } from "../../../configs/network/apis";
import { userDetailUpdate } from "../../../containers/Login/actions/LoginActions";

import LoginSelector from "../../../containers/Login/selectors/LoginSelector";

import { images } from "../../../assets/images";
import { logEvent } from "../../../configs/firebase";
import { getDateDifference } from "../../../utils/dateUtils";

import FirebaseEvents from "configs/firebase/FirebaseEvents";
import JobSeekerSelector from "containers/JobSeeker/selectors/JobSeekerSelector";
import JobNotFoundModal from "../JobNotFoundModal";
import JobDetailCardSkeleton from "./JobDetailCardSkeleton";
import { colors, spacing, styleUtils, textSizes, typography } from "../../../styles";

import { JOBSEEKER_PAGE_ROUTE, EXPLORE_JOBS_PAGE_ROUTE } from "../../../routes/routeConstants";
import JobCardDetailHelpers from "./JobCardDetailHelpers";

const JobDetailCard = ({
  onClickBrandLogo,
  setIsJobAbsent,
  isJobAbsent,
  fallBackPath = `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
  searchLocation = null,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);

  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const brandName = searchParams.get("brandName");
  const forceUpdate = useSelector(JobSeekerSelector.getForceUpdateJobSeekerListing);

  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isJobDetailLoading, setIsJobDetailLoading] = useState(false);

  const isRatingDone = useSelector(LoginSelector.getUserRatingStatus);

  const {
    item,
    init,
    onClickChat,
    onClickFavourite,
    appliedModalOpen,
    showShareJobModal,
    handleAppliedModalClose,
    handleShareJobModal,
    structuredData,
  } = JobCardDetailHelpers({
    setIsJobAbsent,
    isAuthenticated,
    isRatingDone,
    loggedInUser,
    brandName,
    jobId,
    setShowRatingModal,
    searchLocation,
    setIsJobDetailLoading,
  });
  let isBookmarked = loggedInUser?.bookmarkedJobs.includes(item?.jobId);

  const isNew = getDateDifference(item?.date) < 14;
  const isFavourite = item?.jobSeekers?.includes(loggedInUser?.uid);
  const messageForDistanceValue = item?.distance
    ? `${item?.distance} miles away`
    : "Click to Navigate";

  useEffect(() => {
    if (item) {
      const isUnSignedUserClickedOnApply = getClickedOnApplyButtonAction();
      const isShowAlreadyAppliedJobMessage =
        isFavourite && isUnSignedUserClickedOnApply !== "false";
      if (isShowAlreadyAppliedJobMessage) {
        showNotification({
          alertProps: {
            severity: "warning",
            children: "You have already applied for this job",
          },
        });
      }
      if (isUnSignedUserClickedOnApply === "true" && item?.jobId && !isFavourite) {
        onClickFavourite();
      }
    }
  }, [isFavourite, item, item?.jobId, onClickFavourite]);

  useEffect(() => {
    init();
  }, [jobId, brandName, forceUpdate]);

  const onClickMap = (e) => {
    e.stopPropagation();
    window.open("https://maps.google.com?q=" + `${item?.lat}` + "," + `${item?.lng}`);
  };

  const onClickBookmark = async () => {
    if (isAuthenticated) {
      showLoader();
      try {
        const res = await postBookmarkJob({
          jobId: item?.jobId,
        });
        const {
          data: { bookmarkStatus, message },
        } = res;
        hideLoader();
        isBookmarked = bookmarkStatus;
        dispatch(
          userDetailUpdate({
            bookmarkedJobs: bookmarkStatus
              ? [...loggedInUser.bookmarkedJobs, item?.jobId]
              : pull(loggedInUser.bookmarkedJobs, item?.jobId),
          }),
        );
        if (isBookmarked) {
          logEvent(FirebaseEvents.JS_JOB_BOOKMARK_CLICK, {
            IsSignedIn: loggedInUser?.uid ? "Signed_In" : "Non_signed_in",
            Platform: "Web",
            Location: "",
            AppVersion: "",
            OSVersion: "",
          });
        }
        showNotification({
          alertProps: {
            severity: bookmarkStatus ? "success" : "warning",
            children: message,
          },
        });
      } catch (error) {
        hideLoader();
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again.",
          },
        });
      }
    }
  };

  if (isJobDetailLoading) {
    return <JobDetailCardSkeleton isMobile={false} />;
  }

  return (
    <>
      <JobDetailCardContainer>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        <GridContainer
          container
          sx={{
            height: `${styleUtils.pxToRem("146px")}`,
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs
            sm
            md
          >
            <TopContainer
              sx={{
                paddingLeft: spacing.xxl,
                flexDirection: "row",
              }}
            >
              <Avatar
                src={renderImage(item?.brandLogo, "job")}
                onClick={() => onClickBrandLogo(item)}
                variant="rounded"
                sx={{
                  width: 57,
                  height: 57,
                  cursor: "pointer",
                  borderRadius: 57 / 2,
                }}
              >
                <img
                  src={images.defaults.job}
                  alt={item?.listingPosition}
                  style={{ width: 57, height: 57, borderRadius: 57 / 2 }}
                />
              </Avatar>
              <TitleContainer height="auto">
                <Text
                  weight="semibold"
                  size="l"
                  numberOfLines={2}
                >
                  {item?.listingPosition}
                </Text>
                <Text
                  color={colors.placeholder}
                  size="xs"
                >
                  {item?.brandName}
                </Text>
                <Text
                  color={colors.placeholder}
                  size="xs"
                >
                  {getFormattedJobLocation(item?.city)}
                </Text>
                <NewJob
                  jobStyle={{
                    borderRadius: styleUtils.pxToRem("100px"),
                    fontSize: textSizes.xs,
                    width: styleUtils.pxToRem("81px"),
                    marginTop: "5px",
                  }}
                  isNew={isNew}
                />
              </TitleContainer>
            </TopContainer>
          </Grid>
          <TopContainerLeftContent>
            <CircularBox>
              <div style={{ display: "flex" }}>
                <Text
                  weight="medium"
                  size="s"
                  numberOfLines={1}
                  sx={{ color: "#3A5FC7;", fontWeight: "bold" }}
                >
                  ${getProcessedJobDetailValue(item?.wage)}/hr
                </Text>
              </div>
            </CircularBox>

            <Grid
              item
              xs="auto"
              sm="auto"
              md="auto"
            >
              <IconContainer>
                {isAuthenticated && (
                  <Icon
                    onClick={onClickBookmark}
                    name={isBookmarked ? "bookmark" : "remove-bookmark"}
                    size={`${spacing.xxl}`}
                    color={isBookmarked ? "#2B4894" : ""}
                    style={{
                      marginRight: spacing.xl,
                      cursor: "pointer",
                      marginTop: `-${spacing.l}`,
                    }}
                  />
                )}
              </IconContainer>
            </Grid>
          </TopContainerLeftContent>
        </GridContainer>
        <Divider />
        <div
          style={{
            overflow: "auto",
            marginBottom: `calc(${textSizes.l} + 3 * ${spacing.m})`,
            paddingLeft: spacing.xxl,
          }}
        >
          <Grid
            container
            overflow="auto"
          >
            <>
              <Grid
                item
                xs
                sm
                md
              >
                <div
                  style={{
                    padding: `${spacing.xs} 0`,
                  }}
                >
                  <Row>
                    <Icon
                      name="clock"
                      alt={"clock"}
                      size={31}
                    />
                    <InfoBox>
                      <Text
                        size="xs"
                        color="#4E4E4E"
                        weight="semibold"
                      >
                        Working Hours
                      </Text>
                      <Text
                        weight="semibold"
                        size={spacing.descrsize}
                        numberOfLines={1}
                      >
                        {`${getProcessedJobDetailValue(item?.hoursListed)} hours per week`}
                      </Text>
                    </InfoBox>
                  </Row>
                  <Row>
                    <Icon
                      name="experience"
                      alt={"experience"}
                      size={31}
                    />
                    <InfoBox>
                      <Text
                        size="xs"
                        color="#4E4E4E"
                        weight="semibold"
                      >
                        Experience Required
                      </Text>
                      <Text
                        weight="semibold"
                        size={spacing.descrsize}
                        color={colors.blackText}
                      >
                        {getProcessedJobDetailValue(item?.experience) === "Beginner"
                          ? getProcessedJobDetailValue(item?.experience)
                          : `${getProcessedJobDetailValue(item?.experience)} years`}
                      </Text>
                    </InfoBox>
                  </Row>
                  <Row>
                    <Icon
                      name="distance"
                      alt={"distance"}
                      size={31}
                    />
                    <InfoBox>
                      <Text
                        size="xs"
                        color="#4E4E4E"
                        weight="semibold"
                      >
                        Distance to job location
                      </Text>
                      <Text
                        weight="semibold"
                        size={spacing.descrsize}
                        color={colors.blackText}
                      >
                        {messageForDistanceValue}
                      </Text>
                    </InfoBox>
                    <Icon
                      name="map-navigation"
                      size={24}
                      onClick={onClickMap}
                      style={{
                        alignSelf: "end",
                        cursor: "pointer",
                        marginLeft: spacing.xs,
                        marginBottom: spacing.xxs,
                      }}
                    />
                  </Row>
                </div>
              </Grid>
              <Grid
                item
                xs={0}
              >
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                xs
                sm
                md
              >
                <div
                  style={{
                    padding: `${spacing.xs} 0`,
                    marginLeft: spacing.xl,
                  }}
                >
                  <Row>
                    <Icon
                      name="money"
                      alt={"money"}
                      size={31}
                    />
                    <InfoBox>
                      <Text
                        size="xs"
                        color="#4E4E4E"
                        weight="semibold"
                      >
                        Hourly Wage
                      </Text>
                      <Text
                        weight="semibold"
                        size={spacing.descrsize}
                        numberOfLines={1}
                      >
                        {`$${getProcessedJobDetailValue(item?.wage)} per hour`}
                      </Text>
                    </InfoBox>
                  </Row>
                  <Row>
                    <Icon
                      name="timeslot"
                      alt={"timeslot"}
                      size={31}
                    />
                    <InfoBox>
                      <Text
                        size="xs"
                        color="#4E4E4E"
                        weight="semibold"
                      >
                        Shift
                      </Text>
                      <Text
                        weight="semibold"
                        size={spacing.descrsize}
                        numberOfLines={1}
                      >
                        {item?.timeSlot && Array.isArray(item?.timeSlot)
                          ? item?.timeSlot.join(", ")
                          : item?.timeSlot}
                      </Text>
                    </InfoBox>
                  </Row>
                  <Row>
                    <Icon
                      name="calendar"
                      alt={"calendar"}
                      size={31}
                    />
                    <InfoBox>
                      <Text
                        size="xs"
                        color="#4E4E4E"
                        weight="semibold"
                      >
                        Working Days
                      </Text>
                      <Text
                        weight="semibold"
                        size={spacing.descrsize}
                        numberOfLines={1}
                      >
                        {item?.daysSelected && Array.isArray(item?.daysSelected)
                          ? item?.daysSelected.join(", ")
                          : item?.day}
                      </Text>
                    </InfoBox>
                  </Row>
                </div>
              </Grid>
            </>
          </Grid>
          <div style={{ marginTop: `${spacing.m}` }}>
            <Divider />
          </div>
          <JobDetailsContainer sx={{ paddingLeft: spacing.xs }}>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{ marginTop: spacing.s }}
              size={spacing.s}
            >
              Job Description
            </Text>
            <RichTextViewer
              style={{
                fontSize: textSizes.s,
                lineHeight: "1.5",
                marginTop: spacing.xs,
                color: colors.descriptionGray,
              }}
            >
              {decodeToHtml(item?.listingDescription)}
            </RichTextViewer>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{ marginTop: spacing.xl }}
            >
              Short Note
            </Text>
            <Text
              size="s"
              color={colors.descriptionGray}
              style={{ marginTop: spacing.xs }}
            >
              {item?.note}
            </Text>
            {!!item?.min_age && (
              <>
                <Text
                  weight="semibold"
                  color={colors.blackText}
                  style={{ marginTop: spacing.xl }}
                >
                  Minimum Age for the job
                </Text>
                <Text
                  size="s"
                  color={colors.descriptionGray}
                  style={{ marginTop: spacing.xs }}
                >
                  {item?.min_age} Years
                </Text>
              </>
            )}
          </JobDetailsContainer>
          <Divider style={{ marginTop: spacing.s }} />
          <ChatPosterContainer sx={{ paddingLeft: spacing.xl }}>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{
                marginTop: spacing.s,
                marginBottom: spacing.xs,
              }}
            >
              Chat with employer
            </Text>
            <JobPosterFlexBox style={{ justifyContent: "normal" }}>
              <JobPosterDetailsBox>
                <Text
                  weight="medium"
                  size={"s"}
                >
                  {item?.empName}
                </Text>
                <Text
                  size="xs"
                  color={colors.placeholder}
                >
                  {item?.empDesignation}
                </Text>
              </JobPosterDetailsBox>
              <ChatNowButton
                variant="outlined"
                onClick={onClickChat}
              >
                Chat Now
              </ChatNowButton>
            </JobPosterFlexBox>
          </ChatPosterContainer>
          <Divider style={{ marginTop: spacing.s }} />
        </div>

        <ApplyWebContainer>
          <ApplyBtnWeb
            variant={isFavourite ? "outlined" : "contained"}
            disabled={isFavourite}
            onClick={isFavourite ? noop : onClickFavourite}
            sx={{
              backgroundColor: isFavourite ? colors.white : colors.primary,
              color: isFavourite ? colors.primary : colors.white,
              "&:disabled": {
                borderColor: colors.border,
              },
            }}
            {...(!isFavourite && {
              onClick: handleAsync(onClickFavourite),
            })}
          >
            {isFavourite ? "Applied" : "Apply Now"}
          </ApplyBtnWeb>
          <Icon
            name="shareJob"
            onClick={() => handleShareJobModal(true)}
            size={42}
            color={colors.midnightBlue}
            style={{ marginLeft: spacing.l, cursor: "pointer" }}
          />
        </ApplyWebContainer>
      </JobDetailCardContainer>
      {appliedModalOpen && (
        <AppliedModal
          isOpen={appliedModalOpen}
          onClose={handleAppliedModalClose}
          jobDetails={item}
        />
      )}
      {showShareJobModal && (
        <ShareJobDetailsModal
          isOpen={showShareJobModal}
          jobLink={item?.finalLink}
          handleShareJobModal={handleShareJobModal}
        />
      )}

      <RatingsModal
        isOpen={showRatingModal}
        setShowRatingModal={setShowRatingModal}
      />
      {isJobAbsent && (
        <JobNotFoundModal
          isOpen={isJobAbsent}
          handleClickForJobNotFound={() => {
            navigate(
              {
                pathname: fallBackPath,
              },
              {
                state: {
                  handleInactiveLink: true,
                  locationDetails: {
                    lat: item?.lat,
                    lng: item?.lng,
                    locationName: item?.city,
                  },
                },
              },
            );
          }}
        />
      )}
    </>
  );
};

JobDetailCard.propTypes = {
  onClickBrandLogo: PropTypes.func.isRequired,
  setIsJobAbsent: PropTypes.func.isRequired,
  isJobAbsent: PropTypes.bool.isRequired,
  fallBackPath: PropTypes.string,
  searchLocation: PropTypes.object,
};

export default JobDetailCard;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
`;

const TopContainerLeftContent = styled("div")`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.m};
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s};
`;

const IconContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s} 0;
  justify-content: space-between;
`;

const JobDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;

const ChatPosterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;

const JobPosterFlexBox = styled("div")`
  display: flex;
`;

const JobPosterDetailsBox = styled("div")`
  padding-top: ${spacing.xxxs};
`;

const ApplyWebContainer = styled("div")`
  display: inline-flex;
  margin: ${spacing.s};
  position: absolute;
  bottom: 0;
  right: 0;
`;

const ApplyBtnWeb = styled(Button)`
  width: auto;
  border-radius: 100;
  padding: ${spacing.xs} ${spacing.xxxxl};
  font-weight: ${typography.bold};
  font-size: ${textSizes.l};
`;

const ChatNowButton = styled(Button)`
  margin-left: ${spacing.xxxl};
  border-radius: 100;
  height: ${styleUtils.pxToRem("38px")};
  width: ${styleUtils.pxToRem("138px")};
  font-size: ${textSizes.m};
  font-weight: ${typography.semibold};
`;
const CircularBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.xs};
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 10px #647eff2c;
  opacity: 1;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.l};
`;

const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;

const GridContainer = styled(Grid)`
  width: 100%;
  display: flex;
  background: transparent linear-gradient(79deg, #ffffff00 0%, #3a5fc7 200%) 0% 0% no-repeat
    padding-box;
  borderradius: 6px 6px 0px 0px;
`;

const JobDetailCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid ##e6edff;
  border-radius: 6px;
  height: 100%;
  width: 100%;
`;
