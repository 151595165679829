import { useState } from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  usePickerLayout,
  pickersLayoutClasses,
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";

import { DateTimePickerComp, Icon } from "../../../../../../components/atoms";
import {
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../../../../styles";

const SchedulerDateTimePicker = ({ handleDateTimeChange }) => {
  const [dateTimeField, setDateTimeField] = useState(new Date());

  const SchedulerHeader = () => {
    return (
      <Box
        sx={{
          gridColumn: 1,
          gridRow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon
          name="calendar"
          alt="calendar"
          size={38}
          style={{ marginTop: spacing.s, marginLeft: spacing.s }}
        />
      </Box>
    );
  };

  const handleAccept = (onAccept) => {
    handleDateTimeChange(
      dateTimeField?.$d?.toLocaleDateString(),
      dateTimeField.$d.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
    onAccept();
  };

  const ActionButtons = (props) => {
    const { onAccept, onClear } = props;

    return (
      <ButtonWrapper>
        <ActionButton variant="outlined" onClick={onClear}>
          Clear
        </ActionButton>
        <ActionButton
          variant="contained"
          onClick={() => handleAccept(onAccept)}
        >
          Accept
        </ActionButton>
      </ButtonWrapper>
    );
  };

  const CustomLayout = (props) => {
    const { toolbar, tabs, content, actionBar } = usePickerLayout(props);

    return (
      <PickersLayoutRoot
        ownerState={props}
        sx={{
          overflow: "auto",
          [`.${pickersLayoutClasses.actionBar}`]: {
            gridColumn: 2,
            gridRow: 1,
          },
          [`.${pickersLayoutClasses.toolbar}`]: {
            gridColumn: 1,
            gridRow: 2,
          },
        }}
      >
        <SchedulerHeader />
        {toolbar}
        <PickersLayoutContentWrapper
          className={pickersLayoutClasses.contentWrapper}
        >
          {tabs}
          {content}
          {actionBar}
        </PickersLayoutContentWrapper>
      </PickersLayoutRoot>
    );
  };

  return (
    <DateTimePickerComp
      customSlotLayout={CustomLayout}
      customSlotActionBar={ActionButtons}
      pickerLabel="Schedule interview"
      setDateTimeField={setDateTimeField}
    />
  );
};

export default SchedulerDateTimePicker;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: center;
  padding: ${spacing.xs};
`;

const ActionButton = styled(Button)`
  border-radius: ${spacing.l};
  font-size: ${textSizes.m};
  font-weigt: ${typography.bold};
  height: ${styleUtils.pxToRem("48px")};
  width: ${styleUtils.pxToRem("120px")};
  margin-left: ${spacing.xs};
`;
