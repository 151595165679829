import useIsMobile from "../../../../../hooks/useIsMobile";
import { SelectDropDown } from "../../../../../components/atoms";
import { colors, styleUtils } from "../../../../../styles";

const FormDropDownContainer = (props) => {
  const isMobile = useIsMobile();
  // const Wrapper = isMobile ? BottomDrawerDropDown : SelectDropDown;
  const Wrapper = SelectDropDown;

  const webStyle = {
    border: `1px solid ${colors.lightBlue}`,
    height: styleUtils.pxToRem("55px"),
  };

  return (
    <Wrapper
      widthValue={isMobile ? 310 : 654}
      sx={!isMobile ? webStyle : {}}
      {...props}
    />
  );
};

export default FormDropDownContainer;
