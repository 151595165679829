export const employerInitialState = {
  employerListingLoading: false,
  employerListingFailure: false,
  employerListingSuccess: false,
  employerListing: [],

  employerJobSeekerListingLoading: false,
  employerJobSeekerListingFailure: false,
  employerJobSeekerListingSuccess: false,
  employerJobSeekerListing: [],
  employerJobSeekerListingPageLimit: 0,
  employerJobSeekerListCount: 0,
  forceUpdate: undefined,

  employerJobDeleteLoading: false,
  employerJobDeleteFailed: false,
  employerJobDeleteSuccess: false,
  isEmployerRatingDone: false,
  employerFirstJobStartTime:null,
};

export const employerUserDetailsInitialState = {
  employerUserDetailsLoading: false,
  employerUserDetailsSuccess: false,
  employerUserDetailsFailure: false,
  employerUserDetails: {},
};

export const employerSchedulerInitialState = {
  schedulerUserDetails: {},
  schedulerAvailableList: [],
  schedulerUpcomingMeetingList: [],
  schedulerShareLink: "",
};
