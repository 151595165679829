import React from "react";
import { Modal, Button, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text } from "../../../../../components/atoms";
import { images } from "../../../../../assets/images";

import { colors, spacing, textSizes, styleUtils } from "../../../../../styles";
import { DUPLICATE_JOB_ALERT } from "../AddJobConstants";

const DuplicateJobAlert = ({ show, handleCancel, handlePost }) => {
  return (
    <Modal
      open={show}
      onClose={handleCancel}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "none",
          height: styleUtils.pxToRem("408px"),
          width: styleUtils.pxToRem("587px"),
          borderRadius: styleUtils.pxToRem("17px"),
          backgroundColor: colors.white,
        }}
      >
        <ContentContainer>
          <ImgContainer src={images["duplicateLocationModal"]} />
          <ContentWrapper>
            <Text weight="regular" size="l" color="#000000DE">
              {DUPLICATE_JOB_ALERT.DUPLICATE_JOB_SUBTEXT_ONE}
            </Text>
            <Text weight="regular" size="l" color="#000000DE">
              {DUPLICATE_JOB_ALERT.DUPLICATE_JOB_SUBTEXT_TWO}
            </Text>
          </ContentWrapper>

          <ButtonWrapper>
            <ActionButton variant="outlined" onClick={handleCancel}>
              {DUPLICATE_JOB_ALERT.BUTTON_CANCEL}
            </ActionButton>
            <ActionButton variant="contained" sx={{}} onClick={handlePost}>
              {DUPLICATE_JOB_ALERT.BUTTON_POST}
            </ActionButton>
          </ButtonWrapper>
        </ContentContainer>
      </Card>
    </Modal>
  );
};

export default DuplicateJobAlert;

const ContentContainer = styled("div")`
  margin-top: ${styleUtils.pxToRem("41px")};
  margin-left: ${styleUtils.pxToRem("62px")};
  margin-right: ${styleUtils.pxToRem("62px")};
  margin-bottom: ${styleUtils.pxToRem("40px")};
  background-color: ${colors.white};
`;

const ContentWrapper = styled("div")`
  height: ${styleUtils.pxToRem("58px")};
  width: ${styleUtils.pxToRem("464px")};
  margin-top: ${styleUtils.pxToRem("35px")};
  text-align: center;
`;

const ImgContainer = styled("img")`
  height: ${styleUtils.pxToRem("134px")};
  width: ${styleUtils.pxToRem("75px")};
  margin: auto;
  display: block;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${styleUtils.pxToRem("48px")};
  gap: ${styleUtils.pxToRem("24.5px")};
`;

const ActionButton = styled(Button)`
  font-size: ${textSizes.l};
  border-adius: ${spacing.xl};
  width: ${styleUtils.pxToRem("209px")};
  height: ${styleUtils.pxToRem("52px")};
`;
