import { handleActions } from "redux-actions";
import update from "immutability-helper";

import { loginInitialState } from "./initialState";
import * as loginActionTypes from "../actions/actionTypes";
import { USER_DETAIL_SUCCESS } from "containers/Common/actions/commonActionTypes";

const loginReducer = {
  loginState: handleActions(
    {
      [loginActionTypes.LOGIN_REQUEST]: (state) => {
        return {
          ...state,
          isLoginLoading: true,
          isLoginFailed: false,
          isLoginSuccess: false,
          user: null,
          userSocial: null,
        };
      },

      [loginActionTypes.LOGIN_FAILED]: (state) => {
        return {
          ...state,
          isJobSeekerLoginLoading: false,
          isLoginFailed: true,
          isLoginSuccess: false,
          user: null,
          userSocial: null,
        };
      },
      [loginActionTypes.LOGIN_SUCCESS]: (state, { userData, userSocialData }) => {
        return {
          ...state,
          isLoginLoading: false,
          isLoginFailed: false,
          isLoginSuccess: true,
          user: userData,
          userSocial: userSocialData,
        };
      },
      [loginActionTypes.SOCIAL_LOGIN_REQUEST]: (state) => {
        return {
          ...state,
          isLoginLoading: true,
          isLoginFailed: false,
          isLoginSuccess: false,
          user: null,
          userSocial: null,
        };
      },

      [loginActionTypes.SOCIAL_LOGIN_FAILED]: (state) => {
        return {
          ...state,
          isJobSeekerLoginLoading: false,
          isLoginFailed: true,
          isLoginSuccess: false,
          user: null,
          userSocial: null,
        };
      },
      [loginActionTypes.SOCIAL_LOGIN_SUCCESS]: (state, { userData, userSocialData }) => {
        return {
          ...state,
          isLoginLoading: false,
          isLoginFailed: false,
          isLoginSuccess: true,
          user: userData,
          userSocial: userSocialData,
        };
      },
      [loginActionTypes.USER_DETAILS_UPDATE_SUCCESS]: (state, { userData }) => {
        return {
          ...state,
          user: {
            ...state.user,
            ...userData,
          },
        };
      },
      [loginActionTypes.USER_DETAIL_UPDATE]: (state, { payload }) => {
        return {
          ...state,
          user: {
            ...state.user,
            ...payload,
          },
        };
      },
      //User Preferences/Settings
      [loginActionTypes.USER_SETTINGS_UPDATE]: (state, { payload: { settingType, settings } }) => {
        return update(state, {
          settings: {
            [settingType]: { $set: settings },
          },
        });
      },
      //User Location
      [loginActionTypes.USER_LOCATION_UPDATE]: (state, { payload: location }) => {
        return update(state, {
          location: { $set: location },
          isLocationUpdating: { $set: false },
        });
      },

      [loginActionTypes.USER_LOCATION_UPDATING_STATUS]: (state, { payload: isLoadingStart }) => {
        return update(state, {
          isLocationUpdating: { $set: isLoadingStart },
        });
      },
      //User Search Location
      [loginActionTypes.USER_SEARCH_LOCATION_UPDATE]: (state, { payload: location }) => {
        return update(state, {
          searchLocation: { $set: location },
        });
      },
      [loginActionTypes.LOGOUT]: (state) => {
        return update(state, {
          isLoginLoading: { $set: false },
          isLoginFailed: { $set: false },
          isLoginSuccess: { $set: false },
          user: { $set: null },
          userSocial: { $set: null },
          settings: {
            $set: {
              jobSeekerListingFilter: null,
              employerListingFilter: null,
              employerJobSeekerListingFilter: null,
            },
          },
        });
      },
      [loginActionTypes.SET_SIGN_UP_USER]: (state, { userData }) => {
        return {
          ...state,
          isLoginLoading: false,
          isLoginFailed: false,
          isLoginSuccess: true,
          user: userData,
        };
      },
      [loginActionTypes.LOGIN_REQUEST]: (state) => {
        return {
          ...state,
          isLoginLoading: true,
          isLoginFailed: false,
          isLoginSuccess: false,
          user: null,
          userSocial: null,
        };
      },

      [loginActionTypes.LOGIN_FAILED]: (state) => {
        return {
          ...state,
          isJobSeekerLoginLoading: false,
          isLoginFailed: true,
          isLoginSuccess: false,
          user: null,
          userSocial: null,
        };
      },
      [loginActionTypes.GET_BLOCKED_USERS_LIST_SUCCESS]: (state, { blocked_users, blockedIds }) => {
        return {
          ...state,
          user: {
            ...state.user,
            blockedIds: [...blockedIds],
          },
          blockedUsers: [...blocked_users],
        };
      },
      [loginActionTypes.GET_RECENT_UNBLOCKED_USERS_LIST_SUCCESS]: (state, { unblock_users }) => {
        return {
          ...state,
          recentlyUnblockedUsers: [...unblock_users],
        };
      },

      [loginActionTypes.FORGOT_PASSWORD_OTP_SUCCESS]: (state) => {
        return {
          ...state,
        };
      },
      [loginActionTypes.VERIFY_FORGOT_PASSWORD_OTP_SUCCESS]: (state, { payload }) => {
        return {
          ...state,
          isForgotPasswordOtpValid: payload.verifiedStatus,
          forgotPasswordVerificationToken: payload.forgotPasswordVerificationToken,
        };
      },
      [USER_DETAIL_SUCCESS]: (state, { userData }) => {
        return {
          ...state,
          user: {
            ...state.user,
            ...userData,
          },
        };
      },
    },
    loginInitialState,
  ),
};

export default loginReducer;
