import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { spacing } from "../../../styles";
import { Icon, Text } from "../../atoms";
import { images } from "../../../assets/images";
import useIsMobile from "../../../hooks/useIsMobile";

const ReviewCard = ({ item, cardStyle, numberOfLines = 4 }) => {
  const isMobile = useIsMobile();
  return (
    <Card
      sx={{
        ...cardStyle,
        display: "flex",
        flexDirection: "column",
        padding: spacing.xxl,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Text
          color="#041433"
          weight="semibold"
          size="l"
          sx={{ marginBottom: spacing.s }}
        >
          “{item.title}”
        </Text>
        <Text isMinHeight numberOfLines={numberOfLines} color="#4C5468">
          “ {item.message} ”
        </Text>
        <Row>
          {Array(5)
            .fill(0)
            .map((i, index) => (
              <Icon
                key={index}
                name="star-new"
                style={{ marginRight: spacing.s }}
              />
            ))}
        </Row>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Text size="l" weight="bold" color="#4C5468">
              {item.name}
            </Text>
            <Text size="s" color="#4C5468">
              {item.job}
            </Text>
            <Text size="s" color="#4C5468">
              {item.location}
            </Text>
          </div>
          <img
            alt="“"
            src={images["testimony"]}
            style={{ width: isMobile ? 40 : 82, height: 90 }}
          />
        </div>
      </div>
    </Card>
  );
};

export default ReviewCard;

const Row = styled("div")`
  display: flex;
  margin-top: ${spacing.m};
  margin-bottom: ${spacing.m};
`;
