import { Container, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

import DownloadApp from "./DownloadApp";

import { TERMS_CONDITION, PRIVACY_POLICY, BLOGS } from "../../../routes/routeConstants";

import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing } from "../../../styles";
import { SOCIAL_LINKS } from "../../../appConstants";
import { FOOTER_LINKS, FooterConstants } from "../Constants";
import { ACCOUNT_ACTIVATION_CONSTS } from "containers/SignUp/constants";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: colors.primary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container sx={{ py: spacing.xxxl }}>
        <Text
          color={colors.white}
          size={24}
          weight="bold"
          style={{ lineHeight: 1.2 }}
        >
          {FooterConstants.EMPLOYD_TAG}
        </Text>
        <DownloadApp />
        <div style={{ marginTop: spacing.xxxl }}>
          <NavLink
            to={BLOGS}
            style={{ textDecoration: "none" }}
          >
            <Text
              weight="bold"
              color={colors.white}
              sx={{
                marginBottom: spacing.xl,
              }}
            >
              {FooterConstants.BLOGS}
            </Text>
          </NavLink>
          <a
            href={`mailto:${ACCOUNT_ACTIVATION_CONSTS.SUPPORT_EMAIL}`}
            style={{ textDecoration: "none" }}
          >
            <Text
              weight="bold"
              color={colors.white}
              sx={{
                marginBottom: spacing.l,
              }}
            >
              {FooterConstants.CONTACT_US}
            </Text>
          </a>
          <NavLink
            to={PRIVACY_POLICY}
            style={{ textDecoration: "none" }}
          >
            <Text
              weight="bold"
              color={colors.white}
              sx={{
                marginBottom: spacing.l,
              }}
            >
              {FooterConstants.PRIVACY_POLICY}
            </Text>
          </NavLink>
          <NavLink
            to={TERMS_CONDITION}
            style={{ textDecoration: "none" }}
          >
            <Text
              weight="bold"
              color={colors.white}
              sx={{
                marginBottom: spacing.l,
              }}
            >
              {FooterConstants.TERMS_AND_CONDITION}
            </Text>
          </NavLink>
        </div>
        <Divider sx={{ borderColor: "#00227c", marginTop: spacing.xxxl }} />
        <div style={{ marginTop: spacing.l, marginBottom: spacing.l }}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.facebook}
          >
            <SocialImage
              src={images.social.facebook}
              alt="Facebook"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.twitter}
          >
            <SocialImage
              src={images.social.twitter}
              alt="Twitter"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.instagram}
          >
            <SocialImage
              src={images.social.instagram}
              alt="Instagram"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.linkedin}
          >
            <SocialImage
              src={images.social.linkedin}
              alt="LinkedIn"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.tiktok}
          >
            <SocialImage
              src={images.social.tiktok}
              alt="TikTok"
            />
          </a>
        </div>
        <Text
          color={colors.white}
          size="xs"
          style={{ marginTop: spacing.xl }}
        >
          {FooterConstants.COPYRIGHT_RIGHTS_RESERVED}
        </Text>

        <Divider sx={{ borderColor: "#00227c", margin: `${spacing.xxl} 0` }} />
        {FOOTER_LINKS.map((item) => (
          <FooterJobLinksTextWrapper key={item.label}>
            <FooterJobLinksTitle>{item.label}</FooterJobLinksTitle>
            {item.information.map((itemDetails) => (
              <FooterJobLinksAnchorTag
                rel="noopener noreferrer"
                target="_blank"
                href={itemDetails.link}
                key={itemDetails.link}
              >
                <Text color={colors.white}>{itemDetails.text}</Text>
              </FooterJobLinksAnchorTag>
            ))}
          </FooterJobLinksTextWrapper>
        ))}
      </Container>
    </div>
  );
};

export default Footer;

const SocialImage = styled("img")`
  margin-right: ${spacing.s};
  width: 24px;
  height: 24px;
`;

const FooterJobLinksTextWrapper = styled("div")`
  color: ${colors.white};
  margin-bottom: ${spacing.xxxl};
`;
const FooterJobLinksTitle = styled(Text)`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: ${spacing.s};
`;

const FooterJobLinksAnchorTag = styled("a")`
  text-decoration: none;
  display: block;
  margin-bottom: ${spacing.descrsize};
  white-space: nowrap;
`;
