import React from "react";
import { Button, Card, Container, Modal, styled } from "@mui/material";

import useIsMobile from "hooks/useIsMobile";

import Text from "../../atoms/Text";
import Icon from "../../atoms/Icon";

import { colors, spacing, styleUtils } from "styles";

const JOB_NOT_FOUND_CONSTANTS = {
  JOB_NOT_FOUND: "Job cannot be found",
  BUTTON_OKAY: "Okay",
};

const JobNotFoundModal = (props) => {
  const { isOpen, handleClickForJobNotFound } = props;
  const isMobile = useIsMobile();

  return (
    <Modal open={isOpen} style={{ backdropFilter: "blur(2px)" }}>
      <Container sx={{ mt: spacing.xxl }}>
        <Card
          sx={{
            p: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: styleUtils.pxToRem(isMobile ? "300px" : "320px"),
            width: styleUtils.pxToRem(isMobile ? "350px" : "450px"),
          }}
        >
          <ContentWrapper>
            <Icon name="job-not-found" size={styleUtils.pxToRem("100px")} />
            <Text size="m" weight="bold" color={colors.black}>
              {JOB_NOT_FOUND_CONSTANTS.JOB_NOT_FOUND}
            </Text>
            <Button
              variant="contained"
              sx={{ width: "30%" }}
              onClick={handleClickForJobNotFound}
            >
              {JOB_NOT_FOUND_CONSTANTS.BUTTON_OKAY}
            </Button>
          </ContentWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

export default JobNotFoundModal;

const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${styleUtils.pxToRem("30px")};
  height: 100%;
  margin-bottom: 0;
`;
