import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { EMPLOYER } from "../../appConstants";

import LoginSelector from "../../containers/Login/selectors/LoginSelector";
import ContentSelection from "./ContentSelection";

// TODO: recheck the name of this component. It handles employer signup as well via ContentSelection
const JobSeekerRoute = () => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  return isAuthenticated && loggedInUser?.userType === EMPLOYER ? <ContentSelection /> : <Outlet />;
};

export default JobSeekerRoute;
