import PropTypes from "prop-types";
import { Modal, Card, Container, Divider } from "@mui/material";

import { Text } from "../../../../components/atoms";

import ContinueLoginContainer from "containers/Account/ContinueLoginContainer";
import UserInfo from "./UserInfo";
import SetNewPassword from "./SetNewPassword";
import CurrentPassword from "./CurrentPassword";

import { CHANGE_PASSWORD } from "containers/Account/constants";
import { spacing, styleUtils } from "../../../../styles";

const ChangePassword = ({
  currentPasswordFormik,
  onClickPasswordScreen,
  newPasswordFormik,
  passwordResetStatus,
  showNewPasswordForm,
}) => {
  return (
    <Modal open={true}>
      <Container
        sx={{
          mt: spacing.xxl,
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: "calc(100vh - 91px)",
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            paddingBottom: styleUtils.pxToRem("25px"),
          }}
        >
          {passwordResetStatus ? (
            <ContinueLoginContainer />
          ) : (
            <>
              <Text
                size={22}
                weight="bold"
                sx={{
                  marginLeft: spacing.xl,
                  py: spacing.m,
                }}
              >
                {CHANGE_PASSWORD.CHANGE_PASSWORD_HEADER}
              </Text>
              <Divider />
              <UserInfo />
              <Divider flexItem sx={{ mx: spacing.s }} />
              {showNewPasswordForm ? (
                <SetNewPassword
                  newPasswordFormik={newPasswordFormik}
                  onClickPasswordScreen={onClickPasswordScreen}
                />
              ) : (
                <CurrentPassword
                  currentPasswordFormik={currentPasswordFormik}
                  onClickPasswordScreen={onClickPasswordScreen}
                />
              )}
            </>
          )}
        </Card>
      </Container>
    </Modal>
  );
};

ChangePassword.propTypes = {
  currentPasswordFormik: PropTypes.object.isRequired,
  newPasswordFormik: PropTypes.object.isRequired,
  onClickPasswordScreen: PropTypes.func.isRequired,
  passwordResetStatus: PropTypes.bool.isRequired,
  showNewPasswordForm: PropTypes.bool.isRequired,
};

export default ChangePassword;
