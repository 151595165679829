import PropTypes from "prop-types";
import { Divider } from "@mui/material";

import UserInfo from "./UserInfo";
import SetNewPassword from "./SetNewPassword";
import CurrentPassword from "./CurrentPassword";
import ContinueLoginContainer from "containers/Account/ContinueLoginContainer";

import { spacing } from "../../../../styles";

const ChangePassword = ({
  currentPasswordFormik,
  newPasswordFormik,
  passwordResetStatus,
  showNewPasswordForm,
  handleEdit,
}) => {
  return (
    <>
      {passwordResetStatus ? (
        <ContinueLoginContainer />
      ) : (
        <>
          <UserInfo />
          <Divider flexItem sx={{ mx: spacing.s, my: spacing.m }} />
          {showNewPasswordForm ? (
            <SetNewPassword
              newPasswordFormik={newPasswordFormik}
              handleEdit={handleEdit}
            />
          ) : (
            <CurrentPassword
              currentPasswordFormik={currentPasswordFormik}
              handleEdit={handleEdit}
            />
          )}
        </>
      )}
    </>
  );
};

ChangePassword.propTypes = {
  currentPasswordFormik: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  newPasswordFormik: PropTypes.object.isRequired,
  passwordResetStatus: PropTypes.bool.isRequired,
  showNewPasswordForm: PropTypes.bool.isRequired,
};

export default ChangePassword;
