import { Container } from "@mui/material";

import { images } from "../../../assets/images";
import { MultiItemCarousel, Text } from "../../../components/atoms";
import { ReviewCard } from "../../../components/molecules";
import { colors, spacing } from "../../../styles";
import { convertArrayIntoChunks } from "../../../utils/commonUtils";

const Reviews = ({ reviews }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "70vh",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignSelf: "center",
          height: "100%",
          backgroundImage: `url(${images.testimonialBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "30px",
          position: "absolute",
        }}
      />
      <div style={{ zIndex: 999 }}>
        <Text
          style={{
            textAlign: "center",
            lineHeight: 0.2,
            marginBottom: spacing.s,
            marginTop: spacing.xxxl,
            fontSize: "7rem",
            transform: "rotateY(180deg)",
          }}
        >
          &rdquo;
        </Text>
        <Text
          size={42}
          style={{
            textAlign: "center",
            margin: "0 240px",
            lineHeight: 1.2,
            marginBottom: spacing.xxs,
          }}
        >
          The{" "}
          <Text
            size={42}
            component="span"
            weight="extrabold"
            color="#2B4895"
            style={{ lineHeight: 1.2 }}
          >
            EMPLOY
            <Text
              size={42}
              component="span"
              weight="extrabold"
              color="#0A97FF"
              style={{ lineHeight: 1.2 }}
            >
              D
            </Text>{" "}
          </Text>
          Hiring Experience
        </Text>
        <Text
          size="m"
          color={colors.black}
          style={{
            textAlign: "center",
            margin: "0 240px",
            marginBottom: spacing.xxxl,
          }}
        >
          Employers love EmployD!
        </Text>
        <MultiItemCarousel interval={8000}>
          {convertArrayIntoChunks(reviews, 2).map((j, jindex) => (
            <div
              key={jindex}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {j.map((i, index) => (
                <ReviewCard
                  item={i}
                  key={index}
                  cardStyle={{
                    width: 512,
                    marginBottom: spacing.s,
                  }}
                />
              ))}
            </div>
          ))}
        </MultiItemCarousel>
      </div>
    </div>
  );
};

export default Reviews;
