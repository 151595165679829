import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { Text } from "../../components/atoms";
import { JOB_SEEKER } from "../../appConstants";
import { ChatConstants } from "./Constants";
import { images } from "../../assets/images";
import { colors, spacing } from "../../styles";

import {
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  EMPLOYER_PAGE_ROUTE,
  PEOPLE_PAGE_ROUTE,
} from "../../routes/routeConstants";

const EmptyChatView = (props) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <img
        alt="NODATA"
        src={images["noChatFound"]}
        style={{ width: 212, height: 177 }}
      />
      {props.loggedInUser?.userType === JOB_SEEKER ? (
        <>
          <Text weight="bold" size="xl" style={{ textAlign: "center" }}>
            {ChatConstants.NO_CONVERSATION} <br />
          </Text>
          <Text
            size="l"
            color={colors.placeholder}
            style={{ textAlign: "center" }}
          >
            {ChatConstants.LOOK_FOR_NEW_JOB}
          </Text>
        </>
      ) : (
        <>
          <Text weight="bold" size="xl" style={{ textAlign: "center" }}>
            {ChatConstants.NO_CONVERSATION} <br />
          </Text>
          <Text
            size="l"
            color={colors.placeholder}
            style={{ textAlign: "center" }}
          >
            {ChatConstants.CHAT_WITH_JOB_SEEKER}
          </Text>
        </>
      )}
      <Button
        variant="contained"
        sx={{
          mt: spacing.xl,
          width: "auto",
          padding: `${spacing.xs} ${spacing.xxxl}`,
        }}
        onClick={() => {
          props.loggedInUser?.userType === JOB_SEEKER
            ? navigate({
                pathname: `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
              })
            : navigate(`${EMPLOYER_PAGE_ROUTE}/${PEOPLE_PAGE_ROUTE}`);
        }}
      >
        <Text color={colors.white} weight="bold" size="l">
          {props.loggedInUser?.userType === JOB_SEEKER
            ? ChatConstants.EXPLORE_JOBS
            : ChatConstants.CHAT_WITH_JOB_SEEKER_BUTTON}
        </Text>
      </Button>
    </Wrapper>
  );
};

export default EmptyChatView;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
