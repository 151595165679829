import { styled } from "@mui/material/styles";

import { Text } from "../../../../../components/atoms";

import { images } from "assets/images";
import { colors, spacing } from "styles";

import { ACCOUNT_ACTIVATION_CONSTS } from "containers/SignUp/constants";

const ActivationLinkExpiredView = () => {
  return (
    <ViewContainer>
      <img
        src={images.inActiveDirectSignup}
        width={209}
        height={155}
        alt="inActive Link"
        style={{ marginTop: spacing.s, marginBottom: spacing.xxl }}
      />
      <Text size="s" color={colors.charcoalGray} sx={{ textAlign: "center" }}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_1}
      </Text>
      <Text size="s" color={colors.charcoalGray}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_2}
      </Text>
      <Text size="s" color={colors.charcoalGray}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_3}{" "}
        <StyledLink
          href={window.location.origin + "/login/employer"}
          target="_blank"
          rel="noreferrer"
        >
          {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_4}
        </StyledLink>{" "}
      </Text>

      <Text size="s" color={colors.charcoalGray} sx={{ marginTop: spacing.l }}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_5}
      </Text>
      <Text size="s" color={colors.charcoalGray} sx={{ textAlign: "center" }}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_6}
        <StyledLink
          href={`mailto:${ACCOUNT_ACTIVATION_CONSTS.SUPPORT_EMAIL}`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          {ACCOUNT_ACTIVATION_CONSTS.SUPPORT_EMAIL}
        </StyledLink>{" "}
      </Text>
      <Text size="s" color={colors.charcoalGray} sx={{ textAlign: "center" }}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_LINK_EXPIRED_7}
      </Text>
    </ViewContainer>
  );
};

export default ActivationLinkExpiredView;

const ViewContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLink = styled("a")`
  color: ${colors.dodgerBlue};
  text-decoration: none;
  cursor: pointer;
  font-style: italic;
`;
