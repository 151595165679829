import { Autocomplete, Box, InputAdornment, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { colors, spacing } from "styles";
import { Icon, Text } from "..";

//Job Type Constants
const FIELD_NAME = {
  brandName: "Employer",
  listingPosition: "Jobs",
};

const EmploydSearchInput = ({
  name,
  // onChangeSearchTerm, //TO-DO
  fetchSuggestions,
  suggesstions = [],
  suggestionValue,
  setSearchValue,
  placeholder,
  showFieldType = false,
  inputSx,
  showFullWidth = true,
  showSearchIcon = true,
}) => {
  return (
    <Autocomplete
      freeSolo
      fullWidth={showFullWidth}
      value={suggestionValue}
      onChange={(event, newValue) => {
        setSearchValue("searchTerm", newValue);
      }}
      onInputChange={debounce((event, newInputValue) => {
        fetchSuggestions(newInputValue);
      }, 350)}
      options={
        suggesstions.length > 0
          ? suggesstions.map((option) => option.value)
          : []
      }
      renderInput={(params) => {
        return (
          <InputBase
            inputProps={params.inputProps}
            placeholder={placeholder}
            ref={params.InputProps.ref}
            onChange={(event) => {
              setSearchValue(name, event.target.value);
            }}
            startAdornment={
              <InputAdornment sx={{ marginRight: spacing.m }} position="start">
                {showSearchIcon && <Icon size={20} name="search" />}
              </InputAdornment>
            }
            {...params}
            sx={{
              ...inputSx,
            }}
          />
        );
      }}
      renderOption={(props, option, { inputValue }) => {
        const substrStartIndex = option
          ?.toLowerCase()
          .indexOf(inputValue.toLowerCase());

        const fieldType = showFieldType
          ? suggesstions.filter((pos) => pos.value === option)[0]?.field
          : "";

        return (
          <SuggestionList
            style={{
              "&:hover": {
                backgroundColor: colors.selectedBlue,
              },
            }}
            {...props}
          >
            <Box>
              <Text component="span" size="s" weight="medium" color="#3C3C3C">
                {option.slice(0, substrStartIndex)}
              </Text>
              <Text component="span" size="s" weight="semibold" color="#2B4894">
                {option.slice(
                  substrStartIndex,
                  substrStartIndex + inputValue.length
                )}
              </Text>
              <Text component="span" size="s" weight="medium" color="#3C3C3C">
                {option.slice(substrStartIndex + inputValue.length)}
              </Text>
              {showFieldType && (
                <Text
                  component="span"
                  size="s"
                  color="#A7A2A2"
                  style={{ fontStyle: "italic" }}
                >
                  {` in ${FIELD_NAME[fieldType]}`}
                </Text>
              )}
            </Box>
          </SuggestionList>
        );
      }}
      sx={{
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
          {
            backgroundColor: colors.selectedBlue,
          },
      }}
    />
  );
};

export default EmploydSearchInput;

const SuggestionList = styled("li")`
  border-bottom: 1px solid #e0e0e0;
`;
