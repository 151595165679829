import { getBrandJobsApi } from "configs/network/apis";
import { useQuery } from "react-query";

export const useGetEmployerDetailsQuery = ({
  employerUid,
  status = ["active"],
}) => {
  return useQuery(["employer-details", employerUid], () =>
    getBrandJobsApi({
      employerUid,
      status,
    })
  );
};
