import { useSelector } from "react-redux";

import { EMPLOYER, JOB_SEEKER } from "../../../../appConstants";
import useChatNotificationCount from "../../../../hooks/useChatNotificationCount";
import LoginSelector from "../../../Login/selectors/LoginSelector";

import EmployerAuthenticatedNav from "./EmployerAuthenticatedNav";
import JobSeekerAuthenticatedNav from "./JobSeekerAuthenticatedNav";

const AuthenticatedNav = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const chatBadgeCount = useChatNotificationCount();

  return (
    <>
      {loggedInUser?.userType === EMPLOYER && (
        <EmployerAuthenticatedNav chatBadgeCount={chatBadgeCount} />
      )}
      {loggedInUser?.userType === JOB_SEEKER && (
        <JobSeekerAuthenticatedNav chatBadgeCount={chatBadgeCount} />
      )}
    </>
  );
};

export default AuthenticatedNav;
