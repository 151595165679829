import { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import LoginEmailView from "./views/LoginEmailView";
import * as loginActions from "../Login/actions/LoginActions";
import { validationSchema } from "./LoginEmailValidation";
import { userExistApi } from "configs/network/apis";

import {
  getRedirectValue,
  hideLoader,
  showLoader,
  showNotification,
} from "../../utils/commonUtils";

import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import { EMAIL_LOGIN_CONSTS } from "./views/constants";
import {
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  FORGOT_PASSWORD_PAGE_ROUTE,
} from "../../routes/routeConstants";

const LoginWithEmail = (props) => {
  const previousEmail = useRef();
  const { loginActionsProp } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginType: userType } = useParams();
  const onSubmit = async (results) => {
    let { userName, password, showPassword } = results;
    userName = userName.toLowerCase();
    showLoader();
    try {
      if (!showPassword) {
        loginFormik.setFieldTouched("password", false);
        const { data } = await userExistApi({ keyValue: userName, keyType: "email" });
        if (!data.isActive) {
          hideLoader();
          showNotification({
            alertProps: {
              severity: "error",
              // Not keeping this in constants as this will be removed with toast messages work
              children: `Kindly activate your account using the link provided on email ${data.email}`,
            },
          });
        } else if (data.loginType === "PW" && data.userType === userType) {
          previousEmail.current = userName;
          loginFormik.setFieldValue("showPassword", true);
          hideLoader();
        } else if (data.loginType !== "PW" && data.userType === userType) {
          hideLoader();
          showNotification({
            alertProps: {
              severity: "info",
              children: "You have registered with us socially, Please login through the same",
            },
          });
        } else if (data.userType !== userType) {
          hideLoader();
          showNotification({
            alertProps: {
              severity: "error",
              children: "You are registered with another type. Please login through the same",
            },
          });
        }
        return;
      }
      handleLogin({ userName, password });
    } catch (e) {
      const eventUserType =
        userType === EMPLOYER ? FirebaseEvents.EMP_EMAIL_SIGNUP : FirebaseEvents.JS_EMAIL_SIGNUP;
      logEvent(eventUserType);
      navigate(`/signup/${userType}`, {
        state: {
          referrer: state?.referrer,
          email: userName,
        },
      });
    } finally {
      hideLoader();
    }
  };

  const loginFormik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      showPassword: false,
    },
    validationSchema,
    onSubmit,
  });

  const handleLogin = (params) => {
    showLoader();
    const { userName, password } = params;
    loginActionsProp.loginRequest({
      email: userName,
      password,
      type: userType,
      onCallback: onLoginCallback,
    });
  };

  const onLoginCallback = async (params) => {
    if (params.isPasswordInvalid) {
      loginFormik.setFieldError("password", EMAIL_LOGIN_CONSTS.EMAIL_SIGNIN_ERROR);
    } else {
      const [redirectValue] = [getRedirectValue()];
      const { pathname, search, state } = redirectValue;
      if (pathname) {
        navigate(
          {
            pathname,
            search,
          },
          {
            state,
          },
        );
      } else if (params.type === JOB_SEEKER) {
        search
          ? navigate({
              pathname: `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
              search,
            })
          : navigate(`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`);
      } else {
        search
          ? navigate({
              pathname: `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`,
              search,
            })
          : navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`);
      }
      showNotification({
        alertProps: {
          severity: "success",
          children: "You are logged in successfully",
        },
      });
      const eventUserType =
        userType === EMPLOYER ? FirebaseEvents.EMP_EMAIL_SIGNIN : FirebaseEvents.JS_EMAIL_SIGNIN;
      logEvent(eventUserType);
    }
  };

  const onClickForgotPassword = (email) => {
    dispatch(
      loginActions.shareForgotPasswordOtpRequest({
        email: email,
        userType: userType,
        onCallback: forgotPasswordCallback,
      }),
    );
  };

  const forgotPasswordCallback = ({ email, phoneNumber }) => {
    navigate(FORGOT_PASSWORD_PAGE_ROUTE, {
      state: {
        userType: userType,
        email: email,
        phoneNumber: phoneNumber,
      },
    });
  };

  return (
    <LoginEmailView
      handleLogin={handleLogin}
      type={userType}
      referrer={state?.referrer}
      onClickForgotPassword={onClickForgotPassword}
      loginFormik={loginFormik}
      previousEmail={previousEmail}
    />
  );
};

const mapStateToProps = ({ loginState }) => ({
  isLoginLoading: loginState.isLoginLoading,
  isLoginFailed: loginState.isLoginFailed,
  isLoginSuccess: loginState.isLoginSuccess,
  user: loginState.user,
});

const mapDispatchToProps = (dispatch) => ({
  loginActionsProp: bindActionCreators(loginActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithEmail);
