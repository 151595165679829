import { useCallback, useEffect, useRef } from "react";
import { Box, Card, Container, Grid, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Text,
  Icon,
  SelectDropDown,
  EmploydSearchInput,
} from "../../../../../../../components/atoms";
import {
  PeopleCard,
  PeopleDetailCard,
} from "../../../../../../../components/molecules";
import { spacing, styleUtils } from "../../../../../../../styles";

import Filter from "./Filter";
import Sort from "./Sort";
import { images } from "../../../../../../../assets/images";

const PeopleDescription = ({
  employerActiveLocations,
  selectedLoc,
  employerJobSeekerListing,
  toggleExploreView,
  filterSettings,
  onClickSearch,
  onClickPeople,
  searchTerm,
  onChangeSearchTerm,
  handleFilter,
  handleLocationChange,
  handleSorting,
  showLoadMore,
  handleLoadMorePeople,
  handleMarkStarCallback,
  seekerSuggestions,
  fetchSeekerSuggestions,
  setSeekerSearchValue,
}) => {
  const renderPeople = (item, index) => {
    return (
      <PeopleCard
        key={index}
        item={item}
        onClickPeople={onClickPeople}
        cardStyle={{
          marginBottom: spacing.xl,
          marginRight: spacing.s,
        }}
      />
    );
  };

  //#region - Debounce Search
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);

  const onSearch = useCallback(
    debounce((text) => {
      onClickSearch(text)();
    }, 1500),
    []
  );
  //#endregion

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Grid container columnSpacing={spacing.m}>
        <Grid item md={3.5}>
          <LocationDropdownContainer>
            <Icon
              name="people-loc-dropdown"
              size={20}
              style={{
                alignSelf: "end",
                marginRight: styleUtils.remToPx("6px"),
              }}
            />
            <FormControl variant="standard">
              <Text size="xs" color="#898989">
                Results for job posted in
              </Text>
              <SelectDropDown
                fieldValue={selectedLoc}
                fieldName="employer_locations"
                variant="standard"
                handleChange={handleLocationChange}
                dropDownOptions={employerActiveLocations}
                placeholder=" "
                widthValue={264.5}
                sx={{
                  borderBottom: "0.4000000059604645px solid #2B4894",
                  border: "none",
                  height: styleUtils.pxToRem("18px"),
                  "& .MuiSelect-select:focus": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
              />
            </FormControl>
          </LocationDropdownContainer>
          <Card
            sx={{
              height: "86vh",
              py: spacing.l,
              pl: spacing.s,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row>
              <EmploydSearchInput
                name="searchTerm"
                placeholder="Search by job seeker name"
                onChangeSearchTerm={onChangeSearchTerm}
                fetchSuggestions={fetchSeekerSuggestions}
                setSearchValue={setSeekerSearchValue}
                suggesstions={seekerSuggestions}
                suggestionValue={searchTerm}
                showFullWidth={false}
                inputSx={{ height: 44, width: 178 }}
              />
              <Sort handleSorting={handleSorting} />
              <Filter
                handleFilter={handleFilter}
                filterSettings={filterSettings}
              />
            </Row>
            <JobsContainer id="scrollableDiv">
              {employerJobSeekerListing?.length > 0 ? (
                <InfiniteScroll
                  dataLength={employerJobSeekerListing?.length}
                  hasMore={showLoadMore}
                  next={handleLoadMorePeople}
                  scrollableTarget="scrollableDiv"
                >
                  {employerJobSeekerListing?.map(renderPeople)}
                </InfiniteScroll>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={images.empty.search}
                    style={{ width: 100, height: 100 }}
                    alt="search"
                  />
                  <Text size="l" style={{ marginTop: spacing.s }}>
                    No results found for
                  </Text>
                  <Text size="l">this search</Text>
                </Box>
              )}
            </JobsContainer>
          </Card>
        </Grid>
        <Grid
          item
          md={8.5}
          sx={{ display: "flex", flexDirection: "column", height: "86vh" }}
        >
          <IconContainer>
            <Icon
              name="arrow-back"
              size={42}
              style={{ cursor: "pointer" }}
              onClick={toggleExploreView}
            />
          </IconContainer>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <PeopleDetailCard handleMarkStarCallback={handleMarkStarCallback} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PeopleDescription;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${spacing.xl};
  padding-right: ${spacing.s};
`;

const IconContainer = styled("div")`
  display: flex;
  height: 3rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.l};
`;

const JobsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const LocationDropdownContainer = styled("div")`
  display: flex;
  height: ${styleUtils.pxToRem("48px")};
  margin-right: ${styleUtils.pxToRem("38px")};
  margin-bottom: ${styleUtils.pxToRem("8px")};
`;
