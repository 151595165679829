import { useState } from "react";
import { Button, Drawer, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import isEmpty from "lodash/isEmpty";

import { colors, spacing } from "../../../../styles";
import { Icon, Text, TextInput, EmploydSearchInput } from "../../../atoms";

const InputMobile = ({
  searchTermValue,
  mobileSearchValues,
  onChangeSearchTerm,
  onClickSearch,
  fetchPostedJobsSuggestions,
  setSearchValue,
  postedJobsSuggestions,
  sx,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onSearch = () => {
    handleDrawerClose();
    onClickSearch(searchTermValue);
  };

  const constructInputPlaceholder = () => {
    let text = "";
    if (mobileSearchValues?.term) {
      text = `${mobileSearchValues?.term}`;
    }
    return text;
  };

  const searchInputvalue = constructInputPlaceholder();

  return (
    <>
      <SearchInputContainer onClick={handleDrawerOpen} sx={sx}>
        <TextInput
          value={searchInputvalue}
          disabled
          placeholder="Search by job title"
          endAdornment={
            <InputAdornment position="end">
              <IconContainer>
                <Icon name="search-white" />
              </IconContainer>
            </InputAdornment>
          }
          sx={{
            paddingLeft: spacing.s,
            paddingRight: spacing.xxs,
            borderRadius: 40,
            "& .Mui-disabled": {
              WebkitTextFillColor: isEmpty(searchInputvalue)
                ? undefined
                : colors.textPrimary,
            },
          }}
        />
      </SearchInputContainer>
      <Drawer
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            borderRadius: 0,
            width: "100%",
            backgroundColor: colors.white,
            padding: `${spacing.l} ${spacing.m}`,
          },
        }}
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Icon
          name="back"
          color={colors.black}
          style={{ marginBottom: spacing.l }}
          onClick={handleDrawerClose}
        />
        <div>
          <Text
            style={{
              marginLeft: spacing.s,
              marginBottom: spacing.xs,
            }}
          >
            Jobs
          </Text>
          <EmploydSearchInput
            name="searchTerm"
            onChangeSearchTerm={onChangeSearchTerm}
            fetchSuggestions={fetchPostedJobsSuggestions}
            setSearchValue={setSearchValue}
            suggesstions={postedJobsSuggestions}
            suggestionValue={searchTermValue}
            placeholder="Search here"
            inputSx={{
              borderRadius: 40,
            }}
          />
        </div>
        <Button
          onClick={onSearch}
          variant="contained"
          sx={{ marginTop: spacing.xxxl, py: "14px" }}
        >
          <Text weight="bold" color={colors.white}>
            Search
          </Text>
        </Button>
      </Drawer>
    </>
  );
};

export default InputMobile;

const SearchInputContainer = styled("div")``;

const IconContainer = styled("div")`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
`;
