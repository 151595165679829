import { Grid, Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import { Icon, Text } from "../../../components/atoms";
import { images } from "../../../assets/images";
import { colors, spacing, styleUtils } from "../../../styles";
import GoogleLoginButton from "../../../components/molecules/GoogleLoginButton";
import { FacebookLoginButton } from "components/molecules";
import * as Loginconst from "../constants";
import { useState, useEffect } from "react";
import { JOB_SEEKER } from "appConstants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import AccountActivationView from "containers/SignUp/views/DirectEmployerSignUp/web/AccountActivationView";
import DividerWithText from "components/atoms/DividerWithText";

/**
 * Login Form modal view
 *
 */
const LoginWebView = ({
  onClickContinueWithEmail,
  onFailureGoogleLogin,
  onSuccessGoogleLogin,
  onSuccessFacebookLogin,
  type: userType,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //TODO - move activation to a different link
  const isActivationFlow = searchParams.get("isActivationFlow") === "true" || false;
  const [showTermsFooter, setShowTermsFooter] = useState(true);

  useEffect(() => {
    if (!isActivationFlow) {
      const event =
        userType === JOB_SEEKER
          ? FirebaseEvents.UU_JS_LANDS_SIGNUP_PAGE
          : FirebaseEvents.UU_EMP_LANDS_SIGNUP_PAGE;
      logEvent(event);
    }
  }, []);

  const onClickLoginViaPhoneNumber = () => {
    navigate(`/signup/${userType}`, {
      state: {
        isOtpLogin: true,
      },
    });
  };

  const onClickSignInAsJobseekerOrEmployer = () => {
    navigate(`/login/${userType === Loginconst.EMPLOYER_KEY ? "jobSeeker" : "employer"}`);
  };

  return (
    <>
      {isActivationFlow ? (
        <AccountActivationView setShowTermsFooter={setShowTermsFooter} />
      ) : (
        <ContentContainer>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Text
              size="xl"
              weight="bold"
              color={colors.titleBlack}
              sx={{
                marginTop: styleUtils.pxToRem("48px"),
                marginBottom: styleUtils.pxToRem("30px"),
              }}
            >
              {Loginconst.LOGIN_TEXT}{" "}
              {userType === Loginconst.EMPLOYER_KEY ? "Employer" : "Job Seeker"}
            </Text>
          </Grid>
          <CardContainer>
            <GoogleLoginButton
              onProfileSuccess={onSuccessGoogleLogin}
              onFail={onFailureGoogleLogin}
              render={(renderProps) => (
                <SocialButtonContainer onClick={renderProps.onClick} sx={{ gap: "1rem" }}>
                  <StyledDiv>
                    <SocialImage src={images["google-logo"]} />
                  </StyledDiv>
                  <Text
                    size="s"
                    weight="semibold"
                    color="#333333"
                    style={{ marginLeft: spacing.xs, marginTop: spacing.xxs }}
                  >
                    {Loginconst.GOOGLE}
                  </Text>
                </SocialButtonContainer>
              )}
            />
          </CardContainer>
          <CardContainer>
            <FacebookLoginButton
              onProfileSuccess={onSuccessFacebookLogin}
              onFail={(e) => console.log(e)}
              render={(renderProps) => (
                <SocialButtonContainer onClick={renderProps.onClick} sx={{ gap: "1rem" }}>
                  <StyledDiv>
                    <SocialImage src={images["facebook-logo"]} />
                  </StyledDiv>
                  <Text
                    size="s"
                    weight="semibold"
                    color={colors.black}
                    style={{ marginLeft: spacing.xs, marginTop: spacing.xxs }}
                  >
                    {Loginconst.FACEBOOK}
                  </Text>
                </SocialButtonContainer>
              )}
            />
          </CardContainer>
          <div
            style={{
              marginTop: styleUtils.pxToRem("30px"),
              display: "flex",
            }}
          >
            <DividerWithText text="or" width="100" size="m" color={colors.slateGrey} />
          </div>

          <LinkTextsContainer>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Icon name="profile-email" size={23} />
              <Text
                color={colors.royalBlue}
                size="s"
                weight="semibold"
                onClick={onClickContinueWithEmail}
                style={{
                  cursor: "pointer",
                  marginLeft: spacing.xxs,
                }}
              >
                {Loginconst.CONTINUE_WITH_EMAIL}
              </Text>
            </Grid>

            {userType === Loginconst.EMPLOYER_KEY && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginTop={spacing.l}
                marginBottom={spacing.xxl}
              >
                <Icon name="phone-employer" size={19} />
                <Text
                  color={colors.royalBlue}
                  size="s"
                  weight="semibold"
                  onClick={onClickLoginViaPhoneNumber}
                  style={{
                    cursor: "pointer",
                    marginLeft: spacing.xxs,
                  }}
                >
                  {Loginconst.OTP_LOGIN_BTN}
                </Text>
              </Grid>
            )}

            <Grid
              container
              style={{ marginTop: spacing.xxxl }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Icon name="info-user" size={17} style={{ marginRight: "4px" }} />
              <Text
                size="s"
                weight="semibold"
                color={colors.linkText}
                sx={{
                  fontStyle: "italic",
                }}
              >
                {Loginconst.COMMON_SIGNIN_TEXT}{" "}
                {userType === Loginconst.EMPLOYER_KEY ? "a Job Seeker, " : "an Employer, "}
              </Text>
              <Text
                color="#2B4895"
                weight="bold"
                size="s"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  marginLeft: spacing.xxs,
                  fontStyle: "italic",
                }}
                onClick={onClickSignInAsJobseekerOrEmployer}
              >
                {Loginconst.SIGNIN_HERE_TEXT}
              </Text>
            </Grid>
          </LinkTextsContainer>
        </ContentContainer>
      )}
      {/* TODO - make it as a reusable component in the next sprint */}
      {showTermsFooter && (
        <LoginTermsContainer sx={{ marginBottom: "-1rem" }}>
          <Text size="s" style={{ color: `${colors.charcoalGray}` }}>
            {Loginconst.AGREE_TO_TERMS}{" "}
            <StyledLink
              href={window.location.origin + "/termsAndConditions"}
              target="_blank"
              rel="noreferrer"
            >
              {Loginconst.TANDC}
            </StyledLink>{" "}
            and{" "}
            <StyledLink
              href={window.location.origin + "/privacyPolicy"}
              target="_blank"
              rel="noreferrer"
            >
              {Loginconst.PRIVACY_POLICY}
            </StyledLink>
          </Text>
        </LoginTermsContainer>
      )}
    </>
  );
};

LoginWebView.propTypes = {
  onClickContinueWithEmail: PropTypes.func.isRequired,
  onFailureGoogleLogin: PropTypes.func.isRequired,
  onSuccessGoogleLogin: PropTypes.func.isRequired,
  onSuccessFacebookLogin: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default LoginWebView;

const ContentContainer = styled("div")`
  margin-top: ${styleUtils.pxToRem("12px")};
`;

const CardContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${styleUtils.pxToRem("292px")};
  height: ${styleUtils.pxToRem("48px")};
  border: 1px solid ${colors.silver};
  border-radius: ${styleUtils.pxToRem("24px")};
  margin-top: ${styleUtils.pxToRem("16px")};
  cursor: pointer;
`;

const SocialButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding-top: ${styleUtils.pxToRem("5px")};
  width: 100%;
  padding-left: ${spacing.xs};
`;

const StyledDiv = styled("div")`
  border: 1px solid ${colors.silver};
  padding: ${spacing.xs};
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: -1rem;
  padding-top: -1rem;
  margin-left: -0.3rem;
  margin-top: -0.3rem;
`;

const SocialImage = styled("img")`
  width: ${styleUtils.pxToRem("21px")};
  height: ${styleUtils.pxToRem("21px")};
`;

const LinkTextsContainer = styled("div")`
  text-align: center;
  margin-top: ${styleUtils.pxToRem("32px")};
  width: ${styleUtils.pxToRem("292px")};
`;

const LoginTermsContainer = styled("div")`
  position: fixed;
  bottom: ${styleUtils.pxToRem("36px")};
`;

const StyledLink = styled("a")`
  color: ${colors.royalBlue};
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
`;
