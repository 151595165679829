import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";

import { Text } from "../../../../components/atoms";

import { JOB_SEEKER_REGISTRATION_CONSTS } from "../constants";
import { colors, spacing, styleUtils } from "../../../../styles";
import { images } from "../../../../assets/images";

const JobSeekerDetail = () => {
  return (
    <JobSeekerDetailsContainer>
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        sx={{ marginTop: spacing.s }}
      >
        {JOB_SEEKER_REGISTRATION_CONSTS.SEEKER_DETAILS_HEADER}
      </Text>
      <Text size="s" color={colors.subtextGray} sx={{ marginTop: spacing.xxs }}>
        {JOB_SEEKER_REGISTRATION_CONSTS.SEEKER_DETAILS_SUBTEXT}
      </Text>
      <List>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{
                color: colors.bulletGray,
                fontSize: "x-small",
              }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {JOB_SEEKER_REGISTRATION_CONSTS.SEEKER_DETAILS_INFO_ONE}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{
                color: colors.bulletGray,
                fontSize: "x-small",
              }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {JOB_SEEKER_REGISTRATION_CONSTS.SEEKER_DETAILS_INFO_TWO}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{
                color: colors.bulletGray,
                fontSize: "x-small",
              }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {JOB_SEEKER_REGISTRATION_CONSTS.SEEKER_DETAILS_INFO_THREE}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0 }}>
          <ListIcon>
            <CircleIcon
              sx={{
                color: colors.bulletGray,
                fontSize: "x-small",
              }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {JOB_SEEKER_REGISTRATION_CONSTS.SEEKER_DETAILS_INFO_FOUR}
          </Text>
        </ListItem>
      </List>
      <ImgContainer src={images.imgjobseekersignup} />
    </JobSeekerDetailsContainer>
  );
};

export default JobSeekerDetail;

const JobSeekerDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 74px;
`;

const ListIcon = styled(ListItemIcon)`
  min-width: ${styleUtils.pxToRem("8px")};
  margin-right: ${styleUtils.pxToRem("8px")};
`;

const ImgContainer = styled("img")`
  display: block;
  align-self: center;
  margin-top: ${styleUtils.pxToRem("100px")};
  margin-bottom: ${styleUtils.pxToRem("62.5px")};
  width: ${styleUtils.pxToRem("243px")};
  height: ${styleUtils.pxToRem("192px")};
`;
