// import custom axios, which have request and response interceptor
import axios from "axios";
import axiosRetry from "axios-retry";

import { store } from "../store";
import * as loginActionTypes from "./../../containers/Login/actions/actionTypes";
import { logEvent } from "../../configs/firebase";
import { apiErrorModalRef } from "../../globalRefs";
import { showNotification } from "../../utils/commonUtils";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const isBlacklistUrl = (urlIs = "") => {
  //list all the path or api's, not required to handle 401 error
  const urls = [
    "/user/details",
    "/emailExist",
    "/signup",
    "/signIn",
    "/signin",
    "/jobs/jobDetails",
    "/setLocation",
    "/banners",
    "/details",
    "/clearToken",
    "/sendOtp",
    "/sendPhOtp",
    "/resendVerificationEmail",
    "/otpVerify",
    "/phoneVerify",
    "/resendPhoneVerificationCode",
    "/checkVerificationStatus",
    "/setting/country-check",
    "/setting/location-check",
    "/user/verify-forgot-password-code",
  ];

  const index = urls.findIndex((d) => urlIs.includes(d));
  return index > -1;
};

const onLogout = () => {
  const { loginState } = store.getState();
  const { user } = loginState;
  store.dispatch({
    type: loginActionTypes.LOGOUT,
    payload: "",
  });
  showNotification({
    alertProps: {
      severity: "info",
      children: "Session timed out. Please login again.",
    },
  });
  logEvent(FirebaseEvents.SESSION_TIMEOUT, {
    items: [{ userId: user?.user?.uid, status: "success" }],
  });
};

const Api = axios.create({
  baseURL: `${import.meta.env.VITE_APP_NODE_API_URL}`,
  // baseURL: "http://localhost:6432", //For backend testing
  timeout: 600000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
axiosRetry(Api, {
  retries: 3,
});

Api.interceptors.request.use(
  (request) => {
    const { loginState } = store.getState();
    const { user, isForgotPasswordOtpValid, forgotPasswordVerificationToken } = loginState;
    if (user?.token && user?.refreshToken) {
      request.headers["Authorization"] = `Bearer ${user.token}`;
      request.headers["refreshToken"] = user.refreshToken;
    } else if (isForgotPasswordOtpValid && forgotPasswordVerificationToken) {
      request.headers.otpToken = forgotPasswordVerificationToken;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  (response) => {
    const { loginState } = store.getState();
    if (response.headers["access-token"] && (response?.data?.data?.user || loginState?.user)) {
      const newUserData = loginState?.user ? loginState.user : response.data.data.user;
      const newUserSocialData = loginState?.userSocial
        ? loginState.userSocial
        : response.data.data.social;
      newUserData["token"] = response.headers["access-token"];
      newUserData["refreshToken"] = response.headers["refresh-token"];
      newUserData["type"] = response?.data?.data?.user?.userType;
      store.dispatch({
        type: loginActionTypes.LOGIN_SUCCESS,
        userData: newUserData,
        userSocialData: newUserSocialData,
      });
    } else if (response?.headers["otp-token"]) {
      response.data["verifyOtpToken"] = response?.headers["otp-token"];
    }
    return response?.data;
  },
  (error) => {
    const [responseStatus, apiUrl] = [
      error?.response?.status,
      error?.response?.request?.responseURL,
    ];
    if (responseStatus === 401 && !isBlacklistUrl(apiUrl)) {
      // Logout because of Session Timeout
      onLogout();
    } else if (
      responseStatus === 500 &&
      error?.response?.data?.message === "auth/too-many-requests"
    ) {
      //No code here
    } else if (responseStatus >= 500) {
      apiErrorModalRef.current.showModal();
    }
    return Promise.reject(error);
  },
);

export default Api;
