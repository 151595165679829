import { useState } from "react";
import { Box, Button, Divider, Drawer, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../../styles";
import { sortingOptions, CLEAR_OPTION } from "../../Constants";

/**
 * Sorting Menu for jobs
 * by distance, wage and work hours
 */
const Sort = ({ handleSorting }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const appliedSort = sortingOptions.filter((sortOption) => sortOption.order);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onClickMenuItem = (type) => () => {
    handleDrawerClose();
    let sortOrder = "";
    sortingOptions.forEach((item) => {
      if (item.key === type) {
        sortOrder = !item.order || item.order === "desn" ? "ascn" : "desn";
        item.order = sortOrder;
      } else {
        item.order = "";
      }
    });
    handleSorting(type, sortOrder);
  };

  const handleResetSort = () => {
    handleDrawerClose();
    sortingOptions.forEach((item) => {
      item.order = "";
    });
    handleSorting("clear", "");
  };

  return (
    <>
      <SortByButton
        variant="outlined"
        onClick={handleDrawerOpen}
        endIcon={<Icon size={15} name="select-dropdown" />}
        sx={{
          borderColor: appliedSort?.length > 0 ? "#2B4894" : "#DADADA",
          backgroundColor: appliedSort?.length > 0 ? "#F2F6FF" : colors.white,
        }}
      >
        Sort By
        {appliedSort?.length > 0 && (
          <Icon
            size={16}
            name={
              appliedSort[0]?.order === "ascn"
                ? "sort-arrow-up"
                : "sort-arrow-down"
            }
            sx={{ marginLeft: spacing.xs }}
          />
        )}
      </SortByButton>
      <Drawer
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            borderRadius: 0,
            width: "100%",
            backgroundColor: colors.white,
            padding: `${spacing.l} 0`,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        }}
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Row>
          <Text size="l" weight="bold">
            Sort by
          </Text>
        </Row>
        <Divider sx={{ mb: spacing.xs }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {sortingOptions.map((option) => {
            return (
              <SortingMenuItem
                key={option.key}
                onClick={onClickMenuItem(option.key)}
              >
                <Text>{option.title}</Text>
                {option.order && (
                  <Icon
                    size={15}
                    name={
                      appliedSort[0]?.order === "ascn"
                        ? "sort-arrow-up"
                        : "sort-arrow-down"
                    }
                    sx={{ marginLeft: spacing.xs }}
                  />
                )}
              </SortingMenuItem>
            );
          })}
          <ResetMenuItem key="clear" onClick={handleResetSort}>
            <Text>{CLEAR_OPTION}</Text>
          </ResetMenuItem>
        </Box>
      </Drawer>
    </>
  );
};

export default Sort;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.s};
  margin-left: ${spacing.l};
`;
const SortByButton = styled(Button)(() => ({
  color: colors.textPrimary,
  marginRight: spacing.xs,
  width: "auto",
  " &:hover": {
    backgroundColor: colors.white,
    color: colors.textPrimary,
    borderColor: "#DADADA",
  },
}));
const SortingMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
  },
  padding: `0 ${spacing.l}`,
}));
const ResetMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
    textDecorationColor: colors.primary,
  },
  padding: `0 ${spacing.l}`,
}));
