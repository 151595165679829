import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "../../../hooks/useIsMobile";

import { colors, spacing, styleUtils } from "../../../styles";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./peoplecard.css";

const PeopleCardSkeleton = ({ cardStyle, size = "m", id, active }) => {
  const isMobile = useIsMobile();

  return (
    <Card
      sx={{
        transform: `scale(${size === "m" ? 1 : 0.875})`,
        cursor: "pointer",
        ...cardStyle,
      }}
      id={id}
      className={active ? "job-list-item-active" : ""}
    >
      <div>
        <TopContainer
          sx={{ padding: isMobile ? spacing.s : styleUtils.pxToRem("12px") }}
        >
          <Skeleton circle={true} height={57} width={57} />
          <TitleContainer>
            <Skeleton count={2} />
          </TitleContainer>
          <IconContainer>
            <Skeleton circle={true} height={24} width={24} />
          </IconContainer>
        </TopContainer>
        <TableContainer sx={{ padding: `0 ${spacing.s}` }}>
          <Table>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell
                  sx={{
                    border: 0,
                    borderRight: `1px solid ${colors.stroke}`,
                    padding: `${spacing.xs} 0`,
                    paddingRight: spacing.xs,
                    width: "30%",
                  }}
                >
                  <Skeleton circle={true} height={20} width={20} />
                  <Skeleton count={1} />
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    borderRight: `1px solid ${colors.stroke}`,
                    padding: `${spacing.xs} 0`,
                    paddingLeft: spacing.s,
                    paddingRight: spacing.xs,
                    width: "30%",
                  }}
                >
                  <Skeleton circle={true} height={20} width={20} />
                  <Skeleton count={1} />
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: `${spacing.xs} 0`,
                    paddingLeft: spacing.s,
                    width: "30%",
                  }}
                >
                  <Skeleton circle={true} height={20} width={20} />
                  <Skeleton count={1} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <RequestResumeContainer>
          <Skeleton count={1} />
        </RequestResumeContainer>
      </div>
    </Card>
  );
};

export default PeopleCardSkeleton;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${spacing.s};
`;

const IconContainer = styled("div")`
  display: inline-flex;
  margin-left: ${spacing.s};
`;

const RequestResumeContainer = styled("div")`
  border: 1px solid #ebebeb;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-top: ${spacing.s};
  padding: ${spacing.xs} ${spacing.s} ${spacing.xs} ${spacing.s};
`;
