import React, { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ProtectedRoute from "./ProtectedRoute";
import {
  BASE_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  JOBSEEKER_PROFILE_PAGE_ROUTE,
  CHAT_PAGE_ROUTE,
  JOBSEEKER_FAVOURITE_PAGE_ROUTE,
  SIGN_UP_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  LOGIN_WITH_EMAIL_PAGE_ROUTE,
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  PEOPLE_PAGE_ROUTE,
  POST_NEW_JOB_PAGE_ROUTE,
  FORGOT_PASSWORD_PAGE_ROUTE,
  EMPLOYER_PROFILE_PAGE_ROUTE,
  PRIVACY_POLICY,
  EMPLOYER_REGISTRATION_SCREEN,
  TERMS_CONDITION,
  EMPLOYER_HOME,
  EDIT_JOB_PAGE_ROUTE,
  APP_PRIVACY_POLICY,
  APP_TERMS_CONDITION,
  BLOGS,
  BLOG_POST,
  EMPLOYER_SCHEDULER_PAGE_ROUTE,
  JOBSEEKER_SCHEDULER_PAGE_ROUTE,
} from "./routeConstants";
import { PreviousRouteProvider } from "../hooks/usePreviousRoute";
import { isEmpty } from "lodash";

import NavFooter from "./NavFooter/NavFooter";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import EmployerRoute from "./EmployerRoute";
import JobSeekerRoute from "./JobSeekerRoute";
import NoMatch from "../containers/NoMatch/NoMatch";
import ForgotPasswordContainer from "containers/Account/ForgotPasswordContainer";

//CommonRoutes
import JobSeekerHomeContainer from "../containers/JobSeekerHome/HomeContainer";
import EmployerHomeContainer from "../containers/EmployerHome/HomeContainer";
import LoginContainer from "../containers/Login/LoginContainer";
import LoginEmailContainer from "../containers/LoginEmail/LoginEmailContainer";
import SignUpContainer from "../containers/SignUp/SignUpContainer";
import ChatContainer from "../containers/Chat/ChatContainer";

//JobSeekerRoutes
import JobSeekerExploreContainer from "../containers/JobSeeker/views/Explore/ExploreContainer";
import MyJobsViewContainer from "../containers/JobSeeker/views/Favourites/MyJobsViewContainer";
import JobSeekerProfileContainer from "../containers/JobSeeker/views/Profile/ProfileContainer";
import SeekerSchedulerContainer from "../containers/JobSeeker/views/InterviewScheduler/SeekerSchedulerContainer";

//EmployerRoutes
import EmployerExploreContainer from "../containers/Employer/views/Explore/ExploreContainer";
import PeopleContainer from "../containers/Employer/views/People/PeopleContainer";
import AddJobContainer from "../containers/Employer/views/AddJob/AddJobContainer";
import EmployerProfileContainer from "../containers/Employer/views/Profile/ProfileContainer";
import EmployerRegistrationContainer from "../containers/SignUp/views/EmployerRegistration/RegistrationContainer";
import InterviewSchedulerContainer from "../containers/Employer/views/InterviewScheduler/InterviewSchedulerContainer";

import LocationCheckModal from "../utils/LocationPermissionModal";
import LoginSelector from "../containers/Login/selectors/LoginSelector";
import { userState } from "../configs/network/apis";

import NavBarRoute from "./NavBarRoute/NavBarRoute";
import BlogLanding from "../containers/Blog/views/BlogLanding";
import BlogPost from "../containers/Blog/views/BlogPost";
import AskHr from "../chatbot/AskHr";

const TermsAndConditions = lazy(() => import("../containers/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../containers/PrivacyPolicy"));

/**
 * Navigation component containing pages routing path
 */
const Navigation = ({ isCountryCheckError }) => {
  const userLocation = useSelector(LoginSelector.getLoggedInUserCurrentLocation);
  const { pathname, search } = useLocation();
  const isFromApp = pathname.includes("/app/");
  const isBlogPage = pathname.includes("/blogs");
  const isEmployerRegistrationForm = pathname.includes("/employerRegistration");
  const isActivationFlow = search.includes("isActivationFlow=true");

  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  let lat = 0;
  let lng = 0;
  if (userLocation && userLocation?.lat !== 0) {
    lat = userLocation?.lat;
  }
  if (userLocation && userLocation?.lng !== 0) {
    lng = userLocation?.lng;
  }
  if (isAuthenticated && loggedInUser?.userType !== "employer") {
    userState({
      lat: lat,
      lng: lng,
      platform: "web",
    });
  }

  return (
    <>
      <PreviousRouteProvider>
        <Suspense fallback={null}>
          <Routes>
            <Route path="/askhr" element={<AskHr />} />
            {/* With NavBar and Footer */}
            <Route
              path={BASE_PAGE_ROUTE}
              element={<NavFooter isCountryCheckError={isCountryCheckError} />}
            >
              {/* Public Routes */}
              <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={TERMS_CONDITION} element={<TermsAndConditions />} />
              <Route path={BLOGS} element={<BlogLanding />} />
              <Route path={BLOG_POST} element={<BlogPost />} />

              <Route path="*" element={<NoMatch />} />

              {/* Protected Common Routes */}
              <Route path={BASE_PAGE_ROUTE} element={<ProtectedRoute />}>
                <Route path={CHAT_PAGE_ROUTE} element={<ChatContainer />} />
              </Route>

              {/* JobSeeker Routes */}
              <Route path={BASE_PAGE_ROUTE} element={<JobSeekerRoute />}>
                <Route index element={<JobSeekerHomeContainer />} />
                <Route path={EMPLOYER_HOME} element={<EmployerHomeContainer />} />
                <Route path={JOBSEEKER_PAGE_ROUTE}>
                  <Route path={EXPLORE_JOBS_PAGE_ROUTE} element={<JobSeekerExploreContainer />} />
                  <Route
                    path={JOBSEEKER_SCHEDULER_PAGE_ROUTE}
                    element={<SeekerSchedulerContainer />}
                  />
                </Route>
                <Route path={BASE_PAGE_ROUTE} element={<ProtectedRoute />}>
                  <Route path={JOBSEEKER_PAGE_ROUTE}>
                    <Route
                      path={JOBSEEKER_FAVOURITE_PAGE_ROUTE}
                      element={<MyJobsViewContainer />}
                    />
                    <Route
                      path={JOBSEEKER_PROFILE_PAGE_ROUTE}
                      element={<JobSeekerProfileContainer />}
                    />
                  </Route>
                </Route>
              </Route>

              {/* Employer Routes */}
              <Route path={BASE_PAGE_ROUTE} element={<EmployerRoute />}>
                <Route path={EMPLOYER_PAGE_ROUTE}>
                  <Route
                    path={EMPLOYER_SCHEDULER_PAGE_ROUTE}
                    element={<InterviewSchedulerContainer />}
                  />
                </Route>
              </Route>

              <Route path={BASE_PAGE_ROUTE} element={<ProtectedRoute />}>
                <Route path={EMPLOYER_PAGE_ROUTE} element={<EmployerRoute />}>
                  <Route index element={<EmployerExploreContainer />} />
                  <Route path={EMPLOYER_JOBS_PAGE_ROUTE} element={<EmployerExploreContainer />} />
                  <Route path={PEOPLE_PAGE_ROUTE} element={<PeopleContainer />} />
                  <Route path={POST_NEW_JOB_PAGE_ROUTE} element={<AddJobContainer />} />
                  <Route path={EDIT_JOB_PAGE_ROUTE} element={<AddJobContainer />} />
                  <Route
                    path={EMPLOYER_PROFILE_PAGE_ROUTE}
                    element={<EmployerProfileContainer />}
                  />
                </Route>
              </Route>
            </Route>

            {/* Without NavBar and Footer */}
            {/* UnAuthenticated Routes */}
            <Route path={BASE_PAGE_ROUTE} element={<UnAuthenticatedRoute />}>
              <Route path={LOGIN_PAGE_ROUTE} element={<LoginContainer />} />
            </Route>
            {/* Independent pages for app webviews */}
            <Route path={BASE_PAGE_ROUTE}>
              <Route path={APP_PRIVACY_POLICY} element={<PrivacyPolicy isFromApp />} />
              <Route path={APP_TERMS_CONDITION} element={<TermsAndConditions isFromApp />} />
            </Route>
            <Route path={BASE_PAGE_ROUTE} element={<NavBarRoute />}>
              <Route path={BASE_PAGE_ROUTE} element={<UnAuthenticatedRoute />}>
                <Route path={SIGN_UP_PAGE_ROUTE} element={<SignUpContainer />} />
                <Route path={LOGIN_WITH_EMAIL_PAGE_ROUTE} element={<LoginEmailContainer />} />
                <Route
                  path={EMPLOYER_REGISTRATION_SCREEN}
                  element={<EmployerRegistrationContainer />}
                />
                <Route path={FORGOT_PASSWORD_PAGE_ROUTE} element={<ForgotPasswordContainer />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </PreviousRouteProvider>
      {/* Temporarily disabling this for EMP-1258 
      {(isEmpty(userLocation) || !lat) &&
        loggedInUser?.userType !== "employer" &&
        !isFromApp &&
        !isBlogPage &&
        !isActivationFlow &&
        !isEmployerRegistrationForm && <LocationCheckModal />} */}
    </>
  );
};

Navigation.propTypes = {
  isCountryCheckError: PropTypes.bool.isRequired,
};

export default Navigation;
