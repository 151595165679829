import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Button, Card, CircularProgress, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce";
import JobSeekerSelector from "../../../../../selectors/JobSeekerSelector";

import { Icon, Text } from "../../../../../../../components/atoms";
import {
  JobCard,
  JobCardSkeleton,
} from "../../../../../../../components/molecules";

import { setLoginRedirection } from "../../../../../../../utils/commonUtils";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../../../../../styles";

import LocationMap from "./Map";
import Filter from "./Filter";

import { MapViewConstants } from "../../Constants";
import { isEmpty } from "lodash";

const MapView = ({
  onClickSearch,
  filterSettings,
  handleFilter,
  handleLoadMore,
  jobListPage,
  jobSeekerListing,
  jobSeekerSuggestedResults,
  location,
  loggedInUser,
  searchTerm,
  showMapView,
  showLoadMore,
  toggleExploreView,
  totalJobsCount,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeJobId = searchParams.get("activeJobId");

  const jobSeekerloadinglist = [1, 2, 3, 4, 5, 6];
  const noJobsFound = isEmpty(jobSeekerListing);
  const finalJobsDataToRender = noJobsFound
    ? jobSeekerSuggestedResults
    : jobSeekerListing;

  const activeJobIndex = finalJobsDataToRender?.findIndex(
    (job) => job.jobId === activeJobId
  );

  const isJobListFetching = useSelector(JobSeekerSelector.isJobListFetching);
  const scrollRef = useRef();

  const [activeMarker, setActiveMarker] = useState(
    activeJobIndex < 0 ? null : activeJobIndex
  );
  const [activeCenter, setActiveCenter] = useState({});

  const onMapJobCardSelect = (selectedCardIndex) => {
    const selectedCard = document.getElementById(
      `JOB_CARD_${selectedCardIndex}`
    );
    selectedCard.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    if (activeJobIndex >= 0) {
      const scrollToLocation = document.getElementsByClassName(
        "job-list-item-active"
      )?.[0]?.offsetTop;
      if (finalJobsDataToRender.length > 0) {
        scrollRef?.current?.scrollTo(
          0,
          scrollToLocation - scrollRef?.current.offsetTop
        );
      }
    }
  }, []);

  //#region - Debounce Search
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);

  const onSearch = useCallback(
    debounce((text) => {
      onClickSearch(text)();
    }, 1500),
    []
  );

  useEffect(() => {
    if (showLoadMore) {
      handleLoadMore();
    }

    if (isJobListFetching && jobListPage <= 1) {
      setActiveMarker(null);
      setActiveCenter(null);
    }
  }, [jobSeekerListing?.length]);

  const onJobCardSelect = (selectedIndex) => {
    setActiveMarker(selectedIndex);
  };

  const onJobCardClick = (job) => {
    setLoginRedirection(
      loggedInUser?.uid,
      "",
      `?showJobDetailView=true&jobId=${job.jobId}&brandName=${job.brandName}&navMap=true`
    );
    setSearchParams({
      showJobDetailView: true,
      jobId: job.jobId,
      brandName: job.brandName,
      navMap: true,
    });
  };

  const renderJobs = (item, index) => {
    const isFavourite =
      item.jobSeekers && item.jobSeekers.includes(loggedInUser?.uid);
    return (
      <Grid
        key={index}
        onMouseOver={() => onJobCardSelect(index)}
      >
        <JobCard
          item={item}
          isFavourite={isFavourite}
          onClickBrandLogo={() => {}}
          onClickJob={() => onJobCardClick(item)}
          cardStyle={{
            marginBottom: spacing.s,
            marginRight: spacing.s,
            backgroundColor:
              index === activeMarker ? colors.lightBlue : colors.white,
          }}
          id={`JOB_CARD_${index}`}
          active={index === activeMarker}
        />
      </Grid>
    );
  };

  const renderJobCardSekeleton = (_, index) => {
    return (
      <Grid key={index}>
        <JobCardSkeleton
          cardStyle={{
            marginBottom: spacing.s,
            marginRight: spacing.s,
            backgroundColor: colors.white,
          }}
        />
      </Grid>
    );
  };

  const jobsListContent = finalJobsDataToRender?.map(renderJobs);

  return (
    <Grid
      container
      columnSpacing={spacing.m}
      sx={{ marginTop: spacing.xxxl }}
    >
      <Grid
        item
        md={3.5}
      >
        <Card
          sx={{
            height: styleUtils.pxToRem("51px"),
            marginBottom: spacing.s,
          }}
        >
          <Filter
            filterSettings={filterSettings}
            handleFilter={handleFilter}
          />
        </Card>
        <Card
          sx={{
            height: "79vh",
            py: spacing.s,
            pl: spacing.s,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {noJobsFound && (
            <EmptyResultsContainer>
              <Icon
                size={38}
                name="noJobsMatch"
              />
              <Text
                weight="medium"
                style={{ textAlign: "center" }}
              >
                No jobs that match this search.
                <br />
                Here are similar jobs
              </Text>
            </EmptyResultsContainer>
          )}
          <Divider
            flexItem
            sx={{ mx: spacing.xs, my: spacing.s }}
          />
          <JobsContainer
            id="scrollableDiv"
            ref={scrollRef}
          >
            {jobListPage === 1 && isJobListFetching
              ? jobSeekerloadinglist?.map(renderJobCardSekeleton)
              : jobsListContent}
          </JobsContainer>
        </Card>
      </Grid>
      <Grid
        item
        md={8.5}
        sx={{ display: "flex", flexDirection: "column", height: "86vh" }}
      >
        <SearchResultsContainer>
          <TitleContainer>
            {!noJobsFound ? (
              <Text>
                <Text component="span">
                  {MapViewConstants.SEARCH_RESULTS_SHOW}
                </Text>
                <Text
                  weight="bold"
                  component="span"
                >
                  {jobSeekerListing?.length}
                </Text>
                <Text component="span">
                  {MapViewConstants.SEARCH_RESULTS_OF}
                </Text>
                <Text
                  weight="bold"
                  component="span"
                >
                  {totalJobsCount}
                </Text>
                {MapViewConstants.SEARCH_RESULTS_JOBS}
              </Text>
            ) : (
              <Text>{MapViewConstants.SIMILAR_JOBS_TITLE}</Text>
            )}
            {isJobListFetching && jobListPage > 1 && (
              <CircularProgress
                size="1rem"
                color="inherit"
                sx={{ marginLeft: styleUtils.pxToRem("10px") }}
              />
            )}
          </TitleContainer>
          <ListToggleButton
            onClick={toggleExploreView}
            sx={{
              "&:hover": {
                backgroundColor: colors.dodgerBlue,
              },
            }}
          >
            <Icon
              name="list-toggle"
              size={14}
            />
            List View
          </ListToggleButton>
        </SearchResultsContainer>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {showMapView && (
            <LocationMap
              activeCenter={activeCenter}
              activeMarker={activeMarker}
              jobSeekerListing={finalJobsDataToRender}
              location={location}
              onMapJobCardSelect={onMapJobCardSelect}
              setActiveCenter={setActiveCenter}
              setActiveMarker={setActiveMarker}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default MapView;

const JobsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;
const SearchResultsContainer = styled("div")`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.l};
  padding-top: ${styleUtils.pxToRem("10px")};
`;

const ListToggleButton = styled(Button)`
  align-items: center;
  background-color: ${colors.dodgerBlue};
  border-radius: ${styleUtils.pxToRem("18px")};
  color: ${colors.white};
  font-size: ${textSizes.s};
  font-weight: ${typography.bold};
  height: ${styleUtils.pxToRem("36px")};
  justify-content: center;
  padding-top: ${spacing.xs};
  width: ${styleUtils.pxToRem("130px")};
  gap: 7px;
`;

const EmptyResultsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${styleUtils.pxToRem("30px")};
`;

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
`;
