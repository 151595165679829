import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Divider, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  Accordion,
  Icon,
  IconButton,
  MultiCheckbox,
  Text,
} from "../../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../../styles";

import { experienceFilter, hourlyWageFilter, jobTypeFilter } from "../../FilterConstants";
import useEmployerListingFilter from "../../../../../../../hooks/filters/useEmployerListingFilter";
import { FilterCount } from "components/molecules";
import { activeFiltersCount, getFilterCount } from "utils/commonUtils";

const Filter = ({ filterSettings, handleFilter }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    wage,
    status,
    experience,
    onChangeHourlyWages,
    onChangeStatus,
    onChangeExperience,
    onApply,
    onReset,
  } = useEmployerListingFilter(filterSettings, handleFilter, handleDrawerClose);
  const totalFilterCount = activeFiltersCount(filterSettings);

  return (
    <>
      <IconButton name="filter-by" style={{ marginLeft: spacing.s }} onClick={handleDrawerOpen} />
      <Drawer
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            borderRadius: 0,
            width: "80%",
            backgroundColor: colors.white,
            padding: `${spacing.l} ${spacing.m}`,
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Row>
          <FilterCountContainer>
            <Text size="l" weight="bold">
              Filter By
            </Text>
            <FilterCount count={totalFilterCount} />
          </FilterCountContainer>
          <Icon name="close" size={14} color={colors.black} onClick={handleDrawerClose} />
        </Row>
        <Divider sx={{ mb: spacing.s }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
            pr: spacing.xs,
          }}
        >
          <Accordion
            title="Hourly Wages"
            headerRightContent={<FilterCount count={getFilterCount(wage)} />}
            content={
              <MultiCheckbox
                selectOptions={hourlyWageFilter}
                prevSelectedOptions={wage}
                handleSelect={onChangeHourlyWages}
              />
            }
          />
          <Divider />
          <Accordion
            title="Job Type"
            headerRightContent={<FilterCount count={getFilterCount(status)} />}
            content={
              <MultiCheckbox
                selectOptions={jobTypeFilter}
                prevSelectedOptions={status}
                handleSelect={onChangeStatus}
              />
            }
          />
          <Divider />
          <Accordion
            title="Experience"
            headerRightContent={<FilterCount count={getFilterCount(experience)} />}
            content={
              <MultiCheckbox
                selectOptions={experienceFilter}
                prevSelectedOptions={experience}
                handleSelect={onChangeExperience}
              />
            }
          />
        </Box>
        <Divider />
        <ButtonRow>
          <Button
            variant="outlined"
            sx={{
              marginRight: spacing.xs,
              height: "28px",
            }}
            onClick={onReset}
          >
            Reset Filter
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "28px",
            }}
            onClick={onApply}
          >
            Apply
          </Button>
        </ButtonRow>
      </Drawer>
    </>
  );
};

Filter.propTypes = {
  filterSettings: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default Filter;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.xs};
`;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const FilterCountContainer = styled("div")`
  display: flex;
  align-items: center;
`;
