import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

import NavBarContainer from "../../containers/NavBar/NavBarContainer";

const NavBarRoute = () => {
  return (
    <>
      <NavBarContainer />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default NavBarRoute;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
