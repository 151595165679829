import { useDispatch } from "react-redux";
import { Button, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { CardModal, Text } from "../../../../components/atoms";
import { logoutRequest } from "../../../Login/actions/LoginActions";
import { colors, spacing } from "../../../../styles";
import { showNotification } from "../../../../utils/commonUtils";

const LogoutView = ({ islogoutModalOpen, hideLogoutModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logoutRequest());
    navigate("/");
    showNotification({
      alertProps: {
        severity: "info",
        children: "You are logged out.",
      },
    });
  };

  const onCancel = () => {
    hideLogoutModal();
  };

  return (
    <CardModal open={islogoutModalOpen} onClose={hideLogoutModal}>
      <Card
        sx={{
          width: 488,
          height: 226,
          py: spacing.l,
          px: spacing.l,
          borderRadius: 5,
        }}
      >
        <Text style={{ textAlign: "center" }} size="xl">
          Are you sure <br /> you want to logout?
        </Text>
        <Row sx={{ marginTop: "15%" }}>
          <Button onClick={onLogout}>
            <Text size="m" weight="bold" color={colors.primary}>
              Yes
            </Text>
          </Button>
          <Button variant="contained" onClick={onCancel} sx={{ ml: spacing.s }}>
            <Text size="m" color={colors.white} weight="bold">
              No
            </Text>
          </Button>
        </Row>
      </Card>
    </CardModal>
  );
};

export default LogoutView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.s};
`;
