import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Joyride from "react-joyride";

import { Box, Divider, Grid, styled } from "@mui/material";

import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { RichTextViewer, Text } from "../../atoms";

import EmployerSelector from "containers/Employer/selectors/EmployerSelector";
import LoginSelector from "../../../containers/Login/selectors/LoginSelector";

import InactiveJobModal from "containers/Employer/views/AddJob/views/InactiveJobModal";
import EmpJobDetailCard from "./webView/EmpJobDetailCard";
import EmpJobDetailCardMobile from "./mobileView/EmpJobDetailCardMobile";
import { employerListingRequest } from "containers/Employer/actions/EmployerActions";

import useIsMobile from "../../../hooks/useIsMobile";
import {
  decodeToHtml,
  hideLoader,
  showLoader,
  showNotification,
} from "../../../utils/commonUtils";

import { employerJobUpdate, jobDetails } from "../../../configs/network/apis";

import { colors, spacing, textSizes } from "../../../styles";

import {
  EMPLOYER_PAGE_ROUTE,
  POST_NEW_JOB_PAGE_ROUTE,
} from "../../../routes/routeConstants";

const EmployerJobDetailCard = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const brandName = searchParams.get("brandName");
  const isPostedJob = searchParams.get("pJob");

  const [item, setItem] = useState(null);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const statusOfCurrJob = useSelector(
    EmployerSelector.getEmployerJobStatusById(jobId)
  );
  const STATUS_ACTIVE = "active";
  const isJobActive = statusOfCurrJob === STATUS_ACTIVE;

  const coachMarkState = {
    steps: [
      {
        content: (
          <Text size="s" weight="medium" color={colors.darkBlack}>
            You can duplicate the job to post it in multiple locations
          </Text>
        ),
        disableBeacon: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: "bottom",
        spotlightClicks: true,
        styles: {
          options: {
            zIndex: 1,
            width: 250,
          },
          tooltipContent: {
            padding: 0,
            backgroundColor: colors.coachMarkBackgroundColor,
          },
          beaconOuter: {
            animation: "joyride-beacon-outer 1.2s infinite ease-in",
          },
          spotlight: {
            borderRadius: "50%",
          },
        },
        target: ".duplicate",
        title: "",
      },
    ],
  };

  const Wrapper = isMobile ? EmpJobDetailCardMobile : EmpJobDetailCard;

  useEffect(() => {
    init();
  }, [jobId, brandName]);

  const init = async () => {
    try {
      showLoader();
      const res = await jobDetails(jobId);
      setItem(res?.data || "Not found");
      hideLoader();
    } catch (e) {
      setItem("Not found");
      hideLoader();
    }
  };

  const handleStateOfJob = (jobstate) => {
    //1.If job is active, we will show the modal and then change the status with API call
    if (jobstate === "active") {
      setShowDeactivateModal(true);
    } else {
      //2.If job is inactive, we will just call an API and update the status of job
      onClickJobActiveState("active", "");
    }
  };

  const handleJobActiveStatusModal = (value) => {
    setShowDeactivateModal(value);
  };

  const onClickEdit = async (e) => {
    navigate(`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`, {
      state: {
        data: item,
        isEditPress: true,
      },
    });
  };

  const onClickCopy = async (e) => {
    navigate(`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`, {
      state: {
        data: item,
        isCopy: true,
      },
    });
  };

  const onClickJobActiveState = async (
    status,
    reasonText,
    isOtherOptionSelected,
    otherReason
  ) => {
    if (isOtherOptionSelected && !otherReason) {
      //No code here
    } else {
      try {
        showLoader();
        if (isJobActive) {
          logEvent(FirebaseEvents.EMP_CLICK_JOB_INACTIVE);
        }
        const {
          success,
          data: { message },
        } = await employerJobUpdate(
          {
            status,
            reason: reasonText,
          },
          jobId
        );
        handleJobActiveStatusModal(false);
        dispatch(
          employerListingRequest({
            employerUid: loggedInUser?.uid,
          })
        );
        hideLoader();
        showNotification({
          alertProps: {
            severity: success ? "success" : "error",
            children: message,
          },
        });
      } catch (e) {
        hideLoader();
        showNotification({
          alertProps: {
            severity: "error",
            children: "An unexpected error occurred. Please try again.",
          },
        });
      }
    }
  };

  if (!item) {
    return (
      <Grid
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }
  if (item === "Not found") {
    return (
      <Grid
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text>No job found</Text>
      </Grid>
    );
  }

  const getJobDetailsContainerItems = () => {
    return (
      <JobDetailsContainer sx={{ paddingLeft: isMobile ? 0 : "15px" }}>
        <Text
          weight="semibold"
          color={colors.blackText}
          style={{ marginTop: spacing.s }}
        >
          Job Description
        </Text>
        <RichTextViewer
          style={{
            fontSize: textSizes.s,
            lineHeight: "1.5",
            marginTop: spacing.xs,
            marginBottom:spacing.s,
            color: colors.descriptionGray,
          }}
        >
          {decodeToHtml(item?.listingDescription)}
        </RichTextViewer>
        <Divider />
        {Boolean(item?.min_age) && (
          <>
            <Text
              weight="semibold"
              color={colors.blackText}
              style={{ marginTop: spacing.l }}
            >
              Minimum Age for the job
            </Text>
            <Text
              size="s"
              color={colors.descriptionGray}
              style={{ marginTop: spacing.xs,marginBottom:spacing.s }}
            >
              {item?.min_age} years
            </Text>
          </>
        )}
        <Divider />
        <Text
          weight="semibold"
          color={colors.blackText}
          style={{ marginTop: spacing.xl }}
        >
          Short Note
        </Text>
        <Text
          size="s"
          color={colors.descriptionGray}
          style={{ marginTop: spacing.xs }}
        >
          {item?.note}
        </Text>
      </JobDetailsContainer>
    );
  };

  return (
    <BoxContainer>
      {isPostedJob && (
        <Joyride
          steps={coachMarkState.steps}
          disableScrolling={true}
          styles={{
            options: {
              arrowColor: colors.coachMarkBackgroundColor,
              backgroundColor: colors.coachMarkBackgroundColor,
              overlayColor: "#00000077",
              primaryColor: "#000",
              padding: 0,
            },
          }}
        />
      )}
      <Wrapper
        getJobDetailsContainerItems={getJobDetailsContainerItems}
        onClickCopy={onClickCopy}
        onClickEdit={onClickEdit}
        item={item}
        handleStateOfJob={handleStateOfJob}
        statusOfCurrJob={statusOfCurrJob}
      />
      {showDeactivateModal && (
        <InactiveJobModal
          showInactiveOptions={showDeactivateModal}
          handleInactiveView={handleJobActiveStatusModal}
          onClickStatus={onClickJobActiveState}
        />
      )}
    </BoxContainer>
  );
};

export default EmployerJobDetailCard;

const JobDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const BoxContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${spacing.s};
`;
