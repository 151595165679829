import { useEffect } from "react";
import DetailsForm from "./DetailsForm";
import SchedulerEmailView from "./SchedulerEmailView";

const SchedulerUserView = ({
  detailsFormValidationFormik,
  emailValidationFormik,
  showUserDetailsForm,
}) => {
  const { email } = emailValidationFormik.values;

  useEffect(() => {
    if (email) {
      detailsFormValidationFormik.setFieldValue("email", email);
    }
  }, [email]);

  let content = showUserDetailsForm ? (
    <DetailsForm detailsFormValidationFormik={detailsFormValidationFormik} />
  ) : (
    <SchedulerEmailView emailValidationFormik={emailValidationFormik} />
  );

  return <>{content}</>;
};

export default SchedulerUserView;
