import { useCallback, useEffect, useRef } from "react";
import { noop } from "lodash";

/**
 * Custom hook for restoring scroll position based on a unique key.
 * The hook returns a callback function
 * that should be set on the JSX element's ref attribute to manage scroll restoration.
 *
 * @param {string} [key=window.location.href] - A unique key to identify the scroll position, defaults to current URL.
 * @param {number} [timeout=500] - A timeout after which the scroll will not be restored, defaults to 1/2 a second.
 * @returns {Function} A callback function to set as the `ref` on a scrollable JSX element.
 *
 */
export const useScrollRestoration = (
  key = window.location.href,
  timeout = 5000
) => {
  const cleanUp = useRef(noop);
  const connectRef = useCallback(connect, [key, timeout]);
  const handler = useRef(noop);
  const tracked = useRef();
  const updateTimer = useRef(0);

  /**
   * Disconnecting the handlers
   */
  const disconnect = () => {
    handler.current();
    cleanUp.current();
  };

  useEffect(() => {
    if (tracked.current) {
      connectRef(tracked.current);
    }
    return disconnect;
  }, [connectRef]);

  /**
   * To add the scroll event listeners, storing the scroll position 
   * & restoring the scroll position
   */
  function connect(ref) {
    disconnect();
    tracked.current = ref;
    if (ref) {
      ref.addEventListener("scroll", store);
      handler.current = () => {
        ref.removeEventListener("scroll", store);
      };

      const scrollInfo = window.history.state?.[key];
      if (scrollInfo) {
        ref.scrollTop = scrollInfo.top;
        ref.scrollLeft = scrollInfo.left;
        const resizeObserver = new ResizeObserver(() => {
          if (
            ref.scrollHeight > scrollInfo.top ||
            ref.scrollWidth > scrollInfo.left
          ) {
            ref.scrollTop = scrollInfo.top;
            ref.scrollLeft = scrollInfo.left;
            cleanUp.current();
          }
        });

        resizeObserver.observe(ref);
        cleanUp.current = () => {
          resizeObserver.unobserve(ref);
          cleanUp.current = noop;
        };
        setTimeout(() => cleanUp.current(), timeout);
      }
    }

    /**
     * To store the scroll positions
     */
    function store() {
      clearTimeout(updateTimer.current);
      updateTimer.current = setTimeout(() => {
        window.history.replaceState(
          {
            ...window.history.state,
            [key]: {
              top: ref.scrollTop,
              left: ref.scrollLeft,
            },
          },
          ""
        );
      }, 50);
    }
  }

  return connectRef;
};
