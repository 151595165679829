import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Icon, Text } from "../../../components/atoms";
import { colors, styleUtils } from "../../../styles";
import { NavTextIconMapping } from "../NavBarConstants";
import { NavContext } from "./NavContext";

const NavIconLink = ({ to, text }) => {
  const { handleDrawerClose } = useContext(NavContext);
  return (
    <NavLink
      onClick={handleDrawerClose}
      to={to}
      style={{
        textDecoration: "none",
      }}
    >
      {({ isActive }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            name={NavTextIconMapping[text]}
            size={20}
            style={{ marginRight: styleUtils.pxToRem("9px") }}
          />
          <Text
            color={isActive ? colors.primary : colors.textPrimary}
            size="l"
            weight="bold"
            sx={{ transform: "none" }} // to negate global translateY(-1)
          >
            {text}
          </Text>
        </div>
      )}
    </NavLink>
  );
};

export default NavIconLink;
