import { useState } from "react";
import { Divider, Drawer, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { styled } from "@mui/material/styles";

import { IconButton, Text } from "../../../../../../../components/atoms";
import { colors, spacing } from "../../../../../../../styles";
import { peopleSortingOptions, CLEAR_OPTION } from "../../Constants";

const Sort = ({ handleSorting }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const onClickMenuItem = (type) => () => {
    handleDrawerClose();
    let sortOrder = "";
    peopleSortingOptions.forEach((item) => {
      if (item.key === type) {
        sortOrder = !item.order || item.order === "desn" ? "ascn" : "desn";
        item.order = sortOrder;
      } else {
        item.order = "";
      }
    });
    handleSorting(type, sortOrder);
  };

  const handleResetSort = () => {
    handleDrawerClose();
    peopleSortingOptions.forEach((item) => {
      item.order = "";
    });
    handleSorting("clear", "");
  };

  return (
    <>
      <IconButton
        name="sort-az"
        style={{ marginLeft: spacing.s }}
        onClick={handleDrawerOpen}
      />
      <Drawer
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            borderRadius: 0,
            width: "100%",
            backgroundColor: colors.white,
            padding: `${spacing.l} 0`,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        }}
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Row>
          <Text size="l" weight="bold">
            Sort by
          </Text>
        </Row>
        <Divider sx={{ mb: spacing.xs }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {peopleSortingOptions.map((option) => {
            return (
              <SortingMenuItem
                key={option.key}
                onClick={onClickMenuItem(option.key)}
              >
                <Text>{option.title}</Text>
                {option.order &&
                  (option.order === "ascn" ? (
                    <ArrowUpwardIcon
                      sx={{ color: colors.primary, fontSize: "small" }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{ color: colors.primary, fontSize: "small" }}
                    />
                  ))}
              </SortingMenuItem>
            );
          })}
          <ResetMenuItem key="clear" onClick={handleResetSort}>
            <Text>{CLEAR_OPTION}</Text>
          </ResetMenuItem>
        </Box>
      </Drawer>
    </>
  );
};

export default Sort;

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.s};
  margin-left: ${spacing.l};
`;

const SortingMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
  },
  padding: `0 ${spacing.l}`,
}));

const ResetMenuItem = styled(MenuItem)(() => ({
  "&:hover": {
    backgroundColor: colors.shadow,
    textDecorationColor: colors.primary,
  },
  padding: `0 ${spacing.l}`,
}));
