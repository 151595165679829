import * as loginOtpActions from "./actionTypes";

export const verifyLoginOtpUserRequest = (payload) => {
  return {
    type: loginOtpActions.VERIFY_LOGIN_OTP_USER_REQUEST,
    payload,
  };
};

export const sendLoginOtpRequest = (payload) => {
  return {
    type: loginOtpActions.SEND_LOGIN_OTP_REQUEST,
    payload,
  };
};

export const verifyLoginOtpValueRequest = (payload) => {
  return {
    type: loginOtpActions.VERIFY_LOGIN_OTP_REQUEST,
    payload,
  };
};

export const resetLoginUserValidation = () => {
  return {
    type: loginOtpActions.RESET_LOGIN_OTP_VALIDATION,
  };
};

export const resetLoginOtpContainer = () => {
  return {
    type: loginOtpActions.RESET_LOGIN_OTP_VIEW,
  };
};
