import { createContext, useContext, useState } from "react";
import useOnLocationChange from "./useLocationOnChange";
import usePrevious from "./usePrevious";
import {
  BLOGS_TITLE_TAG,
  EMPLOYER_TITLE_TAGS,
  JOBSEEKER_TITLE_TAGS,
  PRIVACY_POLICY_TITLE_TAG,
  TERMS_AND_CONDITION_TITLE_TAG,
  DEFAULT_TITLE_TAG,
  JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS,
  EMPLOYD,
} from "appConstants/titleTagsConstant";
import {
  BLOGS,
  EMPLOYER_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  PRIVACY_POLICY,
  TERMS_CONDITION,
  EMPLOYER_HOME,
} from "routes/routeConstants";
import { useSearchParams } from "react-router-dom";

const PreviousRouteContext = createContext();

export const PreviousRouteProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState(null);
  const [searchParams] = useSearchParams();
  const showCompanyDetailView = searchParams.get("showCompanyDetailView");
  const brandName = searchParams.get("brandName");
  const previousRoute = usePrevious(currentRoute);

  const TITLE_TAG_FOR_COMPANY_DETAIL_VIEW = `${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.EXPLORE_TITLE_TEXT}${brandName} ${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.PART_TIME_TITLE}${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.JOBS_TITLE_TEXT} | ${EMPLOYD}`;

  useOnLocationChange((location) => {
    const { pathname } = location;
    setCurrentRoute(pathname);
    documentTitleSetter(location);
  });

  const documentTitleSetter = (location) => {
    const { pathname } = location;
    if (pathname === BLOGS) {
      document.title = BLOGS_TITLE_TAG;
      return;
    }
    if (pathname.startsWith("/blogs/post/")) {
      document.title = location.state?.title ?? DEFAULT_TITLE_TAG;
      return;
    }
    if (pathname === PRIVACY_POLICY) {
      document.title = PRIVACY_POLICY_TITLE_TAG;
      return;
    }
    if (pathname === TERMS_CONDITION) {
      document.title = TERMS_AND_CONDITION_TITLE_TAG;
      return;
    }
    if (pathname === `/login${JOBSEEKER_PAGE_ROUTE}`) {
      document.title = JOBSEEKER_TITLE_TAGS.JOBSEEKER_LOGIN;
      return;
    }
    if (pathname === `/login${EMPLOYER_PAGE_ROUTE}`) {
      document.title = EMPLOYER_TITLE_TAGS.EMPLOYER_LOGIN;
      return;
    }
    if (showCompanyDetailView) {
      document.title = TITLE_TAG_FOR_COMPANY_DETAIL_VIEW;
      return;
    }
    if (pathname === EMPLOYER_HOME) {
      document.title = EMPLOYER_TITLE_TAGS.EMPLOYER_HOME;
      return;
    }
    if (pathname !== `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`) {
      document.title = DEFAULT_TITLE_TAG;
    }
  };

  return (
    <PreviousRouteContext.Provider value={previousRoute}>{children}</PreviousRouteContext.Provider>
  );
};

const usePreviousRoute = () => useContext(PreviousRouteContext);

export default usePreviousRoute;
