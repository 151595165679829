import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Card } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { IconButton, Text } from "../../../../../components/atoms";
import JobDetailCardMobile from "../../../../../components/molecules/JobDetailCard/JobDetailCardMobile";
import { spacing } from "../../../../../styles";
import JobSeekerSelector from "../../../selectors/JobSeekerSelector";
import { JOBSEEKER_FAVOURITE_PAGE_ROUTE, JOBSEEKER_PAGE_ROUTE } from "routes/routeConstants";

const MyJobsDetailsResView = ({ jobsList, isFavourite, showJobDetailView }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedJob, setSelectedJob] = useState(null);
  const [isJobAbsent, setIsJobAbsent] = useState(false);

  const jobId = searchParams.get("jobId");

  const forceUpdate = useSelector(JobSeekerSelector.getForceUpdateJobSeekerListing);

  useEffect(() => {
    if (showJobDetailView && jobId && jobsList?.length > 0) {
      setSelectedJob(jobsList.find((job) => job.jobId === jobId));
    }
  }, [showJobDetailView, jobId, forceUpdate, jobsList]);

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        paddingBottom: "60px",
        my: spacing.m,
      }}
    >
      <IconButton
        name="arrow-down"
        style={{
          marginBottom: spacing.s,
          transform: "rotate(90deg)",
          cursor: "pointer",
        }}
        onClick={onClickBack}
      />

      <Card sx={{ height: "76vh" }}>
        {showJobDetailView && selectedJob ? (
          <JobDetailCardMobile
            item={selectedJob}
            isFavourite={isFavourite}
            setIsJobAbsent={setIsJobAbsent}
            isJobAbsent={isJobAbsent}
            fallBackPath={`${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_FAVOURITE_PAGE_ROUTE}`}
          />
        ) : (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "57%",
            }}
          >
            <Text size="xl" weight="semibold">
              No Job Selected
            </Text>
          </div>
        )}
      </Card>
    </Container>
  );
};

MyJobsDetailsResView.propTypes = {
  jobsList: PropTypes.array.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  showJobDetailView: PropTypes.bool.isRequired,
};

export default MyJobsDetailsResView;
