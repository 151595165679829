import { all } from "redux-saga/effects";
import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import middleware, { sagaMiddleware } from "./middleware";

/** Sagas **/
import { loginSagas } from "../../containers/Login/sagas/LoginSagas";
import { jobSeekerSagas } from "../../containers/JobSeeker/sagas/JobSeekerSagas";
import { employerSagas } from "../../containers/Employer/sagas/EmployerSagas";
import { signUpSagas } from "../../containers/SignUp/sagas/SignUpSagas";
import { otpSagas } from "../../containers/Otp/sagas/OtpSagas";
import { loginOtpSagas } from "../../containers/Otp/sagas/LoginOtpSagas";

import { rootReducer } from "./reducers";
import { userSaga } from "containers/Common/userSaga";

const rootSaga = function* () {
  yield all([
    loginSagas(),
    jobSeekerSagas(),
    employerSagas(),
    signUpSagas(),
    otpSagas(),
    loginOtpSagas(),
    userSaga(),
  ]);
};

const persistConfig = {
  key: "employd-web", // key is required
  storage, // storage required
  whitelist: ["loginState"],
};

const reducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};
const { store, persistor } = configStore();

export { store, persistor };
