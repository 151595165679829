import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import useIsMobile from "hooks/useIsMobile";

import AccountDetailsView from "./AccountDetailsView";
import ActivationLinkExpiredView from "./ActivationLinkExpiredView";
import AccountActivatedView from "./AccountActivatedView";

import { Text } from "../../../../../components/atoms";
import ApiErrorModal from "components/organisms/ApiErrorModal";

import { colors, styleUtils } from "../../../../../styles";

import { ACCOUNT_ACTIVATION_CONSTS } from "containers/SignUp/constants";
import { apiErrorModalRef } from "globalRefs";
import { getActivationDetailsApi } from "configs/network/apis";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const AccountActivationView = ({ setShowTermsFooter }) => {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const [activateEmail, setActivateEmail] = useState("");
  const token = searchParams.get("token") || "";
  const [isActivationLinkExpired, setIsActivationLinkExpired] = useState(false);
  const [isAlreadyActivated, setIsAlreadyActivated] = useState(false);

  useEffect(() => {
    const fetchActivationDetails = async () => {
      try {
        const response = await getActivationDetailsApi({ token: token });
        setActivateEmail(response.data.email);
        logEvent(FirebaseEvents.EMP_CLICKS_ACTIVATION_LINK_EMAIL);
      } catch (error) {
        handleError(error);
      }
    };
    fetchActivationDetails();
  }, []);

  const handleError = (error) => {
    const errorStatusCode = error?.response?.status;
    setShowTermsFooter(false);
    if (errorStatusCode === 410) {
      //activation token expired
      setIsActivationLinkExpired(true);
    } else if (errorStatusCode === 409) {
      //account already activated
      setIsAlreadyActivated(true);
    } else {
      //unknown/invalid token provided
      apiErrorModalRef.current.showModal();
    }
  };

  let content;
  if (isActivationLinkExpired) {
    content = <ActivationLinkExpiredView />;
  } else if (isAlreadyActivated) {
    content = <AccountActivatedView />;
  } else {
    content = (
      <AccountDetailsView token={token} activateEmail={activateEmail} isMobile={isMobile} />
    );
  }

  return (
    <>
      <ContentHeader size="xl" weight="semibold" color={colors.titleBlack} isMobile={isMobile}>
        {ACCOUNT_ACTIVATION_CONSTS.LABEL_ACCOUNT_ACTIVATION}
      </ContentHeader>
      <ContentContainer
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        {content}
        <ApiErrorModal ref={apiErrorModalRef} />
      </ContentContainer>
    </>
  );
};

AccountActivationView.propTypes = {
  setShowTermsFooter: PropTypes.func.isRequired,
};

export default AccountActivationView;

const ContentContainer = styled("div")`
  margin-top: ${styleUtils.pxToRem("12px")};
  display: flex;
  flex-direction: column;
`;
const ContentHeader = styled(Text)`
  align-self: flex-start;
  margin-top: ${styleUtils.pxToRem("48px")};
  margin-bottom: ${styleUtils.pxToRem("30px")};
  ${(props) => !props.isMobile && `margin-left: ${styleUtils.pxToRem("15px")};`}
`;
