import { Badge } from "@mui/material";
import { Icon } from "../../components/atoms";
import { spacing } from "../../styles";

export const FooterConstants = {
  ABOUT: "About",
  ABOUT_US: "About us",
  BLOGS: "Blogs",
  CONTACT_US: "Contact Us",
  DOWNLOAD_SUBTEXT: "Download the EMPLOYD app today from",
  EMPLOYD_TAG: "EMPLOYD",
  EXPLORE_JOBS: "Explore Jobs",
  HIRING_SUBTEXT: "Hiring and local part-time job search made simple",
  PRIVACY_POLICY: "Privacy Policy",
  COPYRIGHT_RIGHTS_RESERVED: `Copyright © EmployD ${new Date().getFullYear()}, All Rights Reserved`,
  SEARCH_JOBS: "Search Jobs",
  TERMS_AND_CONDITION: "Terms and Conditions",
};

export const JobSeekerFooterData = {
  HOME: {
    label: "Home",
    icon: <Icon name="home" />,
  },
  CHAT: {
    label: "Chat",
    icon: (badgeCount) => (
      <Badge
        badgeContent={badgeCount}
        color="primary"
      >
        <Icon name="comment-o" />
      </Badge>
    ),
  },
  FAVORITE: {
    label: "Applications",
    icon: <Icon name="application" />,
  },
  PROFILE: {
    label: "Profile",
    icon: <Icon name="user" />,
  },
};

export const EmployerFooterData = {
  JOBS: {
    label: "Jobs",
    icon: (
      <Icon
        name="briefcase"
        style={{ marginBottom: spacing.xxs }}
      />
    ),
  },
  PEOPLE: {
    label: "People",
    icon: (
      <Icon
        name="home"
        style={{ marginBottom: spacing.xxs }}
      />
    ),
  },
  PROFILE: {
    label: "Profile",
    icon: (
      <Icon
        name="user"
        style={{ marginBottom: spacing.xxs }}
      />
    ),
  },
  CHAT: {
    label: "Chat",
    icon: (badgeCount) => (
      <Badge
        badgeContent={badgeCount}
        color="primary"
      >
        <Icon
          name="comment-o"
          style={{ marginBottom: spacing.xxs }}
        />
      </Badge>
    ),
  },
};

export const JOB_SEEKER_FOOTER_DESCRIPTION_TEXT = [
  {
    DESCRIPTION_TITLE: "Local Part time Job Search Made Easy With EmployD",
    DESCRIPTION:
      "Are you in search of local part time jobs? You have come to the right place! EmployD has the latest part time jobs from scores of employers, all at your location. All you have to do is search for your desired job location. Find a job you like and click apply. It’s as simple as that.",
  },
  {
    DESCRIPTION_TITLE: "Chat with Employers",
    DESCRIPTION:
      "Communication with your potential employers is important. With EmployD, you will be able to chat with the hiring manager directly, no delays, no waiting period. We understand communication with your employer is key to stand out and you can utilize EmployD to stand out quickly.",
  },
  {
    DESCRIPTION_TITLE: "Simple Application Process",
    DESCRIPTION:
      "Traditional job boards have long, winding application forms, which would take you a long time to fill up. We have distilled our signup forms to the bare minimum. You can now finish your sign up in a minute. Unlike other job boards, all our questions are geared to help you get the part time job that you desire. Say goodbye to tedious job search and make your job search easy with EmployD!",
  },
  {
    DESCRIPTION_TITLE: "Dedicated to Part Time Job",
    DESCRIPTION:
      "Our sole focus is on part time jobs. Every feature is designed to help you find your part time job as quickly as possible",
  },
];

export const EMPLOYER_FOOTER_DESCRIPTION_TEXT = [
  {
    DESCRIPTION_TITLE: "EmployD for Employers",
    DESCRIPTION:
      "Find your part time staff with ease. Just post your job, for free. Yes, using EmployD for Employers you can post a job for free. There are no hidden charges or fees. Post your job and wait for applicants or you can proactively search for talent. You will be able to find matching job seekers from your Employer console. Chat with them and hire!",
  },
  {
    DESCRIPTION_TITLE: "Perfect Job Description using GenAI",
    DESCRIPTION:
      "We know as employers sometimes it’s hard to craft the perfect job description.That is why EmployD offers GenAI integrations to craft the ideal job descriptions for you. It’s straightforward, seamless and best of all free! \n\nWhether you are a jobseeker or employer, Employed simplifies the part time job search and hiring process.",
  },
  {
    DESCRIPTION_TITLE:
      "Find your perfect part time job or hire the right talent today with EmployD",
    DESCRIPTION: "",
  },
];

const BUCKS_COUNTY_LOCATION_URL =
  "lat=40.4107964&lng=-75.2479061&locationName=Bucks+County%2C+PA%2C+USA";
const BASE_URL_FOOTER_LINKS = `${window.location.origin}/jobSeeker/explorejobs`;

const FOOTER_LINKS_COLUMN_ONE = [
  {
    text: "Part Time Jobs in Yardley PA",
    link: `${BASE_URL_FOOTER_LINKS}?lat=40.245664&lng=-74.8459972&locationName=Yardley%2C+PA%2C+USA`,
  },
  {
    text: "Part Time Jobs in Bensalem PA",
    link: `${BASE_URL_FOOTER_LINKS}?lat=40.1186944&lng=-74.93045959999999&locationName=Bensalem%2C+PA%2C+USA`,
  },
  {
    text: "Part Time Jobs in Allentown PA",
    link: `${BASE_URL_FOOTER_LINKS}?lat=40.6022939&lng=-75.4714098&locationName=Allentown%2C+PA%2C+USA`,
  },
  {
    text: "Part Time Jobs in Langehorne PA",
    link: `${BASE_URL_FOOTER_LINKS}?lat=40.1745538&lng=-74.92266649999999&locationName=Langhorne%2C+PA%2C+USA`,
  },
  {
    text: "Part Time Jobs in Newtown PA",
    link: `${BASE_URL_FOOTER_LINKS}?lat=40.2283371&lng=-74.9322596&locationName=Newtown%2C+PA%2C+USA`,
  },
];

const FOOTER_LINKS_COLUMN_TWO = [
  {
    text: "Shoprite Part Time Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Shoprite&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Shady Brook Farm Part Time Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Shady+Brook+Farm&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "H&M Part Time Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=H%26M&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Lowe's Part Time Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Lowe%27s&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Panera Bread Part Time Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Panera+Bread&${BUCKS_COUNTY_LOCATION_URL}`,
  },
];

const FOOTER_LINKS_COLUMN_THREE = [
  {
    text: "Part Time Sales Associate Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Sales+Associate&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Part Time Cleaning Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Clean&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Part Time Barista Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Barista&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Part Time Driving Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Driver&${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Part Time Cashier Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?searchTerm=Cashier&${BUCKS_COUNTY_LOCATION_URL}`,
  },
];

const FOOTER_LINKS_COLUMN_FOUR = [
  {
    text: "Part Time Evening Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?${BUCKS_COUNTY_LOCATION_URL}&shift=Evening`,
  },
  {
    text: "Part Time Weekend Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?${BUCKS_COUNTY_LOCATION_URL}&weekend=true`,
  },
  {
    text: "Part Time Night Jobs",
    link: `${BASE_URL_FOOTER_LINKS}?${BUCKS_COUNTY_LOCATION_URL}&shift=Night`,
  },
  {
    text: "Part Time Jobs For Teens",
    link: `${BASE_URL_FOOTER_LINKS}?${BUCKS_COUNTY_LOCATION_URL}`,
  },
  {
    text: "Part Time Jobs For College Students",
    link: `${BASE_URL_FOOTER_LINKS}?${BUCKS_COUNTY_LOCATION_URL}`,
  },
];

export const FOOTER_LINKS = [
  {
    label: "Locations",
    information: FOOTER_LINKS_COLUMN_ONE,
  },
  {
    label: "Companies",
    information: FOOTER_LINKS_COLUMN_TWO,
  },
  {
    label: "Popular Jobs",
    information: FOOTER_LINKS_COLUMN_THREE,
  },
  {
    label: "Popular Searches",
    information: FOOTER_LINKS_COLUMN_FOUR,
  },
];
