import Settings from "./web/Settings/Settings";
import SettingsMobile from "./mobile/Settings/Settings";
import useIsMobile from "hooks/useIsMobile";
import { useSelector } from "react-redux";
import LoginSelector from "containers/Login/selectors/LoginSelector";

const SettingsContainer = ({
  onClickPasswordScreen,
  onClickEditBlockedUsers,
  onClickDeleteAccount,
}) => {
  const isMobile = useIsMobile();
  const userDetail = useSelector(LoginSelector.getLoggedInUser);
  const showChangePassword = userDetail?.loginType === "PW";
  const SettingsWrapper = isMobile ? SettingsMobile : Settings;

  return (
    <SettingsWrapper
      onClickPasswordScreen={onClickPasswordScreen}
      onClickDeleteAccount={onClickDeleteAccount}
      onClickEditBlockedUsers={onClickEditBlockedUsers}
      showChangePassword={showChangePassword}
    />
  );
};

export default SettingsContainer;
