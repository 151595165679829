import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {
  getPeopleApi,
  getPeopleDescriptionApi,
  requestResumeApi,
  markStarredApi,
} from "../../configs/network/apis";
import { showNotification } from "../../utils/commonUtils";
import { useDispatch } from "react-redux";
import { toggleRequestResume } from "../../containers/Employer/actions/EmployerActions";

export const useGetPeople = (
  searchTerm,
  wage,
  hrsPerWeek,
  age,
  experience,
  interested,
  sortKey,
  sortOrder,
  lat,
  lng,
  page,
) => {
  return useInfiniteQuery(
    "people",
    () => {
      return getPeopleApi({
        searchTerm,
        wage,
        hrsPerWeek,
        age,
        experience,
        interested,
        sortKey,
        sortOrder,
        lat,
        lng,
        page,
      });
    },
    {
      initialData: { data: [], totalRecords: 0 },
      getNextPageParam: (pageParam) => {
        return page + 1 <= Math.ceil(pageParam.totalRecords / 20);
      },
      // keepPreviousData: true,
    },
  );
};

export const useGetPeopleDescriptionQuery = ({ type, userId, employer_id }) => {
  return useQuery(["person", type, userId, employer_id], () =>
    getPeopleDescriptionApi({
      type,
      uid: userId,
      employer_id,
    }),
  );
};

export const useRequestResumeMutation = (type, uid, employerId) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation(requestResumeApi, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["person", type, uid]);
      queryClient.invalidateQueries(["people"]);
      dispatch(toggleRequestResume({ uid, employerId }));
      showNotification({
        alertProps: {
          severity: "success",
          children: res?.message,
        },
      });
    },
    onError: (error) => {
      showNotification({
        alertProps: {
          severity: "error",
          children: error?.response?.data?.message,
        },
      });
    },
  });
  const requestResume = (body) => mutation.mutateAsync(body);
  return [requestResume, mutation];
};

export const useChatRequestResumeMutation = (type, uid) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(requestResumeApi, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["person", type, uid]);
      queryClient.invalidateQueries(["people"]);
      showNotification({
        alertProps: {
          severity: "success",
          children: res?.message,
        },
      });
    },
    onError: (error) => {
      showNotification({
        alertProps: {
          severity: "error",
          children: error?.response?.data?.message,
        },
      });
    },
  });
  const requestResume = (body) => mutation.mutateAsync(body);
  return [requestResume, mutation];
};

export const useMarkStarredMutation = (type, uid) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(markStarredApi, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["person", type, uid]);
      queryClient.invalidateQueries(["people"]);
      showNotification({
        alertProps: {
          severity: "success",
          children: res?.message,
        },
      });
    },
    onError: (error) => {
      showNotification({
        alertProps: {
          severity: "error",
          children: error?.response?.data?.message,
        },
      });
    },
  });
  const markStarred = (body) => mutation.mutateAsync(body);
  return [markStarred, mutation];
};
