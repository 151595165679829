import { fetchEventSource } from "@sameer-j/react-fetch-event-source";
import { store } from "configs/store";

import { GET_ASK_HR_CHAT_RES } from "configs/network/apiConstants";
import { isEmpty } from "lodash";

export const DONE_CHUNK = "[DONE]";

export const AI_GENERATION_TYPES = {
  JOB_DESCRIPTION: "JobDescription",
  APPLY_NOTE: "ApplyNote",
  CHAT: "Chat",
  ASKHR: "AskHr",
};

export const fetchAIData = async ({
  aiGeneratorInput,
  aiGenerationType,
  dataUpdaterWithChunk,
  abortController,
}) => {
  const { loginState } = store.getState();
  const { user } = loginState;

  const { url, method, body } = getApiDetails(aiGenerationType, aiGeneratorInput);

  try {
    await fetchEventSource(url, {
      method,
      body,
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json",
        Authorization: !isEmpty(user) && `Bearer ${user.token}`,
        refreshToken: !isEmpty(user) && user.refreshToken,
      },
      signal: abortController.signal,
      openWhenHidden: true,
      async onopen() {
        // console.log("Open SSE connection."); // DND: This is needed for debugging in future.
      },
      onmessage(event) {
        if (event.data !== DONE_CHUNK) {
          const { chunkMessage } = JSON.parse(event?.data || "");
          dataUpdaterWithChunk({ chunk: chunkMessage });
        } else {
          dataUpdaterWithChunk({ chunk: DONE_CHUNK });
          // console.log("Not message:, closing connection"); // DND: This is needed for debugging in future.
          abortController.abort();
        }
      },
      onerror(error) {
        dataUpdaterWithChunk({ chunk: DONE_CHUNK });
        // console.log("onerror:", error); // DND: This is needed for debugging in future.
        throw new Error(error);
      },
      onclose() {
        // console.log("Server closed connection, closing from client now:", response); // DND: // This is needed for debugging in future.
        dataUpdaterWithChunk({ chunk: DONE_CHUNK });
        abortController.abort();
      },
    });
  } catch (e) {
    // console.log("Error with AI Completion response handling:", e) // DND: This is needed for debugging in future.
    dataUpdaterWithChunk({ chunk: DONE_CHUNK });
  }
};

const getApiDetails = (aiGenerationType, aiGeneratorInput) => {
  const AI_BASE_URL = `${import.meta.env.VITE_APP_NODE_API_URL}/ai-completion`;
  if (aiGenerationType === AI_GENERATION_TYPES.JOB_DESCRIPTION) {
    const { companyName, jobLocation, jobTitle } = aiGeneratorInput;
    return {
      url: `${AI_BASE_URL}/jd`,
      method: "PUT",
      body: JSON.stringify({
        companyName,
        jobLocation,
        jobTitle,
      }),
    };
  } else if (aiGenerationType === AI_GENERATION_TYPES.APPLY_NOTE) {
    const { jobId } = aiGeneratorInput;
    return {
      url: `${AI_BASE_URL}/${jobId}/apply`,
      method: "GET",
    };
  } else if (aiGenerationType === AI_GENERATION_TYPES.CHAT) {
    const { jobSeekerId, employerId } = aiGeneratorInput;
    return {
      url: `${AI_BASE_URL}/chat`,
      method: "PUT",
      body: JSON.stringify({
        jobSeekerId,
        employerId,
      }),
    };
  } else if (aiGenerationType === AI_GENERATION_TYPES.ASKHR) {
    const { prompt } = aiGeneratorInput;
    return {
      url: `${AI_BASE_URL}/${GET_ASK_HR_CHAT_RES}`,
      method: "PUT",
      body: JSON.stringify({
        prompt,
      }),
    };
  }
  return {
    url: AI_BASE_URL,
    method: "PUT",
    body: JSON.stringify({ messages: [{ role: "system", content: "" }] }),
  };
};
