import { MultiItemCarousel, Text } from "../../../components/atoms";
import { ReviewCard } from "../../../components/molecules";
import { colors, spacing } from "../../../styles";
import { convertArrayIntoChunks } from "../../../utils/commonUtils";
import { images } from "../../../assets/images";

const Reviews = ({ reviews }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "74vh",
      }}
    >
      <div
        style={{
          width: `calc(100% - 2 * ${spacing.m})`,
          display: "flex",
          alignSelf: "center",
          height: "100%",
          backgroundImage: `url(${images.testimonialBackgroundMobile})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "30px",
          position: "absolute",
        }}
      />
      <div style={{ zIndex: 999 }}>
        <Text
          style={{
            textAlign: "center",
            lineHeight: 0.2,
            marginBottom: spacing.xxs,
            marginTop: "3.5rem",
            fontSize: "8rem",
            transform: "rotateY(180deg)",
          }}
        >
          &rdquo;
        </Text>
        <Text
          size={28}
          style={{
            lineHeight: 1.2,
            marginLeft: `calc(${spacing.l} + ${spacing.m})`,
            marginRight: `calc(${spacing.l} + ${spacing.m})`,
            marginBottom: spacing.xs,
            textAlign: "center",
          }}
        >
          The{" "}
          <Text
            size={28}
            component="span"
            weight="extrabold"
            color="#2B4895"
            style={{ lineHeight: 1.2 }}
          >
            EMPLOY
            <Text
              size={28}
              component="span"
              weight="extrabold"
              color="#0A97FF"
              style={{ lineHeight: 1.2 }}
            >
              D
            </Text>{" "}
          </Text>
          Hiring Experience
        </Text>
        <Text
          size="s"
          color={colors.black}
          style={{
            textAlign: "center",
            marginBottom: spacing.xl,
          }}
        >
          Employers love EmployD!
        </Text>
        <MultiItemCarousel
          interval={5000}
          mx={spacing.s}
          arrowSize={40}
          arrowPosition={14}
        >
          {convertArrayIntoChunks(reviews, 1).map((j, jindex) => (
            <div
              key={jindex}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {j.map((i, index) => (
                <ReviewCard
                  item={i}
                  key={index}
                  numberOfLines={5}
                  cardStyle={{
                    width: "80vw",
                  }}
                />
              ))}
            </div>
          ))}
        </MultiItemCarousel>
      </div>
    </div>
  );
};

export default Reviews;
