import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";
import { colors } from "styles";
import { OtpInputConstants } from "./OtpInputConstants";

const OtpInput = ({
  value,
  otpLength = 6,
  onChange,
  isVerifying,
  isCodeVerified,
  hasError,
}) => {
  const RE_CHECK = new RegExp(/^\d+$/);

  const valueItems = useMemo(() => {
    const valueArray = value?.split("");
    const items = new Array(otpLength).fill("");

    valueArray?.forEach((char, index) => {
      if (index < otpLength && RE_CHECK.test(char)) {
        items[index] = char;
      }
    });
    return items;
  }, [value, otpLength]);

  const focusToNextInput = (target) => {
    const nextBox = target.nextElementSibling;
    if (nextBox) {
      nextBox.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const prevBox = target.previousElementSibling;
    if (prevBox) {
      prevBox.focus();
    }
  };

  const handleChange = (e, index) => {
    const target = e.target;
    let text = target.value;
    const isTextDigit = RE_CHECK.test(text);
    if (!isTextDigit && text) {
      return;
    }
    text = isTextDigit ? text : " ";
    if (text.length === 1) {
      const newValue =
        value.substring(0, index) + text + value.substring(index + 1);
      onChange(newValue);
      if (!isTextDigit) {
        return;
      }
      focusToNextInput(target);
    } else if (text.length === otpLength) {
      onChange(text);
      target.blur();
    }
  };

  const onInputDown = (e) => {
    const target = e.target;
    const text = target.value;
    if (
      e.key === OtpInputConstants.ARROW_RIGHT ||
      e.key === OtpInputConstants.ARROW_DOWN
    ) {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (
      e.key === OtpInputConstants.ARROW_LEFT ||
      e.key === OtpInputConstants.ARROW_UP
    ) {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    target.setSelectionRange(0, text.length);

    if (e.key !== OtpInputConstants.BACKSPACE || text) {
      return;
    }

    focusToPrevInput(target);
  };

  const onFocus = (e) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  return (
    <InputContainer>
      {valueItems.map((value, index) => (
        <Input
          key={`${index}-${otpLength}`}
          inputMode="numeric"
          maxLength={otpLength}
          type="text"
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={onInputDown}
          onFocus={onFocus}
          sx={[
            {
              outline: "none",
            },
            isCodeVerified && { borderColor: colors.green },
            hasError && !isVerifying && { borderColor: colors.red2 },
            isVerifying && {
              borderColor: colors.darkBlue,
              color: colors.disableGrey,
            },
          ]}
        />
      ))}
    </InputContainer>
  );
};

export default OtpInput;

OtpInput.propTypes = {
  value: PropTypes.string.isRequired,
  otpLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isVerifying: PropTypes.bool.isRequired,
  isCodeVerified: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

const InputContainer = styled("div")`
  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 10px;
`;

const Input = styled("input")`
  width: 35px;
  height: 35px;
  padding: 10px;
  border: 1px solid ${colors.inputBorder};
  border-radius: 5px;
  text-align: center;
`;
