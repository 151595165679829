import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../../../components/atoms";
import { colors, spacing } from "../../../../../styles";
import UploadModal from "../UploadModal";
import DeleteResumeModal from "../DeleteResumeModal";
import ProfileImage from "../../../../../components/molecules/ProfileImage";
import noop from "lodash/noop";
import ResumeContainer from "../ResumeContainer";

import { hideLoader, showLoader, showNotification } from "utils/commonUtils";
import { userDetailUpdate } from "containers/Login/actions/LoginActions";
import { deleteJobSeekerResume } from "configs/network/apis";

const PersonalInformation = ({ userDetails, onUploadResume }) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDeleteResumeModalOpen, setIsDeleteResumeModalOpen] = useState(false);

  const dispatch = useDispatch();

  const showUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const hideUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const showDeleteResumeModal = () => {
    setIsDeleteResumeModalOpen(true);
  };
  const hideDeleteResumeModal = () => {
    setIsDeleteResumeModalOpen(false);
  };

  const handleDeleteResume = async () => {
    hideDeleteResumeModal();
    try {
      showLoader();
      const response = await deleteJobSeekerResume();
      const { message } = response.data;
      const { resumeUrl, resume_filename } = response.data.user;
      dispatch(
        userDetailUpdate({
          resumeUrl: resumeUrl,
          resume_filename: resume_filename,
        }),
      );
      showNotification({
        alertProps: {
          severity: "success",
          children: message,
        },
      });
    } catch (err) {
      showNotification({
        alertProps: {
          severity: "error",
          children: err?.response?.message,
        },
      });
    }
    hideLoader();
  };

  return (
    <>
      <TopContainer>
        <ProfileImage
          style={{ width: 111, height: 111 }}
          currentImageUrl={userDetails?.photoURL}
          alt={userDetails?.fullName}
          imageType="user"
        />
        <Text size={22} weight="semibold" style={{ marginTop: spacing.s }}>
          {userDetails?.fullName || userDetails?.name}
        </Text>
        <Text size="l" color={colors.placeholder}>
          {userDetails?.age} yrs
        </Text>
      </TopContainer>
      <Divider style={{ margin: spacing.l }} />
      <div style={{ padding: spacing.l }}>
        <Row sx={{ mb: spacing.xxl }}>
          <Icon name="profile-email" size={24} style={{ marginRight: spacing.s }} />
          <Text size="m" numberOfLines={1} style={{ display: "flex", flex: 1 }}>
            {userDetails?.email}
          </Text>
        </Row>
        <Row sx={{ mb: spacing.xxl }}>
          <Icon name="profile-phone" size={24} style={{ marginRight: spacing.s }} />
          <Text size="m" numberOfLines={1} style={{ display: "flex", flex: 1 }}>
            {userDetails?.countryCode
              ? `+${userDetails?.countryCode}-${userDetails?.phoneNumber}`
              : userDetails?.phoneNumber}
          </Text>
        </Row>
        <Row
          sx={{ alignItems: "flex-start", cursor: "pointer" }}
          onClick={!userDetails.resumeUrl ? showUploadModal : noop}
        >
          <Icon
            size={24}
            name="profile-description"
            style={{
              marginRight: spacing.s,
              transform: "translateY(4px)",
            }}
          />
          <div>
            {!userDetails.resumeUrl && (
              <Text
                size="m"
                color={colors.primary}
                style={{
                  textDecoration: "underline",
                  textUnderlinePosition: "under",
                }}
              >
                Upload your resume
              </Text>
            )}
            {userDetails.resumeUrl && (
              <Text size="l" sx={{ marginTop: spacing.xxxs }}>
                Your job resume
              </Text>
            )}

            <ResumeContainer
              userDetails={userDetails}
              showUploadModal={showUploadModal}
              showDeleteResumeModal={showDeleteResumeModal}
            />
          </div>
        </Row>
      </div>
      <UploadModal
        isResume
        userDetails={userDetails}
        isUploadModalOpen={isUploadModalOpen}
        showUploadModal={showUploadModal}
        hideUploadModal={hideUploadModal}
        onUploadAttachments={onUploadResume}
        uploadButtonName="Save"
      />
      <DeleteResumeModal
        isOpen={isDeleteResumeModalOpen}
        hideDeleteResumeModal={hideDeleteResumeModal}
        handleDeleteResume={handleDeleteResume}
      />
    </>
  );
};

PersonalInformation.propTypes = {
  userDetails: PropTypes.object.isRequired,
  onUploadResume: PropTypes.func.isRequired,
};

export default PersonalInformation;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${spacing.m};
`;

const Row = styled("div")`
  display: flex;
  align-items: center;
`;
