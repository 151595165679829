import PropTypes from "prop-types";

import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Avatar, Icon, NewJob, Text } from "../../../atoms";
import { colors, spacing, styleUtils } from "../../../../styles";
import { renderImage } from "../../../../utils/imageUtils";
import { images } from "../../../../assets/images";

import { getDateDifference } from "../../../../utils/dateUtils";
import { getFormattedJobLocation, getProcessedJobDetailValue } from "../../../../utils/commonUtils";

import "../jobcard.css";

const ResponsiveMapJobCard = ({ id, item, onClickCard, size = "m" }) => {
  const jobPostingDayDiff = getDateDifference(item?.date);
  const isNew = jobPostingDayDiff < 14;

  return (
    <Card
      sx={{
        transform: `scale(${size === "m" ? 1 : 0.875})`,
        border: `1px solid ${colors.lightBlue}`,
      }}
      onClick={(e) => onClickCard(e, false)}
      id={id}
    >
      <TopContainer>
        <div style={{ display: "flex" }}>
          <Avatar
            src={renderImage(item?.brandLogo, "job")}
            onClick={(e) => onClickCard(e, true)}
            name="job_card_avatar"
            sx={{
              width: 57,
              height: 57,
              borderRadius: 57,
              cursor: "pointer",
            }}
          >
            <img
              alt={item?.listingPosition}
              src={images.defaults.job}
              style={{ width: 57, height: 57, borderRadius: 57 }}
            />
          </Avatar>
          <TitleContainer>
            <Row sx={{ justifyContent: "space-between" }}>
              <Text
                weight="semibold"
                numberOfLines={2}
              >
                {item?.listingPosition}
              </Text>
            </Row>
            <Row>
              <Text
                size="xs"
                weight="regular"
                color={colors.brownText}
                numberOfLines={1}
              >
                {item?.brandName}
              </Text>
            </Row>
            <Row>
              <Text
                size="xs"
                weight="regular"
                color={colors.brownText}
                numberOfLines={1}
              >
                {getFormattedJobLocation(item?.city)}
              </Text>
            </Row>
            <Row>
              <NewJob
                jobStyle={{
                  fontSize: "6px",
                  borderRadius: styleUtils.pxToRem("100px"),
                  width: styleUtils.pxToRem("46.5px"),
                }}
                isNew={isNew}
              />
            </Row>
          </TitleContainer>
        </div>
        <CircularBox
          sx={{
            height: styleUtils.pxToRem("60px"),
            minWidth: styleUtils.pxToRem("60px"),
            textAlign: "center",
            borderRadius: styleUtils.pxToRem("60px"),
          }}
        >
          <Text
            weight="medium"
            size="xs"
            numberOfLines={1}
            sx={{ color: "#3A5FC7;", fontWeight: "bold" }}
          >
            ${getProcessedJobDetailValue(item?.wage)}/hr
          </Text>
        </CircularBox>
      </TopContainer>
      <div style={{ padding: `0 ${spacing.s}`, margin: `${spacing.s} 0` }}>
        <Row sx={{ justifyContent: "space-between" }}>
          <Row sx={{ gap: "6px" }}>
            <Icon
              name="clock"
              alt={"clock"}
              size={21}
            />
            <Text
              weight="semibold"
              size="xs"
              numberOfLines={1}
              lineHeight={1}
            >
              {`${getProcessedJobDetailValue(item?.hoursListed)} hrs/week`}
            </Text>
          </Row>
          <Row sx={{ gap: "6px" }}>
            <Icon
              name="experience"
              alt={"experience"}
              size={21}
            />
            <Text
              weight="semibold"
              size="xs"
              numberOfLines={1}
              lineHeight={1}
            >
              {`${getProcessedJobDetailValue(item?.experience)} years`}
            </Text>
          </Row>
          <Row sx={{ gap: "6px" }}>
            <Icon
              name="distance"
              alt={"distance"}
              size={21}
            />
            <Text
              weight="semibold"
              size="xs"
              numberOfLines={1}
              lineHeight={1}
            >
              {Math.ceil(item?.distance)} {" miles away"}
            </Text>
          </Row>
        </Row>
      </div>
    </Card>
  );
};

ResponsiveMapJobCard.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onClickCard: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default ResponsiveMapJobCard;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${styleUtils.pxToRem("5px")};
  padding-right: 0;
  padding-bottm: ${spacing.xs};
  padding-top: ${spacing.s};
  background: transparent linear-gradient(68deg, #ffffff00 0%, #3a5fc7 200%) 0% 0% no-repeat
    padding-box;
  border-radius: 5px 5px 0px 0px;
  height: ${styleUtils.pxToRem("113px")};
  opacity: 1;
`;
const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.xs};
`;
const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CircularBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.xs};
  background: ${colors.white};
  box-shadow: 0px 6px 10px #647eff2c;
  opacity: 1;
`;
