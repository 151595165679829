import { useEffect } from "react";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";

const useFacebookLogin = (appId) => {
  const initParams = {
    version: "v16.0",
    autoLogAppEvents: true,
    cookie: false,
    localStorage: true,
    appId,
  };
  const init = async () => {
    await FacebookLoginClient.loadSdk("en_US");
    window.fbAsyncInit = () => {
      FacebookLoginClient.init(initParams);
    };
  };

  useEffect(() => {
    init();
  }, []);
};

export default useFacebookLogin;
