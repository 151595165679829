export const otpInitialState = {
  sendOtpLoading: false,
  sendOtpFailed: false,
  sendOtpSuccess: false,
  verifyOtpLoading: false,
  verifyOtpFailed: false,
  verifyOtpSuccess: false,
  isEmailOtpValid: false,
  isPhoneOtpValid: false,
  emailValidation: false,
  phoneValidation: false,
};

export const loginOtpInitialState = {
  sendLoginOtpLoading: false,
  sendLoginOtpFailed: false,
  sendLoginOtpSuccess: false,
  verifyLoginOtpLoading: false,
  verifyloginOtpFailed: false,
  verifyloginOtpSuccess: false,
  isLoginOtpValid: false,
  userValidation: false,
};
