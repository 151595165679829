import PropTypes from "prop-types";
import { Button, Card } from "@mui/material";
import { CardModal, Text } from "../../atoms";
import { spacing, styleUtils } from "../../../styles";
import { hideLoader, showLoader } from "utils/commonUtils";
import useIsMobile from "hooks/useIsMobile";
import { oneTimeLoginApi } from "configs/network/apis";
import { apiErrorModalRef } from "globalRefs";

const EmployerRegistrationModal = ({ token, isModalOpen, setIsModalOpen }) => {
  const isMobile = useIsMobile();

  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleLoginNavigation = async () => {
    showLoader();
    try {
      await oneTimeLoginApi({ oneTimeToken: token });
    } catch (error) {
      apiErrorModalRef.current.showModal();
    } finally {
      hideLoader();
      onClose();
    }
  };

  const handlePageRefresh = () => {
    document.location.reload(true);
    onClose();
  };

  return (
    <CardModal open={isModalOpen}>
      <Card
        sx={{
          p: spacing.l,
          textAlign: "center",
          width: styleUtils.pxToRem(isMobile ? "294px" : "367px"),
        }}
      >
        <Text>{"Account created successfully."}</Text>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: spacing.l,
          }}
        >
          <Button variant="contained" sx={{ width: "auto" }} onClick={handleLoginNavigation}>
            Create a job
          </Button>
          <Button variant="outlined" sx={{ width: "auto" }} onClick={handlePageRefresh}>
            Refresh form
          </Button>
        </div>
      </Card>
    </CardModal>
  );
};

EmployerRegistrationModal.propTypes = {
  token: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default EmployerRegistrationModal;
