import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import HomeBrands from "./views/HomeBrands";
import HomeBrandsMobile from "./mobileViews/HomeBrands";

import PostAJob from "./views/PostAJob";
import PostAJobMobile from "./mobileViews/PostAJob";

import ExploreProfiles from "./views/ExploreProfiles";
import ExploreProfilesMobile from "./mobileViews/ExploreProfiles";

import ChatDirectly from "./views/ChatDirectly";
import ChatDirectlyMobile from "./mobileViews/ChatDirectly";

import Reviews from "./views/Reviews";
import ReviewsMobile from "./mobileViews/Reviews";

import { spacing } from "../../styles";
import LoginSelector from "../Login/selectors/LoginSelector";
import { EMPLOYER } from "../../appConstants";
import useIsMobile from "../../hooks/useIsMobile";

import SearchWrapper from "./SearchWrapper";
import { images } from "../../assets/images";

const HomeView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();
  const referrerId = searchParams.get("referrerId");
  const referrerType = searchParams.get("referrerType");
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const [currentImage, setCurrentImage] = useState(0);

  const onGetStarted = () => {
    navigate(`/login/${EMPLOYER}`, {
      state: {
        path: location.pathname,
        referrer: { referrerId, referrerType },
      },
    });
  };

  const imagesValue = [
    images.home["employer-background"]["1"],
    images.home["employer-background"]["2"],
    images.home["employer-background"]["3"],
    images.home["employer-background"]["4"],
  ];

  const imagesValueWebResponsive = [
    images.home["employer-webresponsive-background"]["1"],
    images.home["employer-webresponsive-background"]["2"],
    images.home["employer-webresponsive-background"]["3"],
    images.home["employer-webresponsive-background"]["4"],
  ];

  useEffect(() => {
    let timer;

    timer = setInterval(() => {
      changeBackgroundImage();
    }, 3000);

    return () => clearInterval(timer);
  }, [currentImage]);

  const changeBackgroundImage = () => {
    let newCurrentImg = 0;
    const noOfImages = imagesValue.length;
    if (currentImage !== noOfImages - 1) {
      newCurrentImg = currentImage + 1;
    }
    setCurrentImage(newCurrentImg);
  };

  const allReviews = [
    {
      name: "Alex Cole",
      job: "Retail Store Owner",
      location: "Newtown, PA",
      title: "Postings jobs have never been this easy",
      message:
        "I found the process of posting a job on the site really easy and straight-forward; the App is very user friendly.",
    },
    {
      name: "Betty Mathis",
      job: "Restaurant Owner",
      location: "Yardley",
      title: "Found the perfect cashier for my store",
      message:
        "EmployD helped me find the cashier for my store in New Town instantly. I've had great success with their service and will continue to use this when hiring part-time employees.",
    },
    {
      name: "Mathew Lewin",
      job: "Store Manager",
      location: "Langhorne",
      title: "No more ads, a simple platform to fill vacancies",
      message:
        "I no longer have to post advertisements for the job vacancies at my store. All I do is post it on EmployD. The platform is easy to use, even for first-timers. I can't believe I didn't know about it before!",
    },
    {
      name: "Shawn Rhodes",
      job: "Hiring Manager",
      location: "Bucks County",
      title: "Quality candidates for all my stores",
      message:
        "I've been able to hire quality candidates for multiple stores in my region since I began using EmployD a few months ago.",
    },
  ];

  const HomeBrandsWrapper = isMobile ? HomeBrandsMobile : HomeBrands;
  const PostAJobWrapper = isMobile ? PostAJobMobile : PostAJob;
  const ExploreProfilesWrapper = isMobile
    ? ExploreProfilesMobile
    : ExploreProfiles;
  const ChatDirectlyWrapper = isMobile ? ChatDirectlyMobile : ChatDirectly;
  const ReviewsWrapper = isMobile ? ReviewsMobile : Reviews;

  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        marginBottom: spacing.xl,
      }}
    >
      <div
        style={{
          backgroundImage: isMobile
            ? `url(${imagesValueWebResponsive[currentImage]})`
            : `url(${imagesValue[currentImage]})`,
          padding: isMobile ? spacing.s : 0,
          margin: 0,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <SearchWrapper onGetStarted={onGetStarted} />
      </div>
      <Divider />
      <HomeBrandsWrapper />
      <Divider />
      <PostAJobWrapper
        isAuthenticated={isAuthenticated}
        onGetStarted={onGetStarted}
      />
      <ExploreProfilesWrapper
        isAuthenticated={isAuthenticated}
        onGetStarted={onGetStarted}
      />
      <ChatDirectlyWrapper
        isAuthenticated={isAuthenticated}
        onGetStarted={onGetStarted}
      />
      <ReviewsWrapper reviews={allReviews} />
    </div>
  );
};

export default HomeView;
