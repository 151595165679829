import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import { isEmpty } from "lodash";
import { ThemeProvider, styled } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { clarity } from "react-microsoft-clarity";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { theme } from "./styles";
import AppMain from "./AppMain";

import "./App.css";
import LoginSelector from "containers/Login/selectors/LoginSelector";
import { getUserUpdatedDetails } from "containers/Common/actions/CommonActions";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

const App = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const dispatch = useDispatch();

  useEffect(() => {
    clarity.init(import.meta.env.VITE_APP_MICROSOFT_CALRITY_PROJECT_ID);
    if (!isEmpty(loggedInUser)) {
      dispatch(
        getUserUpdatedDetails({
          uid: loggedInUser?.uid,
          type: loggedInUser?.userType,
        }),
      );
    }
  }, []);

  return (
    <Container id="APP_CONTAINER">
      <div>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", ${
            import.meta.env.VITE_APP_MICROSOFT_CALRITY_PROJECT_ID
          })`,
          }}
        />
      </div>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <AppMain />
            </StyledEngineProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Container>
  );
};

export default App;

const Container = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
