import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

import SignUpView from "./views/SignUpView";
import useIsMobile from "../../hooks/useIsMobile";

import * as signUpActions from "./actions/SignUpActions";
import * as loginActions from "../Login/actions/LoginActions";

import { getRedirectValue, showLoader, showNotification } from "../../utils/commonUtils";
import { colors, styleUtils } from "../../styles";
import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import {
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
} from "../../routes/routeConstants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

/**
 * User Sign Up component with handler functions
 */
const SignUpContainer = (props) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const { signUpType } = useParams();
  const { state, key } = location || {};

  const [formDetails, setFormDetails] = useState({});
  const [isEmployer, setIsEmployer] = useState(false);
  const [isOtpLogin, setIsOtpLogin] = useState(false);
  const [otpReq, setOtpReq] = useState({});

  const {
    email,
    name,
    image,
    isSocialLogin,
    idToken,
    loginType,
    source,
    referrer,
    isPhoneSignUp,
    countryCodeValue,
    phoneNumValue,
    social_id,
  } = state || {};
  const { signUpActionsProp, isEmployerSignUpSuccess, isJobSeekerSignUpSuccess, isLoginSuccess } =
    props;

  useEffect(() => {
    setIsEmployer(signUpType === EMPLOYER);
    if (location && location.state && location.state.isOtpLogin) {
      setIsOtpLogin(location.state.isOtpLogin);
    }
  }, []);

  useEffect(() => {
    setFormDetails({});
    setOtpReq({});
  }, [key]);

  useEffect(() => {
    if ((isEmployerSignUpSuccess || isJobSeekerSignUpSuccess) && isLoginSuccess) {
      const path = isEmployer
        ? `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`
        : `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`;
      navigate(path);
      showNotification({
        alertProps: {
          severity: "success",
          children: "You are logged in successfully",
        },
      });
    }
  }, [isLoginSuccess]);

  const signupSuccessCallback = async (params) => {
    const [redirectValue] = [getRedirectValue()];
    if (params.type === JOB_SEEKER) {
      const { pathname, search } = redirectValue;
      if (pathname) {
        navigate({
          pathname,
          search,
        });
      } else {
        search
          ? navigate({
              pathname: `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
              search,
            })
          : navigate(`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`);
      }
    }
  };

  const handleSetFormDetails = (values) => {
    setFormDetails({
      ...formDetails,
      ...values,
    });

    if (values?.email && values?.phoneNumber) {
      setOtpReq({
        email: values?.email,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber,
      });
    }
  };

  const handleEmployerSignUp = (values) => {
    handleSetFormDetails(values);
    if (isSocialLogin) {
      if (values["designation"] === "Other") {
        values["designation"] = values.designationText;
        delete values.designationText;
      }
      delete values.confirmPassword;
      showLoader();
      signUpActionsProp.employerSignUpRequest({ ...values, social_id, name });
    }
  };

  const handleJobSeekerSignUp = (values) => {
    setFormDetails({
      ...formDetails,
      ...values,
    });
    showLoader();
    delete formDetails.confirmPassword;
    delete formDetails.image;
    signUpActionsProp.jobSeekerSignUpRequest({
      ...formDetails,
      ...values,
      ...referrer,
      social_id,
      name,
      onCallback: signupSuccessCallback,
    });
  };

  const handleEmailEditForm = (values) => {
    const { email } = values;
    formDetails["email"] = email;
    setOtpReq({
      ...otpReq,
      email: email,
    });
  };

  const handlePhoneEditForm = (values) => {
    const { phoneNumber, countryCode } = values;
    formDetails["countryCode"] = countryCode;
    formDetails["phoneNumber"] = phoneNumber;
    setOtpReq({
      ...otpReq,
      phoneNumber: phoneNumber,
      countryCode: countryCode,
    });
  };

  const handleOtpSubmit = () => {
    if (isEmployer) {
      if (formDetails["designation"] === "Other") {
        formDetails["designation"] = formDetails.designationText;
        delete formDetails.designationText;
      }
      delete formDetails?.designationText;
      delete formDetails.confirmPassword;
      showLoader();
      signUpActionsProp.employerSignUpRequest({
        ...formDetails,
        social_id,
        name,
      });
      logEvent(FirebaseEvents.EMP_EMAIL_SIGNUP_PAGE3);
    } else {
      delete formDetails.confirmPassword;
      delete formDetails.image;
      showLoader();
      signUpActionsProp.jobSeekerSignUpRequest({
        ...formDetails,
        ...referrer,
        social_id,
        name,
        onCallback: signupSuccessCallback,
      });
      logEvent(FirebaseEvents.JS_EMAIL_SIGNUP_PAGE4);
    }
  };

  return (
    <Container id="CONTAINER" style={{ padding: "2% 0% 3% 0%" }}>
      <PaperContainer
        sx={{
          margin: isMobile
            ? `${styleUtils.pxToRem("20px")}  ${styleUtils.pxToRem("6px")} ${styleUtils.pxToRem(
                "42px",
              )} ${styleUtils.pxToRem("6px")}`
            : null,
        }}
      >
        <Paper sx={{ p: 1 }} variant="outlined">
          <SignUpView
            otpReq={otpReq}
            isEmployer={isEmployer}
            isOtpLogin={isOtpLogin}
            isPhoneSignUp={isPhoneSignUp}
            countryCodeValue={countryCodeValue}
            phoneNumValue={phoneNumValue}
            signUpActionsProp={signUpActionsProp}
            seekerFormDetails={formDetails}
            handleEmailEditForm={handleEmailEditForm}
            handlePhoneEditForm={handlePhoneEditForm}
            handleSetFormDetails={handleSetFormDetails}
            handleEmployerSignUp={handleEmployerSignUp}
            handleJobSeekerSignUp={handleJobSeekerSignUp}
            handleOtpSubmit={handleOtpSubmit}
            socialSignUpDetails={{
              email,
              name,
              image,
              isSocialLogin,
              idToken,
              source,
              loginType,
            }}
          />
        </Paper>
      </PaperContainer>
    </Container>
  );
};
const mapStateToProps = ({ signUpState, loginState }) => ({
  isJobSeekerSignUpSuccess: signUpState.isJobSeekerSignUpSuccess,
  isEmployerSignUpSuccess: signUpState.isEmployerSignUpSuccess,
  isLoginSuccess: loginState.isLoginSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  signUpActionsProp: bindActionCreators(signUpActions, dispatch),
  loginActionsProp: bindActionCreators(loginActions, dispatch),
});

SignUpContainer.propTypes = {
  signUpActionsProp: PropTypes.object.isRequired,
  isEmployerSignUpSuccess: PropTypes.bool.isRequired,
  isJobSeekerSignUpSuccess: PropTypes.bool.isRequired,
  isLoginSuccess: PropTypes.bool.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);

const PaperContainer = styled("div")`
  background: #fcfffe 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px ${colors.dropShadow};
  border: 1px solid #e6edff;
  opacity: 1;
`;
