import { useState } from "react";
import PropTypes from "prop-types";

import { Button, Grid, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Icon, Text, TextInput } from "../../../../components/atoms";
import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";

import { CHANGE_PASSWORD } from "../../constants";
import { isEmpty, isEqual } from "lodash";

const SetNewPassword = ({ newPasswordFormik, handleEdit }) => {
  const [showNewPwdText, setShowNewPwdText] = useState(false);
  const [showConfirmPwdText, setShowConfirmPwdText] = useState(false);

  const passwordMatchMessage = newPasswordFormik.values.newPassword.length >= 6 &&
    isEqual(newPasswordFormik.values.newPassword, newPasswordFormik.values.confirmPassword) && (
      <PasswordMatchWrapper>
        <Icon size={15} name="correct-validation" />
        <Text size="s" sx={{ color: colors.darkGray2, marginLeft: spacing.xxs }}>
          {CHANGE_PASSWORD.PASSWORD_MATCH}
        </Text>
      </PasswordMatchWrapper>
    );

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xs, mx: spacing.m }}>
          <InfoLabelText size="l" color="#767676">
            {CHANGE_PASSWORD.NEW_PASSWORD_LABEL}
          </InfoLabelText>
          <PasswordInput
            name="newPassword"
            placeholder={CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER}
            type={showNewPwdText ? "text" : "password"}
            value={newPasswordFormik.values.newPassword}
            onChange={newPasswordFormik.handleChange}
            onBlur={newPasswordFormik.handleBlur}
            endAdornment={
              <InputAdornment position="end" onClick={() => setShowNewPwdText(!showNewPwdText)}>
                <IconButton>
                  {showNewPwdText ? (
                    <Visibility sx={{ color: "#848484" }} />
                  ) : (
                    <VisibilityOff sx={{ color: "#848484" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            sx={{ width: "100%" }}
          />
          {newPasswordFormik.touched.newPassword &&
            Boolean(newPasswordFormik.errors.newPassword) && (
              <FormHelperText sx={{ color: colors.red }}>
                {newPasswordFormik.errors.newPassword}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xs, mx: spacing.m }}>
          <InfoLabelText size="l" color="#767676">
            {CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL}
          </InfoLabelText>
          <PasswordInput
            name="confirmPassword"
            placeholder={CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER}
            type={showConfirmPwdText ? "text" : "password"}
            value={newPasswordFormik.values.confirmPassword}
            onChange={newPasswordFormik.handleChange}
            onBlur={newPasswordFormik.handleBlur}
            handleSubmit={newPasswordFormik.handleSubmit}
            endAdornment={
              <InputAdornment
                position="end"
                onClick={() => setShowConfirmPwdText(!showConfirmPwdText)}
              >
                <IconButton>
                  {showConfirmPwdText ? (
                    <Visibility sx={{ color: "#848484" }} />
                  ) : (
                    <VisibilityOff sx={{ color: "#848484" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            sx={{ width: "100%" }}
          />
          {newPasswordFormik.touched.confirmPassword &&
            Boolean(newPasswordFormik.errors.confirmPassword) && (
              <FormHelperText sx={{ color: colors.red }}>
                {newPasswordFormik.errors.confirmPassword}
              </FormHelperText>
            )}
          {passwordMatchMessage}
        </Grid>
      </div>
      <ActionFooter>
        <ActionButton
          variant="outlined"
          onClick={() => handleEdit("settings", "")}
          sx={{
            mr: spacing.s,
          }}
        >
          {CHANGE_PASSWORD.CANCEL_BUTTON}
        </ActionButton>
        <ActionButton
          disabled={!isEmpty(newPasswordFormik.errors)}
          onClick={newPasswordFormik.handleSubmit}
          variant="contained"
        >
          {CHANGE_PASSWORD.SAVE_BUTTON}
        </ActionButton>
      </ActionFooter>
    </>
  );
};

SetNewPassword.propTypes = {
  newPasswordFormik: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default SetNewPassword;

const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.xs};
  margin-top: ${spacing.m};
`;
const PasswordInput = styled(TextInput)(() => ({
  border: `1px solid ${colors.lightBlue}`,
  borderRadius: 8,
  height: styleUtils.pxToRem("46px"),
  width: styleUtils.pxToRem("294px"),
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: textSizes.m,
  },
}));
const ActionButton = styled(Button)`
  width: ${styleUtils.pxToRem("142.5px")};
  height: ${styleUtils.pxToRem("40px")};
  font-size: ${textSizes.m};
  margin-right: ${spacing.s};
`;
const ActionFooter = styled("div")`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-left: ${spacing.xs};
  padding-right: ${spacing.xs};
  margin-left: ${spacing.xxl};
  margin-right: ${spacing.xxl};
`;

const PasswordMatchWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
`;

