import { useSelector } from "react-redux";
import SchedulerBookSlotForm from "./SchedulerBookSlotForm";

import { SchdeulerListingTable } from "../../../../../components/molecules";
import JobSeekerSelector from "../../../selectors/JobSeekerSelector";

import {
  SEEKER_EMP_SCHEDULE_TABLE_COL_CONSTS,
  SEEKER_EMP_SCHEDULE_TABLE_ROW_KEYS,
} from "../constants";
import { spacing } from "../../../../../styles";

const SeekerSchedulerView = ({
  handleBookSlotFormModal,
  schdedulerBookSlotFormik,
  showBookSlotFormModal,
}) => {
  const empAvailabilityList = useSelector(
    JobSeekerSelector.getEmployerAvailabilityList
  );

  return (
    <>
      <div style={{ marginBottom: spacing.m }}>
        <SchdeulerListingTable
          tableTitle="Employer Availability"
          cloumnHeaders={SEEKER_EMP_SCHEDULE_TABLE_COL_CONSTS}
          rowKeys={SEEKER_EMP_SCHEDULE_TABLE_ROW_KEYS}
          rowValues={empAvailabilityList}
          handleButtonClick={handleBookSlotFormModal}
        />
        <SchedulerBookSlotForm
          handleBookSlotFormModal={handleBookSlotFormModal}
          showBookSlotFormModal={showBookSlotFormModal}
          schdedulerBookSlotFormik={schdedulerBookSlotFormik}
        />
      </div>
    </>
  );
};

export default SeekerSchedulerView;
