import { Button, FormHelperText, styled } from '@mui/material'
import { colors, styleUtils } from 'styles';


export const ResendContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${styleUtils.pxToRem("20px")};
  position: relative;
`;

export const FormHelperErrorText = styled(FormHelperText)`
  font-size: 10px;
  margin-top: 0;
  margin-left: 10px;
  position: absolute;
  left: 10px;
  color: ${colors.red};
`;

export const ResendButton = styled(Button)`
  border: none;
  box-shadow: none;
  position: absolute;
`;