import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { AI_GENERATION_TYPES, DONE_CHUNK, fetchAIData } from "../../../utils/aiUtils";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import AIGenerator from "../AIGenerator";
import { RichTextEditor } from "../../atoms";

const AIToolbarSx = {
  width: {
    md: "50%",
  },
  background: "none",
};

const JobDescriptionInput = ({
  formik,
  jobDescriptionFormik,
  handleJobDescriptionChange,
  companyName,
  isJDGenerationComplete,
  setIsJDGenerationComplete,
  isDisabled,
  setActiveStepAddEditJob,
}) => {
  const editorRef = useRef();
  const abortControllerRef = useRef();
  const isAIContentEmptyRef = useRef(true);
  const [generatedHtml, setGeneratedHtml] = useState("");

  useEffect(() => {
    if (generatedHtml) {
      jobDescriptionFormik.setFieldValue("jobDescription", generatedHtml);
      editorRef.current.root.scrollTop = editorRef.current.root.scrollHeight;
    }
    if (generatedHtml?.length === 0) {
      isAIContentEmptyRef.current = true;
    } else {
      isAIContentEmptyRef.current = false;
    }
  }, [generatedHtml]);

  const areRequiredFieldsPresent = () => {
    formik.setFieldTouched("jobTitle", true);
    formik.setFieldTouched("jobLocation", true);
    if (isEmpty(formik.values.jobTitle) || isEmpty(formik.values.jobLocation)) {
      setActiveStepAddEditJob((prev) => prev - 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
    return true;
  };

  const onPressGenerate = useCallback(async () => {
    if (!areRequiredFieldsPresent()) {
      return;
    }
    logEvent(
      isAIContentEmptyRef.current
        ? FirebaseEvents.EMP_CLICKS_GENERATE_JD
        : FirebaseEvents.EMP_CLICKS_REGENERATE_JD,
    );
    setGeneratedHtml("");
    setIsJDGenerationComplete(false);

    abortControllerRef.current = new AbortController();
    await fetchAIData({
      aiGenerationType: AI_GENERATION_TYPES.JOB_DESCRIPTION,
      aiGeneratorInput: {
        companyName,
        jobTitle: formik.values.jobTitle,
        jobLocation: formik.values.jobLocation,
      },
      dataUpdaterWithChunk: updateJobDescWithChunk,
      abortController: abortControllerRef.current,
    });
  }, [formik.values.jobTitle, formik.values.jobLocation]);

  const onPressStopGenerate = useCallback(async () => {
    abortControllerRef.current.abort();
    setIsJDGenerationComplete(true);
  }, []);

  const updateJobDescWithChunk = ({ chunk }) => {
    if (chunk === DONE_CHUNK) {
      setIsJDGenerationComplete(true);
      return;
    }
    setGeneratedHtml((prevText) => `${prevText}${chunk}`);
  };

  const renderAIToolbar = useCallback(() => {
    return (
      <AIGenerator
        onPressGenerate={onPressGenerate}
        onPressStopGenerate={onPressStopGenerate}
        isGenerationComplete={isJDGenerationComplete}
        isAIContentEmpty={isAIContentEmptyRef.current}
        textForGenerate="Generate description using AI"
        toolbarSx={AIToolbarSx}
        isDisabled={isDisabled}
      />
    );
  }, [formik.values.jobTitle, formik.values.jobLocation, isJDGenerationComplete, isDisabled]);

  return (
    <RichTextEditor
      renderAIToolbar={renderAIToolbar}
      editorRef={editorRef}
      placeholder={"Type or paste the description here"}
      handleChange={handleJobDescriptionChange}
      editorHtml={jobDescriptionFormik?.values?.jobDescription}
      isDisabled={isDisabled}
    />
  );
};

JobDescriptionInput.propTypes = {
  companyName: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  handleJobDescriptionChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isJDGenerationComplete: PropTypes.bool.isRequired,
  jobDescriptionFormik: PropTypes.object.isRequired,
  setIsJDGenerationComplete: PropTypes.func.isRequired,
  setActiveStepAddEditJob: PropTypes.func.isRequired,
};

export default JobDescriptionInput;
