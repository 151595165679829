import MapView from "./views/MapView";
import MapViewMobile from "./mobileViews/MapView";

import useIsMobile from "../../../../../../hooks/useIsMobile";

const MapViewContainer = (props) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? MapViewMobile : MapView;

  return <Wrapper {...props} />;
};

export default MapViewContainer;
