export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_FAILED = "SOCIAL_LOGIN_FAILED";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";

export const USER_SETTINGS_UPDATE = "USER_SETTINGS_UPDATE";
export const USER_LOCATION_UPDATING_STATUS = "USER_LOCATION_UPDATING_STATUS";
export const USER_LOCATION_UPDATE = "USER_LOCATION_UPDATE";
export const USER_SEARCH_LOCATION_UPDATE = "USER_SEARCH_LOCATION_UPDATE";
export const USER_DETAILS_UPDATE_SUCCESS = "USER_DETAILS_UPDATE_SUCCESS";
export const USER_DETAILS_UPDATE = "USER_DETAILS_UPDATE";
export const USER_DETAILS_UPDATE_FAIL = "USER_DETAILS_UPDATE_FAIL";

//only to update single detail of user
export const USER_DETAIL_UPDATE = "USER_DETAIL_UPDATE";

export const LOGOUT = "LOGOUT";

export const SET_SIGN_UP_USER = "SET_SIGN_UP_USER";

export const GET_BLOCKED_USERS_LIST_REQUEST = "GET_BLOCKED_USERS_LIST_REQUEST";
export const GET_BLOCKED_USERS_LIST_SUCCESS = "GET_BLOCKED_USERS_LIST_SUCCESS";
export const GET_BLOCKED_USERS_LIST_FAILED = "GET_BLOCKED_USERS_LIST_FAILED";

export const GET_RECENT_UNBLOCKED_USERS_LIST_REQUEST = "GET_RECENT_UNBLOCKED_USERS_LIST_REQUEST";
export const GET_RECENT_UNBLOCKED_USERS_LIST_SUCCESS = "GET_RECENT_UNBLOCKED_USERS_LIST_SUCCESS";
export const GET_RECENT_UNBLOCKED_USERS_LIST_FAILED = "GET_RECENT_UNBLOCKED_USERS_LIST_FAILED";

export const FORGOT_PASSWORD_OTP_REQUEST = "FORGOT_PASSWORD_OTP_REQUEST";
export const FORGOT_PASSWORD_OTP_FAILED = "FORGOT_PASSWORD_OTP_FAILED";
export const FORGOT_PASSWORD_OTP_SUCCESS = "FORGOT_PASSWORD_OTP_SUCCESS";

export const VERIFY_FORGOT_PASSWORD_OTP_REQUEST = "VERIFY_FORGOT_PASSWORD_OTP_REQUEST";
export const VERIFY_FORGOT_PASSWORD_OTP_FAILED = "VERIFY_FORGOT_PASSWORD_OTP_FAILED";
export const VERIFY_FORGOT_PASSWORD_OTP_SUCCESS = "VERIFY_FORGOT_PASSWORD_OTP_SUCCESS";
