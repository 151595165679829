import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginSelector from "../Login/selectors/LoginSelector";

import ChatViewContainer from "./ChatViewContainer";
import { getBrandJobsApi, getPeopleDescriptionApi } from "../../configs/network/apis";
import { hideLoader, showLoader, showNotification } from "../../utils/commonUtils";
import { EMPLOYER } from "../../appConstants";

const ChatContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const uid = searchParams.get("uid") || "";
  const uType = searchParams.get("uType") || "";

  // This is handle navigation for "Chat Now", specially for new chat
  const location = useLocation();
  const [selectedChatUser, setSelectedChatUser] = useState(() => {
    const { photoURL, fullName, age, companyName, designation } = location?.state || {};
    if (uid && uType) {
      return {
        uid,
        userType: uType,
        photoURL,
        fullName,
        age,
        companyName,
        designation,
      };
    }
  });

  // This is for reseting the selected user when we navigate from chat screen to chat list
  useEffect(() => {
    // TO-DO: Need to refactor using react-query
    const getOppositeChatUserData = async () => {
      try {
        showLoader();
        if (uType === EMPLOYER) {
          const { data } = await getBrandJobsApi({
            employerUid: uid,
            status: ["active"],
          });
          setSelectedChatUser(data?.empDetails);
        } else {
          const data = await getPeopleDescriptionApi({
            type: uType,
            uid,
            employer_id: loggedInUser?.uid,
          });
          setSelectedChatUser(data);
        }
      } catch (err) {
        // Error handled already for failed api calls
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong. Please try again",
          },
        });
      } finally {
        hideLoader();
      }
    };
    if (uType && uid) {
      getOppositeChatUserData();
    } else {
      setSelectedChatUser(null);
    }
  }, [uid, uType]);

  const onChangeChatUser = (user) => {
    setSelectedChatUser(user);
    setSearchParams({ uid: user?.userId || user?.uid, uType: user?.userType });
  };

  return (
    <ChatViewContainer selectedChatUser={selectedChatUser} onChangeChatUser={onChangeChatUser} />
  );
};

export default ChatContainer;
