import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import LoginOtpForm from "./LoginOtpForm";
import LoginOtpVerificationForm from "./LoginOtpVerificationForm";
import { setLoginOnSignUp } from "../../../Login/actions/LoginActions";
import * as loginOtpActions from "../../actions/LoginOtpActions";
import { showNotification } from "../../../../utils/commonUtils";
import {
  EMPLOYER_KEY,
  LOGIN_OTP_KEY,
  LOGIN_WITH_OTP_SUCCESS_MSG,
  LOGIN_OTP_RESEND_SUCCESS,
  LOGIN_OTP_RESEND_FAILED,
} from "./loginOtpConstants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { EMPLOYER_PAGE_ROUTE, EMPLOYER_JOBS_PAGE_ROUTE } from "../../../../routes/routeConstants";

const LoginOtpContainer = (props) => {
  const [renderVerifyLogin, setVerifyLoginRender] = useState(false);
  const [otpSrcDetails, setOtpSrcDetails] = useState({});
  const [loginOtpSecs, setLoginOtpSecs] = useState(60);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    loginOtpActionsProp,
    sendLoginOtpSuccess,
    sendLoginOtpFailed,
    verifyLoginOtpSuccess,
    isLoginOtpValid,
  } = props;

  useEffect(() => {
    return () => {
      loginOtpActionsProp.resetLoginUserValidation();
      loginOtpActionsProp.resetLoginOtpContainer();
    };
  }, []);

  const handleVerifyLoginView = (value) => {
    setVerifyLoginRender(value);
  };

  const handleSendLoginOtp = (details) => {
    setOtpSrcDetails(details);
    loginOtpActionsProp.sendLoginOtpRequest(details);
  };

  const handleResendOtp = () => {
    setLoginOtpSecs(60);
    loginOtpActionsProp.sendLoginOtpRequest({
      ...otpSrcDetails,
      onCallback: otpNotification,
    });
  };

  const handleOtpVerification = (otpValue) => {
    const { email, target, phoneNumber, countryCode } = otpSrcDetails;
    const otpKey = target === "phone" ? "phoneOtp" : "emailOtp";
    loginOtpActionsProp.verifyLoginOtpValueRequest({
      target: target,
      email: email,
      [otpKey]: otpValue,
      loginType: LOGIN_OTP_KEY,
      type: EMPLOYER_KEY,
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      onCallback: handleOtpVerificationCallBack,
    });
  };

  const handleOtpVerificationCallBack = (params) => {
    const {
      response: { verifiedStatus, data },
    } = params;
    data.user["type"] = EMPLOYER_KEY;

    if (verifiedStatus) {
      dispatch(setLoginOnSignUp(data));
      navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`);
      logEvent(FirebaseEvents.EMP_PHONE_SIGNIN);
      showNotification({
        alertProps: {
          severity: "success",
          children: LOGIN_WITH_OTP_SUCCESS_MSG,
        },
      });
    }
  };

  const otpNotification = () => {
    const message = sendLoginOtpSuccess
      ? LOGIN_OTP_RESEND_SUCCESS
      : sendLoginOtpFailed
      ? LOGIN_OTP_RESEND_FAILED
      : "";
    const status = sendLoginOtpSuccess ? "success" : sendLoginOtpFailed ? "error" : "";

    if (sendLoginOtpSuccess || sendLoginOtpFailed) {
      showNotification({
        alertProps: {
          severity: status,
          children: message,
        },
      });
    } else {
      //No code here
    }
  };

  let content = renderVerifyLogin ? (
    <LoginOtpVerificationForm
      handleResendOtp={handleResendOtp}
      handleOtpVerification={handleOtpVerification}
      verifyLoginOtpSuccess={verifyLoginOtpSuccess}
      isLoginOtpValid={isLoginOtpValid}
      handleVerifyLoginView={handleVerifyLoginView}
      loginOtpSecs={loginOtpSecs}
      setLoginOtpSecs={setLoginOtpSecs}
      otpSrcDetails={otpSrcDetails}
    />
  ) : (
    <LoginOtpForm
      verifyExistingUser={loginOtpActionsProp.verifyLoginOtpUserRequest}
      handleVerifyLoginView={handleVerifyLoginView}
      handleSendLoginOtp={handleSendLoginOtp}
    />
  );
  return content;
};

const mapStateToProps = ({ loginOtpState }) => ({
  isLoginOtpValid: loginOtpState.isLoginOtpValid,
  userValidation: loginOtpState.userValidation,
  sendLoginOtpFailed: loginOtpState.sendLoginOtpFailed,
  sendLoginOtpSuccess: loginOtpState.sendLoginOtpSuccess,
  verifyLoginOtpSuccess: loginOtpState.verifyLoginOtpSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  loginOtpActionsProp: bindActionCreators(loginOtpActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginOtpContainer);
