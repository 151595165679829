import { takeLatest, put, all, call } from "redux-saga/effects";
import * as signUpActionTypes from "../actions/actionTypes";
import {
  jobSeekerSignUpApi,
  employerVeirfierApi,
  employerSignUpApi,
} from "../../../configs/network/apis";
import { showLoader, hideLoader } from "../../../utils/commonUtils";

/**
 * Job Seeeker SignUp Saga handler
 */
function* handleJobSeekerSignUp(action) {
  const { payload } = action;
  const { onCallback } = payload;
  payload.phoneNumber = payload.phoneNumber.replace(`+${payload.countryCode}`, "");
  delete payload?.tandc;
  try {
    const { data } = yield call(jobSeekerSignUpApi, payload);
    let userData = data;

    yield put({ type: signUpActionTypes.JOB_SEEKER_SIGNUP_SUCCESS, userData });
    onCallback({ type: "jobSeeker", uid: userData?.user?.uid });
  } catch (error) {
    yield put({ type: signUpActionTypes.JOB_SEEKER_SIGNUP_FAILED });
  } finally {
    hideLoader();
  }
}
/**
 * Employer verifier handler
 */
function* handleEmployerVerification(action) {
  const { payload } = action;
  const { onCallback } = payload;
  showLoader();
  try {
    const res = yield call(employerVeirfierApi, payload);
    const response = res.data;
    yield put({ type: signUpActionTypes.EMPLOYER_VERIFY_SUCCESS, response });
    onCallback(response);
  } catch (error) {
    yield put({ type: signUpActionTypes.EMPLOYER_VERIFY_FAILED });
  } finally {
    hideLoader();
  }
}
/**
 * Employer SignUp Saga handler
 */
function* handleEmployerSignUp(action) {
  const { payload } = action;
  payload.phoneNumber = payload.phoneNumber.replace(`+${payload.countryCode}`, "");
  payload.companyName = payload.companyName.trim();
  delete payload?.tandc;
  try {
    const { data } = yield call(employerSignUpApi, payload);
    let userData = data;
    hideLoader();
    yield put({ type: signUpActionTypes.EMPLOYER_SIGNUP_SUCCESS, userData });
  } catch (error) {
    yield put({ type: signUpActionTypes.EMPLOYER_SIGNUP_FAILED });
    hideLoader();
  }
}
function* watchJobSeekerSignUpRequest() {
  yield takeLatest(signUpActionTypes.JOB_SEEKER_SIGNUP_REQUEST, handleJobSeekerSignUp);
}

function* watchEmployerSignUpRequest() {
  yield takeLatest(signUpActionTypes.EMPLOYER_SIGNUP_REQUEST, handleEmployerSignUp);
}
function* watchEmployerVerificationRequest() {
  yield takeLatest(signUpActionTypes.EMPLOYER_VERIFY_REQUEST, handleEmployerVerification);
}
export function* signUpSagas() {
  yield all([
    watchJobSeekerSignUpRequest(),
    watchEmployerSignUpRequest(),
    watchEmployerVerificationRequest(),
  ]);
}
