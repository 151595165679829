import { useSelector } from "react-redux";
import { Card, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Avatar, Icon, NewJob, Text } from "../../atoms";
import useIsMobile from "hooks/useIsMobile";
import ResponsiveMapJobCard from "./ResponsiveMap";

import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { renderImage } from "../../../utils/imageUtils";
import { getDateDifference, getJobPostedDateDiff } from "../../../utils/dateUtils";
import { images } from "../../../assets/images";
import { getFormattedJobLocation, getProcessedJobDetailValue } from "../../../utils/commonUtils";

import LoginSelector from "../../../containers/Login/selectors/LoginSelector";

import "./jobcard.css";
import { colors, spacing, styleUtils, textSizes } from "../../../styles";

const JobCard = ({
  id,
  active,
  item,
  cardStyle,
  size = "m",
  onClickJob,
  onClickBrandLogo,
  isResMapView = false,
  jobActionStatus = "Posted",
}) => {
  const isMobile = useIsMobile();

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const jobPostingDayDiff = getDateDifference(item?.date);
  const isNew = jobPostingDayDiff < 14;

  //CONSTANT VARIABLES RELATED TO THIS FILE;
  const jobCardScaleFactor = size === "m" ? 1 : 0.875;
  const messageForDistanceValue = item?.distance ? `${item?.distance} miles away` : "NA";

  const topContainerHeight = isMobile ? styleUtils.pxToRem("193px") : styleUtils.pxToRem("144px");

  const avatarContainerStyles = {
    width: isMobile ? 43 : 41,
    height: isMobile ? 43 : 41,
    borderRadius: isMobile ? 43 : 41,
    cursor: "pointer",
    marginLeft: `${spacing.xs}`,
  };

  const avatarImageStyles = {
    width: isMobile ? 43 : 41,
    height: isMobile ? 43 : 41,
    borderRadius: 41,
  };

  const onClickCard = (e, isBrandLogo) => {
    e.stopPropagation();
    logEvent(FirebaseEvents.JS_JOB_CARD_CLICK, {
      Is_SignedIn: loggedInUser ? "Signed_In" : "Non_signed_in",
    });
    if (isBrandLogo) {
      onClickBrandLogo(item);
    } else {
      onClickJob(item);
    }
  };

  if (item?.experience[0] === "Beginner") {
    item.experience[0] = "0";
  }

  let jobCard = (
    <Card
      id={id}
      onClick={(e) => onClickCard(e, false)}
      className={active ? "job-list-item-active" : ""}
      sx={{
        transform: `scale(${jobCardScaleFactor})`,
        cursor: "pointer",
        border: `1px solid ${colors.lightBlue}`,
        ...cardStyle,
      }}
    >
      <div>
        <TopContainer
          sx={{
            height: topContainerHeight,
          }}
        >
          <TopContainerContentWrapper isMobile={isMobile}>
            <Avatar
              src={renderImage(item?.brandLogo, "job")}
              onClick={(e) => onClickCard(e, true)}
              name="job_card_avatar"
              sx={avatarContainerStyles}
            >
              <img
                alt={item?.listingPosition}
                src={images.defaults.job}
                style={avatarImageStyles}
              />
            </Avatar>
            <TitleContainer>
              <Row>
                <Text
                  weight="semibold"
                  numberOfLines={2}
                >
                  {item?.listingPosition}
                </Text>
              </Row>
              <Row sx={{ marginTop: spacing.xs }}>
                <Text
                  size="xs"
                  weight="regular"
                  color={colors.brownText}
                  numberOfLines={1}
                >
                  {item?.brandName}
                </Text>
              </Row>
              <Row>
                <Text
                  size="xs"
                  weight="regular"
                  color={colors.brownText}
                  numberOfLines={1}
                >
                  {getFormattedJobLocation(item?.city)}
                </Text>
              </Row>
            </TitleContainer>
          </TopContainerContentWrapper>
          <CircularBox isMobile={isMobile}>
            <Text
              weight="bold"
              size={isMobile ? "m" : "xs"}
              sx={{ color: "#3A5FC7", textAlign: "center" }}
            >
              ${getProcessedJobDetailValue(item?.wage)}/hr
            </Text>
          </CircularBox>
          <IconContainer isMobile={isMobile}>
            <NewJob
              jobStyle={{
                borderRadius: styleUtils.pxToRem("100px"),
                fontSize: textSizes.xxxs,
                width: styleUtils.pxToRem("46.5px"),
              }}
              isNew={isNew}
            />
          </IconContainer>
        </TopContainer>
        <Grid
          container
          direction="column"
          gap={isMobile ? styleUtils.pxToRem("25px") : styleUtils.pxToRem("16px")}
          alignItems="flex-start"
          height={isMobile && styleUtils.pxToRem("200px")}
          sx={{ paddingLeft: spacing.s, paddingTop: spacing.s }}
        >
          <Row>
            <Icon
              name="clock"
              alt={"clock"}
              size={30}
            />
            <InfoBox>
              <Text
                size="xs"
                color="#4E4E4E"
                weight="semibold"
              >
                Working Hours
              </Text>
              <Text
                weight="semibold"
                size="xs"
                numberOfLines={1}
              >
                {`${getProcessedJobDetailValue(item?.hoursListed)} hrs/week`}
              </Text>
            </InfoBox>
          </Row>
          <Row>
            <Icon
              name="experience"
              alt={"experience"}
              size={30}
            />
            <InfoBox>
              <Text
                size="xs"
                color="#4E4E4E"
                weight="semibold"
              >
                Experience Required
              </Text>
              <Text
                weight="semibold"
                size="xs"
                numberOfLines={1}
              >
                {getProcessedJobDetailValue(item?.experience) === "0"
                  ? "Beginner"
                  : `${getProcessedJobDetailValue(item?.experience)} years`}
              </Text>
            </InfoBox>
          </Row>
          <Row>
            <Icon
              name="distance"
              alt={"distance"}
              size={30}
            />
            <InfoBox>
              <Text
                size="xs"
                color="#4E4E4E"
                weight="semibold"
              >
                Distance to job location
              </Text>
              <Text
                weight="semibold"
                size="xs"
                numberOfLines={1}
              >
                {messageForDistanceValue}
              </Text>
            </InfoBox>
          </Row>
        </Grid>
        <Divider
          style={{
            marginLeft: spacing.s,
            marginRight: spacing.s,
            marginTop: isMobile ? "-5px" : spacing.s,
          }}
        />
        <PostedJobTimeBox>
          <Text
            color={colors.brownText}
            style={{
              fontSize: "11px",
            }}
          >
            {jobActionStatus}{" "}
            {getJobPostedDateDiff({ jobPostedDate: item?.createdAt, jobCardType: jobActionStatus })}
          </Text>
        </PostedJobTimeBox>
      </div>
    </Card>
  );

  const content = isResMapView ? (
    <ResponsiveMapJobCard
      id={id}
      item={item}
      onClickCard={onClickCard}
    />
  ) : (
    jobCard
  );

  return content;
};

export default JobCard;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${styleUtils.pxToRem("5px")};
  padding-right: 0;
  padding-bottm: ${spacing.xs};
  padding-top: ${spacing.s};
  background: transparent linear-gradient(68deg, #ffffff00 0%, #3a5fc7 200%) 0% 0% no-repeat
    padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.xs};
`;

const IconContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "156px" : "99.2px")};
  left: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "17px" : "60px")};
  bottom: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "14px" : "20px")};
  margin-top: ${({ isMobile }) => (isMobile ? spacing.xxs : spacing.s)};
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CircularBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.xs};
  background: ${colors.white};
  box-shadow: 0px 6px 10px #647eff2c;
  opacity: 1;
  height: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "82px" : "58px")};
  min-width: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "82px" : "58px")};
  border-radius: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "82px" : "58px")};
`;
const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;

const PostedJobTimeBox = styled("div")`
  padding-left: ${styleUtils.pxToRem("21.5px")};
  padding-top: ${styleUtils.pxToRem("10.5px")};
  padding-bottom: ${styleUtils.pxToRem("11.97px")};
`;

const TopContainerContentWrapper = styled("div")`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;
