import { Card, Modal, Button, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";

import useIsMobile from "../../../hooks/useIsMobile";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import LoginSelector from "containers/Login/selectors/LoginSelector";

import { Icon, Text } from "../../atoms";
import { logEvent } from "../../../configs/firebase";
import { showNotification } from "../../../utils/commonUtils";

import { colors, spacing } from "../../../styles";

const ShareJobDetailsModal = ({ isOpen, jobLink, handleShareJobModal }) => {
  const isMobile = useIsMobile();
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const onClickCopy = () => {
    copy(jobLink);

    logEvent(FirebaseEvents.JS_JOB_DETAILS_CARD_SHARE_CLICK, {
      Is_SignedIn: loggedInUser?.uid ? "Signed_In" : "Non_signed_in",
    });
    handleShareJobModal(false);
    showNotification({
      alertProps: {
        severity: "info",
        children: "Link copied to clipboard.",
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => handleShareJobModal(false)}
      style={{
        backdropFilter: "blur(2px)",
      }}
    >
      <Container sx={{ mt: spacing.xxl }}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "30%",
            p: 2,
          }}
        >
          <Icon
            size={16}
            name="close"
            onClick={() => handleShareJobModal(false)}
            style={{
              float: "right",
              marginLeft: spacing.xs,
              cursor: "pointer",
            }}
          />
          <ContentWrapper>
            <Text
              size="xl"
              weight="medium"
              style={{ textAlign: "left", color: colors.primary }}
            >
              Share the job
            </Text>

            <CopyWrapper>
              <Text
                size={isMobile ? "l" : "xl"}
                sx={{ maxWidth: "80%", overflowWrap: "break-word" }}
              >
                {jobLink}
              </Text>
              <Button
                variant="text"
                onClick={onClickCopy}
                sx={{
                  justifyContent: "flex-end",
                  color: colors.link,
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                  padding: "0",
                  fontSize: "1rem",
                  ":hover": {
                    backgroundColor: "inherit",
                  },
                }}
              >
                COPY
              </Button>
            </CopyWrapper>
          </ContentWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

export default ShareJobDetailsModal;

const ContentWrapper = styled("div")`
  padding: ${spacing.xs};
  margin-top: ${spacing.s};
`;

const CopyWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.l};
  margin-right: ${spacing.s};
  background-color: ${colors.white};
  box-shadow: 0px 5px 15px ${colors.shadow};
  border: 1px solid ${colors.stroke};
  border-radius: 6px;
  padding: ${spacing.s};
  width: 100%;
`;
