import { Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import EmployerSelector from "../../../../../selectors/EmployerSelector";

import { Icon, Text } from "../../../../../../../components/atoms";
import {
  EmployerJobCard,
  EmployerSearchInput,
  EmptyView,
  LoadingJobView,
} from "../../../../../../../components/molecules";
import { colors, spacing, styleUtils } from "../../../../../../../styles";
import EmployerSignUpModal from "../views/EmployerSignUpModal";
import { EmployerConstants } from "../../Constants";

import Filter from "./Filter";

const JobBoard = ({
  employerListing,
  filterSettings,
  onClickJob,
  handleFilter,
  searchTerm,
  onChangeSearchTerm,
  onClickSearch,
  onClickPostNewJob,
  showSignUpModal,
  handleSignUpModal,
  mobileSearchValues,
  fetchPostedJobsSuggestions,
  setSearchValue,
  postedJobsSuggestions,
}) => {
  const isEmployerJobListLoading = useSelector(
    EmployerSelector.isEmployerJobListLoading
  );
  const renderJobs = (item, index) => {
    return (
      <Grid key={index} item xs={12} sm={12}>
        <EmployerJobCard item={item} onClickJob={onClickJob} />
      </Grid>
    );
  };

  return (
    <Container sx={{ mt: spacing.xxl, mb: spacing.xxxxl }}>
      <Text size={34}>
        <Text
          component="span"
          size={34}
          weight="extrabold"
          color={colors.primary}
        >
          Local hiring
        </Text>{" "}
      </Text>
      <Text size={34}>made simple</Text>
      <EmployerSearchInput
        sx={{ mt: spacing.l }}
        searchTermValue={searchTerm}
        onChangeSearchTerm={onChangeSearchTerm}
        onClickSearch={onClickSearch(searchTerm)}
        mobileSearchValues={mobileSearchValues}
        fetchPostedJobsSuggestions={fetchPostedJobsSuggestions}
        setSearchValue={setSearchValue}
        postedJobsSuggestions={postedJobsSuggestions}
      />
      <EmployerSignUpModal
        showSignUpModal={showSignUpModal}
        handleSignUpModal={handleSignUpModal}
      />
      <Grid sx={{ minHeight: "68vh" }} container mt={spacing.xxl}>
        {isEmployerJobListLoading ? (
          <LoadingJobView
            image="fetching-jobs"
            imageHeight="40%"
            title={EmployerConstants.LOADING_TEXT}
          />
        ) : (
          <Grid item xs={12} sm={12}>
            <TitleContainer>
              <Text weight="bold">Jobs Posted</Text>
              <Filter
                handleFilter={handleFilter}
                filterSettings={filterSettings}
              />
            </TitleContainer>
            <Grid
              container
              mt={employerListing.length > 0 ? spacing.xxs : "8rem"}
              spacing={spacing.xl}
            >
              {employerListing.length > 0 ? (
                employerListing.map(renderJobs)
              ) : (
                <EmptyView
                  image="no-recommended-jobs"
                  imageHeight={styleUtils.pxToRem("160px")}
                  title={EmployerConstants.NO_JOBS_POSTED_TITLE}
                  description={EmployerConstants.NO_JOBS_POSTED_DESCRIPTION}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <AddJobContainer onClick={onClickPostNewJob}>
        <Icon
          size={16}
          name="plus-white"
          color={colors.white}
          style={{ marginRight: spacing.xs }}
        />
        <Text component="span" weight="bold" color={colors.white}>
          Post New Job
        </Text>
      </AddJobContainer>
    </Container>
  );
};

export default JobBoard;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AddJobContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 999;
  background-color: ${colors.primary};
  border-radius: 30px;
  bottom: 5rem;
  right: 1rem;
  padding: ${spacing.s} ${spacing.l};
  cursor: pointer;
`;
