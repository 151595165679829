import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useIsMobile from "../../../../hooks/useIsMobile";
import LoginSelector from "../../../Login/selectors/LoginSelector";
import Profile from "./views/Profile";
import ProfileMobileView from "./mobileViews/Profile";
import { showLoader, hideLoader, getBase64, showNotification } from "../../../../utils/commonUtils";
import { userUpdate } from "../../../Login/actions/LoginActions";

import {
  useConnectDisconnectSocialMutation,
  useGetSocialAccountListQuery,
} from "../../../../reactQuery/connectSocialAccounts";

import { getUserInfoGoogleApi } from "../../../../configs/network/apis";
import DeleteAccountModal from "containers/Account/DeleteAccountModal";
import ChangePasswordContainer from "containers/Account/ChangePasswordContainer";
import { JOBSEEKER_PAGE_ROUTE, JOBSEEKER_PROFILE_PAGE_ROUTE } from "routes/routeConstants";

const ProfileContainer = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const { state } = useLocation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const profileType = searchParams.get("type");
  const editType = searchParams.get("editingSrc");

  const onClickMenu = (type) => () => {
    setSearchParams({ type });
  };

  const handleEdit = (type, editingSrc) => {
    setSearchParams({ type, editingSrc });
  };

  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const handleDeleteAccountClose = () => {
    setIsDeleteAccountOpen(false);
  };
  const onClickDeleteAccount = () => {
    setIsDeleteAccountOpen(true);
  };
  const onClickPasswordScreen = (action) => {
    setIsChangingPassword(action);
  };

  const { isSocialAccountsLoading, socialAccountListData } = useGetSocialAccountListQuery();

  const [connectDisconnectSocial, { isLoading }] = useConnectDisconnectSocialMutation();

  useEffect(() => {
    if (isLoading || isSocialAccountsLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading || isSocialAccountsLoading]);

  const onSuccessGoogleLogin = async (response) => {
    try {
      const userInfo = await getUserInfoGoogleApi({
        access_token: response?.access_token,
      });
      const { name, sub: googleId } = userInfo;
      connectDisconnectSocial({
        social_id: googleId,
        account_type: "google",
        status: true,
        name,
      });
    } catch (error) {
      onFailureGoogleLogin();
    }
  };

  const onFailureGoogleLogin = (response) => {
    if (response.details !== "Cookies are not enabled in current environment.")
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong. Please try again later.",
        },
      });
  };

  const onSuccessFacebookLogin = async (response) => {
    connectDisconnectSocial({
      social_id: response.id,
      account_type: "facebook",
      status: true,
      name: response.name,
    });
  };

  const handleNavigateHome = () => {
    navigate({
      pathname: `${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_PROFILE_PAGE_ROUTE}`,
    });
  };

  const onUploadResume = async (file) => {
    showLoader();
    const encodedFile = await getBase64(file);
    dispatch(
      userUpdate({
        resumeUrl: encodedFile,
        resume_filename: file.name,
      }),
    );
    hideLoader();
  };

  const Wrapper = isMobile ? ProfileMobileView : Profile;

  return (
    <>
      <Wrapper
        editType={editType}
        fromBanner={state?.fromBanner}
        handleEdit={handleEdit}
        handleNavigateHome={handleNavigateHome}
        loggedInUser={loggedInUser}
        onClickMenu={onClickMenu}
        onClickPasswordScreen={onClickPasswordScreen}
        onClickDeleteAccount={onClickDeleteAccount}
        onSuccessGoogleLogin={onSuccessGoogleLogin}
        onFailureGoogleLogin={onFailureGoogleLogin}
        onSuccessFacebookLogin={onSuccessFacebookLogin}
        onUploadResume={onUploadResume}
        profileType={profileType}
        socialAccountListData={socialAccountListData}
      />
      {isDeleteAccountOpen && (
        <DeleteAccountModal
          loggedInUser={loggedInUser}
          open={isDeleteAccountOpen}
          handleClose={handleDeleteAccountClose}
        />
      )}
      {isChangingPassword && (
        <ChangePasswordContainer onClickPasswordScreen={onClickPasswordScreen} />
      )}
    </>
  );
};
export default ProfileContainer;
