import { useState } from "react";
import PropTypes from "prop-types";

import { Button, Divider, Grid, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isEmpty, isEqual } from "lodash";

import { Icon, Text, TextInput } from "../../../../components/atoms";
import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";

import { CHANGE_PASSWORD } from "../../constants";

const SetNewPassword = ({ newPasswordFormik, onClickPasswordScreen }) => {
  const [showNewPwdText, setShowNewPwdText] = useState(false);
  const [showConfirmPwdText, setShowConfirmPwdText] = useState(false);

  const passwordMatchMessage = newPasswordFormik.values.newPassword.length >= 6 &&
    isEqual(newPasswordFormik.values.newPassword, newPasswordFormik.values.confirmPassword) && (
      <PasswordMatchWrapper>
        <Icon size={15} name="correct-validation" />
        <Text size="s" sx={{ color: colors.darkGray2, marginLeft: spacing.xxs }}>
          {CHANGE_PASSWORD.PASSWORD_MATCH}
        </Text>
      </PasswordMatchWrapper>
    );

  return (
    <>
      <Grid
        container
        sx={{
          margin: spacing.m,
        }}
      >
        <Grid item xs={12} sm={12} md={6} sx={{ padding: spacing.xs, maxWidth: "100% !important" }}>
          <InputWrapper>
            <InfoLabelText size="l" color="#767676">
              {CHANGE_PASSWORD.NEW_PASSWORD_LABEL}
            </InfoLabelText>
            <PasswordInput
              name="newPassword"
              placeholder={CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER}
              type={showNewPwdText ? "text" : "password"}
              value={newPasswordFormik.values.newPassword}
              onChange={newPasswordFormik.handleChange}
              onBlur={newPasswordFormik.handleBlur}
              endAdornment={
                <InputAdornment position="end" onClick={() => setShowNewPwdText(!showNewPwdText)}>
                  <IconButton>
                    {showNewPwdText ? (
                      <Visibility sx={{ color: "#848484" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#848484" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {newPasswordFormik.touched.newPassword &&
              Boolean(newPasswordFormik.errors.newPassword) && (
                <FormHelperText sx={{ color: colors.red }}>
                  {newPasswordFormik.errors.newPassword}
                </FormHelperText>
              )}
          </InputWrapper>
          <InputWrapper>
            <InfoLabelText size="l" color="#767676">
              {CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL}
            </InfoLabelText>
            <PasswordInput
              name="confirmPassword"
              placeholder={CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER}
              type={showConfirmPwdText ? "text" : "password"}
              value={newPasswordFormik.values.confirmPassword}
              onChange={newPasswordFormik.handleChange}
              handleSubmit={newPasswordFormik.handleSubmit}
              endAdornment={
                <InputAdornment
                  position="end"
                  onClick={() => setShowConfirmPwdText(!showConfirmPwdText)}
                >
                  <IconButton>
                    {showConfirmPwdText ? (
                      <Visibility sx={{ color: "#848484" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#848484" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {newPasswordFormik.touched.confirmPassword &&
              Boolean(newPasswordFormik.errors.confirmPassword) && (
                <FormHelperText sx={{ color: colors.red }}>
                  {newPasswordFormik.errors.newPassword}
                </FormHelperText>
              )}
            {passwordMatchMessage}
          </InputWrapper>
        </Grid>
      </Grid>
      <Divider flexItem sx={{ mx: spacing.s, marginTop: "2rem" }} />
      <ActionFooter>
        <ActionButton
          sx={{
            mr: spacing.s,
          }}
          variant="outlined"
          onClick={() => onClickPasswordScreen(false)}
        >
          {CHANGE_PASSWORD.CANCEL_BUTTON}
        </ActionButton>
        <ActionButton
          disabled={!isEmpty(newPasswordFormik.errors)}
          onClick={newPasswordFormik.handleSubmit}
          variant="contained"
        >
          {CHANGE_PASSWORD.SAVE_BUTTON}
        </ActionButton>
      </ActionFooter>
    </>
  );
};

SetNewPassword.propTypes = {
  newPasswordFormik: PropTypes.object.isRequired,
  onClickPasswordScreen: PropTypes.func.isRequired,
};

export default SetNewPassword;

const InfoLabelText = styled(Text)`
  margin-bottom: ${spacing.s};
`;
const PasswordInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
  height: styleUtils.pxToRem("56px"),
  width: styleUtils.pxToRem("516px"),
  "& .MuiInputBase-input": {
    fontWeight: typography.semibold,
    fontSize: 20,
  },
}));
const ActionFooter = styled("div")`
  display: flex;
  justify-content: center;
  padding: ${spacing.s} ${spacing.l};
`;
const ActionButton = styled(Button)`
  width: ${styleUtils.pxToRem("224px")};
  height: ${styleUtils.pxToRem("48px")};
  font-size: ${textSizes.m};
`;

const InputWrapper = styled("div")`
  margin-bottom: ${spacing.s};
`;

const PasswordMatchWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
`;
