export const OTP_EMAIL_VERIFY_HEADER = "Verify Email";
export const OTP_EMAIL_VERIFY_SUBTEXT =
  "We’ve sent the verification code to email id";
export const OTP_EMAIL_EDIT_LINK = "Edit Email";

export const OTP_PHONE_VERIFY_HEADER = "Verify Phone Number";
export const OTP_PHONE_VERIFY_SUBTEXT = "We’ve sent the verification code to";
export const OTP_PHONE_EDIT_LINK = "Edit Phone Number";

export const OTP_EMAIL_LABEL = "Email Verification";
export const OTP_EMAIL_PLACEHOLDER = "Enter 6 digit code sent to your email";
export const OTP_PHONE_LABEL = "Phone Number verification";
export const OTP_PHONE_PLACEHOLDER =
  "Enter 6 digit code sent to your phone number";
export const OTP_PHONE__RES_LABEL = "Phone No. verification";
export const OTP_PHONE__RES_PLACEHOLDER =
  "Enter 6 digit code sent to your phone no.";

export const OTP_NEXT_BUTTON = "Next";
export const OTP_SUBMIT_BUTTON = "Submit";
export const OTP_INVALID_MSG = "Entered Otp is invalid";
export const RESEND_CODE_TEXT = "Resend code";
export const RESEND_SUCCESS = "Code resent successfully";
export const RESEND_FAILED = "Resend Failed!! Please try again";
export const PHONE_EXISTS_ERROR = "User with this phone no. already exists";

export const EDIT_EMAIL_FORM_HEADER = "Edit Email";
export const EDIT_PHONE_FORM_HEADER = "Edit Phone Number";
export const EDIT_BACK_LINK = "back to verification";
export const EDIT_EMAIL_LABEL = "Email";
export const EDIT_EMAIL_PLACEHOLDER = "Enter the email address";
export const EDIT_PHONE_LABEL = "Phone number";
export const EDIT_SAVE_BTN = "Save";
