export const JOB_SEEKER = "jobSeeker";
export const EMPLOYER = "employer";
export const RESTRICTED_COUNTRY_HEADER = "Oh No ! We are not here yet.";
export const RESTRICTED_COUNTRY_DESCRIPTION =
  "This app is not available in your region yet, and is only available for USA currently.";

export const REPORT_CATEGORIES = [
  { label: "Spam, and phishing", value: "Spam, and phishing" },
  { label: "Harassment, and threats", value: "Harassment, and threats" },
  { label: "Impersonation", value: "Impersonation" },
  { label: "Illegal activities", value: "Illegal activities" },
  { label: "Hate Speech", value: "Hate Speech" },
  { label: "Other", value: "Other" },
];

export const SOCIAL_LINKS = {
  facebook: "https://www.facebook.com/EmployD-110743218109728/",
  instagram: "https://www.instagram.com/getemployd_us/",
  linkedin: "https://www.linkedin.com/company/employd-us/",
  twitter: "https://twitter.com/employd_us",
  tiktok: "https://www.tiktok.com/@employd_us",
};

export const REFERRAL_SHARE = {
  title: "Refer a Friend",
  message:
    "Hey, I'm looking for my next part time job on the EmployD app.\nJoin me to get hired instantly and earn $10 as a joining bonus.",
};
