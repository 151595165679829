import React from "react";
import { styled } from "@mui/material/styles";
import { Text } from "../../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../../styles";
import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import * as DetailsConsts from "./constants";
import "./SeekerSignUpStyle.css";

/**
 * SignUp Details View Component
 * @returns SignUp Details View
 */
const SeekerSignUpDetailsView = () => {
  return (
    <SeekerignUpDetailsContainer>
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        sx={{ marginTop: spacing.s }}
      >
        {DetailsConsts.SEEKER_DETAILS_HEADER}
      </Text>
      <Text
        size="s"
        sx={{
          color: colors.subtextGray,
          marginTop: spacing.xxs,
        }}
      >
        {DetailsConsts.SEEKER_DETAILS_SUBTEXT}
      </Text>
      <List>
        <ListItem sx={{ paddingLeft: 0, marginTop: spacing.xs }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {DetailsConsts.SEEKER_DETAILS_INFO_ONE}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0, marginTop: spacing.xs }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {DetailsConsts.SEEKER_DETAILS_INFO_TWO}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0, marginTop: spacing.xs }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {DetailsConsts.SEEKER_DETAILS_INFO_THREE}
          </Text>
        </ListItem>
        <ListItem sx={{ paddingLeft: 0, marginTop: spacing.xs }}>
          <ListIcon>
            <CircleIcon
              sx={{ color: colors.bulletGray, fontSize: "x-small" }}
            />
          </ListIcon>
          <Text size="s" color={colors.labelBlack}>
            {DetailsConsts.SEEKER_DETAILS_INFO_FOUR}
          </Text>
        </ListItem>
      </List>
    </SeekerignUpDetailsContainer>
  );
};

export default SeekerSignUpDetailsView;

const SeekerignUpDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 74px;
`;

const ListIcon = styled(ListItemIcon)`
  min-width: ${styleUtils.pxToRem("8px")};
  margin-right: ${styleUtils.pxToRem("8px")};
`;
