import * as yup from "yup";
import { ADD_JOB_ERROR_MESSAGES } from "./AddJobConstants";

export const addJobSchema = yup.object({
  jobTitle: yup.string().required(ADD_JOB_ERROR_MESSAGES.JOB_TITLE_ERROR_TEXT),
  jobLocation: yup.string().required(ADD_JOB_ERROR_MESSAGES.JOB_LOCATION_ERROR_TEXT),
  note: yup.string().required(ADD_JOB_ERROR_MESSAGES.NOTE_ERROR_TEXT),
  wageExpectation: yup
    .array()
    .min(1, ADD_JOB_ERROR_MESSAGES.WAGE_ERROR_TEXT)
    .required(ADD_JOB_ERROR_MESSAGES.WAGE_ERROR_TEXT),
  workingHours: yup
    .array()
    .min(1, ADD_JOB_ERROR_MESSAGES.WORKING_HOURS_ERROR_TEXT)
    .required(ADD_JOB_ERROR_MESSAGES.WORKING_HOURS_ERROR_TEXT),
  min_age: yup.string().required(ADD_JOB_ERROR_MESSAGES.MINIMUM_AGE_ERROR_TEXT),
  timeSlot: yup
    .array()
    .min(1, ADD_JOB_ERROR_MESSAGES.TIME_SLOT_ERROR_TEXT)
    .required(ADD_JOB_ERROR_MESSAGES.TIME_SLOT_ERROR_TEXT),
  experience: yup
    .array()
    .min(1, ADD_JOB_ERROR_MESSAGES.EXPERIENCE_ERROR_TEXT)
    .required(ADD_JOB_ERROR_MESSAGES.EXPERIENCE_ERROR_TEXT),
  daysSelected: yup
    .array()
    .min(1, ADD_JOB_ERROR_MESSAGES.WORKING_DAYS_ERROR_TEXT)
    .required(ADD_JOB_ERROR_MESSAGES.WORKING_DAYS_ERROR_TEXT),
});

export const jobDescriptionSchema = yup.object({
  jobDescription: yup
    .string()
    .required(ADD_JOB_ERROR_MESSAGES.DESCRIPTION_ERROR_TEXT)
    .test(
      "is-empty",
      ADD_JOB_ERROR_MESSAGES.DESCRIPTION_ERROR_TEXT,
      (val) => val !== "<p><br></p>",
    ),
});
