import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Accordion, MultiCheckbox, Text } from "../../../../../../../components/atoms";
import { FilterCount } from "components/molecules";
import { spacing, styleUtils, textSizes } from "../../../../../../../styles";

import { hourlyWageFilter, jobTypeFilter, experienceFilter } from "../../FilterConstants";
import useEmployerListingFilter from "../../../../../../../hooks/filters/useEmployerListingFilter";
import { activeFiltersCount, getFilterCount } from "utils/commonUtils";

const Filter = ({ filterSettings, handleFilter }) => {
  const {
    wage,
    status,
    experience,
    onChangeHourlyWages,
    onChangeStatus,
    onChangeExperience,
    onApply,
    onReset,
  } = useEmployerListingFilter(filterSettings, handleFilter);

  const totalFilterCount = activeFiltersCount(filterSettings);

  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <FilterCountContainer>
          <Text size="l" weight="bold" sx={{ ml: spacing.l }}>
            Filter By
          </Text>
          <FilterCount count={totalFilterCount} />
        </FilterCountContainer>
        <Divider sx={{ mt: spacing.s }} />
        <Box sx={{ p: spacing.l, overflow: "auto" }}>
          <Accordion
            title="Hourly Wages"
            headerRightContent={<FilterCount count={getFilterCount(wage)} />}
            content={
              <MultiCheckbox
                selectOptions={hourlyWageFilter}
                prevSelectedOptions={wage}
                handleSelect={onChangeHourlyWages}
              />
            }
          />
          <Divider />
          <Accordion
            title="Job Type"
            headerRightContent={<FilterCount count={getFilterCount(status)} />}
            content={
              <MultiCheckbox
                selectOptions={jobTypeFilter}
                prevSelectedOptions={status}
                handleSelect={onChangeStatus}
              />
            }
          />
          <Divider />
          <Accordion
            title="Experience"
            headerRightContent={<FilterCount count={getFilterCount(experience)} />}
            content={
              <MultiCheckbox
                selectOptions={experienceFilter}
                prevSelectedOptions={experience}
                handleSelect={onChangeExperience}
              />
            }
          />
        </Box>
        <Divider />
        <ButtonRow>
          <Button
            variant="outlined"
            sx={{
              marginRight: spacing.s,
              lineHeight: spacing.s,
              fontSize: textSizes.s,
              height: styleUtils.pxToRem("35px"),
              width: styleUtils.pxToRem("117px"),
            }}
            onClick={onReset}
          >
            Reset Filter
          </Button>
          <Button
            variant="contained"
            sx={{
              lineHeight: spacing.s,
              fontSize: textSizes.s,
              height: styleUtils.pxToRem("35px"),
              width: styleUtils.pxToRem("98px"),
            }}
            onClick={onApply}
          >
            Apply
          </Button>
        </ButtonRow>
      </CardContent>
    </Card>
  );
};

Filter.propTypes = {
  filterSettings: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default Filter;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.m};
`;

const FilterCountContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.m};
`;
