import { Container } from "@mui/material";

import ListView from "./ListView";
import SearchBox from "./SearchBox";
import DetailsView from "../../DetailsView/webViews/DetailsView";
import MapView from "../../MapView/views/MapView";

import { spacing } from "../../../../../../../styles";

const JobBoard = (props) => {
  const {
    clearInput,
    googleInputRef,
    location,
    mobileSearchValues,
    onClickSearch,
    onChangeSearchTerm,
    onPlaceSelected,
    searchTerm,
    showMapView,
    showJobDetailView,
    showCompanyDetailView,
    jobSuggestions,
    fetchJobSuggestions,
    setFieldValue,
  } = props;

  let jobsViewContent = (
    <>
      <SearchBox
        clearInput={clearInput}
        googleInputRef={googleInputRef}
        location={location}
        mobileSearchValues={mobileSearchValues}
        onClickSearch={onClickSearch}
        onChangeSearchTerm={onChangeSearchTerm}
        onPlaceSelected={onPlaceSelected}
        searchTerm={searchTerm}
        jobSuggestions={jobSuggestions}
        setJobSearchValue={setFieldValue}
        fetchJobSuggestions={fetchJobSuggestions}
      />
      {showMapView ? <MapView {...props} /> : <ListView {...props} />}
    </>
  );

  let content =
    showJobDetailView || showCompanyDetailView ? (
      <DetailsView {...props} />
    ) : (
      jobsViewContent
    );

  return <Container sx={{ my: spacing.xxl }}>{content}</Container>;
};

export default JobBoard;
