import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getBlockedUsersList, getUnblockedUsersList } from "../Login/actions/LoginActions";
import LoginSelector from "../Login/selectors/LoginSelector";

import { blockUser, reportUser } from "configs/network/apis";
import { getIsAccountDeleted, hideLoader, showLoader, showNotification } from "utils/commonUtils";
import useIsMobile from "hooks/useIsMobile";
import EditBlockedUsers from "./web/BlockedUsers/EditBlockedUsers";
import EditBlockedUsersMobile from "./mobile/BlockedUsers/EditBlockedUsers";
import { useNavigate } from "react-router-dom";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { JOB_SEEKER } from "appConstants";

const EditBlockedUsersContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState({});

  const blockedUsers = useSelector(LoginSelector.getBlockedUsersList);
  const unBlockedUsers = useSelector(LoginSelector.getUnblockedUsersList);
  const currentUser = useSelector(LoginSelector.getLoggedInUser);
  const [blockModalStatus, setBlockModalStatus] = useState(false);
  const [unBlockModalStatus, setUnblockModalStatus] = useState(false);
  const [isAccountAbsent, setIsAccountAbsent] = useState(false);

  const getUsersList = () => {
    showLoader();
    dispatch(getBlockedUsersList());
    dispatch(getUnblockedUsersList());
    hideLoader();
  };

  useEffect(() => {
    getUsersList();
  }, []);

  const handleBlockUser = async ({ category, message }) => {
    const isAccountDeleted = await getIsAccountDeleted(
      selectedUser?.userId,
      selectedUser?.userType,
    );
    if (isAccountDeleted) {
      setIsAccountAbsent(true);
    } else {
      setIsAccountAbsent(false);
      showLoader();
      await reportUser({
        reportedUserId: selectedUser.userId,
        message: `${category}:${message}`,
      });
      setBlockModalStatus(false);
      dispatch(getBlockedUsersList());
      dispatch(getUnblockedUsersList());
      hideLoader();
      showNotification({
        alertProps: {
          severity: "success",
          children: `Added ${selectedUser.fullName} to blocklist`,
        },
      });
      logEvent(
        currentUser?.userType === JOB_SEEKER
          ? FirebaseEvents.JS_BLOCK_EMPLOYER
          : FirebaseEvents.EMP_BLOCK_JOBSEEKER,
      );
    }
  };

  const handleUnblockUser = async () => {
    const isAccountDeleted = await getIsAccountDeleted(
      selectedUser?.userId,
      selectedUser?.userType,
    );
    if (isAccountDeleted) {
      setIsAccountAbsent(true);
    } else {
      setIsAccountAbsent(false);
      showLoader();
      await blockUser({
        blockedUserId: selectedUser.userId,
        blockedUserType: selectedUser.userType,
      });
      setUnblockModalStatus(false);
      dispatch(getBlockedUsersList());
      dispatch(getUnblockedUsersList());
      hideLoader();
      showNotification({
        alertProps: {
          severity: "success",
          children: `${selectedUser.fullName} unblocked`,
        },
      });
    }
  };

  const onClickChat = async (userDetail) => {
    const isAccountDeleted = await getIsAccountDeleted(
      selectedUser?.userId,
      selectedUser?.userType,
    );
    if (isAccountDeleted) {
      setIsAccountAbsent(true);
    } else {
      setIsAccountAbsent(false);
      showLoader();
      logEvent(
        currentUser?.userType === JOB_SEEKER
          ? FirebaseEvents.JS_JOB_DETAILS_CARD_CHAT_NOW_CLICK
          : FirebaseEvents.EMP_CLICKS_CHAT_NOW,
      );
      navigate(
        {
          pathname: "/chat",
          search: `?uid=${userDetail?.userId}&uType=${userDetail?.userType}`,
        },
        {
          state: {
            photoURL: userDetail.brandLogo,
            companyName: userDetail.companyName,
            designation: userDetail.designation,
            fullName: userDetail.fullName,
            age: userDetail.age,
          },
        },
      );
    }
  };

  const EditBlockedWrapper = isMobile ? EditBlockedUsersMobile : EditBlockedUsers;

  const handleAbsentAccountError = () => {
    setIsAccountAbsent(false);
    setBlockModalStatus(false);
    setUnblockModalStatus(false);
    getUsersList();
  };

  return (
    <EditBlockedWrapper
      blockedUsers={blockedUsers}
      blockModalStatus={blockModalStatus}
      isAccountAbsent={isAccountAbsent}
      onClickChat={onClickChat}
      handleAbsentAccountError={handleAbsentAccountError}
      handleBlockUser={handleBlockUser}
      handleUnblockUser={handleUnblockUser}
      selectedUserType={selectedUser.userType}
      setBlockModalStatus={setBlockModalStatus}
      setSelectedUser={setSelectedUser}
      setUnblockModalStatus={setUnblockModalStatus}
      unBlockedUsers={unBlockedUsers}
      unBlockModalStatus={unBlockModalStatus}
    />
  );
};

export default EditBlockedUsersContainer;
