import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { Text } from "../../../../../components/atoms";

import { images } from "assets/images";
import { spacing, styleUtils, colors } from "styles";

import { ACCOUNT_ACTIVATION_CONSTS } from "containers/SignUp/constants";

const AccountActivatedView = () => {
  return (
    <ViewContainer>
      <img
        src={images.activatedAccountDirectSignup}
        width={239}
        height={143}
        alt="inActive Link"
        style={{ marginTop: spacing.s, marginBottom: spacing.xxl }}
      />

      <Text size="s" color={colors.charcoalGray} sx={{ textAlign: "center" }}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_ALREADY_ACTIVATED_1}
      </Text>
      <Text size="s" color={colors.charcoalGray} sx={{ textAlign: "center" }}>
        {ACCOUNT_ACTIVATION_CONSTS.TEXT_ALREADY_ACTIVATED_2}
      </Text>
      <Link to="/login/employer">
        <ContinueButton variant="contained">
          {ACCOUNT_ACTIVATION_CONSTS.BUTTON_CONTINUE}
        </ContinueButton>
      </Link>
    </ViewContainer>
  );
};

export default AccountActivatedView;

const ViewContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContinueButton = styled(Button)`
  margin-top: ${styleUtils.pxToRem("32px")};
  width: ${styleUtils.pxToRem("266px")};
`;
