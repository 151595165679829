import Card from "@mui/material/Card";

import Icon from "../Icon";

const IconButton = ({
  name,
  size,
  color,
  style,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <Card
      sx={{
        width: "44px",
        height: "44px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        pointerEvents: disabled ? "none" : "auto",
        ...style,
      }}
      onClick={onClick}
    >
      <Icon name={name} size={size} color={color} {...props} />
    </Card>
  );
};

export default IconButton;
