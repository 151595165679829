import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { spacing } from "../../../styles";

const DateTimePickerComponent = ({
  pickerLabel,
  customSlotActionBar = {},
  customSlotLayout = {},
  setDateTimeField,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DateTimePicker
        label={pickerLabel}
        closeOnSelect={false}
        orientation="portrait"
        reduceAnimations={true}
        slots={{
          actionBar: customSlotActionBar,
          layout: customSlotLayout,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiInputBase-root": {
                padding: `0rem ${spacing.m} 0rem ${spacing.m}`,
              },
            }}
          />
        )}
        sx={{
          "& .MuiInputBase-root": {
            maxHeight: spacing.xxxl,
          },
        }}
        onChange={(newValue) => {
          setDateTimeField(newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerComponent;
