import { styled } from "@mui/material/styles";
import React from 'react'
const History = ({question,onClick}:{question:string,onClick:()=>void}) => {
  return (
  <Wrapper  onClick={onClick}>
    <p>{question.substring(0, 15)}...</p>
  </Wrapper>
  )
}

export default History

const Wrapper = styled("div")`
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #1b1b1d;
  &:hover{
    cursor: pointer;
    background-color: #323236;
  }
`