import { useState } from "react";
import { Box, Button, CardContent, Divider, Menu, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  Accordion,
  IconButton,
  MultiCheckbox,
  Text,
} from "../../../../../../../components/atoms";
import { spacing, styleUtils } from "../../../../../../../styles";

import useEmployerJobSeekerListingFilter from "../../../../../../../hooks/filters/useEmployerJobSeekerListingFilter";
import {
  ageFilter,
  experienceFilter,
  hourlyWageFilter,
  hoursPerWeekFilter,
} from "../../FilterConstants";

const Filter = ({ filterSettings, handleFilter, onlyFavourites }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    wage,
    hrsPerWeek,
    experience,
    age,
    interested,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeAge,
    onChangeFavourites,
    onApply,
    onReset,
  } = useEmployerJobSeekerListingFilter(
    filterSettings,
    handleFilter,
    onlyFavourites,
    handleClose
  );

  return (
    <>
      <IconButton name="filter-by" size={26} onClick={onClickFilter} />
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          top: 10,
          "& .MuiList-root": {
            p: 0,
          },
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Text size="l" weight="bold" sx={{ mt: spacing.xs, ml: spacing.l }}>
            Filter By
          </Text>
          <Divider sx={{ mt: spacing.s }} />
          <Box
            sx={{
              p: spacing.l,
              height: "50vh",
              minWidth: 290,
              overflow: "auto",
            }}
          >
            <Accordion
              title="Hourly Wages"
              content={
                <MultiCheckbox
                  selectOptions={hourlyWageFilter}
                  prevSelectedOptions={wage}
                  handleSelect={onChangeHourlyWages}
                />
              }
            />
            <Divider />
            <Accordion
              title="Hours/week"
              content={
                <MultiCheckbox
                  selectOptions={hoursPerWeekFilter}
                  prevSelectedOptions={hrsPerWeek}
                  handleSelect={onChangeHoursPerWeek}
                />
              }
            />
            <Divider />
            <Accordion
              title="Age"
              content={
                <MultiCheckbox
                  multi={false}
                  selectOptions={ageFilter}
                  prevSelectedOptions={age}
                  handleSelect={onChangeAge}
                />
              }
            />
            <Divider />
            <Accordion
              title="Experience"
              content={
                <MultiCheckbox
                  selectOptions={experienceFilter}
                  prevSelectedOptions={experience}
                  handleSelect={onChangeExperience}
                />
              }
            />
            <Divider />
            <Box
              mt={spacing.s}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text weight="semibold">Applied</Text>
              <Switch checked={interested} onChange={onChangeFavourites} />
            </Box>
          </Box>
          <Divider />
          <ButtonRow>
            <Button
              variant="outlined"
              sx={{
                marginRight: spacing.xs,
                height: styleUtils.pxToRem("34px"),
              }}
              onClick={onReset}
            >
              Reset Filter
            </Button>
            <Button
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("34px"),
              }}
              onClick={onApply}
            >
              Apply
            </Button>
          </ButtonRow>
        </CardContent>
      </Menu>
    </>
  );
};

export default Filter;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.m};
`;
