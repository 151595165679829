import "../sentry.config.js";
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from "react-query";

import Navigation from "./routes/navigation";
import { apiErrorModalRef } from "./globalRefs";
import LoaderContainer from "./containers/Common/LoaderContainer";
import NotificationContainer from "./containers/Common/NotificationContainer";
import CountryCheckLoaderContainer from "./containers/CountryCheck/CountryCheckLoaderContainer";
import ApiErrorModal from "./components/organisms/ApiErrorModal";
import { countryCheck } from "./configs/network/apis";
import { ScrollToTop } from "./components/atoms";
import useFacebookLogin from "./hooks/useFacebookLogin";

const AppMain = () => {
  const { isLoading, isError: isCountryCheckError } = useQuery("country-check", countryCheck, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.message,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours,
    retry: false,
  });

  useFacebookLogin(import.meta.env.VITE_APP_FACEBOOK_APP_ID);
  return (
    <>
      {isLoading && <CountryCheckLoaderContainer />}
      {!isLoading && (
        <>
          <Router>
            <ScrollToTop>
              <Navigation isCountryCheckError={isCountryCheckError} />
            </ScrollToTop>
          </Router>
          <LoaderContainer />
          <NotificationContainer />
          <ApiErrorModal ref={apiErrorModalRef} />
        </>
      )}
    </>
  );
};

export default AppMain;
