import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text, TextInput } from "../../../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../../../styles";
import useIsMobile from "../../../../../hooks/useIsMobile";

import * as deleteJobConsts from "../AddJobConstants";

const DeleteModal = (props) => {
  const { showDeleteJob, handleDeleteModal } = props;
  const [jobDeleteReason, setJobDeleteReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [isOtherOptionSelected, setIsOtherOptionSelected] = useState(false);
  const isMobile = useIsMobile();

  const reasonForDeletingJob =
    jobDeleteReason === "Other" ? otherReason : jobDeleteReason;

  useEffect(() => {
    return () => {
      setJobDeleteReason("");
      setOtherReason("");
    };
  }, []);

  const handleChange = (event) => {
    const targetedElement = event.target.name;
    const targetedElementValue = event.target.value;
    const isOtherReasonEntered = targetedElement === "delete_job_reason_other";
    const isOtherReasonSelected = targetedElementValue === "Other";

    if (isOtherReasonEntered) {
      setOtherReason(targetedElementValue);
      if (targetedElementValue.length > 0) {
        setIsOtherOptionSelected(false);
      } else {
        setIsOtherOptionSelected(true);
      }
    } else {
      if (isOtherReasonSelected) {
        setIsOtherOptionSelected(true);
      } else {
        setIsOtherOptionSelected(false);
      }
      setJobDeleteReason(targetedElementValue);
    }
  };

  return (
    <Modal
      open={showDeleteJob}
      onClose={() => handleDeleteModal(false)}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Container sx={{ mt: spacing.xxl }}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile
              ? styleUtils.pxToRem("340px")
              : styleUtils.pxToRem("609px"),
            height: isMobile
              ? styleUtils.pxToRem("590px")
              : styleUtils.pxToRem("540px"),
            p: 2,
          }}
        >
          <ContentWrapper>
            <Text weight="bold" size="l" color={colors.blackText}>
              {deleteJobConsts.DELETE_JOB_MODAL_HEADER}
            </Text>
            <TermsTextWrapper>
              <Text
                weight="medium"
                size="s"
                color={colors.black}
                sx={{ marginBottom: "4%" }}
              >
                {deleteJobConsts.DELETE_JOB_MODAL_SUBTEXT_TWO}
              </Text>
              <FormControl>
                <RadioGroup value={jobDeleteReason} onChange={handleChange}>
                  {deleteJobConsts.DELETING_JOB_REASON_CONSTANTS.map(
                    (reason) => (
                      <FormControlLabel
                        key={reason.option}
                        name={reason.option}
                        value={reason.value}
                        control={<Radio size="small" id={reason.option} />}
                        label={reason.value}
                        sx={{
                          color: colors.blackText,
                          fontSize: "1rem",
                          width: "100%",
                        }}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
              <TextInput
                name="delete_job_reason_other"
                onChange={handleChange}
                placeholder={
                  deleteJobConsts.DELETE_JOB_MODAL_REASON_PLACEHOLDER
                }
                required={isOtherOptionSelected}
                sx={{ marginLeft: "30px", position: "relative" }}
                inputProps={{
                  style: {
                    height: 100,
                    width: styleUtils.pxToRem("480px"),
                    overflow: "auto",
                  },
                }}
                multiline
              />
              <span
                style={{
                  position: "absolute",
                  bottom: isMobile ? "38%" : "40%",
                  right: isMobile ? "35%" : "62%",
                  color: colors.red,
                  fontWeight: "bold",
                  display: isOtherOptionSelected ? "block" : "none",
                  pointerEvents: "none",
                }}
              >
                *
              </span>
            </TermsTextWrapper>
            <Divider orientation="horizontal" sx={{ mt: spacing.xxl }} />
            <ButtonWrapper sx={{ marginTop: isMobile ? "10%" : "5%" }}>
              <Button
                variant="outlined"
                sx={{ width: "30%" }}
                onClick={() => {
                  handleDeleteModal(false);
                }}
              >
                {deleteJobConsts.DELETE_MODAL_BTN_CANCEL}
              </Button>
              <Button
                variant="contained"
                sx={{ width: "30%", marginLeft: "5%" }}
                disabled={reasonForDeletingJob === ""}
                onClick={() => handleDeleteModal(true, reasonForDeletingJob)}
              >
                {deleteJobConsts.DELETE_MODAL_BTN_APPLY}
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

export default DeleteModal;

const ContentWrapper = styled("div")`
  padding: 2%;
  margin-top: 1%;
`;

const TermsTextWrapper = styled("div")`
  margin-top: 1%;
`;

const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;
