import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import LoginSelector from "../Login/selectors/LoginSelector";
import { hideLoader, showLoader } from "../../utils/commonUtils";
import { chatUser } from "../../configs/network/apis";

import useIsMobile from "../../hooks/useIsMobile";
import ChatViewMobile from "./mobileViews/ChatView";
import ChatView from "./views/ChatView";

const ChatViewContainer = ({ selectedChatUser, onChangeChatUser }) => {
  const [isProfileShown, setIsProfileShown] = useState(false);

  const toggleProfile = () => {
    setIsProfileShown((prevIsProfileShown) => !prevIsProfileShown);
  };

  const initialRender = useRef(true);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const [chatUsers, setChatUsers] = useState(null);
  const [blockedUsers, setBlockedUsers] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState(null);

  useEffect(() => {
    if (initialRender.current) {
      showLoader();
    }
    getChatUsers();
    let getChatUsersInterval = setInterval(getChatUsers, 80000);
    return () => {
      clearInterval(getChatUsersInterval);
    };
  }, []);

  // TODO: check if we can replace this whole thing with react query
  const getChatUsers = async () => {
    try {
      const res = await chatUser();
      // TODO: check why is this map needed
      const chatUsers = res?.data.map((user) => {
        if (user) {
          user.type = loggedInUser?.userType === EMPLOYER ? JOB_SEEKER : EMPLOYER;
          return user;
        }
        return null;
      });
      const filteredUsers = chatUsers.filter((user) => user);
      setChatUsers([...filteredUsers, ...blockedUsers]);
      setFilteredUsers([...filteredUsers, ...blockedUsers]); // TODO: This should not be needed here. Also, it resets the search results when chat users are fetched
      if (initialRender.current) {
        hideLoader();
      }
      initialRender.current = false;
    } catch (e) {
      console.log(e);
    }
  };

  const { values, handleChange } = useFormik({
    initialValues: {
      searchTerm: "",
    },
  });

  const onSearch = useCallback(
    debounce((text) => {
      if (isEmpty(text)) {
        setFilteredUsers(chatUsers);
      } else {
        const filteredUsers = chatUsers.filter((user) => {
          return (
            user?.fullName?.toLowerCase().includes(text?.toLowerCase()) ||
            user?.companyName?.toLowerCase().includes(text?.toLowerCase())
          );
        });
        setFilteredUsers(filteredUsers);
      }
    }, 1500),
    [chatUsers],
  );
  //#endregion

  const isMobile = useIsMobile();
  const Wrapper = isMobile ? ChatViewMobile : ChatView;

  return (
    <Wrapper
      chatUsers={chatUsers}
      filteredChatUsers={filteredUsers}
      selectedChatUser={selectedChatUser}
      onChangeChatUser={onChangeChatUser}
      isProfileShown={isProfileShown}
      toggleProfile={toggleProfile}
      searchTerm={values.searchTerm}
      onChangeSearchTerm={handleChange}
      onSearch={onSearch}
      blockedUsers={blockedUsers}
      setBlockedUsers={setBlockedUsers}
      getChatUsers={getChatUsers}
    />
  );
};

ChatViewContainer.propTypes = {
  onChangeChatUser: PropTypes.func,
  selectedChatUser: PropTypes.object,
};

export default ChatViewContainer;
