import { locationDetails } from "configs/network/apis";
import { showNotification } from "./commonUtils";
import { noop } from "lodash";

export const getCurrentLocation = (successCallback, errorCallback = noop) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        successCallback({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        errorCallback(error);
        if (error?.code === GeolocationPositionError.PERMISSION_DENIED) {
          showNotification({
            alertProps: {
              severity: "warning",
              children: "Location permission denied. Please enable it from your browser settings.",
            },
          });
        } else {
          showNotification({
            alertProps: {
              severity: "warning",
              children: "Unable to fetch current location. Please try again.",
            },
          });
        }
      },
    );
  } else {
    //This case is if the browser doesn't support geolocation
    showNotification({
      alertProps: {
        severity: "warning",
        children: "This feature is not supported by your browser",
      },
    });
  }
};

export const getLocationDetails = async (lat, lng) => {
  const currentLocation = await locationDetails({ lat, lng });
  const { locationString1, countryName, locationString2 } = currentLocation.data.location;
  const locationText = locationString2 || locationString1 || countryName;

  return locationText;
};
