import Api from "./axiosConfig";
import * as apiConstants from "./apiConstants";
import axios from "axios";

// Activation APIs
export const activationSocialLoginApi = (params) => {
  return Api.post(apiConstants.ACTIVATION_SOCIAL_LOGIN_API, params);
};

export const getActivationDetailsApi = (params) => {
  return Api.get(`${apiConstants.ACTIVATION_DETAILS_API}/${params.token}`);
};

export const activationPasswordSetupApi = (params) => {
  return Api.put(apiConstants.ACTIVATION_PASSWORD_SETUP_API, params);
};

export const directSignupApi = (params) => {
  return Api.post(apiConstants.DIRECT_SIGNUP_API, params);
};

export const oneTimeLoginApi = (params) => {
  return Api.post(apiConstants.ONE_TIME_LOGIN_API, params);
};

// Chat APIs

export const chatUser = () => {
  return Api.get(apiConstants.CHAT_USER);
};

export const getSelectedUserChat = (params) => {
  return Api.get(`${apiConstants.SELECTED_USER_CHAT}/${params.userId}`);
};

export const updateLastMessage = (params) => {
  return Api.post(apiConstants.UPDATE_LAST_MESSAGE, params);
};

export const markMessageAsRead = (params) => {
  return Api.put(apiConstants.MARK_MESSAGE_AS_READ, params);
};

export const muteUser = (params) => {
  return Api.put(apiConstants.MUTE_USER, params);
};

export const getLatestChatMsg = (params) => {
  return Api.get(`${apiConstants.GET_LATEST_CHAT_MSG}/${params.userId}/${params.offset}`);
};

export const getUnreadChatUsers = () => {
  return Api.get(`${apiConstants.GET_UNREAD_CHAT_USERS}?timestamp=${Date.now()}`);
};

// User APIs

export const userSignInApi = (params) => Api.post(apiConstants.USER_SIGN_IN, params);

export const userDataDetails = (params) =>
  Api.put(apiConstants.USER_DATA_DETAILS, { ...params, fetchSocial: true });

export const sendOtpApi = (params) => Api.post(apiConstants.SEND_OTP_PATH, params);

export const verifyOtpApi = (params) => Api.put(apiConstants.VERIFY_OTP_PATH, params);

export const blockUser = (params) => {
  return Api.put(apiConstants.BLOCK_USER, params);
};

export const reportUser = (params) => {
  return Api.post(apiConstants.REPORT_USER, params);
};

export const updateUserDetails = (params) => Api.put(apiConstants.USER_DETAILS_UPDATE, params);

export const getBlockedUsersList = () => {
  return Api.get(apiConstants.GET_BLOCKED_USERS_LIST);
};

export const getRecentlyUnblockedUsersList = () => {
  return Api.get(apiConstants.GET_RECENTLY_UNBLOCKED_USERS_LIST);
};

export const validateCurrentPasswordApi = (params) => {
  return Api.put(apiConstants.VALIDATE_CURRENT_PASSWORD, params);
};

export const resetPasswordApi = (params) => {
  return Api.put(apiConstants.RESET_PASSWORD, params);
};

export const shareForgotPasswordOtp = (params) => {
  return Api.put(apiConstants.SHARE_FORGOT_PASSWORD_OTP, params);
};

export const verifyForgotPasswordOtp = (params) => {
  return Api.put(apiConstants.VERIFY_FORGOT_PASSWORD_OTP, params);
};

export const resetForgotPasswordApi = (params) => {
  return Api.put(apiConstants.RESET_FORGOT_PASSWORD, params);
};

export const deleteUserAccount = (params) => {
  return Api.post(apiConstants.DELETE_ACCOUNT, params);
};

export const userReviewDetails = (params) => {
  return Api.post(apiConstants.USER_REVIEW, params);
};

export const connectDisconnectSocialApi = (params) => {
  return Api.post(apiConstants.SOCIAL_CONNECT_DISCONNECT, params);
};

export const userExistApi = (params) => {
  return Api.put(apiConstants.USER_EXIST_PATH, params);
};

export const userState = (params) => {
  return Api.post(apiConstants.USER_STATE, params);
};

export const notifyUser = (params) => {
  return Api.post(apiConstants.NOTIFY_USER, params);
};

export const getReferralLink = (params) => Api.get(apiConstants.GET_REFERRAL_LINK, { params });

// Job Seeker APIs

export const jobSeekerSignUpApi = (params) => {
  return Api.post(apiConstants.JOB_SEEKER_SIGN_UP_API, params);
};

export const jobSeekerJobApply = (params) =>
  Api.put(`${apiConstants.JOB_SEEKER_JOB_APPLY}/${params.jobId}`, {});

export const getAppliedJobs = () => {
  return Api.get(apiConstants.JOB_SEEKER_GET_APPLIED_JOBS);
};

export const getBookmarkedJobs = () => {
  return Api.get(`${apiConstants.JOB_SEEKER_BOOKMARK_JOBS}?timestamp=${Date.now()}`);
};

export const postBookmarkJob = (params) => {
  return Api.post(`${apiConstants.JOB_SEEKER_BOOKMARK_JOBS}/${params.jobId}`);
};

export const deleteJobSeekerResume = async () => {
  return Api.delete(apiConstants.JOB_SEEKER_DELETE_RESUME);
};

// Employer APIs

export const employerSignUpApi = (params) => {
  return Api.post(apiConstants.EMPLOYER_SIGN_UP, params);
};

export const employerJobSeekerListingApi = (params) => {
  return Api.put(apiConstants.EMPLOYER_JOB_SEEKER_LIST, params);
};

export const markStarred = (params) =>
  Api.put(`${apiConstants.EMPLOYER_MARK_STARRED}/${params.jobSeekerId}`, {});

export const employerVeirfierApi = (params) => {
  return Api.post(apiConstants.EMPLOYER_VERIFIER_API, params);
};

export const requestResumeApi = async (params) => {
  const res = await Api.put(
    `${apiConstants.EMPLOYER_REQUEST_RESUME}/${params.jobSeekerId}`,
    params,
  );
  return res.data;
};

export const getEmployerActiveLocs = async () => {
  const res = await Api.get(
    `${apiConstants.EMPLOYER_GET_ACTIVE_LOCATIONS}?timestamp=${Date.now()}`,
  );
  return res.data;
};

export const getEmployerSeekersSuggestions = (params) => {
  return Api.put(apiConstants.EMPLOYER_GET_SUGGESTED_SEEKERS, params);
};

export const employerCheckWebsite = (params) => {
  return Api.put(apiConstants.EMPLOYER_CHECK_WEBSITE, params);
};

export const getYelpBusinessReview = (businessAlias) => {
  return Api.get(`${apiConstants.EMPLOYER_YELP_REVIEW_DETAILS}/${businessAlias}`);
};

// --------------------------

export const locationCheck = (params) => {
  return Api.put(apiConstants.LOCATION_CHECK, params);
};

export const locationDetails = (params) => {
  return Api.put(apiConstants.LOCATION_DETAILS, params);
};

export const countryCheck = () => {
  return Api.get(apiConstants.COUNTRY_CHECK);
};

export const getPeopleApi = async (params) => {
  const res = await Api.post(apiConstants.EMPLOYER_JOB_SEEKER_LIST, params);
  return res.data;
};

export const getPeopleDescriptionApi = async (params) => {
  const res = await Api.put(apiConstants.USER_DATA_DETAILS, params);
  return res.data.user;
};

export const markStarredApi = async (params) => {
  const { jobSeekerId } = params;
  const res = await Api.put(`${apiConstants.EMPLOYER_MARK_STARRED}/${jobSeekerId}`);
  return res.data;
};

export const getUserInfoGoogleApi = async (params) => {
  const res = await axios.get(apiConstants.GET_USER_INFO_GOOGLE_API, {
    headers: { Authorization: `Bearer ${params?.access_token}` },
  });
  return res.data;
};

export const getBlogPostsApi = async ({ category, page, pageSize }) => {
  const res = await axios.get(
    "https://public-api.wordpress.com/rest/v1.1/sites/employd.wordpress.com/posts",
    {
      params: {
        fields: "author,content,categories,date,ID,title,excerpt,slug,featured_image",
        number: pageSize,
        category,
        page,
      },
    },
  );
  return res.data;
};

export const getBlogPostBySlugApi = async ({ slug }) => {
  const res = await axios.get(`${import.meta.env.VITE_APP_NODE_API_URL}/blogs/post/${slug}`);
  return res.data;
};

// Job APIs
export const employerAddJobApi = (params) => {
  return Api.post(apiConstants.EMPLOYER_ADD_JOB, params);
};

export const employerJobUpdate = (params, jobId) => {
  return Api.put(`${apiConstants.EMPLOYER_UPDATE_JOB}/${jobId}`, params);
};

export const getBrandJobsApi = (params) => {
  return Api.put(apiConstants.GET_BRAND_JOBS, params);
};

export const jobDetails = (jobId, params) => {
  return Api.get(`${apiConstants.JOB_DETAILS}/${jobId}`, {
    params,
  });
};

export const jobSeekerListingApi = (params) => {
  return Api.post(apiConstants.JOB_SEEKER_JOB_LISTING, params);
};

export const deleteEmployerJobPostApi = (params, jobId) => {
  return Api.put(`${apiConstants.EMPLOYER_JOB_POST_DELETE}/${jobId}`, params);
};

export const getBanners = (params) => {
  return Api.put(apiConstants.JOBS_BANNER, params);
};

export const getReferralBanner = (params) => {
  return Api.put(apiConstants.REFERRAL_BANNER, params);
};

export const getSeekersJobsSuggestions = (params) => {
  return Api.put(apiConstants.JOB_SEEKER_SUGGESTED_JOBS, params);
};

export const getEmployersJobsSuggestions = (params) => {
  return Api.put(apiConstants.EMPLOYER_SUGGESTED_JOBS, params);
};

//Scheduler
export const schedulerEmployerDetails = (params) => {
  return Api.put(apiConstants.SCHEDULER_EMPLOYER_DETAILS, params);
};

export const schedulerRegisterUser = (params) => {
  return Api.post(apiConstants.SCHEDULER_REGISTER, params);
};

export const schedulerCreateAvailability = (params) => {
  return Api.post(apiConstants.SCHEDULER_AVAILABILITY_CREATE, params);
};

export const schedulerGetAvailabilityList = (params) => {
  return Api.get(
    `${apiConstants.SCHEDULER_AVAILABILITY_LIST}?uid=${params.userId}&&fetch=${params.fetch}`,
  );
};

export const schedulerGetMeetingList = (params) => {
  return Api.get(`${apiConstants.SCHEDULER_UPCOMING_MEETING_LIST}/${params.userId}`);
};

export const schedulerGetShareLink = (params) => {
  return Api.get(`${apiConstants.SCHEDULER_SHARE_LINK}/${params.userId}`);
};

export const schedulerBookSlot = (params) => {
  return Api.post(apiConstants.SCHEDULER_BOOK_SLOT, params);
};
