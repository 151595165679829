import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import LoginSelector from "../Login/selectors/LoginSelector";
import { searchLocationUpdate } from "../Login/actions/LoginActions";

import useIsMobile from "../../hooks/useIsMobile";
import Search from "./views/Search";
import SearchMobile from "./mobileViews/Search";
import { getSeekersJobsSuggestions } from "configs/network/apis";

const SearchWrapper = () => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? SearchMobile : Search;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userPrevLocation = useSelector(LoginSelector.getLoggedInUserCurrentLocation);

  const userLocation = useMemo(
    () => ({
      lat: userPrevLocation?.lat,
      lng: userPrevLocation?.lng,
      locationName: userPrevLocation?.locationName,
    }),
    [userPrevLocation?.lat, userPrevLocation?.lng, userPrevLocation?.locationName],
  );

  const searchLocation = useSelector(LoginSelector.getSearchLocation);
  const location = {
    lat: searchLocation?.lat || userLocation?.lat,
    lng: searchLocation?.lng || userLocation?.lng,
    locationName: searchLocation?.locationName || userLocation?.locationName,
  };

  const googleInputRef = useRef();
  const [jobSuggestions, setJobSuggestions] = useState([]);

  useEffect(() => {
    googleInputRef.current?.changeValue(location.locationName || null);
  }, []);

  useEffect(() => {
    googleInputRef.current?.changeValue(location.locationName || null);

    setFieldValue("lat", location?.lat);
    setFieldValue("lng", location?.lng);
    setFieldValue("locationName", location?.locationName);
  }, [userLocation]);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      searchTerm: "",
      lat: location?.lat,
      lng: location?.lng,
      locationName: location?.locationName,
    },
  });
  const mobileSearchValues = {
    term: values?.searchTerm,
    location: values?.locationName,
  };

  const fetchJobSuggestions = async (value) => {
    if (!value) {
      setJobSuggestions([]);
      return;
    }
    try {
      const res = await getSeekersJobsSuggestions({
        searchTerm: value,
      });
      setJobSuggestions(res.data);
    } catch (e) {
      setJobSuggestions([]);
    }
  };

  const onClickSearch = (searchText) => () => {
    let searchQuery;
    if (!isEmpty(searchText)) {
      searchQuery = `?searchTerm=${searchText}`;
    }
    dispatch(
      searchLocationUpdate({
        lat: values.lat,
        lng: values.lng,
        locationName: values.locationName,
      }),
    );
    navigate({
      pathname: "/jobSeeker/explorejobs",
      search: searchQuery,
    });
  };

  const onPlaceSelected = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const address = place.inputValue;
    setFieldValue("lat", lat);
    setFieldValue("lng", lng);
    setFieldValue("locationName", address);
  };

  // Reset the Lat Lng and value of the field.
  const clearInput = () => {
    googleInputRef.current?.changeValue(null);
    setFieldValue("lat", null);
    setFieldValue("lng", null);
    setFieldValue("locationName", null);
  };

  return (
    <Wrapper
      searchTermValue={values.searchTerm}
      mobileSearchValues={mobileSearchValues}
      onChangeSearchTerm={handleChange}
      googleInputRef={googleInputRef}
      onGooglePlaceSelected={onPlaceSelected}
      onClearGoogleInput={clearInput}
      onClickSearch={onClickSearch}
      location={location}
      fetchJobSuggestions={fetchJobSuggestions}
      jobSuggestions={jobSuggestions}
      setJobSearchValue={setFieldValue}
    />
  );
};

export default SearchWrapper;
