import { useState } from "react";
import { Box, Button, CardContent, Divider, Menu } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  IconButton,
  Text,
  Accordion,
  MultiCheckbox,
} from "../../../../../../../components/atoms";
import { spacing, styleUtils } from "../../../../../../../styles";

import {
  experienceFilter,
  hourlyWageFilter,
  jobTypeFilter,
} from "../../FilterConstants";
import useEmployerListingFilter from "../../../../../../../hooks/filters/useEmployerListingFilter";

const Filter = ({ filterSettings, handleFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    wage,
    status,
    experience,
    onChangeHourlyWages,
    onChangeStatus,
    onChangeExperience,
    onApply,
    onReset,
  } = useEmployerListingFilter(filterSettings, handleFilter, handleClose);

  return (
    <>
      <IconButton
        name="filter-by"
        size={26}
        style={{ marginLeft: spacing.s }}
        onClick={onClickFilter}
      />
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          top: 10,
          "& .MuiList-root": {
            p: 0,
          },
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Text size="l" weight="bold" sx={{ mt: spacing.xs, ml: spacing.l }}>
            Filter By
          </Text>
          <Divider sx={{ mt: spacing.s }} />
          <Box
            sx={{
              p: spacing.l,
              height: "50vh",
              minWidth: 290,
              overflow: "auto",
            }}
          >
            <Accordion
              title="Hourly Wages"
              content={
                <MultiCheckbox
                  selectOptions={hourlyWageFilter}
                  prevSelectedOptions={wage}
                  handleSelect={onChangeHourlyWages}
                />
              }
            />
            <Divider />
            <Accordion
              title="Job Type"
              content={
                <MultiCheckbox
                  selectOptions={jobTypeFilter}
                  prevSelectedOptions={status}
                  handleSelect={onChangeStatus}
                />
              }
            />
            <Divider />
            <Accordion
              title="Experience"
              content={
                <MultiCheckbox
                  selectOptions={experienceFilter}
                  prevSelectedOptions={experience}
                  handleSelect={onChangeExperience}
                />
              }
            />
          </Box>
          <Divider />
          <ButtonRow>
            <Button
              variant="outlined"
              sx={{
                marginRight: spacing.xs,
                height: styleUtils.pxToRem("34px"),
              }}
              onClick={onReset}
            >
              Reset Filter
            </Button>
            <Button
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("34px"),
              }}
              onClick={onApply}
            >
              Apply
            </Button>
          </ButtonRow>
        </CardContent>
      </Menu>
    </>
  );
};

export default Filter;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.m};
`;
