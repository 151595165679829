//User
export const USER_SIGN_IN = "/user/signin"; // node api
export const USER_DATA_DETAILS = "/user/details"; // node api
export const USER_DETAILS_UPDATE = "/user/update"; // node api
export const SEND_OTP_PATH = "/user/send-otp"; // node api
export const VERIFY_OTP_PATH = "/user/verify-otp"; // node api
export const VALIDATE_CURRENT_PASSWORD = "/user/validate-password"; // node api
export const RESET_PASSWORD = "/user/password-reset"; // node api
export const SHARE_FORGOT_PASSWORD_OTP = "user/forgot-password-code"; // node api
export const VERIFY_FORGOT_PASSWORD_OTP = "user/verify-forgot-password-code"; // node api
export const RESET_FORGOT_PASSWORD = "user/forgot-reset-password"; // node api
export const DELETE_ACCOUNT = "/user/delete"; // node api
export const USER_REVIEW = "/user/rating-review"; // node api
export const USER_EXIST_PATH = "/user/user-exist"; // node api
export const NOTIFY_USER = "/user/notify-me"; // node api
export const USER_STATE = "/user/state"; // node api
export const SOCIAL_CONNECT_DISCONNECT = "/user/social-connect"; // node api
export const GET_BLOCKED_USERS_LIST = "/user/blocked-users"; // node api
export const GET_RECENTLY_UNBLOCKED_USERS_LIST = "/user/unblocked-users"; // node api
export const BLOCK_USER = "/user/block-unblock"; // node api
export const REPORT_USER = "/user/report"; // node api
export const GET_REFERRAL_LINK = "/user/referral-link"; // node api

//JobSeeker
export const JOB_SEEKER_SIGN_UP_API = "/job-seeker/signup"; // node api
export const JOB_SEEKER_JOB_APPLY = "/job-seeker/apply/"; // node api
export const JOB_SEEKER_GET_APPLIED_JOBS = "/job-seeker/applied"; // node api
export const JOB_SEEKER_BOOKMARK_JOBS = "/job-seeker/bookmark"; // node api
export const JOB_SEEKER_DELETE_RESUME = "/job-seeker/resume"; // node api

//employer
export const EMPLOYER_VERIFIER_API = "/employer/check-employer"; // node api
export const EMPLOYER_SIGN_UP = "/employer/signup"; // node api
export const EMPLOYER_JOB_SEEKER_LIST = "/employer/seeker-list"; // node api
export const EMPLOYER_MARK_STARRED = "/employer/starred-seeker"; // node api
export const EMPLOYER_CHECK_WEBSITE = "/employer/is-valid-url"; // node api
export const EMPLOYER_REQUEST_RESUME = "/employer/request-resume"; // node api
export const EMPLOYER_GET_ACTIVE_LOCATIONS = "/employer/job-locations"; // node api
export const EMPLOYER_GET_SUGGESTED_SEEKERS = "/employer/seeker-suggestion"; // node api
export const EMPLOYER_YELP_REVIEW_DETAILS = "/employer/yelp-business-details"; // node api

//Scheduler
export const SCHEDULER_EMPLOYER_DETAILS = "/scheduler/employer"; // node api
export const SCHEDULER_REGISTER = "/scheduler/register"; // node api
export const SCHEDULER_AVAILABILITY_CREATE = "/scheduler/availability"; // node api
export const SCHEDULER_AVAILABILITY_LIST = "/scheduler/availability"; // node api
export const SCHEDULER_UPCOMING_MEETING_LIST = "/scheduler/meetings"; // node api
export const SCHEDULER_SHARE_LINK = "/scheduler/share-link"; // node api
export const SCHEDULER_BOOK_SLOT = "/scheduler/booked"; // node api

//Chat Api's
export const CHAT_USER = "/chat/users"; // node api
export const SELECTED_USER_CHAT = "/chat"; // node api
export const UPDATE_LAST_MESSAGE = "/chat/last-message"; // node api
export const MARK_MESSAGE_AS_READ = "/chat/marked-read"; // node api
export const MUTE_USER = "/chat/mute-unmute"; // node api
export const GET_LATEST_CHAT_MSG = "/chat/latest-messages"; // node api
export const GET_UNREAD_CHAT_USERS = "/chat/unread-users"; // node api

//Jobs
export const GET_BRAND_JOBS = "/job/brand-jobs"; // node api
export const JOB_DETAILS = "/job"; // node api
export const EMPLOYER_ADD_JOB = "/job/create"; // node api
export const EMPLOYER_UPDATE_JOB = "/job/update"; // node api
export const EMPLOYER_JOB_POST_DELETE = "/job/delete"; // node api
export const JOB_SEEKER_JOB_LISTING = "/job/listing"; // node api
export const JOB_SEEKER_SUGGESTED_JOBS = "/job/suggestion-list"; // node api
export const EMPLOYER_SUGGESTED_JOBS = "/job/suggestion-list/employer"; // node api

//Settings
export const JOBS_BANNER = "/setting/banner/banner"; // node api
export const REFERRAL_BANNER = "/setting/banner/referral"; // node api

//Location
export const LOCATION_CHECK = "/location/check-boundary"; // node api
export const LOCATION_DETAILS = "/location/details"; // node api
export const COUNTRY_CHECK = "/location/check-country"; // node api

//direct signup
export const ACTIVATION_DETAILS_API = "/activation/details"; // node api
export const ACTIVATION_PASSWORD_SETUP_API = "/activation/password-setup"; // node api
export const ACTIVATION_SOCIAL_LOGIN_API = "/activation/social-activation"; // node api
export const DIRECT_SIGNUP_API = "/employer/direct-signup"; // node api
export const ONE_TIME_LOGIN_API = "/employer/one-time-login"; // node api

//Other's
export const GET_USER_INFO_GOOGLE_API = "https://www.googleapis.com/oauth2/v3/userinfo";

//Chat Bot
export const GET_ASK_HR_CHAT_RES = "askhr"; // node api
