import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Divider, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Text, Accordion, MultiCheckbox } from "../../../../../../../components/atoms";
import { FilterCount } from "components/molecules";

import { spacing, styleUtils, textSizes } from "../../../../../../../styles";

import {
  ageFilter,
  experienceFilter,
  hourlyWageFilter,
  hoursPerWeekFilter,
} from "../../FilterConstants";
import useEmployerJobSeekerListingFilter from "../../../../../../../hooks/filters/useEmployerJobSeekerListingFilter";
import { activeFiltersCount, getFilterCount } from "utils/commonUtils";

const Filter = ({ filterSettings, handleFilter, onlyFavourites }) => {
  const {
    wage,
    hrsPerWeek,
    experience,
    age,
    interested,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeAge,
    onChangeFavourites,
    onApply,
    onReset,
  } = useEmployerJobSeekerListingFilter(filterSettings, handleFilter, onlyFavourites);
  const totalFilterCount = activeFiltersCount(filterSettings);
  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <FilterCountContainer>
          <Text size="l" weight="bold" sx={{ ml: spacing.l }}>
            Filter By
          </Text>
          <FilterCount count={filterSettings?.age > 0 ? totalFilterCount + 1 : totalFilterCount} />
        </FilterCountContainer>
        <Divider sx={{ mt: spacing.s }} />
        <Box sx={{ p: spacing.l, overflow: "auto" }}>
          <Accordion
            title="Hourly Wages"
            headerRightContent={<FilterCount count={getFilterCount(wage)} />}
            content={
              <MultiCheckbox
                selectOptions={hourlyWageFilter}
                prevSelectedOptions={wage}
                handleSelect={onChangeHourlyWages}
              />
            }
          />
          <Divider />
          <Accordion
            title="Hours/week"
            headerRightContent={<FilterCount count={getFilterCount(hrsPerWeek)} />}
            content={
              <MultiCheckbox
                selectOptions={hoursPerWeekFilter}
                prevSelectedOptions={hrsPerWeek}
                handleSelect={onChangeHoursPerWeek}
              />
            }
          />
          <Divider />
          <Accordion
            title="Age"
            headerRightContent={<FilterCount count={age ? 1 : null} />}
            content={
              <MultiCheckbox
                multi={false}
                selectOptions={ageFilter}
                prevSelectedOptions={age}
                handleSelect={onChangeAge}
              />
            }
          />
          <Divider />
          <Accordion
            title="Experience"
            headerRightContent={<FilterCount count={getFilterCount(experience)} />}
            content={
              <MultiCheckbox
                selectOptions={experienceFilter}
                prevSelectedOptions={experience}
                handleSelect={onChangeExperience}
              />
            }
          />
          <Divider />
          <Box mt={spacing.s}>
            <Text weight="semibold">
              Applied{" "}
              <Switch
                checked={interested}
                onChange={onChangeFavourites}
                sx={{ marginLeft: styleUtils.pxToRem("99px") }}
              />
            </Text>
          </Box>
        </Box>
        <Divider />
        <ButtonRow>
          <Button
            variant="outlined"
            sx={{
              marginRight: spacing.s,
              lineHeight: spacing.s,
              fontSize: textSizes.s,
              height: styleUtils.pxToRem("35px"),
              width: styleUtils.pxToRem("117px"),
            }}
            onClick={onReset}
          >
            Reset Filter
          </Button>
          <Button
            variant="contained"
            sx={{
              lineHeight: spacing.s,
              fontSize: textSizes.s,
              height: styleUtils.pxToRem("35px"),
              width: styleUtils.pxToRem("98px"),
            }}
            onClick={onApply}
          >
            Apply
          </Button>
        </ButtonRow>
      </CardContent>
    </Card>
  );
};
Filter.propTypes = {
  filterSettings: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  onlyFavourites: PropTypes.bool,
};
export default Filter;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.m};
`;

const FilterCountContainer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.m};
`;
