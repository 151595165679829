import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@mui/material/MobileStepper";
import { colors } from "../../../styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carousel = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <AutoPlaySwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {children.map((item) => item)}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={children.length}
        position="static"
        activeStep={activeStep}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          border: 0,
          boxShadow: "0px 0px transparent",
          "& .MuiMobileStepper-dot": {
            width: {
              xs: 8,
              sm: 8,
              md: 12,
            },
            height: {
              xs: 8,
              sm: 8,
              md: 12,
            },
            borderRadius: {
              xs: 4,
              sm: 4,
              md: 6,
            },
            backgroundColor: colors.stroke,
            transition: (theme) =>
              theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          },
          "& .MuiMobileStepper-dotActive": {
            width: {
              xs: 20,
              sm: 20,
              md: 28,
            },
            height: {
              xs: 8,
              sm: 8,
              md: 12,
            },
            borderRadius: {
              xs: 4,
              sm: 4,
              md: 6,
            },
            backgroundColor: colors.primary,
            transition: (theme) =>
              theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          },
        }}
      />
    </>
  );
};

export default Carousel;
