export const EMAIL_LOGIN_CONSTS = {
  LABEL_EMAIL: "Email",
  PLACEHOLDER_EMAIL: "Enter email address",
  LABEL_PASSWORD: "Password",
  PLACEHOLDER_PASSWORD: "Enter password",
  EMAIL_SIGNIN_ERROR: "Email or Password incorrect",
  LABEL_FORGOT_PASSWORD: "Forgot password?",
  BUTTON_NEXT: "Next",
};

export const JOB_SEEKER_REGISTRATION_CONSTS = {
  SEEKER_DETAILS_HEADER: "JobSeeker Account",
  SEEKER_DETAILS_SUBTEXT: "On registering you can",
  SEEKER_DETAILS_INFO_ONE: "Build your profile and let recruiters find you",
  SEEKER_DETAILS_INFO_TWO: "Search for jobs that match your interests",
  SEEKER_DETAILS_INFO_THREE: "Apply for jobs in one-click",
  SEEKER_DETAILS_INFO_FOUR:
    "Chat directly with employers and get hired in no time",
};

export const EMPLOYER_REGISTRATION_CONSTS = {
  DETAILS_HEADER: "Employer Account",
  DETAILS_SUBTEXT: "On registering you can",
  EMPLOYER_DETAILS_INFO_ONE: "Post your job free",
  EMPLOYER_DETAILS_INFO_TWO: "Search for nearby job seekers",
  EMPLOYER_DETAILS_INFO_THREE: "Reach out to them directly",
  EMPLOYER_DETAILS_INFO_FOUR: "Fill open positions quickly",
};
