import PropTypes from "prop-types";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Text from "../Text";
import { spacing } from "../../../styles";

const Accordion = ({ title, content, weight = "semibold", sx, headerRightContent }) => {
  return (
    <MuiAccordion
      disableGutters
      sx={{
        "&::before": {
          opacity: 0,
        },
        ...sx,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Text weight={weight}>{title}</Text>
        {headerRightContent}
      </AccordionSummary>
      <AccordionDetails sx={{ mb: spacing.s }}>{content}</AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  weight: PropTypes.string.isRequired,
  sx: PropTypes.object,
  headerRightContent: PropTypes.any,
};

export default Accordion;
