import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Card, Container, Grid, InputAdornment } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import NoChatSelected from "../NoChatSelectedView";
import EmptyChatView from "../EmptyChatView";
import ChatBox from "../ChatBox";
import ChatList from "../ChatList";
import { Icon, TextInput, Text } from "../../../components/atoms";
import {
  ChatEmployerDetailCard,
  ChatJobSeekerDetailCard,
  ChatListCard,
} from "../../../components/molecules";

import LoginSelector from "../../Login/selectors/LoginSelector";

import { EMPLOYER, JOB_SEEKER } from "../../../appConstants";
import { ChatConstants } from "../Constants";
import { spacing } from "../../../styles";

const ChatView = ({
  selectedChatUser,
  onChangeChatUser,
  chatUsers,
  filteredChatUsers,
  isProfileShown,
  toggleProfile,
  searchTerm,
  onChangeSearchTerm,
  onSearch,
  onSend,
  blockedUsers,
  setBlockedUsers,
  getChatUsers,
}) => {
  const initialRender = useRef(true);
  //#region - Debounce Search
  // TODO: Refactor

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const isEmployer = loggedInUser?.userType === EMPLOYER || false;

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);

  const PLACEHOLDER_FOR_SEARCH_INPUT = isEmployer
    ? ChatConstants.SEARCH_INPUT_PLACEHOLDER_EMPLOYER
    : ChatConstants.SEARCH_INPUT_PLACEHOLDER_JOBSEEKER;

  const content =
    chatUsers?.length > 0 || selectedChatUser ? (
      <>
        <Grid item md={3.4}>
          <Card
            sx={{
              height: "85vh",
              pl: spacing.s,
              pt: spacing.s,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row>
              <TextInput
                name="searchTerm"
                value={searchTerm}
                placeholder={PLACEHOLDER_FOR_SEARCH_INPUT}
                sx={{ height: "44px" }}
                endAdornment={
                  <InputAdornment sx={{ marginLeft: spacing.xs }} position="end">
                    <Icon name="search" />
                  </InputAdornment>
                }
                onChange={onChangeSearchTerm}
              />
            </Row>
            {/* if user has searched for specific chat and it does not exist */}
            {filteredChatUsers?.length === 0 && !isEmpty(searchTerm) && (
              <Text style={{ textAlign: "center", marginTop: "100%" }}>
                {ChatConstants.NO_SEARCH_RESULT}
              </Text>
            )}
            {!chatUsers?.length && selectedChatUser && (
              <ChatListCard
                item={selectedChatUser}
                active={true}
                key={selectedChatUser?.userId}
                cardStyle={{
                  marginBottom: spacing.xl,
                  marginRight: spacing.s,
                }}
              />
            )}

            {filteredChatUsers && filteredChatUsers.length > 0 && (
              <ChatList
                chatUsers={filteredChatUsers}
                selectedChatUser={selectedChatUser}
                onChangeChatUser={onChangeChatUser}
              />
            )}
          </Card>
        </Grid>
        {/* if chat user is selected so we show the open chat for that user */}
        {selectedChatUser ? (
          <>
            <Grid
              item
              md={isProfileShown ? 7.4 : 5.2}
              sx={{
                transition: (theme) =>
                  theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
              }}
            >
              <Card
                sx={{
                  height: "85vh",
                  py: spacing.s,
                }}
              >
                <ChatBox
                  selectedChatUser={selectedChatUser}
                  onSend={onSend}
                  blockedUsers={blockedUsers}
                  setBlockedUsers={setBlockedUsers}
                  getChatUsers={getChatUsers}
                  key={selectedChatUser?.userId || selectedChatUser?.uid} // So that React treats it as a new component and resets the state
                />
              </Card>
            </Grid>
            <Grid
              item
              md={isProfileShown ? 1.2 : 3.4}
              sx={{
                position: "relative",
                transition: (theme) =>
                  theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
              }}
            >
              <ToggleProfileWrapper onClick={toggleProfile} isProfileShown={isProfileShown}>
                <Icon name="collapse-card-solid" size={26} style={{ cursor: "pointer" }} />
              </ToggleProfileWrapper>
              <Card sx={{ height: "85vh", py: spacing.s }}>
                {selectedChatUser?.userType === EMPLOYER && (
                  <ChatEmployerDetailCard user={selectedChatUser} isProfileShown={isProfileShown} />
                )}
                {selectedChatUser?.userType === JOB_SEEKER && (
                  <ChatJobSeekerDetailCard
                    user={selectedChatUser}
                    isProfileShown={isProfileShown}
                  />
                )}
              </Card>
            </Grid>
          </>
        ) : (
          <Grid item md={8.5}>
            {/* else, we show no chat selected view */}
            <Card
              sx={{
                height: "85vh",
                py: spacing.s,
              }}
            >
              <NoChatSelected loggedInUser={loggedInUser} />
            </Card>
          </Grid>
        )}
      </>
    ) : (
      <Grid item md={12}>
        <Card
          sx={{
            height: "85vh",
            py: spacing.s,
          }}
        >
          <div>{chatUsers?.length === 0 && <EmptyChatView loggedInUser={loggedInUser} />}</div>
        </Card>
      </Grid>
    );

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Grid container columnSpacing={spacing.xl}>
        {content}
      </Grid>
    </Container>
  );
};

ChatView.propTypes = {
  blockedUsers: PropTypes.arrayOf(PropTypes.string),
  chatUsers: PropTypes.arrayOf(PropTypes.object),
  filteredChatUsers: PropTypes.arrayOf(PropTypes.object),
  getChatUsers: PropTypes.func,
  isProfileShown: PropTypes.bool,
  onChangeChatUser: PropTypes.func,
  onChangeSearchTerm: PropTypes.func,
  onSearch: PropTypes.func,
  onSend: PropTypes.func,
  searchTerm: PropTypes.string,
  selectedChatUser: PropTypes.object,
  setBlockedUsers: PropTypes.func,
  toggleProfile: PropTypes.func,
};

export default ChatView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width:100%,
  margin-right: ${spacing.s};
  margin-bottom: ${spacing.xl};
  padding-right: ${spacing.s};
`;

const ToggleProfileWrapper = styled("div")`
  position: absolute;
  top: 8px;
  left: 14px;
  transition: transform 200ms;
  transform: rotate(${({ isProfileShown }) => (isProfileShown ? "-180deg" : "0deg")})
    translateX(${({ isProfileShown }) => (isProfileShown ? "-6px" : "0px")});
`;
