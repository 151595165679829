const getJobSeekerListing = (state) => {
  const { jobSeekerListing } = state.jobSeekerState;
  return jobSeekerListing;
};

const getForceUpdateJobSeekerListing = (state) => {
  const { forceUpdate } = state.jobSeekerState;
  return forceUpdate;
};

const getPageLimitJobSeekerListing = (state) => {
  const { listedJobsPageLimit } = state.jobSeekerState;
  return listedJobsPageLimit;
};

const isJobListFetching = (state) => {
  const { jobSeekerListingLoading, jobSeekerListingSuccess } =
    state.jobSeekerState;
  return jobSeekerListingLoading && !jobSeekerListingSuccess;
};

const getTotalJobsCount = (state) => {
  const { totalListingJobs } = state.jobSeekerState;
  return totalListingJobs;
};

const getSuggestedJobs = (state) => {
  const { jobSeekerSuggestedResults } = state.jobSeekerState;
  return jobSeekerSuggestedResults;
};

const getEmployerAvailabilityList = (state) => {
  const { employerAvailableList } = state.jobSeekerState;
  return employerAvailableList;
};


const JobSeekerSelector = {
  getJobSeekerListing,
  getForceUpdateJobSeekerListing,
  getPageLimitJobSeekerListing,
  isJobListFetching,
  getTotalJobsCount,
  getEmployerAvailabilityList,
  getSuggestedJobs,
};

export default JobSeekerSelector;
