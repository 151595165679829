import { Badge, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors, spacing, styleUtils } from "../../../styles";
import { Icon, Text } from "../../atoms";

const NavItem = ({ text, icon, isActive, hasBadge, badgeContent }) => (
  <>
    <StyledBox>
      <Icon name={icon} size={16} style={{ marginRight: spacing.xxs }} />
      <Text
        color={isActive ? colors.primary : colors.textPrimary}
        weight="bold"
        sx={{
          "&:hover": {
            color: colors.primary,
          },
        }}
      >
        {text}
      </Text>
      {hasBadge && (
        <Badge
          badgeContent={badgeContent}
          color="primary"
          style={{
            marginBottom: spacing.m,
            marginLeft: spacing.xs,
            alignItems: "center",
          }}
        />
      )}
    </StyledBox>
    {isActive && <Line />}
  </>
);

export default NavItem;

const Line = styled("div")`
  position: absolute;
  bottom: 0;
  height: 4px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-self: center;
  background-color: ${colors.primary};
`;

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  paddingLeft: styleUtils.pxToRem("9px"),
  paddingRight: styleUtils.pxToRem("12px"),
});
