import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";

const TextInput = ({ type, handleSubmit, ...props }) => {
  // For password field do not change the type to search
  const fieldType = type === "password" ? "password" : "search";

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      //since handleSubmit is not a required prop, we need to handle the conditions where we're not passing it
      if (handleSubmit) {
        handleSubmit(e);
      }
    }
  };
  return (
    <InputBase
      inputProps={{ type: `${fieldType}` }}
      onKeyDown={handleKeyDown}
      {...props}
      style={{ cursor: "text" }}
    />
  ); // additional prop for clear button
};

TextInput.propTypes = {
  type: PropTypes.oneOf(["password", "search"]),
  handleSubmit: PropTypes.func,
};

export default TextInput;
