import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text } from "components/atoms";

import { images } from "assets/images";
import { colors, spacing, styleUtils, textSizes } from "../../../../styles";

import { CHANGE_PASSWORD } from "containers/Account/constants";

const ContinueLogin = ({ handleContinueLogin }) => {
  return (
    <ContentContainer>
      <ImgContainer src={images.passwordChangeSuccess} />
      <Text size="m" color={colors.blackText} sx={{ textAlign: "center" }}>
        {CHANGE_PASSWORD.PASSWORD_CHANGE_MSG_1}
      </Text>
      <Text size="m" color={colors.blackText} sx={{ textAlign: "center" }}>
        {CHANGE_PASSWORD.PASSWORD_CHANGE_MSG_2}
      </Text>
      <ActionButton
        onClick={handleContinueLogin}
        variant="contained"
        sx={{
          mr: spacing.s,
        }}
      >
        {CHANGE_PASSWORD.CONTINUE_LOGIN_BUTTON}
      </ActionButton>
    </ContentContainer>
  );
};

export default ContinueLogin;

const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.s};
`;
const ActionButton = styled(Button)`
  font-size: ${textSizes.m};
  height: ${styleUtils.pxToRem("48px")};
  margin-top: ${styleUtils.pxToRem("53px")};
  width: ${styleUtils.pxToRem("254px")};
`;
const ImgContainer = styled("img")`
  display: block;
  align-self: center;
  margin-top: ${styleUtils.pxToRem("113px")};
  margin-bottom: ${styleUtils.pxToRem("35px")};
  width: ${styleUtils.pxToRem("207px")};
  height: ${styleUtils.pxToRem("175px")};
`;
