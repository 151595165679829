import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { isEmpty } from "lodash";

import { Box, Button, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "hooks/useIsMobile";

import { Icon, OtpInput, Text } from "../../../../components/atoms";
import { colors, spacing, textSizes, styleUtils } from "../../../../styles";

import {
  shareForgotPasswordOtpRequest,
  verifyForgotPasswordOtpRequest,
} from "containers/Login/actions/LoginActions";
import { maskEmail, maskPhone } from "utils/commonUtils";

import { FORGOT_PASSWORD } from "containers/Account/constants";
import {
  FormHelperErrorText,
  ResendButton,
  ResendContainer,
} from "containers/Otp/views/styledConstants";

const RESEND_OTP_TIME_LIMIT = 60;
const OTP_LENGTH = 6;

const ForgotPasswordOtpVerification = ({
  userType,
  handlePasswordValidation,
  handleBack,
  email,
  phoneNumber,
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [resendCodeSecs, setResendCodeSecs] = useState(RESEND_OTP_TIME_LIMIT);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const validate = (values) => {
    const errors = {};
    const passwordOtp = values?.passwordOtpValue?.trim();
    if (passwordOtp?.length === 0 || passwordOtp?.length < OTP_LENGTH) {
      errors.passwordOtpValue = FORGOT_PASSWORD.ERROR_OTP_LENGTH;
    } else if (
      passwordOtp?.length === OTP_LENGTH &&
      !isCodeVerified &&
      !isVerifying
    ) {
      errors.passwordOtpValue = FORGOT_PASSWORD.ERROR_OTP_MISMATCH;
    }
    return errors;
  };

  const onSubmit = () => {
    if (isCodeVerified && !isVerifying) {
      handlePasswordValidation(true);
    }
  };

  const formProps = useFormik({
    enableReinitialize: true,
    initialValues: {
      passwordOtpValue: "",
    },
    onSubmit,
    validate,
    validateOnBlur: false,
  });

  useEffect(() => {
    let timer;
    if (resendCodeSecs !== 0) {
      timer = setInterval(() => {
        setResendCodeSecs(resendCodeSecs - 1);
      }, 1000);
      if (resendCodeSecs === 0) {
        setResendCodeSecs(RESEND_OTP_TIME_LIMIT);
        clearInterval(timer);
      }
    }
    return () => clearInterval(timer);
  }, [resendCodeSecs]);

  useEffect(() => {
    if (formProps.values.passwordOtpValue.trim().length === OTP_LENGTH) {
      verifyPasswordOtp(formProps.values.passwordOtpValue);
    }
  }, [formProps.values.passwordOtpValue]);

  const verifyPasswordOtp = (text) => {
    setIsVerifying(true);
    dispatch(
      verifyForgotPasswordOtpRequest({
        email: email,
        otp: text,
        userType: userType,
        onCallback: (params) => {
          setIsVerifying(false);
          if (params.verifiedStatus) {
            setIsCodeVerified(true);
            formProps.setErrors({});
          } else {
            setIsCodeVerified(false);
            formProps.setFieldTouched("passwordOtpValue", true, false);
          }
        },
      })
    );
  };

  const onResendOtpButtonClick = () => {
    setResendCodeSecs(60);

    dispatch(
      shareForgotPasswordOtpRequest({
        email: email,
        userType: userType,
      })
    );
  };

  const webSubText = (
    <>
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        style={{ marginBottom: spacing.xs }}
      >
        {FORGOT_PASSWORD.FORGOT_PASSWORD_HEADER}
      </Text>
      <Text
        size="s"
        numberOfLines={2}
        color={colors.subtextGray}
      >
        We’ve sent the verification code to your registered email id
      </Text>
      <Text
        size="s"
        numberOfLines={2}
        color={colors.subtextGray}
        style={{ marginBottom: spacing.l }}
      >
        {`${maskEmail(email)} and phone no. ${maskPhone(phoneNumber)}`}
      </Text>
    </>
  );

  const mobileSubText = (
    <>
      <Text
        size="m"
        weight="semibold"
        color={colors.titleBlack}
        style={{ marginBottom: spacing.xs }}
      >
        {FORGOT_PASSWORD.FORGOT_PASSWORD_HEADER}
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
      >
        We’ve sent the verification code to your
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
      >
        {`registered email id ${maskEmail(email)}`}
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
        sx={{ marginBottom: spacing.xxl }}
      >
        {`and phone no. ${maskPhone(phoneNumber)}`}
      </Text>
    </>
  );

  const showForgotPasswordFormError =
    formProps.touched.passwordOtpValue &&
    Boolean(formProps.errors.passwordOtpValue);

  const showZero = resendCodeSecs < 10 ? "0" : "";

  return (
    <VerificationBox>
      {isMobile ? mobileSubText : webSubText}
      <FormControl>
        <OtpInput
          value={formProps.values.passwordOtpValue}
          onChange={(text) => formProps.setFieldValue("passwordOtpValue", text)}
          isVerifying={isVerifying}
          isCodeVerified={isCodeVerified}
          hasError={showForgotPasswordFormError}
        />
        <ResendContainer>
          {showForgotPasswordFormError && (
            <>
              <Icon
                name="error"
                size={11}
              />
              <FormHelperErrorText>
                {formProps.errors.passwordOtpValue}
              </FormHelperErrorText>
            </>
          )}
          <ResendButton
            variant="text"
            disabled={resendCodeSecs !== 0}
            onClick={onResendOtpButtonClick}
            className="otp-resend-link"
            size="xs"
            sx={{
              color: resendCodeSecs !== 0 ? colors.timerGray : colors.link,
              left: resendCodeSecs !== 0 ? "165px" : "210px",
            }}
          >
            {resendCodeSecs !== 0
              ? `Resend code in 00:${showZero}${resendCodeSecs}`
              : "Resend Code"}
          </ResendButton>
        </ResendContainer>
      </FormControl>
      <FormsBtnContainer
        sx={{
          marginTop: styleUtils.pxToRem(isMobile ? "310px" : "314px"),
          paddingBottom: "auto",
        }}
      >
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={handleBack}
        />
        <Button
          variant="contained"
          disabled={!isEmpty(formProps.errors)}
          onClick={formProps.handleSubmit}
          sx={{
            fontSize: textSizes.m,
            marginLeft: "auto",
            height: styleUtils.pxToRem("42px"),
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
          }}
        >
          {FORGOT_PASSWORD.NEXT_BUTTON}
        </Button>
      </FormsBtnContainer>
    </VerificationBox>
  );
};

export default ForgotPasswordOtpVerification;

ForgotPasswordOtpVerification.propTypes = {
  userType: PropTypes.string.isRequired,
  handlePasswordValidation: PropTypes.func,
  handleBack: PropTypes.func,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
};

const VerificationBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;
