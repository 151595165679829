import { useFormik } from "formik";
import noop from "lodash/noop";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { userSettingsUpdate } from "../../containers/Login/actions/LoginActions";

const useEmployerJobSeekerListingFilter = (
  filterSettings,
  handleFilter,
  onlyFavourites,
  handleClose = noop
) => {
  const dispatch = useDispatch();

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      wage: filterSettings?.wage?.length > 0 ? filterSettings?.wage : [],
      hrsPerWeek:
        filterSettings?.hrsPerWeek?.length > 0
          ? filterSettings?.hrsPerWeek
          : [],
      age: filterSettings?.age?.length > 0 ? filterSettings?.age : 0,
      experience:
        filterSettings?.experience?.length > 0
          ? filterSettings?.experience
          : [],
      interested: onlyFavourites || filterSettings?.interested || false,
    },
  });

  const onChangeHourlyWages = useCallback((value) => {
    setFieldValue("wage", value);
  }, []);

  const onChangeHoursPerWeek = useCallback((value) => {
    setFieldValue("hrsPerWeek", value);
  }, []);

  const onChangeExperience = useCallback((value) => {
    setFieldValue("experience", value);
  }, []);

  const onChangeAge = useCallback((value) => {
    setFieldValue("age", value);
  }, []);

  const onChangeFavourites = useCallback((event) => {
    setFieldValue("interested", event.target.checked);
  }, []);

  const onApply = () => {
    handleFilter({
      wage: values.wage.includes("all") ? [] : values.wage,
      hrsPerWeek: values.hrsPerWeek.includes("all") ? [] : values.hrsPerWeek,
      experience: values.experience.includes("all") ? [] : values.experience,
      age: values.age,
      interested: values.interested,
    });
    dispatch(
      userSettingsUpdate("employerJobSeekerListingFilter", {
        wage: values.wage,
        hrsPerWeek: values.hrsPerWeek,
        experience: values.experience,
        age: values.age,
        interested: values.interested,
      })
    );
    handleClose();
  };

  const onReset = () => {
    handleFilter({});
    dispatch(
      userSettingsUpdate("employerJobSeekerListingFilter", {
        wage: [],
        hrsPerWeek: [],
        experience: [],
        age: 0,
        interested: false,
      })
    );
    resetForm({
      values: {
        wage: [],
        hrsPerWeek: [],
        experience: [],
        age: 0,
        interested: false,
      },
    });
    handleClose();
  };

  return {
    wage: values.wage,
    hrsPerWeek: values.hrsPerWeek,
    age: values.age,
    experience: values.experience,
    interested: values.interested,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeAge,
    onChangeFavourites,
    onApply,
    onReset,
  };
};

export default useEmployerJobSeekerListingFilter;
