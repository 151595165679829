import { Text } from "../../components/atoms";
import { colors } from "../../styles";

const BlockedMessage = ({ isBlocked, isBlockedBy }) => {
  if (isBlocked) {
    return (
      <Text
        size="xs"
        color={colors.disableGrey}
        style={{ textAlign: "center" }}
      >
        You have blocked this user.
      </Text>
    );
  } else if (isBlockedBy) {
    return (
      <Text
        size="xs"
        color={colors.disableGrey}
        style={{ textAlign: "center" }}
      >
        You can&apos;t reply to this conversation anymore.
      </Text>
    );
  }
  return null;
};

export default BlockedMessage;
