import { useState } from "react";
import { List, ListItem, Collapse, ListItemButton } from "@mui/material";
import { Icon, Text } from "../";
import { spacing } from "../../../styles";

/**
 * Select Dropdown options with selection check
 */
const LocationsDropDown = (props) => {
  const [openList, setOpenList] = useState(true);
  const { dropDownOptions, handleLocNavigation } = props;

  return (
    <List
      sx={{
        paddingBottom: 0,
        paddingTop: 0,
      }}
    >
      <ListItemButton
        sx={{
          paddingLeft: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Text
          size="l"
          weight="semibold"
          color="#2B4894"
          sx={{
            borderBottom: "0.5px solid #2B4894",
            lineHeight: 1.2,
          }}
        >
          {dropDownOptions?.length > 0 ? dropDownOptions[0] : ""}
        </Text>
        <Icon
          name="near-me-loc"
          onClick={() => handleLocNavigation(dropDownOptions[0], 0)}
          size={20}
          style={{ marginLeft: spacing.xs }}
        />
        {!openList && dropDownOptions?.length > 1 && (
          <Text
            size="s"
            weight="semibold"
            color="#2B4894"
            sx={{
              marginLeft: spacing.xs,
            }}
          >
            {`+${dropDownOptions?.length - 1}  `}
          </Text>
        )}
        <Icon
          name={openList ? "expand-more" : "expand-less"}
          onClick={() => {
            setOpenList(!openList);
          }}
          size={13}
          style={{ marginLeft: spacing.m }}
        />
      </ListItemButton>
      <Collapse in={openList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {dropDownOptions?.slice(1).map((dropDownValue, index) => {
            return (
              <ListItem key={index} sx={{ paddingLeft: 0 }}>
                <Text
                  size="l"
                  weight="semibold"
                  color="#2B4894"
                  sx={{
                    borderBottom: "0.5px solid #2B4894",
                    lineHeight: 1.2,
                  }}
                >
                  {dropDownValue}
                </Text>
                <Icon
                  name="near-me-loc"
                  onClick={() => handleLocNavigation(dropDownValue, index)}
                  size={20}
                  style={{ marginLeft: spacing.xs, cursor: "pointer" }}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default LocationsDropDown;
