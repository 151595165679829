import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useSearchParams, useLocation } from "react-router-dom";
import { showNotification, hideLoader, showLoader } from "../../utils/commonUtils";
import { jobDetails } from "../../configs/network/apis";
import { EMPLOYER } from "../../appConstants";
import LoginSelector from "../../containers/Login/selectors/LoginSelector";
import {
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  POST_NEW_JOB_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
} from "../../routes/routeConstants";

const ContentSelection = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const brandName = searchParams.get("brandName");
  const jobId = searchParams.get("jobId");
  const recId = searchParams.get("recId");
  const [content, setContent] = useState("");

  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const searchLocation = useSelector(LoginSelector.getSearchLocation);

  const init = async () => {
    try {
      showLoader();
      const searchLatLngParams = {
          searchedLat: searchLocation?.lat,
          searchedLng: searchLocation?.lng,
        };
      const res = await jobDetails(jobId, searchLatLngParams);
      hideLoader();
      setContent(
        <Navigate
          to={`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`}
          state={{
            data: res?.data,
            isEditPress: true,
            isSharedJob: true,
          }}
          replace
        />,
      );
    } catch (e) {
      hideLoader();
      showNotification({
        alertProps: {
          severity: "info",
          children: "Job not available",
        },
      });
      setContent(<Navigate to={`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`} replace />);
    }
  };

  useEffect(() => {
    if (isAuthenticated && loggedInUser?.userType === EMPLOYER) {
      if (loggedInUser?.uid === recId) {
        init();
      } else if (
        (brandName && jobId && recId) ||
        location.pathname === `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`
      ) {
        showNotification({
          alertProps: {
            severity: "info",
            children: "The link you are trying to open is for a job seeker",
          },
        });
        setContent(<Navigate to={`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`} replace />);
      } else {
        setContent(<Navigate to={`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`} replace />);
      }
    }
  }, [isAuthenticated]);

  return content;
};

export default ContentSelection;
