import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon, Text } from "components/atoms";
import { colors, spacing, styleUtils } from "styles";
import { SETTINGS_SCREEN_CONSTS } from "containers/Account/constants";

const Settings = ({ onClickDeleteAccount, onClickEditBlockedUsers, showChangePassword }) => {
  return (
    <>
      <Row onClick={() => onClickEditBlockedUsers("settings", "editBlocked")}>
        <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
          <Icon size={29} name="blocked-users" color={colors.primary} />
          <Text size="m" color={colors.textPrimary} sx={{ marginLeft: spacing.s }}>
            {SETTINGS_SCREEN_CONSTS.BLOCKED_USERS}
          </Text>
        </div>
        <Icon size={28} name="profile-next" style={{ marginLeft: "auto" }} />
      </Row>
      <Divider />
      {showChangePassword && (
        <>
          <Row
            sx={{ marginTop: spacing.m }}
            onClick={() => onClickEditBlockedUsers("settings", "editPassword")}
          >
            <Icon name="changePassword" size={24} color={colors.primary} />
            <Text size="m" color={colors.textPrimary} sx={{ marginLeft: spacing.s }}>
              {SETTINGS_SCREEN_CONSTS.CHANGE_PASSWORD}
            </Text>
            <Icon size={28} name="profile-next" style={{ marginLeft: "auto" }} />
          </Row>
          <Divider />
        </>
      )}
      <Row sx={{ marginTop: spacing.m }}>
        <Icon name="delete-bin" size={24} color={colors.primary} />
        <DeleteContainer>
          <Text size="m" color={colors.textPrimary} sx={{ marginLeft: spacing.s }}>
            {SETTINGS_SCREEN_CONSTS.DELETE_ACCOUNT}
          </Text>
          <Text
            size="s"
            color={colors.linkColor}
            sx={{
              marginLeft: spacing.s,
              marginTop: spacing.xs,
              width: styleUtils.pxToRem("75px"),
            }}
            onClick={onClickDeleteAccount}
          >
            {SETTINGS_SCREEN_CONSTS.CLICK_HERE}
          </Text>
        </DeleteContainer>
      </Row>
    </>
  );
};

Settings.propTypes = {
  onClickDeleteAccount: PropTypes.func.isRequired,
  onClickEditBlockedUsers: PropTypes.func.isRequired,
  showChangePassword: PropTypes.bool.isRequired,
};

export default Settings;

const Row = styled("div")`
  display: flex;
  margin-bottom: ${spacing.m};
  cursor: pointer;
  padding: ${spacing.xs} ${spacing.l};
  position: relative;
`;
const DeleteContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;
