import { Container, Grid } from "@mui/material";

import { colors, spacing } from "../../../styles";

import { JobSeekerSearchInput } from "../../../components/molecules";
import { Text } from "../../../components/atoms";
import { SearchConstants } from "./Constants";
import noop from "lodash/noop";

const Search = ({
  searchTermValue,
  mobileSearchValues,
  onChangeSearchTerm,
  googleInputRef,
  onGooglePlaceSelected,
  onClearGoogleInput,
  onClickSearch = noop,
  fetchJobSuggestions,
  jobSuggestions,
  setJobSearchValue,
}) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "78vh",
      }}
    >
      <Grid
        container
        columnSpacing={spacing.s}
        sx={{ marginTop: spacing.xxxl }}
      >
        <Grid item md={7}>
          <Text
            id="HOME_SEARCH_WEB_TITLE_1"
            size={46}
            style={{ marginTop: spacing.xxxxl, lineHeight: 1.2 }}
          >
            <Text
              id="HOME_SEARCH_WEB_TITLE_2"
              size={46}
              weight="extrabold"
              component="span"
              color={colors.primary}
              style={{ lineHeight: 1.2 }}
            >
              {SearchConstants.TITLE_2}
            </Text>{" "}
            {SearchConstants.TITLE_1}
          </Text>
          <Text
            id="HOME_SEARCH_WEB_TITLE_3"
            size={46}
            weight="medium"
            style={{ lineHeight: 1.2 }}
          >
            {SearchConstants.TITLE_3}
          </Text>
          <JobSeekerSearchInput
            id="HOME_SEARCH_WEB_INPUT_FIELD"
            googleInputRef={googleInputRef}
            mobileSearchValues={mobileSearchValues}
            onChangeSearchTerm={onChangeSearchTerm}
            onClearGoogleInput={onClearGoogleInput}
            onClickSearch={onClickSearch(searchTermValue)}
            onGooglePlaceSelected={onGooglePlaceSelected}
            searchTermValue={searchTermValue}
            fetchJobSuggestions={fetchJobSuggestions}
            jobSuggestions={jobSuggestions}
            setJobSearchValue={setJobSearchValue}
            sx={{ mt: spacing.xxxl }}
          />
          <Text
            id="HOME_SEARCH_WEB_SUB_TITLE_ASTERIK"
            size="l"
            weight="medium"
            style={{
              marginTop: spacing.s,
            }}
            color="#FD2222"
          >
            {SearchConstants.REQUIRED_ASTERIK}
            <Text
              id="HOME_SEARCH_WEB_SUB_TITLE_1"
              component="span"
              size="l"
              weight="medium"
              color="#A3A3A3"
            >
              {SearchConstants.SUB_TITLE_1}
              <Text
                size="l"
                id="HOME_SEARCH_WEB_SUB_TITLE_2"
                component="span"
                weight="semibold"
                color={colors.textPrimary}
              >
                {SearchConstants.SUB_TITLE_2}
              </Text>
            </Text>
          </Text>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;
