import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";

import { Icon } from "components/atoms";

import { userLocationUpdate, userIsLocationUpdating } from "containers/Login/actions/LoginActions";

import { getCurrentLocation, getLocationDetails } from "utils/locationUtils";
import { showNotification } from "utils/commonUtils";

import LoginSelector from "containers/Login/selectors/LoginSelector";

const CurrentLocation = ({ googleInputRef, setSearchValue }) => {
  const dispatch = useDispatch();
  const isCurrentLocationUpdating = useSelector(LoginSelector.getIsUserLocationUpdating);

  const circularLoader = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="inherit" size={18} />
      </Box>
    );
  };

  const handleCurrentLocationSuccessCallback = async (location) => {
    const { lat, lng } = location;
    setSearchValue("lat", lat);
    setSearchValue("lng", lng);
    const locationText = await getLocationDetails(lat, lng);
    googleInputRef.current.value = locationText;
    setSearchValue("locationName", locationText);
    dispatch(userLocationUpdate({ ...location, locationName: locationText }));
  };

  //TO-DO: Need to implement these operations using useReducer and react-query
  const handleFetchCurrentLocation = async () => {
    try {
      dispatch(userIsLocationUpdating(true));
      getCurrentLocation(handleCurrentLocationSuccessCallback, () => {
        dispatch(userIsLocationUpdating(false));
      });
    } catch (error) {
      showNotification({
        alertProps: {
          severity: "warning",
          children: "An unexpected error occurred. Please try again.",
        },
      });
    }
  };

  const content = (
    <>
      {isCurrentLocationUpdating ? (
        circularLoader()
      ) : (
        <Icon
          name="fetch-current-location"
          size="18"
          style={{ cursor: "pointer" }}
          onClick={handleFetchCurrentLocation}
        />
      )}
    </>
  );

  return content;
};

CurrentLocation.propTypes = {
  googleInputRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  setSearchValue: PropTypes.func.isRequired,
};

export default CurrentLocation;
