import { handleActions } from "redux-actions";
import update from "immutability-helper";

import { commonInitialState } from "./initialState";

import * as CommonActionsTypes from "../actions/commonActionTypes";

const commonReducer = {
  commonState: handleActions(
    {
      [CommonActionsTypes.SHOW_LOADER]: (state) => {
        return update(state, {
          loader: {
            isShown: { $set: true },
          },
        });
      },
      [CommonActionsTypes.HIDE_LOADER]: (state) => {
        return update(state, {
          loader: {
            isShown: { $set: false },
          },
        });
      },
      [CommonActionsTypes.SHOW_NOTIFICATION]: (state, action) => {
        const { snackbarProps, alertProps } = action.payload;
        return update(state, {
          notification: {
            snackbarProps: { $set: snackbarProps },
            alertProps: { $set: alertProps },
            isShown: { $set: true },
          },
        });
      },
      [CommonActionsTypes.HIDE_NOTIFICATION]: (state) => {
        return update(state, {
          notification: {
            isShown: { $set: false },
          },
        });
      },
    },
    commonInitialState
  ),
};

export default commonReducer;
