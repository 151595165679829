import PropTypes from "prop-types";
import { Avatar, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

import { images } from "../../../assets/images";
import { Icon, Text } from "../../atoms";

import { renderImage } from "../../../utils/imageUtils";
import { formatAMPM } from "utils/dateUtils";

import { colors, spacing, styleUtils } from "../../../styles";
import { JOB_SEEKER } from "../../../appConstants";

const ChatListCard = ({ item, cardStyle, active, onClick }) => {
  let data;
  if (item.type === JOB_SEEKER) {
    data = {
      name: item?.fullName,
      image: item?.photoURL,
      defaultImage: "user",
    };
  } else {
    data = {
      name: item?.companyName || item?.fullName,
      image: item?.photoURL,
      defaultImage: "job",
    };
  }

  const onClickItem = () => {
    onClick(item);
  };

  return (
    <Card
      sx={[
        {
          backgroundColor: active ? colors.primary : colors.white,
          cursor: "pointer",
        },
        cardStyle,
      ]}
      onClick={onClickItem}
      className={active ? "chat-list-item-active" : ""}
    >
      <Row>
        <Avatar sx={{ width: 44, height: 44 }} src={renderImage(data.image, data.defaultImage)}>
          <img
            alt={data.name}
            src={images.defaults[data.defaultImage]}
            style={{ width: 44, height: 44 }}
          />
        </Avatar>
        <TextContainer>
          <ContentWrapper style={{ justifyContent: "space-between" }}>
            <Text
              weight="medium"
              color={active ? colors.white : colors.titleBlack}
              numberOfLines={1}
            >
              {data?.name}
            </Text>
            {item?.chat_id && (
              <Text size="xs" color={colors.disableGrey}>
                {formatAMPM(item?.timestamp)}
              </Text>
            )}
          </ContentWrapper>
          <ContentWrapper>
            <Text
              size="xs"
              color={active ? colors.white : colors.subtextGray}
              numberOfLines={1}
              disableScroll
              style={{ flex: 1 }}
            >
              {item.is_file && (
                <Icon
                  size={12}
                  name={active ? "file-mine" : "file"}
                  style={{
                    transform: "translateY(2px)",
                    marginRight: spacing.xxs,
                  }}
                />
              )}
              {item.lastMessage}
            </Text>
            {item?.unreadCount > 0 && (
              <CountBox
                style={{
                  backgroundColor: colors.unreadMessageCount,
                }}
              >
                <Text
                  weight="medium"
                  size="xs"
                  color={colors.white}
                  style={{ transform: "translateY(0.5px)" }}
                >
                  {item.unreadCount}
                </Text>
              </CountBox>
            )}
          </ContentWrapper>
        </TextContainer>
      </Row>
    </Card>
  );
};

ChatListCard.propTypes = {
  active: PropTypes.bool,
  cardStyle: PropTypes.object,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChatListCard;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${spacing.xs};
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.xs};
  flex: 1;
`;

const ContentWrapper = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const CountBox = styled("div")`
  min-width: ${styleUtils.pxToRem("18px")};
  height: ${styleUtils.pxToRem("17px")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
