import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors, spacing } from "../../../styles";

import { JobSeekerSearchInput } from "../../../components/molecules";
import { Text } from "../../../components/atoms";
import { SearchConstants } from "./Constants";
import noop from "lodash/noop";

const Search = ({
  searchTermValue,
  mobileSearchValues,
  onChangeSearchTerm,
  googleInputRef,
  onGooglePlaceSelected,
  onClearGoogleInput,
  onClickSearch = noop,
  fetchJobSuggestions,
  jobSuggestions,
  setJobSearchValue,
}) => {
  return (
    <>
      <AvailableRegion>
        <marquee direction="left">
          <Text id="HOME_SEARCH_MOB_SUB_TITLE_1" size="s" color={colors.white}>
            {SearchConstants.SUB_TITLE_1}{" "}
            <Text
              id="HOME_SEARCH_MOB_SUB_TITLE_2"
              size="s"
              component="span"
              color={colors.white}
              weight="semibold"
            >
              {SearchConstants.SUB_TITLE_2}
            </Text>
          </Text>
        </marquee>
      </AvailableRegion>

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: spacing.xs,
          mb: spacing.xxxxl,
        }}
      >
        <Text
          id="HOME_SEARCH_MOB_TITLE_1"
          size={32}
          weight="extrabold"
          color={colors.primary}
          style={{ marginTop: spacing.xs, ineHeight: 1.2 }}
        >
          {SearchConstants.TITLE_1}
        </Text>
        <Text
          id="HOME_SEARCH_MOB_TITLE_2"
          size={32}
          style={{ lineHeight: 1.2 }}
        >
          {SearchConstants.TITLE_2}
        </Text>
        <Text
          id="HOME_SEARCH_MOB_TITLE_3"
          size={32}
          style={{ lineHeight: 1.2 }}
        >
          {SearchConstants.TITLE_3}{" "}
        </Text>
        <JobSeekerSearchInput
          id="HOME_SEARCH_MOB_INPUT_FIELD"
          displayHome
          onChangeSearchTerm={onChangeSearchTerm}
          onClearGoogleInput={onClearGoogleInput}
          onClickSearch={onClickSearch(searchTermValue)}
          mobileSearchValues={mobileSearchValues}
          googleInputRef={googleInputRef}
          onGooglePlaceSelected={onGooglePlaceSelected}
          searchTermValue={searchTermValue}
          fetchJobSuggestions={fetchJobSuggestions}
          jobSuggestions={jobSuggestions}
          setJobSearchValue={setJobSearchValue}
          sx={{ mt: "80%", zIndex: 1000, backgroundColor: colors.white }}
        />
      </Container>
    </>
  );
};

export default Search;

const AvailableRegion = styled("div")`
  padding: ${spacing.xs} 0;
  background-color: #3cacff;
`;
