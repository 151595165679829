export const jobSeekerInitialState = {
  jobSeekerListingLoading: false,
  jobSeekerListingFailure: false,
  jobSeekerListingSuccess: false,
  jobSeekerListing: [],
  jobSeekerSuggestedResults: [],
  totalListingJobs: 0,
  listedJobsPageLimit: 0,
  isJobSeekerRatingDone: false,
  forceUpdate: false,
};

export const jobSeekerSchedulerInitialState = {
  employerAvailableList: [],
};
