import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Text, Icon } from "../../../components/atoms";
import { CATEGORY_COLORS } from "../blogConstants";
import { styleUtils } from "../../../styles";
import moment from "moment";
import { getSanitizedHtml } from "../../../utils/commonUtils";

const PostCard = ({
  title,
  excerpt,
  image,
  slug,
  content,
  category,
  date,
  authorName,
}) => {
  const navigate = useNavigate();
  const openBlogPost = () => {
    navigate(`/blogs/post/${slug}`, {
      state: {
        title,
        content,
        image,
        authorName,
        date,
        category,
      },
    });
  };
  return (
    <Card sx={{ maxWidth: 320, cursor: "pointer" }} onClick={openBlogPost}>
      <CardMedia component="img" alt={slug} height="150" src={image} />
      <CardContent sx={{ paddingX: "24px", paddingY: "20px" }}>
        <StyledCategoryBadge category={category}>
          <Text disableScroll size="xxs" weight="semibold">
            {category}
          </Text>
        </StyledCategoryBadge>
        <Text
          weight="bold"
          size="l"
          disableScroll
          numberOfLines={2}
          dangerouslySetInnerHTML={{
            __html: getSanitizedHtml(title),
          }}
        />
        <Text
          color="#4C5468"
          size="s"
          sx={{
            marginTop: styleUtils.pxToRem("8px"),
          }}
          disableScroll
          numberOfLines={2}
          dangerouslySetInnerHTML={{
            __html: getSanitizedHtml(excerpt),
          }}
        />
        <div
          style={{
            marginTop: styleUtils.pxToRem("31px"),
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Icon
              name="blog-date"
              size={16}
              color="#B4B4B4"
              style={{
                marginRight: styleUtils.pxToRem("5px"),
              }}
            />
            <Text size="xs" weight="medium" color="#4C5468">
              {moment(date).format("MMM DD YYYY")}
            </Text>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ marginX: styleUtils.pxToRem("12.5px") }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Icon
              name="blog-author"
              size={16}
              color="#B4B4B4"
              style={{
                marginRight: styleUtils.pxToRem("5px"),
              }}
            />
            <Text size="xs" weight="medium" color="#4C5468">
              {authorName}
            </Text>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PostCard;

const StyledCategoryBadge = styled("div")(({ category }) => ({
  backgroundColor: CATEGORY_COLORS[category],
  borderRadius: "12px",
  marginBottom: 16,
  display: "inline-block",
  padding: "4px 14px 3px 15px",
}));
