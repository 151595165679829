export const images = {
  spinner: new URL("./spinner.gif", import.meta.url).href,
  imgemployersignup: new URL("./employersignup.webp", import.meta.url).href,
  imgjobseekersignup: new URL("./jobseekersignup.webp", import.meta.url).href,
  imgpreferencesignup: new URL("./preferencesignup.webp", import.meta.url).href,
  imgpreferencereview: new URL("./preferencereview.webp", import.meta.url).href,
  imginterestsignup: new URL("./interests_2.webp", import.meta.url).href,
  imginterestreview: new URL("./interests_3.webp", import.meta.url).href,
  imgthanksgivingmodal: new URL("./thanksgivingmodal.webp", import.meta.url).href,
  "referral-banner": new URL("./referral-banner.webp", import.meta.url).href,
  logo: new URL("./logo.webp", import.meta.url).href,
  "no-recommended-jobs": new URL("./no-recommended-jobs.webp", import.meta.url).href,
  "fetching-jobs": new URL("./fetching-jobs.webp", import.meta.url).href,
  "facebook-logo": new URL("./facebook-logo.webp", import.meta.url).href,
  "google-logo": new URL("./google-logo.webp", import.meta.url).href,
  "nonserviceblearea-image": new URL("./nonserviceablearea.webp", import.meta.url).href,
  "restrictedcountry-image": new URL("./restrictedCountryPage-background.webp", import.meta.url)
    .href,
  clock: new URL("./clock.webp", import.meta.url).href,
  money: new URL("./money.webp", import.meta.url).href,
  distance: new URL("./distance.webp", import.meta.url).href,
  experience: new URL("./experience.webp", import.meta.url).href,
  postjobimg_1: new URL("./postjobimg_1.webp", import.meta.url).href,
  postjobimg_2: new URL("./postjobimg_2.webp", import.meta.url).href,
  postjobimg_3: new URL("./postjobimg_3.webp", import.meta.url).href,
  home: {
    brands: {
      "agasar-family": new URL("./home/brands/agasar-family.webp", import.meta.url).href,
      arway: new URL("./home/brands/arway.webp", import.meta.url).href,
      ctp: new URL("./home/brands/ctp.webp", import.meta.url).href,
      "fzp-digital": new URL("./home/brands/fzp-digital.webp", import.meta.url).href,
      gymguyz: new URL("./home/brands/gymguyz.webp", import.meta.url).href,
      handm: new URL("./home/brands/handm.webp", import.meta.url).href,
      "hi-bar": new URL("./home/brands/hi-bar.webp", import.meta.url).href,
      "island-tanning": new URL("./home/brands/island-tanning.webp", import.meta.url).href,
      kumon: new URL("./home/brands/kumon.webp", import.meta.url).href,
      "meglio-wood-pizzeria": new URL("./home/brands/meglio-wood-pizzeria.webp", import.meta.url)
        .href,
      nac: new URL("./home/brands/nac.webp", import.meta.url).href,
      "newtown-bagel": new URL("./home/brands/newtown-bagel.webp", import.meta.url).href,
      "newtown-pizza": new URL("./home/brands/newtown-pizza.webp", import.meta.url).href,
      playabowls: new URL("./home/brands/playabowls.webp", import.meta.url).href,
      popeyes: new URL("./home/brands/popeyes.webp", import.meta.url).href,
      rainbow: new URL("./home/brands/rainbow.webp", import.meta.url).href,
      shadybrook: new URL("./home/brands/shadybrook.webp", import.meta.url).href,
      "silly-spoons": new URL("./home/brands/silly-spoons.webp", import.meta.url).href,
      "smoke-dudes": new URL("./home/brands/smoke-dudes.webp", import.meta.url).href,
      "smoothie-king": new URL("./home/brands/smoothie-king.webp", import.meta.url).href,
      "the-briches": new URL("./home/brands/the-briches.webp", import.meta.url).href,
      "the-coffee-room": new URL("./home/brands/the-coffee-room.webp", import.meta.url).href,
      "the-lash-lounge": new URL("./home/brands/the-lash-lounge.webp", import.meta.url).href,
    },
    "jobseeker-hero": {
      2: new URL("./home/jobseeker-hero/2.webp", import.meta.url).href,
      3: new URL("./home/jobseeker-hero/3.webp", import.meta.url).href,
      4: new URL("./home/jobseeker-hero/4.webp", import.meta.url).href,
    },
    "jobseeker-background": {
      1: new URL("./jobseeker-background-image1.webp", import.meta.url).href,
      2: new URL("./jobseeker-background-image2.webp", import.meta.url).href,
      3: new URL("./jobseeker-background-image3.webp", import.meta.url).href,
      4: new URL("./jobseeker-background-image4.webp", import.meta.url).href,
    },
    "jobseeker-responsive-background": {
      1: new URL("./jobseeker-web-responsvie-background1.webp", import.meta.url).href,
      2: new URL("./jobseeker-web-responsvie-background2.webp", import.meta.url).href,
      3: new URL("./jobseeker-web-responsvie-background3.webp", import.meta.url).href,
      4: new URL("./jobseeker-web-responsvie-background4.webp", import.meta.url).href,
    },
    "employer-hero": {
      2: new URL("./home/employer-hero/2.webp", import.meta.url).href,
      3: new URL("./home/employer-hero/3.webp", import.meta.url).href,
      4: new URL("./home/employer-hero/4.webp", import.meta.url).href,
    },
    "employer-background": {
      1: new URL("./employer-background-image1.webp", import.meta.url).href,
      2: new URL("./employer-background-image2.webp", import.meta.url).href,
      3: new URL("./employer-background-image3.webp", import.meta.url).href,
      4: new URL("./employer-background-image4.webp", import.meta.url).href,
    },
    "employer-webresponsive-background": {
      1: new URL("./employer-web-responsvie-background1.webp", import.meta.url).href,
      2: new URL("./employer-web-responsvie-background2.webp", import.meta.url).href,
      3: new URL("./employer-web-responsvie-background3.webp", import.meta.url).href,
      4: new URL("./employer-web-responsvie-background4.webp", import.meta.url).href,
    },
    app: {
      qr: new URL("./home/app/QR.webp", import.meta.url).href,
      downloadApp: new URL("./home/app/downloadApp.webp", import.meta.url).href,
    },
    store: {
      "app-store": new URL("./home/store/app-store.webp", import.meta.url).href,
      "play-store": new URL("./home/store/play-store.webp", import.meta.url).href,
    },
  },
  defaults: {
    job: new URL("./defaults/job.webp", import.meta.url).href,
    user: new URL("./defaults/user.webp", import.meta.url).href,
  },
  social: {
    facebook: new URL("./social/facebook.webp", import.meta.url).href,
    instagram: new URL("./social/instagram.webp", import.meta.url).href,
    linkedin: new URL("./social/linkedin.webp", import.meta.url).href,
    tiktok: new URL("./social/tiktok.webp", import.meta.url).href,
    twitter: new URL("./social/twitter.webp", import.meta.url).href,
  },
  empty: {
    search: new URL("./empty/search.webp", import.meta.url).href,
  },
  profile: {
    edit: new URL("./profile/edit.webp", import.meta.url).href,
    social: {
      google: new URL("./profile/social/google.webp", import.meta.url).href,
      apple: new URL("./profile/social/apple.webp", import.meta.url).href,
      facebook: new URL("./profile/social/facebook.webp", import.meta.url).href,
    },
  },
  multiCarousel: {
    left: new URL("./multi-carousel/left.webp", import.meta.url).href,
    right: new URL("./multi-carousel/right.webp", import.meta.url).href,
  },
  noChatFound: new URL("./noChatFound.webp", import.meta.url).href,
  noChatSelected: new URL("./noChatSelected.webp", import.meta.url).href,
  locationCheck: new URL("./locationCheck.webp", import.meta.url).href,
  locationPermission: new URL("./locationPermission.webp", import.meta.url).href,
  testimony: new URL("./testimony.webp", import.meta.url).href,
  duplicateLocationModal: new URL("./duplicatelocationmodal.webp", import.meta.url).href,
  noResultFound: new URL("./no-result-found.webp", import.meta.url).href,
  noApplicationFound: new URL("./noApplicationFound.webp", import.meta.url).href,
  noLocationImg: new URL("./no-location-img.webp", import.meta.url).href,
  blogs: new URL("./blogs.webp", import.meta.url).href,
  blogPostFooter: new URL("./blog-post-footer.webp", import.meta.url).href,
  blogsBackgroundMobile: new URL("./blogs-background-mobile.webp", import.meta.url).href,
  blogPostFooterMobile: new URL("./blog-post-footer-mobile.webp", import.meta.url).href,
  testimonialBackground: new URL("./testimonialbg.webp", import.meta.url).href,
  testimonialBackgroundMobile: new URL("./testimonialresbg.webp", import.meta.url).href,
  aiGeneration: new URL("./ai-generation.png", import.meta.url).href,
  disabledAIGeneration: new URL("./DisabledAIGen.png", import.meta.url).href,
  passwordChangeSuccess: new URL("./passwordChangeSuccess.webp", import.meta.url).href,
  undrawJobOffers: new URL("./undraw-job-offers.png", import.meta.url).href,
  inActiveDirectSignup: new URL("./inactive-directsingup.png", import.meta.url).href,
  activatedAccountDirectSignup: new URL("./activated-account.png", import.meta.url).href,
};
