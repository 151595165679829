export const MyJobsConstants = {
  SEARCH_INPUT_PLACEHOLDER: "search by jobs, skills",
  GOOLGE_INPUT_PLACEHOLDER: "enter location",
  SEARCH: "Search",
  POPULAR_SKILLS: "Popular skills:",
  SAVED_JOBS: "Applications",
  NO_FAVOURITE_JOBS_TITLE: "You have not applied for any jobs yet.",
  NO_BOOKMARKED_JOBS_TITLE: "You have not bookmarked any job yet.",
  NO_FAVOURITE_JOBS_SUB_TITLE: "Go back to “Home” to look for jobs that interest you.",
  NO_FAVOURITE_JOBS_DESCRIPTION: "Keep track of job listings you’re interested in",
  APPLICATIONS_TAB_HEADER: "applications",
  BOOKMARK_TAB_HEADER: "bookmarks",
  APPPLIED_JOB_TAG: "Applied",
  BOOKMARKED_JOB_TAG: "Bookmarked",
};
