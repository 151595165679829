import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { colors } from "../../../styles";
import Text from "../Text";

const NewJob = ({ textSize = "xxs", jobStyle, isNew = false }) => {
  return (
   isNew &&  (<Trapezium
      sx={{
        ...jobStyle,
      }}
    >
      <Text
        size={textSize}
        weight="semibold"
        color={colors.white}
        sx={{
          transform: "translateY(0.2px)",
          fontSize: jobStyle?.fontSize,
        }}
      >
        NEW JOB
      </Text>
    </Trapezium>)
  );
};

NewJob.propTypes = {
  textSize: PropTypes.string,
  jobStyle: PropTypes.object,
  isNew: PropTypes.bool,
};

export default NewJob;

const Trapezium = styled("div")`
  background-color: #e87d09;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xxs) 0;
  line-height: 1;
`;
