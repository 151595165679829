import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Text, Dropdown, Accordion, MultiCheckbox } from "../../../../../../../components/atoms";
import { FilterCount } from "../../../../../../../components/molecules";

import { colors, spacing, styleUtils } from "../../../../../../../styles";

import {
  daysFilter,
  distanceFilter,
  experienceFilter,
  hourlyWageFilter,
  hoursPerWeekFilter,
  timeSlotFilter,
} from "../../FilterConstants";
import useJobseekerListingFilter from "../../../../../../../hooks/filters/useJobseekerListingFilter";
import { activeFiltersCount, getFilterCount } from "utils/commonUtils";

const Filter = ({ filterSettings, handleFilter }) => {
  const {
    distance,
    wage,
    hrsPerWeek,
    experience,
    days,
    timeSlot,
    onChangeDistance,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeDays,
    onChangeTimeslots,
    onApply,
    onReset,
  } = useJobseekerListingFilter(filterSettings, handleFilter);

  const totalFilterCount = activeFiltersCount(filterSettings) + 1;
  {
    /* Adding 1 for default distance filter */
  }

  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <FilterFlex>
          <Text size="l" weight="bold">
            Filter By
          </Text>
          <FilterText color={colors.white} size="s" weight="bold">
            {totalFilterCount}
          </FilterText>
        </FilterFlex>
        <Divider sx={{ mt: spacing.s }} />
        <Box
          sx={{
            padding: `${spacing.l} ${spacing.l} ${spacing.s} ${spacing.l}`,
            overflow: "auto",
          }}
        >
          <div style={{ display: "flex" }}>
            <Text weight="semibold" sx={{ mb: spacing.xs }}>
              Distance
            </Text>
            <FilterCount count={1} />
          </div>

          <Dropdown
            sx={{ height: 40 }}
            value={distance}
            onChange={onChangeDistance}
            options={distanceFilter}
          />
          <Divider sx={{ mt: spacing.s }} />
          <Accordion
            title="Hourly Wages"
            headerRightContent={<FilterCount count={getFilterCount(wage)} />}
            content={
              <MultiCheckbox
                selectOptions={hourlyWageFilter}
                prevSelectedOptions={wage}
                handleSelect={onChangeHourlyWages}
              />
            }
          />
          <Divider />
          <Accordion
            title="Hours/week"
            headerRightContent={<FilterCount count={getFilterCount(hrsPerWeek)} />}
            content={
              <MultiCheckbox
                selectOptions={hoursPerWeekFilter}
                prevSelectedOptions={hrsPerWeek}
                handleSelect={onChangeHoursPerWeek}
              />
            }
          />
          <Divider />
          <Accordion
            title="Experience"
            headerRightContent={<FilterCount count={getFilterCount(experience)} />}
            content={
              <MultiCheckbox
                selectOptions={experienceFilter}
                prevSelectedOptions={experience}
                handleSelect={onChangeExperience}
              />
            }
          />
          <Divider />
          <Accordion
            title="Days/Week"
            headerRightContent={<FilterCount count={getFilterCount(days)} />}
            content={
              <MultiCheckbox
                selectOptions={daysFilter}
                prevSelectedOptions={days}
                handleSelect={onChangeDays}
              />
            }
          />
          <Divider />
          <Accordion
            title="Shift"
            headerRightContent={<FilterCount count={getFilterCount(timeSlot)} />}
            content={
              <MultiCheckbox
                selectOptions={timeSlotFilter}
                prevSelectedOptions={timeSlot}
                handleSelect={onChangeTimeslots}
              />
            }
          />
        </Box>
        <Divider />
        <ButtonRow>
          <Button
            variant="outlined"
            sx={{
              marginRight: spacing.xs,
              height: styleUtils.pxToRem("35px"),
            }}
            onClick={onReset}
          >
            Reset Filter
          </Button>
          <Button
            variant="contained"
            sx={{
              height: styleUtils.pxToRem("35px"),
            }}
            onClick={onApply}
          >
            Apply
          </Button>
        </ButtonRow>
      </CardContent>
    </Card>
  );
};

Filter.propTypes = {
  filterSettings: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default Filter;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.m};
`;
const FilterFlex = styled("div")`
  display: flex;
  margin-left: ${spacing.l};
  margin-top: ${spacing.l};
`;

const FilterText = styled(Text)`
  background-color: ${colors.filterBadgeBlue};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: ${spacing.xxs};
  margin-top: ${spacing.xxxs};
`;
