export const hourlyWageFilter = [
  {
    id: 1,
    label: "$5-10 /hr",
    value: "5-10",
  },
  {
    id: 2,
    label: "$10-15 /hr",
    value: "10-15",
  },
  {
    id: 3,
    label: "$15-20 /hr",
    value: "15-20",
  },
  {
    id: 4,
    label: "$20-30 /hr",
    value: "20-30",
  },
  {
    id: 5,
    label: "$30-40 /hr",
    value: "30-40",
  },
];

export const jobTypeFilter = [
  {
    id: 1,
    label: "Active",
    value: "active",
  },
  {
    id: 2,
    label: "Inactive",
    value: "inactive",
  },
];

export const experienceFilter = [
  {
    id: 1,
    label: "Beginner",
    value: "Beginner",
  },
  {
    id: 2,
    label: "01-02 years",
    value: "1-2",
  },
  {
    id: 3,
    label: "02-05 years",
    value: "2-5",
  },
  {
    id: 4,
    label: "Above 05 years",
    value: ">5",
  },
];
