import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import LoginSelector from "../containers/Login/selectors/LoginSelector";
import { getUnreadChatUsers } from "../configs/network/apis";

const useChatNotificationCount = () => {
  const isAuthenticated = useSelector(LoginSelector.getIsAuthenticated);
  const [badgeCount, setbadgeCount] = useState(0);

  useEffect(() => {
    let unreadChatCountInterval;
    if (isAuthenticated) {
      getUnreadUsersCount();
      unreadChatCountInterval = setInterval(getUnreadUsersCount, 180000);
    }
    return () => {
      setbadgeCount(0);
      clearInterval(unreadChatCountInterval);
    };
  }, [isAuthenticated]);

  const getUnreadUsersCount = async () => {
    const unreadUsersRes = await getUnreadChatUsers();
    if (unreadUsersRes?.data?.unreadCount >= 0) {
      setbadgeCount(unreadUsersRes.data.unreadCount);
    }
  };

  return badgeCount;
};

export default useChatNotificationCount;
