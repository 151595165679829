import useIsMobile from "../../../../../../hooks/useIsMobile";
import {
  SelectDropDown,
  BottomDrawerDropDown,
} from "../../../../../../components/atoms";

const FormDropDownContainer = (props) => {
  const isMobile = useIsMobile();
  const Wrapper = isMobile ? BottomDrawerDropDown : SelectDropDown;

  return <Wrapper {...props} />;
};

export default FormDropDownContainer;
