import { useEffect } from "react";
import { Button, Card, Container, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import { Text, TextInput } from "../../../components/atoms";

import useIsMobile from "../../../hooks/useIsMobile";
import { colors, spacing, textSizes, styleUtils } from "../../../styles";
import { EMAIL_LOGIN_CONSTS } from "./constants";
import JobSeekerDetail from "./JobSeekerDetail/JobSeekerDetail";
import EmployerDetail from "./EmployerDetail/EmployerDetail";

import { EMPLOYER } from "../../../appConstants";

const LoginEmailView = ({ type: userType, loginFormik, onClickForgotPassword, previousEmail }) => {
  const isMobile = useIsMobile();

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = loginFormik;

  useEffect(() => {
    if (values.showPassword && previousEmail.current !== values.userName.toLowerCase()) {
      setFieldValue("showPassword", false);
      setFieldValue("password", "");
    }
  }, [values.showPassword, values.userName]);

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Card
        sx={{
          py: spacing.xl,
          flex: 1,
          display: "flex",
        }}
      >
        <Grid container>
          <Grid
            item
            xs
            md={5}
            sx={{
              marginLeft: styleUtils.pxToRem(isMobile ? "0" : "48px"),
              marginRight: styleUtils.pxToRem(isMobile ? "0" : "48px"),
              marginTop: styleUtils.pxToRem(isMobile ? "24px" : "48px"),
              height: styleUtils.pxToRem(isMobile ? "643px" : "auto"),
              padding: spacing.s,
            }}
          >
            <Text
              size="l"
              weight="semibold"
              color={colors.titleBlack}
              style={{ marginBottom: spacing.l }}
            >
              {userType === EMPLOYER ? "Employer" : "Job Seeker"} Sign In
            </Text>
            <Text color={colors.labelBlack}>{EMAIL_LOGIN_CONSTS.LABEL_EMAIL}</Text>
            <TextInput
              placeholder={EMAIL_LOGIN_CONSTS.PLACEHOLDER_EMAIL}
              name="userName"
              onChange={handleChange}
              autoComplete="username"
              value={values.userName}
              handleSubmit={handleSubmit}
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: styleUtils.pxToRem("4px"),
                height: styleUtils.pxToRem("42px"),
                marginTop: spacing.xs,
              }}
            />
            {touched.userName && Boolean(errors.userName) && (
              <ErrorContainer>{errors.userName}</ErrorContainer>
            )}
            {values.showPassword && (
              <>
                <div
                  style={{
                    marginTop: spacing.s,
                    marginBottom: spacing.s,
                  }}
                >
                  <Text color={colors.labelBlack}>{EMAIL_LOGIN_CONSTS.LABEL_PASSWORD}</Text>
                  <TextInput
                    placeholder={EMAIL_LOGIN_CONSTS.PLACEHOLDER_PASSWORD}
                    name="password"
                    type="password"
                    onChange={handleChange}
                    autoComplete="current-password"
                    value={values.password}
                    handleSubmit={handleSubmit}
                    sx={{
                      border: "1px solid #E0E0E0",
                      borderRadius: styleUtils.pxToRem("4px"),
                      height: styleUtils.pxToRem("42px"),
                      marginTop: spacing.xs,
                    }}
                  />
                  {touched.password && Boolean(errors.password) && (
                    <ErrorContainer>{errors.password}</ErrorContainer>
                  )}
                </div>
                <NavLink
                  onClick={() => onClickForgotPassword(values.userName)}
                  style={{
                    float: "right",
                  }}
                >
                  <Text
                    size="s"
                    weight="medium"
                    color="#2B4895"
                    sx={{
                      textDecoration: "underline",
                      textUnderlinePosition: "under",
                    }}
                  >
                    {EMAIL_LOGIN_CONSTS.LABEL_FORGOT_PASSWORD}
                  </Text>
                </NavLink>
              </>
            )}
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                fontSize: textSizes.m,
                marginTop: styleUtils.pxToRem("56px"),
                marginRight: "3rem",
                height: styleUtils.pxToRem("42px"),
                width: "100%",
              }}
            >
              {EMAIL_LOGIN_CONSTS.BUTTON_NEXT}
            </Button>
          </Grid>
          {!isMobile && (
            <>
              <Divider orientation="vertical" flexItem sx={{ height: "100%" }} />
              <Grid>{userType === EMPLOYER ? <EmployerDetail /> : <JobSeekerDetail />}</Grid>
            </>
          )}
        </Grid>
      </Card>
    </Container>
  );
};

LoginEmailView.propTypes = {
  type: PropTypes.string.isRequired,
  loginFormik: PropTypes.object.isRequired,
  onClickForgotPassword: PropTypes.func.isRequired,
  previousEmail: PropTypes.object.isRequired,
};

export default LoginEmailView;

const ErrorContainer = styled("div")`
  color: ${colors.red};
  margin-bottom: 3%;
  font-size: ${textSizes.xs};
`;
