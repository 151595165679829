import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon, Text } from "components/atoms";
import useIsMobile from "hooks/useIsMobile";
import { colors, spacing } from "styles";

const ResumeContainer = ({
  resumeFile = {},
  userDetails,
  showUploadModal,
  showDeleteResumeModal,
}) => {
  const isMobile = useIsMobile();
  const resume_filename = userDetails?.resume_filename || resumeFile?.name;
  // This is done if user add resume during onboarding
  const resumeFileName_url = resumeFile instanceof File && URL.createObjectURL(resumeFile);
  if (!userDetails?.resumeUrl && !resumeFile?.name) {
    return null;
  }

  const onClickViewResume = async () => {
    window.open(userDetails?.resumeUrl || resumeFileName_url, "_blank");
  };

  return (
    <Box sx={[{ marginTop: "6px" }, !isMobile && { marginLeft: "40px" }]}>
      {!resumeFile?.name && (
        <Text size="s" color="#797979" style={{ fontStyle: "italic" }}>
          {resume_filename.length > 25 ? `${resume_filename.slice(0, 25)}...` : resume_filename}
        </Text>
      )}
      <Row sx={{ marginTop: "16px" }}>
        <Row onClick={onClickViewResume} sx={{ cursor: "pointer" }}>
          <Icon
            size={20}
            name="profile-visibility"
            style={{ marginRight: spacing.xs }}
            color={colors.primary}
          />
          <Text size="s">View</Text>
        </Row>
        <Row
          sx={{ marginLeft: spacing.s, cursor: "pointer" }}
          onClick={() => showUploadModal(true)}
        >
          <Icon
            size={20}
            name="profile-edit"
            style={{ marginRight: spacing.xs }}
            color={colors.primary}
          />
          <Text size="s">Edit</Text>
        </Row>
        <Row sx={{ marginLeft: spacing.s, cursor: "pointer" }} onClick={showDeleteResumeModal}>
          <Icon
            size={15}
            name="delete-bin"
            style={{ marginRight: spacing.xs }}
            color={colors.primary}
          />
          <Text size="s">Delete</Text>
        </Row>
      </Row>
    </Box>
  );
};

ResumeContainer.propTypes = {
  resumeFile: PropTypes.object,
  userDetails: PropTypes.object.isRequired,
  showUploadModal: PropTypes.func.isRequired,
  showDeleteResumeModal: PropTypes.func.isRequired,
};

export default ResumeContainer;

const Row = styled("div")`
  display: flex;
  align-items: center;
`;
