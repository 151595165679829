import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { DETAILS_FORM_CONSTS } from "../../constants";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
} from "../../../../../../styles";

import { TextInput } from "../../../../../../components/atoms";

const DetailsForm = ({ detailsFormValidationFormik }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <DetailsFormContainer sx={{ padding: spacing.xxxl }}>
          <FormControl
            variant="standard"
            required
            sx={{ marginTop: styleUtils.pxToRem("24px") }}
          >
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {DETAILS_FORM_CONSTS.DETAILS_FORM_EMAIL_LABEL}
            </FormLabel>
            <DetailsFormTextInput
              name="email"
              value={detailsFormValidationFormik.values.email}
              onChange={detailsFormValidationFormik.handleChange}
              error={
                detailsFormValidationFormik.touched.email &&
                Boolean(detailsFormValidationFormik.errors.email)
              }
              placeholder={DETAILS_FORM_CONSTS.DETAILS_FORM_EMAIL_PLACEHOLDER}
              inputProps={{ readOnly: true }}
            />
            {detailsFormValidationFormik.touched.email &&
              Boolean(detailsFormValidationFormik.errors.email) && (
                <DetailsFormHelperText className="form-error-field">
                  {detailsFormValidationFormik.errors.email}
                </DetailsFormHelperText>
              )}
          </FormControl>

          <FormControl
            variant="standard"
            required
            sx={{ marginTop: styleUtils.pxToRem("24px") }}
          >
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {DETAILS_FORM_CONSTS.DETAILS_FORM_NAME_LABEL}
            </FormLabel>
            <DetailsFormTextInput
              name="fullName"
              value={detailsFormValidationFormik.values.fullName}
              onChange={detailsFormValidationFormik.handleChange}
              error={
                detailsFormValidationFormik.touched.fullName &&
                Boolean(detailsFormValidationFormik.errors.fullName)
              }
              placeholder={DETAILS_FORM_CONSTS.DETAILS_FORM_NAME_PLACEHOLDER}
            />
            {detailsFormValidationFormik.touched.fullName &&
              Boolean(detailsFormValidationFormik.errors.fullName) && (
                <DetailsFormHelperText className="form-error-field">
                  {detailsFormValidationFormik.errors.fullName}
                </DetailsFormHelperText>
              )}
          </FormControl>
          <FormControl
            variant="standard"
            required
            sx={{ marginTop: styleUtils.pxToRem("24px") }}
          >
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {DETAILS_FORM_CONSTS.DETAILS_FORM_JOBTITLE_LABEL}
            </FormLabel>
            <DetailsFormTextInput
              name="jobTitle"
              value={detailsFormValidationFormik.values.jobTitle}
              onChange={detailsFormValidationFormik.handleChange}
              error={
                detailsFormValidationFormik.touched.jobTitle &&
                Boolean(detailsFormValidationFormik.errors.jobTitle)
              }
              placeholder={
                DETAILS_FORM_CONSTS.DETAILS_FORM_JOBTITLE_PLACEHOLDER
              }
            />
            {detailsFormValidationFormik.touched.jobTitle &&
              Boolean(detailsFormValidationFormik.errors.jobTitle) && (
                <DetailsFormHelperText className="form-error-field">
                  {detailsFormValidationFormik.errors.jobTitle}
                </DetailsFormHelperText>
              )}
          </FormControl>

          <Button
            variant="contained"
            sx={{
              marginLeft: "auto",
              width: styleUtils.pxToRem("216px"),
              height: styleUtils.pxToRem("42px"),
              fontSize: textSizes.m,
              marginTop: spacing.m,
            }}
            onClick={detailsFormValidationFormik.handleSubmit}
          >
            {DETAILS_FORM_CONSTS.DETAILS_FORM_BUTTON_SUBMIT}
          </Button>
        </DetailsFormContainer>
      </Grid>
    </Grid>
  );
};

export default DetailsForm;

const DetailsFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const DetailsFormTextInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const DetailsFormHelperText = styled(FormHelperText)`
  color: #ff0000;
`;
