import React, { useState } from "react";
import { Text } from "../../atoms";
import { colors } from "../../../styles";

const TOTAL_CHARACTERS_LENGTH = 525;

const ReadMore = (props) => {
  const { children } = props;

  const isTrimmed = children && children?.length > TOTAL_CHARACTERS_LENGTH;
  const trimmedText = isTrimmed
    ? children.substring(0, TOTAL_CHARACTERS_LENGTH) + "..."
    : children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Text sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }} {...props}>
      {isReadMore ? trimmedText : children}

      {isTrimmed && (
        <>
          <br />
          <br />
          <Text
            color={colors.disableGrey}
            size="xs"
            style={{ cursor: "pointer" }}
            component="span"
            onClick={toggleReadMore}
          >
            {isReadMore && <>read more</>}
            {!isReadMore && <>see less</>}
          </Text>
        </>
      )}
    </Text>
  );
};
export default ReadMore;
