import { Button, Container, Grid } from "@mui/material";
import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";

const ChatDirectly = ({ isAuthenticated, onGetStarted }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: styleUtils.pxToRem("110px"),
        minHeight: "80vh",
      }}
    >
      <Grid container columnSpacing={spacing.xxxxl}>
        <Grid item md={6} ml={4}>
          <img
            src={images.home["employer-hero"]["4"]}
            alt="4"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid
          item
          md={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text size={44} style={{ lineHeight: 1.2 }}>
            <Text
              size={44}
              component="span"
              weight="extrabold"
              color={colors.green}
              style={{ lineHeight: 1.2 }}
            >
              Chat
            </Text>{" "}
            directly
          </Text>
          <Text size={44} style={{ lineHeight: 1.2 }}>
            with job-seekers
          </Text>
          <Text size="l" color="#4C5468" style={{ marginTop: spacing.s }}>
            No waiting around, start chatting with job seekers
          </Text>
          <Text size="l" color="#4C5468">
            immediately &amp; know more about them.
          </Text>
          {!isAuthenticated && (
            <Button
              variant="contained"
              sx={{
                height: styleUtils.pxToRem("46px"),
                width: styleUtils.pxToRem("176px"),
                mt: spacing.xxxl,
                px: spacing.xxl,
                py: spacing.xs,
                fontSize: textSizes.l,
                fontWeight: typography.bold,
              }}
              onClick={onGetStarted}
            >
              Get Started
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatDirectly;
