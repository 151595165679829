import {
  Button,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Text } from "../../atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../.././styles";

const SchdeulerListingTable = ({
  tableTitle,
  cloumnHeaders,
  rowKeys,
  rowValues,
  handleButtonClick,
}) => {
  const getColumnsHeaders = (cloumn, colIndex) => {
    return (
      <StyledTableCell key={colIndex}>
        <Text weight="bold">{cloumnHeaders[cloumn]}</Text>
      </StyledTableCell>
    );
  };

  const getRowValues = (row, fieldKey) => {
    if (fieldKey === "button") {
      return (
        <StyledTableCell key={fieldKey}>
          <StyledBtnWeb onClick={() => handleButtonClick(true, row.id)}>
            Book Slot
          </StyledBtnWeb>
        </StyledTableCell>
      );
    } else {
      return (
        <StyledTableCell key={fieldKey}>
          <Text weight="bold">{row[fieldKey]}</Text>
        </StyledTableCell>
      );
    }
  };

  const getRows = (row, rowIndex) => {
    return (
      <StyledTableRow key={rowIndex}>
        {rowKeys?.map((fieldKey) => getRowValues(row, fieldKey))}
      </StyledTableRow>
    );
  };

  return (
    <Content>
      <Title>{tableTitle}</Title>
      <TableContainer
        sx={{
          marginBottom: spacing.l,
          border: `2px solid ${colors.lightBlue}`,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(cloumnHeaders)?.map(getColumnsHeaders)}
            </TableRow>
          </TableHead>

          <TableBody>{rowValues?.map(getRows)}</TableBody>
        </Table>
      </TableContainer>
    </Content>
  );
};

export default SchdeulerListingTable;

const Content = styled("div")`
  margin: ${spacing.l};
`;

const Title = styled(Text)`
  margin-bottom: ${spacing.s};
  font-weight: ${typography.extrabold};
  font-size: ${textSizes.l};
`;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#eff6ff",
    padding: `${spacing.s} ${spacing.s}`,
  },
  [`&.${tableCellClasses.body}`]: {
    padding: `${spacing.s} ${spacing.s}`,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#f4f4f5",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledBtnWeb = styled(Button)`
  width: ${styleUtils.pxToRem("220px")};
  border-radius: 100;
  font-weight: ${typography.bold};
  font-size: ${textSizes.m};
  border-color: ${colors.primary};
`;
