import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";

import { Text } from "../../atoms";
import { colors, spacing } from "../../../styles";
import useIsMobile from "../../../hooks/useIsMobile";

const ConnectSocialItem = ({ renderConnectButton, image, name, socialData }) => {
  const isMobile = useIsMobile();
  const isConnected = socialData?.status;

  const IconContainer = isMobile ? IconMobileContainer : IconWebContainer;

  return (
    <IconContainer>
      <SocialImage src={image} />
      {isConnected ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <TextContainer>
            <Text weight="semibold">{name}</Text>
            <Text color={colors.placeholder} style={{ fontStyle: "italic" }} size="xs">
              Connected as{" "}
              <Text component="span" size="xs" weight="semibold">
                {socialData?.name}
              </Text>{" "}
              on{" "}
              <Text component="span" size="xs" weight="semibold">
                {moment(socialData.updated_at).format("MMM DD, YYYY")}
              </Text>
            </Text>
          </TextContainer>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <TextContainer>
            <Text weight="semibold">{name}</Text>
            <Text color={colors.placeholder} style={{ fontStyle: "italic" }} size="xs">
              Not connected
            </Text>
          </TextContainer>
          {renderConnectButton}
        </Box>
      )}
    </IconContainer>
  );
};

ConnectSocialItem.propTypes = {
  renderConnectButton: PropTypes.any,
  image: PropTypes.string,
  name: PropTypes.string,
  socialData: PropTypes.object,
};

export default ConnectSocialItem;

const IconWebContainer = styled("div")`
  display: flex;
  padding: ${spacing.xl} 0;
`;

const IconMobileContainer = styled("div")`
  display: flex;
  padding: ${spacing.m} 0;
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SocialImage = styled("img")`
  width: 34px;
  height: 34px;
  margin-right: ${spacing.s};
`;
