import { useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box, CircularProgress } from "@mui/material";

const CustomAvatar = ({ children, src, variant = "rounded", sx, ...props }) => {
  const [loaded, setLoader] = useState(false);

  const onLoad = () => {
    try {
      setLoader(true);
    } catch (e) {
      setLoader(true);
    }
  };

  const onError = (e) => {
    setLoader(true);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Avatar
        src={src}
        variant={variant}
        sx={sx}
        imgProps={{
          onLoad,
          onError,
        }}
        {...props}
      >
        {children}
      </Avatar>
      {!loaded && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: 0,
            boxShadow: "0px 0px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

CustomAvatar.propTypes = {
  children: PropTypes.node.isRequired,
  src: PropTypes.string.isRequired,
  variant: PropTypes.string,
  sx: PropTypes.object,
  // `...props` can include any valid props for the Avatar component, so they don't need specific prop types.
};

export default CustomAvatar;
