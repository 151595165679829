import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import * as yup from "yup";

import useIsMobile from "../../../../hooks/useIsMobile";

import EditJobInterests from "./views/EditJobInterests";
import EditJobInterestsMobile from "./mobileViews/EditJobInterestsMobile";

import { userUpdate } from "../../../Login/actions/LoginActions";
import { showLoader } from "../../../../utils/commonUtils";
import { TOP_JOB_ROLES, ALL_JOB_ROLES } from "./constants";

const EditJobInterestsContainer = (props) => {
  const { userData, handleEditJobInterests } = props;

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const validate = yup.object({
    job_interests: yup
      .array()
      .min(3, "Please select a minimum of 3 job interests")
      .max(6, "You can only select a maximum of 6 jobs interests"),
  });

  const handleEditInterests = (values) => {
    showLoader();
    handleEditJobInterests("preferences", "");
    dispatch(
      userUpdate({
        job_interests: values.job_interests,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      job_interests: userData?.job_interests,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      handleEditInterests(values);
    },
  });

  const setInterests = (jobRole) => {
    if (!formik?.values?.job_interests?.includes(jobRole)) {
      if (formik?.values?.job_interests?.length < 6) {
        formik.setFieldValue("job_interests", [...formik.values.job_interests, jobRole]);
      } else {
        formik.setFieldError("job_interests", "You can only select a maximum of 6 jobs interests");
      }
    }
  };

  const handleDeleteInterests = (jobRole) => {
    const currentJobInterests = formik.values.job_interests;
    const filteredJobInterests = currentJobInterests.filter((currOption) => currOption !== jobRole);
    formik.setFieldValue("job_interests", [...filteredJobInterests]);
  };

  const Wrapper = isMobile ? EditJobInterestsMobile : EditJobInterests;

  return (
    <Wrapper
      allJobRoles={ALL_JOB_ROLES}
      formik={formik}
      handleDeleteInterests={handleDeleteInterests}
      handleEditJobInterests={handleEditJobInterests}
      setInterests={setInterests}
      topJobRoles={TOP_JOB_ROLES}
    />
  );
};

export default EditJobInterestsContainer;
