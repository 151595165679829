const FirebaseEvents = {
  // JobSeeker
  JS_APPLY_CHAT: "Web_Apply_Leading_To_Chat",
  JS_USER_DELETE: "jobSeeker_Delete",
  JS_JOB_LISTING: "Web_joblisting",
  // JS_SIGNIN: "Web_JobSeeker_SignIn",
  // JS_SIGNUP: "Web_JobSeeker_SignUp",
  JS_JOB_FAVOURITE: "Web_Mark_Favourite",
  JS_JOB_UNFAVOURITE: "Web_Mark_UnFavourite",
  // JS_GOOGLE_SIGNIN: "Web_jobSeekerEMPLOYER_SignIn_Google",
  // JS_FACEBOOK_SIGNIN: "Web_jobSeekerEMPLOYER_SignIn_Facebook",

  //Google Signup for JobSeeker
  JS_GOOGLE_SIGNUP: "JS_Clicks_Google_ToSignUp",
  JS_GOOGLE_SIGNUP_PAGE1: "JS_Clicks_Next_OnbPage1of3_Google",
  JS_GOOGLE_SIGNUP_PAGE2: "JS_Clicks_Next_OnbPage2of3_Google",
  JS_GOOGLE_SIGNUP_PAGE3: "JS_Clicks_Submit_OnbPage3of3_Google",

  //Facebook Signup for JobSeeker
  JS_FACEBOOK_SIGNUP: "JS_Clicks_FB_ToSignup",
  JS_FACEBOOK_SIGNUP_PAGE1: "JS_Clicks_Next_OnbPage1of3_FB",
  JS_FACEBOOK_SIGNUP_PAGE2: "JS_Clicks_Next_OnbPage2of3_FB",
  JS_FACEBOOK_SIGNUP_PAGE3: "JS_Clicks_Submit_OnbPage3of3_FB",

  //JobSeeker Signup with Email
  JS_EMAIL_SIGNUP: "JS_Clicks_Next_OnFillingEmail_ToSignUp",
  JS_EMAIL_SIGNUP_PAGE1: "JS_Clicks_Next_OnbPage1of4_Email",
  JS_EMAIL_SIGNUP_PAGE2: "JS_Clicks_Next_OnbPage2of4_Email",
  JS_EMAIL_SIGNUP_PAGE3: "JS_Clicks_Next_OnbPage3of4_Email",
  JS_EMAIL_SIGNUP_PAGE4: "JS_Clicks_Submit_OnbPage4of4_Email",

  //JobSeeker Login
  JS_EMAIL_SIGNIN: "JS_Clicks_Next_InEmail_EnterPass_LogsIn",
  JS_GOOGLE_SIGNIN: "JS_Clicks_Google_AndLogsIn",
  JS_FACEBOOK_SIGNIN: "JS_Clicks_FB_AndLogsIn",

  //JS POC
  JS_JOBS_SEARCH_LISTING: "UUorJS_Clicks_Search_Successfully",
  JS_JOB_CARD_CLICK: "UUorJS_Clicks_JobCard",
  JS_JOB_BOOKMARK_CLICK: "JS_Clicks_Bookmark",
  JS_JOB_APPLY_NOW_CLICK: "UUorJS_Clicks_ApplyNow",
  JS_JOB_DETAILS_CARD_CHAT_NOW_CLICK: "UUorJS_Clicks_ChatNow_ExpandedJobCard",
  JS_JOB_DETAILS_CARD_SHARE_CLICK: "UUorJS_CopiesOrShares_Job_Successfully",

  // Employer
  // EMP_SIGNUP: "Web_Employer_SignUp",
  EMP_LOGOUT: "Web_EMP_logout",
  EMP_USER_DELETE: "employer_Delete",
  EMP_FIRST_JOB: "Web_FirstJob",
  EMP_JS_STARRED: "Web_Mark_People_Interested",
  EMP_JS_UNSTARRED: "Web_Mark_People_NotInterested",
  EMP_CLICK_JC: "Web_click_JC",
  // EMP_SIGNIN: "Web_Employer_SignIn",
  // EMP_GOOGLE_SIGNIN: "Web_employerEMPLOYER_SignIn_Google",
  // EMP_FACEBOOK_SIGNIN: "Web_employerEMPLOYER_SignIn_Facebook",

  //Employer Signup with google
  EMP_GOOGLE_SIGNUP: "Emp_Clicks_Google_ToSignUp",
  EMP_GOOGLE_SIGNUP_PAGE1: "Emp_Clicks_Next_OnbPage1of2_Google",
  EMP_GOOGLE_SIGNUP_PAGE2: "Emp_Clicks_Next_OnbPage2of2_Google",

  //Facebook signup for employer
  EMP_FACEBOOK_SIGNUP: "Emp_Clicks_FB_ToSignup",
  EMP_FACEBOOK_SIGNUP_PAGE1: "Emp_Clicks_Next_OnbPage1of2_FB",
  EMP_FACEBOOK_SIGNUP_PAGE2: "Emp_Clicks_Next_OnbPage2of2_FB",
  //Employer Signup with Email
  EMP_EMAIL_SIGNUP: "Emp_Clicks_Next_OnFillingEmail_ToSignUp",
  EMP_EMAIL_SIGNUP_PAGE1: "Emp_Clicks_Next_OnbPage1of3_Email",
  EMP_EMAIL_SIGNUP_PAGE2: "Emp_Clicks_Next_OnbPage2of3_Email",
  EMP_EMAIL_SIGNUP_PAGE3: "Emp_Clicks_Next_OnbPage3of3_Email",

  //Employer Signup with Phone
  EMP_PHONE_SIGNUP: "Emp_Clicks_SignUpHere_InPhoneLogin",
  EMP_PHONE_SIGNUP_PAGE1: "Emp_Clicks_Next_OnbPage1of4_Phone",
  EMP_PHONE_SIGNUP_PAGE2: "Emp_Clicks_Next_OnbPage2of4_Phone",
  EMP_PHONE_SIGNUP_PAGE3: "Emp_Clicks_Next_OnbPage3of4_Phone",
  EMP_PHONE_SIGNUP_PAGE4: "Emp_Clicks_Submit_OnbPage4of4_Phone",

  //Employer SignIn
  EMP_GOOGLE_SIGNIN: "Emp_Clicks_Google_AndLogsIn",
  EMP_EMAIL_SIGNIN: "Emp_Clicks_Email_EntersPass_AndLogsIn",
  EMP_PHONE_SIGNIN: "Emp_Verifies_PhoneNo_AndLogsIn",
  EMP_FACEBOOK_SIGNIN: "Emp_Clicks_FB_AndLogsIn",

  //Empoyer Account Activation
  REGISTERATION_FORM_SUBMIT: "TeamEmployD_Clicks_Submit_EmpRegForm",
  EMP_CLICKS_ACTIVATION_LINK_EMAIL: "Emp_Clicks_ActivateAccount_InEmail",
  EMP_FINISHES_ACCOUNT_ACTIVATION: "Emp_FinishesActivatingAccount_EmailOrSocial",

  // Common
  SESSION_TIMEOUT: "Web_Session_Timeout",
  API_ERROR: "Web_Api_error",
  SHARE_REFERRAL_LINK: "Web_share_referral_link",
  MOBILE_MENU_EMP: "Mobile-Resposnive-mr_ss_emp",
  MOBILE_MENU_JS: "Mobile-Resposnive-mr_ss_js",
  WEB_MENU_EMP: "Web-web_ss_emp",
  WEB_MENU_JS: "Web-web_ss_JS",
  CONTINUE_WITH_JS: "Continue_Js_Web",
  CONTINUE_WITH_EMP: "Continue_Emp_Web",
  //User SignUp
  UU_JS_LANDS_SIGNUP_PAGE: "UU_Lands_JSLoginSignUpPage",
  UU_EMP_LANDS_SIGNUP_PAGE: "UU_Lands_EmpLoginSignUpPage",

  //JS Events
  JS_SUBMITS_RATING: "JS_Clicks_StarRating",
  JS_CLICK_SEND_MESSAGE_APPYJOB: "JS_Clicks_SendMessage_OnApplyThenChat",
  JS_CLICK_CANCEL_MESSAGE_APPYJOB: "JS_Clicks_CloseWindow_OnApplyThenChat",
  JS_CLICK_GENERATE_MESSAGE_APPLYJOB: "JS_Clicks_Generate_OnApplyThenChat",
  JS_CLICK_REGENERATE_MESSAGE_APPLYJOB: "JS_Clicks_Regenerate_OnApplyThenChat",
  JS_CLICK_DELETE_ACCOUNT: "JS_Clicks_DeleteAcct_AfterSharingReason",
  JS_BLOCK_EMPLOYER: "JS_Clicks_Block_AfterSharingReason",
  JS_CLICK_SAVE_RESUME: "JS_Clicks_UploadResume",
  JS_CLICK_COPYANDSHARE_REFERRAL_LINK: "JS_Clicks_CopyOrShares_ReferralLink",
  JS_CONNECTS_SOCIAL_ACCOUNT: "JS_Connects_SocialAcct_Successfully",

  //EMP Events
  EMP_SUBMITS_RATING: "Emp_Clicks_StarRating",
  EMP_CLICK_DELETE_ACCOUNT: "Emp_Clicks_DeleteAcct_AfterGivingReason",
  EMP_BLOCK_JOBSEEKER: "Emp_Clicks_Block_AfterSharingReason",
  EMP_CONNECTS_SOCIAL_ACCOUNT: "Emp_Connects_SocialAcct_Successfully",
  EMP_CLICKS_JOBSEEKER: "Emp_Clicks_JobSeeker_InNavBar",
  EMP_CLICKS_POST_NEW_JOB_NAVBAR: "Emp_Clicks_PostFirstJob_InCarousel",
  EMP_CLICKS_NEXT_ON_POST_JOB : 'Emp_Clicks_Next_WhenPostingAJob',
  EMP_CLICKS_POST_JOB: "Emp_Clicks_Post_AfterFillingJobDetails",
  EMP_CLICKS_SEARCH: "Emp_Clicks_Search_Successfully",
  EMP_CLICKS_JS_CARD: "Emp_Clicks_JSCard",
  EMP_REQUEST_RESUME: "Emp_Clicks_RequestResume",
  EMP_CLICKS_VIEW_RESUME: "Emp_Clicks_ViewResume",
  EMP_CLICKS_SHORTLIST: "Emp_Clicks_Shortlist",
  EMP_CLICKS_CHAT_NOW: "Emp_Clicks_ChatNow",
  EMP_CLICKS_DELETE_JOB: "Emp_Clicks_DeleteJob_AfterSharingReason",
  EMP_CLICK_JOB_INACTIVE: "Emp_Toggles_Inactive_AfterSharingReason",
  EMP_CLICKS_GENERATE_JD: "Emp_Clicks_Generate_JD",
  EMP_CLICKS_REGENERATE_JD: "Emp_Clicks_Regenerate_JD",

  DEV_EVENTS: [
    //Events for DEV
  ],
  PROD_EVENTS: [
    //Employer events
    // "Web_PostJob",
    // "Web_EMP_logout",
    // "Web_request_resume",
    // "Web_FirstJob",
    // "Web_Mark_People_Interested",
    // "Web_Mark_People_NotInterested",
    // "Web_click_JC",
    // //Job Seeker events
    // "Web_Mark_Favourite",
    // "Web_Mark_UnFavourite",
    // "Continue_Js_Web",
    // "Web-web_ss_JS",
    // "Mobile-Resposnive-mr_ss_js",
    // "Web-web_ss_emp",
    // "Web_Apply_Leading_To_Chat",
    // "Mobile-Resposnive-mr_ss_emp",
  ],
  COMMON_EVENTS: [
    "Web_Session_Timeout",
    "Web_Api_error",
    "Web_share_referral_link",
    "JS_Clicks_Google_ToSignUp",
    "JS_Clicks_Next_OnbPage1of3_Google",
    "JS_Clicks_Next_OnbPage2of3_Google",
    "JS_Clicks_Submit_OnbPage3of3_Google",
    "JS_Clicks_FB_ToSignup",
    "JS_Clicks_Next_OnbPage1of3_FB",
    "JS_Clicks_Next_OnbPage2of3_FB",
    "JS_Clicks_Submit_OnbPage3of3_FB",
    "JS_Clicks_Next_OnFillingEmail_ToSignUp",
    "JS_Clicks_Next_OnbPage1of4_Email",
    "JS_Clicks_Next_OnbPage2of4_Email",
    "JS_Clicks_Next_OnbPage3of4_Email",
    "JS_Clicks_Submit_OnbPage4of4_Email",
    //Login Events for JobSeeker
    "JS_Clicks_Next_InEmail_EnterPass_LogsIn",
    "JS_Clicks_Google_AndLogsIn",
    "JS_Clicks_FB_AndLogsIn",

    //Signup Events for Employer
    "Emp_Clicks_Next_OnFillingEmail_ToSignUp",
    "Emp_Clicks_Next_OnbPage1of3_Email",
    "Emp_Clicks_Next_OnbPage2of3_Email",
    "Emp_Clicks_Next_OnbPage3of3_Email",
    "Emp_Clicks_Google_ToSignUp",
    "Emp_Clicks_Next_OnbPage1of2_Google",
    "Emp_Clicks_Next_OnbPage2of2_Google",
    "Emp_Clicks_SignUpHere_InPhoneLogin",
    "Emp_Clicks_Next_OnbPage1of4_Phone",
    "Emp_Clicks_Next_OnbPage2of4_Phone",
    "Emp_Clicks_Next_OnbPage3of4_Phone",
    "Emp_Clicks_Submit_OnbPage4of4_Phone",
    "Emp_Clicks_FB_ToSignup",
    "Emp_Clicks_Next_OnbPage1of2_FB",
    "Emp_Clicks_Next_OnbPage2of2_FB",

    //Login Events for Employer
    "Emp_Clicks_Google_AndLogsIn",
    "Emp_Clicks_Email_EntersPass_AndLogsIn",
    "Emp_Verifies_PhoneNo_AndLogsIn",
    "Emp_Clicks_FB_AndLogsIn",

    //JS POC Events
    "UUorJS_Clicks_Search_Successfully",
    "JS_Clicks_Bookmark",
    "UUorJS_Clicks_ApplyNow",
    "UUorJS_Clicks_ChatNow_ExpandedJobCard",
    "UUorJS_CopiesOrShares_Job_Successfully",

    "UU_Lands_JSLoginSignUpPage",
    "UU_Lands_EmpLoginSignUpPage",

    //JS Events
    "UUorJS_Clicks_JobCard",
    "JS_Clicks_StarRating",
    "JS_Clicks_SendMessage_OnApplyThenChat",
    "JS_Clicks_CloseWindow_OnApplyThenChat",
    "JS_Clicks_Generate_OnApplyThenChat",
    "JS_Clicks_Regenerate_OnApplyThenChat",
    "JS_Clicks_DeleteAcct_AfterSharingReason",
    "JS_Clicks_Block_AfterSharingReason",
    "JS_Clicks_UploadResume",
    "JS_Clicks_CopyOrShares_ReferralLink",
    "JS_Connects_SocialAcct_Successfully",

    //EMP Events
    "Emp_Clicks_StarRating",
    "Emp_Clicks_DeleteAcct_AfterGivingReason",
    "Emp_Clicks_Block_AfterSharingReason",
    "Emp_Connects_SocialAcct_Successfully",
    "Emp_Clicks_JobSeeker_InNavBar",
    "Emp_Clicks_PostFirstJob_InCarousel",
    "Emp_Clicks_Post_AfterFillingJobDetails",
    "Emp_Clicks_Next_WhenPostingAJob",
    "Emp_Clicks_Search_Successfully",
    "Emp_Clicks_JSCard",
    "Emp_Clicks_RequestResume",
    "Emp_Clicks_ViewResume",
    "Emp_Clicks_Shortlist",
    "Emp_Clicks_ChatNow",
    "Emp_Clicks_DeleteJob_AfterSharingReason",
    "Emp_Toggles_Inactive_AfterSharingReason",
    "Emp_Clicks_Generate_JD",
    "Emp_Clicks_Generate_JD",
    "Emp_Clicks_Regenerate_JD",

    //EMPOYER ACTIVATION EVENTS
    "TeamEmployD_Clicks_Submit_EmpRegForm",
    "Emp_Clicks_ActivateAccount_InEmail",
    "Emp_FinishesActivatingAccount_EmailOrSocial",
  ],
};

export default FirebaseEvents;
