import React, { Suspense, lazy, useRef, useState } from "react";
import { useFormik } from "formik";

import { Button, FormLabel, FormControl, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";

import FormDropDownContainer from "../Employer/views/FormDropdown/FormDropDownContainer";
import { Text, TextInput, WebsiteInput } from "../../../../components/atoms";
import { employerRegistrationSchema } from "../Employer/employerFormValidationSchema";

import { colors, spacing, styleUtils, textSizes } from "../../../../styles";
import {
  EMPLOYER_SIGNUP_FORM_ONE,
  EMPLOYER_SIGNUP_FORM_TWO,
  EMPLOYER_FORM_NAME_PLACEHOLDER,
  EMPLOYER_REGISTRATION_FORM_LABEL,
  EMPLOYER_REGISTRATION_FORM_DETAILS,
  EMPLOYER_FORM_NAME_LABEL,
  EMPLOYER_REGISTRATION_FORM_SUBMIT_LABEL,
} from "../Employer/constants";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import EmployerRegistrationModal from "components/organisms/EmployerRegistrationModal";

import { directSignupApi } from "configs/network/apis";
import { hideLoader, showLoader } from "utils/commonUtils";
import { apiErrorModalRef } from "globalRefs";

const MuiPhoneNumber = lazy(() => import("material-ui-phone-number"));

const RegistrationForm = () => {
  const phoneInput = useRef(null);
  const websiteInputRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState("");

  const formik = useFormik({
    initialValues: {
      ...EMPLOYER_REGISTRATION_FORM_DETAILS,
    },
    validationSchema: employerRegistrationSchema,
    onSubmit: async (values) => {
      let { webError, websiteValue } = await websiteInputRef.current.getWebsiteValue();
      if (webError) {
        return;
      }
      values.website = websiteValue;

      createAccountDirectSignup(values);
    },
  });

  const createAccountDirectSignup = async (values) => {
    showLoader();
    try {
      values.phoneNumber = values.phoneNumber.replace(`+${values.countryCode}`, "");
      values.companyName = values.companyName.trim();
      delete values.loginType;
      const data = await directSignupApi(values);
      setToken(data.data.oneTimeToken);
      setIsModalOpen(true);
      logEvent(FirebaseEvents.REGISTERATION_FORM_SUBMIT);
    } catch (error) {
      handleErrorDirectSignupApi(error);
    } finally {
      hideLoader();
    }
  };

  const handleErrorDirectSignupApi = (error) => {
    const errorStatusCode = error?.response?.status;
    if (errorStatusCode === 409) {
      const errorCode = error?.response?.data?.code;
      const errorMessage = error?.response?.data?.message;
      handleEmailOrCompanyExistsError(errorCode, errorMessage);
    } else if (errorStatusCode === 400) {
      //required parameters are missing (already handled in the frontend)
      //or invalid token in one or more of the input fields
      apiErrorModalRef.current.showModal();
    }
  };

  const handleEmailOrCompanyExistsError = (errorCode, errorMessage) => {
    const companyExists = errorCode.includes("companyName");
    const emailExists = errorCode.includes("email");
    if (companyExists && emailExists) {
      formik.setFieldError("companyName", errorMessage);
      formik.setFieldError("email", errorMessage);
    } else if (companyExists) {
      formik.setFieldError("companyName", errorMessage);
    } else if (emailExists) {
      formik.setFieldError("email", errorMessage);
    }
  };

  const handlePhone = (phoneValue) => {
    const {
      current: {
        state: {
          selectedCountry: { dialCode },
        },
      },
    } = phoneInput;
    formik.setFieldValue("countryCode", dialCode);
    formik.setFieldValue("phoneNumber", phoneValue);
  };

  const handleEmpSizeChange = (targetValue) => {
    formik.setFieldValue("employee_size", targetValue);
  };

  const handleDesignationSelection = (targetValue) => {
    formik.setFieldValue("designation", targetValue);
  };

  const handleMultiLocationSelection = (targetValue) => {
    formik.setFieldValue(
      "multiple_location",
      targetValue === EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS[0],
    );
  };

  return (
    <>
      <FormBox>
        <Text size="l" weight="medium" color={colors.darkBlue}>
          {EMPLOYER_REGISTRATION_FORM_LABEL}
        </Text>
        <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText>{EMPLOYER_FORM_NAME_LABEL}</FormLabelText>
          <EmployerFormInput
            name="fullName"
            placeholder={EMPLOYER_FORM_NAME_PLACEHOLDER}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.fullName && Boolean(formik.errors.fullName) && (
            <ErrorContainer>{formik.errors.fullName}</ErrorContainer>
          )}
        </FormControl>
        <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText>{EMPLOYER_SIGNUP_FORM_ONE.LABEL_EMAIL}</FormLabelText>
          <EmployerFormInput
            name="email"
            placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && Boolean(formik.errors.email) && (
            <ErrorContainer>{formik.errors.email}</ErrorContainer>
          )}
        </FormControl>
        <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText>{EMPLOYER_SIGNUP_FORM_ONE.LABEL_PHONE_NUMBER}</FormLabelText>
          <Suspense fallback={null}>
            <MuiPhoneNumber
              name="phoneNumber"
              countryCodeEditable={false}
              placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_PHONE_NUMBER}
              defaultCountry={"us"}
              onlyCountries={["us", "in"]}
              disableAreaCodes={true}
              ref={phoneInput}
              autoFormat={false}
              onChange={handlePhone}
              onBlur={formik.handleBlur}
              sx={{
                "& .MuiInputBase-root": {
                  border: "1px solid #E0E0E0",
                  borderRadius: styleUtils.pxToRem("4px"),
                },
                "& .MuiInputBase-root:hover": {
                  border: "1px solid #2B4895",
                  borderRadius: styleUtils.pxToRem("4px"),
                },
                "& .MuiInputBase-root:before": {
                  borderBottom: "none",
                  transition: "none",
                },
                "& .MuiInputBase-root:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& .MuiButtonBase-root": {
                  borderRight: `1px solid ${colors.stroke}`,
                  paddingRight: spacing.s,
                  borderRadius: 0,
                },
              }}
            />
          </Suspense>
          {formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) && (
            <ErrorContainer>{formik.errors.phoneNumber}</ErrorContainer>
          )}
        </FormControl>
        <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText>{EMPLOYER_SIGNUP_FORM_ONE.LABEL_COMPANY}</FormLabelText>
          <EmployerFormInput
            name="companyName"
            placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_COMPANY}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.companyName && Boolean(formik.errors.companyName) && (
            <ErrorContainer>{formik.errors.companyName}</ErrorContainer>
          )}
        </FormControl>
        <FormControl size="small" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText
            sx={{
              color: colors.labelBlack,
              marginBottom: styleUtils.pxToRem("6px"),
            }}
          >
            {EMPLOYER_SIGNUP_FORM_TWO.LABEL_DESIGNATION}
          </FormLabelText>
          <FormDropDownContainer
            fieldValue={formik.values.designation}
            fieldName="designation"
            widthValue={502}
            handleChange={handleDesignationSelection}
            dropDownOptions={EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_DESIGNATION_OPTIONS}
            placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_DESIGNATION}
            handleBlur={formik.handleBlur}
          />
          {formik.touched.designation && Boolean(formik.errors.designation) && (
            <EmployerFormHelperText>{formik.errors.designation}</EmployerFormHelperText>
          )}
        </FormControl>
        <>
          {formik.values.designation === "Other" && (
            <TextInput
              name="designationText"
              value={formik.values.designationText}
              onChange={formik.handleChange}
              error={formik.touched.designationText && Boolean(formik.errors.designationText)}
              onBlur={formik.handleBlur}
              placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_OTHER_DESIGNATION}
              sx={{
                height: styleUtils.pxToRem("42px"),
                marginTop: styleUtils.pxToRem("8px"),
                border: "1px solid #E0E0E0",
                borderRadius: styleUtils.pxToRem("4px"),
              }}
            />
          )}
          {Boolean(formik.errors.designationText) && (
            <EmployerFormHelperText>{formik.errors.designationText}</EmployerFormHelperText>
          )}
        </>
        <FormControl size="small" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText
            sx={{
              color: colors.labelBlack,
              marginBottom: styleUtils.pxToRem("6px"),
            }}
          >
            {EMPLOYER_SIGNUP_FORM_TWO.LABEL_EMPLOYEESIZE}
          </FormLabelText>
          <FormDropDownContainer
            fieldValue={formik.values.employee_size}
            fieldName="employee_size"
            widthValue={502}
            handleChange={handleEmpSizeChange}
            handleBlur={formik.handleBlur}
            dropDownOptions={EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_SIZE_OPTIONS}
            placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_EMPLOYEESIZE}
          />
          {formik.touched.employee_size && Boolean(formik.errors.employee_size) && (
            <EmployerFormHelperText>{formik.errors.employee_size}</EmployerFormHelperText>
          )}
        </FormControl>
        <FormControl size="small" required sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText
            sx={{
              color: colors.labelBlack,
              marginBottom: styleUtils.pxToRem("6px"),
            }}
          >
            {EMPLOYER_SIGNUP_FORM_TWO.LABEL_MULTI_LOCATIONS}
          </FormLabelText>
          <FormDropDownContainer
            fieldValue={
              formik.values.multiple_location !== null
                ? formik.values.multiple_location
                  ? EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS[0]
                  : EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS[1]
                : ""
            }
            fieldName="multiple_location"
            widthValue={502}
            handleChange={handleMultiLocationSelection}
            dropDownOptions={EMPLOYER_SIGNUP_FORM_TWO.EMPLOYEE_MULTI_LOCATION_OPTIONS}
            placeholder={EMPLOYER_SIGNUP_FORM_TWO.PLACEHOLDER_MULTI_LOCATIONS}
            handleBlur={formik.handleBlur}
          />
          {formik.touched.multiple_location && Boolean(formik.errors.multiple_location) && (
            <EmployerFormHelperText>{formik.errors.multiple_location}</EmployerFormHelperText>
          )}
        </FormControl>
        <FormControl variant="standard" sx={{ marginTop: styleUtils.pxToRem("18px") }}>
          <FormLabelText> {EMPLOYER_SIGNUP_FORM_ONE.LABEL_WEBSITE}</FormLabelText>
          <EmployerFormWebsiteInput
            ref={websiteInputRef}
            placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_WEBSITE}
          />
        </FormControl>
        <SubmitFormButton variant="contained" onClick={formik.handleSubmit}>
          {EMPLOYER_REGISTRATION_FORM_SUBMIT_LABEL}
        </SubmitFormButton>
      </FormBox>
      <EmployerRegistrationModal
        token={token}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};
export default RegistrationForm;

const ErrorContainer = styled("div")`
  color: ${colors.red};
  margin-bottom: 3%;
  margin-top: 2%;
  font-size: ${textSizes.xxs};
`;

const FormBox = styled("div")`
  padding: ${spacing.xxxl};
  display: flex;
  flex-direction: column;
`;

const EmployerFormInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const EmployerFormWebsiteInput = styled(WebsiteInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const FormLabelText = styled(FormLabel)`
  color: ${colors.labelBlack};
  margin-bottom: ${styleUtils.pxToRem("6px")};
`;
const EmployerFormHelperText = styled(FormHelperText)`
  color: #ff0000;
`;

const SubmitFormButton = styled(Button)`
  font-size: ${textSizes.l};
  margin-top: ${spacing.xxl};
`;
