export const spacing = {
  xxxs: "0.125rem",
  xxs: "0.25rem", // 10px
  xs: "0.5rem", // 12px
  descrsize: "0.875rem", //14px
  s: "1rem", // 16px
  m: "1.25rem", // 20px
  l: "1.5rem", // 24px
  xl: "1.875rem", // 30px
  xxl: "2.25rem", // 36px
  xxxl: "3rem",
  xxxxl: "5rem",
  xxxxxl: "8rem",
};
