import React, { Suspense, lazy, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Button, FormControl, FormHelperText, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "../../../../hooks/useIsMobile";

import { Icon, Text } from "../../../../components/atoms";
import { loginOtpUserSchema } from "./loginWithOtpValidation";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { colors, spacing, styleUtils, textSizes } from "../../../../styles";
import * as loginOtpFormConsts from "./loginOtpConstants";
import "./otploginsylesheet.css";

const MuiPhoneNumber = lazy(() => import("material-ui-phone-number"));

/**
 * Otp Login input component
 */
const LoginOtpForm = (props) => {
  const { handleVerifyLoginView, handleSendLoginOtp, verifyExistingUser } = props;
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const phoneInput = useRef(null);

  const loginOtpInputFormik = useFormik({
    initialValues: {
      ...loginOtpFormConsts.LOGIN_OTP_USER_FORM_DETAILS,
    },
    validationSchema: loginOtpUserSchema,
    onSubmit: (values) => {
      handleUserFormSubmit(values);
    },
  });

  const handlePhone = (phoneValue) => {
    loginOtpInputFormik.setFieldValue("phone", phoneValue);
  };

  const handleUserFormSubmit = (formValues) => {
    let otpSrc = formValues.email
      ? formValues.email
      : formValues.phone && formValues.phone.length > 1
      ? formValues.phone
      : "";

    if (formValues.email) {
      otpSrc = formValues.email;
    } else if (formValues.phone && formValues.phone.length > 1) {
      const {
        current: {
          state: {
            selectedCountry: { dialCode },
          },
        },
      } = phoneInput;
      otpSrc = formValues.phone;
      otpSrc = otpSrc.replace(`+${dialCode}`, "");
    }

    if (otpSrc) {
      verifyExistingUser({
        keyValue: otpSrc,
        keyType: "phone",
        userType: "employer",
        loginType: true,
        onCallback: handleUserValidationCallback,
      });
    } else {
      loginOtpInputFormik.setFieldError("email", loginOtpFormConsts.EMPTY_LOGIN_OTP_SRC_ERR_MSG);
      loginOtpInputFormik.setFieldError("phone", loginOtpFormConsts.EMPTY_LOGIN_OTP_SRC_ERR_MSG);
    }
  };

  const handleUserValidationCallback = (params) => {
    const { email, type, userExist } = params;
    const srcType = type === "email" ? "email" : "phone";
    const {
      current: {
        state: {
          selectedCountry: { dialCode },
        },
      },
    } = phoneInput;

    if (userExist) {
      handleVerifyLoginView(true);
      handleSendLoginOtp({
        email: email,
        phoneNumber:
          srcType === "phone"
            ? loginOtpInputFormik.values.phone.replace(`+${dialCode}`, "")
            : undefined,
        target: srcType,
        countryCode: `+${dialCode}`,
      });
    } else {
      loginOtpInputFormik.setFieldError(
        srcType,
        <ExistingUserErrorContainer>
          {loginOtpFormConsts.UNREGISTERED_LOGIN_OTP_SRC_ERR_MSG}
          <ExistingUserErrorFlexBox>
            <NavLink
              to={`/signup/${loginOtpFormConsts.EMPLOYER_KEY}`}
              state={{
                isPhoneSignUp: true,
                countryCodeValue: dialCode,
                phoneNumValue: loginOtpInputFormik.values.phone,
              }}
              onClick={() => logEvent(FirebaseEvents.EMP_PHONE_SIGNUP)}
            >
              <Text
                component={"span"}
                color={colors.primary}
                size="s"
                weight="medium"
                sx={{
                  marginLeft: styleUtils.pxToRem("10px"),
                  marginTop: styleUtils.pxToRem("10px"),
                }}
              >
                {loginOtpFormConsts.LOGIN_OTP_FORM_SIGNUP_LINK_TEXT}
              </Text>
            </NavLink>
          </ExistingUserErrorFlexBox>
        </ExistingUserErrorContainer>,
      );
    }
  };

  return (
    <LoginOtpFormContainer
      sx={{
        height: styleUtils.pxToRem(isMobile ? "643px" : "auto"),
        padding: isMobile ? spacing.xxxs : spacing.s,
      }}
    >
      <Text
        size="xl"
        weight="semibold"
        color={colors.titleBlack}
        sx={{
          marginTop: spacing.xs,
        }}
      >
        {loginOtpFormConsts.LOGIN_OTP_FORM_HEADER}
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
        sx={{
          marginTop: spacing.xxs,
          marginBottom: spacing.xl,
        }}
      >
        {loginOtpFormConsts.LOGIN_OTP_FORM_HEADER_SUBTEXT}
      </Text>

      <FormControl required>
        <FormLabel sx={{ color: colors.labelBlack, marginBottom: spacing.xs }}>
          {loginOtpFormConsts.LOGIN_OTP_FORM_PHONE_LABEL}
        </FormLabel>
        <Suspense fallback={null}>
          <MuiPhoneNumber
            autoFormat={false}
            countryCodeEditable={false}
            defaultCountry={"us"}
            onlyCountries={["us", "in"]}
            disableAreaCodes={true}
            name="phone"
            onChange={handlePhone}
            placeholder="Enter phone number to receive the verification code"
            ref={phoneInput}
            value={loginOtpInputFormik.values.phone}
            sx={{
              "& .MuiInputBase-root": {
                border: "1px solid #E0E0E0",
                borderRadius: styleUtils.pxToRem("4px"),
              },
              "& .MuiInputBase-root:hover": {
                border: "1px solid #2B4895",
                borderRadius: styleUtils.pxToRem("4px"),
              },
              "& .MuiInputBase-root:before": {
                borderBottom: "none",
                transition: "none",
              },
              "& .MuiInputBase-root:after": {
                borderBottom: "none",
              },
              "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
              "& .MuiButtonBase-root": {
                borderRight: `1px solid ${colors.stroke}`,
                paddingRight: spacing.s,
                borderRadius: 0,
              },
              height: styleUtils.pxToRem("42px"),
              width: "100%",
            }}
          />
        </Suspense>
        {loginOtpInputFormik.touched.phone && Boolean(loginOtpInputFormik.errors.phone) && (
          <FormHelperText sx={{ color: colors.red, marginLeft: 0, marginTop: 0 }}>
            {loginOtpInputFormik.errors.phone}
          </FormHelperText>
        )}
      </FormControl>
      <ButtonsContainer>
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <NextButton
          variant="contained"
          disabled={Boolean(loginOtpInputFormik.errors.phone)}
          sx={{
            width: styleUtils.pxToRem(isMobile ? "150px" : "216px"),
          }}
          onClick={loginOtpInputFormik.handleSubmit}
        >
          {loginOtpFormConsts.LOGIN_OTP_FORM_NEXT_BTN}
        </NextButton>
      </ButtonsContainer>
    </LoginOtpFormContainer>
  );
};

export default LoginOtpForm;

const LoginOtpFormContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const NextButton = styled(Button)`
  height: ${styleUtils.pxToRem("42px")};
  font-size: ${textSizes.m};
`;

const ExistingUserErrorFlexBox = styled("div")`
  display: inline-flex;
`;

const ExistingUserErrorContainer = styled("div")`
  margin-top: ${styleUtils.pxToRem("6px")};
`;

const ButtonsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${styleUtils.pxToRem("112px")};
`;
