import { combineReducers } from "redux";
import { purgeStoredState,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { loginInitialState } from "../../containers/Login/reducers/initialState";
import { LOGOUT } from "../../containers/Login/actions/actionTypes";

/** Reducers **/
import loginReducer from "../../containers/Login/reducers/LoginReducer";
import jobSeekerReducer from "../../containers/JobSeeker/reducers/JobSeekerReducer";
import employerReducer from "../../containers/Employer/reducers/EmployerReducer";
import commonReducer from "../../containers/Common/reducers/CommonReducer";
import signUpReducer from "../../containers/SignUp/reducers/SignUpReducer";
import otpReducer from "../../containers/Otp/reducers/OtpReducer";
import loginOtpReducer from "../../containers/Otp/reducers/LoginOtpReducer";

const persistConfig = {
  key: "employd-web", // key is required
  storage, // storage required
  whitelist: ["loginState", "employerState"],
};

const employerPersistConfig = {
  key: "employerState",
  storage,
  whitelist: ["employerFirstJobStartTime"],
};

const reducer = {
  ...loginReducer,
  ...jobSeekerReducer,
  employerState: persistReducer(
    employerPersistConfig,
    employerReducer.employerState
  ),
  ...commonReducer,
  ...signUpReducer,
  ...otpReducer,
  ...loginOtpReducer,
};

const appReducer = combineReducers({ ...reducer });

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    purgeStoredState(persistConfig);
    return appReducer(
      {
        loginState: {
          ...loginInitialState,
          location: state.loginState.location,
        },
      },
      action
    );
  }
  return appReducer(state, action);
};
