import { useState } from "react";
import PropTypes from 'prop-types';
import { Button, Card, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardModal, Icon, Text } from "../../components/atoms";
import { colors, spacing, styleUtils, textSizes } from "../../styles";
import { showNotification } from "../../utils/commonUtils";
import useIsMobile from "../../hooks/useIsMobile";

const FILE_TYPES = ".jpg, .jpeg, .png, .doc,.docx, .pdf, .mp3, .mp4";
const FILE_MIME_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "audio/mpeg",
  "video/mp4",
];

const RESUME_FILE_TYPES = ".doc,.docx, .pdf,";

const RESUME_FILE_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];

const UploadModal = ({
  isUploadModalOpen,
  hideUploadModal,
  onUploadAttachments,
  uploadButtonName,
  isResume,
}) => {
  const isMobile = useIsMobile();

  const [files, setFiles] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileSelectError, setFileSelectError] = useState(false);

  const [dragging, setDragging] = useState({ value: false, numberOfFiles: 0 });

  const onDropFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging({
      value: false,
      numberOfFiles: 0,
    });
    if (e.dataTransfer.items) {
      onSetFile(e.dataTransfer.items, "item");
    } else {
      onSetFile(e.dataTransfer.files, "file");
    }
  };

  const onChangeFile = (e) => {
    onSetFile(e.target.files, "file");
  };

  const handleInputFile = (type, fileItems) => {
    let file;
    if (type === "item") {
      file = fileItems[0].getAsFile();
    } else {
      file = fileItems[0];
    }
    if (file.size > 5242880) {
      setFileSizeError(true);
      return;
    } else {
      setFileSizeError(false);
      setFileSelectError(false);
    }
    if (isResume) {
      if (!RESUME_FILE_MIME_TYPES.includes(file.type)) {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Only pdf and docs are allowed",
          },
        });
        return;
      }
    } else if (!FILE_MIME_TYPES.includes(file.type)) {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Only pdf, images, videos and audios are allowed",
          },
        });
        return;
      }
    setFiles(file);
  };

  const onSetFile = (fileItems, type) => {
    if (fileItems.length > 1) {
      showNotification({
        alertProps: {
          severity: "error",
          children: "Only one file can be uploaded",
        },
      });
    } else if (fileItems.length === 1) {
      handleInputFile(type, fileItems);
    }
  };

  const onClickUpload = () => {
    if (files) {
      onUploadAttachments(files);
      hideUploadModal();
      setFileSelectError(false);
    } else {
      setFileSelectError(true);
    }
  };

  const onDragOverFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging({
      value: true,
      numberOfFiles: e.dataTransfer.items.length || e.dataTransfer.files.length,
    });
    setFileSelectError(false);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging({
      value: false,
      numberOfFiles: 0,
    });
  };

  return (
    <CardModal open={isUploadModalOpen} onClose={hideUploadModal}>
      <Card
        style={{
          maxHeight: styleUtils.pxToRem(isMobile ? "461px" : "451px"),
          minWidth: styleUtils.pxToRem(isMobile ? "334px" : "445px"),
          border: "none",
          borderColor: "none",
        }}
      >
        <Text weight="bold" size="l" sx={{ padding: spacing.m }}>
          Add Attachment
        </Text>
        <Divider />
        <ResumeDragDropContainer
          component="label"
          htmlFor="file-upload"
          onDrop={onDropFile}
          onDragOver={onDragOverFile}
          onDragLeave={onDragLeave}
          dragging={dragging}
          fileSelectError={fileSelectError}
          fileSizeError={fileSizeError}
          isMobile={isMobile}
        >
          {dragging.value ? (
            <>
              <Text size="l" style={{ pointerEvents: "none" }}>
                Drop here!
              </Text>
              <Text
                size="xs"
                color={colors.disableGrey}
                style={{ pointerEvents: "none" }}
              >
                Dropping{" "}
                {dragging.numberOfFiles === 1
                  ? "1 file"
                  : `${dragging.numberOfFiles} files`}
              </Text>
            </>
          ) : (
            <>
              <Text
                size="m"
                weight="semibold"
                style={{ pointerEvents: "none" }}
              >
                Browse file from device
              </Text>
              <Text
                size="l"
                weight="semibold"
                style={{
                  textDecoration: "underline",
                  textUnderlinePosition: "under",
                  color: "#0B97FA",
                  cursor: "pointer",
                }}
              >
                Browse
              </Text>
              <Text
                size="s"
                color="#9D9D9D"
                weight="semibold"
                style={{ pointerEvents: "none", marginTop: spacing.xl }}
              >
                Max. File Size: 5MB
              </Text>
            </>
          )}
        </ResumeDragDropContainer>
        <FileInputContainer>
          {fileSizeError && (
            <Text
              weight="regular"
              size="s"
              color="#FF405E"
              sx={{
                marginLeft: isMobile ? spacing.m : spacing.s,
              }}
            >
              File size is more than 5MB
            </Text>
          )}
          {fileSelectError && (
            <Text
              weight="regular"
              size="s"
              color="#FF405E"
              sx={{
                marginLeft: isMobile ? spacing.m : spacing.s,
              }}
            >
              Please choose a file
            </Text>
          )}
          <input
            type="file"
            id="file-upload"
            accept={isResume ? RESUME_FILE_TYPES : FILE_TYPES}
            multiple={false}
            hidden
            onChange={onChangeFile}
          />
        </FileInputContainer>
        {files && (
          <>
            <Text
              weight="medium"
              size="s"
              color="#767676"
              style={{ marginLeft: spacing.l }}
            >
              Added File Name
            </Text>
            <ResumeFileContainer isMobile={isMobile}>
              <Text style={{ flex: 1 }} numberOfLines={1}>
                {files.name}
              </Text>
              <Icon
                name="close"
                alt="close"
                size={14}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFiles(null);
                }}
              />
            </ResumeFileContainer>
          </>
        )}
        <Divider />
        <Row sx={{ marginLeft: isMobile ? spacing.l : spacing.xxl }}>
          <ActionBtn
            onClick={hideUploadModal}
            sx={{
              marginRight: spacing.xs,
            }}
            isMobile={isMobile}
          >
            Cancel
          </ActionBtn>
          <ActionBtn
            variant="contained"
            onClick={onClickUpload}
            isMobile={isMobile}
          >
            {uploadButtonName}
          </ActionBtn>
        </Row>
      </Card>
    </CardModal>
  );
};

UploadModal.propTypes = {
  isUploadModalOpen: PropTypes.bool.isRequired,
  hideUploadModal: PropTypes.func.isRequired,
  onUploadAttachments: PropTypes.func.isRequired,
  uploadButtonName: PropTypes.string.isRequired,
  isResume: PropTypes.bool.isRequired,
};

export default UploadModal;

const Row = styled("div")`
  display: flex;
  margin-top: ${spacing.m};
  margin-bottom: ${spacing.m};
`;

const ActionBtn = styled(Button)`
  height: ${styleUtils.pxToRem("40")};
  width: ${({ isMobile }) =>
    isMobile ? styleUtils.pxToRem("143px") : styleUtils.pxToRem("178px")};
  font-size: ${textSizes.m};
`;

const ResumeDragDropContainer = styled(Card)`
  border: 2px dashed
    ${({ dragging }) => (!dragging.value ? "#B7C5EB" : colors.primary)};
  height: ${({ isMobile }) =>
    isMobile ? styleUtils.pxToRem("161px") : styleUtils.pxToRem("156px")};
  width: ${({ isMobile }) =>
    isMobile ? styleUtils.pxToRem("294px") : styleUtils.pxToRem("399px")};
  margin: 0 ${spacing.l};
  margin-top: ${spacing.l};
  margin-bottom: ${({ fileSelectError, fileSizeError }) =>
    fileSizeError || fileSelectError ? spacing.xs : spacing.xl};
  padding: ${spacing.xxxl} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: none;
`;

const FileInputContainer = styled("div")`
 margin-left: ${spacing.s},
  margin-bottom: ${spacing.s},
`;

const ResumeFileContainer = styled("div")`
  height: ${styleUtils.pxToRem("46px")};
  width: ${({ isMobile }) =>
    isMobile ? styleUtils.pxToRem("294px") : styleUtils.pxToRem("399px")};
  margin: 0 ${spacing.l};
  margin-top: ${spacing.xs};
  margin-bottom: ${spacing.m};
  padding: ${spacing.s};
  display: flex;
  align-items: center;
  box-shadow: none;
`;
