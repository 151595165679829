import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import JobBoardContainer from "./views/JobBoard/JobBoardContainer";
import DescriptionContainer from "./views/MapView/MapViewContainer";

import EmployerSelector from "../../selectors/EmployerSelector";
import LoginSelector from "../../../Login/selectors/LoginSelector";
import { employerListingRequest } from "../../actions/EmployerActions";
import { setLoginRedirection } from "../../../../utils/commonUtils";
import { logEvent } from "../../../../configs/firebase";

import {
  EMPLOYER_PAGE_ROUTE,
  POST_NEW_JOB_PAGE_ROUTE,
} from "../../../../routes/routeConstants";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import { getEmployersJobsSuggestions } from "configs/network/apis";

const ExploreContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [postedJobsSuggestions, setPostedJobsSuggestions] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get("searchTerm") || "";
  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      searchTerm,
    },
  });

  const showJobDetailView = searchParams.get("showJobDetailView");
  const showPeopleDetailView = searchParams.get("showPeopleDetailView");
  const previousJobId = searchParams.get("jobId");
  const previousBrandName = searchParams.get("brandName");

  const employerListing = useSelector(EmployerSelector.getEmployerListing);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const filterSettings = useSelector(
    LoginSelector.getLoggedInUserSettings("employerListingFilter")
  );

  const [showRatingModal, setShowRatingModal] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    dispatch(
      employerListingRequest({
        searchTerm,
        employerUid: loggedInUser?.uid,
        wage: filterSettings?.wage || [],
        status: filterSettings?.status || [],
        experience: filterSettings?.status || [],
      })
    );
  };

  const toggleExploreView = () => {
    if (showPeopleDetailView && showJobDetailView) {
      navigate(-1);
    } else if (showJobDetailView) {
      navigate("/employer/jobs", { replace: true });
    }
  };

  const onClickSearch = (searchText) => () => {
    if (isEmpty(searchText)) {
      setSearchParams({
        ...(showJobDetailView && {
          showJobDetailView,
          jobId: previousJobId,
          brandName: previousBrandName,
        }),
      });
    } else {
      setSearchParams({
        searchTerm: searchText,
        ...(showJobDetailView && {
          showJobDetailView,
          jobId: previousJobId,
          brandName: previousBrandName,
        }),
      });
    }
    dispatch(
      employerListingRequest({
        searchTerm: searchText,
        employerUid: loggedInUser?.uid,
        wage: filterSettings?.wage || [],
        status: filterSettings?.status || [],
        experience: filterSettings?.status || [],
      })
    );
  };

  const onClickJob = (job) => {
    logEvent(FirebaseEvents.EMP_CLICK_JC, {
      items: [{ status: "onClick on job description success" }],
    });
    setLoginRedirection(
      loggedInUser?.uid,
      "",
      `?showJobDetailView=true&jobId=${job.jobId}&brandName=${job.brandName}`
    );
    setSearchParams({
      searchTerm,
      showJobDetailView: true,
      jobId: job.jobId,
      brandName: job.brandName,
    });
  };

  const onClickPostNewJob = () => {
    navigate(`${EMPLOYER_PAGE_ROUTE}/${POST_NEW_JOB_PAGE_ROUTE}`);
  };

  const handleFilter = (values) => {
    dispatch(
      employerListingRequest({
        searchTerm,
        employerUid: loggedInUser?.uid,
        ...values,
      })
    );
  };

  const mobileSearchValues = {
    term: values?.searchTerm,
  };

  const fetchPostedJobsSuggestions = async (value) => {
    if(!value) {
      setPostedJobsSuggestions([]);
      return;
    }

    try {
      const res = await getEmployersJobsSuggestions({
        searchTerm: value,
      });
      setPostedJobsSuggestions(res?.data);
    } catch (e) {
      setPostedJobsSuggestions([]);
    }
  };

  return (
    <>
      {!showJobDetailView ? (
        <JobBoardContainer
          loggedInUser={loggedInUser}
          employerListing={employerListing}
          filterSettings={filterSettings}
          onClickSearch={onClickSearch}
          onClickJob={onClickJob}
          onClickPostNewJob={onClickPostNewJob}
          handleFilter={handleFilter}
          searchTerm={values.searchTerm}
          onChangeSearchTerm={handleChange}
          mobileSearchValues={mobileSearchValues}
          setSearchValue={setFieldValue}
          postedJobsSuggestions={postedJobsSuggestions}
          fetchPostedJobsSuggestions={fetchPostedJobsSuggestions}
        />
      ) : (
        <DescriptionContainer
          toggleExploreView={toggleExploreView}
          loggedInUser={loggedInUser}
          employerListing={employerListing}
          filterSettings={filterSettings}
          onClickSearch={onClickSearch}
          onClickJob={onClickJob}
          handleFilter={handleFilter}
          searchTerm={values.searchTerm}
          onChangeSearchTerm={handleChange}
          setSearchValue={setFieldValue}
          postedJobsSuggestions={postedJobsSuggestions}
          fetchPostedJobsSuggestions={fetchPostedJobsSuggestions}
          showRatingModal={showRatingModal}
          setShowRatingModal={setShowRatingModal}
        />
      )}
    </>
  );
};

export default ExploreContainer;
