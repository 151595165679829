import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { isEmpty } from "lodash";

import { Button, Card, CircularProgress, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce";
import JobSeekerSelector from "../../../../../selectors/JobSeekerSelector";

import { Icon, Text } from "../../../../../../../components/atoms";
import { JobCard } from "../../../../../../../components/molecules";

import { hideLoader, showLoader } from "../../../../../../../utils/commonUtils";
import {
  colors,
  textSizes,
  typography,
  spacing,
  styleUtils,
} from "../../../../../../../styles";

import ResponsiveFilterWrapper from "../../ResponsiveFilter/ResponsiveFilterWrapper";
import LocationMap from "./Map";

import { JobBoardConstants, MapViewConstants } from "../../Constants";

const MapView = ({
  filterSettings,
  handleLoadMore,
  jobSeekerListing,
  jobSeekerSuggestedResults,
  onClickSearch,
  onClickJob,
  onClickBrandLogo,
  jobListPage,
  location,
  loggedInUser,
  handleFilter,
  searchTerm,
  showLoadMore,
  toggleExploreView,
  totalJobsCount,
}) => {
  const [activeCenter, setActiveCenter] = useState({});
  const [activeMarker, setActiveMarker] = useState(null);

  const noJobsFound = isEmpty(jobSeekerListing);
  const finalJobsDataToRender = noJobsFound
    ? jobSeekerSuggestedResults
    : jobSeekerListing;

  const isJobListFetching = useSelector(JobSeekerSelector.isJobListFetching);

  useEffect(() => {
    if (showLoadMore) {
      handleLoadMore();
    }

    if (isJobListFetching && jobListPage <= 1) {
      setActiveMarker(null);
      setActiveCenter(null);
    }
  }, [jobSeekerListing?.length]);

  //#region - Debounce Search
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);

  const onSearch = useCallback(
    debounce((text) => {
      onClickSearch(text)();
    }, 1500),
    []
  );

  const onJobCardSelect = (selectedIndex) => {
    setActiveMarker(selectedIndex);
  };

  const renderJobs = (item, index) => {
    const isFavourite =
      item.jobSeekers && item.jobSeekers.includes(loggedInUser?.uid);

    return (
      <Grid
        item
        key={index}
        sx={{
          height: styleUtils.pxToRem("160px"),
          width: styleUtils.pxToRem("342px"),
        }}
      >
        <JobCard
          item={item}
          isFavourite={isFavourite}
          onClickJob={onClickJob}
          onClickBrandLogo={onClickBrandLogo}
          isResMapView
        />
      </Grid>
    );
  };

  const jobsListContent = finalJobsDataToRender?.map(renderJobs);

  return (
    <Grid sx={{ marginTop: spacing.xxl, paddingBottom: spacing.s }}>
      <Grid
        item
        xs={12}
        sm={12}
        sx={{ paddingLeft: spacing.m, paddingRight: spacing.s }}
      >
        <OpitonsContainer sx={{ "&::-webkit-scrollbar": { display: "none" } }}>
          <ResponsiveFilterWrapper
            filterSettings={filterSettings}
            handleFilter={handleFilter}
          />
        </OpitonsContainer>
      </Grid>
      <SearchResultsContainer
        item
        xs={12}
        sm={12}
      >
        <TitleContainer>
          {!noJobsFound ? (
            <Text size="12px">
              <Text
                size="12px"
                component="span"
              >
                {MapViewConstants.SEARCH_RESULTS_SHOW}
              </Text>
              <Text
                size="12px"
                weight="bold"
                component="span"
              >
                {jobSeekerListing?.length}
              </Text>
              <Text
                size="12px"
                component="span"
              >
                {MapViewConstants.SEARCH_RESULTS_OF}
              </Text>
              <Text
                size="12px"
                weight="bold"
                component="span"
              >
                {totalJobsCount}
              </Text>
              {MapViewConstants.SEARCH_RESULTS_JOBS}
            </Text>
          ) : (
            <EmptyResultsContainer>
              <Icon
                size={38}
                name="noJobsMatch"
              />
              <Text
                weight="medium"
                style={{ textAlign: "center" }}
              >
                {MapViewConstants.NO_RECOMMENDED_JOBS_TITLE}
                <br />
                {JobBoardConstants.SIMILAR_JOBS_HERE}
              </Text>
            </EmptyResultsContainer>
          )}
          {isJobListFetching && jobListPage > 1 && (
            <CircularProgress
              size={textSizes.s}
              sx={{ marginLeft: spacing.s }}
            />
          )}
        </TitleContainer>
        {jobsListContent?.length > 0 && (
          <ListToggleButton
            sx={{
              "&:hover": {
                backgroundColor: colors.dodgerBlue,
              },
            }}
            onClick={toggleExploreView}
          >
            <Icon
              name="list-toggle"
              color={colors.white}
              size={14}
            />
            List View
          </ListToggleButton>
        )}
      </SearchResultsContainer>
      <Grid item>
        <Card
          sx={{
            marginTop: spacing.m,
            position: "relative",
            height: styleUtils.pxToRem("543px"),
          }}
        >
          <LocationMap
            activeCenter={activeCenter}
            activeMarker={activeMarker}
            jobSeekerListing={finalJobsDataToRender}
            location={location}
            onMapJobCardSelect={onJobCardSelect}
            setActiveCenter={setActiveCenter}
            setActiveMarker={setActiveMarker}
          />
          {isJobListFetching && jobListPage <= 1 ? showLoader() : hideLoader()}

          {activeMarker && (
            <JobsListView
              axis="x"
              index={activeMarker}
              onChangeIndex={onJobCardSelect}
              enableMouseEvents
              style={{ width: styleUtils.pxToRem("380px") }}
            >
              {finalJobsDataToRender.map((item, index) =>
                renderJobs(item, index)
              )}
            </JobsListView>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default MapView;

const OpitonsContainer = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  scrollbarwidth: none;
`;
const SearchResultsContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: ${spacing.m};
  margin-top: ${spacing.l};
  padding-left: ${spacing.s};
`;
const JobsListView = styled(SwipeableViews)`
  position: absolute;
  bottom: 0;
  margin-bottom: ${spacing.xs};
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};
`;
const ListToggleButton = styled(Button)`
  align-items: center;
  background-color: ${colors.dodgerBlue};
  border-radius: ${styleUtils.pxToRem("18px")};
  color: ${colors.white};
  display: flex;
  font-size: ${textSizes.s};
  font-weight: ${typography.bold};
  height: ${styleUtils.pxToRem("36px")};
  justify-content: center;
  gap: 7px;
  width: ${styleUtils.pxToRem("120px")};
  margin-right: 16px;
`;

const EmptyResultsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100svw;
  margin-left: -${styleUtils.pxToRem("30px")};
  margin-bottom: ${styleUtils.pxToRem("15px")};
`;

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
`;
