import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { FacebookLoginButton, GoogleLoginButton } from "../../../../../components/molecules";
import { images } from "../../../../../assets/images";

import { styleUtils, colors } from "../../../../../styles";
import DividerWithText from "components/atoms/DividerWithText";

const SocialActivationView = ({
  onSuccessGoogleLogin,
  onSuccessFacebookLogin,
  onFailureSocialActivation,
}) => {
  return (
    <>
      <DividerWithTextContainer>
        <DividerWithText text="or" width="100" size="m" color={colors.slateGrey} />
      </DividerWithTextContainer>
      <SocialLoginContainer>
        <GoogleLoginButton
          onProfileSuccess={onSuccessGoogleLogin}
          onFail={onFailureSocialActivation}
          render={(renderProps) => (
            <StyledCircularBox onClick={renderProps.onClick}>
              <SocialImage src={images["google-logo"]} />
            </StyledCircularBox>
          )}
        />

        <FacebookLoginButton
          onProfileSuccess={onSuccessFacebookLogin}
          onFail={onFailureSocialActivation}
          render={(renderProps) => (
            <StyledCircularBox onClick={renderProps.onClick}>
              <SocialImage src={images["facebook-logo"]} />
            </StyledCircularBox>
          )}
        />
      </SocialLoginContainer>
    </>
  );
};

SocialActivationView.propTypes = {
  onSuccessGoogleLogin: PropTypes.func.isRequired,
  onSuccessFacebookLogin: PropTypes.func.isRequired,
  onFailureSocialActivation: PropTypes.func.isRequired,
};

export default SocialActivationView;

const DividerWithTextContainer = styled("div")`
  margin-top: ${styleUtils.pxToRem("30px")};
  display: flex;
`;

const SocialLoginContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${styleUtils.pxToRem("20px")};
  margin-top: ${styleUtils.pxToRem("30px")};
`;

const StyledCircularBox = styled(Box)`
  border: 1px solid ${colors.silver};
  padding: ${styleUtils.pxToRem("12px")};
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialImage = styled("img")`
  width: ${styleUtils.pxToRem("20px")};
  height: ${styleUtils.pxToRem("20px")};
`;
