export const HomeConstants = {
  TITLE: "Find your next Job",
  SEARCH_INPUT_PLACEHOLDER: "search by jobs, skills",
  GOOLGE_INPUT_PLACEHOLDER: "enter location",
  SEARCH: "Search",
  POPULAR_SKILLS: "Popular skills:",
};

export const ChatDirectlyConstants = {
  TITLE_1: "Chat",
  TITLE_2: "directly",
  TITLE_3: "with employers",
  SUB_TITLE_1: "Get connected with employers quickly and easily",
  SUB_TITLE_2: "know more about a job posting",
  GET_STARTED_BTN: "Get Started",
};

export const EasyOneClickConstants = {
  TITLE_1: "Easy",
  TITLE_2: "one-click",
  TITLE_3: "apply",
  SUB_TITLE_1: "Apply for jobs at the click of a button",
  SUB_TITLE_2: '- click "Apply Now" at the bottom of job',
  SUB_TITLE_3: "description",
  GET_STARTED_BTN: "Get Started",
};

export const SearchConstants = {
  TITLE_1: "Part-time",
  TITLE_2: "job search made",
  TITLE_3: "simple",
  TITLE_4: "simple",
  SUB_TITLE_1: "*We are currently available in",
  SUB_TITLE_2: "Bucks County region, Pennsylvania",
};

export const SearchLocalJobsConstants = {
  TITLE_1: "Search",
  TITLE_2: "local",
  TITLE_3: "part-time jobs",
  SUB_TITLE_1: "Find part-time jobs in your area. Search by location,",
  SUB_TITLE_2: "or job title to find a wide variety of jobs.",
  GET_STARTED_BTN: "Get Started",
};
