import { styled } from "@mui/material/styles";

import { useLocation, useParams } from "react-router-dom";

import { UnAuthenticatedNavConstants } from "../../NavBarConstants";

import {
  BASE_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  EMPLOYER_HOME,
  BLOGS,
} from "../../../../routes/routeConstants";
import { styleUtils } from "../../../../styles";
import NavIconLink from "../NavIconLink";
import { Divider } from "@mui/material";
import NavButtonLink from "../NavButtonLink";

const UnAuthenticatedNav = () => {
  const location = useLocation();
  const { loginType } = useParams();
  const isEmployer =
    location.pathname === EMPLOYER_HOME || loginType === "employer";

  return (
    <>
      {isEmployer ? (
        <UnAuthenticatedEmployerNav />
      ) : (
        <UnAuthenticatedJobSeekerNav />
      )}
    </>
  );
};

const UnAuthenticatedEmployerNav = () => {
  return (
    <div style={{ paddingTop: styleUtils.pxToRem("8px") }}>
      <StyledContainer gap="36px">
        <NavIconLink
          to={EMPLOYER_HOME}
          text={UnAuthenticatedNavConstants.HOME_TAB}
        />
        <NavIconLink to={BLOGS} text={UnAuthenticatedNavConstants.BLOGS} />
      </StyledContainer>

      <StyledDivider />
      <StyledContainer gap="36px">
        <NavButtonLink
          to={BASE_PAGE_ROUTE}
          text={UnAuthenticatedNavConstants.JOBSEEKER_HOME_BUTTON}
        />
      </StyledContainer>
    </div>
  );
};

const UnAuthenticatedJobSeekerNav = () => {
  return (
    <div style={{ paddingTop: styleUtils.pxToRem("8px") }}>
      <StyledContainer gap="36px">
        <NavIconLink
          to={BASE_PAGE_ROUTE}
          text={UnAuthenticatedNavConstants.HOME_TAB}
        />
        <NavIconLink
          to={`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`}
          text={UnAuthenticatedNavConstants.EXPLORE_TAB}
        />
        <NavIconLink to={BLOGS} text={UnAuthenticatedNavConstants.BLOGS} />
      </StyledContainer>
      <StyledDivider />
      <StyledContainer>
        <NavButtonLink
          to={EMPLOYER_HOME}
          text={UnAuthenticatedNavConstants.EMPLOYER_HOME_BUTTON}
        />
      </StyledContainer>
    </div>
  );
};

export default UnAuthenticatedNav;

const StyledDivider = styled(Divider)({
  borderColor: "#D9D9D9",
  paddingRight: styleUtils.pxToRem("12px"),
  paddingLeft: styleUtils.pxToRem("5px"),
});

const StyledContainer = styled("div")(({ gap = "36px" }) => ({
  paddingTop: styleUtils.pxToRem("32px"),
  paddingBottom: styleUtils.pxToRem("32px"),
  paddingLeft: styleUtils.pxToRem("17px"),
  display: "flex",
  flexDirection: "column",
  gap: styleUtils.pxToRem(gap),
}));
