import { Box, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import { Text } from "../../components/atoms";
import { spacing } from "../../styles";

const NoMatch = () => {
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate("/");
  };

  return (
    <Container
      sx={{
        my: spacing.xxl,
        height: "85vh",
        position: "relative",
      }}
    >
      <MiddleBox>
        <Text style={{ marginBottom: spacing.s }}>
          There is nothing available here.
        </Text>
        <Button variant="contained" onClick={onClickBack}>
          Back to Home
        </Button>
      </MiddleBox>
    </Container>
  );
};

export default NoMatch;

const MiddleBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${spacing.xl};
`;
