import { Divider, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../../../styles";
import ProfileImage from "../../../../../components/molecules/ProfileImage";

const PersonalInformation = ({
  userDetails,
  onClickEditPersonalInformation,
}) => {
  return (
    <>
      <TopContainer>
        <ProfileImage
          style={{ width: 136, height: 136 }}
          currentImageUrl={userDetails?.photoURL}
          alt={userDetails?.fullName}
          imageType="job"
        />
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            right: spacing.l,
            top: spacing.s,
            width: "auto",
            pl: spacing.xl,
            pr: spacing.xl,
          }}
          onClick={() =>
            onClickEditPersonalInformation("personalinformation", "editPers")
          }
        >
          <Text size="l" weight="medium" color={colors.primary}>
            Edit
          </Text>
        </Button>
        <Text size={22} weight="semibold" style={{ marginTop: spacing.s }}>
          {userDetails?.name || userDetails?.fullName}
        </Text>
        <Text size="l" color={colors.placeholder}>
          {userDetails?.designation}
        </Text>
        <Text size="l" color={colors.placeholder}>
          {userDetails?.companyName}
        </Text>
      </TopContainer>
      <Divider />
      <InfoContainer>
        <div style={{ marginTop: spacing.xs }}>
          <InfoBox>
            <Icon
              size={24}
              name="profile-email"
              style={{ marginRight: spacing.s }}
            />
            <Text size="l">{userDetails?.email}</Text>
          </InfoBox>
          <>
            <Text size="l" color="#767676">
              Company Website
            </Text>
            <Text color={colors.primary} size="xl" weight="semibold">
              {userDetails?.website}
            </Text>
          </>
        </div>
        <DividerBlock />
        <div style={{ marginTop: spacing.xs }}>
          <InfoBox>
            <Icon
              size={24}
              name="profile-phone"
              style={{ marginRight: spacing.s }}
            />
            <Text size="l">
              {userDetails?.countryCode
                ? `+${userDetails?.countryCode}-${userDetails?.phoneNumber}`
                : userDetails?.phoneNumber}
            </Text>
          </InfoBox>
          <>
            <Text size="l" color="#767676">
              No. Of Employees
            </Text>
            <Text size="xl" weight="semibold">
              {userDetails?.employee_size}
            </Text>
          </>
        </div>
      </InfoContainer>
    </>
  );
};
export default PersonalInformation;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${spacing.xl};
`;

// const Row = styled("div")`
//   display: flex;
//   align-items: center;
// `;

const DividerBlock = styled("div")`
  border: 0;
  border-right: 1px solid ${colors.stroke};
  height: 15rem;
  margin-left: ${spacing.xxxxl};
  margin-right: ${spacing.xxxxl};
`;

const InfoContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s} ${spacing.xxl};
  margin-top: ${spacing.l};
  margin-left: ${styleUtils.pxToRem("56px")};
`;

const InfoBox = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.xxl};
`;
