import { styled } from "@mui/material/styles";

import { Divider } from "@mui/material";

import { JOB_SEEKER, SOCIAL_LINKS } from "../../../../appConstants";
import {
  TERMS_CONDITION,
  PRIVACY_POLICY,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  BLOGS,
} from "../../../../routes/routeConstants";

import { navigateWindow } from "../../../../utils/commonUtils";
import { colors, styleUtils } from "../../../../styles";
import { Text } from "../../../../components/atoms";
import { images } from "../../../../assets/images";
import { useSelector } from "react-redux";
import LoginSelector from "../../../Login/selectors/LoginSelector";
import NavIconLink from "../NavIconLink";
import {
  JobSeekerAuthenticatedNavConstants,
  UnAuthenticatedNavConstants,
} from "../../NavBarConstants";
import NavTextLink from "../NavTextLink";

const AuthenticatedNav = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const onClickRedirect = (path) => {
    navigateWindow(path);
  };

  return (
    <div style={{ paddingTop: styleUtils.pxToRem("8px") }}>
      <StyledContainer gap="36px">
        {loggedInUser?.userType === JOB_SEEKER && (
          <NavIconLink
            to={`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`}
            text={JobSeekerAuthenticatedNavConstants.EXPLORE_TAB}
          />
        )}
        <NavIconLink to={BLOGS} text={UnAuthenticatedNavConstants.BLOGS} />
      </StyledContainer>
      <StyledDivider />
      <StyledContainer gap="26px">
        <a href="mailto:care@employd.us" style={{ textDecoration: "none" }}>
          <Text
            color={colors.textPrimary}
            size="l"
            weight="medium"
            sx={{ transform: "none" }} // to negate global translateY(-1)
          >
            Contact Us
          </Text>
        </a>
        <NavTextLink to={PRIVACY_POLICY} text="Privacy Policy" />
        <NavTextLink to={TERMS_CONDITION} text="Terms &amp; Conditions" />
      </StyledContainer>
      <StyledDivider />
      <StyledContainer gap="18px">
        <Text size="l">Follow EmployD on</Text>
        <div style={{ display: "flex", gap: styleUtils.pxToRem("20px") }}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.facebook}
          >
            <img
              src={images.social.facebook}
              alt="Facebook"
              width={19}
              height={18}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.twitter}
          >
            <img
              src={images.social.twitter}
              alt="Twitter"
              width={22}
              height={23}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.instagram}
          >
            <img
              src={images.social.instagram}
              alt="Instagram"
              width={18}
              height={18}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.linkedin}
          >
            <img
              src={images.social.linkedin}
              alt="LinkedIn"
              width={18}
              height={18}
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={SOCIAL_LINKS.tiktok}
          >
            <img
              src={images.social.tiktok}
              alt="TikTok"
              width={18}
              height={20}
            />
          </a>
        </div>
      </StyledContainer>
      <StyledDivider />
      <StyledContainer gap="20px">
        <Text size="l">Download the EmployD app</Text>
        <Row>
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              onClickRedirect(
                "https://apps.apple.com/tt/app/employd/id1583769658"
              );
            }}
            onKeyDown={() => {
              onClickRedirect(
                "https://apps.apple.com/tt/app/employd/id1583769658"
              );
            }}
          >
            <img
              alt={"appStore"}
              src={images.home.store["app-store"]}
              style={{
                width: 124,
                height: 44,
                backgroundSize: "cover",
              }}
            />
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              onClickRedirect(
                "https://play.google.com/store/apps/details?id=com.impactanalytics.employdnew"
              );
            }}
            onKeyDown={() => {
              onClickRedirect(
                "https://play.google.com/store/apps/details?id=com.impactanalytics.employdnew"
              );
            }}
          >
            <img
              alt={"playStore"}
              src={images.home.store["play-store"]}
              style={{ width: 143, height: 56, backgroundSize: "cover" }}
            />
          </div>
        </Row>
      </StyledContainer>
    </div>
  );
};

export default AuthenticatedNav;

const Row = styled("div")`
  display: flex;
  align-items: center;
  gap: 19px;
`;

const StyledDivider = styled(Divider)({
  borderColor: "#D9D9D9",
  paddingRight: styleUtils.pxToRem("12px"),
  paddingLeft: styleUtils.pxToRem("5px"),
});

const StyledContainer = styled("div")(({ gap = "36px" }) => ({
  paddingTop: styleUtils.pxToRem("32px"),
  paddingBottom: styleUtils.pxToRem("32px"),
  paddingLeft: styleUtils.pxToRem("17px"),
  display: "flex",
  flexDirection: "column",
  gap: styleUtils.pxToRem(gap),
}));
