import { styled } from "@mui/material/styles";

import { Text } from "../../../../../../../components/atoms";

import { colors, spacing } from "../../../../../../../styles";

import ListView from "./ListView";
import SearchBox from "./SearchBox";
import MapView from "../../MapView/mobileViews/MapView";
import DetailsView from "../../DetailsView/mobileViews/DetailsView";

const JobBoard = (props) => {
  const {
    onClickSearch,
    location,
    searchTerm,
    onChangeSearchTerm,
    mobileSearchValues,
    clearInput,
    googleInputRef,
    onPlaceSelected,
    showMapView,
    showJobDetailView,
    showCompanyDetailView,

    jobSuggestions,
    fetchJobSuggestions,
    setFieldValue,
  } = props;

  let jobsViewContent = (
    <>
      <AvailableRegion>
        <marquee direction="left">
          <Text size="s" color={colors.white}>
            We are currently available in{" "}
            <Text size="s" component="span" color={colors.white} weight="semibold">
              Bucks County region, Pennsylvania
            </Text>
          </Text>
        </marquee>
      </AvailableRegion>
      <SearchBox
        clearInput={clearInput}
        googleInputRef={googleInputRef}
        location={location}
        mobileSearchValues={mobileSearchValues}
        onPlaceSelected={onPlaceSelected}
        onClickSearch={onClickSearch}
        onChangeSearchTerm={onChangeSearchTerm}
        searchTerm={searchTerm}
        jobSuggestions={jobSuggestions}
        setJobSearchValue={setFieldValue}
        fetchJobSuggestions={fetchJobSuggestions}
      />
      {showMapView ? <MapView {...props} /> : <ListView {...props} />}
    </>
  );

  const content =
    showJobDetailView || showCompanyDetailView ? <DetailsView {...props} /> : jobsViewContent;

  return <Container>{content}</Container>;
};

export default JobBoard;

const AvailableRegion = styled("div")`
  padding: ${spacing.xs} 0;
  background-color: #3cacff;
`;
const Container = styled("div")`
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
`;
