export const ACCOUNT_ACTIVATION_CONSTS = {
  LABEL_ACCOUNT_ACTIVATION: "Account Activation",
  TEXT_PREFIX_CONTINUE_WITH: "Continue with ",
  LABEL_PASSWORD: "Password *",
  PLACEHOLDER_PASSWORD: "Enter the password",
  LABEL_CONFIRM_PASSWORD: "Confirm Password *",
  PLACEHOLDER_CONFIRM_PASSWORD: "Re-enter the password",
  ERROR_PASSWORD_MISMATCH: "Passwords don’t match, retry again",
  SUCCESS_PASSWORD_MATCH: "Passwords match",
  BUTTON_NEXT: "Next",
  TEXT_LINK_EXPIRED_1: "This link has expired.",
  TEXT_LINK_EXPIRED_2: "If you have already activated",
  TEXT_LINK_EXPIRED_3: "your account, please login",
  TEXT_LINK_EXPIRED_4: "here",
  TEXT_LINK_EXPIRED_5: "If you haven’t activated your account yet,",
  TEXT_LINK_EXPIRED_6: "please write to",
  TEXT_LINK_EXPIRED_7: "for a new activation link",
  SUPPORT_EMAIL: "care@employd.us",
  TEXT_ALREADY_ACTIVATED_1: "Your account has already been activated.",
  TEXT_ALREADY_ACTIVATED_2: " Please continue to login to your account",
  BUTTON_CONTINUE: "Continue",
  ERROR_NO_PASSWORD_ENTERED: "Please enter the password.",
  ERROR_MINIMUM_PASSWORD_LENGTH: "Please enter min 6 letter password.",
  ERROR_NO_CONFIRM_PASSWORD_ENTERED: "Please re-enter the password",
};

export const SOCIAL_ACTIVATION_CONSTANTS = {
  GENERIC_ERROR_MESSAGE: "An unexpected error occurred. Please try again later.",
  ACCOUNT_ALREADY_EXISTS: "Account with this email already exists. Please proceed to login.",
  ACCOUNT_ALREADY_ACTIVE: "Account is already active.",
  UNEXPECTED_ERROR: "An unexpected error occured. Please try again.",
};
