export const loginInitialState = {
  isLoginLoading: false,
  isLoginFailed: false,
  isLoginSuccess: false,
  user: null,
  userSocial: null,
  settings: {
    jobSeekerListingFilter: null,
    employerListingFilter: null,
    employerJobSeekerListingFilter: null,
  },
  location: null,
  isLocationUpdating: false,
  searchLocation: null,
  blockedUsers: [],
  recentlyUnblockedUsers: [],
  isForgotPasswordOtpValid: false,
  forgotPasswordVerificationToken: "",
};
