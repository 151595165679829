export const EMPLOYER_LISTING_REQUEST = "EMPLOYER_LISTING_REQUEST";
export const EMPLOYER_LISTING_SUCCESS = "EMPLOYER_LISTING_SUCCESS";
export const EMPLOYER_LISTING_FAILURE = "EMPLOYER_LISTING_FAILURE";

export const EMPLOYER_JOB_SEEKER_LISTING_REQUEST =
  "EMPLOYER_JOB_SEEKER_LISTING_REQUEST";
export const EMPLOYER_JOB_SEEKER_LISTING_SUCCESS =
  "EMPLOYER_JOB_SEEKER_LISTING_SUCCESS";
export const EMPLOYER_JOB_SEEKER_LISTING_FAILURE =
  "EMPLOYER_JOB_SEEKER_LISTING_FAILURE";
export const EMPLOYER_JOB_SEEKER_TOGGLE_STARRED =
  "EMPLOYER_JOB_SEEKER_TOGGLE_STARRED";
export const EMPLOYER_JOB_SEEKER_TOGGLE_REQUEST_RESUME =
  "EMPLOYER_JOB_SEEKER_TOGGLE_REQUEST_RESUME";
export const EMPLOYER_JOB_SEEKER_RESET = "EMPLOYER_JOB_SEEKER_RESET";

export const EMPLOYER_JOB_DELETE_REQUEST = "EMPLOYER_JOB_DELETE_REQUEST";
export const EMPLOYER_JOB_DELETE_FAILED = "EMPLOYER_JOB_DELETE_FAILED";
export const EMPLOYER_JOB_DELETE_SUCCESS = "EMPLOYER_JOB_DELETE_SUCCESS";
export const EMPLOYER_RATING_STATUS = "JOB_SEEKER_RATING_STATUS";
export const EMPLOYER_UPDATE_RATING_STATUS = "EMPLOYER_UPDATE_RATING_STATUS";
export const EMPLOYER_FIRST_JOB_START_TIME = "EMPLOYER_FIRST_JOB_START_TIME";

//Scheduler Actions
export const SCHEDULER_USER_DETAILS_UPDATE = "SCHEDULER_USER_DETAILS_UPDATE";

export const SCHEDULER_GET_AVAILABILITY_LIST_REQUEST =
  "SCHEDULER_GET_AVAILABILITY_LIST_REQUEST";
export const SCHEDULER_GET_AVAILABILITY_LIST_FAILED =
  "SCHEDULER_GET_AVAILABILITY_LIST_FAILED";
export const SCHEDULER_GET_AVAILABILITY_LIST_SUCCESS =
  "SCHEDULER_GET_AVAILABILITY_LIST_SUCCESS";

export const SCHEDULER_GET_UPCOMING_LIST_REQUEST =
  "SCHEDULER_GET_UPCOMING_LIST_REQUEST";
export const SCHEDULER_GET_UPCOMING_LIST_FAILED =
  "SCHEDULER_GET_UPCOMING_LIST_FAILED";
export const SCHEDULER_GET_UPCOMING_LIST_SUCCESS =
  "SCHEDULER_GET_UPCOMING_LIST_SUCCESS";

export const SCHEDULER_CREATE_AVAILABILITY_REQUEST =
  "SCHEDULER_CREATE_AVAILABILITY_REQUEST";
export const SCHEDULER_CREATE_AVAILABILITY_FAILED =
  "SCHEDULER_CREATE_AVAILABILITY_FAILED";
export const SCHEDULER_CREATE_AVAILABILITY_SUCCESS =
  "SCHEDULER_CREATE_AVAILABILITY_SUCCESS";

export const SCHEDULER_GET_SHARE_LINK_REQUEST =
  "SCHEDULER_GET_SHARE_LINK_REQUEST";
export const SCHEDULER_GET_SHARE_LINK_FAILED =
  "SCHEDULER_GET_SHARE_LINK_FAILED";
export const SCHEDULER_GET_SHARE_LINK_SUCCESS =
  "SCHEDULER_GET_SHARE_LINK_SUCCESS";
