import { Divider, Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropTypes from "prop-types";

import { colors, spacing, styleUtils } from "../../../styles";
import { Icon } from "../../atoms";

const PeopleDetailCardSkeleton = ({ isMobile }) => {
  const jobDetailPointers = (
    <>
      <Grid item xs sm md>
        <div
          style={{
            padding: !isMobile ? spacing.xs : "",
          }}
        >
          <Row>
            <Skeleton circle={true} height={31} width={31} />
            <InfoBox>
              <Skeleton width={100} style={{ borderRadius: 50, height: 10 }} />
              <Skeleton width={isMobile ? 200 : 80} style={{ borderRadius: 50, height: 10 }} />
            </InfoBox>
          </Row>
          <Row>
            <Skeleton circle={true} height={31} width={31} />
            <InfoBox>
              <Skeleton width={100} style={{ borderRadius: 50, height: 10 }} />
              <Skeleton width={isMobile ? 200 : 80} style={{ borderRadius: 50, height: 10 }} />
            </InfoBox>
          </Row>
          <Row>
            <Skeleton circle={true} height={31} width={31} />
            <InfoBox>
              <Skeleton width={100} style={{ borderRadius: 50, height: 10 }} />
              <Skeleton width={isMobile ? 200 : 80} style={{ borderRadius: 50, height: 10 }} />
            </InfoBox>
          </Row>
        </div>
      </Grid>
      {!isMobile && (
        <Grid item xs={0}>
          <Divider orientation="vertical" />
        </Grid>
      )}
      <Grid item xs sm md>
        <div
          style={{
            padding: isMobile ? "" : spacing.xs,
            marginLeft: isMobile ? "0" : spacing.xl,
          }}
        >
          <Row>
            <Skeleton circle={true} height={31} width={31} />
            <InfoBox>
              <Skeleton width={100} style={{ borderRadius: 50, height: 10 }} />
              <Skeleton width={isMobile ? 200 : 80} style={{ borderRadius: 50, height: 10 }} />
            </InfoBox>
          </Row>
          <Row>
            <Skeleton circle={true} height={31} width={31} />
            <InfoBox>
              <Skeleton width={100} style={{ borderRadius: 50, height: 10 }} />
              <Skeleton width={isMobile ? 200 : 80} style={{ borderRadius: 50, height: 10 }} />
            </InfoBox>
          </Row>
        </div>
      </Grid>
    </>
  );

  return (
    <SkeletonTheme
      baseColor={colors.shimmerBaseColor}
      highlightColor={colors.shimmerHighlightColor}
    >
      <TopContainer>
        <GridContainer
          container
          sx={{
            height: `${!isMobile ? styleUtils.pxToRem("180px") : styleUtils.pxToRem("90px")}`,
            alignItems: "center",
          }}
        >
          <Grid item xs sm md>
            <TopContainer
              sx={{
                flexDirection: "row",
              }}
            >
              <Skeleton
                circle={true}
                style={{
                  width: 57,
                  height: 57,
                  borderRadius: 57 / 2,
                }}
              />
              <TitleContainer height="auto" sx={{ marginLeft: `${spacing.s}` }}>
                <Skeleton width={100} style={{ borderRadius: 50, height: 10, marginTop: 8 }} />
                <Skeleton width={50} style={{ borderRadius: 50, height: 10, marginTop: 8 }} />
              </TitleContainer>
            </TopContainer>
          </Grid>
          <Icon
            name={"star"}
            size={24}
            color={colors.shimmerHighlightColor}
            strokeColor={colors.shimmerHighlightColor}
            style={{
              marginRight: spacing.xl,
              marginLeft: `-${spacing.l}`,
              marginTop: `-${spacing.l}`,
            }}
          />
        </GridContainer>
        <Divider />
        <div
          style={{
            overflow: "auto",
            paddingLeft: spacing.xs,
            marginBottom: isMobile ? "50px" : "",
          }}
        >
          <Grid
            container
            overflow="auto"
            sx={{
              padding: `0 ${!isMobile && spacing.s}`,
            }}
          >
            {isMobile ? (
              <div style={{ marginLeft: spacing.s }}>{jobDetailPointers}</div>
            ) : (
              jobDetailPointers
            )}
          </Grid>
          <div style={{ marginTop: `${spacing.m}` }}>
            <Divider />
          </div>
          <JobSeekerDetailsContainer sx={{ paddingLeft: `${spacing.s}` }}>
            <Skeleton
              style={{
                marginTop: spacing.s,
                marginBottom: spacing.xs,
                width: "20%",
                borderRadius: 50,
              }}
            />
            <Skeleton
              count={2}
              style={{
                lineHeight: "1.5",
                width: "100%",
                height: 10,
                borderRadius: 50,
              }}
            />

            <div style={{ marginTop: `${spacing.m}` }}>
              <Divider />
            </div>

            <Skeleton
              style={{
                marginTop: spacing.s,
                width: "20%",
                borderRadius: 50,
              }}
            />
            <div style={{ display: "flex", marginTop: 10, marginBottom: 10, flexWrap: "wrap" }}>
              <Skeleton
                style={{
                  borderRadius: 100,
                  marginTop: 10,
                  marginRight: `${spacing.xs}`,
                  height: `${styleUtils.pxToRem("38px")}`,
                  width: `${styleUtils.pxToRem("110px")}`,
                  padding: `${spacing.xs} ${spacing.xxxl}`,
                }}
              />
              <Skeleton
                style={{
                  borderRadius: 100,
                  marginTop: 10,
                  marginRight: `${spacing.xs}`,
                  height: `${styleUtils.pxToRem("38px")}`,
                  width: `${styleUtils.pxToRem("110px")}`,
                  padding: `${spacing.xs} ${spacing.xxxl}`,
                }}
              />
              <Skeleton
                style={{
                  borderRadius: 100,
                  marginTop: 10,
                  marginRight: `${spacing.xs}`,
                  height: `${styleUtils.pxToRem("38px")}`,
                  width: `${styleUtils.pxToRem("110px")}`,
                  padding: `${spacing.xs} ${spacing.xxxl}`,
                }}
              />
            </div>

            <div style={{ marginTop: `${spacing.m}` }}>
              <Divider />
            </div>

            <Skeleton
              style={{
                marginTop: spacing.s,
                marginBottom: spacing.xs,
                width: "20%",
                borderRadius: 50,
              }}
            />
            <Skeleton
              style={{
                borderRadius: 100,
                marginTop: 8,
                marginBottom: 8,
                height: `${styleUtils.pxToRem("38px")}`,
                width: `${styleUtils.pxToRem("140px")}`,
                padding: `${spacing.xs} ${spacing.xxxl}`,
              }}
            />
          </JobSeekerDetailsContainer>
          <Divider style={{ marginTop: spacing.s }} />
        </div>
        <ChatContainer isMobile={isMobile}>
          <Skeleton
            style={{
              borderRadius: 100,
              padding: `${spacing.s} ${isMobile ? "160px" : "125px"}`,
            }}
          />
        </ChatContainer>
      </TopContainer>
    </SkeletonTheme>
  );
};

PeopleDetailCardSkeleton.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default PeopleDetailCardSkeleton;

const TopContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  margin-left: ${spacing.s};
  margin-right: ${spacing.s};
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const JobSeekerDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.s};
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.l};
`;

const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;

const GridContainer = styled(Grid)`
  width: 100%;
  display: flex;
  borderradius: 6px 6px 0px 0px;
`;

const ChatContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-end")};
  margin-top: ${({ isMobile }) => (!isMobile ? "25px" : "")};
  margin-bottom: ${({ isMobile }) => (!isMobile ? "25px" : "")};
  position: ${({ isMobile }) => (isMobile ? "fixed" : "")};
  bottom: 0;
  right: auto;
  height: 65px;
  left: 0;
  width: 100%;
  background-color: ${({ isMobile }) => (isMobile ? "#fff" : "")};
  box-shadow: ${({ isMobile }) => (isMobile ? `0px -5px 5px ${colors.shadow}` : "")};
`;
