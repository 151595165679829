import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";

import { images } from "../../../assets/images";
import { colors } from "../../../styles";

import AuthenticatedNav from "./AuthenticatedNav/AuthenticatedNav";
import UnAuthenticatedNav from "./UnAuthenticatedNav/UnAuthenticatedNav";

/**
 * Navigation Bar component containing
 * web tabs
 * @returns Navigation
 */
const NavBar = ({ isAuthenticated, onClickLogo, isCountryCheckError }) => {
  return (
    <>
      <AppBar
        component="nav"
        sx={{ background: colors.white, borderTopWidth: 0 }}
      >
        <Container>
          <Toolbar disableGutters>
            <Logo
              src={images.logo}
              onClick={onClickLogo}
              style={{ cursor: "pointer" }}
            />
            <Box sx={{ display: "flex", flex: 1 }} />
            <Box display="flex" alignItems="center" sx={{ height: "64px" }}>
              {isAuthenticated ? (
                <AuthenticatedNav />
              ) : (
                <UnAuthenticatedNav isCountryCheckError={isCountryCheckError} />
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default NavBar;

const Logo = styled("img")`
  display: flex;
  max-width: 140px;
  width: auto;
  height: auto;
`;
