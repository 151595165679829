import { useEffect, useRef, useState } from "react";
import { InputBase, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Text from "../Text";
import { colors } from "../../../styles";
import Icon from "../Icon";

const Dropdown = ({ value, onChange, options, sx }) => {
  const inputRef = useRef();
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, []);

  const onChangeEvent = (e) => {
    onChange(e.target.value);
  };

  return (
    <Select
      displayEmpty
      value={value}
      renderValue={() =>
        value === "" ? "" : options.find((item) => item.value === value).label
      }
      onChange={onChangeEvent}
      input={
        <InputBase
          sx={{
            border: `1px solid ${colors.seperator}`,
            borderRadius: "8px",
            flex: 0,
            ...sx,
          }}
          ref={inputRef}
        />
      }
      IconComponent={ExpandMoreIcon}
    >
      {options.map((i, index) => (
        <MenuItem
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: inputWidth,
            backgroundColor:
              i.value === value ? colors.selectedBlue : colors.white,
            "&:hover": {
              backgroundColor: colors.selectedBlue,
            },
          }}
          value={i.value}
        >
          <Text weight="medium" size="s">
            {i.label}
          </Text>
          {i.value === value && <Icon name="select-done-option" size={21} />}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Dropdown;
