import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, CardContent, Divider, Menu } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  Accordion,
  Dropdown,
  Icon,
  MultiCheckbox,
  Text,
} from "../../../../../../../components/atoms";
import { FilterCount } from "components/molecules";
import { colors, spacing, styleUtils, textSizes } from "../../../../../../../styles";

import useJobseekerListingFilter from "../../../../../../../hooks/filters/useJobseekerListingFilter";
import {
  distanceFilter,
  hourlyWageFilter,
  hoursPerWeekFilter,
  experienceFilter,
  daysFilter,
  timeSlotFilter,
} from "../../FilterConstants";
import { activeFiltersCount, getFilterCount } from "utils/commonUtils";

const Filter = ({ filterSettings, handleFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    distance,
    wage,
    hrsPerWeek,
    experience,
    days,
    timeSlot,
    onChangeDistance,
    onChangeHourlyWages,
    onChangeHoursPerWeek,
    onChangeExperience,
    onChangeDays,
    onChangeTimeslots,
    onApply,
    onReset,
  } = useJobseekerListingFilter(filterSettings, handleFilter, handleClose);

  const totalFilterCount = activeFiltersCount(filterSettings) + 1;
  {
    /* Adding 1 for default distance filter */
  }

  return (
    <>
      <FilterBox onClick={onClickFilter}>
        <Text color={colors.labelBlack} size="l" weight="bold">
          Filters
        </Text>
        <Text
          color={colors.white}
          size="s"
          weight="bold"
          sx={{
            backgroundColor: "#2B4894",
            borderRadius: "50%",
            height: "20px",
            width: "20px",
            textAlign: "center",
            marginLeft: spacing.xxs,
            marginTop: spacing.xxxs,
          }}
        >
          {totalFilterCount + 1}
        </Text>
        <Icon
          name="select-dropdown"
          size={24}
          style={{
            marginLeft: "auto",
            transform: open ? "rotate(180deg)" : "",
          }}
        />
      </FilterBox>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiList-root": {
            p: 0,
          },
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              p: spacing.l,
              height: "65vh",
              minWidth: 320,
              overflow: "auto",
            }}
          >
            <div style={{ display: "flex" }}>
              <Text weight="semibold" sx={{ mb: spacing.xs }}>
                Distance
              </Text>
              <FilterCount count={1} />
            </div>

            <Dropdown
              sx={{ height: 40 }}
              value={distance}
              onChange={onChangeDistance}
              options={distanceFilter}
            />
            <Divider sx={{ mt: spacing.s }} />
            <Accordion
              title="Hourly Wages"
              headerRightContent={<FilterCount count={getFilterCount(wage)} />}
              content={
                <MultiCheckbox
                  selectOptions={hourlyWageFilter}
                  prevSelectedOptions={wage}
                  handleSelect={onChangeHourlyWages}
                />
              }
            />
            <Divider />
            <Accordion
              title="Hours/week"
              headerRightContent={<FilterCount count={getFilterCount(hrsPerWeek)} />}
              content={
                <MultiCheckbox
                  selectOptions={hoursPerWeekFilter}
                  prevSelectedOptions={hrsPerWeek}
                  handleSelect={onChangeHoursPerWeek}
                />
              }
            />
            <Divider />
            <Accordion
              title="Experience"
              headerRightContent={<FilterCount count={getFilterCount(experience)} />}
              content={
                <MultiCheckbox
                  selectOptions={experienceFilter}
                  prevSelectedOptions={experience}
                  handleSelect={onChangeExperience}
                />
              }
            />
            <Divider />
            <Accordion
              title="Days/Week"
              headerRightContent={<FilterCount count={getFilterCount(days)} />}
              content={
                <MultiCheckbox
                  selectOptions={daysFilter}
                  prevSelectedOptions={days}
                  handleSelect={onChangeDays}
                />
              }
            />
            <Divider />
            <Accordion
              title="Shift"
              headerRightContent={<FilterCount count={getFilterCount(timeSlot)} />}
              content={
                <MultiCheckbox
                  selectOptions={timeSlotFilter}
                  prevSelectedOptions={timeSlot}
                  handleSelect={onChangeTimeslots}
                />
              }
            />
          </Box>
          <Divider />
          <ButtonRow>
            <ActionButton
              variant="outlined"
              sx={{
                marginRight: spacing.xs,
              }}
              onClick={onReset}
            >
              Reset Filter
            </ActionButton>
            <ActionButton variant="contained" onClick={onApply}>
              Apply
            </ActionButton>
          </ButtonRow>
        </CardContent>
      </Menu>
    </>
  );
};
Filter.propTypes = {
  filterSettings: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default Filter;

const ButtonRow = styled("div")`
  margin-top: ${spacing.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.m};
`;
const FilterBox = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.xxs};
  padding: ${spacing.xs} ${spacing.m} ${spacing.xs} ${spacing.s};
`;
const ActionButton = styled(Button)`
  height: ${styleUtils.pxToRem("35px")};
  font-size: ${textSizes.s};
`;
const FilterText = styled(Text)`
  background-color: ${colors.filterBadgeBlue};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: ${spacing.xxs};
  margin-top: ${spacing.xxxs};
`;
