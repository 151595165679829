import React from "react";
import PropTypes from "prop-types";
import { Divider, styled } from "@mui/material";

import Text from "../Text";
import { colors } from "styles";
const DividerWithText = ({
  text = "OR",
  width = "80",
  size = "xxs",
  color = `${colors.mediumGrey}`,
  textMargin = "5",
}) => {
  return (
    <DividerContainer width={width}>
      <Divider sx={{ flex: 1 }} color={color}>
        <Text size={size} weight="semibold" color={color} sx={{ margin: `0 ${textMargin}px` }}>
          {text}
        </Text>
      </Divider>
    </DividerContainer>
  );
};
DividerWithText.propTypes = {
  text: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  color: PropTypes.string,
  textMargin: PropTypes.string,
};

export default DividerWithText;

const DividerContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 16px;
  width: ${(props) => props.width}%;
`;
